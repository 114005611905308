import { Component, OnInit, ViewChild, Input } from "@angular/core";
import { DynamicPanel, GroupPanel } from '../../interfaces';
import { ServiceAgent } from 'src/app/framework-modules/formdata/form/serviceAgent';
import { FormData } from 'src/app/framework-modules/formdata/form/formData';
import { CtTableComponent, TableMetaData } from '../../../../framework-modules/elements/ct-table/component'
import { FilterRequest } from 'src/app/framework-modules/formdata/form/types';
import { SessionComponent } from '../../component';
import { SubjectListForm } from 'src/app/framework-modules/formdata/gen/subjectListForm';

@Component({
  selector: "subject-list",
  templateUrl: "./component.html",
  styleUrls: ["./component.scss"]
})


export class SubjectListComponent implements DynamicPanel, OnInit {
  @ViewChild("gridTable", { static: false }) gtable: CtTableComponent;
  @Input() inputData: any;
  @Input() parentPanel: GroupPanel;
  @Input() session: SessionComponent;
  public tableData: TableMetaData;
  public from: SubjectListForm;
  public fd: FormData;

  constructor(private sa: ServiceAgent) {
    this.from = SubjectListForm.getInstance();
    this.fd = this.from.newFormData(this.sa);
  }

  metaDataInit() {
    this.tableData = {
      data: [] = [],
      metaData: {
        column_names: [
          "name",
          "lectureCredits",
          "practicalCredits",
          "tutorialCredits",
          "department"

        ],
        display_names: {
          "name": "Subject Name",
          "lectureCredits": "Theory Credits",
          "practicalCredits": "Practical Credits",
          "tutorialCredits": "Tutorial Credits",
          "department": "Offering Department"

        },
        edit: true,
        search: true,
        placeholder: "Search By Student Name, USN, Temporary USN, Department"
      }
    };
  }

  ngOnInit() {
    this.metaDataInit();
    this.filter();
  }

  filter() {
    /**
     * initial filter conditions
     */
    const filter: FilterRequest = {
      conditions: {
        parentId: { value: 0, comp: "=" }
      },
      sort: { name: "asc" },
      maxRows: 10
    };
    const obs = this.fd.filter(filter);

    obs.subscribe(
      data => {
        this.tableData.data = data;
        this.gtable.update();
      },
      msgs => {
        console.error(msgs);
      }
    );
  }

  addSubject() {
    this.parentPanel.navigate('SubjectAdd')
  }

  edit($event) {
    this.parentPanel.navigate('SubjectEdit', this.tableData.data[$event].subjectId);
  }

  view($event) {
    this.parentPanel.navigate('StudentView', this.tableData.data[$event].studentId)
  }
}