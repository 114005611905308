
import { Form , Field, ChildForm } from '../form/form';
import { FormData } from '../form/formData';
import { SelectOption, Vo } from '../form/types';
import { Validators } from '@angular/forms'
import { ServiceAgent} from '../form/serviceAgent';
import { SectionForm, SectionVo } from './sectionForm';

export class ManualRegisterForm extends Form {
	private static _instance = new ManualRegisterForm();
	subjectId:Field = {
		name:'subjectId'
		,controlType: 'Hidden'
		,label: 'subjectId'
		,isRequired: true
		,valueType: 1
		,errorId: 'invalidId'
		,maxValue: 9999999999999
	};
	seasonId:Field = {
		name:'seasonId'
		,controlType: 'Hidden'
		,label: 'seasonId'
		,isRequired: true
		,valueType: 1
		,errorId: 'invalidId'
		,maxValue: 9999999999999
	};
	degreeId:Field = {
		name:'degreeId'
		,controlType: 'Hidden'
		,label: 'degreeId'
		,valueType: 1
		,errorId: 'invalidId'
		,maxValue: 9999999999999
	};
	degreeName:Field = {
		name:'degreeName'
		,controlType: 'Output'
		,label: 'degreeName'
		,valueType: 0
		,errorId: 'invalidName'
		,maxLength: 150
	};
	name:Field = {
		name:'name'
		,controlType: 'Output'
		,label: 'name'
		,valueType: 0
		,errorId: 'invalidName'
		,maxLength: 150
	};
	code:Field = {
		name:'code'
		,controlType: 'Output'
		,label: 'code'
		,valueType: 0
		,errorId: 'invalidText'
		,maxLength: 1000
	};
	levelName:Field = {
		name:'levelName'
		,controlType: 'Output'
		,label: 'levelName'
		,valueType: 0
		,errorId: 'invalidText'
		,maxLength: 1000
	};
	levelId:Field = {
		name:'levelId'
		,controlType: 'Output'
		,label: 'levelId'
		,valueType: 1
		,errorId: 'invalidId'
		,maxValue: 9999999999999
	};
	isOffered:Field = {
		name:'isOffered'
		,controlType: 'Output'
		,label: 'isOffered'
		,valueType: 3
		,defaultValue: false
		,errorId: 'invalidBool'
	};
	isRegistered:Field = {
		name:'isRegistered'
		,controlType: 'Output'
		,label: 'isRegistered'
		,valueType: 3
		,errorId: 'invalidBool'
	};

	sections: ChildForm = {
		name:'sections',
		form:SectionForm.getInstance(),
		isEditable:false,
		isTabular:true,
		label:'',
		minRows:0,
		maxRows:0,
		errorId:null
	};

	public static getInstance(): ManualRegisterForm {
		return ManualRegisterForm._instance;
	}

	constructor() {
		super();
		this.fields = new Map();
		this.controls = new Map();
		this.controls.set('subjectId', [Validators.required, Validators.max(9999999999999)]);
		this.fields.set('subjectId', this.subjectId);
		this.controls.set('seasonId', [Validators.required, Validators.max(9999999999999)]);
		this.fields.set('seasonId', this.seasonId);
		this.controls.set('degreeId', [Validators.max(9999999999999)]);
		this.fields.set('degreeId', this.degreeId);
		this.controls.set('degreeName', [Validators.maxLength(150)]);
		this.fields.set('degreeName', this.degreeName);
		this.controls.set('name', [Validators.maxLength(150)]);
		this.fields.set('name', this.name);
		this.controls.set('code', [Validators.maxLength(1000)]);
		this.fields.set('code', this.code);
		this.controls.set('levelName', [Validators.maxLength(1000)]);
		this.fields.set('levelName', this.levelName);
		this.controls.set('levelId', [Validators.max(9999999999999)]);
		this.fields.set('levelId', this.levelId);
		this.controls.set('isOffered', []);
		this.fields.set('isOffered', this.isOffered);
		this.controls.set('isRegistered', []);
		this.fields.set('isRegistered', this.isRegistered);

		this.childForms = new Map();
		this.childForms.set('sections', this.sections);
		this.opsAllowed = {get: true, create: true, update: true, filter: true};
		this.keyFields = ["subjectId"];
	}

	public getName(): string {
		 return 'manualRegister';
	}
}


export class ManualRegisterFd extends FormData {
	constructor(form: ManualRegisterForm, sa: ServiceAgent) {
		super(form, sa);
	}

	setFieldValue(name: 'subjectId' | 'seasonId' | 'degreeId' | 'degreeName' | 'name' | 'code' | 'levelName' | 'levelId' | 'isOffered' | 'isRegistered', value: string | number | boolean | null ): void {
		super.setFieldValue(name, value);
	}

	getFieldValue(name: 'subjectId' | 'seasonId' | 'degreeId' | 'degreeName' | 'name' | 'code' | 'levelName' | 'levelId' | 'isOffered' | 'isRegistered' ): string | number | boolean | null {
		return super.getFieldValue(name);
	}
}


export interface ManualRegisterVo extends Vo {
	code?: string, 
	degreeId?: number, 
	levelName?: string, 
	degreeName?: string, 
	subjectId?: number, 
	parentId?: number, 
	offeredSubjectId?: number, 
	isOffered?: boolean, 
	nbrEnrolled?: number, 
	seasonId?: number, 
	levelId?: number, 
	name?: string, 
	isIntegrated?: boolean, 
	isRegistered?: boolean, 
	isChild?: boolean, 
	sections?: SectionVo
}
