
import { Form , Field, ChildForm } from '../form/form';
import { FormData } from '../form/formData';
import { SelectOption, Vo } from '../form/types';
import { Validators } from '@angular/forms'
import { ServiceAgent} from '../form/serviceAgent';

export class SubjectwiseResultForm extends Form {
	private static _instance = new SubjectwiseResultForm();
	levelId:Field = {
		name:'levelId'
		,controlType: 'Hidden'
		,label: 'levelId'
		,valueType: 1
		,errorId: 'invalidId'
		,maxValue: 9999999999999
	};

	public static getInstance(): SubjectwiseResultForm {
		return SubjectwiseResultForm._instance;
	}

	constructor() {
		super();
		this.fields = new Map();
		this.controls = new Map();
		this.controls.set('levelId', [Validators.max(9999999999999)]);
		this.fields.set('levelId', this.levelId);
		this.opsAllowed = {get: true, filter: true};
	}

	public getName(): string {
		 return 'subjectwiseResult';
	}
}


export class SubjectwiseResultFd extends FormData {
	constructor(form: SubjectwiseResultForm, sa: ServiceAgent) {
		super(form, sa);
	}

	setFieldValue(name: 'levelId', value: string | number | boolean | null ): void {
		super.setFieldValue(name, value);
	}

	getFieldValue(name: 'levelId' ): string | number | boolean | null {
		return super.getFieldValue(name);
	}
}


export interface SubjectwiseResultVo extends Vo {
	cieMarks?: number, 
	hasWithdrawn?: boolean, 
	hasAttendedExam?: boolean, 
	subjectRegisterId?: number, 
	totalMarks?: number, 
	subjectId?: number, 
	studentId?: number, 
	usn?: string, 
	examMarks?: number, 
	academicYear?: string, 
	seasonId?: number, 
	credits?: number, 
	subjectSectionId?: number, 
	levelId?: number, 
	seasonName?: string, 
	gradePoints?: number, 
	admissionQuota?: string, 
	subjectName?: string, 
	departmentName?: string, 
	hasAttendedMakeup?: boolean, 
	isEligibleForExam?: boolean, 
	hasOptedForMakeup?: boolean, 
	slno?: number, 
	offeredSubjectId?: number, 
	grade?: string, 
	name?: string, 
	instituteId?: number, 
	isPassGrade?: boolean, 
	subjectCode?: string, 
	programId?: number
}
