
import { Form , Field, ChildForm } from '../form/form';
import { FormData } from '../form/formData';
import { SelectOption, Vo } from '../form/types';
import { Validators } from '@angular/forms'
import { ServiceAgent} from '../form/serviceAgent';

export class InstituteForm extends Form {
	private static _instance = new InstituteForm();
	instituteId:Field = {
		name:'instituteId'
		,controlType: 'Hidden'
		,label: 'instituteId'
		,valueType: 1
		,errorId: 'invalidId'
		,maxValue: 9999999999999
	};
	trustId:Field = {
		name:'trustId'
		,controlType: 'Hidden'
		,label: 'trustId'
		,isRequired: true
		,listName: 'trustList'
		,valueType: 1
		,errorId: 'invalidId'
		,maxValue: 9999999999999
	};
	name:Field = {
		name:'name'
		,controlType: 'Input'
		,label: 'Name'
		,isRequired: true
		,valueType: 0
		,errorId: 'invalidName'
		,maxLength: 150
	};
	instituteType:Field = {
		name:'instituteType'
		,controlType: 'Dropdown'
		,label: 'Institute Type'
		,isRequired: true
		,listName: 'instituteType'
		,valueList: [
			{value:'DSERTPS',text:'Karnataka State Syllabus Primary School'},
			{value:'DSERTPHS',text:'Karnataka State Syllabus Higher School'},
			{value:'CBSE ',text:'CBSE'},
			{value:'ENG_A_VTU',text:'Engineering College (Automonus Under VTU)'},
			{value:'ENG_VTU',text:'Engineering College ( VTU)'}
			]
		,valueType: 0
		,errorId: 'invalidText'
		,maxLength: 1000
	};
	addressLine1:Field = {
		name:'addressLine1'
		,controlType: 'Input'
		,label: 'Address Line1'
		,isRequired: true
		,valueType: 0
		,errorId: 'invalidDesc'
		,maxLength: 1000
	};
	addressLine2:Field = {
		name:'addressLine2'
		,controlType: 'Input'
		,label: 'Address Line2'
		,valueType: 0
		,errorId: 'invalidDesc'
		,maxLength: 1000
	};
	city:Field = {
		name:'city'
		,controlType: 'Input'
		,label: 'City'
		,isRequired: true
		,valueType: 0
		,errorId: 'invalidName'
		,maxLength: 150
	};
	state:Field = {
		name:'state'
		,controlType: 'Dropdown'
		,label: 'State'
		,isRequired: true
		,listName: 'state'
		,listKey: 'country'
		,keyedList: {
			91 : [
				{value:'Karnataka',text:'Karnataka'}, 
				{value:'Tamil Nadu',text:'Tamil Nadu'}, 
				{value:'Kerala',text:'Kerala'}, 
				{value:'Uttar Pradesh',text:'Uttar Pradesh'}
			], 
			130 : [
				{value:'Karnataka',text:'Karnataka'}, 
				{value:'Tamil Nadu',text:'Tamil Nadu'}, 
				{value:'Kerala',text:'Kerala'}, 
				{value:'Uttar Pradesh',text:'Uttar Pradesh'}
			]
			}
		,valueType: 0
		,errorId: 'invalidState'
		,maxLength: 50
	};
	pincode:Field = {
		name:'pincode'
		,controlType: 'Input'
		,label: 'Pin Code'
		,isRequired: true
		,valueType: 0
		,errorId: 'invalidPin'
		,minLength: 6
		,maxLength: 6
	};
	country:Field = {
		name:'country'
		,controlType: 'Hidden'
		,label: 'Country'
		,isRequired: true
		,valueType: 1
		,defaultValue: 130
		,errorId: 'invalidCountry'
		,maxValue: 999
	};
	govtCode:Field = {
		name:'govtCode'
		,controlType: 'Input'
		,label: 'Government Code'
		,valueType: 0
		,errorId: 'invalidGovtCode'
		,maxLength: 50
	};
	phoneNumber:Field = {
		name:'phoneNumber'
		,controlType: 'Input'
		,label: 'Phone Number'
		,isRequired: true
		,valueType: 0
		,errorId: 'invalidPhone'
		,minLength: 10
		,maxLength: 12
	};
	email:Field = {
		name:'email'
		,controlType: 'Input'
		,label: 'Email'
		,isRequired: true
		,valueType: 0
		,errorId: 'invalidEmail'
		,maxLength: 1000
	};
	createdAt:Field = {
		name:'createdAt'
		,controlType: 'Hidden'
		,label: 'createdAt'
		,valueType: 5
		,errorId: 'invalidTimestamp'
	};
	updatedAt:Field = {
		name:'updatedAt'
		,controlType: 'Hidden'
		,label: 'updatedAt'
		,valueType: 5
		,errorId: 'invalidTimestamp'
	};
	isActive:Field = {
		name:'isActive'
		,controlType: 'Input'
		,label: 'Is Active'
		,valueType: 3
		,defaultValue: true
		,errorId: 'invalidBool'
	};
	universityName:Field = {
		name:'universityName'
		,controlType: 'Input'
		,label: 'University Name'
		,valueType: 0
		,errorId: 'invalidText'
		,maxLength: 1000
	};
	universityCity:Field = {
		name:'universityCity'
		,controlType: 'Input'
		,label: 'University City'
		,valueType: 0
		,errorId: 'invalidText'
		,maxLength: 1000
	};

	public static getInstance(): InstituteForm {
		return InstituteForm._instance;
	}

	constructor() {
		super();
		this.fields = new Map();
		this.controls = new Map();
		this.controls.set('instituteId', [Validators.max(9999999999999)]);
		this.fields.set('instituteId', this.instituteId);
		this.controls.set('trustId', [Validators.required, Validators.max(9999999999999)]);
		this.fields.set('trustId', this.trustId);
		this.controls.set('name', [Validators.required, Validators.maxLength(150)]);
		this.fields.set('name', this.name);
		this.controls.set('instituteType', [Validators.required, Validators.maxLength(1000)]);
		this.fields.set('instituteType', this.instituteType);
		this.controls.set('addressLine1', [Validators.required, Validators.maxLength(1000)]);
		this.fields.set('addressLine1', this.addressLine1);
		this.controls.set('addressLine2', [Validators.maxLength(1000)]);
		this.fields.set('addressLine2', this.addressLine2);
		this.controls.set('city', [Validators.required, Validators.maxLength(150)]);
		this.fields.set('city', this.city);
		this.controls.set('state', [Validators.required, Validators.maxLength(50)]);
		this.fields.set('state', this.state);
		this.controls.set('pincode', [Validators.required, Validators.minLength(6), Validators.maxLength(6), Validators.pattern('[1-9][0-9]{5}')]);
		this.fields.set('pincode', this.pincode);
		this.controls.set('country', [Validators.required, Validators.max(999)]);
		this.fields.set('country', this.country);
		this.controls.set('govtCode', [Validators.maxLength(50)]);
		this.fields.set('govtCode', this.govtCode);
		this.controls.set('phoneNumber', [Validators.required, Validators.minLength(10), Validators.maxLength(12), Validators.pattern('[1-9][0-9]*')]);
		this.fields.set('phoneNumber', this.phoneNumber);
		this.controls.set('email', [Validators.required, Validators.email, Validators.maxLength(1000)]);
		this.fields.set('email', this.email);
		this.controls.set('createdAt', []);
		this.fields.set('createdAt', this.createdAt);
		this.controls.set('updatedAt', []);
		this.fields.set('updatedAt', this.updatedAt);
		this.controls.set('isActive', []);
		this.fields.set('isActive', this.isActive);
		this.controls.set('universityName', [Validators.maxLength(1000)]);
		this.fields.set('universityName', this.universityName);
		this.controls.set('universityCity', [Validators.maxLength(1000)]);
		this.fields.set('universityCity', this.universityCity);
		this.opsAllowed = {get: true, create: true, update: true, filter: true, delete: true};
		this.listFields = ['trustId','instituteType','state'];
		this.keyFields = ["instituteId"];
	}

	public getName(): string {
		 return 'institute';
	}
}


export class InstituteFd extends FormData {
	constructor(form: InstituteForm, sa: ServiceAgent) {
		super(form, sa);
	}

	setFieldValue(name: 'instituteId' | 'trustId' | 'name' | 'instituteType' | 'addressLine1' | 'addressLine2' | 'city' | 'state' | 'pincode' | 'country' | 'govtCode' | 'phoneNumber' | 'email' | 'createdAt' | 'updatedAt' | 'isActive' | 'universityName' | 'universityCity', value: string | number | boolean | null ): void {
		super.setFieldValue(name, value);
	}

	getFieldValue(name: 'instituteId' | 'trustId' | 'name' | 'instituteType' | 'addressLine1' | 'addressLine2' | 'city' | 'state' | 'pincode' | 'country' | 'govtCode' | 'phoneNumber' | 'email' | 'createdAt' | 'updatedAt' | 'isActive' | 'universityName' | 'universityCity' ): string | number | boolean | null {
		return super.getFieldValue(name);
	}
}


export interface InstituteVo extends Vo {
	universityName?: string, 
	pincode?: string, 
	country?: number, 
	city?: string, 
	isActive?: boolean, 
	universityCity?: string, 
	trustId?: number, 
	createdAt?: string, 
	phoneNumber?: string, 
	name?: string, 
	govtCode?: string, 
	instituteId?: number, 
	addressLine1?: string, 
	addressLine2?: string, 
	state?: string, 
	instituteType?: string, 
	email?: string, 
	updatedAt?: string
}
