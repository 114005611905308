import { Component, OnInit, Input } from "@angular/core";
import { GroupPanel } from '../../interfaces';
import { ServiceAgent } from 'src/app/framework-modules/formdata/form/serviceAgent';
import { SessionComponent } from '../../component';
import { ClientContext } from 'src/app/framework-modules/formdata/form/clientContext';
import { MainSectionSelectionFd, MainSectionSelectionForm } from "src/app/framework-modules/formdata/gen/mainSectionSelectionForm";
import { MessageService } from "src/app/services/messageService";

@Component({
  selector: "force-valuation",
  templateUrl: "./component.html",
  styleUrls: ["./component.scss"]
})

export class ForceValuationComponent implements OnInit {
  @Input() inputData: any;
  @Input() parentPanel: GroupPanel;
  @Input() session: SessionComponent;
  /**
   * select a subject using this form
   */

  public form: MainSectionSelectionForm;
  public fd: MainSectionSelectionFd;

  constructor(private sa: ServiceAgent, private cc: ClientContext, private ms:MessageService) {
    
    this.form = MainSectionSelectionForm.getInstance();
    this.fd = this.form.newFormData(this.sa);

  }

  ngOnInit() {
  }

  update(){
    this.sa.serve('forceValuation',
    {
      data:{
       offeredSubjectId:this.fd.getFieldValue('offeredSubjectId') 
      }
    }).subscribe(
      data=>{
        this.ms.showSuccess("UPDATE Successfully")
      }
    )
  }

}