import { Component, Input } from '@angular/core';
import { ServiceAgent } from 'src/app/framework-modules/formdata/form/serviceAgent';
import { FormData } from 'src/app/framework-modules/formdata/form/formData';
import { RouterService } from 'src/app/services/routerService';
import { MessageService } from 'src/app/services/messageService';
import { ClientContext } from 'src/app/framework-modules/formdata/form/clientContext';
import { DynamicPanel, GroupPanel } from '../interfaces';
import { SessionComponent } from '../component';
import { SeasonsListFd, SeasonsListForm } from 'src/app/framework-modules/formdata/gen/seasonsListForm';


@Component({
  selector: 'season-select',
  templateUrl: './component.html',
  styleUrls: ['./component.scss']
})
export class SeasonSelectComponent implements DynamicPanel {
  @Input() inputData: any;
  @Input() parentPanel: GroupPanel;
  @Input() session: SessionComponent;

  seasonListForm: SeasonsListForm;
  seasonListFd: SeasonsListFd;

  public constructor(public sa: ServiceAgent, private ctx: ClientContext, public routerService: RouterService, private ms: MessageService) {
    this.seasonListForm = SeasonsListForm.getInstance();
    this.seasonListFd = this.seasonListForm.newFormData(this.sa);
  }
  
  /**
   * Call this function to navigate 
   * from the season selection dropdown
   */
   showSession(){
    this.sa.serve('Update_userSeason',{data:{"seasonId":this.seasonListFd.getFieldValue('seasonId')}}).subscribe({
      next: data => {
        this.ctx.setcurrentSeasonId(this.seasonListFd.getFieldValue('seasonId') as number);
        this.routerService.openSessionAfterSelect();
      },
      error: err => {

      }
    })
  }

}
