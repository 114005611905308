
import { Form , Field, ChildForm } from '../form/form';
import { FormData } from '../form/formData';
import { SelectOption, Vo } from '../form/types';
import { Validators } from '@angular/forms'
import { ServiceAgent} from '../form/serviceAgent';

export class DegreeProgramSelectionForm extends Form {
	private static _instance = new DegreeProgramSelectionForm();
	degreeId:Field = {
		name:'degreeId'
		,controlType: 'Hidden'
		,label: 'degreeId'
		,isRequired: true
		,valueType: 1
		,errorId: 'invalidId'
		,maxValue: 9999999999999
	};
	programId:Field = {
		name:'programId'
		,controlType: 'Dropdown'
		,label: 'Select a program'
		,isRequired: true
		,listName: 'degreeProgramList'
		,listKey: 'degreeId'
		,valueType: 1
		,errorId: 'invalidId'
		,maxValue: 9999999999999
	};
	autoEnroll:Field = {
		name:'autoEnroll'
		,controlType: 'Hidden'
		,label: 'autoEnroll'
		,isRequired: true
		,valueType: 3
		,errorId: 'invalidBool'
	};

	public static getInstance(): DegreeProgramSelectionForm {
		return DegreeProgramSelectionForm._instance;
	}

	constructor() {
		super();
		this.fields = new Map();
		this.controls = new Map();
		this.controls.set('degreeId', [Validators.required, Validators.max(9999999999999)]);
		this.fields.set('degreeId', this.degreeId);
		this.controls.set('programId', [Validators.required, Validators.max(9999999999999)]);
		this.fields.set('programId', this.programId);
		this.controls.set('autoEnroll', [Validators.required]);
		this.fields.set('autoEnroll', this.autoEnroll);
		this.opsAllowed = {get: true, create: true, update: true, filter: true};
		this.listFields = ['programId'];
		this.keyFields = ["programId"];
	}

	public getName(): string {
		 return 'degreeProgramSelection';
	}
}


export class DegreeProgramSelectionFd extends FormData {
	constructor(form: DegreeProgramSelectionForm, sa: ServiceAgent) {
		super(form, sa);
	}

	setFieldValue(name: 'degreeId' | 'programId' | 'autoEnroll', value: string | number | boolean | null ): void {
		super.setFieldValue(name, value);
	}

	getFieldValue(name: 'degreeId' | 'programId' | 'autoEnroll' ): string | number | boolean | null {
		return super.getFieldValue(name);
	}
}


export interface DegreeProgramSelectionVo extends Vo {
	degreeId?: number, 
	autoEnroll?: boolean, 
	programId?: number
}
