import { Component, Input, Output, EventEmitter, OnInit, ViewChild } from "@angular/core";
import { DynamicPanel, LandingPanel, GroupPanel } from '../../interfaces';
import { ServiceAgent } from 'src/app/framework-modules/formdata/form/serviceAgent';
import { SessionComponent } from '../../component';
import { FormData, TabularData } from 'src/app/framework-modules/formdata/form/formData';
import { GuardianForm } from 'src/app/framework-modules/formdata/gen/guardianForm';
import { FieldValues, FilterRequest, Vo } from 'src/app/framework-modules/formdata/form/types';
import { StudentForm, StudentFd } from 'src/app/framework-modules/formdata/gen/studentForm';
import { MessageService } from 'src/app/services/messageService';
import { CtSpinner } from 'src/app/framework-modules/elements/ct-spinner/component';
import { CtDialogComponent } from 'src/app/framework-modules/elements/ct-dialog/component';
import { MatDialog } from '@angular/material';
import { LevelSectionForm, LevelSectionFd } from 'src/app/framework-modules/formdata/gen/levelSectionForm';
const ADDRESS_FIELDS = ['addressLine1', 'addressLine2', 'city', 'state', 'country', 'pincode'];
const PRESENT_FIELDS = ['presentAddressLine1', 'presentAddressLine2', 'presentCity', 'presentState', 'presentCountry', 'presentPincode'];
const MENU = {
  studentDetails: {name:"Student Details", displayonSidenav:true, order:1},
  address: {name:"Address Details", displayonSidenav:true, order:2},
  addmissionDetails: {name:"Addmission Details", displayonSidenav:true, order:3},
  guardianDetails: {name:"Guardian Details", displayonSidenav: true, order:4},

};

@Component({
  selector: "student-add",
  templateUrl: "./component.html",
  styleUrls: ["./component.scss"]
})
export class StudentAddComponent implements DynamicPanel, OnInit {
  @ViewChild("spinner", {static:false}) spinner: CtSpinner;
  @Input() inputData: any;
  @Input() parentPanel: GroupPanel;
  @Input() session: SessionComponent;

  public form: StudentForm;
  public guardianForm: GuardianForm;
  public fd: StudentFd;

  public levelSectionForm: LevelSectionForm;
  public levelSectionFd: LevelSectionFd;


  public child: TabularData;
  public guardians: FormData[];

  public addressFlags: boolean[] = [false, false];
  public presentIsPermanent: boolean = false;
  public items: {};
  selected: any;
  order: number=1;
  hideSection: boolean = false;

  constructor(private sa: ServiceAgent,private ms:MessageService, private dialog: MatDialog) {
    this.form = StudentForm.getInstance();
    this.fd = new FormData(this.form, this.sa);

    this.levelSectionForm = LevelSectionForm.getInstance();
    this.levelSectionFd = new FormData(this.levelSectionForm, this.sa);
 
    this.guardianForm = GuardianForm.getInstance();
  }

  ngOnInit() {
    
    this.fd = this.form.newFormData(this.sa);
    this.child = this.fd.getChildTable('guardians');
    this.guardians = this.child.childData as FormData[];
    //father and mother data are to be added along with students
    this.addParents();
    this.items = MENU;
    this.selected = "studentDetails";
  }

  save(){
    let dialogRef = this.dialog.open(CtDialogComponent);
    dialogRef.componentInstance.title = 'Save Changes';
    dialogRef.componentInstance.content = 'Are you sure you want to save changes?';
    dialogRef.componentInstance.primary = 'Save';
    const subscribeDialog = dialogRef.componentInstance.save.subscribe((data) => {
      this.dialog.closeAll();
      this.doSave();
    });
  }

  doSave() {
    this.spinner.show('Saving Student Data..........')
    //possible that the user might have changed address fields afer copying
    this.copyAllAddresses();
    console.log(this.fd.getFieldValue('levelSectionId'));
    this.fd.saveAsNew().subscribe({
      next: vo => {
        this.spinner.hide();
        this.ms.showSuccess("Student Added Successfully! ");
        this.parentPanel.navigate("StudentList");
      },
      error: err => {
        this.spinner.hide();
        this.ms.showError(err);
        for(var i=0;i<this.fd.errors.length;i++){
          this.ms.showError(this.fd.errors[i])
        }
        if(this.fd.getFieldsInError().length == 0){
          this.selected = "guardianDetails"
        }
        else{
          this.selected = "studentDetails"
        }
      }
    }); 
}

  private copyAllAddresses() {
    const values = this.getAddress();
    if (this.presentIsPermanent) {
      this.fd.setFieldValues(values);
    }
    for (let i = 0; i < this.addressFlags.length; i++) {
      if (this.addressFlags[i]) {
        this.guardians[i].setFieldValues(values);
      }
    }
  }

  doCancel() {
    let dialogRef = this.dialog.open(CtDialogComponent);
    dialogRef.componentInstance.title = 'Discard Changes';
    dialogRef.componentInstance.content = 'Are you sure you want to discard changes?';
    dialogRef.componentInstance.primary = 'Discard';
    const subscribeDialog = dialogRef.componentInstance.save.subscribe((data) => {
      this.dialog.closeAll();
      this.parentPanel.navigate('StudentList');
    });
    
  }

  /**
   *
   * @param $event emitter from check-box for permenant address
   */

  togglePresentIsPermanent($event) {
    this.presentIsPermanent = $event.checked;
    this.fd.setFieldValues(this.getAddress())
  }

  guardianFlagChanged(idx: number, $event: any) {
    this.addressFlags[idx] = $event.checked;
    this.guardians[idx].setFieldValues(this.getAddress());
  }

  private getAddress(): FieldValues {
    const src = this.fd.extractFields(PRESENT_FIELDS);
    const vals = {};
    for (let i = 0; i < PRESENT_FIELDS.length; i++) {
      vals[ADDRESS_FIELDS[i]] = src[PRESENT_FIELDS[i]];
    }
    return vals;
  }
  /**
   * father and mother are mandatory as of now. T
   */
  addParents() {
    let fd = this.child.appendRow();
    fd.setFieldValue('relationType', 'Father');
    fd.setFieldValue('gender', 'Male');
    fd = this.child.appendRow();
    fd.setFieldValue('relationType', 'Mother');
    fd.setFieldValue('gender', 'Female');
  }

  /**
   * order of the guardians has no consequence.
   * hence no facility to insert. we always append.
   */
  addGuardian() {
    const fd = this.child.appendRow();
    fd.setFieldValue('relationType', "Legal Guardian");
    this.addressFlags.push(false);
  }

  /**
   *
   * @param k 0-based index of the guardina
   */
  removeGuardian(k: number) {
    this.child.removeRow(k);
  }

  /**
   *
   * This function is used to check if section exists in a program
   * */
  getProgramSetion(){
  this.sa.serve("checkProgramSection",{ data: { programId: this.fd.getFieldValue('programId'), levelId: this.fd.getFieldValue('levelId') } }).subscribe({
    next: data => {
      var sections  = data.sections as Vo[];
      if(sections.length != 0){
        this.fd.lists['levelSectionId'] = [];
        sections.forEach(section => {
          this.fd.lists['levelSectionId'].push(
            {'text': section.name as string, 'value': section.levelSectionId as number}
          )
        });
      }
      this.hideSectionField(data);
    },
    error: msg => {
      console.error(msg);
      this.ms.showError('Server returned with an error message. ');
    }
  });
  }

  hideSectionField(data){
    this.hideSection = data['sectionsRequired']
  }
  navigate($event){
    this.selected = $event;
    this.order = this.items[this.selected]['order'];
  }

  next(){
    var ord = this.order + 1;
    Object.keys(this.items).forEach(element => {
      if(this.items[element].order == ord){
        this.selected = element;
        this.order = ord;
        return;
      }
    });
  }

  back(){
    var ord = this.order - 1;
    Object.keys(this.items).forEach(element => {
      if(this.items[element].order == ord){
        this.selected = element;
        this.order = ord;
        return;
      }
    });
  }

  /**
   * Method to set default level section if no sections are required for the program
   */
  setLevelSection(){
    if(!this.hideSection){
    const filter: FilterRequest = {
      conditions: {
        programId:{comp:"=",value:this.fd.getFieldValue('programId')},
        levelId:{comp:"=",value:this.fd.getFieldValue('levelId')}
      },
      sort: { name: "asc" }
    };
    this.levelSectionFd.filter(filter).subscribe(
      data => {
        console.log(data);
        if(data[0] != undefined){
          this.fd.setFieldValue('levelSectionId',data[0].levelSectionId as number);
        }
      },
      msgs => {
        console.error(msgs);
      }
    );
    }
    else{
      this.getProgramSetion();
    }
  }
}

