import { Component, OnInit, ViewChild, Input, AfterViewInit } from "@angular/core";
import { CtEditableTableComponent } from 'src/app/framework-modules/elements/ct-editable-table/component';
import { TableMetaData } from 'src/app/framework-modules/elements/ct-table/component';
import { GroupPanel } from '../../interfaces';
import { SessionComponent } from '../../component';
import { CtSpinner } from 'src/app/framework-modules/elements/ct-spinner/component';
import { PapersInPacketForm, PapersInPacketVo } from 'src/app/framework-modules/formdata/gen/papersInPacketForm';
import { ServiceAgent } from 'src/app/framework-modules/formdata/form/serviceAgent';
import { MessageService } from 'src/app/services/messageService';
import { CtDialogComponent } from 'src/app/framework-modules/elements/ct-dialog/component';
import { MatDialog } from '@angular/material';
import { PanelData } from 'src/app/framework-modules/formdata/form/formData';
import { Vo } from 'src/app/framework-modules/formdata/form/types';

@Component({
  selector: 'valuation-entry',
  templateUrl: "./component.html",
  styleUrls: ["./component.scss"]
})
export class ValuationEditComponent implements OnInit, AfterViewInit {
  @ViewChild("gridTable", { static: false }) gtable: CtEditableTableComponent;
  @ViewChild("spinner", { static: false }) public spinner: CtSpinner;
  @Input() inputData: any;
  @Input() parentPanel: GroupPanel;
  @Input() session: SessionComponent;

  private panelData: PanelData;
  public vo: PapersInPacketVo = {};

  public tableData: TableMetaData;
  public papersExist = false;
  public flag:boolean = false;
  cameFromScreen: any;

  constructor(private sa: ServiceAgent, private ms: MessageService, private dialog: MatDialog) {
    this.panelData = PapersInPacketForm.getInstance().newPanelData(this.sa);
  }

  ngOnInit() {
    this.metaDataInit();
  }

  ngAfterViewInit() {
    this.cameFromScreen = this.inputData.screen;
    this.inputData = this.inputData.data;
    if (this.inputData && this.inputData.offeredSubjectId && this.inputData.packetCode) {
      this.getData(this.inputData);
    } else {
      this.ms.showError('Page invoked with invalid data');
    }
  }
  
  metaDataInit() {
    this.tableData = {
      data: [],
      metaData: {
        column_names: [
          "paperCode",
          "marks"
        ],
        display_names: {
          paperCode: "Paper Code",
          marks: "Marks"
        },
        editable_columns: [
          "marks"
        ],
        // error: "Invalid Marks",
        validations: {
          "marks": {
            maxValue: 100,
            minValue: 0,
            pattern: "^[0-9][0-9]?$|^100$"
          },
        }
      }
    };
  }

  private getData(data) {
    this.spinner.show('Getting papers ..');
    this.panelData.fetchFor(data).subscribe({
      next: data => {
        this.vo = data;
        if(this.vo.valuationType == 2){
          this.tableData.metaData.column_names.splice(1, 0, "mandatory");
          this.tableData.metaData.display_names["mandatory"] = "Mandatory?";
          (this.vo.papers as unknown as any[]).forEach(element =>{
            element.isMandatory ? element["mandatory"] = "2nd Valuation" : element["mandatory"] = "Moderation";
          });
        }
        const list = data.papers as Vo[];
        if (!list || !list.length) {
          this.spinner.hide();
          this.papersExist = false;
          this.ms.showError('No papers for this packet');
          this.tableData.data = [];
          this.gtable.update(this.spinner);
        } else {
          this.papersExist = true;
          console.log(list.length + ' papers received');
          list.forEach(element => {
            if(element.marks == -1){
              element.marks = "" 
            }
          });
          this.tableData.data = list;
          this.gtable.update(this.spinner);
        }
      },

      error: msgs => {
        this.spinner.hide();
        this.ms.showError('Server returned with errors..');
      }
    });
  }

  private saveData() {
    this.spinner.show('Saving marks..');
    if(this.vo.valuationType == 2){
      this.flag = false;
      this.panelData.childTabularData.get("papers").extractAll().forEach(element =>{
        if(element.isMandatory && element.marks == ""){
          this.ms.showInfo(element.paperCode + " is marked mandatory. Invalid marks entered. Considering student marks as 0");
          element.marks = 0;
          this.flag = true;
        }
        if(!element.isMandatory && element.marks == ""){
          element.marks = -1;
        }
      });
    }
    else{
      this.panelData.childTabularData.get("papers").extractAll().forEach(element =>{
        if(element.marks == ""){
          element.marks = -1;
        }
      });
    }
    this.panelData.save().subscribe({
      next: data => {
        this.spinner.hide();
        this.ms.showSuccess('Marks saved');
        this.goBack();
      },

      error: msgs => {
        this.spinner.hide();
        this.panelData.childTabularData.get("papers").extractAll().forEach(element =>{
          if(element.marks == -1){
            element.marks = "";
          }
        });
        this.ms.showError('Server returned with errors..');
      }
    });
  }
  /**
   * Function is called when the user clicks save button.
   * Saves the assigned evaluators to a asubject.
   */
  save() {

    if(this.vo.valuationType == 2){
      this.flag = false;
      this.panelData.childTabularData.get("papers").extractAll().forEach(element =>{
        if(element.isMandatory && element.marks == ""){
          this.ms.showInfo(element.paperCode + " is marked mandatory. Marks not entered");
          this.flag = true;
        }
      });
    }
    if(this.flag){
      var dialogMessage = "Considering student marks as 0. Are you sure you want to continue" 
    }
    else{
      dialogMessage = "Are you sure you want to save changes?"
    }
    let dialogRef = this.dialog.open(CtDialogComponent);
    dialogRef.componentInstance.title = 'Save Changes';
    dialogRef.componentInstance.content = dialogMessage;
    dialogRef.componentInstance.primary = 'Save';
    const subscribeDialog = dialogRef.componentInstance.save.subscribe((data) => {
      this.saveData();
      this.dialog.closeAll();
    });
  }

  /**
   * Function is called when the user clicks cancel button.
   * Goes back to assign Evaluators list screen
   */
  cancel() {
    let dialogRef = this.dialog.open(CtDialogComponent);
    dialogRef.componentInstance.title = 'Discard Changes';
    dialogRef.componentInstance.content = 'Are you sure you want to discard changes?';
    dialogRef.componentInstance.primary = 'Discard';
    const subscribeDialog = dialogRef.componentInstance.save.subscribe((data) => {
      this.dialog.closeAll();
      this.goBack();
    });
  }

  private goBack() {
    this.parentPanel.navigate(this.cameFromScreen);
  }
}