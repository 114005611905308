
import { Form , Field, ChildForm } from '../form/form';
import { FormData } from '../form/formData';
import { SelectOption, Vo } from '../form/types';
import { Validators } from '@angular/forms'
import { ServiceAgent} from '../form/serviceAgent';

export class LoginForm extends Form {
	private static _instance = new LoginForm();
	userName:Field = {
		name:'userName'
		,controlType: 'Input'
		,label: 'Username'
		,isRequired: true
		,valueType: 0
		,errorId: 'invalidText'
		,maxLength: 1000
	};
	password:Field = {
		name:'password'
		,controlType: 'Password'
		,label: 'Password'
		,isRequired: true
		,valueType: 0
		,errorId: 'invalidText'
		,maxLength: 1000
	};

	public static getInstance(): LoginForm {
		return LoginForm._instance;
	}

	constructor() {
		super();
		this.serveGuests = true;
		this.fields = new Map();
		this.controls = new Map();
		this.controls.set('userName', [Validators.required, Validators.maxLength(1000)]);
		this.fields.set('userName', this.userName);
		this.controls.set('password', [Validators.required, Validators.maxLength(1000)]);
		this.fields.set('password', this.password);
		this.opsAllowed = {update: true};
	}

	public getName(): string {
		 return 'login';
	}
}


export class LoginFd extends FormData {
	constructor(form: LoginForm, sa: ServiceAgent) {
		super(form, sa);
	}

	setFieldValue(name: 'userName' | 'password', value: string | number | boolean | null ): void {
		super.setFieldValue(name, value);
	}

	getFieldValue(name: 'userName' | 'password' ): string | number | boolean | null {
		return super.getFieldValue(name);
	}
}


export interface LoginVo extends Vo {
	userName?: string, 
	password?: string
}
