
import { Form , Field, ChildForm } from '../form/form';
import { FormData } from '../form/formData';
import { SelectOption, Vo } from '../form/types';
import { Validators } from '@angular/forms'
import { ServiceAgent} from '../form/serviceAgent';

export class CodedPaperForm extends Form {
	private static _instance = new CodedPaperForm();

	public static getInstance(): CodedPaperForm {
		return CodedPaperForm._instance;
	}

	constructor() {
		super();
		this.fields = new Map();
		this.controls = new Map();
		this.keyFields = ["paperCode", "instituteId"];
	}

	public getName(): string {
		 return 'codedPaper';
	}
}


export class CodedPaperFd extends FormData {
	constructor(form: CodedPaperForm, sa: ServiceAgent) {
		super(form, sa);
	}
	/**  this form has no editable fields. data nust be accessed as Vo and not through fd **/
}


export interface CodedPaperVo extends Vo {
	marks2?: number, 
	effectiveMarks?: number, 
	marks3?: number, 
	valuationRound?: number, 
	paperCode?: string, 
	subjectRegisterId?: number, 
	isMakeup?: boolean, 
	marks1?: number, 
	packetCode?: string, 
	requires2?: boolean, 
	studentId?: number, 
	requires3?: boolean, 
	offeredSubjectId?: number, 
	valuationCompleted?: boolean, 
	instituteId?: number
}
