import { MenuService } from 'src/app/services/menuService';
import { Component, OnInit, Input, ViewChild, ViewContainerRef, Type, ComponentFactoryResolver } from "@angular/core";
import { DynamicPanel, LandingPanel, GroupPanel } from '../interfaces'
import { SessionComponent, SecondaryMenu } from '../component';
import { ConsolidatedAttendanceListComponent } from './consolidatedAttendance-list/component';
import { ConsolidatedAttendanceEditComponent } from './consolidatedAttendance-edit/component';
import { EligibilityListComponent } from './eligibility-list/component';
import { SubjectEligiblityComponent } from './subject-eligibility/component';
import { MarksEntryListComponent } from './marksEntry-list/component';
import { CondoneAttendanceListComponent } from './condone-attendance-list/component';
import { CondoneAttendanceComponent} from './condone-attendance/component';
import { HallTicketComponent } from './hallTicket/component';
import { MarksEntryEditComponent } from './marksEntry-edit/component';
import { ConsolidatedAttendanceUpdateComponent } from './consolidatedAttendance-update/component';
import { CieCalculationListComponent } from './cie-calculation-list/component';
import { MarksEntryBulkUpdateComponent } from './marksEntry-update-bulk/component';
import { MarksEntryUpdateComponent } from './marksEntry-update/component';
import { HallTicketViewComponent } from './hallticket-view/component';
import { ConsolidatedAttendanceViewComponent } from './consolidatedAttendance-view/component';
import { MarksEntryViewComponent } from './marksEntry-view/component';
import { MessageService } from 'src/app/services/messageService';
import { ClientContext } from 'src/app/framework-modules/formdata/form/clientContext';
import { SubjectCieCalculationListComponent } from './subject-cie-calculation-list/component';
import { WithdrawSubjectListComponent } from './withdraw-subject-list/component';
import { WithdrawSubjectEditComponent } from './withdraw-subject-edit/component';
import { CieSubmissionComponent } from './cie-submissions/component';
import { ppNpGradeListComponent } from './ppNpGrade-list/component';
import { ppNpGradeEditComponent } from './ppNpGrade-update/component';

/**
 Const to map the screen-component to screen-name to rended the facotry
 */
const MENU:SecondaryMenu= {
  
  MarksEntryList: {
    comp: MarksEntryListComponent,
    title: "Marks Entry",
    sideNav: true,
    name: "Marks Entry",
    pageId: "D19",
    displayonSidenav: true,
    mainNav: true,
    order:1,
  },
  ConsolidatedAttendanceList: {
    comp: ConsolidatedAttendanceListComponent,
    title: "Attendance",
    sideNav: true,
    pageId: "D16",
    displayonSidenav: true,
    name: "Attendance Entry",
    mainNav: true,
    order:3
  },
  ConsolidatedAttendanceEdit: {
    comp: ConsolidatedAttendanceEditComponent,
    title: "Allocate Attendance for students",
    sideNav: false,
    pageId: "D17",
    name: "Attendance Entry",
    displayonSidenav: false,
    mainNav: false,

  },
  EligibilityList: {
    comp: EligibilityListComponent,
    title: "List of Eligible Students",
    sideNav: true,
    pageId: "D18",
    displayonSidenav: true,
    name: "Eligibility List",
    mainNav: true,
    order:7,
  },
  SubjectEligibility: {
    comp: SubjectEligiblityComponent,
    title: "Subject Eligiblity",
    sideNav: true,
    pageId: "D113",
    displayonSidenav: true,
    name: "Subject Eligibility",
    mainNav: true,
    order:12,
  },
  CondoneAttendanceList: {
    name: "Condone Attendance",
    comp: CondoneAttendanceListComponent,
    title: "List of students not eligible",
    sideNav: true,
    pageId: "D20",
    displayonSidenav: true,
    mainNav: true,
    order:8
  },
  CondoneAttendanceEdit :{
    name: "Condone Attendance Edit",
    comp: CondoneAttendanceComponent,
    title: "Edit Condone Attendance",
    sideNav: false,
    pageId:"D21",    
    displayonSidenav: false,
    mainNav:false,
  },
  // ExamAttendance:{comp:ExamAttendanceComponent,title:"Exam Attendance",sideNav:true},
  HallTicketList: {
    comp: HallTicketComponent,
    title: "Generate Hall Tickets",
    sideNav: true,
    pageId: "D22",
    displayonSidenav: true,
    name: "Hall Ticket",
    mainNav: true,
    order:9,
  },
  MarksEntryEdit: {
    name: "Marks Enrty",
    comp: MarksEntryEditComponent,
    title: "Edit Marks",
    sideNav: false,
    pageId: "D23",
    displayonSidenav: false,
    mainNav: false
  },
  ConsolidatedAttendanceUpdate:{
    name:"Attendance Update",
    comp:ConsolidatedAttendanceUpdateComponent,
    sideNav:false,
    mainNav:false,
    pageId:"D24",
    title:"Edit Attenance",
    displayonSidenav:false
  },
  CieCalculationList:{
    name:"CIE Calculation",
    comp:CieCalculationListComponent,
    sideNav:true,
    mainNav:true,
    pageId:"D25",
    title:"Calculate CIE",
    displayonSidenav:true,
    order:5
  },
  MarksEntryBulkUpdate:{
    name:"Marks Entry Bulk",
    comp:MarksEntryBulkUpdateComponent,
    sideNav:false,
    mainNav:false,
    pageId:"D26",
    title:"Bulk Marks Entry",
    displayonSidenav:false
  },
  MarksEntryUpdate:{
    name:"Marks Update",
    comp:MarksEntryUpdateComponent,
    mainNav:false,
    sideNav:false,
    pageId:"D27",
    title:"Edit Marks",
    displayonSidenav:false
  },
  HallTicketView:{
    name:"Hall Ticket View",
    comp:HallTicketViewComponent,
    mainNav:true,
    sideNav:false,
    pageId:"D56",
    title:"Print Hall Ticket",
    displayonSidenav:false
  },
  MarksEntryView:{
    name:"Marks View",
    comp:MarksEntryViewComponent,
    mainNav:true,
    sideNav:true,
    pageId:"D66",
    title:"View Marks of students",
    displayonSidenav:true,
    order:2
  },
  ConsolidatedAttendanceView:{
    name:"Attendance View",
    comp:ConsolidatedAttendanceViewComponent,
    mainNav:true,
    sideNav:true,
    pageId:"D67",
    title:"View Attendance of Students",
    displayonSidenav:true,
    order:4
  },
  SubjectCieCalculationList:{
    name: "Subject CIE",
    comp: SubjectCieCalculationListComponent,
    mainNav: true,
    sideNav: true,
    pageId: "D68",
    title:"Calculate CIE of Subject",
    displayonSidenav: true,
    order:6,
  },
  WithdrawSubjectList:{
    name: "Withdraw Subject",
    comp: WithdrawSubjectListComponent,
    mainNav: true,
    sideNav: true,
    pageId: "D83",
    title: "List of students who have Withdrawn",
    displayonSidenav: true,
    order:10
  },
  WithdrawSubjectEdit:{
    name: "Withdraw Subject",
    comp: WithdrawSubjectEditComponent,
    mainNav: false,
    sideNav: false,
    pageId: "D84",
    title: "Withdraw students from a subject",
    displayonSidenav: false
  },
  CieSubmissions:{
    name: "CIE Submissions",
    comp: CieSubmissionComponent,
    mainNav: true,
    sideNav: true,
    pageId: "D88",
    title: "CIE Submissions",
    displayonSidenav: true,
    order:11
  },
  PpNpGradeList:{
    name: "PP/NP Grade",
    comp: ppNpGradeListComponent,
    mainNav: true,
    sideNav: true,
    pageId: "D153",
    title: "PP/NP Grade",
    displayonSidenav: true,
    order:13
  },
  PpNpGradeEdit:{
    name: "PP/NP Grade",
    comp: ppNpGradeEditComponent,
    mainNav: false,
    sideNav: false,
    pageId: "D154",
    title: "PP/NP Grade",
    displayonSidenav: false
  }
};

@Component({
  selector: "Sessional",
  templateUrl: "./component.html",
  styleUrls: ["./component.scss"]
})
export class SessionalComponent implements OnInit, GroupPanel {
  @Input() inputData: any;
  @Input() parentPanel: SessionComponent;
  menu: { [key: string]: Type<DynamicPanel> };
  sidenav: boolean = false;
  displayNames: {}
  cardHeader: any;
  public items: {};
  public defaultSelected: string;
  pScreens: string[];
  screens: string[];
  defaultComponent: string;
  
  /**
    container for the child page loaded from the seconday navbar
   */

  @ViewChild("secondaryContainer", { static: true, read: ViewContainerRef }) container: ViewContainerRef;

  constructor(private componentFactoryResolver: ComponentFactoryResolver, private mns: MenuService, private ms: MessageService, private cc: ClientContext) {
    this.pScreens = this.mns.getPermittedScreens();
    this.screens = this.mns.getScreens();
      Object.keys(MENU).forEach( key => {
      if(this.screens.includes(MENU[key].pageId) && this.defaultSelected == undefined){
        this.defaultSelected = MENU[key].name;
        this.cardHeader = MENU[key].title;
        this.defaultComponent = key;
      }
    })
  }

  ngOnInit() {
    this.navigate(this.defaultComponent, {});
    this.items = MENU
  }

  /**
   *
   * @param compName is the name of the component to navigate to
   * @param data is for any related data needed
   */

  public navigate(compName: string, data: any): void {
    console.log('Navigating to ' + compName + ' with data ', data);
    const item = MENU[compName];
    if (!item) {
      console.error(compName + ' is not a valid manu item. Navigation failed.');
      return;
    }

    if (!item.name) {
      console.error('Navigation abandoned because name is missing for this item');
      return;
    }
    if(!this.cc.isPageValid(item.pageId)){
      this.ms.showInfo('Navigation Error: ' + compName + ' is not authorized for this user.');
      return;
    }

    this.parentPanel.toggleMainNav(item.mainNav);
    this.sidenav = item.sideNav;
    this.defaultSelected = item.name;
    this.cardHeader = item.title;
    this.container.clear();
    const factory = this.componentFactoryResolver.resolveComponentFactory(item.comp);
    const c = this.container.createComponent(factory);
    c.instance.inputData = data;
    c.instance.parentPanel = this;
    c.changeDetectorRef.detectChanges();
    console.log('Input data set to ', data );
  }
  fullScreenMode(){
    this.sidenav = false;
    this.parentPanel.hideMainNav();
  }

  halfScreenMode(){
    this.sidenav = true;
    this.parentPanel.showMainNav();
  }
}
