import { Component, OnInit, ViewChild, Input, AfterViewInit } from "@angular/core";
import { GroupPanel } from '../../interfaces';
import { ServiceAgent } from 'src/app/framework-modules/formdata/form/serviceAgent';
import { CtTableComponent, TableMetaData } from '../../../../framework-modules/elements/ct-table/component'
import { SessionComponent } from '../../component';
import { FilterRequest, Vo } from 'src/app/framework-modules/formdata/form/types';
import { MessageService } from 'src/app/services/messageService';
import { ExcelDownloadService } from 'src/app/services/excelDownloadService';
import { PdcListTempUgForm,PdcListTempUgFd } from 'src/app/framework-modules/formdata/gen/pdcListTempUgForm';
import { CtSpinner } from 'src/app/framework-modules/elements/ct-spinner/component';

@Component({
  selector: "withdraw-subject-list",
  templateUrl: "./component.html",
  styleUrls: ["./component.scss"]
})

export class pdcListComponent implements OnInit, AfterViewInit {
  @ViewChild("gridTable", { static: false }) gtable: CtTableComponent;
  @ViewChild("spinner", {static:false}) spinner: CtSpinner;
  @Input() inputData: any;
  @Input() parentPanel: GroupPanel;
  @Input() session: SessionComponent;
  /**
 * for getting students-list
 */
  form: PdcListTempUgForm;
  fd: PdcListTempUgFd;
  tableData: TableMetaData;

  constructor(private sa: ServiceAgent, private ms: MessageService, private ds: ExcelDownloadService) {
    this.form = PdcListTempUgForm.getInstance();
    this.fd = PdcListTempUgForm.getInstance().newFormData(this.sa);
  }

  ngOnInit() {
    this.initTable();
  }

  ngAfterViewInit() {
    this.gtable.update();
    this.getData();
  }

  private initTable() {
    this.tableData = {
      data: [],
      metaData: {
        column_names: ["date","usn","pdcNo","studentName","programName","departmentName","cgpa"],
        display_names: {
          usn: "USN",
          studentName: "Name",
          pdcNo: "Pdc No.",
          programName: "Program",
          departmentName: "Department",
          cgpa: "CGPA",
          date: "Date"
        },
        edit: true,
        buttonName: "Edit",
        search: true,
        selectAttr: "isSelected",
        managerFunction: "Download PDC",
        itemName: "PDC",
        placeholder: "Search by Student Name, Student USN",
        
      }
    };
  }


  getData(){
    const filter: FilterRequest = {
      conditions: {},
      sort: { studentName: "asc" }
    };
    this.fd.filter(filter).subscribe(
      data => {
        this.tableData.data = data;
        this.gtable.update(this.spinner);
      },
      msgs => {
        console.error(msgs);
      }
    )

  }

  download(){
    if(this.tableData.data.some(x => x.isSelected == true)){
      let data: Vo[] = []
      this.tableData.data.forEach(element => {
        if(element.isSelected == true){
          data.push(element)
        }
      });
      this.ds.getPdc(data);
    }else{
      this.ms.showInfo("No PDC is selected, Please select atleast one");
    }
  }

  upload(id: string) {
    this.parentPanel.navigate('pdcUpdate',"")
  }

  editPDC(idx) {
    this.parentPanel.navigate('pdcEdit', this.tableData.data[idx]);
  }
}