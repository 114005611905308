
import { Form , Field, ChildForm } from '../form/form';
import { FormData } from '../form/formData';
import { SelectOption, Vo } from '../form/types';
import { Validators } from '@angular/forms'
import { ServiceAgent} from '../form/serviceAgent';

export class SubjectSectionTestStatisticsForm extends Form {
	private static _instance = new SubjectSectionTestStatisticsForm();
	subjectSectionId:Field = {
		name:'subjectSectionId'
		,controlType: 'Hidden'
		,label: 'subjectSectionId'
		,isRequired: true
		,valueType: 1
		,errorId: 'invalidId'
		,maxValue: 9999999999999
	};

	public static getInstance(): SubjectSectionTestStatisticsForm {
		return SubjectSectionTestStatisticsForm._instance;
	}

	constructor() {
		super();
		this.fields = new Map();
		this.controls = new Map();
		this.controls.set('subjectSectionId', [Validators.required, Validators.max(9999999999999)]);
		this.fields.set('subjectSectionId', this.subjectSectionId);
		this.opsAllowed = {get: true, filter: true};
	}

	public getName(): string {
		 return 'subjectSectionTestStatistics';
	}
}


export class SubjectSectionTestStatisticsFd extends FormData {
	constructor(form: SubjectSectionTestStatisticsForm, sa: ServiceAgent) {
		super(form, sa);
	}

	setFieldValue(name: 'subjectSectionId', value: string | number | boolean | null ): void {
		super.setFieldValue(name, value);
	}

	getFieldValue(name: 'subjectSectionId' ): string | number | boolean | null {
		return super.getFieldValue(name);
	}
}


export interface SubjectSectionTestStatisticsVo extends Vo {
	name?: string, 
	average?: number, 
	instituteId?: number, 
	seqNo?: number, 
	subjectSectionId?: number
}
