
import { Form , Field, ChildForm } from '../form/form';
import { FormData } from '../form/formData';
import { SelectOption, Vo } from '../form/types';
import { Validators } from '@angular/forms'
import { ServiceAgent} from '../form/serviceAgent';

export class PreviousSemesterGPADetailForm extends Form {
	private static _instance = new PreviousSemesterGPADetailForm();
	studentId:Field = {
		name:'studentId'
		,controlType: 'Hidden'
		,label: 'studentId'
		,isRequired: true
		,valueType: 1
		,errorId: 'invalidId'
		,maxValue: 9999999999999
	};
	levelSeq:Field = {
		name:'levelSeq'
		,controlType: 'Hidden'
		,label: 'levelSeq'
		,isRequired: true
		,valueType: 1
		,errorId: 'invalidId'
		,maxValue: 9999999999999
	};
	totalSubjectCredit:Field = {
		name:'totalSubjectCredit'
		,controlType: 'Input'
		,label: 'Total Subject Credit'
		,isRequired: true
		,valueType: 2
		,defaultValue: 0
		,errorId: 'invalidDecimal'
		,nbrFractions: 4
		,maxValue: 1000
	};
	creditPoints:Field = {
		name:'creditPoints'
		,controlType: 'Input'
		,label: 'Credit Points'
		,isRequired: true
		,valueType: 2
		,defaultValue: 0
		,errorId: 'invalidDecimal'
		,nbrFractions: 4
		,maxValue: 1000
	};

	public static getInstance(): PreviousSemesterGPADetailForm {
		return PreviousSemesterGPADetailForm._instance;
	}

	constructor() {
		super();
		this.fields = new Map();
		this.controls = new Map();
		this.controls.set('studentId', [Validators.required, Validators.max(9999999999999)]);
		this.fields.set('studentId', this.studentId);
		this.controls.set('levelSeq', [Validators.required, Validators.max(9999999999999)]);
		this.fields.set('levelSeq', this.levelSeq);
		this.controls.set('totalSubjectCredit', [Validators.required, Validators.max(1000)]);
		this.fields.set('totalSubjectCredit', this.totalSubjectCredit);
		this.controls.set('creditPoints', [Validators.required, Validators.max(1000)]);
		this.fields.set('creditPoints', this.creditPoints);
		this.opsAllowed = {filter: true, get: true};
		this.keyFields = ["studentId"];
	}

	public getName(): string {
		 return 'previousSemesterGPADetail';
	}
}


export class PreviousSemesterGPADetailFd extends FormData {
	constructor(form: PreviousSemesterGPADetailForm, sa: ServiceAgent) {
		super(form, sa);
	}

	setFieldValue(name: 'studentId' | 'levelSeq' | 'totalSubjectCredit' | 'creditPoints', value: string | number | boolean | null ): void {
		super.setFieldValue(name, value);
	}

	getFieldValue(name: 'studentId' | 'levelSeq' | 'totalSubjectCredit' | 'creditPoints' ): string | number | boolean | null {
		return super.getFieldValue(name);
	}
}


export interface PreviousSemesterGPADetailVo extends Vo {
	studentId?: number, 
	totalSubjectCredit?: number, 
	creditPoints?: number, 
	levelId?: number, 
	levelName?: string, 
	levelSeq?: number, 
	totalMaxGradePoints?: number
}
