import { Component, OnInit, ViewChild, Input } from "@angular/core";
import { DynamicPanel, GroupPanel } from '../../interfaces';
import { ServiceAgent } from 'src/app/framework-modules/formdata/form/serviceAgent';
import { CtTableComponent, TableMetaData } from '../../../../framework-modules/elements/ct-table/component'
import { FilterRequest } from 'src/app/framework-modules/formdata/form/types';
import { SessionComponent } from '../../component';
import { SubjectDetailFd, SubjectDetailForm } from 'src/app/framework-modules/formdata/gen/subjectDetailForm';
import { ClientContext } from 'src/app/framework-modules/formdata/form/clientContext';

@Component({
  selector: "subject-list",
  templateUrl: "./component.html",
  styleUrls: ["./component.scss"]
})

export class OfferSubjectListComponent implements DynamicPanel, OnInit {
  @ViewChild("gridTable", { static: false }) gtable: CtTableComponent;
  @Input() inputData: any;
  @Input() parentPanel: GroupPanel;
  @Input() session: SessionComponent;
  
  public tableData: TableMetaData;
  public from: SubjectDetailForm;
  public fd: SubjectDetailFd;
  
  constructor(private sa: ServiceAgent, private cc: ClientContext) {
    this.from = SubjectDetailForm.getInstance();
    this.fd = this.from.newFormData(this.sa);
  }

  ngOnInit() {
    this.metaDataInit();
    this.filter(this.cc.getcurrentSeasonId());
  }

  private metaDataInit() {
    this.tableData = {
      data: [] = [],
      metaData: {
        column_names: [
          "name",
          "code",
          "departmentName",
          "levelName",
          "offered"
        ],
        display_names: {
          "name": "Subject",
          "code": "Code",
          "departmentName":"Offered By",
          "levelName": "Level",
          "offered":"Offered"
        },
        styleAttribute: {
          "offered": [
            {comp:'=',style:{'color':'green','font-weight':600},value:'Offered'},
            {comp:'=',style:{'color':'red','font-weight':600},value:'Not Offered'}
          ]
        },
        edit: true,
        search: true,
        placeholder: "Search By Subject, Code, Department or Sections",
        buttonName: "OFFER"
      }
    };
  }

  private filter(seasonId) {
    /**
     * initial filter conditions
     */
    const filter: FilterRequest = {
      conditions: {
        seasonId: {comp:'=',value:seasonId}
      },
      sort: { name: "asc" }
    };
    const obs = this.fd.filter(filter);

    obs.subscribe(
      data => {
        data.forEach(row=>{
          row.offered = row.isOffered ? 'Offered' : 'Not Offered';
        })
        this.tableData.data = data;
        this.gtable.update();
      },
      msgs => {
        console.error(msgs);
      }
    );
  }

  edit($event) {
    this.parentPanel.navigate('OfferSubjectEdit', this.tableData.data[$event]);
  }
}