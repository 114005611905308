
import { Form , Field, ChildForm } from '../form/form';
import { FormData } from '../form/formData';
import { SelectOption, Vo } from '../form/types';
import { Validators } from '@angular/forms'
import { ServiceAgent} from '../form/serviceAgent';
import { OfferableSectionForm, OfferableSectionVo } from './offerableSectionForm';

export class OfferedSubjectDetailForm extends Form {
	private static _instance = new OfferedSubjectDetailForm();
	offeredSubjectId:Field = {
		name:'offeredSubjectId'
		,controlType: 'Hidden'
		,label: 'offeredSubjectId'
		,valueType: 1
		,defaultValue: 0
		,errorId: 'invalidId'
		,maxValue: 9999999999999
	};
	seasonId:Field = {
		name:'seasonId'
		,controlType: 'Hidden'
		,label: 'seasonId'
		,isRequired: true
		,valueType: 1
		,errorId: 'invalidId'
		,maxValue: 9999999999999
	};
	subjectId:Field = {
		name:'subjectId'
		,controlType: 'Hidden'
		,label: 'subjectId'
		,isRequired: true
		,valueType: 1
		,errorId: 'invalidId'
		,maxValue: 9999999999999
	};
	name:Field = {
		name:'name'
		,controlType: 'Dropdown'
		,label: 'Degree'
		,valueType: 0
		,errorId: 'invalidName'
		,maxLength: 150
	};
	code:Field = {
		name:'code'
		,controlType: 'Dropdown'
		,label: 'Degree'
		,valueType: 0
		,errorId: 'invalidText'
		,maxLength: 1000
	};
	degreeId:Field = {
		name:'degreeId'
		,controlType: 'Dropdown'
		,label: 'Degree'
		,isRequired: true
		,listName: 'degreeList'
		,valueType: 1
		,defaultValue: 0
		,errorId: 'invalidId'
		,maxValue: 9999999999999
	};
	levelId:Field = {
		name:'levelId'
		,controlType: 'Dropdown'
		,label: 'Level'
		,isRequired: true
		,listName: 'levelList'
		,listKey: 'degreeId'
		,valueType: 1
		,defaultValue: 0
		,errorId: 'invalidId'
		,maxValue: 9999999999999
	};
	nbrEnrolled:Field = {
		name:'nbrEnrolled'
		,controlType: 'Hidden'
		,label: 'nbrEnrolled'
		,valueType: 1
		,defaultValue: 0
		,errorId: 'invalidInteger'
		,maxValue: 9999999999999
	};
	canBeWithdrawn:Field = {
		name:'canBeWithdrawn'
		,controlType: 'Hidden'
		,label: 'canBeWithdrawn'
		,valueType: 3
		,defaultValue: true
		,errorId: 'invalidBool'
	};
	isOffered:Field = {
		name:'isOffered'
		,controlType: 'Checkbox'
		,label: 'Is Offered'
		,valueType: 3
		,defaultValue: false
		,errorId: 'invalidBool'
	};
	autoEnroll:Field = {
		name:'autoEnroll'
		,controlType: 'Hidden'
		,label: 'autoEnroll'
		,valueType: 3
		,errorId: 'invalidBool'
	};
	departmentName:Field = {
		name:'departmentName'
		,controlType: 'Output'
		,label: 'Department Name'
		,valueType: 0
		,errorId: 'invalidText'
		,maxLength: 1000
	};

	sections: ChildForm = {
		name:'sections',
		form:OfferableSectionForm.getInstance(),
		isEditable:false,
		isTabular:true,
		label:'',
		minRows:0,
		maxRows:0,
		errorId:null
	};

	public static getInstance(): OfferedSubjectDetailForm {
		return OfferedSubjectDetailForm._instance;
	}

	constructor() {
		super();
		this.fields = new Map();
		this.controls = new Map();
		this.controls.set('offeredSubjectId', [Validators.max(9999999999999)]);
		this.fields.set('offeredSubjectId', this.offeredSubjectId);
		this.controls.set('seasonId', [Validators.required, Validators.max(9999999999999)]);
		this.fields.set('seasonId', this.seasonId);
		this.controls.set('subjectId', [Validators.required, Validators.max(9999999999999)]);
		this.fields.set('subjectId', this.subjectId);
		this.controls.set('name', [Validators.maxLength(150)]);
		this.fields.set('name', this.name);
		this.controls.set('code', [Validators.maxLength(1000)]);
		this.fields.set('code', this.code);
		this.controls.set('degreeId', [Validators.required, Validators.max(9999999999999)]);
		this.fields.set('degreeId', this.degreeId);
		this.controls.set('levelId', [Validators.required, Validators.max(9999999999999)]);
		this.fields.set('levelId', this.levelId);
		this.controls.set('nbrEnrolled', [Validators.max(9999999999999)]);
		this.fields.set('nbrEnrolled', this.nbrEnrolled);
		this.controls.set('canBeWithdrawn', []);
		this.fields.set('canBeWithdrawn', this.canBeWithdrawn);
		this.controls.set('isOffered', []);
		this.fields.set('isOffered', this.isOffered);
		this.controls.set('autoEnroll', []);
		this.fields.set('autoEnroll', this.autoEnroll);
		this.controls.set('departmentName', [Validators.maxLength(1000)]);
		this.fields.set('departmentName', this.departmentName);

		this.childForms = new Map();
		this.childForms.set('sections', this.sections);
		this.opsAllowed = {delete: true, get: true, update: true, filter: true};
		this.listFields = ['degreeId','levelId'];
		this.keyFields = ["seasonId", "subjectId"];
	}

	public getName(): string {
		 return 'offeredSubjectDetail';
	}
}


export class OfferedSubjectDetailFd extends FormData {
	constructor(form: OfferedSubjectDetailForm, sa: ServiceAgent) {
		super(form, sa);
	}

	setFieldValue(name: 'offeredSubjectId' | 'seasonId' | 'subjectId' | 'name' | 'code' | 'degreeId' | 'levelId' | 'nbrEnrolled' | 'canBeWithdrawn' | 'isOffered' | 'autoEnroll' | 'departmentName', value: string | number | boolean | null ): void {
		super.setFieldValue(name, value);
	}

	getFieldValue(name: 'offeredSubjectId' | 'seasonId' | 'subjectId' | 'name' | 'code' | 'degreeId' | 'levelId' | 'nbrEnrolled' | 'canBeWithdrawn' | 'isOffered' | 'autoEnroll' | 'departmentName' ): string | number | boolean | null {
		return super.getFieldValue(name);
	}
}


export interface OfferedSubjectDetailVo extends Vo {
	departmentName?: string, 
	code?: string, 
	degreeId?: number, 
	attendanceFrozen?: boolean, 
	levelName?: string, 
	cieFrozen?: boolean, 
	degreeName?: string, 
	autoEnroll?: boolean, 
	subjectId?: number, 
	parentId?: number, 
	offeredSubjectId?: number, 
	isOffered?: boolean, 
	nbrEnrolled?: number, 
	seasonId?: number, 
	levelId?: number, 
	name?: string, 
	isIntegrated?: boolean, 
	canBeWithdrawn?: boolean, 
	isChild?: boolean, 
	sections?: OfferableSectionVo
}
