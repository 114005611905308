import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { CtEditPanel } from './ct-edit-panel/component';
import { CtGroupPanelComponent } from './ct-group-panel/component';
import { CtDataPanel } from './ct-data-panel/component';
import { CtDataPanel1 } from './ct-data-panel-1/component';
import {
  /**
   * Material Imports
   */
  MatFormFieldModule,
  MatIconModule,
  MatInputModule,
  MatSidenavModule,
  MatButtonModule,
  MatChipsModule,
  MatSortModule,
  MatTableModule,
  MatPaginatorModule,
  MatCheckboxModule,
  MatTooltipModule,
  MatCardModule,
  MatSelectModule,
  MatGridListModule,

} from '@angular/material';

@NgModule({
  declarations: [
    /**
     * Material Component Declarations
     */

    /**
     * Banyantree Compoenent Declarations
     */
    CtEditPanel,
    CtGroupPanelComponent,
    CtDataPanel,
    CtDataPanel1,

  ],
  imports: [
    /**
     * Material Component Imports
     */
    MatFormFieldModule,
    MatInputModule,
    MatIconModule,
    MatSidenavModule,
    MatButtonModule,
    MatChipsModule,
    MatSortModule,
    MatTableModule,
    MatPaginatorModule,
    MatCheckboxModule,
    MatTooltipModule,
    MatCardModule,
    MatSelectModule,
    MatGridListModule,
    /**
     * Banyantree Compoenent  Imports
     */
    /**
     * Miscellaneous Imports
     */
    ReactiveFormsModule,
    CommonModule
  ],
  bootstrap: [],
  exports: [
    /**
     * Material Component Exports
     */
    MatFormFieldModule,
    MatInputModule,
    MatIconModule,
    MatSidenavModule,
    MatButtonModule,
    MatChipsModule,
    MatSortModule,
    MatTableModule,
    MatPaginatorModule,
    MatCheckboxModule,
    MatTooltipModule,
    MatCardModule,
    MatSelectModule,
    MatGridListModule,
    /**
     * Banyantree Compoenents Exports
     */
    CtEditPanel,
    CtGroupPanelComponent,
    CtDataPanel,
    CtDataPanel1,
    /**
     * Miscellaneous Exports
     */
    ReactiveFormsModule,
    CommonModule
  ]
  , entryComponents: []
})
export class CardElementsModule {
  constructor() {
  }
}
