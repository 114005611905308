
import { Form , Field, ChildForm } from '../form/form';
import { FormData } from '../form/formData';
import { SelectOption, Vo } from '../form/types';
import { Validators } from '@angular/forms'
import { ServiceAgent} from '../form/serviceAgent';
import { EnrollableStudentForm, EnrollableStudentVo } from './enrollableStudentForm';

export class EnrollStudentForm extends Form {
	private static _instance = new EnrollStudentForm();
	degreeId:Field = {
		name:'degreeId'
		,controlType: 'Output'
		,label: 'Select a Degree'
		,isRequired: true
		,listName: 'degreeList'
		,valueType: 1
		,errorId: 'invalidId'
		,maxValue: 9999999999999
	};
	levelId:Field = {
		name:'levelId'
		,controlType: 'Output'
		,label: 'Select a Semester'
		,isRequired: true
		,listName: 'levelList'
		,listKey: 'degreeId'
		,valueType: 1
		,errorId: 'invalidId'
		,maxValue: 9999999999999
	};
	seasonId:Field = {
		name:'seasonId'
		,controlType: 'Output'
		,label: 'Select a Season'
		,isRequired: true
		,listName: 'seasonList'
		,valueType: 1
		,errorId: 'invalidId'
		,maxValue: 9999999999999
	};
	levelSectionId:Field = {
		name:'levelSectionId'
		,controlType: 'Output'
		,label: 'Select a Section'
		,isRequired: true
		,listName: 'levelSectionList'
		,listKey: 'levelId'
		,valueType: 1
		,errorId: 'invalidId'
		,maxValue: 9999999999999
	};

	students: ChildForm = {
		name:'students',
		form:EnrollableStudentForm.getInstance(),
		isEditable:false,
		isTabular:true,
		label:'',
		minRows:1,
		maxRows:10000,
		errorId:null
	};

	public static getInstance(): EnrollStudentForm {
		return EnrollStudentForm._instance;
	}

	constructor() {
		super();
		this.fields = new Map();
		this.controls = new Map();
		this.controls.set('degreeId', [Validators.required, Validators.max(9999999999999)]);
		this.fields.set('degreeId', this.degreeId);
		this.controls.set('levelId', [Validators.required, Validators.max(9999999999999)]);
		this.fields.set('levelId', this.levelId);
		this.controls.set('seasonId', [Validators.required, Validators.max(9999999999999)]);
		this.fields.set('seasonId', this.seasonId);
		this.controls.set('levelSectionId', [Validators.required, Validators.max(9999999999999)]);
		this.fields.set('levelSectionId', this.levelSectionId);

		this.childForms = new Map();
		this.childForms.set('students', this.students);
		this.opsAllowed = {get: true, update: true, filter: true};
		this.listFields = ['degreeId','levelId','seasonId','levelSectionId'];
	}

	public getName(): string {
		 return 'enrollStudent';
	}
}


export class EnrollStudentFd extends FormData {
	constructor(form: EnrollStudentForm, sa: ServiceAgent) {
		super(form, sa);
	}

	setFieldValue(name: 'degreeId' | 'levelId' | 'seasonId' | 'levelSectionId', value: string | number | boolean | null ): void {
		super.setFieldValue(name, value);
	}

	getFieldValue(name: 'degreeId' | 'levelId' | 'seasonId' | 'levelSectionId' ): string | number | boolean | null {
		return super.getFieldValue(name);
	}
}


export interface EnrollStudentVo extends Vo {
	degreeId?: number, 
	levelSectionId?: number, 
	seasonId?: number, 
	levelId?: number, 
	students?: EnrollableStudentVo
}
