import { Component, OnInit, ViewChild, Input, AfterViewInit } from "@angular/core";
import { DynamicPanel, GroupPanel } from '../../interfaces';
import { CtTableComponent, TableMetaData } from '../../../../framework-modules/elements/ct-table/component'
import { SessionComponent } from '../../component';
import { ServiceAgent } from 'src/app/framework-modules/formdata/form/serviceAgent';
import { EvaluatorForm } from 'src/app/framework-modules/formdata/gen/evaluatorForm';
import { PanelData } from 'src/app/framework-modules/formdata/form/formData';
import { FilterRequest } from 'src/app/framework-modules/formdata/form/types';
import { MessageService } from 'src/app/services/messageService';
import { OfferedSubjectSelectionForm, OfferedSubjectSelectionFd } from 'src/app/framework-modules/formdata/gen/offeredSubjectSelectionForm';
import { CtSpinner } from 'src/app/framework-modules/elements/ct-spinner/component';


@Component({
  selector: "assign-evaluators-list",
  templateUrl: "./component.html",
  styleUrls: ["./component.scss"]
})
export class AssignEvaluatorsListComponent implements DynamicPanel, OnInit {
  @ViewChild("gridTable", { static: false }) gtable: CtTableComponent;
  @ViewChild("spinner", { static: false }) spinner: CtSpinner;
  @Input() inputData: any;
  @Input() parentPanel: GroupPanel;
  @Input() session: SessionComponent;

  public tableData: TableMetaData;
  /**
   * form for header drop-down fields that are used to get the
   */
  public form: OfferedSubjectSelectionForm;
  public fd: OfferedSubjectSelectionFd;

  private selectedId: string = null;

  /**
   * for getting list of evaluators for this offered subject
   */
  private pd: PanelData;

  constructor(private sa: ServiceAgent, private ms: MessageService) {
    this.form = OfferedSubjectSelectionForm.getInstance();
    this.fd = this.form.newFormData(this.sa);
    this.pd = EvaluatorForm.getInstance().newPanelData(this.sa);
  }


  ngOnInit() {
    this.metaDataInit();
  }

  /**
   * Table Metadata initialization
   */
  metaDataInit() {
    this.tableData = {
      data: [] = [],
      metaData: {
        column_names: [
          "name",
          "phoneNumber",
          "email",
          "isExternal"
        ],
        display_names: {
          name: "Name",
          phoneNumber: "Phone",
          email: "Email",
          isExternal: "External?"
        },
        search: true,
        placeholder: "Search on Name, Department, Phone, Email"
      }
    };
  }

  public deptChanged(){
    this.selectedId = null;
  }
  
  public subjectChanged(id) {
    if (id == this.selectedId) {
      return;
    }
    if (id) {
      this.getData(id);
    }else{
      this.selectedId = null;
    }
  }

  private getData(id) {
    const filter: FilterRequest = {
      conditions: {
        offeredSubjectId: { value: id, comp: "=" }
      },
      sort: { name: "asc" }
    };

    this.spinner.show('Getting evaluators for this subject..');
    this.pd.filter(filter).subscribe({
      next: list => {
        this.selectedId = id;
        console.log(list.length + ' evaluators listed');
        this.tableData.data = list;
        this.gtable.update(this.spinner);
      },
      error: msgs => {
        this.spinner.hide();
        this.ms.showError('server returned with errors');
      }
    });
  }

  /**
   * Navigate to Evaluator Edit Screen
   */
  edit() {
    if (!this.selectedId) {
      this.ms.showError('Please select a subject');
      return;
    }
    const d = {
      departmentId:this.fd.getFieldValue('departmentId'),
      offeredSubjectId:this.selectedId
    };
    this.parentPanel.navigate("AssignEvaluatorsEdit", d);
  }
}