import { Component, OnInit, ViewChild, Input, AfterViewInit } from "@angular/core";
import { ServiceAgent } from 'src/app/framework-modules/formdata/form/serviceAgent';
import { CtTableComponent, TableMetaData } from 'src/app/framework-modules/elements/ct-table/component';
import { GroupPanel } from '../../interfaces';
import { SessionComponent } from '../../component';
import { FilterRequest } from 'src/app/framework-modules/formdata/form/types';
import { MessageService } from 'src/app/services/messageService';
import { SubjectRegisterDetailFd, SubjectRegisterDetailForm } from 'src/app/framework-modules/formdata/gen/subjectRegisterDetailForm';
import { CtSpinner } from 'src/app/framework-modules/elements/ct-spinner/component';
import { MainSectionSelectionFd, MainSectionSelectionForm } from 'src/app/framework-modules/formdata/gen/mainSectionSelectionForm';


@Component({
  selector: 'exam-marks-entry-list',
  templateUrl: "./component.html",
  styleUrls: ["./component.scss"]
})
export class FinalMarksListComponent implements OnInit {
  @ViewChild("gridTable", { static: false }) gtable: CtTableComponent;
  @ViewChild("spinner", { static: false }) public spinner: CtSpinner;
  @Input() inputData: any;
  @Input() parentPanel: GroupPanel;
  @Input() session: SessionComponent;

  public form: MainSectionSelectionForm;
  public fd: MainSectionSelectionFd;
  examForm: SubjectRegisterDetailForm;
  examFd: SubjectRegisterDetailFd;

  tableData: TableMetaData;

  constructor(private sa: ServiceAgent,
    private ms: MessageService) {

    this.form = MainSectionSelectionForm.getInstance();
    this.fd = this.form.newFormData(this.sa);
    this.examForm = SubjectRegisterDetailForm.getInstance();
    this.examFd = this.examForm.newFormData(this.sa);
  }

  ngOnInit() {
    this.initTable();
  }

  /**
   * Metadata Init for the table
   */
  initTable() {
    this.tableData = {
      data: [],
      metaData: {
        column_names: [
          "name",
          "usn",
          "cieMarks",
          "examMarks"
        ],
        display_names: {
          "name": "Name",
          "usn": "USN",
          "cieMarks":"CIE Marks",
          "examMarks": "Exam Marks"
        },
        search: true,
        edit: false,
        placeholder: "Search by Student Name, Student USN"
      }
    };
  }

  /**
   * Change Listener for the section dropdown
   * @param $event Section Id
   */
  sectionChanged($event){
    this.spinner.show("Loading.....")
    const f: FilterRequest = {
      conditions: {
        offeredSubjectId: {comp:'=',value:this.fd.getFieldValue('offeredSubjectId')},
        hasAttendedExam: {comp:'=',value:true}
      },
      sort: {usn:"asc"}
    }
    this.examFd.filter(f).subscribe(
      data => {
        this.tableData.data = data;
        this.gtable.update();
        this.spinner.hide();
      },
      err => {
        this.spinner.hide();
      }
    )
  }

  /**
   * Goto update marks screen
   */
  edit(){
    this.parentPanel.navigate('FinalMarksUpdate',{
      subject: this.fd.getDisplayedValueOf('offeredSubjectId'),
      offeredSubjectId: this.fd.getFieldValue('offeredSubjectId')
    })
  }

}