
import { Form , Field, ChildForm } from '../form/form';
import { FormData } from '../form/formData';
import { SelectOption, Vo } from '../form/types';
import { Validators } from '@angular/forms'
import { ServiceAgent} from '../form/serviceAgent';

export class QuotaWiseGpaStatisticsForm extends Form {
	private static _instance = new QuotaWiseGpaStatisticsForm();

	public static getInstance(): QuotaWiseGpaStatisticsForm {
		return QuotaWiseGpaStatisticsForm._instance;
	}

	constructor() {
		super();
		this.fields = new Map();
		this.controls = new Map();
		this.opsAllowed = {filter: true, get: true};
	}

	public getName(): string {
		 return 'quotaWiseGpaStatistics';
	}
}


export class QuotaWiseGpaStatisticsFd extends FormData {
	constructor(form: QuotaWiseGpaStatisticsForm, sa: ServiceAgent) {
		super(form, sa);
	}
	/**  this form has no editable fields. data nust be accessed as Vo and not through fd **/
}


export interface QuotaWiseGpaStatisticsVo extends Vo {
	count?: number, 
	instituteId?: number, 
	admissionQuota?: string, 
	seasonId?: number, 
	gpaRange?: string
}
