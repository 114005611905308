
import { Form , Field, ChildForm } from '../form/form';
import { FormData } from '../form/formData';
import { SelectOption, Vo } from '../form/types';
import { Validators } from '@angular/forms'
import { ServiceAgent} from '../form/serviceAgent';
import { RegisteredStudentNameForm, RegisteredStudentNameVo } from './registeredStudentNameForm';

export class ExamAttendeeListForm extends Form {
	private static _instance = new ExamAttendeeListForm();
	departmentId:Field = {
		name:'departmentId'
		,controlType: 'Dropdown'
		,label: 'Department'
		,isRequired: true
		,listName: 'departmentList'
		,valueType: 1
		,errorId: 'invalidId'
		,maxValue: 9999999999999
	};
	offeredSubjectId:Field = {
		name:'offeredSubjectId'
		,controlType: 'Dropdown'
		,label: 'Subject'
		,isRequired: true
		,listName: 'offeredSubjectList'
		,listKey: 'departmentId'
		,valueType: 1
		,errorId: 'invalidId'
		,maxValue: 9999999999999
	};
	isMakeup:Field = {
		name:'isMakeup'
		,controlType: 'Checkbox'
		,label: 'Is Make Up'
		,valueType: 3
		,errorId: 'invalidBool'
	};

	students: ChildForm = {
		name:'students',
		form:RegisteredStudentNameForm.getInstance(),
		isEditable:false,
		isTabular:true,
		label:'',
		minRows:0,
		maxRows:0,
		errorId:null
	};

	public static getInstance(): ExamAttendeeListForm {
		return ExamAttendeeListForm._instance;
	}

	constructor() {
		super();
		this.fields = new Map();
		this.controls = new Map();
		this.controls.set('departmentId', [Validators.required, Validators.max(9999999999999)]);
		this.fields.set('departmentId', this.departmentId);
		this.controls.set('offeredSubjectId', [Validators.required, Validators.max(9999999999999)]);
		this.fields.set('offeredSubjectId', this.offeredSubjectId);
		this.controls.set('isMakeup', []);
		this.fields.set('isMakeup', this.isMakeup);

		this.childForms = new Map();
		this.childForms.set('students', this.students);
		this.opsAllowed = {get: true, update: true};
		this.listFields = ['departmentId','offeredSubjectId'];
	}

	public getName(): string {
		 return 'examAttendeeList';
	}
}


export class ExamAttendeeListFd extends FormData {
	constructor(form: ExamAttendeeListForm, sa: ServiceAgent) {
		super(form, sa);
	}

	setFieldValue(name: 'departmentId' | 'offeredSubjectId' | 'isMakeup', value: string | number | boolean | null ): void {
		super.setFieldValue(name, value);
	}

	getFieldValue(name: 'departmentId' | 'offeredSubjectId' | 'isMakeup' ): string | number | boolean | null {
		return super.getFieldValue(name);
	}
}


export interface ExamAttendeeListVo extends Vo {
	offeredSubjectId?: number, 
	isMakeup?: boolean, 
	departmentId?: number, 
	students?: RegisteredStudentNameVo
}
