
import { Form , Field, ChildForm } from '../form/form';
import { FormData } from '../form/formData';
import { SelectOption, Vo } from '../form/types';
import { Validators } from '@angular/forms'
import { ServiceAgent} from '../form/serviceAgent';

export class StaffDetailForm extends Form {
	private static _instance = new StaffDetailForm();
	staffId:Field = {
		name:'staffId'
		,controlType: 'Hidden'
		,label: 'staffId'
		,isRequired: true
		,valueType: 1
		,errorId: 'invalidFlexibleId'
		,minValue: -1
		,maxValue: 9999999999999
	};
	instituteId:Field = {
		name:'instituteId'
		,controlType: 'Hidden'
		,label: 'instituteId'
		,valueType: 1
		,errorId: 'invalidTenentKey'
		,maxValue: 9999999999999
	};
	departmentName:Field = {
		name:'departmentName'
		,controlType: 'Input'
		,label: 'Department'
		,isRequired: true
		,valueType: 0
		,errorId: 'invalidText'
		,maxLength: 1000
	};
	departmentId:Field = {
		name:'departmentId'
		,controlType: 'Hidden'
		,label: 'departmentId'
		,isRequired: true
		,valueType: 1
		,errorId: 'invalidId'
		,maxValue: 9999999999999
	};
	empId:Field = {
		name:'empId'
		,controlType: 'Input'
		,label: 'Employee ID'
		,isRequired: true
		,valueType: 0
		,errorId: 'invalidText'
		,maxLength: 1000
	};
	firstName:Field = {
		name:'firstName'
		,controlType: 'Input'
		,label: 'First Name'
		,isRequired: true
		,valueType: 0
		,errorId: 'invalidName'
		,maxLength: 150
	};
	lastName:Field = {
		name:'lastName'
		,controlType: 'Input'
		,label: 'Last Name'
		,valueType: 0
		,errorId: 'invalidName'
		,maxLength: 150
	};
	middleName:Field = {
		name:'middleName'
		,controlType: 'Input'
		,label: 'Middle Name'
		,isRequired: true
		,valueType: 0
		,errorId: 'invalidName'
		,maxLength: 150
	};
	displayName:Field = {
		name:'displayName'
		,controlType: 'Input'
		,label: 'Display Name'
		,isRequired: true
		,valueType: 0
		,errorId: 'invalidName'
		,maxLength: 150
	};
	phoneNumber:Field = {
		name:'phoneNumber'
		,controlType: 'Input'
		,label: 'Phone'
		,valueType: 0
		,errorId: 'invalidPhone'
		,minLength: 10
		,maxLength: 12
	};
	email:Field = {
		name:'email'
		,controlType: 'Input'
		,label: 'Email'
		,isRequired: true
		,valueType: 0
		,errorId: 'invalidEmail'
		,maxLength: 1000
	};

	public static getInstance(): StaffDetailForm {
		return StaffDetailForm._instance;
	}

	constructor() {
		super();
		this.fields = new Map();
		this.controls = new Map();
		this.controls.set('staffId', [Validators.required, Validators.min(-1), Validators.max(9999999999999)]);
		this.fields.set('staffId', this.staffId);
		this.controls.set('instituteId', [Validators.max(9999999999999)]);
		this.fields.set('instituteId', this.instituteId);
		this.controls.set('departmentName', [Validators.required, Validators.maxLength(1000)]);
		this.fields.set('departmentName', this.departmentName);
		this.controls.set('departmentId', [Validators.required, Validators.max(9999999999999)]);
		this.fields.set('departmentId', this.departmentId);
		this.controls.set('empId', [Validators.required, Validators.maxLength(1000)]);
		this.fields.set('empId', this.empId);
		this.controls.set('firstName', [Validators.required, Validators.maxLength(150)]);
		this.fields.set('firstName', this.firstName);
		this.controls.set('lastName', [Validators.maxLength(150)]);
		this.fields.set('lastName', this.lastName);
		this.controls.set('middleName', [Validators.required, Validators.maxLength(150)]);
		this.fields.set('middleName', this.middleName);
		this.controls.set('displayName', [Validators.required, Validators.maxLength(150)]);
		this.fields.set('displayName', this.displayName);
		this.controls.set('phoneNumber', [Validators.minLength(10), Validators.maxLength(12), Validators.pattern('[1-9][0-9]*')]);
		this.fields.set('phoneNumber', this.phoneNumber);
		this.controls.set('email', [Validators.required, Validators.email, Validators.maxLength(1000)]);
		this.fields.set('email', this.email);
		this.opsAllowed = {get: true, filter: true};
		this.keyFields = ["staffId"];
	}

	public getName(): string {
		 return 'staffDetail';
	}
}


export class StaffDetailFd extends FormData {
	constructor(form: StaffDetailForm, sa: ServiceAgent) {
		super(form, sa);
	}

	setFieldValue(name: 'staffId' | 'instituteId' | 'departmentName' | 'departmentId' | 'empId' | 'firstName' | 'lastName' | 'middleName' | 'displayName' | 'phoneNumber' | 'email', value: string | number | boolean | null ): void {
		super.setFieldValue(name, value);
	}

	getFieldValue(name: 'staffId' | 'instituteId' | 'departmentName' | 'departmentId' | 'empId' | 'firstName' | 'lastName' | 'middleName' | 'displayName' | 'phoneNumber' | 'email' ): string | number | boolean | null {
		return super.getFieldValue(name);
	}
}


export interface StaffDetailVo extends Vo {
	departmentName?: string, 
	empId?: string, 
	firstName?: string, 
	lastName?: string, 
	phoneNumber?: string, 
	displayName?: string, 
	departmentId?: number, 
	instituteId?: number, 
	middleName?: string, 
	staffId?: number, 
	email?: string
}
