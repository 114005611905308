import { Component, OnInit, ViewChild, Input } from "@angular/core";
import { DynamicPanel, GroupPanel } from '../../interfaces';
import { ServiceAgent } from 'src/app/framework-modules/formdata/form/serviceAgent';
import { CtTableComponent, TableMetaData } from '../../../../framework-modules/elements/ct-table/component'
import { FilterRequest } from 'src/app/framework-modules/formdata/form/types';
import { SessionComponent } from '../../component';
import { OfferableSubjectForm } from 'src/app/framework-modules/formdata/gen/offerableSubjectForm';

@Component({
  selector: "first-year-offer-list",
  templateUrl: "./component.html",
  styleUrls: ["./component.scss"]
})

export class FirstYearOfferList implements DynamicPanel, OnInit {
  @ViewChild("gridTable", { static: false }) gtable: CtTableComponent;
  @Input() inputData: any;
  @Input() parentPanel: GroupPanel;
  @Input() session: SessionComponent;
  
  public tableData: TableMetaData;
  public from: OfferableSubjectForm;
  
  constructor(private sa: ServiceAgent) {
    this.from = OfferableSubjectForm.getInstance();
  }

  ngOnInit() {
    this.metaDataInit();
    this.filter();
  }

  private metaDataInit() {
    this.tableData = {
      data: [] = [],
      metaData: {
        column_names: [
          "name",
          "code",
          "offeredSections"
        ],
        display_names: {
          "name": "Subject",
          "code": "Code",
          "offeredSections": "Sections"
        },
        edit: true,
        search: true,
        placeholder: "Search By Subject, Code, Department or Sections",
        buttonName: "OFFER"
      }
    };
  }

  private filter() {
    /**
     * initial filter conditions
     */
    const filter: FilterRequest = {
      conditions: { departmentId: { value: 0, comp: "=" }},   
      sort: { name: "asc" }
    };
    const obs = this.sa.filter(this.from, filter);

    obs.subscribe(
      data => {
        this.tableData.data = data;
        this.gtable.update();
      },
      msgs => {
        console.error(msgs);
      }
    );
  }

  edit($event) {
    this.parentPanel.navigate('FirstYearRegisterEdit', this.tableData.data[$event].subjectId);
  }
}