import { Component, Input } from '@angular/core';
import { ServiceAgent } from 'src/app/framework-modules/formdata/form/serviceAgent';
import { FormData } from 'src/app/framework-modules/formdata/form/formData';
import { RouterService } from 'src/app/services/routerService';
import { NonSessionComponent } from '../../component';
import { DynamicPanel, GroupPanel } from '../../interfaces';
import { MessageService } from 'src/app/services/messageService';
import { ForgotPasswordForm, ForgotPasswordFd } from 'src/app/framework-modules/formdata/gen/forgotPasswordForm';

@Component({
  selector: 'forgot-password-change',
  templateUrl: './component.html',
  styleUrls: ['./component.scss']
})
export class ForgotPassowrdChangeComponent implements DynamicPanel {
  @Input() inputData: any;
  @Input() parentPanel: GroupPanel;
  @Input() session: NonSessionComponent;

  public form: ForgotPasswordForm;
  public fd: ForgotPasswordFd;
  public constructor(public sa: ServiceAgent, public routerService: RouterService, private ms: MessageService) {
    this.form = ForgotPasswordForm.getInstance();
    this.fd = new FormData(this.form, this.sa);
  }


  /**
   * resets the password for a user and redirects him to the login page.
   */
  resetPassword() {
    var newPass = this.fd.getFieldValue('newPassword');
    var confirmPass = this.fd.getFieldValue('confirmPassword');
    if (newPass !== confirmPass) {
      this.ms.showError("Passwords do not match!");
      return;
    }
    this.fd.save().subscribe(
      data => {
        this.ms.showSuccess("Password changed successfully!");
        this.parentPanel.navigate('Login')
      },
      error => {
        this.ms.showError('Password reset failed. Please try again');
      }
    );
  }
}
