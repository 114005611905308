import { Component, OnInit, ViewChild, Input, AfterViewInit } from "@angular/core";
import { ServiceAgent } from 'src/app/framework-modules/formdata/form/serviceAgent';
import { CtTableComponent, TableMetaData } from 'src/app/framework-modules/elements/ct-table/component';
import { GroupPanel } from '../../interfaces';
import { SessionComponent } from '../../component';
import { AllSectionSelectionFd, AllSectionSelectionForm} from "src/app/framework-modules/formdata/gen/allSectionSelectionForm";
import { RowToColMeta, ColToRowMeta, Transposer, Vo } from 'src/app/framework-modules/formdata/form/types';
import { MessageService } from 'src/app/services/messageService';
import { AssignedSubjectSelectionForm, AssignedSubjectSelectionFd } from 'src/app/framework-modules/formdata/gen/assignedSubjectSelectionForm';
import { ProjectMarksForSectionFd, ProjectMarksForSectionForm } from "src/app/framework-modules/formdata/gen/projectMarksForSectionForm";


@Component({
  selector: 'project-marks-entry-list',
  templateUrl: "./component.html",
  styleUrls: ["./component.scss"]
})
export class ProjectMarksEntryListComponent implements OnInit, AfterViewInit {
  @ViewChild("gridTable", { static: false }) gtable: CtTableComponent;
  @Input() inputData: any;
  @Input() parentPanel: GroupPanel;
  @Input() session: SessionComponent;

  /**
   * for selecting subjectSection and test (seqNo)
   */

  form2: AllSectionSelectionForm;
  fd2: AllSectionSelectionFd;
  
  form: AssignedSubjectSelectionForm;
  fd: AssignedSubjectSelectionFd;
  selectedSection = ''
  selectedSeqNo = '';
  assignedTestScheme: string = 'Test Scheme not allocated';
  /**
   * cie is effectively frozen?
   */
  cieFrozen = false;

  /**
   * for getting marks-list
   */
  form1: ProjectMarksForSectionForm;
  fd1: ProjectMarksForSectionFd;

  private colToRowMeta: ColToRowMeta;
  tableData: TableMetaData;

  constructor(private sa: ServiceAgent,
    private ms: MessageService) {

    this.form = AssignedSubjectSelectionForm.getInstance();
    this.fd = this.form.newFormData(this.sa);
    this.form1 = ProjectMarksForSectionForm.getInstance();
    this.fd1 = this.form1.newFormData(this.sa);

    this.form2 = AllSectionSelectionForm.getInstance();
    this.fd2 =  this.form2 .newFormData(this.sa);
  }

  ngOnInit() {
    this.initTable();
  }

  ngAfterViewInit() {
    /**
     * update() is not a repeatable method() we have to be careful before calling it!!
     */
    //this.gtable.update();
  }

  sectionChanged(id: string) {
    if (this.selectedSection == id) {
      return;
    }
    if (id) {
      this.selectedSection = '';
      this.fd1.lists.seqNo = [];
      this.getData(id);
      
    }
  }

  seqNoChanged(seqNo) {
    this.selectedSeqNo = seqNo;
  }

  getData(id: string) {
    console.log(id)
    this.fd1.setFieldValue('subjectSectionId', id);
    this.fd1.fetchData().subscribe({
      next: data => {
        this.selectedSection = id;  
        this.assignedTestScheme = data.assessmentSchemeName as string;
        this.setData(data);
      },
      error: msg => {
        console.error(msg);
        this.assignedTestScheme = 'Test Scheme not allocated';
      }
    });
  }

  /**
   * 
   * @param data set the data reced from server into our table component after transposing it
   */
  setData(data) {
    this.cieFrozen = data.cieFrozen;
    console.log(this.cieFrozen )
    /**
     * input meta data for the trasnposer
     */
    const meta: RowToColMeta = {
      datarowsAttribute: 'marks',
      headingAttribute: 'name',
      keyAttribute: 'seqNo',
      rowKeyAttribute: 'assessmentSeqNo',
      rowValueAttribute: 'marks',
      columns: data.names as [],
    };

    /**
     * table meta data
     */
    const students = data.students as [];
    const colNames = ["name", "usn"];
    const headings = { name: "Name", usn: "USN" };
    const tableMeta = { names: colNames, headings: headings };
    this.colToRowMeta = Transposer.rowToCol(meta, tableMeta, students);
    this.resetTable(colNames, headings, students);
  }

  initTable() {
    this.tableData = {
      data: [],
      metaData: {
        column_names: [],
        display_names: {},
        search: true,
        edit: false,
        placeholder: "Search by Student Name, Student USN"
      }
    };
  }

  resetTable(colNames: string[], headings: object, data: object[]) {
    this.tableData.metaData.column_names = colNames;
    this.tableData.metaData.display_names = headings as {};
    this.tableData.metaData.edit = false;
    this.tableData.data = data as Vo[];
    this.gtable.update();
  }

  goToBulkUpdate() {
    this.parentPanel.navigate('ProjectMarksEntryBulkUpdate', this.selectedSection)
  }
  /**
   * edit enabled when freeze is in effect.
   * @param rowId 
   */
  edit(rowId) {
    if (!this.selectedSeqNo) {
      this.ms.showError('Please select test before chosing edit for a student');
      return;
    }
    const row = this.tableData.data[rowId];
    const data = {
      assessmentSeqNo: this.selectedSeqNo,
      subjectRegisterId: row.subjectRegisterId,
      studentId: row.studentId
    };
    this.parentPanel.navigate("ProjectMarksEntryUpdate", data)
  }
}