
import { Form , Field, ChildForm } from '../form/form';
import { FormData } from '../form/formData';
import { SelectOption, Vo } from '../form/types';
import { Validators } from '@angular/forms'
import { ServiceAgent} from '../form/serviceAgent';

export class MarkSheetDownloadForm extends Form {
	private static _instance = new MarkSheetDownloadForm();
	title:Field = {
		name:'title'
		,controlType: 'Input'
		,label: 'Marksheet Title'
		,isRequired: true
		,valueType: 0
		,errorId: 'invalidText'
		,maxLength: 1000
	};

	public static getInstance(): MarkSheetDownloadForm {
		return MarkSheetDownloadForm._instance;
	}

	constructor() {
		super();
		this.fields = new Map();
		this.controls = new Map();
		this.controls.set('title', [Validators.required, Validators.maxLength(1000)]);
		this.fields.set('title', this.title);
		this.opsAllowed = {get: true, filter: true};
	}

	public getName(): string {
		 return 'markSheetDownload';
	}
}


export class MarkSheetDownloadFd extends FormData {
	constructor(form: MarkSheetDownloadForm, sa: ServiceAgent) {
		super(form, sa);
	}

	setFieldValue(name: 'title', value: string | number | boolean | null ): void {
		super.setFieldValue(name, value);
	}

	getFieldValue(name: 'title' ): string | number | boolean | null {
		return super.getFieldValue(name);
	}
}


export interface MarkSheetDownloadVo extends Vo {
	title?: string, 
	instituteId?: number
}
