import { Component, Input, OnInit, ViewChild } from "@angular/core";
import { DynamicPanel, GroupPanel} from '../../interfaces';
import { ServiceAgent} from 'src/app/framework-modules/formdata/form/serviceAgent';
import { SessionComponent} from '../../component';
import { ClientContext} from 'src/app/framework-modules/formdata/form/clientContext';
import { ExcelDownloadService} from 'src/app/services/excelDownloadService';
import { TableMetaData, CtTableComponent} from 'src/app/framework-modules/elements/ct-table/component';
import { MessageService} from 'src/app/services/messageService';
import { GradeGracingStudentListForm, GradeGracingStudentListFd, GradeGracingStudentListVo } from 'src/app/framework-modules/formdata/gen/gradeGracingStudentListForm';
import { Vo, FilterRequest } from 'src/app/framework-modules/formdata/form/types';

@Component({
  selector: "gradegracing-view",
  templateUrl: "./component.html",
  styleUrls: ["./component.scss"]
})
export class GradeGracingViewComponent implements DynamicPanel, OnInit {
  @ViewChild("gridTable", {  static: false}) gtable: CtTableComponent;
  @Input() inputData: any;
  @Input() parentPanel: GroupPanel;
  @Input() session: SessionComponent;

  public form: GradeGracingStudentListForm;
  public fd: GradeGracingStudentListFd;
  public tableData: TableMetaData;
  public selectedId = '';
  public vo: GradeGracingStudentListVo = {};
  
  constructor(private sa: ServiceAgent,  private cc: ClientContext,  private ms: MessageService,  private ds: ExcelDownloadService) {
    this.form = GradeGracingStudentListForm.getInstance();
    this.fd = this.form.newFormData(this.sa);
  }

  ngOnInit() {
    this.metaDataInit();
  }

  metaDataInit() {
    this.tableData = {
      data: [],
      metaData: {
        column_names: [
          "subjectName",
          "subjectCode",
          "grade"
        ],
        display_names: {
          "subjectName":"Subject Name",
          "subjectCode":"Subject Code",
          "grade":"Grade"
        },
        search: true,
        placeholder: "Search By Student Name, USN, Program"
      }
    };
    this.getGradeDetails();
  }

  getGradeDetails() {
    this.tableData.metaData.edit = true;
    this.tableData.metaData.buttonName = "Edit";

    const filter: FilterRequest = {
      conditions: {
        levelId: { comp: "=", value: this.inputData["levelId"]},
        programId: { comp: "=", value: this.inputData["programId"]},
        seasonId: { comp: "=", value: this.inputData["seasonId"]},
        studentId: {comp: "=", value: this.inputData["studentId"]}
      },
      sort: {
        name: "asc"
      },
      maxRows: 10
    };

    var grade = ['A','B','C','D','E','F','I','X','S','O','A+','B+','P']
    const obs = this.fd.filter(filter).subscribe(
      data => {
        this.vo = data[0];
        var datas = [];
        var data1 = data[0].subjectDetails as Vo[];
        if(data1 != null){
          data1.forEach( subjects => {
              if (grade.indexOf((subjects.grade).toString()) != -1){
                datas.push(subjects)
              }
          })
        }
        this.tableData.data = datas as Vo[]
        this.gtable.update();
      },
      msgs => {
        console.error(msgs);
      }
    );
  }

  edit($event) {
    this.parentPanel.navigate("GradeGracingEdit", { studentId: this.tableData.data[$event].studentId, 
                              subjectRegisterId: this.tableData.data[$event].subjectRegisterId, 
                              instituteId: this.tableData.data[$event].instituteId, 
                              seasonId: this.tableData.data[$event].seasonId, 
                              programId: this.inputData['programId'],
                              levelId: this.inputData['levelId'],
                              gracedValuationRound: this.inputData['gracedValuationRound']});
  }

  goBack(){
    this.parentPanel.navigate("GradeGracingList");
  }
}
