import { Component, OnInit, ViewChild, Input, Output, EventEmitter, ComponentFactoryResolver } from "@angular/core";
import { DynamicPanel, LandingPanel, GroupPanel } from '../../interfaces';
import { ServiceAgent } from 'src/app/framework-modules/formdata/form/serviceAgent';
import { CtTableComponent, TableMetaData } from '../../../../framework-modules/elements/ct-table/component'
import { FilterRequest } from 'src/app/framework-modules/formdata/form/types';
import { SessionComponent } from '../../component';
import { StaffDetailForm, StaffDetailFd } from 'src/app/framework-modules/formdata/gen/staffDetailForm';
import { DesignationForm, DesignationFd } from 'src/app/framework-modules/formdata/gen/designationForm';

@Component({
  selector: "staff-list",
  templateUrl: "./component.html",
  styleUrls: ["./component.scss"]
})


export class DesignationListComponent implements DynamicPanel {
  @ViewChild("gridTable", { static: false }) gtable: CtTableComponent;
  @Input() inputData: any;
  @Input() parentPanel: GroupPanel;
  @Input() session: SessionComponent;
  public tableData: TableMetaData;
  public from: DesignationForm;
  public fd: DesignationFd;

  constructor(private sa: ServiceAgent) {
    this.from = DesignationForm.getInstance();
    this.fd = this.from.newFormData(this.sa);
  }

  metaDataInit() {
    this.tableData = {
      data: [] = [],
      metaData: {
        column_names: [
          "name",
          "code"
        ],
        display_names: {
          "name": "Designation Name",
          "code": "Code"
        },
        edit: true,
        search: true,
        view: true,
        placeholder: "Search By Designation",
        buttonName: "EDIT PERMISSIONS"
      }
    };
    this.filter();
  }

  ngOnInit() {
    this.metaDataInit();
  }

  filter() {
    /**
     * initial filter conditions
     */
    const filter: FilterRequest = {
      conditions: {},
      sort: { name: "asc" },
      maxRows: 10
    };
    const obs = this.fd.filter(filter);

    obs.subscribe(
      data => {
        this.tableData.data = data;
        this.gtable.update();
      },
      msgs => {
        console.error(msgs);
      }
    );
  }

  addDesignation() {
    this.parentPanel.navigate('DesignationAdd')
  }

  edit($event) {
    this.parentPanel.navigate('DesignationEdit', this.tableData.data[$event].designationId)
  }

  view($event) {
    this.parentPanel.navigate('StaffView', this.tableData.data[$event].designationId)
  }

}
