import { Component, OnInit, ViewChild, Input } from "@angular/core";
import { DynamicPanel, GroupPanel } from '../../interfaces';
import { ServiceAgent } from 'src/app/framework-modules/formdata/form/serviceAgent';
import { CtTableComponent, TableMetaData } from '../../../../framework-modules/elements/ct-table/component'
import { SessionComponent } from '../../component';
import { OfferedSubjectSelectionForm, OfferedSubjectSelectionFd } from 'src/app/framework-modules/formdata/gen/offeredSubjectSelectionForm';
import { PanelData } from 'src/app/framework-modules/formdata/form/formData';
import { MessageService } from 'src/app/services/messageService';
import { FilterRequest } from 'src/app/framework-modules/formdata/form/types';
import { CtSpinner } from 'src/app/framework-modules/elements/ct-spinner/component';
import { ChallengeValuationOptedStudentForm, ChallengeValuationOptedStudentFd, ChallengeValuationOptedStudentVo } from 'src/app/framework-modules/formdata/gen/challengeValuationOptedStudentForm';



@Component({
  selector: "challenge-list",
  templateUrl: "./component.html",
  styleUrls: ["./component.scss"],
})


export class ChallengeValuationListComponent implements DynamicPanel, OnInit {
  @ViewChild("gridTable", { static: false }) gtable: CtTableComponent;
  @ViewChild("spinner", { static: false }) spinner: CtSpinner;
  @Input() inputData: any;
  @Input() parentPanel: GroupPanel;
  @Input() session: SessionComponent;

  public tableData: TableMetaData;
  /** form and fd for selecting the subjects */
  public form: ChallengeValuationOptedStudentForm;
  public fd: ChallengeValuationOptedStudentFd;
  public vo: ChallengeValuationOptedStudentVo = {};

  public form1:OfferedSubjectSelectionForm;
  public fd1:OfferedSubjectSelectionFd;



  private selectedId: string = null;

  constructor(private sa: ServiceAgent, private ms: MessageService) {
    this.form = ChallengeValuationOptedStudentForm.getInstance();
    this.fd = this.form.newFormData(this.sa);
    this.form1 = OfferedSubjectSelectionForm.getInstance();
    this.fd1 = this.form1.newFormData(this.sa);
  }


  /**
   * 
   */
  ngOnInit() {
    this.metaDataInit();
  }

  metaDataInit() {
    this.tableData = {
      data: [],
      metaData: {
        column_names: [
          "name",
          "usn",
          "department",
          "level"
        ],
        display_names: {
          name: "Name",
          usn: "USN",
          department: "Department Name",
          level: "Level"
        },
        search: true,
        placeholder: "Search By Name, USN, Department"
      },
    };
  }

  public idChanged(id) {
    if (this.selectedId == id) {
      return;
    }
    this.selectedId = id;
    if (id) {
      this.getData(id);
    }
  }

  private getData(id) {
    const filter: FilterRequest = {
      conditions: {
        offeredSubjectId:{comp:"=",value:id}
      }
    };
    this.fd.filter(filter).subscribe(
      data => {
        this.tableData.data = data;
        this.gtable.update();
      },
      msgs => {
        console.error(msgs);
      }
    );
  }

  edit() {
    this.parentPanel.navigate('ChallengeValuationEdit', this.selectedId);
  }

  download() {
    this.ms.showError('Download funcitonlity is not ready yet');
  }
}