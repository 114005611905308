import { Component, OnInit, ViewChild, Input, AfterViewInit } from "@angular/core";
import { DynamicPanel, GroupPanel } from '../../interfaces';
import { ServiceAgent } from 'src/app/framework-modules/formdata/form/serviceAgent';
import { SessionComponent } from '../../component';
import { MessageService } from 'src/app/services/messageService';
import { CtSpinner } from 'src/app/framework-modules/elements/ct-spinner/component';
import { ExamAttendeeListForm, ExamAttendeeListFd, ExamAttendeeListVo } from 'src/app/framework-modules/formdata/gen/examAttendeeListForm';
import { CtTableComponent, TableMetaData } from 'src/app/framework-modules/elements/ct-table/component';
import { FinalMarksForm, FinalMarksFd, FinalMarksVo } from 'src/app/framework-modules/formdata/gen/finalMarksForm';
import { CurrentEnrolledStudentForm, CurrentEnrolledStudentFd, CurrentEnrolledStudentVo } from 'src/app/framework-modules/formdata/gen/currentEnrolledStudentForm';
import { ProgramLevelListForm, ProgramLevelListFd, ProgramLevelListVo } from 'src/app/framework-modules/formdata/gen/programLevelListForm';
import { FilterRequest } from 'src/app/framework-modules/formdata/form/types';
import { ExcelDownloadService } from 'src/app/services/excelDownloadService';
import { GradeCardStudentDetailFd, GradeCardStudentDetailForm } from 'src/app/framework-modules/formdata/gen/gradeCardStudentDetailForm';

@Component({
  selector: "results-level-list",
  templateUrl: "./component.html",
  styleUrls: ["./component.scss"],
})


export class ResultsLevelListComponent implements DynamicPanel, OnInit, AfterViewInit {
  @ViewChild("gridTable", { static: false }) public gtable: CtTableComponent;
  @ViewChild("spinner", { static: false }) spinner: CtSpinner;
  @Input() inputData: any;
  @Input() parentPanel: GroupPanel;
  @Input() session: SessionComponent;

  public form: ProgramLevelListForm;
  public fd: ProgramLevelListFd;
  public vo: ProgramLevelListVo = {};
  public tableData: TableMetaData;
  public studentForm: GradeCardStudentDetailForm;
  public studentFd: GradeCardStudentDetailFd;
  public studentVo: CurrentEnrolledStudentVo = {};
  programId: any;
  levelId: any;
  seasonId: any;
  
  constructor(private sa: ServiceAgent, private ms: MessageService, private ds: ExcelDownloadService) {
    this.form = ProgramLevelListForm.getInstance();
    this.fd = this.form.newFormData(this.sa);
    this.studentForm = GradeCardStudentDetailForm.getInstance();
    this.studentFd = this.studentForm.newFormData(this.sa);
  }


  ngOnInit() {
    this.metaDataInit();
    
  }

  ngAfterViewInit(){
    this.gtable.update()
  }

  /**
   * initialising the table data
   */
  metaDataInit() {
    this.tableData = {
      data: [] = [],
      metaData: {
        column_names: [
          "studentName",
          "usn",
          "registeredCredits",
          "obtainedCredits",
          "sgpa",
          "cgpa"
        ],
        display_names: {
          "studentName": "Student Name",
          "usn": "USN",
          "registeredCredits":"Credits Registered",
          "obtainedCredits":"Credits Obtained",
          "sgpa": "SGPA",
          "cgpa": "CGPA"
        },
        buttonName: "View Details",
        edit: true,
        search: true,
        placeholder: "Search a student..."
      }
    };
  }

  /**
   * Filter conditions for student list.
   * Also even change listener for level list.
   * @param $event levelId
   */
  filter($event){
    this.seasonId = $event;
    const filter: FilterRequest = {
      conditions: {
        levelId: {comp:"=",value:this.fd.getFieldValue('programLevelId')},
        programId: {comp:"=",value:this.fd.getFieldValue('programId')},
        seasonId: {comp: "=",value:this.fd.getFieldValue('seasonId')}
      },
      maxRows: 10,
      sort: {usn:"asc"}
    }

    const obs = this.studentFd.filter(filter);
    const that = this;

    this.spinner.show("Loading Data..........")
    obs.subscribe(data =>{
      this.tableData.data = data;
      this.gtable.update();
      this.spinner.hide();
      this.ms.showSuccess("Grades Loaded !")
    })
  }

  /**
   * Event change listner for programId dropdown
   * @param $event Program Id
   */
  setProgram($event){
    this.programId = $event;
    this.filter(this.seasonId);
  }

  /**
   * Event change listner for levelId dropdown
   * @param $event Program Id
   */
  setLevel($event){
    this.levelId = $event;
    this.filter(this.seasonId);
  }

  /**
   * Downloads Marks sheet level wise.
   */
  doDownload(){
    var data = []
    this.tableData.data.forEach(row =>{
      data.push({
        "Name": row["studentName"],
        "USN": row["usn"],
        "Registered Credits": row["registeredCredits"],
        "Obtained Credits": row["obtainedCredits"],
        "SGPA": row["sgpa"],
        "CGPA": row["cgpa"]
      })
    });
    this.ds.downloadExcel({Results:data},this.fd.getDisplayedValueOf('programId')+ " - "+this.fd.getDisplayedValueOf('programLevelId') + " Results")
  }

  /**
   * Go to the detailed view of marks for each student.
   * @param $event Student details
   */
  show($event){
    this.parentPanel.navigate("ResultsLevelView", [this.tableData.data[$event],"ResultsLevel",this.seasonId]);
  }

  /**
   * Used to calculate GPA for the selected program and level
   */
  doCalculate(){
    this.sa.serve("CalculateResultsForLevel", {data:{programId:this.fd.getFieldValue('programId'),levelId:this.fd.getFieldValue('programLevelId')}}).subscribe(
      data =>{
        this.parentPanel.navigate("ResultsLevel")
        this.ms.showSuccess("GPA calculated successfully");
      }
    )
  }
}