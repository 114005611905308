import { Component, OnInit, ViewChild, Input, Output, EventEmitter, ComponentFactoryResolver } from "@angular/core";
import { DynamicPanel, LandingPanel, GroupPanel } from '../../interfaces';
import { ServiceAgent } from 'src/app/framework-modules/formdata/form/serviceAgent';
import { InstituteForm } from 'src/app/framework-modules/formdata/gen/instituteForm';
import { FormData } from 'src/app/framework-modules/formdata/form/formData';
import { CtTableComponent, TableMetaData } from '../../../../framework-modules/elements/ct-table/component'
import { FilterRequest } from 'src/app/framework-modules/formdata/form/types';
import { SessionComponent } from '../../component';
import { ClientContext } from 'src/app/framework-modules/formdata/form/clientContext';
import { MatDialog } from '@angular/material';

@Component({
  selector: "institute-list",
  templateUrl: "./component.html",
  styleUrls: ["./component.scss"],
})


export class InsituteListComponent implements DynamicPanel, OnInit {
  @ViewChild("gridTable", { static: false }) gtable: CtTableComponent;
  @Input() inputData: any;
  @Input() parentPanel: GroupPanel;
  @Input() session: SessionComponent;
  public tableData: TableMetaData;
  public from: InstituteForm;
  public fd: FormData;

  constructor(private sa: ServiceAgent, private cc: ClientContext, private dialog: MatDialog) {
    this.from = InstituteForm.getInstance();
    this.fd = this.from.newFormData(this.sa);
  }

  metaDataInit() {
    this.tableData = {
      data: [],
      metaData: {
        column_names: [
          "name",
          "instituteType",
          "phoneNumber"
        ],
        display_names: {
          "name": "Institute Name",
          "instituteType": "Institute Type",
          "phoneNumber": "Phone Number"
        },
        edit: true,
        search: true,
        placeholder: "Search By Institute Name, Institute Number, Phone Number",
        view: true,
      },
    };
  }

  ngOnInit() {
    this.metaDataInit();
    this.filter();
  }

  filter() {
    /**
     * initial filter conditions
     */
    const filter: FilterRequest = {
      conditions: {
      },
      sort: { name: "asc" },
      maxRows: 10
    };
    this.fd.filter(filter).subscribe(
      data => {
        this.tableData.data = data;
        this.gtable.update();
      },
      msgs => {
        console.error(msgs);
      }
    );
  }

  addInstitute() {
    this.parentPanel.navigate('InstituteAdd')
  }

  edit($event) {
    this.parentPanel.navigate("InstituteEdit", this.tableData.data[$event].instituteId)
  }

  view($event) {
    this.cc.switchInstitute(this.tableData.data[$event].instituteId as number);
    this.parentPanel.navigate("DepartmentList", this.tableData.data[$event].instituteId);
  }
}