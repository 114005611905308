import { Component, OnInit, ViewChild, ViewContainerRef, Type, ComponentFactoryResolver, Input } from '@angular/core';
import { LandingPanel, GroupPanel, DynamicPanel } from './interfaces';
import { InstituteComponent } from '../session/institute/component'
import { StudentComponent } from './student/component';
import { SubjectComponent } from './subject/component'

import { StaffComponent } from './staff/component';
import { AuthorisationComponent } from './authorisation/component';
import { ClientContext } from 'src/app/framework-modules/formdata/form/clientContext';
import { MenuService } from 'src/app/services/menuService';
import { CtSpinner } from 'src/app/framework-modules/elements/ct-spinner/component';
import { SessionalComponent } from './sessional/component';
import { TerminalComponent } from './terminal/component';
import { FreezeComponent } from './freeze/component';
import { DownloadComponent } from './download/component';
 
import { ResetPasswordComponent } from './reset-password/component';
import { DashBoardComponent } from './dashboard/component';
import { ArchiveComponent } from './archive/component';
import { IcdComponent } from './icd/component';
import { MakeUpComponent } from './make-up/component';


/**
 * temp during development. will come from menuService
 */
const ROUTES: PrimaryMenu= {
  Dashboard:{compName:DashBoardComponent,pageId:"G11",displayName:"Dashboard"},
  Institutes: { compName: InstituteComponent, pageId: "G03", displayName: "Institutes" },
  Students:{compName:StudentComponent,pageId:"G06",displayName:"Students"},
  Staffs: { compName: StaffComponent, pageId: "G05", displayName: "Staffs" },
  Subjects: { compName: SubjectComponent, pageId: "G07", displayName: "Subjects" },
  Sessionals: { compName: SessionalComponent, pageId: "G04", displayName: "Sessionals" },
  Freeze: { compName: FreezeComponent, pageId: "G02", displayName: "Freeze" },
  Authorisation: { compName: AuthorisationComponent, pageId: "G01", displayName: "Authorisation" },  
  Terminals:{compName:TerminalComponent,pageId:"G08",displayName:"Terminals"},
  Downloads:{compName:DownloadComponent,pageId:"G09",displayName:"Downloads"},
  // Archive:{compName:ArchiveComponent,pageId:"G12",displayName:"Archive"},
  "One-Click":{compName:ArchiveComponent,pageId:"G12",displayName:"One-Click"},
  ICD:{compName:IcdComponent,pageId:"G13",displayName:"ICD"},
  MakeUp:{compName: MakeUpComponent,pageId:"G14",displayName:"MakeUp"},
};
const MODULES = [
  'Dashboard', 'Institutes', 'Students', 'Subjects', 'Examinations', 'Staffs', 'Authorisation',"Terminals","Freeze","Download","Archive","MakeUp"
];
const RESET: ResetPage ={
  ResetPassword: {compName: ResetPasswordComponent, pageId: "G0", displayName: "Reset Password"}
}

@Component({
  selector: 'session',
  templateUrl: './component.html',
  styleUrls: ['./component.scss'],
})

export class SessionComponent implements LandingPanel, OnInit {
  @ViewChild("CtSpinner", { static: false }) public CtSpinner: CtSpinner;
  menu: { [key: string]: { compName: Type<GroupPanel>, pageId: string, displayName: string } };
  modules: { [key: string]: { compName: Type<GroupPanel>, pageId: string, displayName: string } };
  reset: {[key: string]: {compName: Type<GroupPanel> , displayName: string}};
  c: ComponentFactoryResolver;
  mainNav: boolean = true;

  @ViewChild("primaryContainer", { static: true, read: ViewContainerRef }) container: ViewContainerRef;
  sideNav: Array<any> = [];
  selected: boolean = false;
  sideNavLength: number = 0;
  selectedSideNavItem: string;
  pScreens: Array<string>=[];
  defaultSelected: string;
  pModules: string[];

  constructor(private componentFactoryResolver: ComponentFactoryResolver, private ms: MenuService) {
    this.pScreens = this.ms.getPermittedScreens();
    this.pModules = this.ms.getPermittedModules();
    Object.keys(ROUTES).forEach(key => {
      if(this.pModules.includes(ROUTES[key].pageId) && this.defaultSelected == undefined){
        this.defaultSelected = key;
      }
    });
  }

  ngOnInit() {
    /**
     * after we write menuService, we will use it this way.
     * this.menus = this.menuService.getMainMenu();
     */
    this.menu = ROUTES;
    this.modules = ROUTES;
    this.reset = RESET;
  }

  ngAfterViewInit() {
    this.navigate(this.defaultSelected);
  }
  public navigateToReset(panelName: string): void {
    this.container.clear();
    const factory = this.componentFactoryResolver.resolveComponentFactory(this.reset[panelName].compName);
    const c = this.container.createComponent(factory);
    c.instance.parentPanel = this;
    c.changeDetectorRef.detectChanges();
  }

  /**
   *
   * @param panelName landing panel to be opened in the container
   * @param data any optional data t be passed to the landing panel
   */
  public navigate(panelName: string, data?: any): void {
    this.CtSpinner.show('Loading...');
    if (this.pScreens.indexOf(this.menu[panelName].pageId) > -1) {
      this.container.clear();
      // console.log(this.menu[panelName], this.menu[panelName].compName)
      const factory = this.componentFactoryResolver.resolveComponentFactory(this.menu[panelName].compName);
      const c = this.container.createComponent(factory);
      c.instance.parentPanel = this;
      c.instance.inputData = data;
      c.changeDetectorRef.detectChanges();
    }
    this.CtSpinner.hide();
  }
  toggleMainNav(mainNav: boolean) {
    this.mainNav = mainNav
  }

  navigation(moduleId) {
    console.log(moduleId)
    this.selected = true;
    this.navigate(moduleId);
  }

  hideMainNav(){
    this.mainNav = false;
  }

  showMainNav(){
    this.mainNav = true;
  }
}

export interface SecondaryMenu{
  [key: string]: {
    comp: Type<DynamicPanel>,
    title: string,
    subTitle?: string,
    sideNav: boolean,
    name?: string,
    pageId: string,
    displayonSidenav?: boolean,
    mainNav: boolean,
    order?: number
  }
}

export interface PrimaryMenu{ 
  [key: string]: { 
    compName: Type<GroupPanel>,
    pageId: string, 
    displayName: string 
  } 
}
  export interface ResetPage{
    [key: string]:{
      compName: Type<GroupPanel>,
      pageId: string,
      displayName: string
    }
  }
