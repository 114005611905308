
import { Form , Field, ChildForm } from '../form/form';
import { FormData } from '../form/formData';
import { SelectOption, Vo } from '../form/types';
import { Validators } from '@angular/forms'
import { ServiceAgent} from '../form/serviceAgent';

export class SubjectSectionFrozenStatusForm extends Form {
	private static _instance = new SubjectSectionFrozenStatusForm();

	public static getInstance(): SubjectSectionFrozenStatusForm {
		return SubjectSectionFrozenStatusForm._instance;
	}

	constructor() {
		super();
		this.fields = new Map();
		this.controls = new Map();
		this.opsAllowed = {filter: true};
		this.keyFields = ["subjectSectionId"];
	}

	public getName(): string {
		 return 'subjectSectionFrozenStatus';
	}
}


export class SubjectSectionFrozenStatusFd extends FormData {
	constructor(form: SubjectSectionFrozenStatusForm, sa: ServiceAgent) {
		super(form, sa);
	}
	/**  this form has no editable fields. data nust be accessed as Vo and not through fd **/
}


export interface SubjectSectionFrozenStatusVo extends Vo {
	offeredSubjectId?: number, 
	createdAt?: string, 
	seasonId?: number, 
	subjectSectionId?: number, 
	instituteId?: number, 
	attendanceFrozen?: boolean, 
	cieFrozen?: boolean, 
	subjectId?: number, 
	updatedAt?: string
}
