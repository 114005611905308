
import { Form , Field, ChildForm } from '../form/form';
import { FormData } from '../form/formData';
import { SelectOption, Vo } from '../form/types';
import { Validators } from '@angular/forms'
import { ServiceAgent} from '../form/serviceAgent';

export class LevelForm extends Form {
	private static _instance = new LevelForm();
	levelId:Field = {
		name:'levelId'
		,controlType: 'Hidden'
		,label: 'levelId'
		,valueType: 1
		,errorId: 'invalidId'
		,maxValue: 9999999999999
	};
	instituteId:Field = {
		name:'instituteId'
		,controlType: 'Hidden'
		,label: 'instituteId'
		,valueType: 1
		,errorId: 'invalidTenentKey'
		,maxValue: 9999999999999
	};
	degreeId:Field = {
		name:'degreeId'
		,controlType: 'Hidden'
		,label: 'degreeId'
		,isRequired: true
		,listName: 'degreeList'
		,valueType: 1
		,errorId: 'invalidId'
		,maxValue: 9999999999999
	};
	name:Field = {
		name:'name'
		,controlType: 'Input'
		,label: 'Name'
		,isRequired: true
		,valueType: 0
		,errorId: 'invalidName'
		,maxLength: 150
	};
	code:Field = {
		name:'code'
		,controlType: 'Input'
		,label: 'Code'
		,valueType: 0
		,errorId: 'invalidCode'
		,maxLength: 50
	};
	levelSeq:Field = {
		name:'levelSeq'
		,controlType: 'Hidden'
		,label: 'levelSeq'
		,isRequired: true
		,valueType: 1
		,errorId: 'invalidInteger'
		,maxValue: 9999999999999
	};
	createdAt:Field = {
		name:'createdAt'
		,controlType: 'Hidden'
		,label: 'createdAt'
		,valueType: 5
		,errorId: 'invalidTimestamp'
	};
	updatedAt:Field = {
		name:'updatedAt'
		,controlType: 'Hidden'
		,label: 'updatedAt'
		,valueType: 5
		,errorId: 'invalidTimestamp'
	};

	public static getInstance(): LevelForm {
		return LevelForm._instance;
	}

	constructor() {
		super();
		this.fields = new Map();
		this.controls = new Map();
		this.controls.set('levelId', [Validators.max(9999999999999)]);
		this.fields.set('levelId', this.levelId);
		this.controls.set('instituteId', [Validators.max(9999999999999)]);
		this.fields.set('instituteId', this.instituteId);
		this.controls.set('degreeId', [Validators.required, Validators.max(9999999999999)]);
		this.fields.set('degreeId', this.degreeId);
		this.controls.set('name', [Validators.required, Validators.maxLength(150)]);
		this.fields.set('name', this.name);
		this.controls.set('code', [Validators.maxLength(50)]);
		this.fields.set('code', this.code);
		this.controls.set('levelSeq', [Validators.required, Validators.max(9999999999999)]);
		this.fields.set('levelSeq', this.levelSeq);
		this.controls.set('createdAt', []);
		this.fields.set('createdAt', this.createdAt);
		this.controls.set('updatedAt', []);
		this.fields.set('updatedAt', this.updatedAt);
		this.opsAllowed = {get: true, create: true, update: true, filter: true};
		this.listFields = ['degreeId'];
		this.keyFields = ["levelId"];
	}

	public getName(): string {
		 return 'level';
	}
}


export class LevelFd extends FormData {
	constructor(form: LevelForm, sa: ServiceAgent) {
		super(form, sa);
	}

	setFieldValue(name: 'levelId' | 'instituteId' | 'degreeId' | 'name' | 'code' | 'levelSeq' | 'createdAt' | 'updatedAt', value: string | number | boolean | null ): void {
		super.setFieldValue(name, value);
	}

	getFieldValue(name: 'levelId' | 'instituteId' | 'degreeId' | 'name' | 'code' | 'levelSeq' | 'createdAt' | 'updatedAt' ): string | number | boolean | null {
		return super.getFieldValue(name);
	}
}


export interface LevelVo extends Vo {
	createdAt?: string, 
	code?: string, 
	degreeId?: number, 
	levelId?: number, 
	name?: string, 
	instituteId?: number, 
	levelSeq?: number, 
	updatedAt?: string
}
