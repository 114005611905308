
import { Form , Field, ChildForm } from '../form/form';
import { FormData } from '../form/formData';
import { SelectOption, Vo } from '../form/types';
import { Validators } from '@angular/forms'
import { ServiceAgent} from '../form/serviceAgent';

export class AssignableStaffForm extends Form {
	private static _instance = new AssignableStaffForm();
	staffId:Field = {
		name:'staffId'
		,controlType: 'Hidden'
		,label: 'staffId'
		,isRequired: true
		,valueType: 1
		,errorId: 'invalidId'
		,maxValue: 9999999999999
	};
	isAssigned:Field = {
		name:'isAssigned'
		,controlType: 'Output'
		,label: 'isAssigned'
		,valueType: 3
		,defaultValue: false
		,errorId: 'invalidBool'
	};
	name:Field = {
		name:'name'
		,controlType: 'Output'
		,label: 'name'
		,isRequired: true
		,valueType: 0
		,errorId: 'invalidName'
		,maxLength: 150
	};
	email:Field = {
		name:'email'
		,controlType: 'Output'
		,label: 'email'
		,valueType: 0
		,errorId: 'invalidText'
		,maxLength: 1000
	};

	public static getInstance(): AssignableStaffForm {
		return AssignableStaffForm._instance;
	}

	constructor() {
		super();
		this.fields = new Map();
		this.controls = new Map();
		this.controls.set('staffId', [Validators.required, Validators.max(9999999999999)]);
		this.fields.set('staffId', this.staffId);
		this.controls.set('isAssigned', []);
		this.fields.set('isAssigned', this.isAssigned);
		this.controls.set('name', [Validators.required, Validators.maxLength(150)]);
		this.fields.set('name', this.name);
		this.controls.set('email', [Validators.maxLength(1000)]);
		this.fields.set('email', this.email);
		this.opsAllowed = {filter: true};
	}

	public getName(): string {
		 return 'assignableStaff';
	}
}


export class AssignableStaffFd extends FormData {
	constructor(form: AssignableStaffForm, sa: ServiceAgent) {
		super(form, sa);
	}

	setFieldValue(name: 'staffId' | 'isAssigned' | 'name' | 'email', value: string | number | boolean | null ): void {
		super.setFieldValue(name, value);
	}

	getFieldValue(name: 'staffId' | 'isAssigned' | 'name' | 'email' ): string | number | boolean | null {
		return super.getFieldValue(name);
	}
}


export interface AssignableStaffVo extends Vo {
	name?: string, 
	isAssigned?: boolean, 
	staffId?: number, 
	email?: string, 
	departmentId?: number
}
