
import { Form , Field, ChildForm } from '../form/form';
import { FormData } from '../form/formData';
import { SelectOption, Vo } from '../form/types';
import { Validators } from '@angular/forms'
import { ServiceAgent} from '../form/serviceAgent';

export class AdmissionQuotaStudentForm extends Form {
	private static _instance = new AdmissionQuotaStudentForm();

	public static getInstance(): AdmissionQuotaStudentForm {
		return AdmissionQuotaStudentForm._instance;
	}

	constructor() {
		super();
		this.fields = new Map();
		this.controls = new Map();
		this.opsAllowed = {filter: true, get: true};
	}

	public getName(): string {
		 return 'admissionQuotaStudent';
	}
}


export class AdmissionQuotaStudentFd extends FormData {
	constructor(form: AdmissionQuotaStudentForm, sa: ServiceAgent) {
		super(form, sa);
	}
	/**  this form has no editable fields. data nust be accessed as Vo and not through fd **/
}


export interface AdmissionQuotaStudentVo extends Vo {
	studentId?: number, 
	admissionQuota?: string
}
