import { Component, OnInit, ViewChild, Input, AfterViewInit } from "@angular/core";
import { ServiceAgent } from 'src/app/framework-modules/formdata/form/serviceAgent';
import { TableMetaData } from 'src/app/framework-modules/elements/ct-table/component';
import { GroupPanel } from '../../interfaces';
import { SessionComponent } from '../../component';
import { MarksForSectionForm, MarksForSectionFd, MarksForSectionVo } from 'src/app/framework-modules/formdata/gen/marksForSectionForm';
import { RowToColMeta, ColToRowMeta, Transposer, Vo } from 'src/app/framework-modules/formdata/form/types';
import { ClientContext } from 'src/app/framework-modules/formdata/form/clientContext';
import { MessageService } from 'src/app/services/messageService';
import { CtEditableTableComponent } from 'src/app/framework-modules/elements/ct-editable-table/component';
import { CtDialogComponent } from 'src/app/framework-modules/elements/ct-dialog/component';
import { MatDialog } from '@angular/material';
import { StudentDetailForm, StudentDetailFd } from 'src/app/framework-modules/formdata/gen/studentDetailForm';
import { ExcelDownloadService, ExcelTemplate } from "src/app/services/excelDownloadService";
import { ExcelParserService } from "src/app/services/excelParserService";


@Component({
  selector: 'manrks-entry-bulk-update',
  templateUrl: "./component.html",
  styleUrls: ["./component.scss"]
})
export class MarksEntryBulkUpdateComponent implements OnInit, AfterViewInit {
  @ViewChild("gridTable", { static: false }) gtable: CtEditableTableComponent;
  @Input() inputData: any;
  @Input() parentPanel: GroupPanel;
  @Input() session: SessionComponent;

  form: MarksForSectionForm;
  fd: MarksForSectionFd;
  vo: MarksForSectionVo = {};
  studentForm:StudentDetailForm;
  studentFd:StudentDetailFd;
  private students: [];


  private colToRowMeta: ColToRowMeta;
  tableData: TableMetaData;

  constructor(private sa: ServiceAgent,
    private ms: MessageService,
    private cc: ClientContext,
    private ds: ExcelDownloadService,
    private ep: ExcelParserService,
    private dialog: MatDialog) {

    this.form = MarksForSectionForm.getInstance();
    this.fd = this.form.newFormData(this.sa);
    this.studentForm = StudentDetailForm.getInstance();
    this.studentFd = this.studentForm.newFormData(this.sa);
  }

  ngOnInit() {
    this.initTable();

    if (this.inputData) {
      this.getData(this.inputData);
      console.log('Service invoked for data ' + this.inputData);
    } else {
      this.ms.showError('Page Error: input data not received for this page');
    }
  }

  initTable() {
    this.tableData = {
      data: [],
      metaData: {
        column_names: [],
        display_names: {},
        search: true,
        placeholder: "Search by Student Name, Student USN",
        editable_columns: [],
        validations: {}
      }
    };
  }

  ngAfterViewInit() {
  }

  getData(id: string) {
    this.fd.setFieldValue('subjectSectionId', id);
    this.fd.fetchData().subscribe({
      next: data => {
        this.setData(data);
      },
      error: msg => {
        console.error(msg);
        this.ms.showError('Server returned with an error message. ');
      }
    });
  }

  /**
   * 
   * @param data set the data reced from server into our table component after transposing it
   */
  setData(data) {
    this.vo = data;
    this.students = data.students;
    function sortByProperty(property) {
      return function (a, b) {
        if (a[property] > b[property])
          return 1;
        else if (a[property] < b[property])
          return -1;
        return 0;
      }
    }
    this.students.sort(sortByProperty("usn"));
    /**
     * input meta data for the trasnposer
     */
    var cols = data.names as []
    const meta: RowToColMeta = {
      datarowsAttribute: 'marks',
      headingAttribute: 'name',
      subHeadingAttribute: 'maxMarks',
      keyAttribute: 'seqNo',
      rowKeyAttribute: 'assessmentSeqNo',
      rowValueAttribute: 'marks',
      columns: data.names as [],
    };
    /**
     * table meta data
     */
    const colNames = ["name", "usn"];
    const headings = { name: "Name", usn: "USN" };
    const tableMeta = { names: colNames, headings: headings };
    this.colToRowMeta = Transposer.rowToCol(meta, tableMeta, this.students);

    const edits = [];
    for (var i = 2; i < colNames.length; i++) {
      edits.push(colNames[i]);
    }
    var valids = {}
    for(var i=1; i<=cols.length;i++){
      valids["c_"+i] = {}
      valids["c_"+i]["maxValue"] = cols[i-1]["maxMarks"];
      valids["c_"+i].pattern = '(ab|AB|Ab|\\d{0,2}\\.?\\d{0,2})';
    }
    this.tableData.metaData.column_names = colNames;
    this.tableData.metaData.display_names = headings;
    this.tableData.metaData.editable_columns = edits;
    this.tableData.metaData.validations = valids;
    this.tableData.metaData.error = "Please enter Marks or AB"
    this.tableData.data = this.students;
    this.gtable.update();
  }

  saveData() {
    Transposer.colToRow(this.colToRowMeta, this.students);
    this.fd.save().subscribe({
      next: data => {
        this.ms.showSuccess('Marks saved');
        this.calculateCie();
        this.parentPanel.navigate('MarksEntryList');
      },
      error: msg => {
        this.ms.showError("Please check for highlighted error fields");
      }
    });
  }

  popUpMessage(){
    let dialogRef = this.dialog.open(CtDialogComponent);
    dialogRef.componentInstance.title = 'NOTICE';
    dialogRef.componentInstance.content = 'Kindly modify only marks columns and none of the other columns,If you find any mismatch in data kindly contact your System Admin';
    dialogRef.componentInstance.primary = 'Download Template';
    const subscribeDialog = dialogRef.componentInstance.save.subscribe((data) => {
      // Yet to implement bulk upload functionality
      this.dialog.closeAll();
      this.downloadTemplate()
    });
  }

  save() {
    let dialogRef = this.dialog.open(CtDialogComponent);
    dialogRef.componentInstance.title = 'Save Changes';
    dialogRef.componentInstance.content = 'Are you sure you want to save changes?';
    dialogRef.componentInstance.primary = 'Save';
    const subscribeDialog = dialogRef.componentInstance.save.subscribe((data) => {
      // Yet to implement bulk upload functionality
      this.dialog.closeAll();
      this.saveData();
    });
  }

  cancel() {
    let dialogRef = this.dialog.open(CtDialogComponent);
    dialogRef.componentInstance.title = 'Discard Changes';
    dialogRef.componentInstance.content = 'Are you sure you want to discard changes?';
    dialogRef.componentInstance.primary = 'Discard';
    const subscribeDialog = dialogRef.componentInstance.save.subscribe((data) => {
      // Yet to implement bulk upload functionality
      this.dialog.closeAll();
      this.parentPanel.navigate('MarksEntryList')
    });
  }

  calculateCie() {
    const data = { subjectSectionId: this.inputData };
    this.sa.serve("calculateCieForSection", { data: data }).subscribe({
      next: data => {
        this.ms.showSuccess("Cie claculated for this subject-section");
      },

      error: msg => {
        console.error(msg);
        this.ms.showError("Server returned with an error message: " + msg[0].text);
      }
    });
  }

  onFileChange(ev){
    this.ep.parseBulkMarks(ev,this.vo.sectionName).subscribe(
      data =>{
        this.fillData(data);
      }
    );
  }

  fillData(data){
    var usnMap = {};
    this.tableData.data.forEach((element,index) =>{
      usnMap[element["usn"] as string] = index;
    });
    if(Object.keys(data).length != Object.keys(usnMap).length){
      this.ms.showError("Number of students is not same in excel and in database. Excel data manipulated!");
      return;
    }
    let flag: boolean = true;
    Object.keys(data).forEach(key =>{
      let index = usnMap[key];
      if(index == undefined){
        this.ms.showError(key+" not found in this section. Excel Data manipulated");
        flag = false;
        return;
      }
    });
    if(flag){
      Object.keys(data).forEach(key =>{
        let index = usnMap[key];
        Object.keys(data[key]).forEach(test=>{
          this.tableData.data[index][test] = data[key][test];
        })
      });
      this.ms.showSuccess("Excel Uploaded Successfully!");
    }
  }

  /**
   * Downloads an excel template to upload marks
   */
  downloadTemplate(){
    let tableHeader = [];
    let tableValues = [];
    let colWidths = []
    Object.keys(this.tableData.metaData.display_names).forEach(key=>{
      tableHeader.push(this.tableData.metaData.display_names[key]);
      tableValues.push({ key: key, width: 35, style: { font: { size: 12, name: 'Cambria' }, alignment: { horizontal: 'center', vertical: 'middle' }, border: {}, fill: null, numFmt: '', protection: null } },);
      colWidths.push(20);
    });
    /** Swapping name and USN column */
    var tmp = tableHeader[0]
    tableHeader[0] = tableHeader[1]
    tableHeader[1] = tmp;
    var tmp1 = tableValues[0]
    tableValues[0] = tableValues[1]
    tableValues[1] = tmp1;
    tableValues[1].style.alignment.horizontal = 'left';
    colWidths[0]= 30;colWidths[1]=60;
    var len = this.ds.getColumnValue(colWidths.length-1);

    let template:ExcelTemplate = {
      fixedHeader: [
        { loc: "A1", value: `${this.cc.getinstituteName()}, ${this.cc.getinstituteCity()} - ${this.cc.getinstitutePinCode().split(' ')[this.cc.getinstitutePinCode().split(' ').length-1]}`.toUpperCase(), style: { font: { size: 18, name: 'Cambria', bold: true }, alignment: { horizontal: 'center', vertical: 'middle' }, border: {}, fill: null, numFmt: '', protection: {locked:true} } },
        { loc: "A2", value: `(Autonomous Institute, Affiliated to ${this.cc.getuniversityName()})`, style: { font: { size: 12, name: 'Cambria', bold: true }, alignment: { horizontal: 'center', vertical: 'middle' }, border: {}, fill: null, numFmt: '', protection: {locked:true} } },
        { loc: "A3", value: "Marks Upload --- "+this.vo.subjectName+" - "+ this.vo.subjectCode +" - "+this.vo.sectionName, style: { font: { size: 16, name: 'Cambria', bold: true }, alignment: { horizontal: 'center', vertical: 'middle' }, border: {}, fill: null, numFmt: '', protection: {locked:true} } },
      ],
      dynamicHeader: [],
      merges: ['A1:'+len+'1', 'A2:'+len+'2', 'A3:'+len+'3'],
      tableArray: "students",
      tableStart: 5,
      tableStyle: { rowHeight: 15, },
      tableHeader: tableHeader,
      tableValues: tableValues,
      colWidths: colWidths,
      fixedFooter: [],
      dynamicFootfer: [],
      footerMerges: [],
      footerRowHeights: [],
      tableHeaderStyle: { font: { size: 14, name: 'Cambria', bold: true }, alignment: { horizontal: 'center', vertical: 'middle' }, border: {bottom: {style: 'thin'},top: {style: 'thin'}, left: {style: 'thin'},right:{style: 'thin'} }, fill: {type: 'pattern',pattern: 'solid',fgColor: {  argb: 'FF00FF00'}}, numFmt: '', protection: {locked:true} }
    }

    this.ds.excelFromTemplate(template,{"students":this.tableData.data},"Bulk Upload --- "+this.vo.subjectCode+"-"+this.vo.sectionName,this.vo.sectionName)
  }

}