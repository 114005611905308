import { Component, OnInit, ViewChild, Input } from "@angular/core";
import { GroupPanel } from "../../interfaces";
import { ServiceAgent } from "src/app/framework-modules/formdata/form/serviceAgent";
import { SessionComponent } from "../../component";
import { ClientContext } from "src/app/framework-modules/formdata/form/clientContext";
import { TableMetaData,CtTableComponent} from "src/app/framework-modules/elements/ct-table/component";
import { AllSectionSelectionFd, AllSectionSelectionForm} from "src/app/framework-modules/formdata/gen/allSectionSelectionForm";
import { MarksForSectionForm, MarksForSectionFd } from "src/app/framework-modules/formdata/gen/marksForSectionForm";
import { MessageService } from "src/app/services/messageService";
import { ColToRowMeta, RowToColMeta, Transposer, Vo, FilterRequest } from "src/app/framework-modules/formdata/form/types";
import { ExcelDownloadService } from "src/app/services/excelDownloadService";
import { CieSubjectDetailForm, CieSubjectDetailFd } from 'src/app/framework-modules/formdata/gen/cieSubjectDetailForm';
import { AssignedSubjectSelectionForm, AssignedSubjectSelectionFd } from 'src/app/framework-modules/formdata/gen/assignedSubjectSelectionForm';
import { AssignedAllSubjectSelectionForm, AssignedAllSubjectSelectionFd } from 'src/app/framework-modules/formdata/gen/assignedAllSubjectSelectionForm';
import { AssignedStaffNameForm, AssignedStaffNameFd, AssignedStaffNameVo } from 'src/app/framework-modules/formdata/gen/assignedStaffNameForm';
import { InstituteConfigurationForm, InstituteConfigurationVo, InstituteConfigurationFd } from 'src/app/framework-modules/formdata/gen/instituteConfigurationForm';
import { SubjectSectionForm, SubjectSectionFd, SubjectSectionVo } from 'src/app/framework-modules/formdata/gen/subjectSectionForm';
import { CtDialogComponent } from 'src/app/framework-modules/elements/ct-dialog/component';
import { MatDialog } from '@angular/material';

@Component({
  selector: "subject-cie-calculation-list",
  templateUrl: "./component.html",
  styleUrls: ["./component.scss"]
})
export class SubjectCieCalculationListComponent implements OnInit {
  @ViewChild("gridTable", { static: false }) gtable: CtTableComponent;
  @Input() inputData: any;
  @Input() parentPanel: GroupPanel;
  @Input() session: SessionComponent;

  /**
   * drop-downs to select a subject-section-id are managed with this form.
   * this is a local form, in the sense that the data is not sent/recd.
   */
  selectForm: AssignedAllSubjectSelectionForm;
  selectFd: AssignedAllSubjectSelectionFd;

  /**
   * for selecting subjectSection
   */
  form: AllSectionSelectionForm;
  fd: AllSectionSelectionFd;
  selectedSection = "";
  /**
   * for getting marks-list
   */
  public form1: MarksForSectionForm;
  public fd1: MarksForSectionFd;
  public cieForm:CieSubjectDetailForm;
  public cieFd:CieSubjectDetailFd;
  public form2: AssignedStaffNameForm;
  public fd2: AssignedStaffNameFd;
  public vo: AssignedStaffNameVo = {}; 
  public instituteFrom: InstituteConfigurationForm;
  public instituteFd: InstituteConfigurationFd;
  public instituteVo: InstituteConfigurationVo = {}; 
  public subjectSectionFrom: SubjectSectionForm;
  public subjectSectionFd: SubjectSectionFd;
  public subjectSectionVo: SubjectSectionVo = {};
  private colToRowMeta: ColToRowMeta;
  tableData: TableMetaData;
  submitted: boolean;

  constructor(
    private sa: ServiceAgent,
    private cc: ClientContext,
    private ms: MessageService,
    private ds: ExcelDownloadService,
    private dialog: MatDialog
  ) {
    this.form = AllSectionSelectionForm.getInstance();
    this.fd = this.form.newFormData(this.sa);
    this.form1 = MarksForSectionForm.getInstance();
    this.fd1 = this.form1.newFormData(this.sa);
    this.form2 = AssignedStaffNameForm.getInstance();
    this.fd2 = this.form2.newFormData(this.sa);
    this.instituteFrom = InstituteConfigurationForm.getInstance();
    this.instituteFd = this.instituteFrom.newFormData(this.sa);
    this.cieForm = CieSubjectDetailForm.getInstance();
    this.cieFd = this.cieForm.newFormData(this.sa);
    this.selectForm = AssignedAllSubjectSelectionForm.getInstance();
    this.selectFd = this.selectForm.newFormData(this.sa);
    this.subjectSectionFrom = SubjectSectionForm.getInstance();
    this.subjectSectionFd = this.subjectSectionFrom.newFormData(this.sa);
  }
  ngOnInit() {
    this.initTable();
  }

  ngAfterViewInit() {}

  sectionChanged(id: string) {
    if (this.selectedSection == id) {
      console.log("section remains as " + id);
      return;
    }
    this.selectedSection = id;

    if (id) {
      this.getData(id);
    }
  }

  getData(id: string) {
    this.fd1.setFieldValue("subjectSectionId", id);
    this.fd1.fetchData().subscribe({
      next: data => {
        this.selectedSection = id;
        this.setData(data);
        this.getSubmissionStatus(this.selectedSection);
      },

      error: msg => {
        console.error(msg);
        this.ms.showError("Server returned with an error message: " + msg[0].text);
      }
    });
  }

  /**
   * Get the status of CIE submission
   * @param selectedSection Status of selected subjectSection
   */
  getSubmissionStatus(selectedSection){
    var instituteId = this.cc.getInstituteId();
    const filter: FilterRequest = {
      conditions: {
        instituteId:{comp:'=',value:instituteId},
        subjectSectionId:{comp:'=',value:selectedSection},
        seasonId:{comp:'=',value:this.cc.getcurrentSeasonId()},
      }
    }
    this.fd2.filter(filter).subscribe({
      next: status => {
        this.vo = status[0];
        this.submitted = this.vo.cieSubmitted=='NO' ? false : true;
      }
    })
  }

  /**
   *
   * @param data set the data reced from server into our table component after transposing it
   */
  setData(data) {
    /**
     * table meta data
     */
    const students = data.students as [];
    const colNames = ["name", "usn", "cieMarks"];
    const headings = { name: "Name", usn: "USN", cieMarks: "CIE" };

    if (data.isIntegrated) {
      /**
       * integrated subject will have thery and lab cie as additonal columns
       */
      colNames.push("theoryCieMarks");
      colNames.push("labCieMarks");
      headings["theoryCieMarks"] = "Theory";
      headings["labCieMarks"] = "Lab";
    } else {
      /**
       * non-integrated subject will have cie for tests as child rows.
       * We have to transpose these rows into columns
       */
      const meta: RowToColMeta = {
        datarowsAttribute: "marks",
        headingAttribute: "name",
        keyAttribute: "seqNo",
        rowKeyAttribute: "assessmentSeqNo",
        rowValueAttribute: "marks",
        columns: data.names as []
      };

      const tableMeta = { names: colNames, headings: headings };
      this.colToRowMeta = Transposer.rowToCol(meta, tableMeta, students);
    }
    this.resetTable(colNames, headings, students);
  }

  showIntegratedCie(data) {}

  initTable() {
    this.tableData = {
      data: [],
      metaData: {
        column_names: [],
        display_names: {},
        search: true,
        placeholder: "Search by Student Name, Student USN"
      }
    };
  }

  resetTable(colNames: string[], headings: object, data: object[]) {
    this.tableData.metaData.column_names = colNames;
    this.tableData.metaData.display_names = headings as {};
    this.tableData.data = data as Vo[];
    this.gtable.update();
  }

  calculateCie() {
    const data = { subjectSectionId: this.selectedSection };
    this.sa.serve("calculateCieForSection", { data: data }).subscribe({
      next: data => {
        this.ms.showSuccess("Cie claculated for this subject-section");
        this.getData(this.selectedSection);
      },

      error: msg => {
        console.error(msg);
        this.ms.showError("Server returned with an error message: " + msg[0].text);
      }
    });
  }

  doDownload(){
    this.sa.serve("get_cieSubjectSectionDetail", {
      data:{subjectSectionId:this.selectFd.getFieldValue('subjectSectionId')}
    }
    ).subscribe(
      data=>{
        this.ds.downloadCIE(data);
      }
    )
  }

  /**
   * Submit CIE to COE
   * Freezes the CIE of the selected subjectsectiom
   */
  submit(){
    let dialogRef = this.dialog.open(CtDialogComponent);
    dialogRef.componentInstance.title = 'Submit';
    dialogRef.componentInstance.content = 'Current CIE will be submitted to the COE and marks entry will be frozen. Are you sure you want to submit?';
    dialogRef.componentInstance.primary = 'Submit';
    const subscribeDialog = dialogRef.componentInstance.save.subscribe((data) => {
      const parameters = { subjectSectionId: this.selectedSection };
      this.sa.serve("freezeSubjectSection",{data:parameters}).subscribe({
       next: data => {
         this.dialog.closeAll();
         this.ms.showSuccess('CIE Submitted to COE successfully!');
         this.parentPanel.navigate('SubjectCieCalculationList');
       },
       error: msg => {
         this.dialog.closeAll();
         this.ms.showError('Server returned with an error message. ');
       }
     });
    });
  }

}
