import { Component, OnInit, Input, ViewChild, ViewContainerRef, Type, ComponentFactoryResolver } from "@angular/core";
import { DynamicPanel, LandingPanel, GroupPanel } from '../interfaces'
import { SessionComponent, SecondaryMenu } from '../component';
import { PendingAuthorisationListComponent } from './pending-authorisation-list/component';
import { AuthorisationListComponent } from './authorisation-list/component'
import { MenuService } from 'src/app/services/menuService';
import { AuthorisationEditComponent } from './authorisation-edit/component';
import { MessageService } from 'src/app/services/messageService';
import { ClientContext } from 'src/app/framework-modules/formdata/form/clientContext';
/**
 Const to map the screen-component to screen-name to rended the facotry
 */
const MENU:SecondaryMenu = {
  AuthorisationList: { comp: AuthorisationListComponent, title: "Authorisation Status", sideNav: true, pageId: "D01", displayonSidenav:true ,name: "Authorisation Status", mainNav: true,order:2 },
  PendingAuthorisation: { comp: PendingAuthorisationListComponent, title: "Pending Authorisation", sideNav: true, pageId: "D02", displayonSidenav: true, name: "Pending Authorisation", mainNav: true,order:1 },
  AuthorisationEdit: { comp: AuthorisationEditComponent, title: "Authorisation", sideNav: true, pageId: "D03", displayonSidenav: false, name: "Authorisation Edit", mainNav: true },
};

@Component({
  selector: "authorisation",
  templateUrl: "./component.html",
  styleUrls: ["./component.scss"]
})
export class AuthorisationComponent implements OnInit, GroupPanel {
  @Input() inputData: any;
  @Input() parentPanel: SessionComponent;
  menu: { [key: string]: Type<DynamicPanel> };
  sidenav: boolean = false;
  displayNames: {}
  cardHeader: any;
  public items:{};
  public defaultSelected: string;
  pScreens: string[];
  screens: string[];
  defaultComponent: string;
  /**
    container for the child page loaded from the seconday navbar
   */

  @ViewChild("secondaryContainer", { static: true, read: ViewContainerRef }) container: ViewContainerRef;
  constructor(private componentFactoryResolver: ComponentFactoryResolver, private mns: MenuService, private ms: MessageService, private cc: ClientContext) { 
    this.pScreens = this.mns.getPermittedScreens();
    this.screens = this.mns.getScreens();
      Object.keys(MENU).forEach( key => {
      if(this.screens.includes(MENU[key].pageId) && this.defaultSelected == undefined){
        this.defaultSelected = MENU[key].name;
        this.cardHeader = MENU[key].title;
        this.defaultComponent = key;
      }
    })
  }

  ngOnInit() {
    this.navigate(this.defaultComponent, {});
    this.items = MENU;
  }

  /**
   *
   * @param compName is the name of the component to navigate to
   * @param data is for any related data needed
   */
  public navigate(compName: string, data: any): void {
    console.log('Navigating to ' + compName + ' with data ', data);
    const item = MENU[compName];
    if (!item) {
      console.error(compName + ' is not a valid manu item. Navigation failed.');
      return;
    }

    if (!item.name) {
      console.error('Navigation abandoned because name is missing for this item');
      return;
    }

    if(!this.cc.isPageValid(item.pageId)){
      this.ms.showInfo('Navigation Error: ' + compName + ' is not authorized for this user.');
      return;
    }

    this.parentPanel.toggleMainNav(item.mainNav);
    this.sidenav = item.sideNav;
    this.defaultSelected = item.name;
    this.cardHeader = item.title;
    this.container.clear();
    const factory = this.componentFactoryResolver.resolveComponentFactory(item.comp);
    const c = this.container.createComponent(factory);
    c.instance.inputData = data;
    c.instance.parentPanel = this;
    c.changeDetectorRef.detectChanges();
  }
  fullScreenMode(){
    this.sidenav = false;
    this.parentPanel.hideMainNav();
  }

  halfScreenMode(){
    this.sidenav = true;
    this.parentPanel.showMainNav();
  }
}
