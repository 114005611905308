import { Component, OnInit, ViewChild, Input} from "@angular/core";
import { DynamicPanel, GroupPanel } from '../../interfaces';
import { ServiceAgent } from 'src/app/framework-modules/formdata/form/serviceAgent';
import { TableMetaData } from 'src/app/framework-modules/elements/ct-table/component'
import { SessionComponent } from '../../component';
import { MatDialog } from '@angular/material';
import { CtCheckboxTableComponent } from 'src/app/framework-modules/elements/ct-checkbox-table/component';
import { ExamAttendanceForm, ExamAttendanceVo } from 'src/app/framework-modules/formdata/gen/examAttendanceForm';
import { CtDialogComponent } from 'src/app/framework-modules/elements/ct-dialog/component';
import { CtSpinner } from 'src/app/framework-modules/elements/ct-spinner/component';
import { PanelData } from 'src/app/framework-modules/formdata/form/formData';
import { MessageService } from 'src/app/services/messageService';
import { ExamRegisterVo } from 'src/app/framework-modules/formdata/gen/examRegisterForm';
import { FormAConfigFd, FormAConfigForm } from "src/app/framework-modules/formdata/gen/formAConfigForm";
import { FormADownloadForm, FormADownloadFd } from "src/app/framework-modules/formdata/gen/formADownloadForm";
import { MakeupFormADownloadForm, MakeupFormADownloadFd } from "src/app/framework-modules/formdata/gen/makeupFormADownloadForm";

import { ExcelDownloadService } from "src/app/services/excelDownloadService";
import * as _ from 'lodash';
import { ClientContext } from 'src/app/framework-modules/formdata/form/clientContext';

@Component({
  selector: "exam-attendance-edit",
  templateUrl: "./component.html",
  styleUrls: ["./component.scss"],
})

export class ExamAttendanceEditComponent implements DynamicPanel, OnInit {
  @ViewChild("gridTable", { static: false }) gtable: CtCheckboxTableComponent;
  @ViewChild("gridTable1", { static: false }) gtable1: CtCheckboxTableComponent;

  @ViewChild("spinner", { static: false }) spinner: CtSpinner;
  @Input() inputData: any;
  @Input() parentPanel: GroupPanel;
  @Input() session: SessionComponent;

  public tableData: TableMetaData;
  public tableData1: TableMetaData;

  private pd: PanelData;
  public vo: ExamAttendanceVo;
  private students: ExamRegisterVo[];
  public isMakeup: boolean = false;

  public downloadSettingsForm: FormAConfigForm;
  public downloadSettingsFd: FormAConfigFd;
  public downloadForm:FormADownloadForm;
  public downloadFd:FormADownloadFd;
  public MakeupFormADownloadForm: MakeupFormADownloadForm;
  public MakeupFormADownloadFd: MakeupFormADownloadFd;
  

  

  constructor(private sa: ServiceAgent,
    private dialog: MatDialog,
    private ms: MessageService,
    private ds: ExcelDownloadService,private ctx: ClientContext) {
    this.pd = ExamAttendanceForm.getInstance().newPanelData(this.sa);
    this.vo = {};
    this.students = [];
    this.downloadForm = FormADownloadForm.getInstance();
    this.downloadFd = this.downloadForm.newFormData(this.sa);

    this.MakeupFormADownloadForm = MakeupFormADownloadForm.getInstance();
    this.MakeupFormADownloadFd = this.MakeupFormADownloadForm.newFormData(this.sa);
    this.downloadSettingsForm = FormAConfigForm.getInstance();
    this.downloadSettingsFd = this.downloadSettingsForm.newFormData(this.sa);
  }

  ngOnInit() {
    this.metaDataInit();
  }

  ngAfterViewInit() {
    if (this.inputData && this.inputData.offeredSubjectId && this.inputData.departmentId) {
        this.getData(this.inputData);
    } else {
      this.ms.showError('Page with no/invalid data');
    }
  }

  /*update correct data in the table */
  /* use if condition for proper download of A form*/

  metaDataInit() {
    this.tableData = {
      data: [],
      metaData: {
        column_names: [
          "name",
          "usn",
          "department",
          "level"
        ],
        display_names: {
          name: "Name",
          usn: "USN",
          department: "Department Name",
          level: "Level"
        },
        selectAttr:"hasAttendedExam",
        search: true,
        placeholder: "Search By Name,USN,Department Name...",
        managerFunction: "SAVE & DOWNLOAD",
        itemName: "Students"
      },
    };
  }

  
  private getData(data) {
    this.spinner.show('Getting attendance register..');
    /**
     * offeredSubjectId is the primary key in the form
     */
    this.pd.fetchFor(data).subscribe({     
      next: data => {
        this.vo = data;
        var students = [];
        (data.students as []).forEach(std =>{
          if(std['hasAttendedExam'] == false){
              students.push(std);
            } 
        });
        this.tableData.data = students;
        this.gtable.update(this.spinner);
      },

      error: msg => {
        this.spinner.hide();
        this.ms.showError('server returned with errors');
      }
    });
  }


  private saveData() {
    if(!this.downloadSettingsFd.validateForm()){
      this.ms.showError("Please fill date and time to download!");
      return;
    }
    this.spinner.show("Updating Attendance.....")
    this.pd.setFieldValue('isMakeup',this.inputData.isMakeup);
    this.pd.save().subscribe({
      next: data => {
        this.doDownload();
      },

      error: msg => {
        this.ms.showError('server returned with errors');
        this.spinner.hide();
      }
    });
  }


  /**
   * Function is called when the user clicks save button.
   * Saves the assigned evaluators to a asubject.
   */
  save() {
    let dialogRef = this.dialog.open(CtDialogComponent);
    dialogRef.componentInstance.title = 'Save Changes';
    dialogRef.componentInstance.content = 'Are you sure you want to save changes?';
    dialogRef.componentInstance.primary = 'Save';
    const subscribeDialog = dialogRef.componentInstance.save.subscribe((data) => {
      this.saveData();
      this.dialog.closeAll();
    });
  }

  /**
   * Function is called when the user clicks cancel button.
   * Goes back to assign Evaluators list screen
   */
  cancel() {
    let dialogRef = this.dialog.open(CtDialogComponent);
    dialogRef.componentInstance.title = 'Discard Changes';
    dialogRef.componentInstance.content = 'Are you sure you want to discard changes?';
    dialogRef.componentInstance.primary = 'Discard';
    const subscribeDialog = dialogRef.componentInstance.save.subscribe((data) => {
      this.dialog.closeAll();
      this.parentPanel.navigate("ExamAttendanceList");
    });
  }

  /**
   * Downloads Form-A
   */
  doDownload(){
    var title = ""
    if(this.ctx.getInstituteId() == 1){
      title = "B.E/B.Arch/M.B.A/M.C.A/M.Tech. _________ Semester Examination __________ (MM/YYYY)"
    }
    if(this.ctx.getInstituteId() == 2){
      title = "B.A/B.Sc/B.B.A/B.C.A/B.Voc/B.Com _________ Semester Examination __________ (MM/YYYY)"
    }
    this.downloadFd.setFieldValue('offeredSubjectId',this.inputData.offeredSubjectId);
    this.downloadFd.fetchData().subscribe(
      data=>{
        var programs = {};
        if(this.downloadSettingsFd.getFieldValue('departmentWise') == true){
          (data.students as unknown as any[]).forEach(element => {
            var pn = element["programName"];
            if(programs[pn] == undefined){
              programs[pn] = _.cloneDeep(data);
              programs[pn].students = [element];
            }
            else{
              programs[pn].students.push(element)
            }
          });
          console.log(programs);
          Object.keys(programs).forEach(program=>{
            this.ds.downloadFormA(programs[program],program + " - " + this.inputData.subjectName,this.downloadSettingsFd.getFieldValue('dateOfIssue'),this.downloadSettingsFd.getFieldValue('examTimings'),title);
          });
        }
        else{
          this.ds.downloadFormA(data,this.inputData.subjectName,this.downloadSettingsFd.getFieldValue('dateOfIssue'),this.downloadSettingsFd.getFieldValue('examTimings'),title);
        }
        this.ms.showSuccess('Attendance Updated and Download Successful');
        this.spinner.hide()
        this.parentPanel.navigate('ExamAttendanceList');
      }
    )
  }
}