import { Component, OnInit, ViewChild, Input } from "@angular/core";
import { DynamicPanel, GroupPanel } from '../../interfaces';
import { ServiceAgent } from 'src/app/framework-modules/formdata/form/serviceAgent';
import { CtTableComponent, TableMetaData, } from '../../../../framework-modules/elements/ct-table/component'
import { FilterRequest } from 'src/app/framework-modules/formdata/form/types';
import { SessionComponent } from '../../component';
import { CtSpinner } from 'src/app/framework-modules/elements/ct-spinner/component';
import { PanelData } from 'src/app/framework-modules/formdata/form/formData';
import { MessageService } from 'src/app/services/messageService';
import { EvaluatorSelectionForm, EvaluatorSelectionFd } from 'src/app/framework-modules/formdata/gen/evaluatorSelectionForm';
import { MakeUpPacketListForm, MakeUpPacketListVo } from 'src/app/framework-modules/formdata/gen/makeUpPacketListForm';


@Component({
  selector: "makeup-packet-list-evaluator",
  templateUrl: "./component.html",
  styleUrls: ["./component.scss"]
})

export class MakeUpPacketListByEvaluatorComponent implements DynamicPanel, OnInit {
  @ViewChild("gridTable", { static: false }) gtable: CtTableComponent;
  @ViewChild("spinner", { static: false }) public spinner: CtSpinner;
  @Input() inputData: any;
  @Input() parentPanel: GroupPanel;
  @Input() session: SessionComponent;
  public form: EvaluatorSelectionForm;
  public fd: EvaluatorSelectionFd;
  public tableData: TableMetaData;

  private pd: PanelData;
  private packets: MakeUpPacketListVo[];


  constructor(private sa: ServiceAgent, private ms: MessageService) {
    this.form = EvaluatorSelectionForm.getInstance();
    this.fd = this.form.newFormData(this.sa);

    this.pd = MakeUpPacketListForm.getInstance().newPanelData(this.sa);
  }


  ngOnInit() {
    this.metaDataInit();
  }

  metaDataInit() {
    this.tableData = {
      data: [],
      metaData: {
        column_names: [
          "packetCode",
          "name",
          "phoneNumber"
        ],
        display_names: {
          packetCode: "Packet",
          name: "Evaluator Name",
          phoneNumber: "Phone Number"
        },
        search: true,
        edit:true,
        view:true,
        buttonName:"Enter Marks",
        placeholder: "Search By Evaluator Name, Packet ID....",
      }
    };
  }

  public listForEval() {
    const offerId = this.fd.getFieldValue('offeredSubjectId');
    const evalId = this.fd.getFieldValue('evaluatorId');
    if (!offerId || !evalId) {
      this.ms.showError('Please select an evaluator');
      return;
    }
    const filter: FilterRequest = {
      conditions: {
        offeredSubjectId: { comp: "=", value: offerId },
        assignedTo: { comp: "=", value: evalId }
      }
    };
    this.getData(filter);
  }

  /**
   * get list of packets for this offer-id. PacketListForm is used
   * @param offerId 
   */
  private getData(filter) {
    this.spinner.show('Getting packets ..')
    this.pd.filter(filter).subscribe({
      next: list => {
        console.log(list.length + ' packets');
        this.tableData.data = list;
        this.packets = list;
        this.gtable.update(this.spinner);
      },
      error: msgs => {
        this.spinner.hide();
        this.ms.showError('Server returned with errors..');
      }
    });
  }

  private getParams(idx) {
    const vo = this.packets[idx];
    return { 
      offeredSubjectId: vo.offeredSubjectId, 
      packetCode: vo.packetCode,
      isMakeUp: true
    };
  }
  public editPacket(idx) {
    this.parentPanel.navigate('MakeUpValuationEdit', {data: this.getParams(idx), screen: "MakeUpPacketListByEvaluator"});
  }
  public viewPacket(idx) {
    this.parentPanel.navigate('MakeUpValuationView', {data: this.getParams(idx), screen: "MakeUpPacketListByEvaluator"});
  }
}
