
import { Form , Field, ChildForm } from '../form/form';
import { FormData } from '../form/formData';
import { SelectOption, Vo } from '../form/types';
import { Validators } from '@angular/forms'
import { ServiceAgent} from '../form/serviceAgent';
import { ChildSubjectForm, ChildSubjectVo } from './childSubjectForm';

export class SubjectForm extends Form {
	private static _instance = new SubjectForm();
	subjectId:Field = {
		name:'subjectId'
		,controlType: 'Hidden'
		,label: 'subjectId'
		,valueType: 1
		,errorId: 'invalidId'
		,maxValue: 9999999999999
	};
	instituteId:Field = {
		name:'instituteId'
		,controlType: 'Hidden'
		,label: 'instituteId'
		,valueType: 1
		,errorId: 'invalidTenentKey'
		,maxValue: 9999999999999
	};
	departmentId:Field = {
		name:'departmentId'
		,controlType: 'Dropdown'
		,label: 'Offering Department'
		,isRequired: true
		,listName: 'departmentList'
		,valueType: 1
		,errorId: 'invalidId'
		,maxValue: 9999999999999
	};
	subjectTypeId:Field = {
		name:'subjectTypeId'
		,controlType: 'Dropdown'
		,label: 'Type of Subject'
		,isRequired: true
		,listName: 'subjectTypeList'
		,valueType: 1
		,errorId: 'invalidId'
		,maxValue: 9999999999999
	};
	name:Field = {
		name:'name'
		,controlType: 'Input'
		,label: 'Subject Name'
		,isRequired: true
		,valueType: 0
		,errorId: 'invalidName'
		,maxLength: 150
	};
	code:Field = {
		name:'code'
		,controlType: 'Input'
		,label: 'Subject Code'
		,isRequired: true
		,valueType: 0
		,errorId: 'invalidCode'
		,maxLength: 50
	};
	lectureCredits:Field = {
		name:'lectureCredits'
		,controlType: 'Input'
		,label: 'Lecture Credits'
		,valueType: 1
		,defaultValue: 0
		,errorId: 'invalidInteger'
		,maxValue: 9999999999999
	};
	practicalCredits:Field = {
		name:'practicalCredits'
		,controlType: 'Input'
		,label: 'Practical Credits'
		,valueType: 1
		,defaultValue: 0
		,errorId: 'invalidInteger'
		,maxValue: 9999999999999
	};
	tutorialCredits:Field = {
		name:'tutorialCredits'
		,controlType: 'Input'
		,label: 'Tutorial Credits'
		,valueType: 1
		,defaultValue: 0
		,errorId: 'invalidInteger'
		,maxValue: 9999999999999
	};
	totalCredits:Field = {
		name:'totalCredits'
		,controlType: 'Input'
		,label: 'Total Credits'
		,isRequired: true
		,valueType: 1
		,defaultValue: 0
		,errorId: 'invalidInteger'
		,maxValue: 9999999999999
	};
	parentId:Field = {
		name:'parentId'
		,controlType: 'Hidden'
		,label: 'parentId'
		,valueType: 1
		,defaultValue: 0
		,errorId: 'invalidId'
		,maxValue: 9999999999999
	};
	slno:Field = {
		name:'slno'
		,controlType: 'Input'
		,label: 'Order in grade card'
		,valueType: 1
		,defaultValue: 0
		,errorId: 'invalidInteger'
		,maxValue: 9999999999999
	};
	isExamsRequired:Field = {
		name:'isExamsRequired'
		,controlType: 'Input'
		,label: 'Is Exam Required'
		,valueType: 3
		,defaultValue: true
		,errorId: 'invalidBool'
	};
	isPrintedInHallticket:Field = {
		name:'isPrintedInHallticket'
		,controlType: 'Input'
		,label: 'Is Printed in HallTicket'
		,valueType: 3
		,defaultValue: true
		,errorId: 'invalidBool'
	};
	isElective:Field = {
		name:'isElective'
		,controlType: 'Input'
		,label: 'Is Elective'
		,valueType: 3
		,defaultValue: false
		,errorId: 'invalidBool'
	};
	isIntegrated:Field = {
		name:'isIntegrated'
		,controlType: 'Hidden'
		,label: 'isIntegrated'
		,valueType: 3
		,defaultValue: false
		,errorId: 'invalidBool'
	};
	isActive:Field = {
		name:'isActive'
		,controlType: 'Input'
		,label: 'Is Active'
		,valueType: 3
		,defaultValue: true
		,errorId: 'invalidBool'
	};
	packetPrefix:Field = {
		name:'packetPrefix'
		,controlType: 'Input'
		,label: 'Packet Prefix'
		,valueType: 0
		,defaultValue: ''
		,errorId: 'invalidText'
		,maxLength: 1000
	};
	gradingSchemeId:Field = {
		name:'gradingSchemeId'
		,controlType: 'Input'
		,label: 'Grading Scheme'
		,isRequired: true
		,listName: 'gradingSchemeList'
		,valueType: 1
		,errorId: 'invalidId'
		,maxValue: 9999999999999
	};
	isAttendanceRequired:Field = {
		name:'isAttendanceRequired'
		,controlType: 'Input'
		,label: 'Is Attendance Required'
		,isRequired: true
		,valueType: 3
		,defaultValue: true
		,errorId: 'invalidBool'
	};
	qpCode:Field = {
		name:'qpCode'
		,controlType: 'Input'
		,label: 'QP Code'
		,valueType: 0
		,errorId: 'invalidCode'
		,maxLength: 50
	};

	childSubjects: ChildForm = {
		name:'childSubjects',
		form:ChildSubjectForm.getInstance(),
		isEditable:true,
		isTabular:true,
		label:'',
		minRows:0,
		maxRows:0,
		errorId:null
	};

	public static getInstance(): SubjectForm {
		return SubjectForm._instance;
	}

	constructor() {
		super();
		this.fields = new Map();
		this.controls = new Map();
		this.controls.set('subjectId', [Validators.max(9999999999999)]);
		this.fields.set('subjectId', this.subjectId);
		this.controls.set('instituteId', [Validators.max(9999999999999)]);
		this.fields.set('instituteId', this.instituteId);
		this.controls.set('departmentId', [Validators.required, Validators.max(9999999999999)]);
		this.fields.set('departmentId', this.departmentId);
		this.controls.set('subjectTypeId', [Validators.required, Validators.max(9999999999999)]);
		this.fields.set('subjectTypeId', this.subjectTypeId);
		this.controls.set('name', [Validators.required, Validators.maxLength(150)]);
		this.fields.set('name', this.name);
		this.controls.set('code', [Validators.required, Validators.maxLength(50)]);
		this.fields.set('code', this.code);
		this.controls.set('lectureCredits', [Validators.max(9999999999999)]);
		this.fields.set('lectureCredits', this.lectureCredits);
		this.controls.set('practicalCredits', [Validators.max(9999999999999)]);
		this.fields.set('practicalCredits', this.practicalCredits);
		this.controls.set('tutorialCredits', [Validators.max(9999999999999)]);
		this.fields.set('tutorialCredits', this.tutorialCredits);
		this.controls.set('totalCredits', [Validators.required, Validators.max(9999999999999)]);
		this.fields.set('totalCredits', this.totalCredits);
		this.controls.set('parentId', [Validators.max(9999999999999)]);
		this.fields.set('parentId', this.parentId);
		this.controls.set('slno', [Validators.max(9999999999999)]);
		this.fields.set('slno', this.slno);
		this.controls.set('isExamsRequired', []);
		this.fields.set('isExamsRequired', this.isExamsRequired);
		this.controls.set('isPrintedInHallticket', []);
		this.fields.set('isPrintedInHallticket', this.isPrintedInHallticket);
		this.controls.set('isElective', []);
		this.fields.set('isElective', this.isElective);
		this.controls.set('isIntegrated', []);
		this.fields.set('isIntegrated', this.isIntegrated);
		this.controls.set('isActive', []);
		this.fields.set('isActive', this.isActive);
		this.controls.set('packetPrefix', [Validators.maxLength(1000)]);
		this.fields.set('packetPrefix', this.packetPrefix);
		this.controls.set('gradingSchemeId', [Validators.required, Validators.max(9999999999999)]);
		this.fields.set('gradingSchemeId', this.gradingSchemeId);
		this.controls.set('isAttendanceRequired', [Validators.required]);
		this.fields.set('isAttendanceRequired', this.isAttendanceRequired);
		this.controls.set('qpCode', [Validators.maxLength(50)]);
		this.fields.set('qpCode', this.qpCode);

		this.childForms = new Map();
		this.childForms.set('childSubjects', this.childSubjects);
		this.opsAllowed = {get: true, create: true, update: true, filter: true};
		this.listFields = ['departmentId','subjectTypeId','gradingSchemeId'];
		this.keyFields = ["subjectId"];
	}

	public getName(): string {
		 return 'subject';
	}
}


export class SubjectFd extends FormData {
	constructor(form: SubjectForm, sa: ServiceAgent) {
		super(form, sa);
	}

	setFieldValue(name: 'subjectId' | 'instituteId' | 'departmentId' | 'subjectTypeId' | 'name' | 'code' | 'lectureCredits' | 'practicalCredits' | 'tutorialCredits' | 'totalCredits' | 'parentId' | 'slno' | 'isExamsRequired' | 'isPrintedInHallticket' | 'isElective' | 'isIntegrated' | 'isActive' | 'packetPrefix' | 'gradingSchemeId' | 'isAttendanceRequired' | 'qpCode', value: string | number | boolean | null ): void {
		super.setFieldValue(name, value);
	}

	getFieldValue(name: 'subjectId' | 'instituteId' | 'departmentId' | 'subjectTypeId' | 'name' | 'code' | 'lectureCredits' | 'practicalCredits' | 'tutorialCredits' | 'totalCredits' | 'parentId' | 'slno' | 'isExamsRequired' | 'isPrintedInHallticket' | 'isElective' | 'isIntegrated' | 'isActive' | 'packetPrefix' | 'gradingSchemeId' | 'isAttendanceRequired' | 'qpCode' ): string | number | boolean | null {
		return super.getFieldValue(name);
	}
}


export interface SubjectVo extends Vo {
	isElective?: boolean, 
	code?: string, 
	lectureCredits?: number, 
	isExamsRequired?: boolean, 
	practicalCredits?: number, 
	departmentId?: number, 
	tutorialCredits?: number, 
	isPrintedInHallticket?: boolean, 
	gradingSchemeId?: number, 
	isAttendanceRequired?: boolean, 
	isActive?: boolean, 
	subjectId?: number, 
	parentId?: number, 
	slno?: number, 
	createdAt?: string, 
	totalCredits?: number, 
	qpCode?: string, 
	name?: string, 
	instituteId?: number, 
	isIntegrated?: boolean, 
	subjectTypeId?: number, 
	packetPrefix?: string, 
	updatedAt?: string, 
	childSubjects?: ChildSubjectVo
}
