import { Component,Input, Output, EventEmitter, OnInit } from "@angular/core";
import { DynamicPanel, LandingPanel, GroupPanel } from '../../interfaces';
import { ServiceAgent } from 'src/app/framework-modules/formdata/form/serviceAgent';
import { SessionComponent } from '../../component';
import { FormData } from 'src/app/framework-modules/formdata/form/formData';
import { DepartmentForm, DepartmentVo } from 'src/app/framework-modules/formdata/gen/departmentForm';
import { MessageService } from 'src/app/services/messageService';
import { CtDialogComponent } from 'src/app/framework-modules/elements/ct-dialog/component';
import { MatDialog } from '@angular/material';


@Component({
  selector: "department-edit",
  templateUrl: "./component.html",
  styleUrls: ["./component.scss"]
})
export class DepartmentEditComponent implements DynamicPanel,OnInit{
  @Input() inputData: any;
  @Input() parentPanel: GroupPanel;
  @Input() session:SessionComponent;
  
  public form:DepartmentForm;
  public fd:FormData;
  public vo:DepartmentVo;
  
  constructor(private sa:ServiceAgent,private ms:MessageService, private dialog: MatDialog){
    this.form = DepartmentForm.getInstance();
    this.fd = new FormData(this.form,this.sa);

  }

  ngOnInit(){
    this.fd.setFieldValue(this.form.departmentId.name, this.inputData);
    this.fd.fetchData().subscribe({
    	next: vo=>{
        console.log(vo)
      },
    	error: msg => console.error("Error from server ", msg)
    });
  }

  save(){
    let dialogRef = this.dialog.open(CtDialogComponent);
    dialogRef.componentInstance.title = 'Save Changes';
    dialogRef.componentInstance.content = 'Are you sure you want to save changes?';
    dialogRef.componentInstance.primary = 'Save';
    const subscribeDialog = dialogRef.componentInstance.save.subscribe((data) => {
      this.dialog.closeAll();
      this.doSaveDepartment();
    });
  }
  
  doCancel() {
    let dialogRef = this.dialog.open(CtDialogComponent);
    dialogRef.componentInstance.title = 'Discard Changes';
    dialogRef.componentInstance.content = 'Are you sure you want to discard changes?';
    dialogRef.componentInstance.primary = 'Discard';
    const subscribeDialog = dialogRef.componentInstance.save.subscribe((data) => {
      this.dialog.closeAll();
      this.parentPanel.navigate("DepartmentList", this.fd.getFieldValue('instituteId'));
    }); 
  }

  /**
   * Adds a new Department to the client's trust
   */
  doSaveDepartment() {
    this.fd.save().subscribe({
      next: vo => {
        this.ms.showSuccess("Change(s) saved successfully!")
      this.parentPanel.navigate("DepartmentList", this.fd.getFieldValue('instituteId'));
      },
      error: err => {
        this.ms.showError(err);
      }
    });
  }

  cancel(){
    this.parentPanel.navigate("DepartmentList", this.fd.getFieldValue('instituteId'));
  }

}