import { Component, OnInit, ViewChild, Input } from "@angular/core";
import { GroupPanel } from '../../interfaces';
import { ServiceAgent } from 'src/app/framework-modules/formdata/form/serviceAgent';
import { SessionComponent } from '../../component';
import { TableMetaData, CtTableComponent } from 'src/app/framework-modules/elements/ct-table/component';
import { CtSpinner } from 'src/app/framework-modules/elements/ct-spinner/component';
import { CtDialogComponent } from 'src/app/framework-modules/elements/ct-dialog/component';
import { MatDialog } from '@angular/material';
import { MessageService } from 'src/app/services/messageService';
import { ExcelDownloadService } from 'src/app/services/excelDownloadService';
import { OfferedSubjectSelectionFd, OfferedSubjectSelectionForm } from 'src/app/framework-modules/formdata/gen/offeredSubjectSelectionForm';
import { SubjectwiseResultFd, SubjectwiseResultForm, SubjectwiseResultVo } from 'src/app/framework-modules/formdata/gen/subjectwiseResultForm';
import { FilterRequest } from 'src/app/framework-modules/formdata/form/types';
import { CodedPaperListFd, CodedPaperListForm } from "src/app/framework-modules/formdata/gen/codedPaperListForm";
import { ClientContext } from "src/app/framework-modules/formdata/form/clientContext";

@Component({
  selector: "subject-grade-calculation",
  templateUrl: "./component.html",
  styleUrls: ["./component.scss"]
})


export class SubjectGradeCalculationComponent implements OnInit {
  @ViewChild("gridTable", { static: false }) gtable: CtTableComponent;
  @ViewChild("spinner", { static: false }) public spinner: CtSpinner;
  @Input() inputData: any;
  @Input() parentPanel: GroupPanel;
  @Input() session: SessionComponent;

  form: OfferedSubjectSelectionForm;
  fd: OfferedSubjectSelectionFd;
  tableData: TableMetaData;

  public subjectForm: SubjectwiseResultForm;
  public subjectFd: SubjectwiseResultFd;
  public vo: SubjectwiseResultVo = {};
  valForm: CodedPaperListForm;
  valFd: CodedPaperListFd;

  constructor(private sa: ServiceAgent, private ms: MessageService, private dialog: MatDialog, private ds: ExcelDownloadService,private cc: ClientContext) {
    this.form = OfferedSubjectSelectionForm.getInstance();
    this.fd = this.form.newFormData(this.sa);
    this.subjectForm = SubjectwiseResultForm.getInstance();
    this.subjectFd = this.subjectForm.newFormData(this.sa);
    this.valForm = CodedPaperListForm.getInstance();
    this.valFd = this.valForm.newFormData(this.sa);
  }

  ngOnInit() {
    this.metaDataInit();
    this.valFd.setFieldValue('valuationRound',this.cc.getValue('_user').currentValuationRound)
  }

  /**
   * Event change listener for the offered subhject ID dropdown.
   * @param $event Offfered subject ID
   */
  idChanged(){
    const filter: FilterRequest ={
      conditions: {
        offeredSubjectId:{comp:"=",value:this.fd.getFieldValue('offeredSubjectId')}
      },
      maxRows: 10,
      sort: {usn:"asc"}
    }
    const obs = this.subjectFd.filter(filter);
    const that = this;
    this.spinner.show("Loading Data.....");
    obs.subscribe(
      data => {
        this.tableData.data = data;
        this.gtable.update();
        this.spinner.hide();
    });
  }

  /**
   * opens dialog to confirm calculation
   */
  calculate() {
    let dialogRef = this.dialog.open(CtDialogComponent);
    dialogRef.componentInstance.title = 'Calculate Grades';
    dialogRef.componentInstance.content = 'Are you sure you want calculate grades?';
    dialogRef.componentInstance.primary = 'Calculate';
    const subscribeDialog = dialogRef.componentInstance.save.subscribe((data) => {
      this.dialog.closeAll();
      this.doCalculate();
    });
  }

  private doCalculate(){
    this.spinner.show("Calculating Grades......");
    this.sa.serve("assignGrades",{data:{offeredSubjectId:this.fd.getFieldValue('offeredSubjectId'),valuationRound:this.valFd.getFieldValue('valuationRound'),isMakeUp: false}}).subscribe({
      next: data => {
        this.ms.showSuccess("Grades successfully calculated");
        this.spinner.hide();
        this.idChanged();
      },
      error: err =>{
        this.spinner.hide();
      }
    })
  }

  /**
   * Initializing table metadata.
   */
  metaDataInit() {
    this.tableData = {
      data: [],
      metaData: {
        column_names: [
          "name",
          "usn",
          "departmentName",
          "cieMarks",
          "examMarks",
          "totalMarks",
          "grade",
          "gradePoints"
        ],
        display_names: {
          "name":"Student Name",
          "usn":"USN",
          "departmentName":"Department Name",
          "cieMarks":"CIE",
          "examMarks":"Exam Marks",
          "totalMarks": "Final Marks",
          "grade":"Grade",
          "gradePoints":"Grade Points",
        },
        styleAttribute: {
          "grade": [
            {comp: '=', value: 'F', style:{'color':'red', 'font-weight': 800}},
            {comp: '=', value: 'W', style:{'color':'black', 'font-weight': 800}},
            {comp: '=', value: 'X', style:{'color':'green', 'font-weight': 800}},
           ]
        },
        search: true,
        placeholder: "Search By Student Name, USN and Department"
      }
    };
  }
}