
import { Form , Field, ChildForm } from '../form/form';
import { FormData } from '../form/formData';
import { SelectOption, Vo } from '../form/types';
import { Validators } from '@angular/forms'
import { ServiceAgent} from '../form/serviceAgent';

export class PaperMarksEntryForm extends Form {
	private static _instance = new PaperMarksEntryForm();
	paperCode:Field = {
		name:'paperCode'
		,controlType: 'Hidden'
		,label: 'paperCode'
		,isRequired: true
		,valueType: 0
		,errorId: 'invalidText'
		,maxLength: 1000
	};
	subjectRegisterId:Field = {
		name:'subjectRegisterId'
		,controlType: 'Hidden'
		,label: 'subjectRegisterId'
		,isRequired: true
		,valueType: 1
		,errorId: 'invalidId'
		,maxValue: 9999999999999
	};
	marks1:Field = {
		name:'marks1'
		,controlType: 'Input'
		,label: 'Initial Valution'
		,valueType: 1
		,defaultValue: 0
		,errorId: 'invalidMraks'
		,minValue: -1
		,maxValue: 100
	};
	marks2:Field = {
		name:'marks2'
		,controlType: 'Input'
		,label: 'Reval/Moderation/Challenge Valuation'
		,valueType: 1
		,defaultValue: 0
		,errorId: 'invalidMraks'
		,minValue: -1
		,maxValue: 100
	};
	marks3:Field = {
		name:'marks3'
		,controlType: 'Input'
		,label: '3rd Valuation'
		,valueType: 1
		,defaultValue: 0
		,errorId: 'invalidMraks'
		,minValue: -1
		,maxValue: 100
	};

	public static getInstance(): PaperMarksEntryForm {
		return PaperMarksEntryForm._instance;
	}

	constructor() {
		super();
		this.fields = new Map();
		this.controls = new Map();
		this.controls.set('paperCode', [Validators.required, Validators.maxLength(1000)]);
		this.fields.set('paperCode', this.paperCode);
		this.controls.set('subjectRegisterId', [Validators.required, Validators.max(9999999999999)]);
		this.fields.set('subjectRegisterId', this.subjectRegisterId);
		this.controls.set('marks1', [Validators.min(-1), Validators.max(100)]);
		this.fields.set('marks1', this.marks1);
		this.controls.set('marks2', [Validators.min(-1), Validators.max(100)]);
		this.fields.set('marks2', this.marks2);
		this.controls.set('marks3', [Validators.min(-1), Validators.max(100)]);
		this.fields.set('marks3', this.marks3);
		this.opsAllowed = {get: true, update: true, filter: true};
		this.keyFields = ["paperCode", "subjectRegisterId"];
	}

	public getName(): string {
		 return 'paperMarksEntry';
	}
}


export class PaperMarksEntryFd extends FormData {
	constructor(form: PaperMarksEntryForm, sa: ServiceAgent) {
		super(form, sa);
	}

	setFieldValue(name: 'paperCode' | 'subjectRegisterId' | 'marks1' | 'marks2' | 'marks3', value: string | number | boolean | null ): void {
		super.setFieldValue(name, value);
	}

	getFieldValue(name: 'paperCode' | 'subjectRegisterId' | 'marks1' | 'marks2' | 'marks3' ): string | number | boolean | null {
		return super.getFieldValue(name);
	}
}


export interface PaperMarksEntryVo extends Vo {
	marks2?: number, 
	effectiveMarks?: number, 
	marks3?: number, 
	valuationRound?: number, 
	paperCode?: string, 
	subjectRegisterId?: number, 
	isMakeup?: boolean, 
	marks1?: number, 
	packetCode?: string, 
	requires2?: boolean, 
	marks2IsEditable?: boolean, 
	requires3?: boolean, 
	marks3IsEditable?: boolean, 
	instituteId?: number, 
	marks1IsEditable?: boolean
}
