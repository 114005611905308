import { Component, OnInit, Input, AfterViewInit, Output, EventEmitter } from "@angular/core";
import { TableMetaData } from 'src/app/framework-modules/elements/ct-table/component';
import { PaperMarksEntryForm, PaperMarksEntryFd, PaperMarksEntryVo } from 'src/app/framework-modules/formdata/gen/paperMarksEntryForm';
import { ServiceAgent } from 'src/app/framework-modules/formdata/form/serviceAgent';
import { MessageService } from 'src/app/services/messageService';
import { CtDialogComponent } from 'src/app/framework-modules/elements/ct-dialog/component';
import { MatDialog } from '@angular/material';

@Component({
  selector: 'ct-paper-valuation-edit',
  templateUrl: "./component.html",
  styleUrls: ["./component.scss"]
})
export class PaperValuationEditComponent implements OnInit, AfterViewInit {
  @Input() inputData: {paperCode:string, subjectRegisterId:string};
  /**
   * 
   */
	@Output() public done = new EventEmitter<PaperMarksEntryVo>();

  form: PaperMarksEntryForm;
  fd:PaperMarksEntryFd; 
  public vo: PaperMarksEntryVo = {};

  public tableData: TableMetaData;
  public papersExist = false;

  constructor(private sa: ServiceAgent, private ms: MessageService, private dialog: MatDialog) {
    this.form = PaperMarksEntryForm.getInstance();
    this.fd = this.form.newFormData(this.sa);
  }

  ngOnInit() {
   //
  }

  ngAfterViewInit() {
    if (this.inputData && this.inputData.paperCode && this.inputData.subjectRegisterId) {
      this.getData(this.inputData);
    } else {
      this.ms.showError('Page invoked with invalid data');
    }
  }
 
  private getData(data) {
    console.log(data);
    this.fd.fetchFor(data).subscribe({
      next: data => {
        this.fd.setFieldValue("marks1", this.fd.getFieldValue("marks1") == -1 ? "" : this.fd.getFieldValue("marks1"))
        this.fd.setFieldValue("marks2", this.fd.getFieldValue("marks2") == -1 ? "" : this.fd.getFieldValue("marks2"))
        this.fd.setFieldValue("marks3", this.fd.getFieldValue("marks3") == -1 ? "" : this.fd.getFieldValue("marks3"))
        console.log(data);
        this.vo = this.fd.getRawData();
      },

      error: msgs => {
        this.ms.showError('Server returned with errors..');
      }
    });
  }

  private saveData() {
    this.fd.setFieldValue("marks1", this.fd.getFieldValue("marks1") == "" ? -1 : this.fd.getFieldValue("marks1"))
    this.fd.setFieldValue("marks2", this.fd.getFieldValue("marks2") == "" ? -1 : this.fd.getFieldValue("marks2"))
    this.fd.setFieldValue("marks3", this.fd.getFieldValue("marks3") == "" ? -1 : this.fd.getFieldValue("marks3"))
    this.fd.save().subscribe({
      next: data => {
        this.ms.showSuccess('Marks saved');
        this.back(data);
      },

      error: msgs => {
        this.ms.showError('Server returned with errors..');
        this.back(null);
      }
    });
  }
  /**
   * Function is called when the user clicks save button.
   * Saves the assigned evaluators to a asubject.
   */
  save() {
    let dialogRef = this.dialog.open(CtDialogComponent);
    dialogRef.componentInstance.title = 'Save Changes';
    dialogRef.componentInstance.content = 'Are you sure you want to save changes?';
    dialogRef.componentInstance.primary = 'Save';
    const subscribeDialog = dialogRef.componentInstance.save.subscribe((data) => {
      this.saveData();
      this.dialog.closeAll();
    });
  }

  /**
   * Function is called when the user clicks cancel button.
   * Goes back to assign Evaluators list screen
   */
  cancel() {
    let dialogRef = this.dialog.open(CtDialogComponent);
    dialogRef.componentInstance.title = 'Discard Changes';
    dialogRef.componentInstance.content = 'Are you sure you want to discard changes?';
    dialogRef.componentInstance.primary = 'Discard';
    const subscribeDialog = dialogRef.componentInstance.save.subscribe((data) => {
      this.dialog.closeAll();
      this.back(null);
    });
  }

  private back(data:PaperMarksEntryVo ) {
    this.done.next(data);
  }
}