import { Injectable } from "@angular/core";

@Injectable()

export class ConstantsService {

  public static ROUTE_NON_SESSION = "non-session";
  public static ROUTE_SESSION = "session";
  public static ROUTE_SEASON_SELECTION = "season-select";
 
}
