
import { Form , Field, ChildForm } from '../form/form';
import { FormData } from '../form/formData';
import { SelectOption, Vo } from '../form/types';
import { Validators } from '@angular/forms'
import { ServiceAgent} from '../form/serviceAgent';

export class TranscriptSemesterDetailForm extends Form {
	private static _instance = new TranscriptSemesterDetailForm();
	studentId:Field = {
		name:'studentId'
		,controlType: 'Hidden'
		,label: 'studentId'
		,isRequired: true
		,valueType: 1
		,errorId: 'invalidId'
		,maxValue: 9999999999999
	};
	seasonId:Field = {
		name:'seasonId'
		,controlType: 'Hidden'
		,label: 'seasonId'
		,isRequired: true
		,valueType: 1
		,errorId: 'invalidId'
		,maxValue: 9999999999999
	};

	public static getInstance(): TranscriptSemesterDetailForm {
		return TranscriptSemesterDetailForm._instance;
	}

	constructor() {
		super();
		this.fields = new Map();
		this.controls = new Map();
		this.controls.set('studentId', [Validators.required, Validators.max(9999999999999)]);
		this.fields.set('studentId', this.studentId);
		this.controls.set('seasonId', [Validators.required, Validators.max(9999999999999)]);
		this.fields.set('seasonId', this.seasonId);
		this.opsAllowed = {get: true, filter: true};
		this.keyFields = ["studentId", "seasonId"];
	}

	public getName(): string {
		 return 'transcriptSemesterDetail';
	}
}


export class TranscriptSemesterDetailFd extends FormData {
	constructor(form: TranscriptSemesterDetailForm, sa: ServiceAgent) {
		super(form, sa);
	}

	setFieldValue(name: 'studentId' | 'seasonId', value: string | number | boolean | null ): void {
		super.setFieldValue(name, value);
	}

	getFieldValue(name: 'studentId' | 'seasonId' ): string | number | boolean | null {
		return super.getFieldValue(name);
	}
}


export interface TranscriptSemesterDetailVo extends Vo {
	registeredCredits?: number, 
	levelSectionId?: number, 
	obtainedCredits?: number, 
	sessionName?: string, 
	levelName?: string, 
	levelSeq?: number, 
	sessionSemester?: string, 
	seasonEndDate?: string, 
	studentId?: number, 
	sgpa?: number, 
	sectionName?: string, 
	seasonStartDate?: string, 
	totalCredits?: number, 
	isSupplementary?: boolean, 
	academicYear?: string, 
	seasonId?: number, 
	programName?: string, 
	instituteId?: number, 
	cgpa?: number, 
	programId?: number
}
