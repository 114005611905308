
import { Form , Field, ChildForm } from '../form/form';
import { FormData } from '../form/formData';
import { SelectOption, Vo } from '../form/types';
import { Validators } from '@angular/forms'
import { ServiceAgent} from '../form/serviceAgent';

export class DesignationForm extends Form {
	private static _instance = new DesignationForm();
	designationId:Field = {
		name:'designationId'
		,controlType: 'Hidden'
		,label: 'designationId'
		,valueType: 1
		,errorId: 'invalidId'
		,maxValue: 9999999999999
	};
	name:Field = {
		name:'name'
		,controlType: 'Input'
		,label: 'Name'
		,isRequired: true
		,valueType: 0
		,errorId: 'invalidName'
		,maxLength: 150
	};
	code:Field = {
		name:'code'
		,controlType: 'Input'
		,label: 'Code'
		,valueType: 0
		,errorId: 'invalidCode'
		,maxLength: 50
	};
	allowedPages:Field = {
		name:'allowedPages'
		,controlType: 'Hidden'
		,label: 'allowedPages'
		,valueType: 0
		,defaultValue: ''
		,errorId: 'invalidText'
		,maxLength: 1000
	};
	isActive:Field = {
		name:'isActive'
		,controlType: 'Input'
		,label: 'Is Active'
		,valueType: 3
		,defaultValue: true
		,errorId: 'invalidBool'
	};

	public static getInstance(): DesignationForm {
		return DesignationForm._instance;
	}

	constructor() {
		super();
		this.fields = new Map();
		this.controls = new Map();
		this.controls.set('designationId', [Validators.max(9999999999999)]);
		this.fields.set('designationId', this.designationId);
		this.controls.set('name', [Validators.required, Validators.maxLength(150)]);
		this.fields.set('name', this.name);
		this.controls.set('code', [Validators.maxLength(50)]);
		this.fields.set('code', this.code);
		this.controls.set('allowedPages', [Validators.maxLength(1000)]);
		this.fields.set('allowedPages', this.allowedPages);
		this.controls.set('isActive', []);
		this.fields.set('isActive', this.isActive);
		this.opsAllowed = {get: true, filter: true, update: true, create: true};
		this.keyFields = ["designationId"];
	}

	public getName(): string {
		 return 'designation';
	}
}


export class DesignationFd extends FormData {
	constructor(form: DesignationForm, sa: ServiceAgent) {
		super(form, sa);
	}

	setFieldValue(name: 'designationId' | 'name' | 'code' | 'allowedPages' | 'isActive', value: string | number | boolean | null ): void {
		super.setFieldValue(name, value);
	}

	getFieldValue(name: 'designationId' | 'name' | 'code' | 'allowedPages' | 'isActive' ): string | number | boolean | null {
		return super.getFieldValue(name);
	}
}


export interface DesignationVo extends Vo {
	allowedPages?: string, 
	createdAt?: string, 
	code?: string, 
	designationId?: number, 
	name?: string, 
	instituteId?: number, 
	isActive?: boolean, 
	updatedAt?: string
}
