import { Component, Input, Output, EventEmitter, OnInit, ViewChild } from "@angular/core";
import { DynamicPanel, LandingPanel, GroupPanel } from '../../interfaces';
import { ServiceAgent } from 'src/app/framework-modules/formdata/form/serviceAgent';
import { SessionComponent } from '../../component';
import { CtSpinner } from 'src/app/framework-modules/elements/ct-spinner/component';
import { MessageService } from 'src/app/services/messageService';
import { SubjectRegisterForm, SubjectRegisterFd, SubjectRegisterVo } from 'src/app/framework-modules/formdata/gen/subjectRegisterForm';
import { StudentInRegisterVo } from 'src/app/framework-modules/formdata/gen/studentInRegisterForm';
import { StudentSubjectMarksForm, StudentSubjectMarksFd, StudentSubjectMarksVo } from 'src/app/framework-modules/formdata/gen/studentSubjectMarksForm';
import { FilterRequest } from 'src/app/framework-modules/formdata/form/types';
import { CtTableComponent, TableMetaData } from 'src/app/framework-modules/elements/ct-table/component';

@Component({
  selector: "student-subjects",
  templateUrl: "./component.html",
  styleUrls: ["./component.scss"]
})


export class StudentSubjectsComponent implements DynamicPanel, OnInit {
  @ViewChild("gridTable", { static: false }) gtable: CtTableComponent;
  @ViewChild("spinner", {static:false}) spinner: CtSpinner;
  @Input() inputData: StudentInRegisterVo;
  @Input() parentPanel: GroupPanel;
  @Input() session: SessionComponent;

  form1: SubjectRegisterForm;
  fd1: SubjectRegisterFd;
  vo1: SubjectRegisterVo = {};
  form2: StudentSubjectMarksForm;
  fd2: StudentSubjectMarksFd;
  vo2: StudentSubjectMarksVo = {};
  tests: Array<StudentSubjectMarksVo> = [];
  tableData: TableMetaData;

  constructor(private sa: ServiceAgent, private ms: MessageService) {
    this.form1 = SubjectRegisterForm.getInstance();
    this.fd1 = this.form1.newFormData(this.sa);
    this.form2 = StudentSubjectMarksForm.getInstance();
    this.fd2 = this.form2.newFormData(this.sa);
  }

  ngOnInit() {
    this.metaDataInit();
    this.getData(this.inputData.studentId,this.inputData.subjectRegisterId);
  }

  metaDataInit(){
    this.tableData = {
      data: [],
      metaData: {
        column_names: [
          "name",
          "maxMarks",
          "marks"
        ],
        display_names:{
          "name":"Test",
          "maxMarks":"Max Marks",
          "marks":"Obtained"
        },
        search: false,
      }
    }
  }

  goBack(){
    this.parentPanel.navigate("StudentView");
  }

  getData(studentId,subjectRegisterId) {
    this.fd1.setFieldValue('studentId', studentId);
    this.fd1.setFieldValue('subjectRegisterId', subjectRegisterId);
    this.fd1.fetchData().subscribe({
      next: data => {
        this.vo1 = data;
        this.getTestData(this.vo1.subjectSectionId,studentId);
      },
      error: msg => {
        console.error(msg);
        this.ms.showError('Server returned with an error message. ');
      }
    });
  }

  private getTestData(subjectSectionId,studentId){
    const filter: FilterRequest = {
      conditions: {
        subjectSectionId:{comp:'=',value:subjectSectionId},
        studentId:{comp:'=',value:studentId}
      },
      sort:{seqNo:"asc"}
    }
    this.fd2.filter(filter).subscribe({
      next: data=>{
        this.tests = data as [];
        this.tableData.data = this.tests;
        this.gtable.update();
      }
    })
  }

  setData(data) {
    
  }
}
