
import { Form , Field, ChildForm } from '../form/form';
import { FormData } from '../form/formData';
import { SelectOption, Vo } from '../form/types';
import { Validators } from '@angular/forms'
import { ServiceAgent} from '../form/serviceAgent';

export class PaperValuationForm extends Form {
	private static _instance = new PaperValuationForm();

	public static getInstance(): PaperValuationForm {
		return PaperValuationForm._instance;
	}

	constructor() {
		super();
		this.fields = new Map();
		this.controls = new Map();
		this.opsAllowed = {filter: true};
		this.keyFields = ["paperCode"];
	}

	public getName(): string {
		 return 'paperValuation';
	}
}


export class PaperValuationFd extends FormData {
	constructor(form: PaperValuationForm, sa: ServiceAgent) {
		super(form, sa);
	}
	/**  this form has no editable fields. data nust be accessed as Vo and not through fd **/
}


export interface PaperValuationVo extends Vo {
	marks?: number, 
	paperCode?: string, 
	subjectRegisterId?: number, 
	isMandatory?: boolean, 
	packetCode?: string
}
