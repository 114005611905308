import { Component, OnInit, ViewChild, Input, AfterViewInit, ViewChildren} from "@angular/core";
import { DynamicPanel, GroupPanel } from '../../interfaces';
import { ServiceAgent } from 'src/app/framework-modules/formdata/form/serviceAgent';
import { CtTableComponent, TableMetaData, } from '../../../../framework-modules/elements/ct-table/component'
import { FilterRequest } from 'src/app/framework-modules/formdata/form/types';
import { SessionComponent } from '../../component';
import { CtSpinner } from 'src/app/framework-modules/elements/ct-spinner/component';
import { ExternalForm, ExternalFd } from 'src/app/framework-modules/formdata/gen/externalForm';
import { MessageService } from 'src/app/services/messageService';


@Component({
  selector: "external-evaluator-list",
  templateUrl: "./component.html",
  styleUrls: ["./component.scss"]
})

export class ExternalStaffListComponent implements DynamicPanel, OnInit, AfterViewInit {
  @ViewChild("gridTable", { static: false }) public gtable: CtTableComponent;
  @ViewChild("spinner", { static: false }) public spinner: CtSpinner;
  @Input() inputData: any;
  @Input() parentPanel: GroupPanel;
  @Input() session: SessionComponent;
 
  public tableData: TableMetaData;
  public form: ExternalForm;
  public fd: ExternalFd;

  constructor(private sa: ServiceAgent, private ms: MessageService) {
    this.form = ExternalForm.getInstance();
    this.fd = this.form.newFormData(this.sa);
  }

  ngOnInit() {
  }

  ngAfterViewInit(){
    this.metaDataInit();
    this.filter();
  }
  /**
   * initialising the table data
   */
  metaDataInit() {
    this.tableData = {
      data: [] = [],
      metaData: {
        column_names: [
          "name",
          "collegeName",
          "phoneNumber",
          "email"
        ],
        display_names: {
          "name": "External Evaluator Name",
          "collegeName": "College",
          "phoneNumber": "Phone Number",
          "email": "Email"
        },
        edit: true,
        search: true,
        placeholder: "Search By Evaluator Name, Department, College",
      }
    };
  }

  filter() {
    /**
     * initial filter conditions
     */
    const filter: FilterRequest = {
      conditions: {},
      sort: { evaluatorName: "asc" }
    };
    const obs = this.fd.filter(filter);
    if(this.spinner){
    this.spinner.show('Getting List of evaluators..');
    }else{
      console.error('spinner is not yet initialized..')
    }

    obs.subscribe(
      list => {
        this.tableData.data = list;
        this.gtable.update(this.spinner);
      },
      msgs => {
        if(this.spinner){
        this.spinner.hide();
        }
        this.ms.showError('server returned with errors');
      }
    );
  }

  /**
   * Add an external staff
   */
  addExternalStaff() {
    this.parentPanel.navigate('ExternalStaffAdd')
  }
  
  /*
   * this gets the Id of the staff and navigates to editing screen, setting the values fetched
   */
  edit(rowId) {
    this.parentPanel.navigate('ExternalStaffEdit', this.tableData.data[rowId].externalId)
  }
}
