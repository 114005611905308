import { Component, Input } from '@angular/core';
import { ServiceAgent } from 'src/app/framework-modules/formdata/form/serviceAgent';
import { FormData } from 'src/app/framework-modules/formdata/form/formData';
import { RouterService } from 'src/app/services/routerService';
import { NonSessionComponent } from '../../component';
import { DynamicPanel, GroupPanel } from '../../interfaces';
import { MessageService } from 'src/app/services/messageService';
import { ClientContext } from '../../../../framework-modules/formdata/form/clientContext';

import { UserRegistrationForm, UserRegistrationFd } from 'src/app/framework-modules/formdata/gen/userRegistrationForm';


@Component({
  selector: 'user-registration',
  templateUrl: './component.html',
  styleUrls: ['./component.scss']
})
export class UserRegistrationComponent implements DynamicPanel {
  @Input() inputData: any;
  @Input() parentPanel: GroupPanel;
  @Input() session: NonSessionComponent;

  emailVerified: boolean = true;

  public form: UserRegistrationForm;
  public fd: UserRegistrationFd;

  public constructor(public sa: ServiceAgent, private ctx: ClientContext, public routerService: RouterService, private ms: MessageService) {
    this.form = UserRegistrationForm.getInstance();
    this.fd = new FormData(this.form, this.sa);
  }

  /**
   * Register a new User by taking inputs
   */
  registerUser() {
    const pass = this.fd.getFieldValue('newPassword') as string;;
    const confirmPass = this.fd.getFieldValue('confirmPassword') as string;;

    if (pass != confirmPass) {
      this.ms.showError("Passwords do not match!");
      return;
    }

    this.fd.save().subscribe(
      data => {
        this.ms.showSuccess("Registered Successfully. Now you can login");
        this.parentPanel.navigate("Login");
      },
      error => {
        this.ms.showSuccess("Invalid OTP. entered. Please try again.");
      }
    );
  }

  /**
   * Check if the user exists
  */
  requestUser() {
    console.log('logging')
    const email = this.fd.getFieldValue('email');
    this.sa.serve('sendOtp', { data: { loginId: email} },false).subscribe(
      data => {
        this.emailVerified = false;
        this.ms.showInfo("OTP sent to registered mobile");
      },

      error => {
        this.ms.showError("email is not valid for this user");
      }
    );
  }

  /**
   * Cancel and goback to login screen
   */
  cancel() {
    this.parentPanel.navigate("Login");
  }
}
