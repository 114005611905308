
import { Form , Field, ChildForm } from '../form/form';
import { FormData } from '../form/formData';
import { SelectOption, Vo } from '../form/types';
import { Validators } from '@angular/forms'
import { ServiceAgent} from '../form/serviceAgent';
import { FormAStudentForm, FormAStudentVo } from './formAStudentForm';

export class FormADownloadForm extends Form {
	private static _instance = new FormADownloadForm();

	students: ChildForm = {
		name:'students',
		form:FormAStudentForm.getInstance(),
		isEditable:false,
		isTabular:true,
		label:'',
		minRows:0,
		maxRows:0,
		errorId:null
	};

	public static getInstance(): FormADownloadForm {
		return FormADownloadForm._instance;
	}

	constructor() {
		super();
		this.fields = new Map();
		this.controls = new Map();

		this.childForms = new Map();
		this.childForms.set('students', this.students);
		this.opsAllowed = {get: true, filter: true};
		this.keyFields = ["offeredSubjectId"];
	}

	public getName(): string {
		 return 'formADownload';
	}
}


export class FormADownloadFd extends FormData {
	constructor(form: FormADownloadForm, sa: ServiceAgent) {
		super(form, sa);
	}
	/**  this form has no editable fields. data nust be accessed as Vo and not through fd **/
}


export interface FormADownloadVo extends Vo {
	departmentName?: string, 
	name?: string, 
	offeredSubjectId?: number, 
	code?: string, 
	degreeId?: number, 
	students?: FormAStudentVo
}
