import { Component, Input} from '@angular/core';

@Component({
  selector: 'ct-reset-button',
  templateUrl: "./component.html",
  styleUrls: ['./component.scss']
})


export class CTResetButtonComponent {

}
