
import { Form , Field, ChildForm } from '../form/form';
import { FormData } from '../form/formData';
import { SelectOption, Vo } from '../form/types';
import { Validators } from '@angular/forms'
import { ServiceAgent} from '../form/serviceAgent';

export class SectionPpNpGradeForm extends Form {
	private static _instance = new SectionPpNpGradeForm();
	subjectRegisterId:Field = {
		name:'subjectRegisterId'
		,controlType: 'Hidden'
		,label: 'subjectRegisterId'
		,isRequired: true
		,valueType: 1
		,errorId: 'invalidId'
		,maxValue: 9999999999999
	};
	studentId:Field = {
		name:'studentId'
		,controlType: 'Hidden'
		,label: 'studentId'
		,valueType: 1
		,defaultValue: 0
		,errorId: 'invalidId'
		,maxValue: 9999999999999
	};
	name:Field = {
		name:'name'
		,controlType: 'Output'
		,label: 'name'
		,valueType: 0
		,errorId: 'invalidName'
		,maxLength: 150
	};
	usn:Field = {
		name:'usn'
		,controlType: 'Output'
		,label: 'usn'
		,valueType: 0
		,errorId: 'invalidText'
		,maxLength: 1000
	};
	grade:Field = {
		name:'grade'
		,controlType: 'Output'
		,label: 'grade'
		,valueType: 0
		,errorId: 'invalidMarksOrAb'
		,maxLength: 5
	};

	public static getInstance(): SectionPpNpGradeForm {
		return SectionPpNpGradeForm._instance;
	}

	constructor() {
		super();
		this.fields = new Map();
		this.controls = new Map();
		this.controls.set('subjectRegisterId', [Validators.required, Validators.max(9999999999999)]);
		this.fields.set('subjectRegisterId', this.subjectRegisterId);
		this.controls.set('studentId', [Validators.max(9999999999999)]);
		this.fields.set('studentId', this.studentId);
		this.controls.set('name', [Validators.maxLength(150)]);
		this.fields.set('name', this.name);
		this.controls.set('usn', [Validators.maxLength(1000)]);
		this.fields.set('usn', this.usn);
		this.controls.set('grade', [Validators.maxLength(5), Validators.pattern('(pp|Pp|PP|pP|np|Np|nP|NP|^$)')]);
		this.fields.set('grade', this.grade);
		this.opsAllowed = {filter: true, update: true};
		this.keyFields = ["subjectRegisterId"];
	}

	public getName(): string {
		 return 'sectionPpNpGrade';
	}
}


export class SectionPpNpGradeFd extends FormData {
	constructor(form: SectionPpNpGradeForm, sa: ServiceAgent) {
		super(form, sa);
	}

	setFieldValue(name: 'subjectRegisterId' | 'studentId' | 'name' | 'usn' | 'grade', value: string | number | boolean | null ): void {
		super.setFieldValue(name, value);
	}

	getFieldValue(name: 'subjectRegisterId' | 'studentId' | 'name' | 'usn' | 'grade' ): string | number | boolean | null {
		return super.getFieldValue(name);
	}
}


export interface SectionPpNpGradeVo extends Vo {
	studentId?: number, 
	usn?: string, 
	subjectRegisterId?: number, 
	subjectSectionId?: number, 
	grade?: string, 
	name?: string, 
	instituteId?: number
}
