
import { Form , Field, ChildForm } from '../form/form';
import { FormData } from '../form/formData';
import { SelectOption, Vo } from '../form/types';
import { Validators } from '@angular/forms'
import { ServiceAgent} from '../form/serviceAgent';

export class ResetPasswordForm extends Form {
	private static _instance = new ResetPasswordForm();
	currentPassword:Field = {
		name:'currentPassword'
		,controlType: 'Password'
		,label: 'Current Password'
		,isRequired: true
		,valueType: 0
		,errorId: 'invalidText'
		,maxLength: 1000
	};
	newPassword:Field = {
		name:'newPassword'
		,controlType: 'Password'
		,label: 'New Password'
		,isRequired: true
		,valueType: 0
		,errorId: 'invalidText'
		,maxLength: 1000
	};
	confirmPassword:Field = {
		name:'confirmPassword'
		,controlType: 'Password'
		,label: 'Re-enter New Password'
		,isRequired: true
		,valueType: 0
		,errorId: 'invalidText'
		,maxLength: 1000
	};

	public static getInstance(): ResetPasswordForm {
		return ResetPasswordForm._instance;
	}

	constructor() {
		super();
		this.fields = new Map();
		this.controls = new Map();
		this.controls.set('currentPassword', [Validators.required, Validators.maxLength(1000)]);
		this.fields.set('currentPassword', this.currentPassword);
		this.controls.set('newPassword', [Validators.required, Validators.maxLength(1000)]);
		this.fields.set('newPassword', this.newPassword);
		this.controls.set('confirmPassword', [Validators.required, Validators.maxLength(1000)]);
		this.fields.set('confirmPassword', this.confirmPassword);
		this.opsAllowed = {get: true, update: true};
	}

	public getName(): string {
		 return 'resetPassword';
	}
}


export class ResetPasswordFd extends FormData {
	constructor(form: ResetPasswordForm, sa: ServiceAgent) {
		super(form, sa);
	}

	setFieldValue(name: 'currentPassword' | 'newPassword' | 'confirmPassword', value: string | number | boolean | null ): void {
		super.setFieldValue(name, value);
	}

	getFieldValue(name: 'currentPassword' | 'newPassword' | 'confirmPassword' ): string | number | boolean | null {
		return super.getFieldValue(name);
	}
}


export interface ResetPasswordVo extends Vo {
	newPassword?: string, 
	confirmPassword?: string, 
	currentPassword?: string
}
