import { Component, Input, ViewChild, OnInit } from "@angular/core";
import { DynamicPanel, GroupPanel } from '../../interfaces';
import { ServiceAgent } from 'src/app/framework-modules/formdata/form/serviceAgent';
import { SessionComponent } from '../../component';
import { CtSpinner } from 'src/app/framework-modules/elements/ct-spinner/component';
import { FilterRequest, Vo } from 'src/app/framework-modules/formdata/form/types';
import { ExcelDownloadService, ExcelTemplate } from 'src/app/services/excelDownloadService';
import { AllOfferedSubjectListForm } from 'src/app/framework-modules/formdata/gen/allOfferedSubjectListForm';
import { CieSubjectDetailForm } from 'src/app/framework-modules/formdata/gen/cieSubjectDetailForm';
import { MessageService } from 'src/app/services/messageService';
import { CieDownloadForm, CieDownloadFd, CieDownloadVo } from 'src/app/framework-modules/formdata/gen/cieDownloadForm';
import { SubjectSectionForm, SubjectSectionFd } from 'src/app/framework-modules/formdata/gen/subjectSectionForm';
import { PanelData } from 'src/app/framework-modules/formdata/form/formData';
import { HallTicketDownloadFd, HallTicketDownloadForm } from 'src/app/framework-modules/formdata/gen/hallTicketDownloadForm';
import { MarkSheetDownloadFd, MarkSheetDownloadForm } from 'src/app/framework-modules/formdata/gen/markSheetDownloadForm';

import { DepartmentForm, DepartmentFd } from 'src/app/framework-modules/formdata/gen/departmentForm';
import { StudentDetailDownloadForm, StudentDetailDownloadFd } from 'src/app/framework-modules/formdata/gen/studentDetailDownloadForm';
import { ProgramLevelListForm, ProgramLevelListFd, ProgramLevelListVo } from 'src/app/framework-modules/formdata/gen/programLevelListForm';
import { GradeCardStudentDetailForm, GradeCardStudentDetailFd } from 'src/app/framework-modules/formdata/gen/gradeCardStudentDetailForm';
import * as moment from "moment";
import { CurrentBacklogDetailFd, CurrentBacklogDetailForm } from "src/app/framework-modules/formdata/gen/currentBacklogDetailForm";
import { MtechGradeCardStudentDetailForm, MtechGradeCardStudentDetailFd } from 'src/app/framework-modules/formdata/gen/mtechGradeCardStudentDetailForm';
import { ClientContext } from "src/app/framework-modules/formdata/form/clientContext";
import { LevelWiseAllOfferedSubjectListVo, LevelWiseAllOfferedSubjectListForm } from 'src/app/framework-modules/formdata/gen/levelWiseAllOfferedSubjectListForm';
import { StudentSubjectAndAssignedStaffNameFd, StudentSubjectAndAssignedStaffNameForm } from "src/app/framework-modules/formdata/gen/studentSubjectAndAssignedStaffNameForm";

const studentTemplate: ExcelTemplate = {
  fixedHeader: [
    { loc: "A1", value: "B M S  COLLEGE OF ENGINEERING, BANGALORE - 19 ", style: { font: { size: 36, name: 'Cambria', bold: true }, alignment: { horizontal: 'center', vertical: 'middle' }, border: {}, fill: null, numFmt: '', protection: null } },
    { loc: "A2", value: "(Autonomous Institute, Affiliated to VTU)", style: { font: { size: 22, name: 'Cambria', bold: true }, alignment: { horizontal: 'center', vertical: 'middle' }, border: {}, fill: null, numFmt: '', protection: null } },
    { loc: "A3", value: "SEMESTER END EXAMINATION MAY/JUNE-2019", style: { font: { size: 30, name: 'Cambria', bold: true }, alignment: { horizontal: 'center', vertical: 'middle' }, border: {}, fill: null, numFmt: '', protection: null } },
  ],
  dynamicHeader: [],
  merges: ['A1:X1', 'A2:X2', 'A3:X3'],
  tableArray: "students",
  tableStart: 5,
  tableStyle: { rowHeight: 30 },
  tableHeader: ["Department", "Program", "Semester", "Section", "USN", "Name", "Email", "Phone Number", "Gender", "Date of Birth", "Caste", "Relegion",
    "Category", "Address Line 1", "Address Line 2", "Present Address Line 1", "Present Address Line 2", "Nationality", "Personal ID",
    "Admission Date", "Qualifying Exam Rank", "Admission Quota", "Previous Board", "Previous Institute"
  ],
  tableValues: [
    { key: "departmentName", width: 45, style: { font: { size: 18, name: 'Cambria' }, alignment: { horizontal: 'center', vertical: 'middle' }, border: {}, fill: null, numFmt: '', protection: null } },
    { key: "programName", width: 45, style: { font: { size: 18, name: 'Cambria' }, alignment: { horizontal: 'center', vertical: 'middle' }, border: {}, fill: null, numFmt: '', protection: null } },
    { key: "semester", width: 20, style: { font: { size: 18, name: 'Cambria' }, alignment: { horizontal: 'center', vertical: 'middle' }, border: {}, fill: null, numFmt: '', protection: null } },
    { key: "section", width: 15, style: { font: { size: 18, name: 'Cambria' }, alignment: { horizontal: 'center', vertical: 'middle' }, border: {}, fill: null, numFmt: '', protection: null } },
    { key: "usn", width: 30, style: { font: { size: 18, name: 'Cambria' }, alignment: { horizontal: 'center', vertical: 'middle' }, border: {}, fill: null, numFmt: '', protection: null } },
    { key: "name", width: 40, style: { font: { size: 18, name: 'Cambria' }, alignment: { horizontal: 'left', vertical: 'middle' }, border: {}, fill: null, numFmt: '', protection: null } },
    { key: "email", width: 45, style: { font: { size: 18, name: 'Cambria' }, alignment: { horizontal: 'left', vertical: 'middle' }, border: {}, fill: null, numFmt: '', protection: null } },
    { key: "phoneNumber", width: 25, style: { font: { size: 18, name: 'Cambria' }, alignment: { horizontal: 'center', vertical: 'middle' }, border: {}, fill: null, numFmt: '', protection: null } },
    { key: "gender", width: 10, style: { font: { size: 18, name: 'Cambria' }, alignment: { horizontal: 'center', vertical: 'middle' }, border: {}, fill: null, numFmt: '', protection: null } },
    { key: "dateOfBirth", width: 25, style: { font: { size: 18, name: 'Cambria' }, alignment: { horizontal: 'center', vertical: 'middle' }, border: {}, fill: null, numFmt: '', protection: null } },
    { key: "caste", width: 25, style: { font: { size: 18, name: 'Cambria' }, alignment: { horizontal: 'center', vertical: 'middle' }, border: {}, fill: null, numFmt: '', protection: null } },
    { key: "relegion", width: 25, style: { font: { size: 18, name: 'Cambria' }, alignment: { horizontal: 'center', vertical: 'middle' }, border: {}, fill: null, numFmt: '', protection: null } },
    { key: "category", width: 25, style: { font: { size: 18, name: 'Cambria' }, alignment: { horizontal: 'center', vertical: 'middle' }, border: {}, fill: null, numFmt: '', protection: null } },
    { key: "addressLine1", width: 45, style: { font: { size: 18, name: 'Cambria' }, alignment: { horizontal: 'center', vertical: 'middle' }, border: {}, fill: null, numFmt: '', protection: null } },
    { key: "addressLine2", width: 45, style: { font: { size: 18, name: 'Cambria' }, alignment: { horizontal: 'center', vertical: 'middle' }, border: {}, fill: null, numFmt: '', protection: null } },
    { key: "presentAddressLine1", width: 45, style: { font: { size: 18, name: 'Cambria' }, alignment: { horizontal: 'center', vertical: 'middle' }, border: {}, fill: null, numFmt: '', protection: null } },
    { key: "presentAddressLine2", width: 45, style: { font: { size: 18, name: 'Cambria' }, alignment: { horizontal: 'center', vertical: 'middle' }, border: {}, fill: null, numFmt: '', protection: null } },
    { key: "nationality", width: 25, style: { font: { size: 18, name: 'Cambria' }, alignment: { horizontal: 'center', vertical: 'middle' }, border: {}, fill: null, numFmt: '', protection: null } },
    { key: "personalId", width: 25, style: { font: { size: 18, name: 'Cambria' }, alignment: { horizontal: 'center', vertical: 'middle' }, border: {}, fill: null, numFmt: '', protection: null } },
    { key: "admissionDate", width: 25, style: { font: { size: 18, name: 'Cambria' }, alignment: { horizontal: 'center', vertical: 'middle' }, border: {}, fill: null, numFmt: '', protection: null } },
    { key: "qualifyingExamRank", width: 20, style: { font: { size: 18, name: 'Cambria' }, alignment: { horizontal: 'center', vertical: 'middle' }, border: {}, fill: null, numFmt: '', protection: null } },
    { key: "admissionQuota", width: 25, style: { font: { size: 18, name: 'Cambria' }, alignment: { horizontal: 'center', vertical: 'middle' }, border: {}, fill: null, numFmt: '', protection: null } },
    { key: "previousBoard", width: 45, style: { font: { size: 18, name: 'Cambria' }, alignment: { horizontal: 'center', vertical: 'middle' }, border: {}, fill: null, numFmt: '', protection: null } },
    { key: "previousInstitute", width: 45, style: { font: { size: 18, name: 'Cambria' }, alignment: { horizontal: 'center', vertical: 'middle' }, border: {}, fill: null, numFmt: '', protection: null } },
  ],
  colWidths: [40, 40],
  fixedFooter: [],
  dynamicFootfer: [],
  footerMerges: [],
  footerRowHeights: [],
  tableHeaderStyle: { font: { size: 20, name: 'Cambria', bold: true }, alignment: { horizontal: 'center', vertical: 'middle' }, border: {}, fill: null, numFmt: '', protection: null }
}

@Component({
  selector: "download-list",
  templateUrl: "./component.html",
  styleUrls: ["./component.scss"]
})


export class DownloadListComponent implements DynamicPanel, OnInit {
  @ViewChild("spinner", { static: false }) public spinner: CtSpinner;
  @Input() inputData: any;
  @Input() parentPanel: GroupPanel;
  @Input() session: SessionComponent;

  /**
   * we initialliy get a list of all offered subjects.
   */
  private offeredSubjects: LevelWiseAllOfferedSubjectListVo[];
  private nbrSubjects = 0;
  private currentIdx = 0;

  /**
   * cieSubjectDetail is the top level data structure that is the downloaded data per subject
   */
  private subjectPd: PanelData;


  public subjectSectionForm: SubjectSectionForm;
  public from: StudentDetailDownloadForm;
  public fd: StudentDetailDownloadFd;

  public subjecSectionFd: SubjectSectionFd;
  public cieForm: CieDownloadForm;
  public cieFd: CieDownloadFd;
  public currentIndex: number = 0;
  public subjectSections: CieDownloadVo[] = [];
  public subjectSectionDetails: CieDownloadVo[];
  public departmentForm: DepartmentForm;
  public departmentFd: DepartmentFd;
  public htSelectionForm: ProgramLevelListForm;
  public htSelectionFd: ProgramLevelListFd;
  public temp: number = 1;
  public departments: number[] = [];
  public departmentNames: string[] = [];
  public programForm: ProgramLevelListForm;
  public programFd: ProgramLevelListFd;
  public programForm1: ProgramLevelListForm;
  public programFd1: ProgramLevelListFd;
  public programVo: ProgramLevelListVo = {};
  public gradeCardForm: GradeCardStudentDetailForm;
  public gradeCardFd: GradeCardStudentDetailFd;
  isFrozen: boolean;
  selectedDepartment: any;
  programId: any;
  levelId: any;
  hallTicketForm: HallTicketDownloadForm;
  hallTicketPd: HallTicketDownloadFd;
  markSheetForm: MarkSheetDownloadForm;
  markSheetPd: MarkSheetDownloadFd;
  public programForm2: ProgramLevelListForm;
  public programFd2: ProgramLevelListFd;
  public backlogForm: CurrentBacklogDetailForm;
  public backlogFd: CurrentBacklogDetailFd;

  public mtechGradeCardForm: MtechGradeCardStudentDetailForm;
  public mtechGradeCardFd: MtechGradeCardStudentDetailFd;
  public showMtechGradeCardDownload: boolean;
  public programForm3: ProgramLevelListForm;
  public programFd3: ProgramLevelListFd;

  public cieDownloadForm: ProgramLevelListForm;
  public cieDownloadFd: ProgramLevelListFd;

  public rvCvDownloadForm: ProgramLevelListForm;
  public rvCvDownloadFd: ProgramLevelListFd;

  public studentSubjectForm: StudentSubjectAndAssignedStaffNameForm;
  public studentSubjectFd: StudentSubjectAndAssignedStaffNameFd;

  public registeredSubjectFilterForm: ProgramLevelListForm;
  public registeredSubjectFilterFd: ProgramLevelListFd;
  constructor(private sa: ServiceAgent,
    private ds: ExcelDownloadService,
    private ctx: ClientContext,
    private ms: MessageService) {

    this.subjectPd = CieSubjectDetailForm.getInstance().newPanelData(this.sa);

    this.from = StudentDetailDownloadForm.getInstance();
    this.fd = this.from.newFormData(this.sa);
    this.cieForm = CieDownloadForm.getInstance();
    this.cieFd = this.cieForm.newFormData(this.sa);
    this.departmentForm = DepartmentForm.getInstance();
    this.departmentFd = this.departmentForm.newFormData(this.sa);
    this.htSelectionForm = ProgramLevelListForm.getInstance();
    this.htSelectionFd = this.htSelectionForm.newFormData(this.sa);
    this.subjectSectionForm = SubjectSectionForm.getInstance();
    this.subjecSectionFd = this.subjectSectionForm.newFormData(this.sa);
    this.programForm = ProgramLevelListForm.getInstance();
    this.programFd = this.programForm.newFormData(this.sa);
    this.programForm1 = ProgramLevelListForm.getInstance();
    this.programFd1 = this.programForm1.newFormData(this.sa);
    this.gradeCardForm = GradeCardStudentDetailForm.getInstance();
    this.gradeCardFd = this.gradeCardForm.newFormData(this.sa);
    this.hallTicketForm = HallTicketDownloadForm.getInstance()
    this.hallTicketPd = this.hallTicketForm.newFormData(this.sa);
    this.markSheetForm = MarkSheetDownloadForm.getInstance()
    this.markSheetPd = this.markSheetForm.newFormData(this.sa);
    this.programForm2 = ProgramLevelListForm.getInstance();
    this.programFd2 = this.programForm2.newFormData(this.sa);
    this.backlogForm = CurrentBacklogDetailForm.getInstance();
    this.backlogFd = this.backlogForm.newFormData(this.sa);

    this.mtechGradeCardForm = MtechGradeCardStudentDetailForm.getInstance();
    this.mtechGradeCardFd = this.mtechGradeCardForm.newFormData(this.sa);
    this.programForm3 = ProgramLevelListForm.getInstance();
    this.programFd3 = this.programForm3.newFormData(this.sa);

    this.cieDownloadForm = ProgramLevelListForm.getInstance();
    this.cieDownloadFd = this.cieDownloadForm.newFormData(this.sa);

    this.rvCvDownloadForm = ProgramLevelListForm.getInstance();
    this.rvCvDownloadFd = this.cieDownloadForm.newFormData(this.sa);
    this.rvCvDownloadFd.setFieldValue("isMakeup", false);
    this.studentSubjectForm = StudentSubjectAndAssignedStaffNameForm.getInstance();
    this.studentSubjectFd = this.studentSubjectForm.newFormData(this.sa);

    this.registeredSubjectFilterForm = ProgramLevelListForm.getInstance();
    this.registeredSubjectFilterFd = this.programForm3.newFormData(this.sa);
  }

  ngOnInit() {
    this.showMtechGradeCardDownload = (this.ctx.getInstituteId() == 1)? true: false;
    // this.getOfferedSubjects();
    this.getDepartments();
  }

  getDepartments() {
    this.departments = [];
    const filter: FilterRequest = {
      conditions: {},
      sort: { name: "asc" },
      maxRows: 10
    };
    this.departmentFd.setFieldValue('instituteId',this.ctx.getInstituteId())
    this.departmentFd.filter(filter).subscribe(
      data => {
        data.forEach(index => {
          this.departments.push(index.departmentId as number);
          this.departmentNames.push(index.name as string);
        })
      },
      msgs => {
        console.error(msgs);
      }
    );
  }


  /**
   * get all current offered subjects
   */
  private getOfferedSubjects() {
    const pd = AllOfferedSubjectListForm.getInstance().newPanelData(this.sa);
    pd.setFieldValue('instituteId',this.ctx.getInstituteId());
    pd.filter().subscribe(
      list => {
        this.offeredSubjects = list;
        this.nbrSubjects = this.offeredSubjects.length;
        console.log(this.nbrSubjects + ' subjects to be downloaded');
      },
      msgs => {
        this.ms.showError('Sorry. Server returned with an error, and hence we will not be able to download CIE details');
      }
    );
  }

  public doDownloadCie() {
    this.spinner.show('Fetching all subjects..');
    this.offeredSubjects = []
    const pd = LevelWiseAllOfferedSubjectListForm.getInstance().newPanelData(this.sa);
    const f: FilterRequest = {
      conditions: {
        programId: {value: this.cieDownloadFd.getFieldValue('programId'), comp:'='},
        departmentId: {value: this.cieDownloadFd.getFieldValue('departmentId'), comp:'='},
        seasonId: {value: this.cieDownloadFd.getFieldValue('seasonId'), comp:'='},
        levelId: {value: this.cieDownloadFd.getFieldValue('programLevelId'), comp:'='}
      }
    }
    
    pd.filter(f).subscribe(
      list => {
        console.log(list)
        this.offeredSubjects = list;
        this.nbrSubjects = this.offeredSubjects.length;
        console.log(this.nbrSubjects + ' subjects to be downloaded');

        if (this.nbrSubjects == 0) {
          this.ms.showInfo('No subjects detected for download');
          return;
        }
        /**
         * it is possible to fire download for all subjects asynch, but we do a serialized process at this time
         */
        this.spinner.hide();
        this.currentIdx = -1;
        this.spinner.show('Started download process..');
        this.downloadNextSubject();
      },
      msgs => {
        this.ms.showError('Sorry. Server returned with an error, and hence we will not be able to download CIE details');
        this.spinner.hide();
      }
    );
  }


  getResultSheets(){

    if(this.programFd1.getFieldValue('dateOfIssue') == null){
      this.ms.showError("Please select Date of Issue!");
      return;
    }
    let title = this.markSheetPd.getFieldValue('title') as string;
    if(title == "" || title == null || title.length == 0){
      this.ms.showError("Please enter a title for the mark sheet!");
      return;
    }
    this.spinner.show('This may take quite some time....');
    this.sa.serve(
      "generateResultSheet",{ 
        data: { 
          programId: this.programFd1.getFieldValue('programId'),
          seasonId: this.programFd1.getFieldValue('seasonId'),
          programLevelId: this.programFd1.getFieldValue('programLevelId'),
          isMakeup: this.programFd1.getFieldValue('isMakeup')
        }
      }
    ).subscribe(
      data => {
        console.log(data);
        this.ds.downloadMarksSheet( data,this.programFd1.getDisplayedValueOf('programId'), this.programFd1.getFieldValue('dateOfIssue') as string ,this.programFd1.getDisplayedValueOf('programLevelId'),this.programFd1.getDisplayedValueOf('seasonId'), Boolean(this.programFd1.getFieldValue('isMakeup')),title);
        this.spinner.hide();
      },
      error => {
        this.ms.showError("OOPS! Something Went Wrong");
        this.spinner.hide();
      }
    );
  }

  getRvCVOptedStudents(){
    this.spinner.show('This may take quite some time....');
    this.sa.serve(
      "get_revalOrChallengeOptedList",{ 
        data: { 
          programId: this.rvCvDownloadFd.getFieldValue('programId'),
          seasonId: this.rvCvDownloadFd.getFieldValue('seasonId'),
          programLevelId: this.rvCvDownloadFd.getFieldValue('programLevelId'),
          isMakeup: this.rvCvDownloadFd.getFieldValue('isMakeup')
        }
      }
    ).subscribe(
      data => {
        console.log(data);
        this.ds.downloadRvCvOptedReportSheet( data,this.programFd1.getDisplayedValueOf('programId'), this.programFd1.getFieldValue('dateOfIssue') as string ,this.programFd1.getDisplayedValueOf('programLevelId'),this.programFd1.getDisplayedValueOf('seasonId'), Boolean(this.programFd1.getFieldValue('isMakeup')));
        this.spinner.hide();
      },
      error => {
        this.ms.showError("OOPS! Something Went Wrong");
        this.spinner.hide();
      }
    );
  }

  getRevalResultSheets(){

    if(this.programFd1.getFieldValue('dateOfIssue') == null){
      this.ms.showError("Please select Date of Issue!");
      return;
    }

    this.spinner.show('This may take quite some time....');
    this.sa.serve(
      "generateRevalResultSheet",{ 
        data: { 
          programId: this.programFd1.getFieldValue('programId'),
          seasonId: this.programFd1.getFieldValue('seasonId'),
          programLevelId: this.programFd1.getFieldValue('programLevelId')
        }
      }
    ).subscribe(
      data => {
        console.log(data);
        this.ds.downloadMarksSheet( data,this.programFd1.getDisplayedValueOf('programId'), this.programFd1.getFieldValue('dateOfIssue') as string ,this.programFd1.getDisplayedValueOf('programLevelId'),this.programFd1.getDisplayedValueOf('seasonId'), false, "Revaluation");
        this.spinner.hide();
      },
      error => {
        this.ms.showError("OOPS! Something Went Wrong");
        this.spinner.hide();
      }
    );
  }

  getChallengeResultSheets(){

    if(this.programFd1.getFieldValue('dateOfIssue') == null){
      this.ms.showError("Please select Date of Issue!");
      return;
    }

    this.spinner.show('This may take quite some time....');
    this.sa.serve(
      "generateChallengeResultSheet",{ 
        data: { 
          programId: this.programFd1.getFieldValue('programId'),
          seasonId: this.programFd1.getFieldValue('seasonId'),
          programLevelId: this.programFd1.getFieldValue('programLevelId')
        }
      }
    ).subscribe(
      data => {
        console.log(data);
        this.ds.downloadMarksSheet( data,this.programFd1.getDisplayedValueOf('programId'), this.programFd1.getFieldValue('dateOfIssue') as string ,this.programFd1.getDisplayedValueOf('programLevelId'),this.programFd1.getDisplayedValueOf('seasonId'), false, "Challenge" );
        this.spinner.hide();
      },
      error => {
        this.ms.showError("OOPS! Something Went Wrong");
        this.spinner.hide();
      }
    );
  }


  private downloadNextSubject() {
    this.currentIdx++;
    if (this.currentIdx >= this.nbrSubjects) {
      this.spinner.hide();
      this.ms.showSuccess('Downloaded Cie details for ' + this.nbrSubjects + ' subjects');
      return;
    }

    const vo = this.offeredSubjects[this.currentIdx];
    this.spinner.show(vo.name + ' : downloading data...');

    const filter: FilterRequest = {
      conditions: {
        offeredSubjectId: { comp: "=", value: vo.offeredSubjectId }
      }
    };

    this.subjectPd.fetchFor({ offeredSubjectId: vo.offeredSubjectId, seasonId: vo.seasonId }).subscribe(
      data => {
        this.spinner.show(vo.name + ' : formatting and writing it to local file....');
        this.ds.downloadCIE(data);
        this.downloadNextSubject();
      },
      msgs => {
        this.ms.showSuccess(vo.name + ' not downloaded due to an error.. going with the next one');
        this.downloadNextSubject();
      }
    );
  }

  /**
   * function fetches all the required student data 
   * and calles the excel download service
   */
  getStudents() {
    this.departments.forEach((x, index) => {
      const filter: FilterRequest = {
        conditions: {
          departmentId: { comp: "=", value: x }
        },
        sort: { name: "asc" },
        maxRows: 10
      };
      const obs = this.fd.filter(filter);

      obs.subscribe(
        data => {
          this.spinner.spinnerText = "Downloading Student Data for" + this.departmentNames[index];
          // console.log(data);
          studentTemplate.fixedHeader = [
            { loc: "A1", value: `${this.ctx.getinstituteName()}, ${this.ctx.getinstituteCity()} - ${this.ctx.getinstitutePinCode().split(' ')[this.ctx.getinstitutePinCode().split(' ').length-1]} `.toUpperCase(), style: { font: { size: 36, name: 'Cambria', bold: true }, alignment: { horizontal: 'center', vertical: 'middle' }, border: {}, fill: null, numFmt: '', protection: null } },
            { loc: "A2", value: `(Autonomous Institute, Affiliated to ${this.ctx.getuniversityName()})`, style: { font: { size: 22, name: 'Cambria', bold: true }, alignment: { horizontal: 'center', vertical: 'middle' }, border: {}, fill: null, numFmt: '', protection: null } },
            { loc: "A3", value: this.ctx.getInstituteId() == 1 ? "SEMESTER END EXAMINATION SEPT/OCT-2022" : "SEMESTER END EXAMINATION JAN/FEB 2023", style: { font: { size: 30, name: 'Cambria', bold: true }, alignment: { horizontal: 'center', vertical: 'middle' }, border: {}, fill: null, numFmt: '', protection: null } },
          ]
          this.ds.excelFromTemplate(studentTemplate, { students: data }, this.departmentNames[index]);
          // this.ds.downloadExcel({ "Student Data": data }, this.departmentNames[index]);
        },
        msgs => {
          this.spinner.hide();
          console.error(msgs);
        }
      );
    })
  }


  /**
   * download hall tickets
   */
  getTickets() {
    if(this.htSelectionFd.getFieldValue('dateOfIssue') == null){
      console.log(this.htSelectionFd.getFieldValue('dateOfIssue'));
      this.ms.showError("Please select Date of Issue!");
      return;
    }
    var title = this.hallTicketPd.getFieldValue('title') as string;
    console.log("sks",)
    if(title == "" || title == null || title.length == 0){
      this.ms.showError("Please enter a title for the hallticket!");
      return;
    }
    this.spinner.show('Contacting server for data..');
    this.hallTicketPd.fetchFor({isMakeup: this.hallTicketPd.getFieldValue('isMakeup'),programId: this.htSelectionFd.getFieldValue('programId'), levelId: this.htSelectionFd.getFieldValue('programLevelId'),}).subscribe({
      next: data => {
        console.log(data);
        let departmentWise = {};
        let students = data.students as Vo[];
        if(students.length == 0){
          this.ms.showInfo("No students found in this level!");
          this.spinner.hide();
          return;
        }
        this.handover(students);
      }
    });
  }

  setDepartment($event) {
    this.selectedDepartment = $event;
  }

  private handover(list) {
    if (list != null || list.length != 0) {
      this.spinner.show(list.length + ' halltickets being formatted. This may take quite some time....');
      if(this.ctx.getInstituteId() == 1){
        this.ds.downloadHallTicket(list, moment(this.htSelectionFd.getFieldValue('dateOfIssue') as string).format("DD-MM-YYYY"), this.htSelectionFd.getDisplayedValueOf('departmentId') + '-' + this.htSelectionFd.getDisplayedValueOf('programId') + '-' + this.htSelectionFd.getDisplayedValueOf('programLevelId') + ' Halltickets', this.hallTicketPd.getFieldValue('title') as string, false);
      }else if(this.ctx.getInstituteId() == 2){
        this.ds.downloadBmscwHallTicket(list, moment(this.htSelectionFd.getFieldValue('dateOfIssue') as string).format("DD MMMM YYYY"), this.htSelectionFd.getDisplayedValueOf('departmentId') + '-' + this.htSelectionFd.getDisplayedValueOf('programId') + '-' + this.htSelectionFd.getDisplayedValueOf('programLevelId') + ' Halltickets', this.hallTicketPd.getFieldValue('title') as string, false);
      }
      this.spinner.hide();
      this.ms.showSuccess(list.length + ' halltickets formatted and downloaded');
    }
    else {
      this.spinner.hide();
      this.ms.showInfo("No students enrolled in this department!");
    }

  }

  /**
   * Event change listner for prgramId dropdown
   * @param $event Program Id
   */
  setProgram($event){
    this.programId = $event;
  }

  /**
   * Event change listner for LevelId dropdown
   * @param $event Level Id
   */
  setLevel($event){
    this.levelId = $event;
  }

  /**
   * Downloads Grade Cards in Bulk
   */
  getGradeCards(){
    if(this.programFd.getFieldValue('dateOfIssue') == null){
      this.ms.showError("Please select Date of Issue!");
      return;
    }
    const filter: FilterRequest = {
      conditions: {
        levelId:{comp:"=",value:this.levelId},
        seasonId:{comp:"=",value:this.programFd.getFieldValue('seasonId')},
        programId:{comp:"=",value:this.programId}
      }
    }
    this.spinner.show('This may take quite some time....');
    this.gradeCardFd.filter(filter).subscribe({
      next: data =>{
        if(data.length == 0){
          this.ms.showError("No students for selected options!")
          this.spinner.hide();
          return;
        }
        this.spinner.spinnerText = data.length + ' grade cards are being generated. This may take quite some time....';
        this.ds.getGradeCard(data,this.programFd.getDisplayedValueOf('programId'), this.programFd.getFieldValue('dateOfIssue') as string, this.programFd.getFieldValue('isAciteRequired') as boolean);
        this.spinner.hide();
        // show success message for BMSCE. As they have provided grade card format
        if(this.ctx.getInstituteId() == 1){
          this.ms.showSuccess(data.length + ' grade cards generated and downloaded');
        }
      }
    })
  }

  getMtechFinalGradeCards(){
    if(this.programFd.getFieldValue('dateOfIssue') == null){
      this.ms.showError("Please select Date of Issue!");
      return;
    }
    this.mtechGradeCardFd.setFieldValue("levelId",this.levelId);
    this.mtechGradeCardFd.setFieldValue("seasonId", this.programFd3.getFieldValue("seasonId"));
    this.mtechGradeCardFd.setFieldValue("programId", this.programId);
    const payload = this.mtechGradeCardFd.extractAllFields();
    this.spinner.show('This may take quite some time....');
    this.sa.serve("filter_mtechGradeCardStudentDetail",{ data: payload}).subscribe(
      res =>{
        if((res["students"] as []).length == 0){
          this.ms.showError("No students for selected options!")
          this.spinner.hide();
          return;
        }
        this.spinner.spinnerText = (res["students"] as []).length + ' grade cards are being generated. This may take quite some time....';
        this.ds.getMtechFinalGradeCard((res["students"] as []),this.programFd3.getDisplayedValueOf('programId'), moment(this.programFd.getFieldValue('dateOfIssue') as string).format('DD-MMM-YYYY'));
        this.spinner.hide();
        this.ms.showSuccess((res["students"] as []).length + ' grade cards generated and downloaded');
      }
    )
  }

  getBacklogs(){
    const filter: FilterRequest = {
      conditions: {
        programId: {comp:"=",value:this.programFd2.getFieldValue('programId')}
      },
      sort: {usn:"asc"}
    }
    const obs = this.backlogFd.filter(filter);
    this.spinner.show("Loading Data..........")
    obs.subscribe(data =>{
      this.doDownloadBacklogs(data);
      this.spinner.hide();
      this.ms.showSuccess("Report Downloaded !")
    })
  }

  doDownloadBacklogs(data){
    var levels:{} = {};
    data.forEach(row => {
      var item = {};
      item["Name"] = row["name"]
      item["USN"] = row["usn"]
      item["Number of subjects"] = row["numberOfSubjects"]
      item["Total Pending Credits"] = row["totalPendingCredits"]
      item["Subjects"] = ""
      row["subjects"].forEach(sub => {
        item["Subjects"] += sub["subjectCode"] + " - " + sub["pendingCredits"] + " Credits - " + sub["numberOfAttempts"] + " Attempts\n"
      });
      this.programFd2.setFieldValue('programLevelId', row["levelId"] as number);
      var levelName = this.programFd2.getDisplayedValueOf('programLevelId');
      if(levels.hasOwnProperty(levelName)){
        levels[levelName].push(item);
      }
      else{
        levels[levelName] = [item]
      }
    });
    this.ds.downloadExcel(levels,this.programFd2.getDisplayedValueOf('programId') + " Backlog Report")
  }

  getstudentSubjects(){
    this.spinner.show('Started download process..');

    const filter: FilterRequest = {
      conditions: {
        levelId:{comp:"=",value:this.registeredSubjectFilterFd.getFieldValue("programLevelId")},
        seasonId:{comp:"=",value:this.registeredSubjectFilterFd.getFieldValue('seasonId')},
        programId:{comp:"=",value:this.registeredSubjectFilterFd.getFieldValue("programId")}
      }
    }
    this.studentSubjectFd.filter(filter).subscribe(
      data => {
        let excelData = this.formatExcelData(data)
        this.spinner.hide();
        this.ds.downloadSudentSubjectsExcel(excelData,'Student Registered Subjects for season '+this.studentSubjectFd.getDisplayedValueOf('seasonId'));
      },
      msgs => {
        this.spinner.hide();
        console.error(msgs);
      }
    );
  }

  formatExcelData(data){
    let excelData = {data: []};
    for(var i=0; i<data.length; i++){
        let rowData = {}
        rowData['Usn'] =  data[i]['usn']
        rowData['Name'] = data[i]['name']
        rowData['Course Code'] = data[i]['subjectCode']
        rowData['Section'] = data[i]['sectionName']
        rowData['Title'] = data[i]['subjectName']
        rowData['Faculty'] = data[i]['staffName']
        excelData["data"].push(rowData)
    }
    return excelData;
  }

}