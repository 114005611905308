import { Component, OnInit, ViewChild, Input, AfterViewInit } from "@angular/core";
import { DynamicPanel, GroupPanel } from '../../interfaces';
import { ServiceAgent } from 'src/app/framework-modules/formdata/form/serviceAgent';
import { SessionComponent } from '../../component';
import { MessageService } from 'src/app/services/messageService';
import { CtSpinner } from 'src/app/framework-modules/elements/ct-spinner/component';
import { ExamAttendeeListForm, ExamAttendeeListFd, ExamAttendeeListVo } from 'src/app/framework-modules/formdata/gen/examAttendeeListForm';
import { CtTableComponent, TableMetaData } from 'src/app/framework-modules/elements/ct-table/component';
import { FinalMarksForm, FinalMarksFd, FinalMarksVo } from 'src/app/framework-modules/formdata/gen/finalMarksForm';
import { CurrentEnrolledStudentForm, CurrentEnrolledStudentFd, CurrentEnrolledStudentVo } from 'src/app/framework-modules/formdata/gen/currentEnrolledStudentForm';
import { ProgramLevelListForm, ProgramLevelListFd, ProgramLevelListVo } from 'src/app/framework-modules/formdata/gen/programLevelListForm';
import { FilterRequest } from 'src/app/framework-modules/formdata/form/types';
import { ExcelDownloadService } from 'src/app/services/excelDownloadService';
import { GradeCardStudentDetailFd, GradeCardStudentDetailForm } from 'src/app/framework-modules/formdata/gen/gradeCardStudentDetailForm';
import { CurrentBacklogDetailFd, CurrentBacklogDetailForm, CurrentBacklogDetailVo } from "src/app/framework-modules/formdata/gen/currentBacklogDetailForm";

@Component({
  selector: "current-backlogs",
  templateUrl: "./component.html",
  styleUrls: ["./component.scss"],
})


export class CurrentBacklogsComponent implements DynamicPanel, OnInit {
  @ViewChild("gridTable", { static: false }) public gtable: CtTableComponent;
  @ViewChild("spinner", { static: false }) spinner: CtSpinner;
  @Input() inputData: any;
  @Input() parentPanel: GroupPanel;
  @Input() session: SessionComponent;

  public form: ProgramLevelListForm;
  public fd: ProgramLevelListFd;
  public vo: ProgramLevelListVo = {};
  public tableData: TableMetaData;
  public studentForm: CurrentBacklogDetailForm;
  public studentFd: CurrentBacklogDetailFd;
  public studentVo: CurrentBacklogDetailVo = {};
  programId: any;
  levelId: any;
  seasonId: any;
  
  constructor(private sa: ServiceAgent, private ms: MessageService, private ds: ExcelDownloadService) {
    this.form = ProgramLevelListForm.getInstance();
    this.fd = this.form.newFormData(this.sa);
    this.studentForm = CurrentBacklogDetailForm.getInstance();
    this.studentFd = this.studentForm.newFormData(this.sa);
  }


  ngOnInit() {
  }

  /**
   * Filter conditions for student list.
   * Downloads the report
   */
  filter(){
    const filter: FilterRequest = {
      conditions: {
        levelId: {comp:"=",value:this.fd.getFieldValue('programLevelId')},
        programId: {comp:"=",value:this.fd.getFieldValue('programId')}
      },
      sort: {usn:"asc"}
    }
    const obs = this.studentFd.filter(filter);
    this.spinner.show("Loading Data..........")
    obs.subscribe(data =>{
      console.log(data);
      this.doDownload(data);
      this.spinner.hide();
      this.ms.showSuccess("Report Downloaded !")
    })
  }

  /**
   * Downloads Marks sheet level wise.
   */
  doDownload(data: Array<any>){
    var processed = [];
    data.forEach(row => {
      var item = {};
      item["Name"] = row["name"]
      item["USN"] = row["usn"]
      item["Number of subjects"] = row["numberOfSubjects"]
      item["Total Pending Credits"] = row["totalPendingCredits"]
      item["Subjects"] = ""
      row["subjects"].forEach(sub => {
        item["Subjects"] += sub["subjectCode"] + " - " + sub["pendingCredits"] + " Credits - " + sub["numberOfAttempts"] + " Attempts\n"
      });
      processed.push(item);
    })
    var toDownload = {}
    toDownload[this.fd.getDisplayedValueOf('programLevelId')] = processed
    this.ds.downloadExcel(toDownload,this.fd.getDisplayedValueOf('programId')+ " - "+this.fd.getDisplayedValueOf('programLevelId') + " Backlog Report")
  }

}