
import { Form , Field, ChildForm } from '../form/form';
import { FormData } from '../form/formData';
import { SelectOption, Vo } from '../form/types';
import { Validators } from '@angular/forms'
import { ServiceAgent} from '../form/serviceAgent';

export class CieActivitiesForm extends Form {
	private static _instance = new CieActivitiesForm();
	instituteId:Field = {
		name:'instituteId'
		,controlType: 'Hidden'
		,label: 'instituteId'
		,isRequired: true
		,valueType: 1
		,errorId: 'invalidId'
		,maxValue: 9999999999999
	};
	cieEntryFrozen:Field = {
		name:'cieEntryFrozen'
		,controlType: 'Hidden'
		,label: 'cieEntryFrozen'
		,valueType: 3
		,defaultValue: false
		,errorId: 'invalidBool'
	};
	attendanceEntryFrozen:Field = {
		name:'attendanceEntryFrozen'
		,controlType: 'Hidden'
		,label: 'attendanceEntryFrozen'
		,valueType: 3
		,defaultValue: false
		,errorId: 'invalidBool'
	};

	public static getInstance(): CieActivitiesForm {
		return CieActivitiesForm._instance;
	}

	constructor() {
		super();
		this.fields = new Map();
		this.controls = new Map();
		this.controls.set('instituteId', [Validators.required, Validators.max(9999999999999)]);
		this.fields.set('instituteId', this.instituteId);
		this.controls.set('cieEntryFrozen', []);
		this.fields.set('cieEntryFrozen', this.cieEntryFrozen);
		this.controls.set('attendanceEntryFrozen', []);
		this.fields.set('attendanceEntryFrozen', this.attendanceEntryFrozen);
		this.opsAllowed = {get: true};
		this.keyFields = ["instituteId"];
	}

	public getName(): string {
		 return 'cieActivities';
	}
}


export class CieActivitiesFd extends FormData {
	constructor(form: CieActivitiesForm, sa: ServiceAgent) {
		super(form, sa);
	}

	setFieldValue(name: 'instituteId' | 'cieEntryFrozen' | 'attendanceEntryFrozen', value: string | number | boolean | null ): void {
		super.setFieldValue(name, value);
	}

	getFieldValue(name: 'instituteId' | 'cieEntryFrozen' | 'attendanceEntryFrozen' ): string | number | boolean | null {
		return super.getFieldValue(name);
	}
}


export interface CieActivitiesVo extends Vo {
	cieEntryFrozen?: boolean, 
	attendanceEntryFrozen?: boolean, 
	instituteId?: number
}
