
import { Form , Field, ChildForm } from '../form/form';
import { FormData } from '../form/formData';
import { SelectOption, Vo } from '../form/types';
import { Validators } from '@angular/forms'
import { ServiceAgent} from '../form/serviceAgent';
import { PaperListForm, PaperListVo } from './paperListForm';

export class ChallengePaperEvaluationDetailsForm extends Form {
	private static _instance = new ChallengePaperEvaluationDetailsForm();
	packetCode:Field = {
		name:'packetCode'
		,controlType: 'Hidden'
		,label: 'packetCode'
		,isRequired: true
		,valueType: 0
		,errorId: 'invalidText'
		,maxLength: 1000
	};

	scriptCodes: ChildForm = {
		name:'scriptCodes',
		form:PaperListForm.getInstance(),
		isEditable:false,
		isTabular:true,
		label:'',
		minRows:0,
		maxRows:0,
		errorId:null
	};

	public static getInstance(): ChallengePaperEvaluationDetailsForm {
		return ChallengePaperEvaluationDetailsForm._instance;
	}

	constructor() {
		super();
		this.fields = new Map();
		this.controls = new Map();
		this.controls.set('packetCode', [Validators.required, Validators.maxLength(1000)]);
		this.fields.set('packetCode', this.packetCode);

		this.childForms = new Map();
		this.childForms.set('scriptCodes', this.scriptCodes);
		this.opsAllowed = {get: true, filter: true};
		this.keyFields = ["packetCode"];
	}

	public getName(): string {
		 return 'challengePaperEvaluationDetails';
	}
}


export class ChallengePaperEvaluationDetailsFd extends FormData {
	constructor(form: ChallengePaperEvaluationDetailsForm, sa: ServiceAgent) {
		super(form, sa);
	}

	setFieldValue(name: 'packetCode', value: string | number | boolean | null ): void {
		super.setFieldValue(name, value);
	}

	getFieldValue(name: 'packetCode' ): string | number | boolean | null {
		return super.getFieldValue(name);
	}
}


export interface ChallengePaperEvaluationDetailsVo extends Vo {
	evaluatorPhoneNumber?: string, 
	evaluatorName?: string, 
	evaluatorType?: string, 
	valuationRound?: number, 
	instituteId?: number, 
	department?: string, 
	subjectCode?: string, 
	evaluatorEmail?: string, 
	packetCode?: string, 
	subjectName?: string, 
	scriptCodes?: PaperListVo
}
