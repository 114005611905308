
import { Form , Field, ChildForm } from '../form/form';
import { FormData } from '../form/formData';
import { SelectOption, Vo } from '../form/types';
import { Validators } from '@angular/forms'
import { ServiceAgent} from '../form/serviceAgent';
import { CieMarksForm, CieMarksVo } from './cieMarksForm';

export class MarksForStudentForm extends Form {
	private static _instance = new MarksForStudentForm();
	subjectRegisterId:Field = {
		name:'subjectRegisterId'
		,controlType: 'Hidden'
		,label: 'subjectRegisterId'
		,isRequired: true
		,valueType: 1
		,errorId: 'invalidId'
		,maxValue: 9999999999999
	};
	studentId:Field = {
		name:'studentId'
		,controlType: 'Hidden'
		,label: 'studentId'
		,valueType: 1
		,errorId: 'invalidId'
		,maxValue: 9999999999999
	};
	name:Field = {
		name:'name'
		,controlType: 'Output'
		,label: 'name'
		,valueType: 0
		,errorId: 'invalidName'
		,maxLength: 150
	};
	usn:Field = {
		name:'usn'
		,controlType: 'Output'
		,label: 'usn'
		,valueType: 0
		,errorId: 'invalidText'
		,maxLength: 1000
	};
	cieMarks:Field = {
		name:'cieMarks'
		,controlType: 'Output'
		,label: 'cieMarks'
		,valueType: 1
		,errorId: 'invalidInteger'
		,maxValue: 9999999999999
	};

	marks: ChildForm = {
		name:'marks',
		form:CieMarksForm.getInstance(),
		isEditable:false,
		isTabular:true,
		label:'',
		minRows:0,
		maxRows:0,
		errorId:null
	};

	public static getInstance(): MarksForStudentForm {
		return MarksForStudentForm._instance;
	}

	constructor() {
		super();
		this.fields = new Map();
		this.controls = new Map();
		this.controls.set('subjectRegisterId', [Validators.required, Validators.max(9999999999999)]);
		this.fields.set('subjectRegisterId', this.subjectRegisterId);
		this.controls.set('studentId', [Validators.max(9999999999999)]);
		this.fields.set('studentId', this.studentId);
		this.controls.set('name', [Validators.maxLength(150)]);
		this.fields.set('name', this.name);
		this.controls.set('usn', [Validators.maxLength(1000)]);
		this.fields.set('usn', this.usn);
		this.controls.set('cieMarks', [Validators.max(9999999999999)]);
		this.fields.set('cieMarks', this.cieMarks);

		this.childForms = new Map();
		this.childForms.set('marks', this.marks);
		this.opsAllowed = {get: true, filter: true};
		this.keyFields = ["subjectRegisterId"];
	}

	public getName(): string {
		 return 'marksForStudent';
	}
}


export class MarksForStudentFd extends FormData {
	constructor(form: MarksForStudentForm, sa: ServiceAgent) {
		super(form, sa);
	}

	setFieldValue(name: 'subjectRegisterId' | 'studentId' | 'name' | 'usn' | 'cieMarks', value: string | number | boolean | null ): void {
		super.setFieldValue(name, value);
	}

	getFieldValue(name: 'subjectRegisterId' | 'studentId' | 'name' | 'usn' | 'cieMarks' ): string | number | boolean | null {
		return super.getFieldValue(name);
	}
}


export interface MarksForStudentVo extends Vo {
	cieMarks?: number, 
	overallAttendance?: string, 
	subjectRegisterId?: number, 
	hasWithdrawn?: boolean, 
	studentId?: number, 
	offeredSubjectId?: number, 
	usn?: string, 
	seasonId?: number, 
	subjectSectionId?: number, 
	name?: string, 
	instituteId?: number, 
	isIntegrated?: boolean, 
	subjectName?: string, 
	marks?: CieMarksVo
}
