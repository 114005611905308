
import { Form , Field, ChildForm } from '../form/form';
import { FormData } from '../form/formData';
import { SelectOption, Vo } from '../form/types';
import { Validators } from '@angular/forms'
import { ServiceAgent} from '../form/serviceAgent';

export class LevelWiseAllOfferedSubjectListForm extends Form {
	private static _instance = new LevelWiseAllOfferedSubjectListForm();
	instituteId:Field = {
		name:'instituteId'
		,controlType: 'Hidden'
		,label: 'instituteId'
		,valueType: 1
		,errorId: 'invalidTenentKey'
		,maxValue: 9999999999999
	};
	degreeId:Field = {
		name:'degreeId'
		,controlType: 'Hidden'
		,label: 'degreeId'
		,isRequired: true
		,valueType: 1
		,errorId: 'invalidId'
		,maxValue: 9999999999999
	};
	departmentId:Field = {
		name:'departmentId'
		,controlType: 'Hidden'
		,label: 'departmentId'
		,isRequired: true
		,valueType: 1
		,errorId: 'invalidId'
		,maxValue: 9999999999999
	};
	seasonId:Field = {
		name:'seasonId'
		,controlType: 'Hidden'
		,label: 'seasonId'
		,isRequired: true
		,valueType: 1
		,errorId: 'invalidId'
		,maxValue: 9999999999999
	};
	offeredSubjectId:Field = {
		name:'offeredSubjectId'
		,controlType: 'Hidden'
		,label: 'offeredSubjectId'
		,valueType: 1
		,errorId: 'invalidId'
		,maxValue: 9999999999999
	};
	name:Field = {
		name:'name'
		,controlType: 'Output'
		,label: 'Subject Name'
		,isRequired: true
		,valueType: 0
		,errorId: 'invalidName'
		,maxLength: 150
	};
	cieFrozen:Field = {
		name:'cieFrozen'
		,controlType: 'Output'
		,label: 'CIE Frozen'
		,valueType: 3
		,errorId: 'invalidBool'
	};
	attendanceFrozen:Field = {
		name:'attendanceFrozen'
		,controlType: 'Output'
		,label: 'Attendance Frozen'
		,valueType: 3
		,errorId: 'invalidBool'
	};

	public static getInstance(): LevelWiseAllOfferedSubjectListForm {
		return LevelWiseAllOfferedSubjectListForm._instance;
	}

	constructor() {
		super();
		this.fields = new Map();
		this.controls = new Map();
		this.controls.set('instituteId', [Validators.max(9999999999999)]);
		this.fields.set('instituteId', this.instituteId);
		this.controls.set('degreeId', [Validators.required, Validators.max(9999999999999)]);
		this.fields.set('degreeId', this.degreeId);
		this.controls.set('departmentId', [Validators.required, Validators.max(9999999999999)]);
		this.fields.set('departmentId', this.departmentId);
		this.controls.set('seasonId', [Validators.required, Validators.max(9999999999999)]);
		this.fields.set('seasonId', this.seasonId);
		this.controls.set('offeredSubjectId', [Validators.max(9999999999999)]);
		this.fields.set('offeredSubjectId', this.offeredSubjectId);
		this.controls.set('name', [Validators.required, Validators.maxLength(150)]);
		this.fields.set('name', this.name);
		this.controls.set('cieFrozen', []);
		this.fields.set('cieFrozen', this.cieFrozen);
		this.controls.set('attendanceFrozen', []);
		this.fields.set('attendanceFrozen', this.attendanceFrozen);
		this.opsAllowed = {filter: true, get: true};
		this.keyFields = ["offeredSubjectId"];
	}

	public getName(): string {
		 return 'levelWiseAllOfferedSubjectList';
	}
}


export class LevelWiseAllOfferedSubjectListFd extends FormData {
	constructor(form: LevelWiseAllOfferedSubjectListForm, sa: ServiceAgent) {
		super(form, sa);
	}

	setFieldValue(name: 'instituteId' | 'degreeId' | 'departmentId' | 'seasonId' | 'offeredSubjectId' | 'name' | 'cieFrozen' | 'attendanceFrozen', value: string | number | boolean | null ): void {
		super.setFieldValue(name, value);
	}

	getFieldValue(name: 'instituteId' | 'degreeId' | 'departmentId' | 'seasonId' | 'offeredSubjectId' | 'name' | 'cieFrozen' | 'attendanceFrozen' ): string | number | boolean | null {
		return super.getFieldValue(name);
	}
}


export interface LevelWiseAllOfferedSubjectListVo extends Vo {
	offeredSubjectId?: number, 
	degreeId?: number, 
	seasonId?: number, 
	departmentId?: number, 
	name?: string, 
	instituteId?: number, 
	attendanceFrozen?: boolean, 
	cieFrozen?: boolean
}
