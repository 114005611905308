import { Component, OnInit, Input, ViewChild } from "@angular/core";
import { GroupPanel } from '../../interfaces';
import { ServiceAgent } from 'src/app/framework-modules/formdata/form/serviceAgent';
import { SessionComponent } from '../../component';
import { ClientContext } from 'src/app/framework-modules/formdata/form/clientContext';
import { StudentIdForUsnFd, StudentIdForUsnForm, StudentIdForUsnVo } from 'src/app/framework-modules/formdata/gen/studentIdForUsnForm';
import { MessageService } from 'src/app/services/messageService';
import { ChangeProgramFd, ChangeProgramForm } from 'src/app/framework-modules/formdata/gen/changeProgramForm';
import { MatDialog } from '@angular/material';
import { CtDialogComponent } from 'src/app/framework-modules/elements/ct-dialog/component';
import { CtTableComponent, TableMetaData } from 'src/app/framework-modules/elements/ct-table/component';
import { SubjectRegisterDetailFd, SubjectRegisterDetailForm, SubjectRegisterDetailVo } from 'src/app/framework-modules/formdata/gen/subjectRegisterDetailForm';
import { FilterRequest } from 'src/app/framework-modules/formdata/form/types';
import { InstituteConfigurationFd, InstituteConfigurationForm, InstituteConfigurationVo } from 'src/app/framework-modules/formdata/gen/instituteConfigurationForm';
import { CtSpinner } from 'src/app/framework-modules/elements/ct-spinner/component';

@Component({
  selector: "change-program-edit",
  templateUrl: "./component.html",
  styleUrls: ["./component.scss"]
})

export class ChangeProgramEditComponent implements OnInit {
  @ViewChild("gridTable", { static: false }) gtable: CtTableComponent;
  @ViewChild("spinner", {static:false}) spinner: CtSpinner;
  @Input() inputData: any;
  @Input() parentPanel: GroupPanel;
  @Input() session: SessionComponent;

  public programForm: ChangeProgramForm;
  public programFd: ChangeProgramFd;

  searched: boolean = false;

  constructor(private sa: ServiceAgent, private cc: ClientContext, private ms:MessageService, private dialog: MatDialog) {
    this.programForm = ChangeProgramForm.getInstance();
    this.programFd = this.programForm.newFormData(this.sa);
  }

  ngOnInit(){
    this.programFd.setFieldValue('studentId',this.inputData.studentId);
  }

  changeProgram(){
    let dialogRef = this.dialog.open(CtDialogComponent);
    dialogRef.componentInstance.title = 'Change Program';
    dialogRef.componentInstance.content = 'Are you sure you want to change the student program?';
    dialogRef.componentInstance.primary = 'Change';
    const subscribeDialog = dialogRef.componentInstance.save.subscribe((data) => {
      this.dialog.closeAll();
      this.change();
    });
  }

  private change(){
    this.spinner.show("Saving changes....")
    if(this.programFd.validateForm()){
      this.programFd.save().subscribe(
        data=>{
          this.ms.showSuccess("Student Program changed successfully!");
          this.spinner.hide();
          this.parentPanel.navigate("ChangeProgram");
        },
        err =>{
          this.spinner.hide();
        }
      );
    } 
  }

  cancel(){
    let dialogRef = this.dialog.open(CtDialogComponent);
    dialogRef.componentInstance.title = 'Discard Changes';
    dialogRef.componentInstance.content = 'Are you sure you want to discard changes?';
    dialogRef.componentInstance.primary = 'Discard';
    const subscribeDialog = dialogRef.componentInstance.save.subscribe((data) => {
      this.dialog.closeAll();
      this.parentPanel.navigate("ChangeProgram");
    });
  }
  
  accept(){
    this.searched = true;
  }
}
