import { Component,Input, OnInit, AfterViewInit } from "@angular/core";
import { DynamicPanel, GroupPanel } from '../../interfaces';
import { ServiceAgent } from 'src/app/framework-modules/formdata/form/serviceAgent';
import { SessionComponent } from '../../component';
import { FormData } from 'src/app/framework-modules/formdata/form/formData';
import { FieldValues } from 'src/app/framework-modules/formdata/form/types';
import { StudentEditForm } from 'src/app/framework-modules/formdata/gen/studentEditForm';
import { CtDialogComponent } from 'src/app/framework-modules/elements/ct-dialog/component';
import { MatDialog } from '@angular/material';
import { MessageService } from 'src/app/services/messageService';
import { ClientContext } from 'src/app/framework-modules/formdata/form/clientContext';
import * as _ from 'lodash';

const ADDRESS_FIELDS = ['addressLine1', 'addressLine2', 'city', 'state', 'country', 'pincode'];
const PRESENT_FIELDS = ['presentAddressLine1', 'presentAddressLine2', 'presentCity', 'presentState', 'presentCountry', 'presentPincode'];
const MENU = {
  studentDetails: {name:"Student Details", displayonSidenav:true, order:1},
  address: {name:"Address Details", displayonSidenav:true, order:2},
  admissionDetails: {name:"Admission Details", displayonSidenav:true, order:3},
};
@Component({
  selector: "student-edit",
  templateUrl: "./component.html",
  styleUrls: ["./component.scss"]
})


export class StudentEditComponent implements DynamicPanel,OnInit,AfterViewInit{
  @Input() inputData: any;
  @Input() parentPanel: GroupPanel;
  @Input() session:SessionComponent;

  public form : StudentEditForm;
  public fd : FormData;
  public items: {};
  selected: any;
  order: number=1;
  
  public addressFlags: boolean[] = [false, false];
  public presentIsPermanent: boolean = false;

  constructor(private sa:ServiceAgent, private ms: MessageService, private dialog: MatDialog, private ctx: ClientContext){
    this.form = _.cloneDeep(StudentEditForm.getInstance());
    this.form.usn.isEditable = true;
    this.fd = this.form.newFormData(this.sa);
    this.items = MENU;
    this.selected = "studentDetails";
  }

  ngOnInit() {
    this.fd.setFieldValue('studentId', this.inputData.studentId)
    this.fd.fetchData().subscribe({
    	next: vo=>{
      },
    	error: msg => console.error("Error from server ", msg)
    });
  }

  ngAfterViewInit(){
    if(this.inputData.navigatedFrom == "StudentView"){
      this.fd.formGroup.get('name').disable();
      this.fd.formGroup.get('usn').disable();
      this.fd.formGroup.get('email').disable();
      this.fd.formGroup.get('departmentId').disable();
      this.fd.formGroup.get('programId').disable();
      this.fd.formGroup.get('levelId').disable();
      this.fd.formGroup.get('levelSectionId').disable();
      this.fd.formGroup.get('academicRegulationId').disable();
      this.fd.formGroup.get('studentTypeId').disable();
    }
  }

  save(){
    let dialogRef = this.dialog.open(CtDialogComponent);
    dialogRef.componentInstance.title = 'Save Changes';
    dialogRef.componentInstance.content = 'Are you sure you want to save changes?';
    dialogRef.componentInstance.primary = 'Save';
    const subscribeDialog = dialogRef.componentInstance.save.subscribe((data) => {
      this.dialog.closeAll();
      this.doSave();
    });
  }

  doSave() {
    //possible that the user might have changed address fields afer copying
    this.copyAllAddresses();
    if(this.inputData.navigatedFrom == "StudentView"){
      this.fd.formGroup.get('name').enable();
      this.fd.formGroup.get('usn').enable();
      this.fd.formGroup.get('email').enable();
      this.fd.formGroup.get('departmentId').enable();
      this.fd.formGroup.get('programId').enable();
      this.fd.formGroup.get('levelId').enable();
      this.fd.formGroup.get('levelSectionId').enable();
      this.fd.formGroup.get('academicRegulationId').enable();
      this.fd.formGroup.get('studentTypeId').enable();
    }
    this.fd.save().subscribe({
      next: vo => {
        this.ms.showSuccess("Student details changed successfully! ");
        if(this.ctx.getUser().userType == "Student"){
          this.parentPanel.navigate('StudentView', {studentId:this.inputData.studentId});
        }
        else {
          this.parentPanel.navigate("StudentList");
        }
      },
      error: err => {
        console.error(err);
        if(this.inputData.navigatedFrom == "StudentView"){
          this.fd.formGroup.get('name').disable();
          this.fd.formGroup.get('usn').disable();
          this.fd.formGroup.get('email').disable();
          this.fd.formGroup.get('departmentId').disable();
          this.fd.formGroup.get('programId').disable();
          this.fd.formGroup.get('levelId').disable();
          this.fd.formGroup.get('levelSectionId').disable();
          this.fd.formGroup.get('academicRegulationId').disable();
          this.fd.formGroup.get('studentTypeId').disable();
        }
        if(this.fd.getFieldsInError().length != 0){
          this.selected = "studentDetails";
          this.order = 1;
        }
        // this.ms.showError(err);
      }
    });
  }

  private copyAllAddresses(){
    const values = this.getAddress();
    if(this.presentIsPermanent){
      this.fd.setFieldValues(values);
    }
  }

  doCancel() {
    let dialogRef = this.dialog.open(CtDialogComponent);
    dialogRef.componentInstance.title = 'Discard Changes';
    dialogRef.componentInstance.content = 'Are you sure you want to discard changes?';
    dialogRef.componentInstance.primary = 'Discard';
    const subscribeDialog = dialogRef.componentInstance.save.subscribe((data) => {
      this.dialog.closeAll();
      if(this.ctx.getUser().userType == "Student"){
        this.parentPanel.navigate('StudentView', {studentId:this.inputData.studentId});
      }
      else {
        this.parentPanel.navigate("StudentList");
      }
    });   
  }

  /**
   * 
   * @param $event emitter from check-box for permenant address
   */

  togglePresentIsPermanent($event) {
    this.presentIsPermanent = $event.checked;
    this.fd.setFieldValues(this.getAddress())
  }

  private getAddress(): FieldValues {
    const src = this.fd.extractFields(PRESENT_FIELDS);
    const vals = {};
    for (let i = 0; i < PRESENT_FIELDS.length; i++) {
      vals[ADDRESS_FIELDS[i]] = src[PRESENT_FIELDS[i]];
    }
    return vals;
  }


  addGuardian(){
    this.parentPanel.navigate('GuardianEdit',{studentId:this.inputData.studentId,
                                              addressLine1:this.fd.getFieldValue('presentAddressLine1'),
                                              addressLine2:this.fd.getFieldValue('presentAddressLine2'),
                                              city:this.fd.getFieldValue('presentCity'),
                                              state:this.fd.getFieldValue('presentState'),
                                              pincode:this.fd.getFieldValue('presentPincode')});
  }

  navigate($event){
    this.selected = $event;
    this.order = this.items[this.selected]['order'];
  }

  next(){
    var ord = this.order + 1;
    Object.keys(this.items).forEach(element => {
      if(this.items[element].order == ord){
        this.selected = element;
        this.order = ord;
        return;
      }
    });
  }

  back(){
    var ord = this.order - 1;
    Object.keys(this.items).forEach(element => {
      if(this.items[element].order == ord){
        this.selected = element;
        this.order = ord;
        return;
      }
    });
  }
}