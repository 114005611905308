import { Component, OnInit, Input, ViewChild, ViewContainerRef, Type, ComponentFactoryResolver } from "@angular/core";
import { DynamicPanel, LandingPanel, GroupPanel } from '../interfaces'
import { SessionComponent, SecondaryMenu } from '../component';
import { StudentListComponent } from './student-list/component';
import { StudentAddComponent } from './student-add/component';
import { StudentEditComponent } from './student-edit/component';
import { StudentViewComponent } from './student-view/component';
import { MenuService } from 'src/app/services/menuService';
import { MessageService } from 'src/app/services/messageService';
import { ClientContext } from 'src/app/framework-modules/formdata/form/clientContext';
import { StudentSubjectsComponent } from './studentSubjects/component';
import { StudentResultComponent } from './student-results/component';
import { StudentNamesEditComponent } from './student-names-edit/component';
import { GuardianEditComponent } from './guardian-edit/component';
import { StudentUploadComponent } from "./student-upload/component";

/**
 Const to map the screen-component to screen-name to rended the facotry
 */
const MENU: SecondaryMenu= {
  StudentUpload: { comp: StudentUploadComponent, title: "Upload Students", sideNav: true,displayonSidenav: true, pageId: "D180", name: "Students Upload", mainNav: true,order:1 },
  StudentList: { comp: StudentListComponent, title: "List of Students", sideNav: true,displayonSidenav: true, pageId: "D32", name: "Students List", mainNav: true,order:2 },
  StudentAdd: { comp: StudentAddComponent, title: "Add A New Student", sideNav: false, pageId: "D33",  name: "Add a Student", mainNav: false },
  StudentView: { comp: StudentViewComponent, title: "View Student Details", sideNav: false, pageId: "D35", name: "View Student", mainNav: true },
  StudentEdit: { comp: StudentEditComponent, title: "Edit Student Details", sideNav: false, pageId: "D34", name: "Edit a Student", mainNav: false },
  StudentSubjects: {comp: StudentSubjectsComponent, title: "Subject Details", sideNav: false, pageId: "D87", name: "Subject Details", mainNav: false},
  StudentResult: {comp:StudentResultComponent, title:"Final results", sideNav: false, pageId:"D89", name:"Results", mainNav: false},
  StudentNames: { comp: StudentNamesEditComponent, title: "Edit Student Details", sideNav: false, pageId: "D34", name: "Edit a Student", mainNav: false },
  GuardianEdit: { comp: GuardianEditComponent, title: "Edit Guardian Details", sideNav: false, pageId: "D138", name: "Edit a Guardian", mainNav: false },
};

@Component({
  selector: "Students",
  templateUrl: "./component.html",
  styleUrls: ["./component.scss"]
})
export class StudentComponent implements OnInit, GroupPanel {
  @Input() inputData: any;
  @Input() parentPanel: SessionComponent;
  menu: { [key: string]: Type<DynamicPanel> };
  sidenav: boolean = false;
  displayNames: {}
  cardHeader: any;
  defaultSelected: string;
  screens: string[];
  defaultComponent: string;
  public items: {};
  /**
    container for the child page loaded from the seconday navbar
   */

  @ViewChild("secondaryContainer", { static: true, read: ViewContainerRef }) container: ViewContainerRef;
  
  constructor(private componentFactoryResolver: ComponentFactoryResolver, private mns: MenuService, private ms: MessageService, private cc: ClientContext) {
    this.screens = this.mns.getScreens();
    Object.keys(MENU).forEach( key => {
      if(this.screens.includes(MENU[key].pageId) && this.defaultSelected == undefined){
        this.defaultSelected = MENU[key].name;
        this.cardHeader = MENU[key].title;
        this.defaultComponent = key;
      }
    })
  }

  ngOnInit() {
    /**
     Navigate to Student List page on app load
     */
    this.navigate(this.defaultComponent, {});
    this.items = MENU;
  }

  /**
   *
   * @param compName is the name of the component to navigate to
   * @param data is for any related data needed
   */

  public navigate(compName: string, data: any): void {
    let m = MENU[compName];
    if(!this.cc.isPageValid(m.pageId)){
      this.ms.showInfo('Navigation Error: ' + compName + ' is not authorized for this user.');
      return;
    }
    this.parentPanel.toggleMainNav(m.mainNav)
    this.sidenav = m.sideNav;
    if (m.name != undefined) {
      this.defaultSelected = m.name;
    }
    this.cardHeader = m.title;
    this.container.clear();
    const factory = this.componentFactoryResolver.resolveComponentFactory(
      m.comp
    );
    const c = this.container.createComponent(factory);
    c.instance.inputData = data;
    c.instance.parentPanel = this;
    c.changeDetectorRef.detectChanges();
  }

  fullScreenMode(){
    this.sidenav = false;
    this.parentPanel.hideMainNav();
  }

  halfScreenMode(){
    this.sidenav = true;
    this.parentPanel.showMainNav();
  }
}
