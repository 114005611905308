import { Component, Input, Output, EventEmitter, OnInit, ViewChild, AfterViewInit } from "@angular/core";
import { DynamicPanel, LandingPanel, GroupPanel } from '../../interfaces';
import { ServiceAgent } from 'src/app/framework-modules/formdata/form/serviceAgent';
import { SessionComponent } from '../../component';
import { StaffForm, StaffFd } from 'src/app/framework-modules/formdata/gen/staffForm';
import { MessageService } from 'src/app/services/messageService';
import { DesignationForm, DesignationFd } from 'src/app/framework-modules/formdata/gen/designationForm';
import { CtCheckboxTableComponent } from 'src/app/framework-modules/elements/ct-checkbox-table/component';
import { TableMetaData } from 'src/app/framework-modules/elements/ct-table/component';
import { CtDialogComponent } from 'src/app/framework-modules/elements/ct-dialog/component';
import { MatDialog } from '@angular/material';

const PAGE_DETAILS = [

  {pageId:"D01",screenName:"Authorization List",screenDesc:"List of authorization",moduleName:"Authorization",selected:false,moduleId:"G01"},
  {pageId:"D02",screenName:"Pending Authorisation",screenDesc:"List of pending authorization",moduleName:"Authorization",selected:false,moduleId:"G01"},
  {pageId:"D03",screenName:"Authorisation Edit",screenDesc:"Edit pending authorizations",moduleName:"Authorization",selected:false,moduleId:"G01"},
  {pageId:"D04",screenName:"Freeze List",screenDesc:"Freeze CIE and Attendance",moduleName:"Freeze",selected:false,moduleId:"G02"},
  {pageId:"D05",screenName:"Institute List",screenDesc:"List of Institutes in the Trust",moduleName:"Institutes",selected:false,moduleId:"G03"},
  {pageId:"D06",screenName:"Institute Add",screenDesc:"Add a new Institute",moduleName:"Institutes",selected:false,moduleId:"G03"},
  {pageId:"D07",screenName:"Institute Edit",screenDesc:"Edit Institute Details",moduleName:"Institutes",selected:false,moduleId:"G03"},
  {pageId:"D08",screenName:"Institute View",screenDesc:"View Institute Details",moduleName:"Institutes",selected:false,moduleId:"G03"},
  {pageId:"D09",screenName:"Department List",screenDesc:"List of departments in an institute",moduleName:"Institutes",selected:false,moduleId:"G03"},
  {pageId:"D10",screenName:"Department Add",screenDesc:"Add a new Department",moduleName:"Institutes",selected:false,moduleId:"G03"},
  {pageId:"D11",screenName:"Department Edit",screenDesc:"Edit Department Details",moduleName:"Institutes",selected:false,moduleId:"G03"},
  {pageId:"D12",screenName:"Program List",screenDesc:"List of programs of an institute",moduleName:"Institutes",selected:false,moduleId:"G03"},
  {pageId:"D13",screenName:"Program Add",screenDesc:"Add a new program",moduleName:"Institutes",selected:false,moduleId:"G03"},
  {pageId:"D14",screenName:"Level List",screenDesc:"List of levels in an institute",moduleName:"Institutes",selected:false,moduleId:"G03"},
  {pageId:"D15",screenName:"Test Type Allocation",screenDesc:"Allocate a test type to a subject",moduleName:"Subjects",selected:false,moduleId:"G07"},
  {pageId:"D16",screenName:"Attendance List",screenDesc:"Details of attendance of students",moduleName:"Sessionals",selected:false,moduleId:"G04"},
  {pageId:"D17",screenName:"Attendance Edit",screenDesc:"Mark attendance details",moduleName:"Sessionals",selected:false,moduleId:"G04"},
  {pageId:"D18",screenName:"Eligibility List",screenDesc:"List of Eligible Students",moduleName:"Sessionals",selected:false,moduleId:"G04"},
  {pageId:"D113",screenName:"Staff Eligibility List",screenDesc:"List of Eligible Students for Staff",moduleName:"Sessionals",selected:false,moduleId:"G04"},
  {pageId:"D19",screenName:"Marks Entry List",screenDesc:"List of marks of students",moduleName:"Sessionals",selected:false,moduleId:"G04"},
  {pageId:"D20",screenName:"Condone Attendance List",screenDesc:"List of Students for condonement",moduleName:"Sessionals",selected:false,moduleId:"G04"},
  {pageId:"D21",screenName:"Condone Attendance Edit",screenDesc:"Condone Students",moduleName:"Sessionals",selected:false,moduleId:"G04"},
  {pageId:"D22",screenName:"Hall Ticket List",screenDesc:"Generate Hall tickets",moduleName:"Sessionals",selected:false,moduleId:"G04"},
  {pageId:"D23",screenName:"Marks Entry Edit",screenDesc:"Update Marks of Students",moduleName:"Sessionals",selected:false,moduleId:"G04"},
  {pageId:"D24",screenName:"Attendance Update",screenDesc:"Update Attendace details of a student",moduleName:"Sessionals",selected:false,moduleId:"G04"},
  {pageId:"D25",screenName:"CIE Calculation",screenDesc:"Calculate CIE of students",moduleName:"Sessionals",selected:false,moduleId:"G04"},
  {pageId:"D26",screenName:"Bulk Marks Entry",screenDesc:"Bulk update marks",moduleName:"Sessionals",selected:false,moduleId:"G04"},
  {pageId:"D27",screenName:"Marks Entry Update",screenDesc:"Update Marks of students",moduleName:"Sessionals",selected:false,moduleId:"G04"},
  {pageId:"D28",screenName:"Staff List",screenDesc:"List of Staffs",moduleName:"Staffs",selected:false,moduleId:"G05"},
  {pageId:"D29",screenName:"Staff Add",screenDesc:"Add a new Staff",moduleName:"Staffs",selected:false,moduleId:"G05"},
  {pageId:"D30",screenName:"Staff Edit",screenDesc:"Edit the details of a staff",moduleName:"Staffs",selected:false,moduleId:"G05"},
  // {pageId:"D31",screenName:"",screenDesc:"",moduleName:"G",selected:false,moduleId:"G05"},
  {pageId:"D32",screenName:"Student List",screenDesc:"List of students",moduleName:"Students",selected:false,moduleId:"G06"},
  {pageId:"D33",screenName:"Student Add",screenDesc:"Add a new Student",moduleName:"Students",selected:false,moduleId:"G06"},
  {pageId:"D34",screenName:"Student Edit",screenDesc:"Edit the details of a student",moduleName:"Students",selected:false,moduleId:"G06"},
  {pageId:"D35",screenName:"Student View",screenDesc:"View the details of a student",moduleName:"Students",selected:false,moduleId:"G06"},
  {pageId:"D36",screenName:"Subject List",screenDesc:"List of Subjects",moduleName:"Subjects",selected:false,moduleId:"G07"},
  {pageId:"D37",screenName:"Subject Add",screenDesc:"Add a new Subject",moduleName:"Subjects",selected:false,moduleId:"G07"},
  {pageId:"D38",screenName:"Subject Edit",screenDesc:"Edit the details of a subject",moduleName:"Subjects",selected:false,moduleId:"G07"},
  {pageId:"D39",screenName:"Offered Subject List",screenDesc:"List of Offered Subjects",moduleName:"Subjects",selected:false,moduleId:"G07"},
  {pageId:"D40",screenName:"Offered Subject Edit",screenDesc:"Edit Details of Offered Subjects",moduleName:"Subjects",selected:false,moduleId:"G07"},
  {pageId:"D41",screenName:"Registered Student List",screenDesc:"List of Registered Students",moduleName:"Subjects",selected:false,moduleId:"G07"},
  {pageId:"D42",screenName:"Register Students",screenDesc:"Register new students to a subject",moduleName:"Subjects",selected:false,moduleId:"G07"},
  {pageId:"D43",screenName:"Assigned Staff List",screenDesc:"List of staff assigned to a subject",moduleName:"Subjects",selected:false,moduleId:"G07"},
  {pageId:"D44",screenName:"Assign Staff Edit",screenDesc:"",moduleName:"Subjects",selected:false,moduleId:"G07"},
  {pageId:"D45",screenName:"Exam Attendance Edit",screenDesc:"Edit Exam Attendance of a Student",moduleName:"Terminals",selected:false,moduleId:"G08"},
  {pageId:"D46",screenName:"Coding",screenDesc:"Answer Script Coding",moduleName:"Terminals",selected:false,moduleId:"G08"},
  {pageId:"D47",screenName:"Assign Evaluators List",screenDesc:"List of Assigned Evaluators",moduleName:"Terminals",selected:false,moduleId:"G08"},
  {pageId:"D48",screenName:"Assign Evaluators Edit",screenDesc:"Edit Assigned Evaluators",moduleName:"Terminals",selected:false,moduleId:"G08"},
  {pageId:"D49",screenName:"Exam Attendance List",screenDesc:"View marked Exam Attendance",moduleName:"Terminals",selected:false,moduleId:"G08"},
  {pageId:"D50",screenName:"External Staff List",screenDesc:"List of External Evaluators",moduleName:"Terminals",selected:false,moduleId:"G08"},
  {pageId:"D51",screenName:"External Staff Add",screenDesc:"Add a new External staff",moduleName:"Terminals",selected:false,moduleId:"G08"},
  {pageId:"D52",screenName:"External Staff Edit",screenDesc:"Edit details of an External Staff",moduleName:"Terminals",selected:false,moduleId:"G08"},
  // {pageId:"D53",screenName:"",screenDesc:"",moduleName:"G",selected:false,moduleId:"G"},
  {pageId:"D54",screenName:"Download List",screenDesc:"Download Data",moduleName:"Downloads",selected:false,moduleId:"G09"},
  // {pageId:"D55",screenName:"Freeze List",screenDesc:"Freeze CIE and Attendance",moduleName:"Freeze",selected:false,moduleId:"G10"},
  {pageId:"D56",screenName:"Hall Ticket Download",screenDesc:"Download a Hall Ticket",moduleName:"Sessionals",selected:false,moduleId:"G04"},
  // {pageId:"D57",screenName:"",screenDesc:"",moduleName:"Terminals",selected:false,moduleId:"G08"},
  // {pageId:"D58",screenName:"",screenDesc:"",moduleName:"Terminals",selected:false,moduleId:"G08"},
  // {pageId:"D59",screenName:"",screenDesc:"",moduleName:"Terminals",selected:false,moduleId:"G08"},
  // {pageId:"D60",screenName:"",screenDesc:"",moduleName:"Terminals",selected:false,moduleId:"G08"},
  // {pageId:"D61",screenName:"",screenDesc:"",moduleName:"Terminals",selected:false,moduleId:"G08"},
  // {pageId:"D62",screenName:"",screenDesc:"",moduleName:"Terminals",selected:false,moduleId:"G08"},
  // {pageId:"D63",screenName:"",screenDesc:"",moduleName:"Terminals",selected:false,moduleId:"G08"},
  // {pageId:"D64",screenName:"",screenDesc:"",moduleName:"Terminals",selected:false,moduleId:"G08"},
  // {pageId:"D65",screenName:"",screenDesc:"",moduleName:"Terminals",selected:false,moduleId:"G08"},
  {pageId:"D66",screenName:"Marks View",screenDesc:"View the marks of students for a subject",moduleName:"Sessionals",selected:false,moduleId:"G04"},
  {pageId:"D67",screenName:"Attendance View",screenDesc:"View the Attendance of students for a subject",moduleName:"Sessionals",selected:false,moduleId:"G04"},
  {pageId:"D68",screenName:"Subject CIE Calculation",screenDesc:"Calculate the CIE of students subject wise",moduleName:"Sessionals",selected:false,moduleId:"G04"},
  {pageId:"D69",screenName:"Initiate Valuation",screenDesc:"Inititate and freeze valuation rounds",moduleName:"Terminals",selected:false,moduleId:"G08"},
  {pageId:"D70",screenName:"Results - Subject",screenDesc:"View marks Subject wise",moduleName:"Terminals",selected:false,moduleId:"G08"},
  {pageId:"D71",screenName:"Results - Level wise",screenDesc:"View marks of all students",moduleName:"Terminals",selected:false,moduleId:"G08"},
  {pageId:"D72",screenName:"Results - Valuation",screenDesc:"View marks of different valuation rounds",moduleName:"Terminals",selected:false,moduleId:"G08"},
  {pageId:"D73",screenName:"Marks - Consolidated (details)",screenDesc:"View details of marks of a student",moduleName:"Terminals",selected:false,moduleId:"G08"},
  {pageId:"D74",screenName:"Revaluation List",screenDesc:"List of students approved for revaluation",moduleName:"Terminals",selected:false,moduleId:"G08"},
  {pageId:"D75",screenName:"Revaluation Approve",screenDesc:"Aprrove students for revaluation",moduleName:"Terminals",selected:false,moduleId:"G08"},
  {pageId:"D76",screenName:"Challenge Revaluation List",screenDesc:"Challenge Revaluation Lis",moduleName:"Terminals",selected:false,moduleId:"G08"},
  {pageId:"D77",screenName:"Challenge Revaluation Edit",screenDesc:"Challenge Revaluation Edit",moduleName:"Terminals",selected:false,moduleId:"G08"},
  // {pageId:"D78",screenName:"",screenDesc:"",moduleName:"G",selected:false,moduleId:"G"},
  // {pageId:"D79",screenName:"",screenDesc:"",moduleName:"G",selected:false,moduleId:"G"},
  {pageId:"D80",screenName:"Designation List",screenDesc:"List of Designations",moduleName:"Staffs",selected:false,moduleId:"G05"},
  {pageId:"D81",screenName:"Designation Add",screenDesc:"Add a new Designation",moduleName:"Staffs",selected:false,moduleId:"G05"},
  {pageId:"D82",screenName:"Designation Edit",screenDesc:"Edit a Designation",moduleName:"Staffs",selected:false,moduleId:"G05"},
  {pageId:"D83",screenName:"Withdrawal List",screenDesc:"List of students who have withdrawn",moduleName:"Sessionals",selected:false,moduleId:"G04"},
  {pageId:"D84",screenName:"Withdrawal Edit",screenDesc:"Edit list of withdrawn students",moduleName:"Sessionals",selected:false,moduleId:"G04"},
  {pageId:"D85",screenName:"Exemption List",screenDesc:"List of Exempted students",moduleName:"Terminals",selected:false,moduleId:"G08"},
  {pageId:"D86",screenName:"Exemption Edit",screenDesc:"Edit the list of exempted students",moduleName:"Terminals",selected:false,moduleId:"G08"},
  // {pageId:"D87",screenName:"",screenDesc:"",moduleName:"G",selected:false,moduleId:"G"},
  // {pageId:"D88",screenName:"",screenDesc:"",moduleName:"G",selected:false,moduleId:"G"},
  // {pageId:"D89",screenName:"",screenDesc:"",moduleName:"G",selected:false,moduleId:"G"},
  // {pageId:"D90",screenName:"",screenDesc:"",moduleName:"G",selected:false,moduleId:"G"},
  // {pageId:"D91",screenName:"",screenDesc:"",moduleName:"G",selected:false,moduleId:"G"},
  // {pageId:"D92",screenName:"",screenDesc:"",moduleName:"G",selected:false,moduleId:"G"},
  // {pageId:"D93",screenName:"",screenDesc:"",moduleName:"G",selected:false,moduleId:"G"},
  // {pageId:"D94",screenName:"",screenDesc:"",moduleName:"G",selected:false,moduleId:"G"},
  // {pageId:"D95",screenName:"",screenDesc:"",moduleName:"G",selected:false,moduleId:"G"},
  // {pageId:"D96",screenName:"",screenDesc:"",moduleName:"G",selected:false,moduleId:"G"},
  // {pageId:"D97",screenName:"",screenDesc:"",moduleName:"G",selected:false,moduleId:"G"},
  // {pageId:"D98",screenName:"",screenDesc:"",moduleName:"G",selected:false,moduleId:"G"},
  // {pageId:"D99",screenName:"",screenDesc:"",moduleName:"G",selected:false,moduleId:"G"},
  // {pageId:"D100",screenName:"",screenDesc:"",moduleName:"G",selected:false,moduleId:"G"},
  {pageId:"D101",screenName:"Assign Packets List",screenDesc:"List of Assigned Packets",moduleName:"Terminals",selected:false,moduleId:"G08"},
  {pageId:"D102",screenName:"Assign Packets Edit",screenDesc:"Edit Assigned Packets",moduleName:"Terminals",selected:false,moduleId:"G08"},
  {pageId:"D103",screenName:"Packet List - Subject",screenDesc:"View packets according to a subject",moduleName:"Terminals",selected:false,moduleId:"G08"},
  {pageId:"D150",screenName:"Packet List - Search",screenDesc:"Search a packet by its code",moduleName:"Terminals",selected:false,moduleId:"G08"},
  {pageId:"D151",screenName:"Packet Status",screenDesc:"View the Completion Status of packets",moduleName:"Terminals",selected:false,moduleId:"G08"},
  {pageId:"D152",screenName:"Force Evaluation",screenDesc:"Force a packet into Evaluation",moduleName:"Terminals",selected:false,moduleId:"G08"},
  {pageId:"D104",screenName:"Valuation View",screenDesc:"View the status of Valuation",moduleName:"Terminals",selected:false,moduleId:"G08"},
  {pageId:"D105",screenName:"Valuation Edit",screenDesc:"Edit Valuation status",moduleName:"Terminals",selected:false,moduleId:"G08"},
  // {pageId:"D106",screenName:"",screenDesc:"",moduleName:"Terminals",selected:false,moduleId:"G08"},
  // {pageId:"D107",screenName:"",screenDesc:"",moduleName:"Terminals",selected:false,moduleId:"G08"},
  // {pageId:"D108",screenName:"",screenDesc:"",moduleName:"Terminals",selected:false,moduleId:"G08"},
  // {pageId:"D109",screenName:"",screenDesc:"",moduleName:"Terminals",selected:false,moduleId:"G08"},
  {pageId:"D111",screenName:"Packet List - Evaluator",screenDesc:"View packets according to an evaluator",moduleName:"Terminals",selected:false,moduleId:"G08"},
  {pageId:"D120",screenName:"Admin Dashboard",screenDesc:"Admin Dashboard",moduleName:"Dashboard",selected:false,moduleId:"G11"},
  {pageId:"D149",screenName:"CoE Dashboard",screenDesc:"CoE Dashboard",moduleName:"Dashboard",selected:false,moduleId:"G11"},
  {pageId:"D122",screenName:"Admin Dashboard - Details",screenDesc:"View detailed jist of students selected from dashboard",moduleName:"Dashboard",selected:false,moduleId:"G11"},
  {pageId:"D121",screenName:"Staff Dashboard",screenDesc:"Staff Dashboard",moduleName:"Dashboard",selected:false,moduleId:"G11"},
  // {pageId:"D110",screenName:"",screenDesc:"",moduleName:"Terminals",selected:false,moduleId:"G08"},
  {pageId:"D130",screenName:"Enrollments List",screenDesc:"Enrollments-List",moduleName:"Institutes",selected:false,moduleId:"G03"},
  {pageId:"D131",screenName:"Enrollments Edit",screenDesc:"Enrollments-Edit",moduleName:"Institutes",selected:false,moduleId:"G03"},
  {pageId:"D133",screenName:"Final exam Marks List",screenDesc:"View Final Exam Marks",moduleName:"Terminals",selected:false,moduleId:"G08"},
  {pageId:"D134",screenName:"Final exam Marks Edit",screenDesc:"Edit Final Exam Marks",moduleName:"Terminals",selected:false,moduleId:"G08"},
  {pageId:"D132",screenName:"Student Archive",screenDesc:"One-click Student History",moduleName:"One-click",selected:false,moduleId:"G12"},
  {pageId:"D135",screenName:"ICD Dashboard",screenDesc:"ICD Dashboard",moduleName:"ICD",selected:false,moduleId:"G13"},
  {pageId:"D136",screenName:"ICD Dashboard View",screenDesc:"ICD Dashboard drill down",moduleName:"ICD",selected:false,moduleId:"G13"},
  {pageId:"D137",screenName:"Change Program",screenDesc:"Change a student's program",moduleName:"Institutes",selected:false,moduleId:"G03"},
  {pageId:"D138",screenName:"Guardian Edit",screenDesc:"Edit the details of a guardians",moduleName:"Students",selected:false,moduleId:"G06"},
  {pageId:"D139",screenName:"Assessment Scheme List",screenDesc:"List of assessment schemes",moduleName:"Institutes",selected:false,moduleId:"G03"},
  {pageId:"D140",screenName:"Assessment Scheme Add",screenDesc:"Add a new assessment scheme",moduleName:"Institutes",selected:false,moduleId:"G03"},
  {pageId:"D141",screenName:"Subject Grading",screenDesc:"Grade a Subject",moduleName:"Terminals",selected:false,moduleId:"G08"},
  {pageId:"D142",screenName:"Degree Grading",screenDesc:"Grade subjects offered to a degree",moduleName:"Terminals",selected:false,moduleId:"G08"},
  {pageId:"D143",screenName:"First Year Register List",screenDesc:"Register for First year students",moduleName:"Subjects",selected:false,moduleId:"G07"},
  {pageId:"D144",screenName:"First Year Register Add",screenDesc:"Create register for first year",moduleName:"Subjects",selected:false,moduleId:"G07"},
  {pageId:"D145",screenName:"Auto Register List",screenDesc:"Subjects for auto register",moduleName:"Subjects",selected:false,moduleId:"G07"},
  {pageId:"D146",screenName:"Auto Register Add",screenDesc:"Create register automatically",moduleName:"Subjects",selected:false,moduleId:"G07"},
  {pageId:"D147",screenName:"Manual Register List",screenDesc:"Subjects for manual register",moduleName:"Subjects",selected:false,moduleId:"G07"},
  {pageId:"D148",screenName:"Manual Register Add",screenDesc:"Create Registers Manually",moduleName:"Subjects",selected:false,moduleId:"G07"},
  {pageId:"D157",screenName:"Assign Reviewer",screenDesc:"Assign a reviewer for challenge valuation",moduleName:"Terminals",selected:false,moduleId:"G08"},
  {pageId:"D158",screenName:"Project Marks Enrty",screenDesc:"List of project marks",moduleName:"Terminals",selected:false,moduleId:"G08"},
  {pageId:"D159",screenName:"Edit Project Marks",screenDesc:"Enter project marks",moduleName:"Terminals",selected:false,moduleId:"G08"},
  {pageId:"D160",screenName:"Update Project Marks",screenDesc:"Enter project marks",moduleName:"Terminals",selected:false,moduleId:"G08"},
  {pageId:"D161",screenName:"Bulk Update Project Marks",screenDesc:"Enter project marks",moduleName:"Terminals",selected:false,moduleId:"G08"},
  {pageId:"D88",screenName:"CIE Submissions",screenDesc:"CIE Submissions",moduleName:"Sessionals",selected:false,moduleId:"G07"},
  {pageId:"D162",screenName:"PDC Download",screenDesc:"PDC Download",moduleName:"Terminals",selected:false,moduleId:"G08"}
];
@Component({
  selector: "staff-add",
  templateUrl: "./component.html",
  styleUrls: ["./component.scss"]
})


export class DesignationAddComponent implements DynamicPanel, OnInit, AfterViewInit {
  @ViewChild("gridTable", { static: false }) gtable: CtCheckboxTableComponent;
  @Input() inputData: any;
  @Input() parentPanel: GroupPanel;
  @Input() session: SessionComponent;

  public form: DesignationForm;
  public fd: DesignationFd;
  public tableData: TableMetaData;

  constructor(private sa: ServiceAgent, private messageService: MessageService, private dialog: MatDialog) {
    this.form = DesignationForm.getInstance();
    this.fd = new DesignationFd(this.form, this.sa);
  }

  ngOnInit() {
    this.fd = this.form.newFormData(this.sa);
    this.metaDataInit()
  }

  ngAfterViewInit(){
    this.gtable.update();
  }

  metaDataInit(){
    this.tableData = {
      data: PAGE_DETAILS,
      metaData: {
        selectAttr: "selected",
        column_names: [
          "screenName",
          "screenDesc",
          "moduleName",
        ],
        display_names: {
          "screenName": "Screen Name",
          "screenDesc": "Screen Description",
          "moduleName": "Module Name",
        },
        itemName:"Screens",
        managerFunction: "SAVE",
        search:true,
        placeholder: "Search by Screen, Module..."
      }
    }
  }

  save(){
    let dialogRef = this.dialog.open(CtDialogComponent);
    dialogRef.componentInstance.title = 'Save Changes';
    dialogRef.componentInstance.content = 'Are you sure you want to save changes?';
    dialogRef.componentInstance.primary = 'Save';
    const subscribeDialog = dialogRef.componentInstance.save.subscribe((data) => {
      this.dialog.closeAll();
      this.doSave();
    });
  }

  doSave() {
    var str = "";
    for(var i=0; i< this.gtable.selection.selected.length; i++){
      str += this.gtable.selection.selected[i]["pageId"];
      if(i != this.gtable.selection.selected.length-1){
        str += ","
      }
    }
    this.fd.setFieldValue('allowedPages',str);
    this.fd.saveAsNew().subscribe({
      next: vo => {
        this.parentPanel.navigate("DesignationList");
      },
      error: err => {
        console.error(err);
      }
    });
  }

  doCancel() {
    let dialogRef = this.dialog.open(CtDialogComponent);
    dialogRef.componentInstance.title = 'Discard Changes';
    dialogRef.componentInstance.content = 'Are you sure you want to discard changes?';
    dialogRef.componentInstance.primary = 'Discard';
    const subscribeDialog = dialogRef.componentInstance.save.subscribe((data) => {
      this.dialog.closeAll();
      this.parentPanel.navigate('DesignationList');
    }); 
  }

}
