import { Component, OnInit, ViewChild, Input } from "@angular/core";
import { DynamicPanel, GroupPanel } from '../../interfaces';
import { ServiceAgent } from 'src/app/framework-modules/formdata/form/serviceAgent';
import { CtTableComponent } from '../../../../framework-modules/elements/ct-table/component'
import { SessionComponent } from '../../component';
import { MessageService } from 'src/app/services/messageService';
import { SubjectSectionDetailVo } from 'src/app/framework-modules/formdata/gen/subjectSectionDetailForm';
import { MatDialog } from '@angular/material';
import { CtDialogComponent } from 'src/app/framework-modules/elements/ct-dialog/component';
import { ManualRegisterFd, ManualRegisterForm } from 'src/app/framework-modules/formdata/gen/manualRegisterForm';
import { SectionFd, SectionForm } from 'src/app/framework-modules/formdata/gen/sectionForm';
import { Form } from 'src/app/framework-modules/formdata/form/form';
import { Vo } from 'src/app/framework-modules/formdata/form/types';
import { FormData } from 'src/app/framework-modules/formdata/form/formData';

@Component({
  selector: "manual-register-edit",
  templateUrl: "./component.html",
  styleUrls: ["./component.scss"]
})


export class ManualRegisterEditComponent implements DynamicPanel, OnInit {
  @ViewChild("gridTable", { static: false }) gtable: CtTableComponent;
  @Input() inputData: any;
  @Input() parentPanel: GroupPanel;
  @Input() session: SessionComponent;

  public form: ManualRegisterForm;
  public fd: ManualRegisterFd;
  public sectionForm: SectionForm;
  public sectionFd: SectionFd;
  public levelSelected:boolean = false;
  public sectionList: SubjectSectionDetailVo[] = [];
  public isRegistered: boolean = false;
  public sections: SectionFd[] = [];
  actions: Array<any> = [];

  constructor(private sa: ServiceAgent, private ms: MessageService,public dialog:MatDialog) {
    this.form = ManualRegisterForm.getInstance();
    this.fd = this.form.newFormData(this.sa);
    this.sectionForm = SectionForm.getInstance();
    this.sectionFd = this.sectionForm.newFormData(this.sa);
  }

  ngOnInit() {
    this.fetchSubjectDetails();
  }

  fetchSubjectDetails(): void {
    if (!this.inputData) {
      console.error('Design error : Input for subjectId not received.');
      return;
    }
    this.fd.setFieldValue('subjectId', this.inputData);
    this.fd.fetchData().subscribe(
      data => {
        if(data.isOffered || data.degreeId!=0){
          this.levelSelected = true;
          this.isRegistered = data.isRegistered as boolean;
          this.sections = this.sectionFd.toFdArray(data.sections as Vo[],this.sectionForm,this.sa);
          this.sections.forEach(r=>{
            this.actions.push([false,false]);
            r.formGroup.disable();
          });
          if(!data.isRegistered){
            this.sections.push(this.sectionForm.newFormData(this.sa));
            this.actions.push([false,true]);
          }
        }
      },
      msgs => {
        this.ms.showError('Server returned with Error : ' + msgs);
      }
    );
  }

  /**
   * save the changes
   */
  save() {
    let dialogRef = this.dialog.open(CtDialogComponent);
    dialogRef.componentInstance.title = 'Save Changes';
    dialogRef.componentInstance.content = 'Are you sure you want to save changes?';
    dialogRef.componentInstance.primary = 'Save';
    const subscribeDialog = dialogRef.componentInstance.save.subscribe((data) => {
      this.dialog.closeAll();
      this.doSave();
    });
    
  }

  private doSave(){
    if(this.validate()){
      this.fd.childTabularData.get("sections").setAll(this.sectionFd.toVoArray(this.sections));
      this.fd.save().subscribe({
        next: data => {
          this.ms.showSuccess('Subject Offered to sections Successfully')
          this.parentPanel.navigate('ManualRegister');
        },
        error: err => {
          if(err){
            if(err[0].type == 'error'){
              this.ms.showError('Server returned with Error : ' + err[0].text);
            }
          }
        }
      });
    }
  }

  private validate(){
    let flag = true;
    this.sections.forEach(section =>{
      flag = flag && section.validateForm();
    })
    return flag;
  }

  /**
   * user discards changes
   */
  cancel(){
    let dialogRef = this.dialog.open(CtDialogComponent);
    dialogRef.componentInstance.title = 'Discard Changes';
    dialogRef.componentInstance.content = 'Are you sure you want to discard changes?';
    dialogRef.componentInstance.primary = 'Discard';
    const subscribeDialog = dialogRef.componentInstance.save.subscribe((data) => {
      this.dialog.closeAll();    
      this.parentPanel.navigate('ManualRegister');
    });
  }

  removeSection(i){
    this.sections.splice(i,1);
    this.actions.splice(i, 1);
    this.actions[this.actions.length - 1][1] = true;
  }

  addSection(){
    this.sections.push(this.sectionForm.newFormData(this.sa));
    this.actions[this.actions.length - 1][1] = false;
    this.actions.push([true, true]);
  }
}