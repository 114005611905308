import { Component, OnInit, ViewChild, Input, AfterViewInit } from "@angular/core";
import { DynamicPanel, GroupPanel } from '../../interfaces';
import { ServiceAgent } from 'src/app/framework-modules/formdata/form/serviceAgent';
import { SessionComponent } from '../../component';
import { MessageService } from 'src/app/services/messageService';
import { CtSpinner } from 'src/app/framework-modules/elements/ct-spinner/component';
import { CtTableComponent, TableMetaData } from 'src/app/framework-modules/elements/ct-table/component';
import { FilterRequest } from 'src/app/framework-modules/formdata/form/types';
import { ExcelDownloadService } from 'src/app/services/excelDownloadService';
import { IndividualGradeCardForm, IndividualGradeCardFd, IndividualGradeCardVo } from 'src/app/framework-modules/formdata/gen/individualGradeCardForm';
import { GradeCardStudentDetailFd, GradeCardStudentDetailForm } from 'src/app/framework-modules/formdata/gen/gradeCardStudentDetailForm';
import { MatInputModule } from '@angular/material';
import { ProgramForm, ProgramFd } from 'src/app/framework-modules/formdata/gen/programForm';
import { ClientContext } from 'src/app/framework-modules/formdata/form/clientContext';
import { HttpClient } from '@angular/common/http';
import { MtechGradeCardStudentDetailForm, MtechGradeCardStudentDetailFd } from 'src/app/framework-modules/formdata/gen/mtechGradeCardStudentDetailForm';
import * as moment from 'moment';

@Component({
  selector: "indidvidual-Garde-card-list",
  templateUrl: "./component.html",
  styleUrls: ["./component.scss"],
})


export class IndividualGradeCardListComponent implements DynamicPanel, OnInit, AfterViewInit {
  @ViewChild("gridTable", { static: false }) public gtable: CtTableComponent;
  @ViewChild("spinner", { static: false }) spinner: CtSpinner;
  @Input() inputData: any;
  @Input() parentPanel: GroupPanel;
  @Input() session: SessionComponent;

  public form: IndividualGradeCardForm;
  public fd: IndividualGradeCardFd;
  public vo: IndividualGradeCardVo = {};
  public gradeCardForm: GradeCardStudentDetailForm;
  public gradeCardFd: GradeCardStudentDetailFd;
  public programForm: ProgramForm;
  public programFd: ProgramFd;
  public tableData: TableMetaData;
  public studentDetailToggle:boolean = false;
  public studentData : any;
  public downloadToggle:boolean= true;
  public showImage:boolean;
  public imageUrl:any;
  public selectedImage:File;
  public base64:string;
  public finalMtechGradeCardForm: MtechGradeCardStudentDetailForm;
  public finalMtechGradeCardFd: MtechGradeCardStudentDetailFd;
  
  constructor(private sa: ServiceAgent, private ms: MessageService, private ds: ExcelDownloadService,
    private http:HttpClient,private ctx:ClientContext) {
    this.form = IndividualGradeCardForm.getInstance();
    this.fd = this.form.newFormData(this.sa);
    this.gradeCardForm = GradeCardStudentDetailForm.getInstance();
    this.gradeCardFd = this.gradeCardForm.newFormData(this.sa);
    this.programForm = ProgramForm.getInstance();
    this.programFd = this.programForm.newFormData(this.sa);
    this.finalMtechGradeCardForm = MtechGradeCardStudentDetailForm.getInstance();
    this.finalMtechGradeCardFd = this.finalMtechGradeCardForm.newFormData(this.sa);
  }


  ngOnInit() {
    this.metaDataInit();
    
  }

  ngAfterViewInit(){
  }

  /**
   * initialising the table data
   */
  metaDataInit() {
    this.tableData = {
      data: [] = [],
      metaData: {
        column_names: [
          "subjectName",
          "subjectCode",
          "grade",
          "gradePoints"
        ],
        display_names: {
          "subjectName":"Subject Name",
          "subjectCode":"Subject Code",
          "grade":"Grade",
          "gradePoint":"Grade Point"
        },
        buttonName: "View Details",
        edit: false,
        search: false,
        placeholder: "Search a student..."
      }
    };
  }

  getGradeCards(){
    this.downloadToggle = true;
    this.studentDetailToggle = false;
    this.base64 = null;
    if(this.fd.getFieldValue('date') == null){
      this.ms.showError("Please select Date of Issue!");
      return;
    }
    const filter: FilterRequest = {
      conditions: {
        levelId:{comp:"=",value:this.fd.getFieldValue('levelId')},
        seasonId:{comp:"=",value:this.fd.getFieldValue('seasonId')},
        usn:{comp:"=",value:this.fd.getFieldValue('usn').toString().toUpperCase()}
      }
    }
    if(!this.fd.getFieldValue("finalMtechGradeCard")){
      this.gradeCardFd.filter(filter).subscribe({
        next: data =>{
          if(data.length == 0){
            this.ms.showError("No students for selected options!")
            this.studentDetailToggle = false;
            return;
          }
          else{
            this.downloadToggle = false;
          }
          this.studentDetailToggle = true;
          this.studentData = data;
          this.tableData.data = this.studentData[0].academicDetails;
          this.gtable.update();
          this.checkImageAvailable();
        }  
      })
    }else{
      this.finalMtechGradeCardFd.setFieldValue("levelId", this.fd.getFieldValue('levelId'));
      this.finalMtechGradeCardFd.setFieldValue("seasonId", this.fd.getFieldValue('seasonId'));
      this.finalMtechGradeCardFd.setFieldValue("usn", this.fd.getFieldValue('usn').toString().toUpperCase());
      const payload = this.finalMtechGradeCardFd.extractAllFields();
      this.sa.serve("filter_mtechGradeCardStudentDetail",{ data: payload}).subscribe(
        res =>{
          if((res["students"] as []).length == 0){
            this.ms.showError("No students for selected options!")
            return;
          }
          else{
            this.downloadToggle = false;
          }
          this.studentDetailToggle = true;
          this.studentData = res["students"];
          this.tableData.data = this.studentData[0].academicDetails;
          this.gtable.update();
          this.checkImageAvailable();
        }
      )
    }
}

checkImageAvailable(){
  this.http.get("/assets/images/portraits/" + this.studentData[0].usn + ".jpg",{responseType: "text"}).subscribe(
    data => {
    this.showImage=true;  
    },
    err => {
      this.showImage=false;
    });
  }
 

  doDownload(){
    const filter: FilterRequest = {
      conditions: {
        programId: {comp:'=',value:this.studentData[0].programId},
        instituteId: {comp:'=', value: this.ctx.getInstituteId()}
      },
    }
    this.programFd.filter(filter).subscribe({
      next: data=>{
        var programName = data[0].name as string;
        if(!this.fd.getFieldValue("finalMtechGradeCard")){
          this.ds.getGradeCard(this.studentData, programName, this.fd.getFieldValue('date') as string, this.fd.getFieldValue('isAciteRequired') as boolean, this.base64);
        }else{
          this.ds.getMtechFinalGradeCard(this.studentData, programName, moment(this.fd.getFieldValue('date') as string).format('DD-MMM-YYYY'), this.base64)
        }
        this.ms.showSuccess("Grade cards generated and downloaded for "+this.studentData[0].studentName);
      }
    })
    
  
  }   

  previewImage($event){
    console.log($event)
    this.selectedImage=$event.target.files[0];
    let reader = new FileReader();
    reader.readAsDataURL(this.selectedImage);
    reader.onload =this.handleReaderLoaded.bind(this);
  }

  handleReaderLoaded(readerEvt){
    this.base64= readerEvt.target.result;
    console.log(this.base64);
  }
}