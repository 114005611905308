
import { Form , Field, ChildForm } from '../form/form';
import { FormData } from '../form/formData';
import { SelectOption, Vo } from '../form/types';
import { Validators } from '@angular/forms'
import { ServiceAgent} from '../form/serviceAgent';

export class ChangeProgramForm extends Form {
	private static _instance = new ChangeProgramForm();
	departmentId:Field = {
		name:'departmentId'
		,controlType: 'Dropdown'
		,label: 'Select a Department'
		,isRequired: true
		,listName: 'departmentList'
		,valueType: 1
		,errorId: 'invalidId'
		,maxValue: 9999999999999
	};
	programId:Field = {
		name:'programId'
		,controlType: 'Dropdown'
		,label: 'Select a Program'
		,isRequired: true
		,listName: 'programList'
		,listKey: 'departmentId'
		,valueType: 1
		,errorId: 'invalidId'
		,maxValue: 9999999999999
	};
	programLevelId:Field = {
		name:'programLevelId'
		,controlType: 'Dropdown'
		,label: 'Select a Semester'
		,isRequired: true
		,listName: 'programLevelList'
		,listKey: 'programId'
		,valueType: 1
		,errorId: 'invalidId'
		,maxValue: 9999999999999
	};
	levelSectionId:Field = {
		name:'levelSectionId'
		,controlType: 'Dropdown'
		,label: 'Select a Section'
		,isRequired: true
		,listName: 'levelSectionList'
		,listKey: 'programLevelId'
		,valueType: 1
		,errorId: 'invalidId'
		,maxValue: 9999999999999
	};
	studentId:Field = {
		name:'studentId'
		,controlType: 'Hidden'
		,label: 'studentId'
		,isRequired: true
		,valueType: 1
		,errorId: 'invalidId'
		,maxValue: 9999999999999
	};

	public static getInstance(): ChangeProgramForm {
		return ChangeProgramForm._instance;
	}

	constructor() {
		super();
		this.fields = new Map();
		this.controls = new Map();
		this.controls.set('departmentId', [Validators.required, Validators.max(9999999999999)]);
		this.fields.set('departmentId', this.departmentId);
		this.controls.set('programId', [Validators.required, Validators.max(9999999999999)]);
		this.fields.set('programId', this.programId);
		this.controls.set('programLevelId', [Validators.required, Validators.max(9999999999999)]);
		this.fields.set('programLevelId', this.programLevelId);
		this.controls.set('levelSectionId', [Validators.required, Validators.max(9999999999999)]);
		this.fields.set('levelSectionId', this.levelSectionId);
		this.controls.set('studentId', [Validators.required, Validators.max(9999999999999)]);
		this.fields.set('studentId', this.studentId);
		this.opsAllowed = {get: true, update: true, filter: true};
		this.listFields = ['departmentId','programId','programLevelId','levelSectionId'];
		this.keyFields = ["programLevelId", "programId"];
	}

	public getName(): string {
		 return 'changeProgram';
	}
}


export class ChangeProgramFd extends FormData {
	constructor(form: ChangeProgramForm, sa: ServiceAgent) {
		super(form, sa);
	}

	setFieldValue(name: 'departmentId' | 'programId' | 'programLevelId' | 'levelSectionId' | 'studentId', value: string | number | boolean | null ): void {
		super.setFieldValue(name, value);
	}

	getFieldValue(name: 'departmentId' | 'programId' | 'programLevelId' | 'levelSectionId' | 'studentId' ): string | number | boolean | null {
		return super.getFieldValue(name);
	}
}


export interface ChangeProgramVo extends Vo {
	studentId?: number, 
	programLevelId?: number, 
	levelSectionId?: number, 
	programId?: number, 
	departmentId?: number
}
