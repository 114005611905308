
import { Form , Field, ChildForm } from '../form/form';
import { FormData } from '../form/formData';
import { SelectOption, Vo } from '../form/types';
import { Validators } from '@angular/forms'
import { ServiceAgent} from '../form/serviceAgent';

export class OfferedSubjectRegisterDetailForm extends Form {
	private static _instance = new OfferedSubjectRegisterDetailForm();

	public static getInstance(): OfferedSubjectRegisterDetailForm {
		return OfferedSubjectRegisterDetailForm._instance;
	}

	constructor() {
		super();
		this.fields = new Map();
		this.controls = new Map();
		this.opsAllowed = {get: true, filter: true};
		this.keyFields = ["offeredSubjectId", "hasAttendedExam"];
	}

	public getName(): string {
		 return 'offeredSubjectRegisterDetail';
	}
}


export class OfferedSubjectRegisterDetailFd extends FormData {
	constructor(form: OfferedSubjectRegisterDetailForm, sa: ServiceAgent) {
		super(form, sa);
	}
	/**  this form has no editable fields. data nust be accessed as Vo and not through fd **/
}


export interface OfferedSubjectRegisterDetailVo extends Vo {
	cieMarks?: number, 
	hasAttendedExam?: boolean, 
	subjectRegisterId?: number, 
	isEligibleToWithdraw?: boolean, 
	hasWithdrawn?: boolean, 
	isAuthorizedAbsence?: boolean, 
	subjectId?: number, 
	studentId?: number, 
	usn?: string, 
	examMarks?: number, 
	hasAttendanceShortage?: boolean, 
	seasonId?: number, 
	subjectSectionId?: number, 
	department?: string, 
	hasCieShortage?: boolean, 
	subjectName?: string, 
	isCondoned?: boolean, 
	overallAttendance?: string, 
	isEligibleForExam?: boolean, 
	totalAttendance?: number, 
	parentId?: number, 
	offeredSubjectId?: number, 
	totalClasses?: number, 
	isCondonable?: boolean, 
	grade?: string, 
	name?: string, 
	instituteId?: number
}
