
import { Form , Field, ChildForm } from '../form/form';
import { FormData } from '../form/formData';
import { SelectOption, Vo } from '../form/types';
import { Validators } from '@angular/forms'
import { ServiceAgent} from '../form/serviceAgent';

export class StudentIdForUsnForm extends Form {
	private static _instance = new StudentIdForUsnForm();
	studentId:Field = {
		name:'studentId'
		,controlType: 'Output'
		,label: 'studentId'
		,valueType: 1
		,errorId: 'invalidFlexibleId'
		,minValue: -1
		,maxValue: 9999999999999
	};
	instituteId:Field = {
		name:'instituteId'
		,controlType: 'Hidden'
		,label: 'instituteId'
		,valueType: 1
		,errorId: 'invalidTenentKey'
		,maxValue: 9999999999999
	};
	usn:Field = {
		name:'usn'
		,controlType: 'Input'
		,label: 'Enter USN'
		,isRequired: true
		,valueType: 0
		,errorId: 'invalidText'
		,maxLength: 1000
	};
	name:Field = {
		name:'name'
		,controlType: 'Hidden'
		,label: 'name'
		,valueType: 0
		,errorId: 'invalidName'
		,maxLength: 150
	};
	phoneNumber:Field = {
		name:'phoneNumber'
		,controlType: 'Output'
		,label: 'phoneNumber'
		,valueType: 0
		,errorId: 'invalidPhone'
		,minLength: 10
		,maxLength: 12
	};
	departmentName:Field = {
		name:'departmentName'
		,controlType: 'Output'
		,label: 'departmentName'
		,valueType: 0
		,errorId: 'invalidText'
		,maxLength: 1000
	};
	semester:Field = {
		name:'semester'
		,controlType: 'Output'
		,label: 'semester'
		,isRequired: true
		,valueType: 0
		,errorId: 'invalidText'
		,maxLength: 1000
	};
	section:Field = {
		name:'section'
		,controlType: 'Output'
		,label: 'section'
		,isRequired: true
		,valueType: 0
		,errorId: 'invalidText'
		,maxLength: 1000
	};
	programName:Field = {
		name:'programName'
		,controlType: 'Output'
		,label: 'programName'
		,isRequired: true
		,valueType: 0
		,errorId: 'invalidText'
		,maxLength: 1000
	};

	public static getInstance(): StudentIdForUsnForm {
		return StudentIdForUsnForm._instance;
	}

	constructor() {
		super();
		this.fields = new Map();
		this.controls = new Map();
		this.controls.set('studentId', [Validators.min(-1), Validators.max(9999999999999)]);
		this.fields.set('studentId', this.studentId);
		this.controls.set('instituteId', [Validators.max(9999999999999)]);
		this.fields.set('instituteId', this.instituteId);
		this.controls.set('usn', [Validators.required, Validators.maxLength(1000)]);
		this.fields.set('usn', this.usn);
		this.controls.set('name', [Validators.maxLength(150)]);
		this.fields.set('name', this.name);
		this.controls.set('phoneNumber', [Validators.minLength(10), Validators.maxLength(12), Validators.pattern('[1-9][0-9]*')]);
		this.fields.set('phoneNumber', this.phoneNumber);
		this.controls.set('departmentName', [Validators.maxLength(1000)]);
		this.fields.set('departmentName', this.departmentName);
		this.controls.set('semester', [Validators.required, Validators.maxLength(1000)]);
		this.fields.set('semester', this.semester);
		this.controls.set('section', [Validators.required, Validators.maxLength(1000)]);
		this.fields.set('section', this.section);
		this.controls.set('programName', [Validators.required, Validators.maxLength(1000)]);
		this.fields.set('programName', this.programName);
		this.opsAllowed = {get: true, filter: true};
		this.keyFields = ["usn"];
	}

	public getName(): string {
		 return 'studentIdForUsn';
	}
}


export class StudentIdForUsnFd extends FormData {
	constructor(form: StudentIdForUsnForm, sa: ServiceAgent) {
		super(form, sa);
	}

	setFieldValue(name: 'studentId' | 'instituteId' | 'usn' | 'name' | 'phoneNumber' | 'departmentName' | 'semester' | 'section' | 'programName', value: string | number | boolean | null ): void {
		super.setFieldValue(name, value);
	}

	getFieldValue(name: 'studentId' | 'instituteId' | 'usn' | 'name' | 'phoneNumber' | 'departmentName' | 'semester' | 'section' | 'programName' ): string | number | boolean | null {
		return super.getFieldValue(name);
	}
}


export interface StudentIdForUsnVo extends Vo {
	studentId?: number, 
	departmentName?: string, 
	usn?: string, 
	phoneNumber?: string, 
	programName?: string, 
	name?: string, 
	instituteId?: number, 
	semester?: string, 
	section?: string
}
