
import { Form , Field, ChildForm } from '../form/form';
import { FormData } from '../form/formData';
import { SelectOption, Vo } from '../form/types';
import { Validators } from '@angular/forms'
import { ServiceAgent} from '../form/serviceAgent';

export class AssignableEvaluatorForm extends Form {
	private static _instance = new AssignableEvaluatorForm();

	public static getInstance(): AssignableEvaluatorForm {
		return AssignableEvaluatorForm._instance;
	}

	constructor() {
		super();
		this.fields = new Map();
		this.controls = new Map();
	}

	public getName(): string {
		 return 'assignableEvaluator';
	}
}


export class AssignableEvaluatorFd extends FormData {
	constructor(form: AssignableEvaluatorForm, sa: ServiceAgent) {
		super(form, sa);
	}
	/**  this form has no editable fields. data nust be accessed as Vo and not through fd **/
}


export interface AssignableEvaluatorVo extends Vo {
	evaluatorType?: string, 
	phoneNumber?: string, 
	isAssigned?: boolean, 
	isExternal?: boolean, 
	name?: string, 
	instituteId?: number, 
	department?: string, 
	evaluatorId?: number, 
	email?: string
}
