
import { Form , Field, ChildForm } from '../form/form';
import { FormData } from '../form/formData';
import { SelectOption, Vo } from '../form/types';
import { Validators } from '@angular/forms'
import { ServiceAgent} from '../form/serviceAgent';

export class OfferedSubjectSelectionForm extends Form {
	private static _instance = new OfferedSubjectSelectionForm();
	departmentId:Field = {
		name:'departmentId'
		,controlType: 'Dropdown'
		,label: 'Select a Department'
		,isRequired: true
		,listName: 'departmentList'
		,valueType: 1
		,errorId: 'invalidId'
		,maxValue: 9999999999999
	};
	offeredSubjectId:Field = {
		name:'offeredSubjectId'
		,controlType: 'Dropdown'
		,label: 'Select a Subject'
		,isRequired: true
		,listName: 'offeredSubjectList'
		,listKey: 'departmentId'
		,valueType: 1
		,errorId: 'invalidId'
		,maxValue: 9999999999999
	};

	public static getInstance(): OfferedSubjectSelectionForm {
		return OfferedSubjectSelectionForm._instance;
	}

	constructor() {
		super();
		this.fields = new Map();
		this.controls = new Map();
		this.controls.set('departmentId', [Validators.required, Validators.max(9999999999999)]);
		this.fields.set('departmentId', this.departmentId);
		this.controls.set('offeredSubjectId', [Validators.required, Validators.max(9999999999999)]);
		this.fields.set('offeredSubjectId', this.offeredSubjectId);
		this.listFields = ['departmentId','offeredSubjectId'];
	}

	public getName(): string {
		 return 'offeredSubjectSelection';
	}
}


export class OfferedSubjectSelectionFd extends FormData {
	constructor(form: OfferedSubjectSelectionForm, sa: ServiceAgent) {
		super(form, sa);
	}

	setFieldValue(name: 'departmentId' | 'offeredSubjectId', value: string | number | boolean | null ): void {
		super.setFieldValue(name, value);
	}

	getFieldValue(name: 'departmentId' | 'offeredSubjectId' ): string | number | boolean | null {
		return super.getFieldValue(name);
	}
}


export interface OfferedSubjectSelectionVo extends Vo {
	offeredSubjectId?: number, 
	departmentId?: number
}
