
import { Form , Field, ChildForm } from '../form/form';
import { FormData } from '../form/formData';
import { SelectOption, Vo } from '../form/types';
import { Validators } from '@angular/forms'
import { ServiceAgent} from '../form/serviceAgent';

export class ApprovalForm extends Form {
	private static _instance = new ApprovalForm();
	approvalId:Field = {
		name:'approvalId'
		,controlType: 'Hidden'
		,label: 'approvalId'
		,valueType: 1
		,errorId: 'invalidId'
		,maxValue: 9999999999999
	};
	internalRefId:Field = {
		name:'internalRefId'
		,controlType: 'Hidden'
		,label: 'internalRefId'
		,isRequired: true
		,valueType: 1
		,errorId: 'invalidId'
		,maxValue: 9999999999999
	};
	disposedByName:Field = {
		name:'disposedByName'
		,controlType: 'Input'
		,label: 'Authoriser Name'
		,valueType: 0
		,errorId: 'invalidText'
		,maxLength: 1000
	};
	disposalRemarks:Field = {
		name:'disposalRemarks'
		,controlType: 'Input'
		,label: 'Remarks'
		,valueType: 0
		,errorId: 'invalidText'
		,maxLength: 1000
	};
	approvalStatus:Field = {
		name:'approvalStatus'
		,controlType: 'Hidden'
		,label: 'approvalStatus'
		,isRequired: true
		,valueType: 0
		,errorId: 'invalidText'
		,maxLength: 1000
	};

	public static getInstance(): ApprovalForm {
		return ApprovalForm._instance;
	}

	constructor() {
		super();
		this.fields = new Map();
		this.controls = new Map();
		this.controls.set('approvalId', [Validators.max(9999999999999)]);
		this.fields.set('approvalId', this.approvalId);
		this.controls.set('internalRefId', [Validators.required, Validators.max(9999999999999)]);
		this.fields.set('internalRefId', this.internalRefId);
		this.controls.set('disposedByName', [Validators.maxLength(1000)]);
		this.fields.set('disposedByName', this.disposedByName);
		this.controls.set('disposalRemarks', [Validators.maxLength(1000)]);
		this.fields.set('disposalRemarks', this.disposalRemarks);
		this.controls.set('approvalStatus', [Validators.required, Validators.maxLength(1000)]);
		this.fields.set('approvalStatus', this.approvalStatus);
		this.opsAllowed = {filter: true, get: true, update: true};
		this.keyFields = ["approvalId"];
	}

	public getName(): string {
		 return 'approval';
	}
}


export class ApprovalFd extends FormData {
	constructor(form: ApprovalForm, sa: ServiceAgent) {
		super(form, sa);
	}

	setFieldValue(name: 'approvalId' | 'internalRefId' | 'disposedByName' | 'disposalRemarks' | 'approvalStatus', value: string | number | boolean | null ): void {
		super.setFieldValue(name, value);
	}

	getFieldValue(name: 'approvalId' | 'internalRefId' | 'disposedByName' | 'disposalRemarks' | 'approvalStatus' ): string | number | boolean | null {
		return super.getFieldValue(name);
	}
}


export interface ApprovalVo extends Vo {
	approvalStatus?: string, 
	newValue?: string, 
	requestedByStaff?: number, 
	disposedByName?: string, 
	requestType?: string, 
	refText?: string, 
	contactPerson?: string, 
	disposedByUser?: number, 
	usn?: string, 
	internalRefId?: number, 
	seasonId?: number, 
	narration?: string, 
	name?: string, 
	instituteId?: number, 
	disposedDate?: string, 
	approvalId?: number, 
	oldValue?: string, 
	requestedDate?: string, 
	contactPhone?: string, 
	disposalRemarks?: string
}
