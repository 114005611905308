
import { Form , Field, ChildForm } from '../form/form';
import { FormData } from '../form/formData';
import { SelectOption, Vo } from '../form/types';
import { Validators } from '@angular/forms'
import { ServiceAgent} from '../form/serviceAgent';

export class RevaluationOptedStudentForm extends Form {
	private static _instance = new RevaluationOptedStudentForm();

	public static getInstance(): RevaluationOptedStudentForm {
		return RevaluationOptedStudentForm._instance;
	}

	constructor() {
		super();
		this.fields = new Map();
		this.controls = new Map();
		this.opsAllowed = {filter: true};
	}

	public getName(): string {
		 return 'revaluationOptedStudent';
	}
}


export class RevaluationOptedStudentFd extends FormData {
	constructor(form: RevaluationOptedStudentForm, sa: ServiceAgent) {
		super(form, sa);
	}
	/**  this form has no editable fields. data nust be accessed as Vo and not through fd **/
}


export interface RevaluationOptedStudentVo extends Vo {
	studentId?: number, 
	offeredSubjectId?: number, 
	usn?: string, 
	level?: string, 
	name?: string, 
	instituteId?: number, 
	section?: string, 
	department?: string
}
