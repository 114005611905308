
import { Form , Field, ChildForm } from '../form/form';
import { FormData } from '../form/formData';
import { SelectOption, Vo } from '../form/types';
import { Validators } from '@angular/forms'
import { ServiceAgent} from '../form/serviceAgent';

export class RevalOrChallengeValuationListForm extends Form {
	private static _instance = new RevalOrChallengeValuationListForm();
	degreeId:Field = {
		name:'degreeId'
		,controlType: 'Dropdown'
		,label: 'Select a degree'
		,isRequired: true
		,listName: 'DegreeList'
		,valueType: 1
		,errorId: 'invalidId'
		,maxValue: 9999999999999
	};
	programId:Field = {
		name:'programId'
		,controlType: 'Dropdown'
		,label: 'Select a Program'
		,isRequired: true
		,listName: 'degreeProgramList'
		,listKey: 'degreeId'
		,valueType: 1
		,errorId: 'invalidId'
		,maxValue: 9999999999999
	};
	levelId:Field = {
		name:'levelId'
		,controlType: 'Dropdown'
		,label: 'Select a Semester'
		,isRequired: true
		,listName: 'programLevelList'
		,listKey: 'programId'
		,valueType: 1
		,errorId: 'invalidId'
		,maxValue: 9999999999999
	};
	seasonId:Field = {
		name:'seasonId'
		,controlType: 'Dropdown'
		,label: 'Select a Season'
		,isRequired: true
		,listName: 'seasonList'
		,valueType: 1
		,errorId: 'invalidId'
		,maxValue: 9999999999999
	};

	public static getInstance(): RevalOrChallengeValuationListForm {
		return RevalOrChallengeValuationListForm._instance;
	}

	constructor() {
		super();
		this.fields = new Map();
		this.controls = new Map();
		this.controls.set('degreeId', [Validators.required, Validators.max(9999999999999)]);
		this.fields.set('degreeId', this.degreeId);
		this.controls.set('programId', [Validators.required, Validators.max(9999999999999)]);
		this.fields.set('programId', this.programId);
		this.controls.set('levelId', [Validators.required, Validators.max(9999999999999)]);
		this.fields.set('levelId', this.levelId);
		this.controls.set('seasonId', [Validators.required, Validators.max(9999999999999)]);
		this.fields.set('seasonId', this.seasonId);
		this.opsAllowed = {get: true, filter: true};
		this.listFields = ['degreeId','programId','levelId','seasonId'];
	}

	public getName(): string {
		 return 'revalOrChallengeValuationList';
	}
}


export class RevalOrChallengeValuationListFd extends FormData {
	constructor(form: RevalOrChallengeValuationListForm, sa: ServiceAgent) {
		super(form, sa);
	}

	setFieldValue(name: 'degreeId' | 'programId' | 'levelId' | 'seasonId', value: string | number | boolean | null ): void {
		super.setFieldValue(name, value);
	}

	getFieldValue(name: 'degreeId' | 'programId' | 'levelId' | 'seasonId' ): string | number | boolean | null {
		return super.getFieldValue(name);
	}
}


export interface RevalOrChallengeValuationListVo extends Vo {
	degreeId?: number, 
	isMakeUp?: boolean, 
	optedForReval?: boolean, 
	levelName?: string, 
	slno?: number, 
	studentId?: number, 
	usn?: string, 
	seasonId?: number, 
	levelId?: number, 
	studentName?: string, 
	instituteId?: number, 
	subjectCode?: string, 
	optedForChallenge?: boolean, 
	programId?: number, 
	subjectName?: string
}
