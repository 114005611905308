
import { Form , Field, ChildForm } from '../form/form';
import { FormData } from '../form/formData';
import { SelectOption, Vo } from '../form/types';
import { Validators } from '@angular/forms'
import { ServiceAgent} from '../form/serviceAgent';
import { CodedPaperNameForm, CodedPaperNameVo } from './codedPaperNameForm';

export class MalpracticeProneListForm extends Form {
	private static _instance = new MalpracticeProneListForm();
	offeredSubjectId:Field = {
		name:'offeredSubjectId'
		,controlType: 'Hidden'
		,label: 'offeredSubjectId'
		,isRequired: true
		,valueType: 1
		,errorId: 'invalidId'
		,maxValue: 9999999999999
	};

	students: ChildForm = {
		name:'students',
		form:CodedPaperNameForm.getInstance(),
		isEditable:false,
		isTabular:true,
		label:'',
		minRows:0,
		maxRows:0,
		errorId:null
	};

	public static getInstance(): MalpracticeProneListForm {
		return MalpracticeProneListForm._instance;
	}

	constructor() {
		super();
		this.fields = new Map();
		this.controls = new Map();
		this.controls.set('offeredSubjectId', [Validators.required, Validators.max(9999999999999)]);
		this.fields.set('offeredSubjectId', this.offeredSubjectId);

		this.childForms = new Map();
		this.childForms.set('students', this.students);
		this.opsAllowed = {get: true, update: true};
		this.keyFields = ["offeredSubjectId"];
	}

	public getName(): string {
		 return 'malpracticeProneList';
	}
}


export class MalpracticeProneListFd extends FormData {
	constructor(form: MalpracticeProneListForm, sa: ServiceAgent) {
		super(form, sa);
	}

	setFieldValue(name: 'offeredSubjectId', value: string | number | boolean | null ): void {
		super.setFieldValue(name, value);
	}

	getFieldValue(name: 'offeredSubjectId' ): string | number | boolean | null {
		return super.getFieldValue(name);
	}
}


export interface MalpracticeProneListVo extends Vo {
	name?: string, 
	offeredSubjectId?: number, 
	students?: CodedPaperNameVo
}
