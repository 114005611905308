import { Component, OnInit, ViewChild, Input, Output, EventEmitter, ComponentFactoryResolver, AfterViewInit } from "@angular/core";
import { DynamicPanel, GroupPanel } from '../../interfaces';
import { ServiceAgent } from 'src/app/framework-modules/formdata/form/serviceAgent';
import { CtTableComponent, TableMetaData } from '../../../../framework-modules/elements/ct-table/component'
import { SessionComponent } from '../../component';
import { RegisteredStudentNameForm, RegisteredStudentNameFd } from 'src/app/framework-modules/formdata/gen/registeredStudentNameForm'
import { ClientContext } from 'src/app/framework-modules/formdata/form/clientContext';
import { FilterRequest } from 'src/app/framework-modules/formdata/form/types';
import { MainSectionSelectionForm, MainSectionSelectionFd } from 'src/app/framework-modules/formdata/gen/mainSectionSelectionForm';
import { ExcelDownloadService, ExcelTemplate } from "src/app/services/excelDownloadService";
import { CtDialogComponent } from "src/app/framework-modules/elements/ct-dialog/component";
import { MatDialog } from "@angular/material";

@Component({
  selector: "student-register-list",
  templateUrl: "./component.html",
  styleUrls: ["./component.scss"]
})


export class RegisterStudentListComponent implements DynamicPanel, OnInit, AfterViewInit {
  @ViewChild("gridTable", { static: false }) gtable: CtTableComponent;
  @Input() inputData: any;
  @Input() parentPanel: GroupPanel;
  @Input() session: SessionComponent;
  public tableData: TableMetaData;
  public form: MainSectionSelectionForm;
  public fd: MainSectionSelectionFd;
  public namesFd: RegisteredStudentNameFd;


  constructor(private sa: ServiceAgent, private ctx: ClientContext, private dialog: MatDialog, private ds: ExcelDownloadService) {
    this.form = MainSectionSelectionForm.getInstance();
    this.fd = this.form.newFormData(this.sa);
    this.namesFd = RegisteredStudentNameForm.getInstance().newFormData(this.sa);
  }

  metaDataInit() {
    this.tableData = {
      data: [],
      metaData: {
        column_names: [
          "name",
          "usn",
          "department",
          "level",
          "section"
        ],
        display_names: {
          "name": "Student Name",
          "usn": "Student USN",
          "department": "Student Department",
          "level": "Level",
          "section": "Section"
        },
        edit: false,
        search: true,
        placeholder: "Search By Student Name, USN, Department"
      }
    };
  }

  ngOnInit() {
    this.metaDataInit();
  }

  ngAfterViewInit() {
    this.gtable.update();
  }

  filter() {
    /**
     * filter for subjectSectionId
     */
    const filter: FilterRequest = {
      conditions: {
        subjectSectionId: { value: this.fd.getFieldValue('subjectSectionId'), comp: "=" }
      },
      sort: { name: "asc" }
    };
    this.namesFd.filter(filter).subscribe(
      data => {
        this.tableData.data = data;
        this.gtable.update();
      },
      msgs => {
        console.error(msgs);
      }
    );
  }

  registerStudents() {
    this.parentPanel.navigate("RegisterStudentEdit", this.fd.getFieldValue('subjectSectionId'));
  }

  popUpMessage(){
    let dialogRef = this.dialog.open(CtDialogComponent);
    dialogRef.componentInstance.title = 'NOTICE';
    dialogRef.componentInstance.content = "This template can be used to register any number of students irrespective of the subject selected. Kindly review the count of the students to be registered before clicking the register students button";
    dialogRef.componentInstance.primary = 'Download Template';
    const subscribeDialog = dialogRef.componentInstance.save.subscribe((data) => {
      // Yet to implement bulk upload functionality
      this.dialog.closeAll();
      this.downloadTemplate()
    });
  }

  downloadTemplate(){
    let template: ExcelTemplate = {
      fixedHeader: [
        { loc: "A1", value: `${this.ctx.getinstituteName()}, ${this.ctx.getinstituteCity()} - ${this.ctx.getinstitutePinCode().split(' ')[this.ctx.getinstitutePinCode().split(' ').length-1]}`.toUpperCase(), style: { font: { size: 18, name: 'Cambria', bold: true }, alignment: { horizontal: 'center', vertical: 'middle' }, border: {}, fill: null, numFmt: '', protection: {locked:true} } },
        { loc: "A2", value: `(Autonomous Institute, Affiliated to ${this.ctx.getuniversityName()})`, style: { font: { size: 12, name: 'Cambria', bold: true }, alignment: { horizontal: 'center', vertical: 'middle' }, border: {}, fill: null, numFmt: '', protection: {locked:true} } },
        { loc: "A3", value: "Register Students", style: { font: { size: 16, name: 'Cambria', bold: true }, alignment: { horizontal: 'center', vertical: 'middle' }, border: {}, fill: null, numFmt: '', protection: {locked:true} } },
      ],
      dynamicHeader: [],
      merges: ['A1:D1', 'A2:D2', 'A3:D3'],
      tableArray: "students",
      tableStart: 5,
      tableStyle: { rowHeight: 15, },
      tableHeader: ["USN",],
      tableValues: [],
      colWidths: [30, 60,20,20],
      fixedFooter: [],
      dynamicFootfer: [],
      footerMerges: [],
      footerRowHeights: [],
      tableHeaderStyle: { font: { size: 14, name: 'Cambria', bold: true }, alignment: { horizontal: 'center', vertical: 'middle' }, border: {bottom: {style: 'thin'},top: {style: 'thin'}, left: {style: 'thin'},right:{style: 'thin'} }, fill: {type: 'pattern',pattern: 'solid',fgColor: {  argb: 'FF00FF00'}}, numFmt: '', protection: {locked:true} }
    }
    this.ds.excelFromTemplate(template,{"students":[]},"Registration Template","Registration")
  }

}
