
import { Form , Field, ChildForm } from '../form/form';
import { FormData } from '../form/formData';
import { SelectOption, Vo } from '../form/types';
import { Validators } from '@angular/forms'
import { ServiceAgent} from '../form/serviceAgent';

export class RegisterableStudentForm extends Form {
	private static _instance = new RegisterableStudentForm();
	studentId:Field = {
		name:'studentId'
		,controlType: 'Hidden'
		,label: 'studentId'
		,isRequired: true
		,valueType: 1
		,errorId: 'invalidId'
		,maxValue: 9999999999999
	};
	isRegistered:Field = {
		name:'isRegistered'
		,controlType: 'Output'
		,label: 'isRegistered'
		,valueType: 3
		,defaultValue: false
		,errorId: 'invalidBool'
	};
	name:Field = {
		name:'name'
		,controlType: 'Output'
		,label: 'name'
		,valueType: 0
		,errorId: 'invalidName'
		,maxLength: 150
	};
	usn:Field = {
		name:'usn'
		,controlType: 'Output'
		,label: 'usn'
		,valueType: 0
		,errorId: 'invalidText'
		,maxLength: 1000
	};
	department:Field = {
		name:'department'
		,controlType: 'Output'
		,label: 'department'
		,valueType: 0
		,errorId: 'invalidName'
		,maxLength: 150
	};

	public static getInstance(): RegisterableStudentForm {
		return RegisterableStudentForm._instance;
	}

	constructor() {
		super();
		this.fields = new Map();
		this.controls = new Map();
		this.controls.set('studentId', [Validators.required, Validators.max(9999999999999)]);
		this.fields.set('studentId', this.studentId);
		this.controls.set('isRegistered', []);
		this.fields.set('isRegistered', this.isRegistered);
		this.controls.set('name', [Validators.maxLength(150)]);
		this.fields.set('name', this.name);
		this.controls.set('usn', [Validators.maxLength(1000)]);
		this.fields.set('usn', this.usn);
		this.controls.set('department', [Validators.maxLength(150)]);
		this.fields.set('department', this.department);
		this.opsAllowed = {filter: true};
	}

	public getName(): string {
		 return 'registerableStudent';
	}
}


export class RegisterableStudentFd extends FormData {
	constructor(form: RegisterableStudentForm, sa: ServiceAgent) {
		super(form, sa);
	}

	setFieldValue(name: 'studentId' | 'isRegistered' | 'name' | 'usn' | 'department', value: string | number | boolean | null ): void {
		super.setFieldValue(name, value);
	}

	getFieldValue(name: 'studentId' | 'isRegistered' | 'name' | 'usn' | 'department' ): string | number | boolean | null {
		return super.getFieldValue(name);
	}
}


export interface RegisterableStudentVo extends Vo {
	studentId?: number, 
	name?: string, 
	usn?: string, 
	isRegistered?: boolean, 
	department?: string
}
