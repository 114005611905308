
import { Form , Field, ChildForm } from '../form/form';
import { FormData } from '../form/formData';
import { SelectOption, Vo } from '../form/types';
import { Validators } from '@angular/forms'
import { ServiceAgent} from '../form/serviceAgent';

export class PaperListForm extends Form {
	private static _instance = new PaperListForm();

	public static getInstance(): PaperListForm {
		return PaperListForm._instance;
	}

	constructor() {
		super();
		this.fields = new Map();
		this.controls = new Map();
		this.opsAllowed = {filter: true, get: true, update: true};
		this.keyFields = ["offeredSubjectId"];
	}

	public getName(): string {
		 return 'paperList';
	}
}


export class PaperListFd extends FormData {
	constructor(form: PaperListForm, sa: ServiceAgent) {
		super(form, sa);
	}
	/**  this form has no editable fields. data nust be accessed as Vo and not through fd **/
}


export interface PaperListVo extends Vo {
	offeredSubjectId?: number, 
	instituteId?: number, 
	paperCode?: string, 
	packetCode?: string
}
