
import { Form , Field, ChildForm } from '../form/form';
import { FormData } from '../form/formData';
import { SelectOption, Vo } from '../form/types';
import { Validators } from '@angular/forms'
import { ServiceAgent} from '../form/serviceAgent';

export class MarksUpdateForm extends Form {
	private static _instance = new MarksUpdateForm();
	subjectRegisterId:Field = {
		name:'subjectRegisterId'
		,controlType: 'Hidden'
		,label: 'subjectRegisterId'
		,isRequired: true
		,valueType: 1
		,errorId: 'invalidId'
		,maxValue: 9999999999999
	};
	assessmentSeqNo:Field = {
		name:'assessmentSeqNo'
		,controlType: 'Hidden'
		,label: 'assessmentSeqNo'
		,isRequired: true
		,valueType: 1
		,errorId: 'invalidId'
		,maxValue: 9999999999999
	};
	studentAssessmentId:Field = {
		name:'studentAssessmentId'
		,controlType: 'Hidden'
		,label: 'studentAssessmentId'
		,isRequired: true
		,valueType: 1
		,errorId: 'invalidId'
		,maxValue: 9999999999999
	};
	subjectId:Field = {
		name:'subjectId'
		,controlType: 'Hidden'
		,label: 'subjectId'
		,isRequired: true
		,valueType: 1
		,errorId: 'invalidId'
		,maxValue: 9999999999999
	};
	studentId:Field = {
		name:'studentId'
		,controlType: 'Hidden'
		,label: 'studentId'
		,isRequired: true
		,valueType: 1
		,errorId: 'invalidId'
		,maxValue: 9999999999999
	};
	marks:Field = {
		name:'marks'
		,controlType: 'Input'
		,label: 'Revised Marks (AB if absent)'
		,isRequired: true
		,valueType: 0
		,errorId: 'invalidMarksOrAb'
		,maxLength: 5
	};
	requesterName:Field = {
		name:'requesterName'
		,controlType: 'Input'
		,label: 'Requester Name'
		,isRequired: true
		,valueType: 0
		,errorId: 'invalidText'
		,maxLength: 1000
	};
	requesterPhone:Field = {
		name:'requesterPhone'
		,controlType: 'Input'
		,label: 'Requester Phone'
		,isRequired: true
		,valueType: 0
		,errorId: 'invalidText'
		,maxLength: 1000
	};
	remarks:Field = {
		name:'remarks'
		,controlType: 'Input'
		,label: 'Remarks'
		,isRequired: true
		,valueType: 0
		,errorId: 'invalidText'
		,maxLength: 1000
	};

	public static getInstance(): MarksUpdateForm {
		return MarksUpdateForm._instance;
	}

	constructor() {
		super();
		this.fields = new Map();
		this.controls = new Map();
		this.controls.set('subjectRegisterId', [Validators.required, Validators.max(9999999999999)]);
		this.fields.set('subjectRegisterId', this.subjectRegisterId);
		this.controls.set('assessmentSeqNo', [Validators.required, Validators.max(9999999999999)]);
		this.fields.set('assessmentSeqNo', this.assessmentSeqNo);
		this.controls.set('studentAssessmentId', [Validators.required, Validators.max(9999999999999)]);
		this.fields.set('studentAssessmentId', this.studentAssessmentId);
		this.controls.set('subjectId', [Validators.required, Validators.max(9999999999999)]);
		this.fields.set('subjectId', this.subjectId);
		this.controls.set('studentId', [Validators.required, Validators.max(9999999999999)]);
		this.fields.set('studentId', this.studentId);
		this.controls.set('marks', [Validators.required, Validators.maxLength(5), Validators.pattern('(ab|AB|Ab|\\d{0,2}\\.?\\d{0,2})')]);
		this.fields.set('marks', this.marks);
		this.controls.set('requesterName', [Validators.required, Validators.maxLength(1000)]);
		this.fields.set('requesterName', this.requesterName);
		this.controls.set('requesterPhone', [Validators.required, Validators.maxLength(1000)]);
		this.fields.set('requesterPhone', this.requesterPhone);
		this.controls.set('remarks', [Validators.required, Validators.maxLength(1000)]);
		this.fields.set('remarks', this.remarks);
		this.opsAllowed = {get: true, update: true};
		this.keyFields = ["subjectRegisterId", "assessmentSeqNo"];
	}

	public getName(): string {
		 return 'marksUpdate';
	}
}


export class MarksUpdateFd extends FormData {
	constructor(form: MarksUpdateForm, sa: ServiceAgent) {
		super(form, sa);
	}

	setFieldValue(name: 'subjectRegisterId' | 'assessmentSeqNo' | 'studentAssessmentId' | 'subjectId' | 'studentId' | 'marks' | 'requesterName' | 'requesterPhone' | 'remarks', value: string | number | boolean | null ): void {
		super.setFieldValue(name, value);
	}

	getFieldValue(name: 'subjectRegisterId' | 'assessmentSeqNo' | 'studentAssessmentId' | 'subjectId' | 'studentId' | 'marks' | 'requesterName' | 'requesterPhone' | 'remarks' ): string | number | boolean | null {
		return super.getFieldValue(name);
	}
}


export interface MarksUpdateVo extends Vo {
	subjectRegisterId?: number, 
	requesterPhone?: string, 
	currentMarks?: string, 
	marks?: string, 
	subjectId?: number, 
	studentId?: number, 
	studentAssessmentId?: number, 
	usn?: string, 
	assessmentSeqNo?: number, 
	requesterName?: string, 
	studentName?: string, 
	instituteId?: number, 
	remarks?: string, 
	subjectName?: string, 
	testName?: string
}
