import { Component, OnInit, ViewChild, Input } from "@angular/core";
import { DynamicPanel, GroupPanel } from '../../interfaces';
import { ServiceAgent } from 'src/app/framework-modules/formdata/form/serviceAgent';
import { SessionComponent } from '../../component';
import { MessageService } from 'src/app/services/messageService';
import { CtSpinner } from 'src/app/framework-modules/elements/ct-spinner/component';
import { InitiateValuationForm, InitiateValuationFd, InitiateValuationVo } from 'src/app/framework-modules/formdata/gen/initiateValuationForm';
import { MatDialog } from '@angular/material';
import { CtDialogComponent } from 'src/app/framework-modules/elements/ct-dialog/component';
import { ValuationActivitiesForm, ValuationActivitiesFd } from 'src/app/framework-modules/formdata/gen/valuationActivitiesForm';
import { InstituteConfigurationForm, InstituteConfigurationFd, InstituteConfigurationVo } from 'src/app/framework-modules/formdata/gen/instituteConfigurationForm';
import { ClientContext } from 'src/app/framework-modules/formdata/form/clientContext';



@Component({
  selector: "initiate-valuation",
  templateUrl: "./component.html",
  styleUrls: ["./component.scss"],
})


export class InitiateValuationListComponent implements DynamicPanel, OnInit {
  @ViewChild("spinner", { static: false }) spinner: CtSpinner;
  @Input() inputData: any;
  @Input() parentPanel: GroupPanel;
  @Input() session: SessionComponent;

  public form: InitiateValuationForm;
  public form1:ValuationActivitiesForm;
  public fd: InitiateValuationFd;
  public vo: InitiateValuationVo = {};
  public pd: ValuationActivitiesFd;
  public form2: InstituteConfigurationForm;
  public fd2: InstituteConfigurationFd;
  public instituteVo: InstituteConfigurationVo = {};
  isFrozen: boolean= false;
  initiated: boolean = true;
  currentValuation: string;
  status: "To be Initiated" | "Valuation In Progress" | "Round Frozen";
  allCompleted: boolean = false;
  examType: "Normal" | "Make-Up" = "Normal";
  isGradesCalculated: boolean = false;
  resultsAnnounced: boolean = true;

  constructor(private sa: ServiceAgent, private ms: MessageService, private dialog: MatDialog,private cc: ClientContext) {
    this.form = InitiateValuationForm.getInstance();
    this.fd = this.form.newFormData(this.sa);
    this.form1 = ValuationActivitiesForm.getInstance();
    this.pd = this.form1.newFormData(this.sa);
    this.form2 = InstituteConfigurationForm.getInstance();
    this.fd2 = this.form2.newFormData(this.sa);
    this.fd2.setFieldValue('instituteId', this.cc.getInstituteId());
  }


  ngOnInit() {
    this.setData();
    this.fd2.fetchData().subscribe({
      next: data =>{
        this.instituteVo = data;
        this.resultsAnnounced = this.instituteVo.resultsAnnounced as boolean;
      }
    })
  }

  /**
   * This function will
   * 1. Set the current round
   * 2. Tell if the round is in progress or hasn't been initiated
   * 3. If round == 3 and isFrozen == true then All valuation rounds have benn completed; 
   */
  setData(){
    this.pd.fetchData().subscribe(data=>{
      var round = data.currentValuationRound as number;
      this.isFrozen = data.isValuationFrozen as boolean;
      this.isGradesCalculated = data.isGradesCalculated as boolean;
      if(data.isMakeup as boolean){
        this.examType = "Make-Up"
      }
      if(this.isFrozen){
        if(this.isGradesCalculated){
          this.status = "To be Initiated";
          round++;
        }
        else{
          this.status = "Round Frozen";
        }
      }
      else{
        this.status = "Valuation In Progress";
      }
      if(round == 4){
        this.allCompleted = true;
      }
      this.getRound(round);
    });
  }

  /**
   * 
   * @param roundNumber returns the valuation round name for an input round number.
   */
  private getRound(roundNumber){
    this.fd.lists["valuationRound"].forEach( rounds =>{
      if(rounds.value == roundNumber){
        this.currentValuation = rounds.text;
        return
      }
    })
  }

  /**
   * Inititates a valuation round
   * Enables the freeze button
   */
  doInititate(){
    if(!this.fd.getFieldsInError().includes("packetPrefix")){
      this.sa.serve("initiateValuationRound",{data:{packetPrefix:this.fd.getFieldValue('packetPrefix')}}).subscribe(data=>{
        this.setData();
      });
    }
    else{
      this.fd.validateForm();
    }
   
  }

  /**
   * Freezes a valuation round
   * Will set the current round to its next value
   */
  doFreeze(){
    if(!this.isFrozen){
      let dialogRef = this.dialog.open(CtDialogComponent);
      dialogRef.componentInstance.title = 'Freeze Valuation Round';
      dialogRef.componentInstance.content = 'Are you sure you want to freeze the current valuation round and calculate Grades?';
      dialogRef.componentInstance.primary = 'Freeze';
      const subscribeDialog = dialogRef.componentInstance.save.subscribe((d) => {
        this.dialog.closeAll();
        this.spinner.show("Calculating Grades......")
        this.sa.serve("freezeValuationRound",{data:{isFrozen: this.isFrozen}}).subscribe(data=>{
          this.spinner.hide();
          this.ms.showSuccess("Valuation round has been frozen!");
          this.setData();
        },
        err=>{
          this.ms.showError("Errow While calculating Grades!!");
          this.spinner.hide()
        })
      });
    }
    else{
      let dialogRef = this.dialog.open(CtDialogComponent);
      dialogRef.componentInstance.title = 'Unfreeze Valuation Round';
      dialogRef.componentInstance.content = 'Are you sure you want to unfreeze the current valuation round?';
      dialogRef.componentInstance.primary = 'Unfreeze';
      const subscribeDialog = dialogRef.componentInstance.save.subscribe((d) => {
        this.sa.serve("freezeValuationRound",{data:{isFrozen: this.isFrozen}}).subscribe(data=>{
          this.dialog.closeAll();
          this.ms.showSuccess("Valuation round has been unfrozen!");
          this.setData();
        })
      });
    }
    
  }

  /**
   * Caluates Gpa for the whole institute
   */
  calculateGpa(){
    let dialogRef = this.dialog.open(CtDialogComponent);
    dialogRef.componentInstance.title = 'Calculate GPA and Terminate Current Valuation Round';
    dialogRef.componentInstance.content = 'Are you sure you want to calculate GPA for current valuation round?';
    dialogRef.componentInstance.primary = 'Calculate';
    const subscribeDialog = dialogRef.componentInstance.save.subscribe((d) => {
      this.dialog.closeAll();
          this.spinner.show("Calculating GPA for each student......");
          this.sa.serve("calculateResults").subscribe({
            next: data2 =>{
              this.ms.showSuccess("GPA calculation Successful!");
              this.setData();
              this.spinner.hide();
            },
            error: err=>{
              console.error(err);
              this.spinner.hide();
            }
          });
    });
  }

  doAnnounce(){
    let dialogRef = this.dialog.open(CtDialogComponent);
    dialogRef.componentInstance.title = 'Announce results';
    dialogRef.componentInstance.content = 'Are you sure you want to announce results? Students can view their results after this operation is completed';
    dialogRef.componentInstance.primary = 'Announce';
    const subscribeDialog = dialogRef.componentInstance.save.subscribe((d) => {
      this.sa.serve('announceResults').subscribe({
        next: data=>{
          this.ms.showSuccess("Results announced! Students can check results from their login");
          this.resultsAnnounced = true;
          this.dialog.closeAll();
        }
      });
    });
  }

  doClose(){
    let dialogRef = this.dialog.open(CtDialogComponent);
    dialogRef.componentInstance.title = 'Close Valuation Round';
    dialogRef.componentInstance.content = 'Are you sure you want to close the current valuation round? GPA will be calculated and the round cannot be accessed again!';
    dialogRef.componentInstance.primary = 'Close Round';
    const subscribeDialog = dialogRef.componentInstance.save.subscribe((d) => {
      this.dialog.closeAll();
      this.spinner.show("Closing Round....");
      this.sa.serve('calculateResultsAndClose').subscribe({
        next: data=>{
          this.ms.showSuccess("Round Closed!");
          this.spinner.hide();
        }
      });
    });
  }
}