import { MenuService } from 'src/app/services/menuService';
import { Component, OnInit, Input, ViewChild, ViewContainerRef, Type, ComponentFactoryResolver } from "@angular/core";
import { DynamicPanel, GroupPanel } from '../interfaces'
import { SessionComponent, SecondaryMenu } from '../component';
import { MakeUpAssignPacketsListComponent } from './assignPackets-list/component';
import { MakeUpAssignPacketsEditComponent } from './assignPackets-edit/component';
import { MakeUpCodingComponent } from './coding/component';
import { MakeUpExamAttendanceListComponent } from './examAttendance-list/component';
import { MakeUpExamAttendanceEditComponent } from './examAttendance-edit/component';
import { MakeUpValuationViewComponent } from './valuationView/component';
import { MakeUpValuationEditComponent } from './valuationEdit/component';
import { ClientContext } from 'src/app/framework-modules/formdata/form/clientContext';
import { MessageService } from 'src/app/services/messageService';
import { MakeUpPacketListByEvaluatorComponent } from './packetList-evaluator/component';
import { MakeUpResultsLevelListComponent } from './resultsLevel-list/component';
import { MakeUpResultsLevelViewComponent } from './resultsLevel-view/component';
import { MakeUpSubjectGradeCalculationComponent } from './subject-grade-calculation/component';
import { MakeUpPacketListComponent } from './packetList-subject/component';

/**
 Const to map the screen-component to screen-name to rended the facotry
 */
const MENU: SecondaryMenu = {
  MakeUpExamAttendanceList: {comp: MakeUpExamAttendanceListComponent,title: "Exam Attendance",name: "Exam Attendance",sideNav: true,pageId: "D165",displayonSidenav: true,mainNav: true,order: 1},
  MakeUpExamAttendanceEdit: {comp: MakeUpExamAttendanceEditComponent,title: "Mark Exam Attendance",name: "Exam Attendance Edit",sideNav: false,pageId: "D166",displayonSidenav: false,mainNav: false},
  MakeUpCoding: {comp: MakeUpCodingComponent,title: "Coding",name: "Coding",sideNav: true,mainNav: true,pageId: "D167",displayonSidenav: true,subTitle: "Coding",order: 2},
  MakeUpAssignPacketsList: {comp: MakeUpAssignPacketsListComponent,title: "Assign Packets to Evaluators",name: "Packet Assignment",sideNav: true,pageId: "D168",displayonSidenav: true,mainNav: true,order: 3},
  MakeUpAssignPacketsEdit: {comp: MakeUpAssignPacketsEditComponent,title: "Assign Packets to Evaluators",name: "Packet Assignment",sideNav: false,pageId: "D169",displayonSidenav: false,mainNav: false},
  MakeUpPacketListByEvaluator: {comp: MakeUpPacketListByEvaluatorComponent,title: "Packets List By Evaluator",name: "Packet List - Evaluator",sideNav: true,pageId: "D170",displayonSidenav: true,mainNav: true,order: 5},
  MakeUpValuationView: {comp: MakeUpValuationViewComponent,title: "View Marks",name: "View  Marks",sideNav: false,pageId: "D171",displayonSidenav: false,mainNav: false},
  MakeUpValuationEdit: {comp: MakeUpValuationEditComponent,title: "Enter Marks",name: "Marks Entry",sideNav: false,pageId: "D172",displayonSidenav: false,mainNav: false},
  MakeUpGradeCalculation:{comp: MakeUpSubjectGradeCalculationComponent,mainNav: true,sideNav: true,displayonSidenav: true,pageId: "D173",title: "Calculate Grades for a Subject",order: 6,name: "Subject Grading"},
  MakeUpResultsLevel: {comp: MakeUpResultsLevelListComponent,title: "Results of students Levelwise",name: "Results - Level Wise",sideNav: true,pageId: "D174",displayonSidenav: true,mainNav: true,order: 7},
  MakeUpResultsLevelView: {comp: MakeUpResultsLevelViewComponent,title: "View Marks of students",name: "Marks - Consolidated",sideNav: false,pageId: "D175",displayonSidenav: false,mainNav: false,},
  MakeUpPacketList: {comp: MakeUpPacketListComponent,title: "Packets List By Subjects",name: "Packet List - Subject",sideNav: true,pageId: "D176",displayonSidenav: true,mainNav: true,order: 4}
};

@Component({
  selector: "Make-up",
  templateUrl: "./component.html",
  styleUrls: ["./component.scss"]
})
export class MakeUpComponent implements OnInit, GroupPanel {
  @Input() inputData: any;
  @Input() parentPanel: SessionComponent;
  menu: { [key: string]: Type<DynamicPanel> };
  public sidenav: boolean = false;
  displayNames: {}
  public items: {};
  cardHeader: any;
  public defaultSelected: string;
  pScreens: Array<string>;
  screens: string[];
  defaultComponent: string;
  /**
    container for the child page loaded from the seconday navbar
   */

  @ViewChild("secondaryContainer", { static: true, read: ViewContainerRef }) container: ViewContainerRef;
  constructor(private componentFactoryResolver: ComponentFactoryResolver,private cc: ClientContext, private ms: MessageService, private mns: MenuService) {
    this.screens = this.mns.getScreens();
      Object.keys(MENU).forEach( key => {
      if(this.screens.includes(MENU[key].pageId) && this.defaultSelected == undefined){
        this.defaultSelected = MENU[key].name;
        this.cardHeader = MENU[key].title;
        this.defaultComponent = key;
      }
    })
  }

  ngOnInit() {
    this.navigate(this.defaultComponent, {});
    /**
     * providing menu items to the items array from the MENU
     */
    this.items = MENU;
  }

  /**
   *
   * @param compName is the name of the component to navigate to
   * @param data is for any related data needed
   */

  public navigate(compName: string, data: any): void {
    const m = MENU[compName];
    if (!m) {
      this.ms.showInfo('Navigation Error: ' + compName + ' is not a valid page name.');
      return;
    }

    if(!this.cc.isPageValid(m.pageId)){
      this.ms.showInfo('Navigation Error: ' + compName + ' is not authorized for this user.');
      return;
    }

    this.parentPanel.toggleMainNav(m.mainNav)
    this.sidenav = m.sideNav;

    if (m.name != undefined) {
      this.defaultSelected = m.name;
    }

    this.cardHeader = m.title;
    this.container.clear();
    const factory = this.componentFactoryResolver.resolveComponentFactory(
      m.comp
    );

    const c = this.container.createComponent(factory);
    c.instance.inputData = data;
    c.instance.parentPanel = this;
    c.changeDetectorRef.detectChanges();
  }

  fullScreenMode() {
    this.sidenav = false;
    this.parentPanel.hideMainNav();
  }

  halfScreenMode() {
    this.sidenav = true;
    this.parentPanel.showMainNav();
  }
}
