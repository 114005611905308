import { Component, OnInit, ViewChild, Input, AfterViewInit } from "@angular/core";
import { DynamicPanel, GroupPanel } from '../../interfaces';
import { ServiceAgent } from 'src/app/framework-modules/formdata/form/serviceAgent';
import { TableMetaData } from '../../../../framework-modules/elements/ct-table/component'
import { SessionComponent } from '../../component';
import { AssignEvaluatorForm, AssignEvaluatorVo } from 'src/app/framework-modules/formdata/gen/assignEvaluatorForm';
import { CtCheckboxTableComponent } from 'src/app/framework-modules/elements/ct-checkbox-table/component';
import { CtDialogComponent } from 'src/app/framework-modules/elements/ct-dialog/component';
import { MatDialog } from '@angular/material';
import { MessageService } from 'src/app/services/messageService';
import { PanelData } from 'src/app/framework-modules/formdata/form/formData';
import { AssignableEvaluatorVo } from 'src/app/framework-modules/formdata/gen/assignableEvaluatorForm';
import { CtSpinner } from 'src/app/framework-modules/elements/ct-spinner/component';

@Component({
  selector: "assign-evaluators-edit",
  templateUrl: "./component.html",
  styleUrls: ["./component.scss"]
})
export class AssignEvaluatorsEditComponent implements DynamicPanel, OnInit, AfterViewInit {
  @ViewChild("gridTable", { static: false }) gtable: CtCheckboxTableComponent;
  @ViewChild("spinner", { static: false }) spinner: CtSpinner;
  @Input() inputData: any;
  @Input() parentPanel: GroupPanel;
  @Input() session: SessionComponent;

  public tableData: TableMetaData;
  private pd: PanelData;
  public vo: AssignEvaluatorVo;
  private evaluators: AssignableEvaluatorVo[];

  constructor(private sa: ServiceAgent, private dialog: MatDialog, private ms: MessageService) {
    this.pd = AssignEvaluatorForm.getInstance().newPanelData(this.sa);
    this.vo = {};
    this.evaluators = [];
  }

  /**
   * Table Metadata initialization
   */
  metaDataInit() {
    this.tableData = {
      data: [] = [],
      metaData: {
        column_names: [
          "name",
          "department",
          "phoneNumber",
          "email",
          "evaluatorType"

        ],
        display_names: {
          name: "Name",
          department: "Department",
          phoneNumber: "Phone No.",
          email: "Email",
          evaluatorType: "Internal/External"
        },
        selectAttr:"isAssigned",
        search: true,
        placeholder: "Search by Department,Phone or Email....",
        managerFunction: "SAVE",
        itemName: "Evaluators"
      }
    };
  }
  ngOnInit() {
    this.parentPanel.fullScreenMode();
    this.metaDataInit();
  }

  ngAfterViewInit() {
    if (this.inputData && this.inputData.offeredSubjectId && this.inputData.departmentId) {
      this.getData(this.inputData.departmentId, this.inputData.offeredSubjectId);
    } else {
      this.ms.showError('Page with no/invalid data');
    }
  }

  private getData(deptId, offerId) {
    this.spinner.show('Getting Evaluators for this subject..');
    /**
     * offeredSubjectId is the primary key in the form
     */
    this.pd.fetchFor({ offeredSubjectId: offerId, departmentId: deptId }).subscribe({
      next: data => {
        this.vo = data;
        this.tableData.data = data.evaluators as [];
        this.gtable.update(this.spinner);
      },

      error: msg => {
        this.spinner.hide();
        this.ms.showError('server returned with errors');
      }
    });
  }


  private saveData() {
    this.spinner.show('Assigning evaluators..');
    this.pd.save().subscribe({
      next: data => {
        this.spinner.hide();
        this.ms.showSuccess('Evaluators Assigned');
        this.parentPanel.navigate('AssignEvaluatorsList');
      },

      error: msg => {
        this.spinner.hide();
        this.ms.showError('server returned with errors');
      }
    });
  }


  /**
   * Function is called when the user clicks save button.
   * Saves the assigned evaluators to a asubject.
   */
  save() {
    let dialogRef = this.dialog.open(CtDialogComponent);
    dialogRef.componentInstance.title = 'Save Changes';
    dialogRef.componentInstance.content = 'Are you sure you want to save changes?';
    dialogRef.componentInstance.primary = 'Save';
    const subscribeDialog = dialogRef.componentInstance.save.subscribe((data) => {
      this.saveData();
      this.dialog.closeAll();
    });
  }

  /**
   * Function is called when the user clicks cancel button.
   * Goes back to assign Evaluators list screen
   */
  cancel() {
    let dialogRef = this.dialog.open(CtDialogComponent);
    dialogRef.componentInstance.title = 'Discard Changes';
    dialogRef.componentInstance.content = 'Are you sure you want to discard changes?';
    dialogRef.componentInstance.primary = 'Discard';
    const subscribeDialog = dialogRef.componentInstance.save.subscribe((data) => {
      this.dialog.closeAll();
      this.parentPanel.navigate("AssignEvaluatorsList");
    });
  }
}