
import { Form , Field, ChildForm } from '../form/form';
import { FormData } from '../form/formData';
import { SelectOption, Vo } from '../form/types';
import { Validators } from '@angular/forms'
import { ServiceAgent} from '../form/serviceAgent';
import { RevaluationEligibleStudentForm, RevaluationEligibleStudentVo } from './revaluationEligibleStudentForm';

export class RevaluationStudentForm extends Form {
	private static _instance = new RevaluationStudentForm();
	name:Field = {
		name:'name'
		,controlType: 'Output'
		,label: 'Subject'
		,valueType: 0
		,errorId: 'invalidName'
		,maxLength: 150
	};
	offeredSubjectId:Field = {
		name:'offeredSubjectId'
		,controlType: 'Hidden'
		,label: 'offeredSubjectId'
		,isRequired: true
		,valueType: 1
		,defaultValue: 0
		,errorId: 'invalidId'
		,maxValue: 9999999999999
	};

	students: ChildForm = {
		name:'students',
		form:RevaluationEligibleStudentForm.getInstance(),
		isEditable:false,
		isTabular:true,
		label:'',
		minRows:1,
		maxRows:10000,
		errorId:null
	};

	public static getInstance(): RevaluationStudentForm {
		return RevaluationStudentForm._instance;
	}

	constructor() {
		super();
		this.fields = new Map();
		this.controls = new Map();
		this.controls.set('name', [Validators.maxLength(150)]);
		this.fields.set('name', this.name);
		this.controls.set('offeredSubjectId', [Validators.required, Validators.max(9999999999999)]);
		this.fields.set('offeredSubjectId', this.offeredSubjectId);

		this.childForms = new Map();
		this.childForms.set('students', this.students);
		this.opsAllowed = {get: true, update: true, filter: true};
		this.keyFields = ["offeredSubjectId"];
	}

	public getName(): string {
		 return 'revaluationStudent';
	}
}


export class RevaluationStudentFd extends FormData {
	constructor(form: RevaluationStudentForm, sa: ServiceAgent) {
		super(form, sa);
	}

	setFieldValue(name: 'name' | 'offeredSubjectId', value: string | number | boolean | null ): void {
		super.setFieldValue(name, value);
	}

	getFieldValue(name: 'name' | 'offeredSubjectId' ): string | number | boolean | null {
		return super.getFieldValue(name);
	}
}


export interface RevaluationStudentVo extends Vo {
	name?: string, 
	offeredSubjectId?: number, 
	code?: string, 
	students?: RevaluationEligibleStudentVo
}
