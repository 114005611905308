import { MenuService } from '../services/menuService';
import { NgModule, ModuleWithProviders } from '@angular/core';
import { RouterService } from '../services/routerService';
import { ExcelDownloadService } from '../services/excelDownloadService';
import { RouterModule } from '@angular/router';
import { ElementsModule } from '../framework-modules/elements/elements'
import { CardElementsModule } from '../framework-modules/cards/cardElements';
import { HttpModule } from '@angular/http';
import { HttpClientModule, HttpClient } from '@angular/common/http';
import { InsituteListComponent } from './session/institute/institute-list/component';
import { MessageService } from '../services/messageService';
import { InstituteAddComponent } from './session/institute/institute-add/component';
import { InstituteEditComponent } from './session/institute/institute-edit/component';
import { InstituteViewComponent } from './session/institute/institute-view/component';
import { StudentListComponent } from './session/student/student-list/component';
import { DashBoardComponent } from './session/dashboard/component';
import { IcdComponent } from './session/icd/component';
import { DashBoardViewComponent } from './session/dashboard/dashboard-view/component';
import { DashBoardListComponent } from './session/dashboard/dashboard-list/component';
import { IcdListComponent } from './session/icd/icd-list/component';
import { IcdViewComponent } from './session/icd/icd-view/component';
import { DepartmentListComponent } from './session/institute/department-list/component';
import { DepartmentAddComponent } from './session/institute/department-add/component';
import { DepartmentEditComponent } from './session/institute/department-edit/component';
import { StudentAddComponent } from './session/student/student-add/component';
import { StudentEditComponent } from './session/student/student-edit/component';
import { GuardianEditComponent } from './session/student/guardian-edit/component';
import { StudentViewComponent } from './session/student/student-view/component';
import { SessionComponent } from './session/component';
import { InstituteComponent } from './session/institute/component';
import { StudentComponent } from './session/student/component';
import { SubjectComponent } from './session/subject/component';
import { SubjectListComponent } from './session/subject/subject-list/component';
import { SubjectAddComponent } from './session/subject/subject-add/component';
import { SubjectEditComponent } from './session/subject/subject-edit/component';
import { OfferSubjectListComponent } from './session/subject/offerSubject-list/component';
import { OfferSubjectEditComponent } from './session/subject/offerSubject-edit/component';
import { ProgramListComponent } from './session/institute/program-list/component';
import { ProgramAddComponent } from './session/institute/program-add/component';
import { LevelListComponent } from './session/institute/level-list/component';
import { RegisterStudentListComponent } from './session/subject/registerStudent-list/component';
import { RegisterStudentEditComponent } from './session/subject/registerStudent-edit/component';
import { ConsolidatedAttendanceListComponent } from './session/sessional/consolidatedAttendance-list/component';
import { ConsolidatedAttendanceEditComponent } from './session/sessional/consolidatedAttendance-edit/component';
import { EligibilityListComponent } from './session/sessional/eligibility-list/component';
import { SubjectEligiblityComponent } from './session/sessional/subject-eligibility/component';
import { MarksEntryListComponent } from './session/sessional/marksEntry-list/component';
import { AssignStaffComponent } from './session/subject/assignStaff-list/component';
import { CondoneAttendanceListComponent } from './session/sessional/condone-attendance-list/component';
import { CondoneAttendanceComponent } from './session/sessional/condone-attendance/component';
import { AssignStaffEditComponent } from './session/subject/assignStaff-edit/component';
import { CtDialogComponent } from '../framework-modules/elements/ct-dialog/component';
import { HallTicketComponent } from './session/sessional/hallTicket/component';
import { MarksEntryEditComponent } from './session/sessional/marksEntry-edit/component';
import { NonSessionComponent } from './non-session/component';
import { LandingComponent } from './non-session/landing-page/component';
import { LoginComponent } from './non-session/landing-page/login/component';
import { ForgotPassowrdOtpComponent } from './non-session/landing-page/forgot-password-otp/component';
import { ForgotPassowrdChangeComponent } from './non-session/landing-page/forgot-password-change/component';
import { UserRegistrationComponent } from './non-session/landing-page/reset-password/component';
import { StaffComponent } from './session/staff/component';
import { StaffListComponent } from './session/staff/staff-list/component';
import { StaffAddComponent } from './session/staff/staff-add/component';
import { StaffEditComponent } from './session/staff/staff-edit/component';
import { AuthorisationComponent } from './session/authorisation/component';
import { AuthorisationListComponent } from './session/authorisation/authorisation-list/component';
import { PendingAuthorisationListComponent } from './session/authorisation/pending-authorisation-list/component';
import { AuthorisationEditComponent } from './session/authorisation/authorisation-edit/component';
import { ConsolidatedAttendanceUpdateComponent } from './session/sessional/consolidatedAttendance-update/component';
import { CieCalculationListComponent } from './session/sessional/cie-calculation-list/component';
import { MarksEntryBulkUpdateComponent } from './session/sessional/marksEntry-update-bulk/component';
import { MarksEntryUpdateComponent } from './session/sessional/marksEntry-update/component';
import { SessionalComponent } from './session/sessional/component';
import { AssignPacketsListComponent } from './session/terminal/assignPackets-list/component';
import { AssignPacketsEditComponent } from './session/terminal/assignPackets-edit/component';
import { TerminalComponent } from './session/terminal/component';
import { CodingComponent } from './session/terminal/coding/component';
import { ExamAttendanceEditComponent } from './session/terminal/examAttendance-edit/component';
import { ExamAttendanceListComponent } from './session/terminal/examAttendance-list/component';
import { ExternalStaffListComponent} from './session/terminal/externalStaff-list/component'
import { ExternalStaffAddComponent } from './session/terminal/externalStaff-add/component';
import { ExternalStaffEditComponent } from './session/terminal/externalStaff-edit/component';
import { FreezeComponent } from './session/freeze/component';
import { FreezeListComponent } from './session/freeze/freeze-list/component';
import { DownloadComponent } from './session/download/component';
import { DownloadListComponent } from './session/download/download-list/component';
import { TestTypeSelectionComponent } from './session/subject/testTypeSelection-list/component';
import { ResetPasswordComponent } from './session/reset-password/component';
import { ResetPasswordPageComponent } from './session/reset-password/resetPassword-page/component';
import { HallTicketViewComponent } from './session/sessional/hallticket-view/component';
import { PacketListComponent } from './session/terminal/packetList-subject/component';
import { PacketListByEvaluatorComponent } from './session/terminal/packetList-evaluator/component';
import { ValuationEditComponent } from './session/terminal/valuationEdit/component';
import { ValuationViewComponent } from './session/terminal/valuationView/component';
import { AssignEvaluatorsListComponent } from './session/terminal/assignEvaluators-list/component';
import { AssignEvaluatorsEditComponent } from './session/terminal/assignEvaluators-edit/component';
import { DesignationListComponent } from './session/staff/designation-list/component';
import { DesignationAddComponent } from './session/staff/designation-add/component';
import { DesignationEditComponent } from './session/staff/designation-edit/component';
import { PaperValuationEditComponent } from './session/terminal/paperValuation-edit/component';
import { MarksEntryViewComponent } from './session/sessional/marksEntry-view/component';
import { ConsolidatedAttendanceViewComponent } from './session/sessional/consolidatedAttendance-view/component';
import { SubjectCieCalculationListComponent } from './session/sessional/subject-cie-calculation-list/component';
import { InitiateValuationListComponent } from './session/terminal/initiate-valuation/component';
import { ResultsSubjectComponent } from './session/terminal/resultsSubject-list/component';
import { RevaluationListComponent } from './session/terminal/revaluation-list/component';
import { RevaluationEditComponent } from './session/terminal/revaluation-edit/component';
import { ChallengeValuationListComponent } from './session/terminal/challengeValuation-list/component';
import { ChallengeValuationEditComponent } from './session/terminal/challengeValuation-edit/component';
import { WithdrawSubjectListComponent } from './session/sessional/withdraw-subject-list/component';
import { WithdrawSubjectEditComponent } from './session/sessional/withdraw-subject-edit/component';
import { ExamExemptionListComponent } from './session/terminal/examExemption-list/component';
import { ExamExemptionEditComponent } from './session/terminal/examExemption-edit/component';
import { ResultsLevelListComponent } from './session/terminal/resultsLevel-list/component';
import { ResultsLevelViewComponent } from './session/terminal/resultsLevel-view/component';
import { ResultsValuationComponent } from './session/terminal/resultsValuation/component';
import { StudentSubjectsComponent } from './session/student/studentSubjects/component';
import { CieSubmissionComponent } from './session/sessional/cie-submissions/component';
import { MaintenanceComponent } from './non-session/maintenance/component';
import { SiteDownComponent } from './non-session/maintenance/site-down/component';
import { StudentResultComponent } from './session/student/student-results/component';
import { IndividualGradeCardListComponent } from './session/terminal/individualGradeCard-list/component';
import { StaffDashboardListComponent } from './session/dashboard/staff-dashboard-list/component';
import { EnrollmentsListComponent } from './session/institute/enrollments-list/component';
import { EnrollmentsAddComponent } from './session/institute/enrollments-add/component';
import { StudentDashboardListComponent } from './session/dashboard/student-dashboard/component';
import { ArchiveComponent } from './session/archive/component';
import { StudentArchiveComponent } from './session/archive/student-archive/component';
import { FinalMarksListComponent } from './session/terminal/final-exam-marks-list/component';
import { FinalMarksUpdateComponent } from './session/terminal/final-exam-marks-update/component';
import { StudentNamesEditComponent } from './session/student/student-names-edit/component';
import { ChangeProgramComponent } from './session/institute/program-change/component';
import { ChangeProgramEditComponent } from './session/institute/program-change-edit/component';
import { AssessmentSchemeListComponent } from './session/institute/assessment-scheme-list/component';
import { AssessmentSchemeAddComponent } from './session/institute/assessment-scheme-add/component';
import { SubjectGradeCalculationComponent } from './session/terminal/subject-grade-calculation/component';
import { FirstYearOfferList } from './session/subject/firstYearOffer-list/component';
import { AutoRegisterListComponent } from './session/subject/auto-register/component';
import { FirstYearOfferEditComponent } from './session/subject/firstYearOffer-edit/component';
import { ManualRegisterListComponent } from './session/subject/manual-register/component';
import { ManualRegisterEditComponent } from './session/subject/manualRegister-edit/component';
import { AutoRegisterEditComponent } from './session/subject/autoRegister-edit/component';
import { DegreeGradeCalculationComponent } from './session/terminal/degree-grade-calculation/component';
import { PacketListSearchComponent } from './session/terminal/packetList-search/component';
import { PdfDownloadService } from '../services/pdfDownloadService';
import { PacketListStatusComponent } from './session/terminal/packetList-status/component';
import { ValuationActivitiesComponent } from './session/dashboard/valuation-activities/component';
import { ExcelParserService } from '../services/excelParserService';
import { ForceValuationComponent } from './session/terminal/force-valuation/component';
import { ppNpGradeListComponent } from './session/sessional/ppNpGrade-list/component';
import { ppNpGradeEditComponent } from './session/sessional/ppNpGrade-update/component';
import { MalpracticeListComponent } from './session/terminal/malpractice-list/component';
import { MalpracticeEditComponent } from './session/terminal/malpractice-edit/component';
import { CurrentBacklogsComponent } from './session/terminal/current-backlogs/component';
import { TranscriptListComponent } from './session/terminal/transcripts/component';
import { SeasonSelectComponent } from './session/season-select/component';
import { JsPDFFontService } from '../services/jsPdfFontService';
import { AssignReviewerComponent } from './session/terminal/assignReviewer/component';
import { ProjectMarksEntryListComponent } from './session/terminal/projectMarksEntry-list/component';
import { ProjectMarksEntryEditComponent } from './session/terminal/projectMarksEntry-edit/component';
import { ProjectMarksEntryUpdateComponent } from './session/terminal/projectMarksEntry-update/component';
import { ProjectMarksEntryBulkUpdateComponent } from './session/terminal/projectMarksEntry-update-bulk/component';
import { pdcListComponent } from './session/terminal/pdc-list/component';
import { pdcUpdateComponent } from './session/terminal/pdc-update/component';
import { pdcEditComponent } from './session/terminal/pdc-edit/component';
import { MakeUpComponent } from './session/make-up/component';
import { MakeUpExamAttendanceListComponent } from './session/make-up/examAttendance-list/component';
import { MakeUpExamAttendanceEditComponent } from './session/make-up/examAttendance-edit/component';
import { MakeUpCodingComponent } from './session/make-up/coding/component';
import { MakeUpAssignPacketsListComponent } from './session/make-up/assignPackets-list/component';
import { MakeUpAssignPacketsEditComponent } from './session/make-up/assignPackets-edit/component';
import { MakeUpPacketListByEvaluatorComponent } from './session/make-up/packetList-evaluator/component';
import { MakeUpValuationViewComponent } from './session/make-up/valuationView/component';
import { MakeUpValuationEditComponent } from './session/make-up/valuationEdit/component';
import { MakeUpSubjectGradeCalculationComponent } from './session/make-up/subject-grade-calculation/component';
import { MakeUpResultsLevelListComponent } from './session/make-up/resultsLevel-list/component';
import { MakeUpResultsLevelViewComponent } from './session/make-up/resultsLevel-view/component';
import { MakeUpPacketListComponent } from './session/make-up/packetList-subject/component';
import { GradeGracingListComponent } from './session/terminal/gradeGracing-list/component';
import { GradeGracingViewComponent } from './session/terminal/gradeGracing-view/component';
import { GradeGracingEditComponent } from './session/terminal/gradeGracing-edit/component';
import { StudentUploadComponent } from './session/student/student-upload/component';
import { RevalChallengeStudentListComponent } from './session/terminal/reval-challenge-student-list/component';


/**
 * Do not import any angular material module in this file unless it it the only option
 * Material modules to be imported in '../framework-modules/elements/elements.ts'
 */

@NgModule({
  declarations: [
    /**
     * All components to be declared here
     */

    /**
    * Landing Componenet
    */
    NonSessionComponent,
    LandingComponent,
    SessionComponent,
    SeasonSelectComponent,
    InstituteComponent,
    DashBoardComponent,
    IcdComponent,
    StudentComponent,
    SubjectComponent,
    SessionalComponent,
    StaffComponent,
    AuthorisationComponent,
    TerminalComponent,
    FreezeComponent,
    DownloadComponent,
    ResetPasswordComponent,
    MaintenanceComponent,
    ArchiveComponent,
    /**
     * Non Session Group Panels
     */
    LoginComponent,
    ForgotPassowrdOtpComponent,
    ForgotPassowrdChangeComponent,
    UserRegistrationComponent,
    SiteDownComponent,
    /**
     * Session Group Panels
     */
    InsituteListComponent,
    InstituteAddComponent,
    InstituteEditComponent,
    InstituteViewComponent,
    StudentListComponent,
    StudentAddComponent,
    StudentEditComponent,
    GuardianEditComponent,
    StudentViewComponent,
    DashBoardListComponent,
    IcdListComponent,
    IcdViewComponent,
    DashBoardViewComponent,
    DepartmentListComponent,
    DepartmentAddComponent,
    DepartmentEditComponent,
    SubjectListComponent,
    SubjectAddComponent,
    SubjectEditComponent,
    OfferSubjectListComponent,
    OfferSubjectEditComponent,
    RegisterStudentListComponent,
    SessionComponent,
    InstituteComponent,
    DashBoardComponent,
    IcdComponent,
    StudentComponent,
    ProgramListComponent,
    ProgramAddComponent,
    LevelListComponent,
    RegisterStudentEditComponent,
    TestTypeSelectionComponent,
    ConsolidatedAttendanceListComponent,
    ConsolidatedAttendanceEditComponent,
    EligibilityListComponent,
    SubjectEligiblityComponent,
    MarksEntryListComponent,
    AssignStaffComponent,
    EligibilityListComponent,
    CondoneAttendanceListComponent,
    CondoneAttendanceComponent,
    AssignStaffEditComponent,
    HallTicketComponent,
    StaffListComponent,
    StaffAddComponent,
    StaffEditComponent,
    MarksEntryEditComponent,
    AuthorisationListComponent,
    PendingAuthorisationListComponent,
    AuthorisationEditComponent,
    ConsolidatedAttendanceUpdateComponent,
    CieCalculationListComponent,
    MarksEntryBulkUpdateComponent,
    MarksEntryUpdateComponent,
    AssignPacketsListComponent,
    AssignPacketsEditComponent,
    CodingComponent,
    ExamAttendanceListComponent,
    ExamAttendanceEditComponent,
    ExternalStaffListComponent,
    ExternalStaffAddComponent,
    ExternalStaffEditComponent,
    FreezeListComponent,
    DownloadListComponent,
    ResetPasswordPageComponent,
    HallTicketViewComponent,
    PacketListComponent,
    PacketListByEvaluatorComponent,
    ValuationEditComponent,
    ValuationViewComponent,
    AssignEvaluatorsListComponent,
    AssignEvaluatorsEditComponent,
    DesignationListComponent,
    DesignationAddComponent,
    DesignationEditComponent,
    PaperValuationEditComponent,
    MarksEntryViewComponent,
    ConsolidatedAttendanceViewComponent,
    SubjectCieCalculationListComponent,
    InitiateValuationListComponent,
    ResultsLevelViewComponent,
    ResultsSubjectComponent,
    ResultsValuationComponent,
    ResultsLevelListComponent,
    RevaluationEditComponent,
    RevaluationListComponent,
    ChallengeValuationListComponent,
    ChallengeValuationEditComponent,
    WithdrawSubjectListComponent,
    WithdrawSubjectEditComponent,
    ExamExemptionListComponent,
    ExamExemptionEditComponent,
    StudentSubjectsComponent,
    CieSubmissionComponent,
    StudentResultComponent,
    IndividualGradeCardListComponent,
    StaffDashboardListComponent,
    EnrollmentsListComponent,
    EnrollmentsAddComponent,
    StudentDashboardListComponent,
    StudentArchiveComponent,
    FinalMarksListComponent,
    FinalMarksUpdateComponent,
    StudentNamesEditComponent,
    ChangeProgramComponent,
    ChangeProgramEditComponent,
    AssessmentSchemeListComponent,
    AssessmentSchemeAddComponent,
    SubjectGradeCalculationComponent,
    FirstYearOfferList,
    AutoRegisterListComponent,
    FirstYearOfferEditComponent,
    ManualRegisterListComponent,
    ManualRegisterEditComponent,
    AutoRegisterEditComponent,
    DegreeGradeCalculationComponent,
    PacketListSearchComponent,
    PacketListStatusComponent,
    ValuationActivitiesComponent,
    ForceValuationComponent,
    ppNpGradeListComponent,
    ppNpGradeEditComponent,
    MalpracticeListComponent,
    MalpracticeEditComponent,
    CurrentBacklogsComponent,
    TranscriptListComponent,
    AssignReviewerComponent,
    ProjectMarksEntryListComponent,
    ProjectMarksEntryEditComponent,
    ProjectMarksEntryUpdateComponent,
    ProjectMarksEntryBulkUpdateComponent,
    pdcListComponent,
    pdcUpdateComponent,
    pdcEditComponent,
    MakeUpComponent,
    MakeUpExamAttendanceListComponent,
    MakeUpExamAttendanceEditComponent,
    MakeUpCodingComponent,
    MakeUpAssignPacketsListComponent,
    MakeUpAssignPacketsEditComponent,
    MakeUpPacketListByEvaluatorComponent,
    MakeUpValuationViewComponent,
    MakeUpValuationEditComponent,
    MakeUpSubjectGradeCalculationComponent,
    MakeUpResultsLevelListComponent,
    MakeUpResultsLevelViewComponent,
    MakeUpPacketListComponent,
    GradeGracingListComponent,
    GradeGracingViewComponent,
    GradeGracingEditComponent,
    StudentUploadComponent,
    RevalChallengeStudentListComponent,
  ],
  imports: [
    RouterModule,
    ElementsModule,
    CardElementsModule,
    HttpClientModule,

  ],

  providers: [],
  bootstrap: [],
  exports: [
    /**
     * App Components
     */
    RouterModule,
    ElementsModule,
    CardElementsModule,
    HttpClientModule,
    /**
     * All components to be declared here
     */

    /**
    * Landing Componenet
    */
    NonSessionComponent,
    LandingComponent,
    SessionComponent,
    SeasonSelectComponent,
    InstituteComponent,
    DashBoardComponent,
    IcdComponent,
    StudentComponent,
    ProgramListComponent,
    ProgramAddComponent,
    LevelListComponent,
    SubjectComponent,
    SessionalComponent,
    StaffComponent,
    AuthorisationComponent,
    TerminalComponent,
    FreezeComponent,
    DownloadComponent,
    ResetPasswordComponent,
    MaintenanceComponent,
    ArchiveComponent,
    /**
     * Non Session Group Panels
     */
    LoginComponent,
    ForgotPassowrdOtpComponent,
    ForgotPassowrdChangeComponent,
    UserRegistrationComponent,
    SiteDownComponent,
    /**
     * Session Group Panels
     */
    InsituteListComponent,
    InstituteAddComponent,
    InstituteEditComponent,
    InstituteViewComponent,
    StudentListComponent,
    StudentAddComponent,
    StudentEditComponent,
    GuardianEditComponent,
    StudentViewComponent,
    DashBoardListComponent,
    IcdListComponent,
    IcdViewComponent,
    DashBoardViewComponent,
    DepartmentListComponent,
    DepartmentAddComponent,
    DepartmentEditComponent,
    SubjectListComponent,
    SubjectAddComponent,
    SubjectEditComponent,
    OfferSubjectListComponent,
    OfferSubjectEditComponent,
    RegisterStudentListComponent,
    ProgramListComponent,
    RegisterStudentEditComponent,
    TestTypeSelectionComponent,
    ConsolidatedAttendanceListComponent,
    ConsolidatedAttendanceEditComponent,
    EligibilityListComponent,
    SubjectEligiblityComponent,
    MarksEntryListComponent,
    AssignStaffComponent,
    EligibilityListComponent,
    CondoneAttendanceListComponent,
    CondoneAttendanceComponent,
    AssignStaffEditComponent,
    HallTicketComponent,
    StaffListComponent,
    StaffAddComponent,
    StaffEditComponent,
    MarksEntryEditComponent,
    AuthorisationListComponent,
    PendingAuthorisationListComponent,
    AuthorisationEditComponent,
    ConsolidatedAttendanceUpdateComponent,
    CieCalculationListComponent,
    MarksEntryBulkUpdateComponent,
    MarksEntryUpdateComponent,
    AssignPacketsListComponent,
    AssignPacketsEditComponent,
    CodingComponent,
    ExamAttendanceListComponent,
    ExamAttendanceEditComponent,
    ExternalStaffListComponent,
    ExternalStaffAddComponent,
    ExternalStaffEditComponent,
    FreezeListComponent,
    DownloadListComponent,
    ResetPasswordPageComponent,
    HallTicketViewComponent,
    PacketListComponent,
    PacketListByEvaluatorComponent,
    ValuationEditComponent,
    ValuationViewComponent,
    AssignEvaluatorsListComponent,
    AssignEvaluatorsEditComponent,
    DesignationListComponent,
    DesignationAddComponent,
    DesignationEditComponent,
    PaperValuationEditComponent,
    MarksEntryViewComponent,
    ConsolidatedAttendanceViewComponent,
    SubjectCieCalculationListComponent,
    InitiateValuationListComponent,
    ResultsLevelViewComponent,
    ResultsSubjectComponent,
    ResultsValuationComponent,
    ResultsLevelListComponent,
    RevaluationEditComponent,
    RevaluationListComponent,
    ChallengeValuationListComponent,
    ChallengeValuationEditComponent,
    WithdrawSubjectListComponent,
    WithdrawSubjectEditComponent,
    ExamExemptionListComponent,
    ExamExemptionEditComponent,
    StudentSubjectsComponent,
    CieSubmissionComponent,
    StudentResultComponent,
    IndividualGradeCardListComponent,
    StaffDashboardListComponent,
    EnrollmentsListComponent,
    EnrollmentsAddComponent,
    StudentDashboardListComponent,
    StudentArchiveComponent,
    FinalMarksListComponent,
    FinalMarksUpdateComponent,
    StudentNamesEditComponent,
    ChangeProgramComponent,
    ChangeProgramEditComponent,
    AssessmentSchemeListComponent,
    AssessmentSchemeAddComponent,
    SubjectGradeCalculationComponent,
    FirstYearOfferList,
    AutoRegisterListComponent,
    FirstYearOfferEditComponent,
    ManualRegisterListComponent,
    ManualRegisterEditComponent,
    AutoRegisterEditComponent,
    DegreeGradeCalculationComponent,
    PacketListSearchComponent,
    PacketListStatusComponent,
    ValuationActivitiesComponent,
    ForceValuationComponent,
    ppNpGradeListComponent,
    ppNpGradeEditComponent,
    MalpracticeListComponent,
    MalpracticeEditComponent,
    CurrentBacklogsComponent,
    TranscriptListComponent,
    AssignReviewerComponent,
    ProjectMarksEntryListComponent,
    ProjectMarksEntryEditComponent,
    ProjectMarksEntryUpdateComponent,
    ProjectMarksEntryBulkUpdateComponent,
    pdcListComponent,
    pdcUpdateComponent,
    pdcEditComponent,
    MakeUpComponent,
    MakeUpExamAttendanceListComponent,
    MakeUpExamAttendanceEditComponent,
    MakeUpCodingComponent,
    MakeUpAssignPacketsListComponent,
    MakeUpAssignPacketsEditComponent,
    MakeUpPacketListByEvaluatorComponent,
    MakeUpValuationViewComponent,
    MakeUpValuationEditComponent,
    MakeUpSubjectGradeCalculationComponent,
    MakeUpResultsLevelListComponent,
    MakeUpResultsLevelViewComponent,
    MakeUpPacketListComponent,
    GradeGracingListComponent,
    GradeGracingViewComponent,
    GradeGracingEditComponent,
    StudentUploadComponent,
    RevalChallengeStudentListComponent,
  ],
  schemas: [],
  entryComponents: [
    /**
     * to render the components using component factory
     */
    /**
     * All components to be declared here
    */

    /**
    * Landing Componenet
    */
    NonSessionComponent,
    LandingComponent,
    SessionComponent,
    SeasonSelectComponent,
    InstituteComponent,
    DashBoardComponent,
    IcdComponent,
    StudentComponent,
    SubjectComponent,
    SessionalComponent,
    TerminalComponent,
    FreezeComponent,
    DownloadComponent,
    ResetPasswordComponent,
    MaintenanceComponent,
    ArchiveComponent,
    /**
     * Non Session Group Panels
     */
    LoginComponent,
    ForgotPassowrdOtpComponent,
    ForgotPassowrdChangeComponent,
    UserRegistrationComponent,
    SiteDownComponent,
    /**
     * Session Group Panels
     */
    InsituteListComponent,
    InstituteAddComponent,
    InstituteEditComponent,
    InstituteViewComponent,
    StudentListComponent,
    StudentAddComponent,
    StudentEditComponent,
    GuardianEditComponent,
    StudentViewComponent,
    DashBoardListComponent,
    IcdListComponent,
    IcdViewComponent,
    DashBoardViewComponent,
    DepartmentListComponent,
    DepartmentAddComponent,
    DepartmentEditComponent,
    SubjectListComponent,
    SubjectAddComponent,
    SubjectEditComponent,
    OfferSubjectListComponent,
    OfferSubjectEditComponent,
    RegisterStudentListComponent,
    SessionComponent,
    InstituteComponent,
    DashBoardComponent,
    IcdComponent,
    StudentComponent,
    StaffComponent,
    AuthorisationComponent,
    TerminalComponent,
    ProgramListComponent,
    ProgramAddComponent,
    LevelListComponent,
    RegisterStudentEditComponent,
    TestTypeSelectionComponent,
    ConsolidatedAttendanceListComponent,
    ConsolidatedAttendanceEditComponent,
    EligibilityListComponent,
    SubjectEligiblityComponent,
    MarksEntryListComponent,
    AssignStaffComponent,
    EligibilityListComponent,
    CondoneAttendanceListComponent,
    CondoneAttendanceComponent,
    AssignStaffEditComponent,
    HallTicketComponent,
    StaffListComponent,
    StaffAddComponent,
    StaffEditComponent,
    MarksEntryEditComponent,
    PendingAuthorisationListComponent,
    AuthorisationListComponent,
    AuthorisationEditComponent,
    ConsolidatedAttendanceUpdateComponent,
    CieCalculationListComponent,
    MarksEntryBulkUpdateComponent,
    MarksEntryUpdateComponent,
    AssignPacketsListComponent,
    AssignPacketsEditComponent,
    CodingComponent,
    ExamAttendanceListComponent,
    ExamAttendanceEditComponent,
    ExternalStaffListComponent,
    ExternalStaffAddComponent,
    ExternalStaffEditComponent,
    FreezeListComponent,
    DownloadListComponent,
    ResetPasswordPageComponent,
    HallTicketViewComponent,
    PacketListComponent,
    PacketListByEvaluatorComponent,
    ValuationEditComponent,
    ValuationViewComponent,    
    AssignEvaluatorsListComponent,
    AssignEvaluatorsEditComponent,
    DesignationListComponent,
    DesignationAddComponent,
    DesignationEditComponent,
    PaperValuationEditComponent,
    MarksEntryViewComponent,
    ConsolidatedAttendanceViewComponent,
    SubjectCieCalculationListComponent,
    InitiateValuationListComponent,
    ResultsLevelViewComponent,
    ResultsSubjectComponent,
    ResultsValuationComponent,
    ResultsLevelListComponent,
    RevaluationEditComponent,
    RevaluationListComponent,
    ChallengeValuationListComponent,
    ChallengeValuationEditComponent,
    WithdrawSubjectListComponent,
    WithdrawSubjectEditComponent,
    ExamExemptionListComponent,
    ExamExemptionEditComponent,
    StudentSubjectsComponent,
    CieSubmissionComponent,
    StudentResultComponent,
    IndividualGradeCardListComponent,
    StaffDashboardListComponent,
    EnrollmentsListComponent,
    EnrollmentsAddComponent,
    StudentDashboardListComponent,
    StudentArchiveComponent,
    FinalMarksListComponent,
    FinalMarksUpdateComponent,
    StudentNamesEditComponent,
    ChangeProgramComponent,
    ChangeProgramEditComponent,
    AssessmentSchemeListComponent,
    AssessmentSchemeAddComponent,
    SubjectGradeCalculationComponent,
    FirstYearOfferList,
    AutoRegisterListComponent,
    FirstYearOfferEditComponent,
    ManualRegisterListComponent,
    ManualRegisterEditComponent,
    AutoRegisterEditComponent,
    DegreeGradeCalculationComponent,
    PacketListSearchComponent,
    PacketListStatusComponent,
    ValuationActivitiesComponent,
    ForceValuationComponent,
    ppNpGradeListComponent,
    ppNpGradeEditComponent,
    MalpracticeListComponent,
    MalpracticeEditComponent,
    CurrentBacklogsComponent,
    TranscriptListComponent,
    AssignReviewerComponent,
    ProjectMarksEntryListComponent,
    ProjectMarksEntryEditComponent,
    ProjectMarksEntryUpdateComponent,
    ProjectMarksEntryBulkUpdateComponent,
    pdcListComponent,
    pdcUpdateComponent,
    pdcEditComponent,
    MakeUpComponent,
    MakeUpExamAttendanceListComponent,
    MakeUpExamAttendanceEditComponent,
    MakeUpCodingComponent,
    MakeUpAssignPacketsListComponent,
    MakeUpAssignPacketsEditComponent,
    MakeUpPacketListByEvaluatorComponent,
    MakeUpValuationViewComponent,
    MakeUpValuationEditComponent,
    MakeUpSubjectGradeCalculationComponent,
    MakeUpResultsLevelListComponent,
    MakeUpResultsLevelViewComponent,
    MakeUpPacketListComponent,
    GradeGracingListComponent,
    GradeGracingViewComponent,
    GradeGracingEditComponent,
    StudentUploadComponent,
    RevalChallengeStudentListComponent,
    /**
     * Mis entry components
    */
    CtDialogComponent
  ]

})
export class BaseModule {
  static forRoot(): ModuleWithProviders {
    return {
      ngModule: BaseModule,
      providers: [
        RouterService,
        MessageService,
        ExcelDownloadService,
        JsPDFFontService,
        MenuService,
        PdfDownloadService,
        ExcelParserService
      ]
    };
  }

}
