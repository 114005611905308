
import { Form , Field, ChildForm } from '../form/form';
import { FormData } from '../form/formData';
import { SelectOption, Vo } from '../form/types';
import { Validators } from '@angular/forms'
import { ServiceAgent} from '../form/serviceAgent';

export class GracingSubjectRegisterDetailForm extends Form {
	private static _instance = new GracingSubjectRegisterDetailForm();
	instituteId:Field = {
		name:'instituteId'
		,controlType: 'Hidden'
		,label: 'instituteId'
		,valueType: 1
		,errorId: 'invalidTenentKey'
		,maxValue: 9999999999999
	};
	seasonId:Field = {
		name:'seasonId'
		,controlType: 'Hidden'
		,label: 'seasonId'
		,valueType: 1
		,errorId: 'invalidId'
		,maxValue: 9999999999999
	};
	parentId:Field = {
		name:'parentId'
		,controlType: 'Hidden'
		,label: 'parentId'
		,valueType: 1
		,errorId: 'invalidId'
		,maxValue: 9999999999999
	};
	subjectSectionId:Field = {
		name:'subjectSectionId'
		,controlType: 'Hidden'
		,label: 'subjectSectionId'
		,valueType: 1
		,errorId: 'invalidId'
		,maxValue: 9999999999999
	};
	subjectId:Field = {
		name:'subjectId'
		,controlType: 'Hidden'
		,label: 'subjectId'
		,valueType: 1
		,errorId: 'invalidId'
		,maxValue: 9999999999999
	};
	offeredSubjectId:Field = {
		name:'offeredSubjectId'
		,controlType: 'Hidden'
		,label: 'offeredSubjectId'
		,valueType: 1
		,errorId: 'invalidId'
		,maxValue: 9999999999999
	};
	subjectRegisterId:Field = {
		name:'subjectRegisterId'
		,controlType: 'Hidden'
		,label: 'subjectRegisterId'
		,isRequired: true
		,valueType: 1
		,errorId: 'invalidId'
		,maxValue: 9999999999999
	};
	studentId:Field = {
		name:'studentId'
		,controlType: 'Hidden'
		,label: 'studentId'
		,valueType: 1
		,errorId: 'invalidId'
		,maxValue: 9999999999999
	};
	cieMarks:Field = {
		name:'cieMarks'
		,controlType: 'Input'
		,label: 'cieMarks'
		,valueType: 1
		,errorId: 'invalidInteger'
		,maxValue: 9999999999999
	};
	name:Field = {
		name:'name'
		,controlType: 'Input'
		,label: 'name'
		,valueType: 0
		,errorId: 'invalidName'
		,maxLength: 150
	};
	usn:Field = {
		name:'usn'
		,controlType: 'Input'
		,label: 'usn'
		,valueType: 0
		,errorId: 'invalidText'
		,maxLength: 1000
	};
	subjectName:Field = {
		name:'subjectName'
		,controlType: 'Input'
		,label: 'subjectName'
		,valueType: 0
		,errorId: 'invalidName'
		,maxLength: 150
	};
	grade:Field = {
		name:'grade'
		,controlType: 'Input'
		,label: 'Grace'
		,isRequired: true
		,valueType: 0
		,errorId: 'invalidText'
		,maxLength: 1000
	};
	examMarks:Field = {
		name:'examMarks'
		,controlType: 'Input'
		,label: 'examMarks'
		,valueType: 1
		,errorId: 'invalidInteger'
		,maxValue: 9999999999999
	};
	effectiveMarks:Field = {
		name:'effectiveMarks'
		,controlType: 'Input'
		,label: 'effectiveMarks'
		,valueType: 1
		,errorId: 'invalidInteger'
		,maxValue: 9999999999999
	};
	isGraced:Field = {
		name:'isGraced'
		,controlType: 'Input'
		,label: 'isGraced'
		,valueType: 3
		,defaultValue: false
		,errorId: 'invalidBool'
	};
	subjectCode:Field = {
		name:'subjectCode'
		,controlType: 'Input'
		,label: 'subjectCode'
		,valueType: 0
		,errorId: 'invalidText'
		,maxLength: 1000
	};
	academicRegulationId:Field = {
		name:'academicRegulationId'
		,controlType: 'Hidden'
		,label: 'academicRegulationId'
		,valueType: 1
		,errorId: 'invalidId'
		,maxValue: 9999999999999
	};
	gracedMarks:Field = {
		name:'gracedMarks'
		,controlType: 'Input'
		,label: 'Marks Graced'
		,valueType: 1
		,defaultValue: 0
		,errorId: 'invalidMraks'
		,minValue: -1
		,maxValue: 100
	};

	public static getInstance(): GracingSubjectRegisterDetailForm {
		return GracingSubjectRegisterDetailForm._instance;
	}

	constructor() {
		super();
		this.fields = new Map();
		this.controls = new Map();
		this.controls.set('instituteId', [Validators.max(9999999999999)]);
		this.fields.set('instituteId', this.instituteId);
		this.controls.set('seasonId', [Validators.max(9999999999999)]);
		this.fields.set('seasonId', this.seasonId);
		this.controls.set('parentId', [Validators.max(9999999999999)]);
		this.fields.set('parentId', this.parentId);
		this.controls.set('subjectSectionId', [Validators.max(9999999999999)]);
		this.fields.set('subjectSectionId', this.subjectSectionId);
		this.controls.set('subjectId', [Validators.max(9999999999999)]);
		this.fields.set('subjectId', this.subjectId);
		this.controls.set('offeredSubjectId', [Validators.max(9999999999999)]);
		this.fields.set('offeredSubjectId', this.offeredSubjectId);
		this.controls.set('subjectRegisterId', [Validators.required, Validators.max(9999999999999)]);
		this.fields.set('subjectRegisterId', this.subjectRegisterId);
		this.controls.set('studentId', [Validators.max(9999999999999)]);
		this.fields.set('studentId', this.studentId);
		this.controls.set('cieMarks', [Validators.max(9999999999999)]);
		this.fields.set('cieMarks', this.cieMarks);
		this.controls.set('name', [Validators.maxLength(150)]);
		this.fields.set('name', this.name);
		this.controls.set('usn', [Validators.maxLength(1000)]);
		this.fields.set('usn', this.usn);
		this.controls.set('subjectName', [Validators.maxLength(150)]);
		this.fields.set('subjectName', this.subjectName);
		this.controls.set('grade', [Validators.required, Validators.maxLength(1000)]);
		this.fields.set('grade', this.grade);
		this.controls.set('examMarks', [Validators.max(9999999999999)]);
		this.fields.set('examMarks', this.examMarks);
		this.controls.set('effectiveMarks', [Validators.max(9999999999999)]);
		this.fields.set('effectiveMarks', this.effectiveMarks);
		this.controls.set('isGraced', []);
		this.fields.set('isGraced', this.isGraced);
		this.controls.set('subjectCode', [Validators.maxLength(1000)]);
		this.fields.set('subjectCode', this.subjectCode);
		this.controls.set('academicRegulationId', [Validators.max(9999999999999)]);
		this.fields.set('academicRegulationId', this.academicRegulationId);
		this.controls.set('gracedMarks', [Validators.min(-1), Validators.max(100)]);
		this.fields.set('gracedMarks', this.gracedMarks);
		this.opsAllowed = {get: true, filter: true, update: true};
		this.keyFields = ["subjectRegisterId"];
	}

	public getName(): string {
		 return 'gracingSubjectRegisterDetail';
	}
}


export class GracingSubjectRegisterDetailFd extends FormData {
	constructor(form: GracingSubjectRegisterDetailForm, sa: ServiceAgent) {
		super(form, sa);
	}

	setFieldValue(name: 'instituteId' | 'seasonId' | 'parentId' | 'subjectSectionId' | 'subjectId' | 'offeredSubjectId' | 'subjectRegisterId' | 'studentId' | 'cieMarks' | 'name' | 'usn' | 'subjectName' | 'grade' | 'examMarks' | 'effectiveMarks' | 'isGraced' | 'subjectCode' | 'academicRegulationId' | 'gracedMarks', value: string | number | boolean | null ): void {
		super.setFieldValue(name, value);
	}

	getFieldValue(name: 'instituteId' | 'seasonId' | 'parentId' | 'subjectSectionId' | 'subjectId' | 'offeredSubjectId' | 'subjectRegisterId' | 'studentId' | 'cieMarks' | 'name' | 'usn' | 'subjectName' | 'grade' | 'examMarks' | 'effectiveMarks' | 'isGraced' | 'subjectCode' | 'academicRegulationId' | 'gracedMarks' ): string | number | boolean | null {
		return super.getFieldValue(name);
	}
}


export interface GracingSubjectRegisterDetailVo extends Vo {
	cieMarks?: number, 
	effectiveMarks?: number, 
	subjectRegisterId?: number, 
	gracedMarks?: number, 
	parentId?: number, 
	subjectId?: number, 
	studentId?: number, 
	offeredSubjectId?: number, 
	usn?: string, 
	isGraced?: boolean, 
	examMarks?: number, 
	seasonId?: number, 
	subjectSectionId?: number, 
	grade?: string, 
	name?: string, 
	instituteId?: number, 
	subjectCode?: string, 
	academicRegulationId?: number, 
	subjectName?: string
}
