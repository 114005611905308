
import { Form , Field, ChildForm } from '../form/form';
import { FormData } from '../form/formData';
import { SelectOption, Vo } from '../form/types';
import { Validators } from '@angular/forms'
import { ServiceAgent} from '../form/serviceAgent';

export class StaffForm extends Form {
	private static _instance = new StaffForm();
	staffId:Field = {
		name:'staffId'
		,controlType: 'Hidden'
		,label: 'staffId'
		,isRequired: true
		,valueType: 1
		,defaultValue: -1
		,errorId: 'invalidFlexibleId'
		,minValue: -1
		,maxValue: 9999999999999
	};
	instituteId:Field = {
		name:'instituteId'
		,controlType: 'Hidden'
		,label: 'instituteId'
		,valueType: 1
		,errorId: 'invalidTenentKey'
		,maxValue: 9999999999999
	};
	departmentId:Field = {
		name:'departmentId'
		,controlType: 'Dropdown'
		,label: 'Department'
		,isRequired: true
		,listName: 'departmentList'
		,valueType: 1
		,errorId: 'invalidId'
		,maxValue: 9999999999999
	};
	empId:Field = {
		name:'empId'
		,controlType: 'Input'
		,label: 'Employee Id'
		,isRequired: true
		,valueType: 0
		,errorId: 'invalidText'
		,maxLength: 1000
	};
	firstName:Field = {
		name:'firstName'
		,controlType: 'Input'
		,label: 'First Name'
		,isRequired: true
		,valueType: 0
		,errorId: 'invalidName'
		,maxLength: 150
	};
	middleName:Field = {
		name:'middleName'
		,controlType: 'Input'
		,label: 'Middle Name'
		,valueType: 0
		,errorId: 'invalidName'
		,maxLength: 150
	};
	lastName:Field = {
		name:'lastName'
		,controlType: 'Input'
		,label: 'Last Name'
		,valueType: 0
		,errorId: 'invalidName'
		,maxLength: 150
	};
	displayName:Field = {
		name:'displayName'
		,controlType: 'Input'
		,label: 'Display Name'
		,isRequired: true
		,valueType: 0
		,errorId: 'invalidName'
		,maxLength: 150
	};
	dateOfBirth:Field = {
		name:'dateOfBirth'
		,controlType: 'Input'
		,label: 'Date of birth'
		,valueType: 4
		,errorId: 'invalidDate'
		,minValue: 73000
		,maxValue: 73000
	};
	spouseName:Field = {
		name:'spouseName'
		,controlType: 'Input'
		,label: 'Spouse Name'
		,valueType: 0
		,errorId: 'invalidName'
		,maxLength: 150
	};
	fatherName:Field = {
		name:'fatherName'
		,controlType: 'Input'
		,label: 'Father Name'
		,valueType: 0
		,errorId: 'invalidName'
		,maxLength: 150
	};
	addressLine1:Field = {
		name:'addressLine1'
		,controlType: 'Input'
		,label: 'Address - Line 1'
		,valueType: 0
		,errorId: 'invalidText'
		,maxLength: 1000
	};
	addressLine2:Field = {
		name:'addressLine2'
		,controlType: 'Input'
		,label: 'Address - Line 2'
		,valueType: 0
		,errorId: 'invalidText'
		,maxLength: 1000
	};
	city:Field = {
		name:'city'
		,controlType: 'Input'
		,label: 'City'
		,valueType: 0
		,errorId: 'invalidName'
		,maxLength: 150
	};
	state:Field = {
		name:'state'
		,controlType: 'Dropdown'
		,label: 'State'
		,listName: 'state'
		,listKey: 'country'
		,keyedList: {
			91 : [
				{value:'Karnataka',text:'Karnataka'}, 
				{value:'Tamil Nadu',text:'Tamil Nadu'}, 
				{value:'Kerala',text:'Kerala'}, 
				{value:'Uttar Pradesh',text:'Uttar Pradesh'}
			], 
			130 : [
				{value:'Karnataka',text:'Karnataka'}, 
				{value:'Tamil Nadu',text:'Tamil Nadu'}, 
				{value:'Kerala',text:'Kerala'}, 
				{value:'Uttar Pradesh',text:'Uttar Pradesh'}
			]
			}
		,valueType: 0
		,errorId: 'invalidState'
		,maxLength: 50
	};
	pincode:Field = {
		name:'pincode'
		,controlType: 'Input'
		,label: 'Pin Code'
		,valueType: 0
		,errorId: 'invalidPin'
		,minLength: 6
		,maxLength: 6
	};
	country:Field = {
		name:'country'
		,controlType: 'Hidden'
		,label: 'Country'
		,valueType: 1
		,defaultValue: 130
		,errorId: 'invalidCountry'
		,maxValue: 999
	};
	phoneNumber:Field = {
		name:'phoneNumber'
		,controlType: 'Input'
		,label: 'Phone'
		,isRequired: true
		,valueType: 0
		,errorId: 'invalidPhone'
		,minLength: 10
		,maxLength: 12
	};
	email:Field = {
		name:'email'
		,controlType: 'Input'
		,label: 'Email'
		,isRequired: true
		,valueType: 0
		,errorId: 'invalidEmail'
		,maxLength: 1000
	};
	gender:Field = {
		name:'gender'
		,controlType: 'Dropdown'
		,label: 'Gender'
		,listName: 'gender'
		,valueList: [
			{value:'Male',text:'Male'},
			{value:'Female',text:'Female'},
			{value:'Others',text:'Others'},
			{value:'Not Applicable',text:'Not Applicable'}
			]
		,valueType: 0
		,errorId: 'invalidGender'
		,maxLength: 10
	};
	dateOfJoining:Field = {
		name:'dateOfJoining'
		,controlType: 'Input'
		,label: 'Date of joining'
		,valueType: 4
		,errorId: 'invalidDate'
		,minValue: 73000
		,maxValue: 73000
	};
	dateOfLeaving:Field = {
		name:'dateOfLeaving'
		,controlType: 'Input'
		,label: 'Date of leaving'
		,valueType: 4
		,errorId: 'invalidDate'
		,minValue: 73000
		,maxValue: 73000
	};
	reasonForLeaving:Field = {
		name:'reasonForLeaving'
		,controlType: 'Input'
		,label: 'Reason for leaving'
		,valueType: 0
		,errorId: 'invalidText'
		,maxLength: 1000
	};
	designationId:Field = {
		name:'designationId'
		,controlType: 'Dropdown'
		,label: 'Designation'
		,listName: 'designationList'
		,valueType: 1
		,defaultValue: 0
		,errorId: 'invalidId'
		,maxValue: 9999999999999
	};
	createdAt:Field = {
		name:'createdAt'
		,controlType: 'Hidden'
		,label: 'createdAt'
		,valueType: 5
		,errorId: 'invalidTimestamp'
	};
	updatedAt:Field = {
		name:'updatedAt'
		,controlType: 'Hidden'
		,label: 'updatedAt'
		,valueType: 5
		,errorId: 'invalidTimestamp'
	};

	public static getInstance(): StaffForm {
		return StaffForm._instance;
	}

	constructor() {
		super();
		this.fields = new Map();
		this.controls = new Map();
		this.controls.set('staffId', [Validators.required, Validators.min(-1), Validators.max(9999999999999)]);
		this.fields.set('staffId', this.staffId);
		this.controls.set('instituteId', [Validators.max(9999999999999)]);
		this.fields.set('instituteId', this.instituteId);
		this.controls.set('departmentId', [Validators.required, Validators.max(9999999999999)]);
		this.fields.set('departmentId', this.departmentId);
		this.controls.set('empId', [Validators.required, Validators.maxLength(1000)]);
		this.fields.set('empId', this.empId);
		this.controls.set('firstName', [Validators.required, Validators.maxLength(150)]);
		this.fields.set('firstName', this.firstName);
		this.controls.set('middleName', [Validators.maxLength(150)]);
		this.fields.set('middleName', this.middleName);
		this.controls.set('lastName', [Validators.maxLength(150)]);
		this.fields.set('lastName', this.lastName);
		this.controls.set('displayName', [Validators.required, Validators.maxLength(150)]);
		this.fields.set('displayName', this.displayName);
		this.controls.set('dateOfBirth', []);
		this.fields.set('dateOfBirth', this.dateOfBirth);
		this.controls.set('spouseName', [Validators.maxLength(150)]);
		this.fields.set('spouseName', this.spouseName);
		this.controls.set('fatherName', [Validators.maxLength(150)]);
		this.fields.set('fatherName', this.fatherName);
		this.controls.set('addressLine1', [Validators.maxLength(1000)]);
		this.fields.set('addressLine1', this.addressLine1);
		this.controls.set('addressLine2', [Validators.maxLength(1000)]);
		this.fields.set('addressLine2', this.addressLine2);
		this.controls.set('city', [Validators.maxLength(150)]);
		this.fields.set('city', this.city);
		this.controls.set('state', [Validators.maxLength(50)]);
		this.fields.set('state', this.state);
		this.controls.set('pincode', [Validators.minLength(6), Validators.maxLength(6), Validators.pattern('[1-9][0-9]{5}')]);
		this.fields.set('pincode', this.pincode);
		this.controls.set('country', [Validators.max(999)]);
		this.fields.set('country', this.country);
		this.controls.set('phoneNumber', [Validators.required, Validators.minLength(10), Validators.maxLength(12), Validators.pattern('[1-9][0-9]*')]);
		this.fields.set('phoneNumber', this.phoneNumber);
		this.controls.set('email', [Validators.required, Validators.email, Validators.maxLength(1000)]);
		this.fields.set('email', this.email);
		this.controls.set('gender', [Validators.maxLength(10)]);
		this.fields.set('gender', this.gender);
		this.controls.set('dateOfJoining', []);
		this.fields.set('dateOfJoining', this.dateOfJoining);
		this.controls.set('dateOfLeaving', []);
		this.fields.set('dateOfLeaving', this.dateOfLeaving);
		this.controls.set('reasonForLeaving', [Validators.maxLength(1000)]);
		this.fields.set('reasonForLeaving', this.reasonForLeaving);
		this.controls.set('designationId', [Validators.max(9999999999999)]);
		this.fields.set('designationId', this.designationId);
		this.controls.set('createdAt', []);
		this.fields.set('createdAt', this.createdAt);
		this.controls.set('updatedAt', []);
		this.fields.set('updatedAt', this.updatedAt);
		this.opsAllowed = {get: true, create: true, update: true, filter: true};
		this.listFields = ['departmentId','state','gender','designationId'];
		this.keyFields = ["staffId"];
	}

	public getName(): string {
		 return 'staff';
	}
}


export class StaffFd extends FormData {
	constructor(form: StaffForm, sa: ServiceAgent) {
		super(form, sa);
	}

	setFieldValue(name: 'staffId' | 'instituteId' | 'departmentId' | 'empId' | 'firstName' | 'middleName' | 'lastName' | 'displayName' | 'dateOfBirth' | 'spouseName' | 'fatherName' | 'addressLine1' | 'addressLine2' | 'city' | 'state' | 'pincode' | 'country' | 'phoneNumber' | 'email' | 'gender' | 'dateOfJoining' | 'dateOfLeaving' | 'reasonForLeaving' | 'designationId' | 'createdAt' | 'updatedAt', value: string | number | boolean | null ): void {
		super.setFieldValue(name, value);
	}

	getFieldValue(name: 'staffId' | 'instituteId' | 'departmentId' | 'empId' | 'firstName' | 'middleName' | 'lastName' | 'displayName' | 'dateOfBirth' | 'spouseName' | 'fatherName' | 'addressLine1' | 'addressLine2' | 'city' | 'state' | 'pincode' | 'country' | 'phoneNumber' | 'email' | 'gender' | 'dateOfJoining' | 'dateOfLeaving' | 'reasonForLeaving' | 'designationId' | 'createdAt' | 'updatedAt' ): string | number | boolean | null {
		return super.getFieldValue(name);
	}
}


export interface StaffVo extends Vo {
	empId?: string, 
	lastName?: string, 
	fatherName?: string, 
	country?: number, 
	gender?: string, 
	city?: string, 
	displayName?: string, 
	departmentId?: number, 
	createdAt?: string, 
	addressLine1?: string, 
	addressLine2?: string, 
	state?: string, 
	email?: string, 
	updatedAt?: string, 
	pincode?: string, 
	designationId?: number, 
	dateOfBirth?: string, 
	firstName?: string, 
	reasonForLeaving?: string, 
	dateOfJoining?: string, 
	phoneNumber?: string, 
	instituteId?: number, 
	middleName?: string, 
	spouseName?: string, 
	staffId?: number, 
	dateOfLeaving?: string
}
