
import { Form , Field, ChildForm } from '../form/form';
import { FormData } from '../form/formData';
import { SelectOption, Vo } from '../form/types';
import { Validators } from '@angular/forms'
import { ServiceAgent} from '../form/serviceAgent';

export class OfferableSectionForm extends Form {
	private static _instance = new OfferableSectionForm();
	levelSectionId:Field = {
		name:'levelSectionId'
		,controlType: 'Hidden'
		,label: 'levelSectionId'
		,isRequired: true
		,valueType: 1
		,errorId: 'invalidId'
		,maxValue: 9999999999999
	};
	sectionId:Field = {
		name:'sectionId'
		,controlType: 'Hidden'
		,label: 'sectionId'
		,isRequired: true
		,valueType: 1
		,errorId: 'invalidId'
		,maxValue: 9999999999999
	};
	name:Field = {
		name:'name'
		,controlType: 'Output'
		,label: 'Section'
		,valueType: 0
		,errorId: 'invalidName'
		,maxLength: 150
	};
	subjectSectionId:Field = {
		name:'subjectSectionId'
		,controlType: 'Hidden'
		,label: 'subjectSectionId'
		,valueType: 1
		,defaultValue: 0
		,errorId: 'invalidId'
		,maxValue: 9999999999999
	};
	nbrEnrolled:Field = {
		name:'nbrEnrolled'
		,controlType: 'Hidden'
		,label: 'nbrEnrolled'
		,valueType: 1
		,defaultValue: 0
		,errorId: 'invalidInteger'
		,maxValue: 9999999999999
	};
	canBeWithdrawn:Field = {
		name:'canBeWithdrawn'
		,controlType: 'Hidden'
		,label: 'canBeWithdrawn'
		,valueType: 3
		,defaultValue: true
		,errorId: 'invalidBool'
	};
	isOffered:Field = {
		name:'isOffered'
		,controlType: 'Checkbox'
		,label: 'Is Offered'
		,valueType: 3
		,defaultValue: false
		,errorId: 'invalidBool'
	};

	public static getInstance(): OfferableSectionForm {
		return OfferableSectionForm._instance;
	}

	constructor() {
		super();
		this.fields = new Map();
		this.controls = new Map();
		this.controls.set('levelSectionId', [Validators.required, Validators.max(9999999999999)]);
		this.fields.set('levelSectionId', this.levelSectionId);
		this.controls.set('sectionId', [Validators.required, Validators.max(9999999999999)]);
		this.fields.set('sectionId', this.sectionId);
		this.controls.set('name', [Validators.maxLength(150)]);
		this.fields.set('name', this.name);
		this.controls.set('subjectSectionId', [Validators.max(9999999999999)]);
		this.fields.set('subjectSectionId', this.subjectSectionId);
		this.controls.set('nbrEnrolled', [Validators.max(9999999999999)]);
		this.fields.set('nbrEnrolled', this.nbrEnrolled);
		this.controls.set('canBeWithdrawn', []);
		this.fields.set('canBeWithdrawn', this.canBeWithdrawn);
		this.controls.set('isOffered', []);
		this.fields.set('isOffered', this.isOffered);
		this.opsAllowed = {filter: true};
	}

	public getName(): string {
		 return 'offerableSection';
	}
}


export class OfferableSectionFd extends FormData {
	constructor(form: OfferableSectionForm, sa: ServiceAgent) {
		super(form, sa);
	}

	setFieldValue(name: 'levelSectionId' | 'sectionId' | 'name' | 'subjectSectionId' | 'nbrEnrolled' | 'canBeWithdrawn' | 'isOffered', value: string | number | boolean | null ): void {
		super.setFieldValue(name, value);
	}

	getFieldValue(name: 'levelSectionId' | 'sectionId' | 'name' | 'subjectSectionId' | 'nbrEnrolled' | 'canBeWithdrawn' | 'isOffered' ): string | number | boolean | null {
		return super.getFieldValue(name);
	}
}


export interface OfferableSectionVo extends Vo {
	isOffered?: boolean, 
	nbrEnrolled?: number, 
	levelSectionId?: number, 
	subjectSectionId?: number, 
	levelId?: number, 
	name?: string, 
	instituteId?: number, 
	sectionId?: number, 
	canBeWithdrawn?: boolean, 
	programId?: number
}
