import { Component, OnInit, ViewChild, Input, AfterViewInit } from "@angular/core";
import { ServiceAgent } from 'src/app/framework-modules/formdata/form/serviceAgent';
import { GroupPanel } from '../../interfaces';
import { SessionComponent } from '../../component';
import { MarksEntryForm, MarksEntryVo } from 'src/app/framework-modules/formdata/gen/marksEntryForm';
import { CtEditableTableComponent } from 'src/app/framework-modules/elements/ct-editable-table/component';
import { ClientContext } from 'src/app/framework-modules/formdata/form/clientContext';
import { CtDialogComponent } from 'src/app/framework-modules/elements/ct-dialog/component';
import { MatDialog } from '@angular/material';
import { MessageService } from 'src/app/services/messageService';
import { PanelData } from 'src/app/framework-modules/formdata/form/formData';
import { TableMetaData } from 'src/app/framework-modules/elements/ct-table/component';
import { ExcelParserService } from "src/app/services/excelParserService";
import { ExcelDownloadService, ExcelTemplate } from "src/app/services/excelDownloadService";

@Component({
  selector: 'manrks-entry-edit',
  templateUrl: "./component.html",
  styleUrls: ["./component.scss"]
})
export class MarksEntryEditComponent implements OnInit, AfterViewInit {
  @ViewChild("gridTable", { static: false }) gtable: CtEditableTableComponent;
  @Input() inputData: any;
  @Input() parentPanel: GroupPanel;
  @Input() session: SessionComponent;

  form: MarksEntryForm;
  fd: PanelData;
  vo: MarksEntryVo;

  tableData: TableMetaData;
  fullscreen: boolean = false;

  constructor(private sa: ServiceAgent, private cc: ClientContext, private ms: MessageService, private dialog: MatDialog,private ep:ExcelParserService,private ds:ExcelDownloadService) {
    this.form = MarksEntryForm.getInstance();
    this.fd = this.form.newPanelData(this.sa);
    this.vo = this.fd.getRawData();
  }

  ngOnInit() {
    this.metaDataInit();
    if(!this.inputData){
      console.error('Page is to be invoked with {id:subjectSectionId, seqNo:itemSeqNo}, prbably bug in marks-entry-list');
      this.ms.showError('Page Error : Page called with no input data');
      return;
    }
    const secId = this.inputData.id;
    const seqNo = this.inputData.seqNo;
  
    if (!secId || !seqNo) {
      console.error('Page is to be invoked with {id:subjectSectionId, seqNo:itemSeqNo}, prbably bug in marks-entry-list');
      this.ms.showError('Page Error : Page called with invalid input data');
      return;
    }
    this.getData(secId, seqNo)
  }

  ngAfterViewInit() {
    //this.gtable.update();
  }

  metaDataInit() {
    this.tableData = {
      data: [],
      metaData: {
        column_names: [
          "name",
          "usn",
          "marks"
        ],
        display_names: {
          "name": "Name",
          "usn": "USN",
          "marks": "Marks"
        },
        editable_columns: [
          "marks"
        ],
        error: "Error: Please Enter Marks or AB",
        validations: {
          "marks": {},
        },
        search: true,
        placeholder: "Search a student by Name, USN..."
      }
    };
  }

  getData(secId, seqNo) {
    /**
     * fetch() cannot be used because of primary-key issues. 
     * we use fetchFor(data) iinstead to satisfy API for get_marksEntry service
     */
    const data = {subjectSectionId: secId, seqNo:seqNo};
    this.fd.fetchFor(data).subscribe({
      next: data => {
        console.log(data);
        this.setData(data);
        console.log('Data received from the server');
      },
      error: msg => {
        console.error(msg);
        this.ms.showError('Server returned with an error message. ');
      }
    });
  }

  saveData() {
    this.fd.save().subscribe({
      next: data => {
        this.ms.showSuccess("Marks Saved Successfully!!");
        this.parentPanel.navigate('MarksEntryList');
      },
      error: msg => {
        this.ms.showError("Please check for highlighted error fields");
      }
    });
  }

  private setData(data) {
    function sortByProperty(property) {
      return function (a, b) {
        if (a[property] > b[property])
          return 1;
        else if (a[property] < b[property])
          return -1;
        return 0;
      }
    }
    this.vo = data;
    data.students.sort(sortByProperty("usn"));
    this.tableData.data = data.students;
    this.tableData.metaData.validations["marks"]["maxValue"] = data.maxMarks;
    this.vo.assessmentName += " - " + data.maxMarks + " Marks";
    this.tableData.metaData.validations['marks'].pattern = '(ab|AB|Ab|\\d{0,2}\\.?\\d{0,2})';
    this.tableData.metaData.validations["marks"]["minValue"] = 0;
    this.gtable.update();
  }
  /**
   * This fuction is called when the user clicks the discard button.
   * It asks to user whether he wants to discard the changes made.
   * If yes, it returns back to the marks-entry-list page.
   */
  discardChanges() {
    let dialogRef = this.dialog.open(CtDialogComponent);
    dialogRef.componentInstance.title = 'Discard Changes';
    dialogRef.componentInstance.content = 'Are you sure you want to discard changes?';
    dialogRef.componentInstance.primary = 'Discard';
    const subscribeDialog = dialogRef.componentInstance.save.subscribe((data) => {
      this.dialog.closeAll();
      this.parentPanel.navigate('MarksEntryList');
    });
  }

  /**
   * This function is called when the user clicks the save button.
   * It bulk uploads the user entered marks data to the database.
   * After that it reverts back to the marks-entry-list page.
   */
  saveChanges() {
    let dialogRef = this.dialog.open(CtDialogComponent);
    dialogRef.componentInstance.title = 'Save Changes';
    dialogRef.componentInstance.content = 'Are you sure you want to save changes?';
    dialogRef.componentInstance.primary = 'Save';
    const subscribeDialog = dialogRef.componentInstance.save.subscribe((data) => {
      // Yet to implement bulk upload functionality
      this.dialog.closeAll();
      this.saveData();
    });
  }

  goFullscreen(){
    this.fullscreen = !this.fullscreen;
  }

  popUpMessage(){
    let dialogRef = this.dialog.open(CtDialogComponent);
    dialogRef.componentInstance.title = 'NOTICE';
    dialogRef.componentInstance.content = 'Kindly modify only marks columns and none of the other columns,If you find any mismatch in data kindly contact your System Admin';
    dialogRef.componentInstance.primary = 'Download Template';
    const subscribeDialog = dialogRef.componentInstance.save.subscribe((data) => {
      // Yet to implement bulk upload functionality
      this.dialog.closeAll();
      this.downloadTemplate()
    });
  }

  downloadTemplate(){
    let templateData = [];
    let template: ExcelTemplate = {
      fixedHeader: [
        { loc: "A1", value: `${this.cc.getinstituteName()}, ${this.cc.getinstituteCity()} - ${this.cc.getinstitutePinCode().split(' ')[this.cc.getinstitutePinCode().split(' ').length-1]}`.toUpperCase(), style: { font: { size: 18, name: 'Cambria', bold: true }, alignment: { horizontal: 'center', vertical: 'middle' }, border: {}, fill: null, numFmt: '', protection: {locked:true} } },
        { loc: "A2", value: `(Autonomous Institute, Affiliated to ${this.cc.getuniversityName()})`, style: { font: { size: 12, name: 'Cambria', bold: true }, alignment: { horizontal: 'center', vertical: 'middle' }, border: {}, fill: null, numFmt: '', protection: {locked:true} } },
        { loc: "A3", value: "Marks Upload --- "+this.vo.subjectName+" - "+ this.vo.subjectCode +" - "+this.vo.sectionName, style: { font: { size: 16, name: 'Cambria', bold: true }, alignment: { horizontal: 'center', vertical: 'middle' }, border: {}, fill: null, numFmt: '', protection: {locked:true} } },
      ],
      dynamicHeader: [],
      merges: ['A1:C1', 'A2:C2', 'A3:C3'],
      tableArray: "students",
      tableStart: 5,
      tableStyle: { rowHeight: 15, },
      tableHeader: ["USN", "Name", this.vo.assessmentName,],
      tableValues: [
        { key: "usn", width: 35, style: { font: { size: 12, name: 'Cambria' }, alignment: { horizontal: 'center', vertical: 'middle' }, border: {}, fill: null, numFmt: '', protection: {locked:true} } },
        { key: "name", width: 65, style: { font: { size: 12, name: 'Cambria' }, alignment: { horizontal: 'left', vertical: 'middle' }, border: {}, fill: null, numFmt: '', protection: {locked:true} } },
        { key: "marks", width: 10, style: { font: { size: 12, name: 'Cambria' }, alignment: { horizontal: 'center', vertical: 'middle' }, border: {}, fill: null, numFmt: '', protection: null } },
      ],
      colWidths: [30, 60,20],
      fixedFooter: [],
      dynamicFootfer: [],
      footerMerges: [],
      footerRowHeights: [],
      tableHeaderStyle: { font: { size: 14, name: 'Cambria', bold: true }, alignment: { horizontal: 'center', vertical: 'middle' }, border: {bottom: {style: 'thin'},top: {style: 'thin'}, left: {style: 'thin'},right:{style: 'thin'} }, fill: {type: 'pattern',pattern: 'solid',fgColor: {  argb: 'FF00FF00'}}, numFmt: '', protection: {locked:true} }
    }
    this.ds.excelFromTemplate(template,{"students":this.tableData.data},"Marks Template --- "+this.vo.subjectCode+"-"+this.vo.sectionName,this.vo.sectionName)
  }

  onFileChange(ev){
    this.ep.parseMarks(ev,this.vo.sectionName).subscribe(
      data =>{
        this.fillData(data);
      }
    );
  }
  /**
   * usnMap = {usn:index};
   * @param data has {usn:marks}
   */
  private fillData(data:{}){
    var usnMap = {};
    this.tableData.data.forEach((element,index) =>{
      usnMap[element["usn"] as string] = index;
    });
    if(Object.keys(data).length != Object.keys(usnMap).length){
      this.ms.showError("Number of students is not same in excel and in database. Excel data manipulated!");
      return;
    }
    let flag: boolean = true;
    Object.keys(data).forEach(key =>{
      let index = usnMap[key];
      if(index == undefined){
        this.ms.showError(key+" not found in this section. Excel Data manipulated");
        flag=false;
        return;
      }
    });
    if(flag){
      Object.keys(data).forEach(key =>{
        let index = usnMap[key];
        this.tableData.data[index]["marks"] = data[key];
      });
      this.ms.showSuccess("Excel Uploaded Successfully!");
    }
  }
}