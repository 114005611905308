
import { Form , Field, ChildForm } from '../form/form';
import { FormData } from '../form/formData';
import { SelectOption, Vo } from '../form/types';
import { Validators } from '@angular/forms'
import { ServiceAgent} from '../form/serviceAgent';

export class CieMarksForm extends Form {
	private static _instance = new CieMarksForm();
	studentAssessmentId:Field = {
		name:'studentAssessmentId'
		,controlType: 'Hidden'
		,label: 'studentAssessmentId'
		,isRequired: true
		,valueType: 1
		,errorId: 'invalidId'
		,maxValue: 9999999999999
	};
	assessmentSchemeId:Field = {
		name:'assessmentSchemeId'
		,controlType: 'Hidden'
		,label: 'assessmentSchemeId'
		,isRequired: true
		,valueType: 1
		,errorId: 'invalidId'
		,maxValue: 9999999999999
	};
	assessmentSeqNo:Field = {
		name:'assessmentSeqNo'
		,controlType: 'Hidden'
		,label: 'assessmentSeqNo'
		,isRequired: true
		,valueType: 1
		,errorId: 'invalidInteger'
		,maxValue: 9999999999999
	};
	marks:Field = {
		name:'marks'
		,controlType: 'Output'
		,label: 'marks'
		,valueType: 0
		,errorId: 'invalidMarksOrAb'
		,maxLength: 5
	};

	public static getInstance(): CieMarksForm {
		return CieMarksForm._instance;
	}

	constructor() {
		super();
		this.fields = new Map();
		this.controls = new Map();
		this.controls.set('studentAssessmentId', [Validators.required, Validators.max(9999999999999)]);
		this.fields.set('studentAssessmentId', this.studentAssessmentId);
		this.controls.set('assessmentSchemeId', [Validators.required, Validators.max(9999999999999)]);
		this.fields.set('assessmentSchemeId', this.assessmentSchemeId);
		this.controls.set('assessmentSeqNo', [Validators.required, Validators.max(9999999999999)]);
		this.fields.set('assessmentSeqNo', this.assessmentSeqNo);
		this.controls.set('marks', [Validators.maxLength(5), Validators.pattern('(ab|AB|Ab|\\d{0,2}\\.?\\d{0,2})')]);
		this.fields.set('marks', this.marks);
	}

	public getName(): string {
		 return 'cieMarks';
	}
}


export class CieMarksFd extends FormData {
	constructor(form: CieMarksForm, sa: ServiceAgent) {
		super(form, sa);
	}

	setFieldValue(name: 'studentAssessmentId' | 'assessmentSchemeId' | 'assessmentSeqNo' | 'marks', value: string | number | boolean | null ): void {
		super.setFieldValue(name, value);
	}

	getFieldValue(name: 'studentAssessmentId' | 'assessmentSchemeId' | 'assessmentSeqNo' | 'marks' ): string | number | boolean | null {
		return super.getFieldValue(name);
	}
}


export interface CieMarksVo extends Vo {
	studentAssessmentId?: number, 
	assessmentSeqNo?: number, 
	marksScored?: number, 
	marks?: string, 
	assessmentSchemeId?: number, 
	hasAttended?: boolean
}
