import { CtTableComponent, TableMetaData, } from '../../../../framework-modules/elements/ct-table/component';
import { Component, Input, OnInit, ViewChild } from "@angular/core";
import { DynamicPanel, GroupPanel } from '../../interfaces';
import { ServiceAgent } from 'src/app/framework-modules/formdata/form/serviceAgent';
import { SessionComponent } from '../../component';
import { MessageService } from 'src/app/services/messageService';
import { PanelData } from 'src/app/framework-modules/formdata/form/formData';
import { PendingApprovalVo, PendingApprovalForm } from 'src/app/framework-modules/formdata/gen/pendingApprovalForm';

@Component({
  selector: "pending-authorisation",
  templateUrl: "./component.html",
  styleUrls: ["./component.scss"]
})

export class PendingAuthorisationListComponent implements DynamicPanel, OnInit {

  @ViewChild("gridTable", { static: false }) gtable: CtTableComponent;
  @ViewChild("details", { static: false })
  @Input() inputData: any;
  @Input() parentPanel: GroupPanel;
  @Input() session: SessionComponent;

  public tableData: TableMetaData;
  /**
   * used only to get data from the server. not used for rendering data
   */
  private fd: PanelData;
  /**
   * approvals received from the server
   */
  private aprs: PendingApprovalVo[];

  constructor(private sa: ServiceAgent, private messageService: MessageService) {
    this.initMetadata();
    this.fd = PendingApprovalForm.getInstance().newPanelData(this.sa);
  }

  ngOnInit() {
    this.getData();
  }

  ngAfterViewInit() {
  }

  private initMetadata() {
    this.tableData = {
      data: [
      ],
      metaData: {
        column_names: [
          'requestedDate',
          'requestType',
          'contactPerson',
          'name',
          'usn',
          'refText'
        ],
        display_names: {
          requestedDate: 'Date',
          requestType: 'Type',
          contactPerson: 'Requested By',
          name: 'Student',
          usn: 'USN',
          refText: 'Subject'
        },
        search: true,
        edit: true,
        placeholder: "Search By Requester, Student, Subject",
      }
    }
  }

  private getData() {
    this.fd.filter().subscribe({
      next: list => {
        this.setData(list);
      },
      error: msg => {
        this.messageService.showError('Server returned with an error');
      }
    });
  }

  private setData(list: PendingApprovalVo[]) {
    this.aprs = list;
    console.log(list.length + ' requests being rendered');
    this.tableData.data = list;
    this.gtable.update();
  }

  edit(idx) {
    const vo = this.aprs[idx];
    const param = {
      approvalId: vo.approvalId,
      internalRefId: vo.internalRefId
    };
    this.parentPanel.navigate('AuthorisationEdit', param)
  }
}

