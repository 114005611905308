
import { Form , Field, ChildForm } from '../form/form';
import { FormData } from '../form/formData';
import { SelectOption, Vo } from '../form/types';
import { Validators } from '@angular/forms'
import { ServiceAgent} from '../form/serviceAgent';

export class GuardianNamesForm extends Form {
	private static _instance = new GuardianNamesForm();
	guardianId:Field = {
		name:'guardianId'
		,controlType: 'Hidden'
		,label: 'guardianId'
		,isRequired: true
		,valueType: 1
		,defaultValue: -1
		,errorId: 'invalidFlexibleId'
		,minValue: -1
		,maxValue: 9999999999999
	};
	studentId:Field = {
		name:'studentId'
		,controlType: 'Hidden'
		,label: 'studentId'
		,valueType: 1
		,errorId: 'invalidFlexibleId'
		,minValue: -1
		,maxValue: 9999999999999
	};
	instituteId:Field = {
		name:'instituteId'
		,controlType: 'Hidden'
		,label: 'instituteId'
		,valueType: 1
		,errorId: 'invalidTenentKey'
		,maxValue: 9999999999999
	};
	relationType:Field = {
		name:'relationType'
		,controlType: 'Input'
		,label: 'Relationship'
		,isRequired: true
		,listName: 'relationType'
		,valueList: [
			{value:'Mother',text:'Mother'},
			{value:'Father',text:'Father'},
			{value:'Legal Guardian',text:'Legal Guardian'}
			]
		,valueType: 0
		,errorId: 'invalidText'
		,maxLength: 1000
	};
	name:Field = {
		name:'name'
		,controlType: 'Input'
		,label: 'Name'
		,isRequired: true
		,valueType: 0
		,errorId: 'invalidName'
		,maxLength: 150
	};

	public static getInstance(): GuardianNamesForm {
		return GuardianNamesForm._instance;
	}

	constructor() {
		super();
		this.fields = new Map();
		this.controls = new Map();
		this.controls.set('guardianId', [Validators.required, Validators.min(-1), Validators.max(9999999999999)]);
		this.fields.set('guardianId', this.guardianId);
		this.controls.set('studentId', [Validators.min(-1), Validators.max(9999999999999)]);
		this.fields.set('studentId', this.studentId);
		this.controls.set('instituteId', [Validators.max(9999999999999)]);
		this.fields.set('instituteId', this.instituteId);
		this.controls.set('relationType', [Validators.required, Validators.maxLength(1000)]);
		this.fields.set('relationType', this.relationType);
		this.controls.set('name', [Validators.required, Validators.maxLength(150)]);
		this.fields.set('name', this.name);
		this.opsAllowed = {get: true, create: true, update: true, filter: true};
		this.listFields = ['relationType'];
		this.keyFields = ["guardianId"];
	}

	public getName(): string {
		 return 'guardianNames';
	}
}


export class GuardianNamesFd extends FormData {
	constructor(form: GuardianNamesForm, sa: ServiceAgent) {
		super(form, sa);
	}

	setFieldValue(name: 'guardianId' | 'studentId' | 'instituteId' | 'relationType' | 'name', value: string | number | boolean | null ): void {
		super.setFieldValue(name, value);
	}

	getFieldValue(name: 'guardianId' | 'studentId' | 'instituteId' | 'relationType' | 'name' ): string | number | boolean | null {
		return super.getFieldValue(name);
	}
}


export interface GuardianNamesVo extends Vo {
	relationType?: string, 
	annualSalary?: number, 
	pincode?: string, 
	country?: number, 
	occupation?: string, 
	gender?: string, 
	city?: string, 
	guardianId?: number, 
	studentId?: number, 
	qualification?: string, 
	createdAt?: string, 
	phoneNumber?: string, 
	name?: string, 
	instituteId?: number, 
	addressLine1?: string, 
	addressLine2?: string, 
	state?: string, 
	email?: string, 
	updatedAt?: string
}
