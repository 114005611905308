
import { Form , Field, ChildForm } from '../form/form';
import { FormData } from '../form/formData';
import { SelectOption, Vo } from '../form/types';
import { Validators } from '@angular/forms'
import { ServiceAgent} from '../form/serviceAgent';

export class PacketListForm extends Form {
	private static _instance = new PacketListForm();

	public static getInstance(): PacketListForm {
		return PacketListForm._instance;
	}

	constructor() {
		super();
		this.fields = new Map();
		this.controls = new Map();
		this.opsAllowed = {filter: true};
	}

	public getName(): string {
		 return 'packetList';
	}
}


export class PacketListFd extends FormData {
	constructor(form: PacketListForm, sa: ServiceAgent) {
		super(form, sa);
	}
	/**  this form has no editable fields. data nust be accessed as Vo and not through fd **/
}


export interface PacketListVo extends Vo {
	offeredSubjectId?: number, 
	phoneNumber?: string, 
	valuationRound?: number, 
	name?: string, 
	instituteId?: number, 
	assignedTo?: number, 
	valuationType?: number, 
	packetCode?: string
}
