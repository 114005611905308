import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AppComponent } from './app.component';
import { ConstantsService } from './services/constantsService';

const appRoutes: Routes = [
  {
    path: '',
    component: AppComponent
  },
  {
    path: ConstantsService.ROUTE_NON_SESSION, loadChildren: './modules/non-session/module#NonSessionModule'
  },
  {
    path: ConstantsService.ROUTE_SESSION, loadChildren: './modules/session/module#SessionModule'
  },
  {
    path: ConstantsService.ROUTE_SEASON_SELECTION, loadChildren: './modules/session/season-select/module#SeasonSelectModule'
  }
];

@NgModule({
  imports: [RouterModule.forRoot(appRoutes)],
  exports: [RouterModule]
})
export class AppRouting { }
