import { Component, OnInit, ViewChild, Input, AfterViewInit } from "@angular/core";
import { DynamicPanel, GroupPanel } from '../../interfaces';
import { ServiceAgent } from 'src/app/framework-modules/formdata/form/serviceAgent';
import { CtTableComponent, TableMetaData } from '../../../../framework-modules/elements/ct-table/component'
import { FilterRequest } from 'src/app/framework-modules/formdata/form/types';
import { SessionComponent } from '../../component';
import { ClientContext } from 'src/app/framework-modules/formdata/form/clientContext';
import { AssignedStaffNameForm, AssignedStaffNameFd } from 'src/app/framework-modules/formdata/gen/assignedStaffNameForm';
import { TaughtSectionSelectionForm, TaughtSectionSelectionFd } from 'src/app/framework-modules/formdata/gen/taughtSectionSelectionForm';

@Component({
  selector: "assign-staff-list",
  templateUrl: "./component.html",
  styleUrls: ["./component.scss"]
})

export class AssignStaffComponent implements DynamicPanel, OnInit, AfterViewInit {
  @ViewChild("gridTable", { static: false }) gtable: CtTableComponent;
  @Input() inputData: any;
  @Input() parentPanel: GroupPanel;
  @Input() session: SessionComponent;
  public tableData: TableMetaData;
  public form: TaughtSectionSelectionForm;
  public fd: TaughtSectionSelectionFd;
  public namesFd: AssignedStaffNameFd;

  constructor(private sa: ServiceAgent, private ctx: ClientContext) {
    this.form = TaughtSectionSelectionForm.getInstance();
    this.fd = this.form.newFormData(this.sa);
    this.namesFd = AssignedStaffNameForm.getInstance().newFormData(this.sa);
  }

  metaDataInit() {
    this.tableData = {
      data: [
      ],
      metaData: {
        column_names: [
          "name",
          "email"
        ],
        display_names: {
          "name": "Name",
          "email": "E-mail"
        },
        edit: false,
        search: true,
        placeholder: "Search By Name and E-mail"
      }
    };
  }

  ngOnInit() {
    this.metaDataInit();
  }

  ngAfterViewInit() {
    this.gtable.update();
  }

  selectionChanged(id:any){
    if(id){
      this.filter(id);
    }else{
      this.clearList();
    }
  }

  edit() {
    this.parentPanel.navigate('AssignStaffEdit', this.fd.getFieldValue('subjectSectionId'));
  }

  clearList() {
        this.tableData.data = [];
        this.gtable.update();
  }

  filter(id) {
    /**
     * filter for subjectSectionId
     */
    const filter: FilterRequest = {
      conditions: {
        subjectSectionId: { value: id, comp: "=" }
      },
      sort: { name: "asc" }
    };

    this.namesFd.filter(filter).subscribe(
      data => {
        this.tableData.data = data;
        this.gtable.update();
      },
      msgs => {
        console.error(msgs);
      }
    );
  }
}