
import { Form , Field, ChildForm } from '../form/form';
import { FormData } from '../form/formData';
import { SelectOption, Vo } from '../form/types';
import { Validators } from '@angular/forms'
import { ServiceAgent} from '../form/serviceAgent';
import { SubjectwiseResultForm, SubjectwiseResultVo } from './subjectwiseResultForm';
import { GuardianForm, GuardianVo } from './guardianForm';
import { PreviousSemesterGPADetailForm, PreviousSemesterGPADetailVo } from './previousSemesterGPADetailForm';

export class MtechGradeCardStudentDetailForm extends Form {
	private static _instance = new MtechGradeCardStudentDetailForm();
	instituteId:Field = {
		name:'instituteId'
		,controlType: 'Hidden'
		,label: 'instituteId'
		,valueType: 1
		,errorId: 'invalidTenentKey'
		,maxValue: 9999999999999
	};
	studentId:Field = {
		name:'studentId'
		,controlType: 'Hidden'
		,label: 'studentId'
		,valueType: 1
		,errorId: 'invalidId'
		,maxValue: 9999999999999
	};
	levelId:Field = {
		name:'levelId'
		,controlType: 'Hidden'
		,label: 'levelId'
		,valueType: 1
		,errorId: 'invalidId'
		,maxValue: 9999999999999
	};
	programId:Field = {
		name:'programId'
		,controlType: 'Hidden'
		,label: 'programId'
		,valueType: 1
		,errorId: 'invalidId'
		,maxValue: 9999999999999
	};
	seasonId:Field = {
		name:'seasonId'
		,controlType: 'Hidden'
		,label: 'seasonId'
		,valueType: 1
		,errorId: 'invalidId'
		,maxValue: 9999999999999
	};
	degreeId:Field = {
		name:'degreeId'
		,controlType: 'Hidden'
		,label: 'degreeId'
		,valueType: 1
		,errorId: 'invalidId'
		,maxValue: 9999999999999
	};
	studentName:Field = {
		name:'studentName'
		,controlType: 'Hidden'
		,label: 'studentName'
		,valueType: 0
		,errorId: 'invalidText'
		,maxLength: 1000
	};
	usn:Field = {
		name:'usn'
		,controlType: 'Hidden'
		,label: 'usn'
		,valueType: 0
		,errorId: 'invalidText'
		,maxLength: 1000
	};
	levelName:Field = {
		name:'levelName'
		,controlType: 'Hidden'
		,label: 'levelName'
		,valueType: 0
		,errorId: 'invalidText'
		,maxLength: 1000
	};
	seasonName:Field = {
		name:'seasonName'
		,controlType: 'Hidden'
		,label: 'seasonName'
		,valueType: 0
		,errorId: 'invalidText'
		,maxLength: 1000
	};
	departmentName:Field = {
		name:'departmentName'
		,controlType: 'Hidden'
		,label: 'departmentName'
		,valueType: 0
		,errorId: 'invalidText'
		,maxLength: 1000
	};
	cgpa:Field = {
		name:'cgpa'
		,controlType: 'Hidden'
		,label: 'cgpa'
		,valueType: 0
		,errorId: 'invalidText'
		,maxLength: 1000
	};
	sgpa:Field = {
		name:'sgpa'
		,controlType: 'Hidden'
		,label: 'sgpa'
		,valueType: 0
		,errorId: 'invalidText'
		,maxLength: 1000
	};
	totalCredits:Field = {
		name:'totalCredits'
		,controlType: 'Hidden'
		,label: 'totalCredits'
		,valueType: 0
		,errorId: 'invalidText'
		,maxLength: 1000
	};
	registeredCredits:Field = {
		name:'registeredCredits'
		,controlType: 'Hidden'
		,label: 'registeredCredits'
		,valueType: 0
		,errorId: 'invalidText'
		,maxLength: 1000
	};
	obtainedCredits:Field = {
		name:'obtainedCredits'
		,controlType: 'Hidden'
		,label: 'obtainedCredits'
		,valueType: 0
		,errorId: 'invalidText'
		,maxLength: 1000
	};
	barcode:Field = {
		name:'barcode'
		,controlType: 'Hidden'
		,label: 'barcode'
		,valueType: 0
		,errorId: 'invalidText'
		,maxLength: 1000
	};
	projectTitle:Field = {
		name:'projectTitle'
		,controlType: 'Hidden'
		,label: 'projectTitle'
		,valueType: 0
		,errorId: 'invalidText'
		,maxLength: 1000
	};

	academicDetails: ChildForm = {
		name:'academicDetails',
		form:SubjectwiseResultForm.getInstance(),
		isEditable:false,
		isTabular:true,
		label:'',
		minRows:0,
		maxRows:0,
		errorId:null
	};
	guardianDetails: ChildForm = {
		name:'guardianDetails',
		form:GuardianForm.getInstance(),
		isEditable:false,
		isTabular:true,
		label:'',
		minRows:0,
		maxRows:0,
		errorId:null
	};
	previousSemesterGPADetails: ChildForm = {
		name:'previousSemesterGPADetails',
		form:PreviousSemesterGPADetailForm.getInstance(),
		isEditable:false,
		isTabular:true,
		label:'',
		minRows:0,
		maxRows:0,
		errorId:null
	};

	public static getInstance(): MtechGradeCardStudentDetailForm {
		return MtechGradeCardStudentDetailForm._instance;
	}

	constructor() {
		super();
		this.fields = new Map();
		this.controls = new Map();
		this.controls.set('instituteId', [Validators.max(9999999999999)]);
		this.fields.set('instituteId', this.instituteId);
		this.controls.set('studentId', [Validators.max(9999999999999)]);
		this.fields.set('studentId', this.studentId);
		this.controls.set('levelId', [Validators.max(9999999999999)]);
		this.fields.set('levelId', this.levelId);
		this.controls.set('programId', [Validators.max(9999999999999)]);
		this.fields.set('programId', this.programId);
		this.controls.set('seasonId', [Validators.max(9999999999999)]);
		this.fields.set('seasonId', this.seasonId);
		this.controls.set('degreeId', [Validators.max(9999999999999)]);
		this.fields.set('degreeId', this.degreeId);
		this.controls.set('studentName', [Validators.maxLength(1000)]);
		this.fields.set('studentName', this.studentName);
		this.controls.set('usn', [Validators.maxLength(1000)]);
		this.fields.set('usn', this.usn);
		this.controls.set('levelName', [Validators.maxLength(1000)]);
		this.fields.set('levelName', this.levelName);
		this.controls.set('seasonName', [Validators.maxLength(1000)]);
		this.fields.set('seasonName', this.seasonName);
		this.controls.set('departmentName', [Validators.maxLength(1000)]);
		this.fields.set('departmentName', this.departmentName);
		this.controls.set('cgpa', [Validators.maxLength(1000)]);
		this.fields.set('cgpa', this.cgpa);
		this.controls.set('sgpa', [Validators.maxLength(1000)]);
		this.fields.set('sgpa', this.sgpa);
		this.controls.set('totalCredits', [Validators.maxLength(1000)]);
		this.fields.set('totalCredits', this.totalCredits);
		this.controls.set('registeredCredits', [Validators.maxLength(1000)]);
		this.fields.set('registeredCredits', this.registeredCredits);
		this.controls.set('obtainedCredits', [Validators.maxLength(1000)]);
		this.fields.set('obtainedCredits', this.obtainedCredits);
		this.controls.set('barcode', [Validators.maxLength(1000)]);
		this.fields.set('barcode', this.barcode);
		this.controls.set('projectTitle', [Validators.maxLength(1000)]);
		this.fields.set('projectTitle', this.projectTitle);

		this.childForms = new Map();
		this.childForms.set('academicDetails', this.academicDetails);
		this.childForms.set('guardianDetails', this.guardianDetails);
		this.childForms.set('previousSemesterGPADetails', this.previousSemesterGPADetails);
		this.opsAllowed = {get: true, filter: true};
	}

	public getName(): string {
		 return 'mtechGradeCardStudentDetail';
	}
}


export class MtechGradeCardStudentDetailFd extends FormData {
	constructor(form: MtechGradeCardStudentDetailForm, sa: ServiceAgent) {
		super(form, sa);
	}

	setFieldValue(name: 'instituteId' | 'studentId' | 'levelId' | 'programId' | 'seasonId' | 'degreeId' | 'studentName' | 'usn' | 'levelName' | 'seasonName' | 'departmentName' | 'cgpa' | 'sgpa' | 'totalCredits' | 'registeredCredits' | 'obtainedCredits' | 'barcode' | 'projectTitle', value: string | number | boolean | null ): void {
		super.setFieldValue(name, value);
	}

	getFieldValue(name: 'instituteId' | 'studentId' | 'levelId' | 'programId' | 'seasonId' | 'degreeId' | 'studentName' | 'usn' | 'levelName' | 'seasonName' | 'departmentName' | 'cgpa' | 'sgpa' | 'totalCredits' | 'registeredCredits' | 'obtainedCredits' | 'barcode' | 'projectTitle' ): string | number | boolean | null {
		return super.getFieldValue(name);
	}
}


export interface MtechGradeCardStudentDetailVo extends Vo {
	departmentName?: string, 
	registeredCredits?: string, 
	degreeId?: number, 
	obtainedCredits?: string, 
	levelName?: string, 
	sessionSemester?: string, 
	studentId?: number, 
	sgpa?: string, 
	usn?: string, 
	totalCredits?: string, 
	academicYear?: string, 
	seasonId?: number, 
	levelId?: number, 
	seasonName?: string, 
	studentName?: string, 
	instituteId?: number, 
	cgpa?: string, 
	barcode?: string, 
	programId?: number, 
	projectTitle?: string, 
	academicDetails?: SubjectwiseResultVo, 
	guardianDetails?: GuardianVo, 
	previousSemesterGPADetails?: PreviousSemesterGPADetailVo
}
