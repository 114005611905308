import { AfterViewInit, Component, Input, ViewChild } from "@angular/core";
import { DynamicPanel, GroupPanel } from '../../interfaces';
import { ServiceAgent } from 'src/app/framework-modules/formdata/form/serviceAgent';
import { SessionComponent } from '../../component';
import { TableMetaData } from 'src/app/framework-modules/elements/ct-table/component';
import { FilterRequest, Vo } from 'src/app/framework-modules/formdata/form/types';
import { ClientContext } from 'src/app/framework-modules/formdata/form/clientContext';
import { CtCheckboxTableComponent } from 'src/app/framework-modules/elements/ct-checkbox-table/component';
import { CtSpinner } from 'src/app/framework-modules/elements/ct-spinner/component';
import { CtDialogComponent } from 'src/app/framework-modules/elements/ct-dialog/component';
import { MatDialog } from '@angular/material';
import { EnrollStudentFd, EnrollStudentForm } from 'src/app/framework-modules/formdata/gen/enrollStudentForm';
import { MessageService } from 'src/app/services/messageService';
import { ExcelParserService } from 'src/app/services/excelParserService';
import { ExcelTemplate, ExcelDownloadService } from 'src/app/services/excelDownloadService';


@Component({
  selector: "enrollments-add",
  templateUrl: "./component.html",
  styleUrls: ["./component.scss"]
})


export class EnrollmentsAddComponent implements DynamicPanel, AfterViewInit {
  @ViewChild("spinner", {static:false}) spinner: CtSpinner;
  @ViewChild("gridTable", { static: false }) gtable: CtCheckboxTableComponent;
  @Input() inputData: any;
  @Input() parentPanel: GroupPanel;
  @Input() session: SessionComponent;
  public tableData: TableMetaData;
  public form: EnrollStudentForm;
  public fd: EnrollStudentFd; 
  public vo: Vo;
  data: Vo;

  constructor(private sa: ServiceAgent, private cc: ClientContext, private dialog: MatDialog, private ms: MessageService, private ep: ExcelParserService, private ds: ExcelDownloadService) {
    this.form = EnrollStudentForm.getInstance();
    this.fd = this.form.newFormData(this.sa);
  }

  metaDataInit() {
    this.tableData = {
      data: [],
      metaData: {
        column_names: [
          "name",
          "usn",
          // "departmentName",
          "programName"
        ],
        display_names: {
          "name": "Name",
          "usn": "USN",
          "departmentName": "Department",
          "programName": "Program Name"
        },
        edit: false,
        search: true,
        placeholder: "Search By Student Name, USN, Department...",
        view: false,
        selectAttr: "isRegistered",
        managerFunction: "ENROLL STUDENTS"
      },
    };
    this.getEnrollments();
  }

  ngAfterViewInit() {
    this.metaDataInit();
  }

  getEnrollments(){
    this.spinner.show("Loading....");
    const f: FilterRequest = {
      conditions: {
        seasonId: {comp:'=', value:this.inputData.seasonId},
        degreeId: {comp:'=', value:this.inputData.degreeId},
        levelId: {comp:'=', value:this.inputData.levelId},
        levelSectionId: {comp: '=',value:this.inputData.levelSectionId}
      }
    }
    this.fd.filter(f).subscribe(
      data=>{
        this.tableData.data = data[0].students as [];
        this.gtable.update();
        this.data = data[0].students as Vo;
        this.spinner.hide();
      },
      err => {
        this.spinner.hide();
      }
    )
  }

  /**
   * Enroll students to selected level section and season
   */
  private save() {
    const payload = {
      seasonId: this.inputData.seasonId,
        degreeId: this.inputData.degreeId,
        levelId: this.inputData.levelId,
        levelSectionId: this.inputData.levelSectionId,
        students: this.data
    }
    this.sa.serve("Update_enrollStudent",{data:payload}).subscribe(
      data =>{
        this.ms.showSuccess("Enrolled Students to " + this.inputData.levelSection + " in " + this.inputData.season + " season.");
        this.parentPanel.navigate('EnrollmentsList');
      },
      err =>{
        this.ms.showError(err);
      }
    )
  }

  enrollStudents() {
    let dialogRef = this.dialog.open(CtDialogComponent);
    dialogRef.componentInstance.title = 'Enroll Selected Students';
    dialogRef.componentInstance.content = 'Are you sure you want to enroll selected students?';
    dialogRef.componentInstance.primary = 'Enroll';
    const subscribeDialog = dialogRef.componentInstance.save.subscribe((data) => {
      this.dialog.closeAll();
      this.save();
    });
  }

  goBack() {
    let dialogRef = this.dialog.open(CtDialogComponent);
    dialogRef.componentInstance.title = 'Discard Changes';
    dialogRef.componentInstance.content = 'Are you sure you want to discard changes?';
    dialogRef.componentInstance.primary = 'Discard';
    const subscribeDialog = dialogRef.componentInstance.save.subscribe((data) => {
      this.dialog.closeAll();
    this.parentPanel.navigate('EnrollmentsList')
    });
  }

  onFileChange(ev){
    this.spinner.show("Uploading....");
    this.ep.parseUSN(ev,"Enrollment").subscribe(
      data =>{
        this.fillData(data);
      },
      err =>{
        this.spinner.hide();
      }
    );
  }

  /**
   * usnMap = {usn:index};
   * @param data has {usn:true}
   */
   private fillData(data:{}){
    var usnMap: {[key:string]:any} = {};
    this.tableData.data.forEach((element,index) =>{
      usnMap[element["usn"] as string] = index;
    });
    let allUsn = Object.keys(usnMap), count = 0;
    Object.keys(data).forEach(key =>{
      if(usnMap[key] == undefined){
        return
      }
      count++;
      let index = usnMap[key];
      this.tableData.data[index]["isRegistered"] = true;
    });
    this.ms.showSuccess("Selected " + count + " students!");
    this.spinner.hide();
    this.gtable.update();
  }

  popUpMessage(){
    let dialogRef = this.dialog.open(CtDialogComponent);
    dialogRef.componentInstance.title = 'NOTICE';
    dialogRef.componentInstance.content = "This template can be used to enroll any number of students. Kindly review the count of the students to be enrolled before clicking the Enroll Students button";
    dialogRef.componentInstance.primary = 'Download Template';
    const subscribeDialog = dialogRef.componentInstance.save.subscribe((data) => {
      // Yet to implement bulk upload functionality
      this.dialog.closeAll();
      this.downloadTemplate()
    });
  }

  downloadTemplate(){
    let template: ExcelTemplate = {
      fixedHeader: [
        { loc: "A1", value: `${this.cc.getinstituteName()}, ${this.cc.getinstituteCity()} - ${this.cc.getinstitutePinCode().split(' ')[this.cc.getinstitutePinCode().split(' ').length-1]}`.toUpperCase(), style: { font: { size: 18, name: 'Cambria', bold: true }, alignment: { horizontal: 'center', vertical: 'middle' }, border: {}, fill: null, numFmt: '', protection: {locked:true} } },
        { loc: "A2", value: `(Autonomous Institute, Affiliated to ${this.cc.getuniversityName()})`, style: { font: { size: 12, name: 'Cambria', bold: true }, alignment: { horizontal: 'center', vertical: 'middle' }, border: {}, fill: null, numFmt: '', protection: {locked:true} } },
        { loc: "A3", value: "Enroll Students", style: { font: { size: 16, name: 'Cambria', bold: true }, alignment: { horizontal: 'center', vertical: 'middle' }, border: {}, fill: null, numFmt: '', protection: {locked:true} } },
      ],
      dynamicHeader: [],
      merges: ['A1:D1', 'A2:D2', 'A3:D3'],
      tableArray: "students",
      tableStart: 5,
      tableStyle: { rowHeight: 15, },
      tableHeader: ["USN",],
      tableValues: [],
      colWidths: [30, 60,20,20],
      fixedFooter: [],
      dynamicFootfer: [],
      footerMerges: [],
      footerRowHeights: [],
      tableHeaderStyle: { font: { size: 14, name: 'Cambria', bold: true }, alignment: { horizontal: 'center', vertical: 'middle' }, border: {bottom: {style: 'thin'},top: {style: 'thin'}, left: {style: 'thin'},right:{style: 'thin'} }, fill: {type: 'pattern',pattern: 'solid',fgColor: {  argb: 'FF00FF00'}}, numFmt: '', protection: {locked:true} }
    }
    this.ds.excelFromTemplate(template,{"students":[]},"Enrollment Template","Enrollment")
  }
}