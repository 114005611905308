
import { Form , Field, ChildForm } from '../form/form';
import { FormData } from '../form/formData';
import { SelectOption, Vo } from '../form/types';
import { Validators } from '@angular/forms'
import { ServiceAgent} from '../form/serviceAgent';

export class EvaluatorSelectionForm extends Form {
	private static _instance = new EvaluatorSelectionForm();
	departmentId:Field = {
		name:'departmentId'
		,controlType: 'Dropdown'
		,label: 'Select a Department'
		,isRequired: true
		,listName: 'departmentList'
		,valueType: 1
		,errorId: 'invalidId'
		,maxValue: 9999999999999
	};
	offeredSubjectId:Field = {
		name:'offeredSubjectId'
		,controlType: 'Dropdown'
		,label: 'Select a Subject'
		,isRequired: true
		,listName: 'offeredSubjectList'
		,listKey: 'departmentId'
		,valueType: 1
		,errorId: 'invalidId'
		,maxValue: 9999999999999
	};
	evaluatorId:Field = {
		name:'evaluatorId'
		,controlType: 'Dropdown'
		,label: 'Select an Evaluator'
		,isRequired: true
		,listName: 'evaluatorList'
		,listKey: 'offeredSubjectId'
		,valueType: 1
		,errorId: 'invalidId'
		,maxValue: 9999999999999
	};
	valuationRound:Field = {
		name:'valuationRound'
		,controlType: 'Dropdown'
		,label: 'Valuation Round'
		,isRequired: true
		,listName: 'valuationRoundList'
		,valueList: [
			{value:'1',text:'Initial'},
			{value:'2',text:'Revaluation'},
			{value:'3',text:'Challenge'}
			]
		,valueType: 1
		,defaultValue: 1
		,errorId: 'invalidInteger'
		,maxValue: 9999999999999
	};
	valuationType:Field = {
		name:'valuationType'
		,controlType: 'Dropdown'
		,label: 'Valuation Type'
		,isRequired: true
		,listName: 'valuationType'
		,listKey: 'valuationRound'
		,keyedList: {
			1 : [
				{value:'1',text:'I Valuation'}, 
				{value:'2',text:'II Val/Mod'}, 
				{value:'3',text:'III Valuation'}
			], 
			2 : [
				{value:'2',text:'Revaluation'}, 
				{value:'3',text:'3rd Valuationn'}
			], 
			3 : [
				{value:'2',text:'Challenge'}, 
				{value:'3',text:'3rd Valuation'}
			]
			}
		,valueType: 1
		,defaultValue: 1
		,errorId: 'invalidValuationType'
		,maxValue: 3
	};

	public static getInstance(): EvaluatorSelectionForm {
		return EvaluatorSelectionForm._instance;
	}

	constructor() {
		super();
		this.fields = new Map();
		this.controls = new Map();
		this.controls.set('departmentId', [Validators.required, Validators.max(9999999999999)]);
		this.fields.set('departmentId', this.departmentId);
		this.controls.set('offeredSubjectId', [Validators.required, Validators.max(9999999999999)]);
		this.fields.set('offeredSubjectId', this.offeredSubjectId);
		this.controls.set('evaluatorId', [Validators.required, Validators.max(9999999999999)]);
		this.fields.set('evaluatorId', this.evaluatorId);
		this.controls.set('valuationRound', [Validators.required, Validators.max(9999999999999)]);
		this.fields.set('valuationRound', this.valuationRound);
		this.controls.set('valuationType', [Validators.required, Validators.max(3)]);
		this.fields.set('valuationType', this.valuationType);
		this.listFields = ['departmentId','offeredSubjectId','evaluatorId','valuationRound','valuationType'];
	}

	public getName(): string {
		 return 'evaluatorSelection';
	}
}


export class EvaluatorSelectionFd extends FormData {
	constructor(form: EvaluatorSelectionForm, sa: ServiceAgent) {
		super(form, sa);
	}

	setFieldValue(name: 'departmentId' | 'offeredSubjectId' | 'evaluatorId' | 'valuationRound' | 'valuationType', value: string | number | boolean | null ): void {
		super.setFieldValue(name, value);
	}

	getFieldValue(name: 'departmentId' | 'offeredSubjectId' | 'evaluatorId' | 'valuationRound' | 'valuationType' ): string | number | boolean | null {
		return super.getFieldValue(name);
	}
}


export interface EvaluatorSelectionVo extends Vo {
	offeredSubjectId?: number, 
	valuationRound?: number, 
	evaluatorId?: number, 
	valuationType?: number, 
	departmentId?: number
}
