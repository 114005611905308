
import { Form , Field, ChildForm } from '../form/form';
import { FormData } from '../form/formData';
import { SelectOption, Vo } from '../form/types';
import { Validators } from '@angular/forms'
import { ServiceAgent} from '../form/serviceAgent';
import { GuardianNamesForm, GuardianNamesVo } from './guardianNamesForm';

export class StudentNameForm extends Form {
	private static _instance = new StudentNameForm();
	studentId:Field = {
		name:'studentId'
		,controlType: 'Hidden'
		,label: 'studentId'
		,isRequired: true
		,valueType: 1
		,defaultValue: -1
		,errorId: 'invalidFlexibleId'
		,minValue: -1
		,maxValue: 9999999999999
	};
	instituteId:Field = {
		name:'instituteId'
		,controlType: 'Hidden'
		,label: 'instituteId'
		,valueType: 1
		,errorId: 'invalidTenentKey'
		,maxValue: 9999999999999
	};
	usn:Field = {
		name:'usn'
		,controlType: 'Input'
		,label: 'USN'
		,isRequired: true
		,valueType: 0
		,errorId: 'invalidText'
		,maxLength: 1000
	};
	name:Field = {
		name:'name'
		,controlType: 'Input'
		,label: 'Name'
		,isRequired: true
		,valueType: 0
		,errorId: 'invalidName'
		,maxLength: 150
	};
	updatedAt:Field = {
		name:'updatedAt'
		,controlType: 'Hidden'
		,label: 'updatedAt'
		,valueType: 5
		,errorId: 'invalidTimestamp'
	};

	guardians: ChildForm = {
		name:'guardians',
		form:GuardianNamesForm.getInstance(),
		isEditable:true,
		isTabular:true,
		label:'',
		minRows:1,
		maxRows:0,
		errorId:null
	};

	public static getInstance(): StudentNameForm {
		return StudentNameForm._instance;
	}

	constructor() {
		super();
		this.fields = new Map();
		this.controls = new Map();
		this.controls.set('studentId', [Validators.required, Validators.min(-1), Validators.max(9999999999999)]);
		this.fields.set('studentId', this.studentId);
		this.controls.set('instituteId', [Validators.max(9999999999999)]);
		this.fields.set('instituteId', this.instituteId);
		this.controls.set('usn', [Validators.required, Validators.maxLength(1000)]);
		this.fields.set('usn', this.usn);
		this.controls.set('name', [Validators.required, Validators.maxLength(150)]);
		this.fields.set('name', this.name);
		this.controls.set('updatedAt', []);
		this.fields.set('updatedAt', this.updatedAt);

		this.childForms = new Map();
		this.childForms.set('guardians', this.guardians);
		this.opsAllowed = {get: true, create: true, update: true, filter: true};
		this.keyFields = ["studentId"];
	}

	public getName(): string {
		 return 'studentName';
	}
}


export class StudentNameFd extends FormData {
	constructor(form: StudentNameForm, sa: ServiceAgent) {
		super(form, sa);
	}

	setFieldValue(name: 'studentId' | 'instituteId' | 'usn' | 'name' | 'updatedAt', value: string | number | boolean | null ): void {
		super.setFieldValue(name, value);
	}

	getFieldValue(name: 'studentId' | 'instituteId' | 'usn' | 'name' | 'updatedAt' ): string | number | boolean | null {
		return super.getFieldValue(name);
	}
}


export interface StudentNameVo extends Vo {
	presentAddressLine1?: string, 
	regNo?: string, 
	presentAddressLine2?: string, 
	country?: number, 
	personalId?: string, 
	gender?: string, 
	city?: string, 
	departmentId?: number, 
	studentTypeId?: number, 
	isActive?: boolean, 
	studentId?: number, 
	usn?: string, 
	bloodGroup?: string, 
	previousClass?: string, 
	createdAt?: string, 
	presentState?: string, 
	levelId?: number, 
	addressLine1?: string, 
	addressLine2?: string, 
	state?: string, 
	admissionQuota?: string, 
	academicRegulationId?: number, 
	email?: string, 
	updatedAt?: string, 
	pincode?: string, 
	admissionDate?: string, 
	placeOfBirth?: string, 
	domicileState?: string, 
	levelSectionId?: number, 
	previousInstitute?: string, 
	caste?: string, 
	dateOfBirth?: string, 
	previousBoard?: string, 
	qualifyingExamRank?: string, 
	religion?: string, 
	presentCity?: string, 
	phoneNumber?: string, 
	nationality?: string, 
	presentCountry?: number, 
	name?: string, 
	instituteId?: number, 
	addressesAreSame?: boolean, 
	tempUsn?: string, 
	category?: string, 
	programId?: number, 
	presentPincode?: string, 
	guardians?: GuardianNamesVo
}
