import { Component, OnInit, Input, ViewChild } from "@angular/core";
import { GroupPanel } from '../../interfaces';
import { ServiceAgent } from 'src/app/framework-modules/formdata/form/serviceAgent';
import { SessionComponent } from '../../component';
import { ClientContext } from 'src/app/framework-modules/formdata/form/clientContext';
import { StudentIdForUsnFd, StudentIdForUsnForm, StudentIdForUsnVo } from 'src/app/framework-modules/formdata/gen/studentIdForUsnForm';
import { MessageService } from 'src/app/services/messageService';
import { ChangeProgramFd, ChangeProgramForm } from 'src/app/framework-modules/formdata/gen/changeProgramForm';
import { MatDialog } from '@angular/material';
import { CtDialogComponent } from 'src/app/framework-modules/elements/ct-dialog/component';
import { CtTableComponent, TableMetaData } from 'src/app/framework-modules/elements/ct-table/component';
import { SubjectRegisterDetailFd, SubjectRegisterDetailForm, SubjectRegisterDetailVo } from 'src/app/framework-modules/formdata/gen/subjectRegisterDetailForm';
import { FilterRequest } from 'src/app/framework-modules/formdata/form/types';
import { InstituteConfigurationFd, InstituteConfigurationForm, InstituteConfigurationVo } from 'src/app/framework-modules/formdata/gen/instituteConfigurationForm';

@Component({
  selector: "change-program",
  templateUrl: "./component.html",
  styleUrls: ["./component.scss"]
})

export class ChangeProgramComponent implements OnInit {
  @ViewChild("gridTable", { static: false }) gtable: CtTableComponent;
  @Input() inputData: any;
  @Input() parentPanel: GroupPanel;
  @Input() session: SessionComponent;
  
  public tableData: TableMetaData;

  public studentIdForm: StudentIdForUsnForm;
  public studentIdFd: StudentIdForUsnFd;
  public studentIdVo: StudentIdForUsnVo;

  public studentRegistersForm: SubjectRegisterDetailForm;
  public studentRegistersFd: SubjectRegisterDetailFd;
  public studentRegistersVo: SubjectRegisterDetailVo;
  
  public form1: InstituteConfigurationForm;
  public fd1: InstituteConfigurationFd;
  public vo1: InstituteConfigurationVo = {};

  searched: boolean = false;

  constructor(private sa: ServiceAgent, private cc: ClientContext, private ms:MessageService, private dialog: MatDialog) {
    this.studentIdForm = StudentIdForUsnForm.getInstance();
    this.studentIdFd = this.studentIdForm.newFormData(this.sa);
    this.form1 = InstituteConfigurationForm.getInstance();
    this.fd1 = this.form1.newFormData(this.sa);
    this.studentRegistersForm = SubjectRegisterDetailForm.getInstance();
    this.studentRegistersFd = this.studentRegistersForm.newFormData(this.sa);
  }

  ngOnInit() {
    this.tableData= {
      data:[],
      metaData:{
        column_names: [
          "subjectName",
          "cieMarks"
        ],
        display_names:{
          "subjectName":"Subject Name",
          "cieMarks": "CIE Marks"
        }
      }
    }
  }

  /**
   * Activated when the search button is pressed
   * This will output the studentId for a selected USN
   * Shows an error message to the user if student is not found
   */
  searchForStudent(){
    this.studentIdFd.setFieldValue('usn',this.studentIdFd.getFieldValue('usn').toString().trim().toUpperCase());
    this.studentIdFd.fetchData().subscribe(
      data => {
        this.studentIdVo = data;
        this.fetchStudentRegisters()
        this.searched = true;
      },
      err =>{
        this.ms.showInfo("No student Found!");
      }
    );
  }

  private fetchStudentRegisters(){
    var instituteId = this.cc.getInstituteId();
    this.fd1.setFieldValue('instituteId',instituteId);
    this.fd1.fetchData().subscribe({
      next: vo=>{
        this.vo1 = vo;
        const f: FilterRequest = {
          conditions: {
            studentId:{comp:'=',value: this.studentIdVo.studentId},
            seasonId: {comp:'=',value:this.vo1.currentSeason}
          }
        }
        this.studentRegistersFd.filter(f).subscribe(
          data =>{
            this.tableData.data = data as [];
            this.gtable.update();
          }
        )
      }
    });
  }

  changeProgram(){
    this.parentPanel.navigate("ChangeProgramEdit",{studentId:this.studentIdVo.studentId})
  }
  
}
