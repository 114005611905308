import { Component, OnInit, Input, ViewChild } from "@angular/core";
import { GroupPanel } from '../../interfaces';
import { ServiceAgent } from 'src/app/framework-modules/formdata/form/serviceAgent';
import { SessionComponent } from '../../component';
import { ClientContext } from 'src/app/framework-modules/formdata/form/clientContext';
import { MessageService } from 'src/app/services/messageService';
import { MatDialog } from '@angular/material';
import { FormData, TabularData } from 'src/app/framework-modules/formdata/form/formData';
import { CtDialogComponent } from 'src/app/framework-modules/elements/ct-dialog/component';
import { CtTableComponent, TableMetaData } from 'src/app/framework-modules/elements/ct-table/component';
import { CtSpinner } from 'src/app/framework-modules/elements/ct-spinner/component';
import { AssessmentSchemeAddForm, AssessmentSchemeAddFd } from 'src/app/framework-modules/formdata/gen/assessmentSchemeAddForm';
import { AssessmentItemForm, AssessmentItemFd } from 'src/app/framework-modules/formdata/gen/assessmentItemForm';
import { LogicBlockForAssessmentSchemeForm, LogicBlockForAssessmentSchemeFd } from 'src/app/framework-modules/formdata/gen/logicBlockForAssessmentSchemeForm';

@Component({
  selector: "assessment-scheme-add",
  templateUrl: "./component.html",
  styleUrls: ["./component.scss"]
})

export class AssessmentSchemeAddComponent implements OnInit {
  @ViewChild("gridTable", { static: false }) gtable: CtTableComponent;
  @ViewChild("spinner", {static:false}) spinner: CtSpinner;
  @Input() inputData: any;
  @Input() parentPanel: GroupPanel;
  @Input() session: SessionComponent;
  /**
   * Form to add a new Assessment Scheme
   * This is the parent form which will submit the data
   */
  public assessmentSchemeForm : AssessmentSchemeAddForm;
  public assessmentSchemeFd : AssessmentSchemeAddFd;
  /*
   * Form to add a logic Block for new Assessment Items
   */
  public logicBlockForm : LogicBlockForAssessmentSchemeForm;
  public logicBlockFd : LogicBlockForAssessmentSchemeFd;
  /* 
   * Form to add a new Assessment Items
   */
  public assessmentItemForm : AssessmentItemForm;
  public assessmentItemFd : AssessmentItemFd;
  /**
   * This data structure controls the page supporting functionalitites
   */
  public helper: Array<{
    fd: LogicBlockForAssessmentSchemeFd,
    showTests: boolean,
    assessmentItems: Array<AssessmentItemFd>,
    actions: {remove: boolean, add: boolean},
  }> = [];

  /**
   * Creates a instance of the all the above forms  
   */
  constructor(private sa: ServiceAgent, private cc: ClientContext, private ms:MessageService, private dialog: MatDialog) {
    this.assessmentSchemeForm = AssessmentSchemeAddForm.getInstance();
    this.assessmentSchemeFd = this.assessmentSchemeForm.newFormData(this.sa);
    this.assessmentItemForm = AssessmentItemForm.getInstance();
    this.assessmentItemFd = this.assessmentItemForm.newFormData(this.sa);
    this.logicBlockForm = LogicBlockForAssessmentSchemeForm.getInstance();
    this.logicBlockFd = this.logicBlockForm.newFormData(this.sa);
  }

  /**
   * It is called when the directive is instantiated.
   */
  ngOnInit(){ 
    this.formInitialData();
  }

  private formInitialData(){
    this.helper.push({
      fd: this.logicBlockForm.newFormData(this.sa),
      actions: {add: true, remove: false},
      showTests: true,
      assessmentItems: []
    });
  }

  /**
   * Adds a new logic block to the scheme
   */
  addLogicBlock(): void {
    this.helper[this.helper.length-1].actions.add = false;
    this.helper.push({
      fd: this.logicBlockForm.newFormData(this.sa),
      actions: {add: true, remove: true},
      showTests: true,
      assessmentItems: []
    });
  }

  /**
   * Removes a logic block from the list
   * @param index Index of the block to be removed
   */
  removeLogicBlock(index: number): void {
    this.helper.splice(index,1);
    if(this.helper.length == 1){
      this.helper[0].actions.add = true;
    }
  }

  /**
   * method dropdown listener
   * Toggles number of tests input box
   */
  onMethodChange(index :number,$event : any): void {
    if(this.helper[index].fd.getFieldValue('selectMethod') == 3) {
      this.helper[index].showTests = false;
      this.helper[index].fd.setFieldValue('numberOfTests',1);
    }
    else{
      this.helper[index].showTests = true;
    }
  }

  /**
   * Total tests textbox change listener
   * @param index Index of the logic block
   * @param $event Value entered in the input box
   */
  onTestsChange(index: number, $event: any): void {
    var totalTests = parseInt($event);
    if(totalTests == NaN){
      /**
       * Let's Wait for the user to input a numeric value
       */
      return;
    }
    var numberOfTests = parseInt(this.helper[index].fd.getFieldValue('numberOfTests') as string);
    if(numberOfTests == NaN && this.helper[index].showTests){
      this.ms.showError("Invalid number of tests entered");
      return;
    }
    if(numberOfTests > totalTests && this.helper[index].showTests){
      this.ms.showError("Number of Tests should be less than or equal to Total Tests.");
      return;
    }
    /**
     * We are all set not to add assessment items
     * First let us check if there is any existing items
     */
    var existingTests = this.helper[index].assessmentItems.length;
    if(existingTests != 0){
      var testsToAdd = totalTests - existingTests;
      if(testsToAdd > 0){
        for(var i=0; i< testsToAdd; i++){
          this.helper[index].assessmentItems.push(this.assessmentItemForm.newFormData(this.sa));
        };
      }
      else{
        for(var i=0; i> testsToAdd; i--){
          this.helper[index].assessmentItems.pop();
        };
      }
    }
    else{
      for(var i=0; i< totalTests; i++){
        this.helper[index].assessmentItems.push(this.assessmentItemForm.newFormData(this.sa));
      };
    }
  }

  /**
   * It is called on when user clicks on cancel button
   */
  cancel(){
    let dialogRef = this.dialog.open(CtDialogComponent);
    dialogRef.componentInstance.title = 'Discard Changes';
    dialogRef.componentInstance.content = 'Are you sure you want to discard changes?';
    dialogRef.componentInstance.primary = 'Discard';
    const subscribeDialog = dialogRef.componentInstance.save.subscribe((data) => {
      this.dialog.closeAll();
      this.parentPanel.navigate("AssessmentSchemeList");
    });
  }

  /**
   * Called when the user clicks on the save button
   */
  save(){
    var saveMessage  = this.getSaveMessage();
    let dialogRef = this.dialog.open(CtDialogComponent);
    dialogRef.componentInstance.title = 'Save Changes';
    dialogRef.componentInstance.content = 'Are you sure you want to add '+saveMessage+' tests as a new Assessment Scheme ?';
    dialogRef.componentInstance.primary = 'Save';
    const subscribeDialog = dialogRef.componentInstance.save.subscribe((data) => {
      this.dialog.closeAll();
      this.consolidateItems();
    });
  }

  /**
   * get the message of all the tests that the user has added
   */
  private getSaveMessage(): string{
    var message: string = "";
    this.helper.forEach((block,index) =>{
      var method = block.fd.getFieldValue('selectMethod');
      if(method == 1){
        message += " Best " + block.fd.getFieldValue('numberOfTests') + " Of " + block.fd.getFieldValue('totalTests');
      }
      else if(method == 2){
        message += " Average " + block.fd.getFieldValue('numberOfTests') + " Of " + block.fd.getFieldValue('totalTests');
      }
      else{
        message += " Compulsory " + block.fd.getFieldValue('totalTests');
      }
      if(index < this.helper.length-1){
        message += " and "
      }
    });
    return message;
  }

  /**
   * Validate and consolidate the fields to send data to server
   */
  private consolidateItems(){
    var blocks = new TabularData(this.logicBlockForm, this.sa, false);
    var consolidatedFd: LogicBlockForAssessmentSchemeFd[] = [];
    var flag: boolean = true;
    var message = "";

    this.helper.forEach(block =>{
      var assessmentItem = new TabularData(this.assessmentItemForm, this.sa, false);
      if(!block.fd.validateForm()){
        flag = false;
        message = "Fields in error!";
        return;
      }
      if(block.fd.getFieldValue('selectMethod') != 3 && parseInt(block.fd.getFieldValue('numberOfTests') as string) > parseInt(block.fd.getFieldValue('totalTests') as string)){
        flag = false;
        message = "Total number of tests must be greater or equal to number of tests!";
        return;
      }
      block.assessmentItems.forEach(item =>{
        if(!item.validateForm()){
          flag = false;
          message = "Fields in error!";
          return;
        }
      });
      assessmentItem.setAll(this.assessmentItemFd.toVoArray(block.assessmentItems));
      block.fd.childTabularData.set("assessmentItem", assessmentItem);
      consolidatedFd.push(block.fd);
    });

    if(!flag){
      this.ms.showError(message);
    }

    blocks.setAll(this.logicBlockFd.toVoArray(consolidatedFd));
    this.assessmentSchemeFd.childTabularData.set("logicBlockForAssessmentScheme", blocks);

    if(!this.assessmentSchemeFd.validateForm){
      this.ms.showError("Fields in error!");
    }

    this.saveForm()
  }

  private saveForm(){
    this.assessmentSchemeFd.saveAsNew().subscribe({
      next: data =>{
        this.parentPanel.navigate('AssessmentSchemeList');
      },
      error: err => {
        console.error(err);
      }
    })
  }
}
