
import { Form , Field, ChildForm } from '../form/form';
import { FormData } from '../form/formData';
import { SelectOption, Vo } from '../form/types';
import { Validators } from '@angular/forms'
import { ServiceAgent} from '../form/serviceAgent';

export class SubjectSectionForm extends Form {
	private static _instance = new SubjectSectionForm();
	subjectSectionId:Field = {
		name:'subjectSectionId'
		,controlType: 'Hidden'
		,label: 'subjectSectionId'
		,valueType: 1
		,errorId: 'invalidId'
		,maxValue: 9999999999999
	};
	offeredSubjectId:Field = {
		name:'offeredSubjectId'
		,controlType: 'Hidden'
		,label: 'offeredSubjectId'
		,isRequired: true
		,valueType: 1
		,errorId: 'invalidId'
		,maxValue: 9999999999999
	};
	subjectId:Field = {
		name:'subjectId'
		,controlType: 'Hidden'
		,label: 'subjectId'
		,isRequired: true
		,valueType: 1
		,errorId: 'invalidId'
		,maxValue: 9999999999999
	};
	seasonId:Field = {
		name:'seasonId'
		,controlType: 'Hidden'
		,label: 'seasonId'
		,isRequired: true
		,valueType: 1
		,errorId: 'invalidId'
		,maxValue: 9999999999999
	};
	instituteId:Field = {
		name:'instituteId'
		,controlType: 'Hidden'
		,label: 'instituteId'
		,valueType: 1
		,errorId: 'invalidTenentKey'
		,maxValue: 9999999999999
	};
	cieFrozen:Field = {
		name:'cieFrozen'
		,controlType: 'Hidden'
		,label: 'cieFrozen'
		,valueType: 3
		,defaultValue: false
		,errorId: 'invalidBool'
	};
	attendanceFrozen:Field = {
		name:'attendanceFrozen'
		,controlType: 'Hidden'
		,label: 'attendanceFrozen'
		,valueType: 3
		,defaultValue: false
		,errorId: 'invalidBool'
	};
	levelSectionId:Field = {
		name:'levelSectionId'
		,controlType: 'Input'
		,label: 'Level Section'
		,valueType: 1
		,defaultValue: 0
		,errorId: 'invalidId'
		,maxValue: 9999999999999
	};
	sectionId:Field = {
		name:'sectionId'
		,controlType: 'Input'
		,label: 'Section'
		,isRequired: true
		,valueType: 1
		,defaultValue: 0
		,errorId: 'invalidId'
		,maxValue: 9999999999999
	};
	createdAt:Field = {
		name:'createdAt'
		,controlType: 'Hidden'
		,label: 'createdAt'
		,valueType: 5
		,errorId: 'invalidTimestamp'
	};
	updatedAt:Field = {
		name:'updatedAt'
		,controlType: 'Hidden'
		,label: 'updatedAt'
		,valueType: 5
		,errorId: 'invalidTimestamp'
	};

	public static getInstance(): SubjectSectionForm {
		return SubjectSectionForm._instance;
	}

	constructor() {
		super();
		this.fields = new Map();
		this.controls = new Map();
		this.controls.set('subjectSectionId', [Validators.max(9999999999999)]);
		this.fields.set('subjectSectionId', this.subjectSectionId);
		this.controls.set('offeredSubjectId', [Validators.required, Validators.max(9999999999999)]);
		this.fields.set('offeredSubjectId', this.offeredSubjectId);
		this.controls.set('subjectId', [Validators.required, Validators.max(9999999999999)]);
		this.fields.set('subjectId', this.subjectId);
		this.controls.set('seasonId', [Validators.required, Validators.max(9999999999999)]);
		this.fields.set('seasonId', this.seasonId);
		this.controls.set('instituteId', [Validators.max(9999999999999)]);
		this.fields.set('instituteId', this.instituteId);
		this.controls.set('cieFrozen', []);
		this.fields.set('cieFrozen', this.cieFrozen);
		this.controls.set('attendanceFrozen', []);
		this.fields.set('attendanceFrozen', this.attendanceFrozen);
		this.controls.set('levelSectionId', [Validators.max(9999999999999)]);
		this.fields.set('levelSectionId', this.levelSectionId);
		this.controls.set('sectionId', [Validators.required, Validators.max(9999999999999)]);
		this.fields.set('sectionId', this.sectionId);
		this.controls.set('createdAt', []);
		this.fields.set('createdAt', this.createdAt);
		this.controls.set('updatedAt', []);
		this.fields.set('updatedAt', this.updatedAt);
		this.opsAllowed = {get: true, create: true, update: true, filter: true};
		this.keyFields = ["subjectSectionId"];
	}

	public getName(): string {
		 return 'subjectSection';
	}
}


export class SubjectSectionFd extends FormData {
	constructor(form: SubjectSectionForm, sa: ServiceAgent) {
		super(form, sa);
	}

	setFieldValue(name: 'subjectSectionId' | 'offeredSubjectId' | 'subjectId' | 'seasonId' | 'instituteId' | 'cieFrozen' | 'attendanceFrozen' | 'levelSectionId' | 'sectionId' | 'createdAt' | 'updatedAt', value: string | number | boolean | null ): void {
		super.setFieldValue(name, value);
	}

	getFieldValue(name: 'subjectSectionId' | 'offeredSubjectId' | 'subjectId' | 'seasonId' | 'instituteId' | 'cieFrozen' | 'attendanceFrozen' | 'levelSectionId' | 'sectionId' | 'createdAt' | 'updatedAt' ): string | number | boolean | null {
		return super.getFieldValue(name);
	}
}


export interface SubjectSectionVo extends Vo {
	levelSectionId?: number, 
	attendanceFrozen?: boolean, 
	cieFrozen?: boolean, 
	sectionId?: number, 
	subjectId?: number, 
	offeredSubjectId?: number, 
	createdAt?: string, 
	totalClasses?: number, 
	seasonId?: number, 
	subjectSectionId?: number, 
	instituteId?: number, 
	updatedAt?: string
}
