import { Component, OnInit, ViewChild, Input, AfterViewInit } from "@angular/core";
import { ServiceAgent } from 'src/app/framework-modules/formdata/form/serviceAgent';
import { TableMetaData } from 'src/app/framework-modules/elements/ct-table/component';
import { GroupPanel } from '../../interfaces';
import { SessionComponent } from '../../component';
import { PdcDownloadTempUgForm, PdcDownloadTempUgFd, PdcDownloadTempUgVo } from 'src/app/framework-modules/formdata/gen/pdcDownloadTempUgForm';
import { RowToColMeta, ColToRowMeta, Transposer, Vo } from 'src/app/framework-modules/formdata/form/types';
import { ClientContext } from 'src/app/framework-modules/formdata/form/clientContext';
import { MessageService } from 'src/app/services/messageService';
import { CtEditableTableComponent } from 'src/app/framework-modules/elements/ct-editable-table/component';
import { CtDialogComponent } from 'src/app/framework-modules/elements/ct-dialog/component';
import { MatDialog } from '@angular/material';
import { StudentDetailForm, StudentDetailFd } from 'src/app/framework-modules/formdata/gen/studentDetailForm';
import { ExcelDownloadService, ExcelTemplate } from "src/app/services/excelDownloadService";
import { ExcelParserService } from "src/app/services/excelParserService";


@Component({
  selector: 'manrks-entry-bulk-update',
  templateUrl: "./component.html",
  styleUrls: ["./component.scss"]
})
export class pdcUpdateComponent implements OnInit, AfterViewInit {
  @ViewChild("gridTable", { static: false }) gtable: CtEditableTableComponent;
  @Input() inputData: any;
  @Input() parentPanel: GroupPanel;
  @Input() session: SessionComponent;

  form: PdcDownloadTempUgForm;
  fd: PdcDownloadTempUgFd;
  vo: PdcDownloadTempUgVo = {};
  studentForm:StudentDetailForm;
  studentFd:StudentDetailFd;
  private students: [];


  private colToRowMeta: ColToRowMeta;
  tableData: TableMetaData;

  constructor(private sa: ServiceAgent,
    private ms: MessageService,
    private cc: ClientContext,
    private ds: ExcelDownloadService,
    private ep: ExcelParserService,
    private dialog: MatDialog) {

    this.form = PdcDownloadTempUgForm.getInstance();
    this.fd = this.form.newFormData(this.sa);
    this.studentForm = StudentDetailForm.getInstance();
    this.studentFd = this.studentForm.newFormData(this.sa);
  }

  ngOnInit() {
    this.initTable();
  }

  initTable() {
    this.tableData = {
      data: [],
      metaData: {
        column_names: ["date","usn","pdcNo","studentName","programName","departmentName","cgpa"],
        display_names: {
          usn: "USN",
          studentName: "Name",
          pdcNo: "Pdc No.",
          programName: "Program",
          departmentName: "Department",
          cgpa: "CGPA",
          date: "Date"
        },
        search: true,
        placeholder: "Search by Student Name, Student USN",
        editable_columns: ["date","usn","pdcNo","studentName","programName","departmentName","cgpa"],
        validations: {}
      }
    };
  }

  ngAfterViewInit() {
    this.gtable.update();
  }

  getData(id: string) {
  
  }

  saveData() {
    console.log(this.fd)
    this.sa.serve("UpdateTempPdcUg", { data: { applicantData: this.tableData.data}}).subscribe(
      (data) => {
        console.log(data)
        this.ms.showSuccess("Data Sucessfully Updated");
        this.parentPanel.navigate('pdcDownload');
      },
      (err) => {
        console.log(err);
      }
    );
  }

  save() {
    let dialogRef = this.dialog.open(CtDialogComponent);
    dialogRef.componentInstance.title = 'Save Changes';
    dialogRef.componentInstance.content = 'Are you sure you want to save changes?';
    dialogRef.componentInstance.primary = 'Save';
    const subscribeDialog = dialogRef.componentInstance.save.subscribe((data) => {
      // Yet to implement bulk upload functionality
      this.dialog.closeAll();
      this.saveData();
    });
  }

  cancel() {
    let dialogRef = this.dialog.open(CtDialogComponent);
    dialogRef.componentInstance.title = 'Discard Changes';
    dialogRef.componentInstance.content = 'Are you sure you want to discard changes?';
    dialogRef.componentInstance.primary = 'Discard';
    const subscribeDialog = dialogRef.componentInstance.save.subscribe((data) => {
      // Yet to implement bulk upload functionality
      this.dialog.closeAll();
      this.parentPanel.navigate('pdcDownload')
    });
  }

  onFileChange(ev){
    this.ep.parseBulkPDC(ev,"Sheet1").subscribe(
      data =>{
        this.fillData(data);
      }
    );
  }

  fillData(data){
    data["Sheet1"].forEach( row =>{
          this.tableData.data.push({"date":row[0], "usn": row[2], "pdcNo":row[1], "studentName": row[3], "programName":row[4], "departmentName": row[5], "cgpa": row[6]})
          this.fd.setFieldValues(row);
    });
    this.ms.showSuccess("Excel Uploaded Successfully!");
    this.gtable.update();
  }

}