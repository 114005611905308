
import { Form , Field, ChildForm } from '../form/form';
import { FormData } from '../form/formData';
import { SelectOption, Vo } from '../form/types';
import { Validators } from '@angular/forms'
import { ServiceAgent} from '../form/serviceAgent';

export class StudentDetailDownloadForm extends Form {
	private static _instance = new StudentDetailDownloadForm();
	studentId:Field = {
		name:'studentId'
		,controlType: 'Hidden'
		,label: 'studentId'
		,isRequired: true
		,valueType: 1
		,errorId: 'invalidFlexibleId'
		,minValue: -1
		,maxValue: 9999999999999
	};
	instituteId:Field = {
		name:'instituteId'
		,controlType: 'Hidden'
		,label: 'instituteId'
		,valueType: 1
		,errorId: 'invalidTenentKey'
		,maxValue: 9999999999999
	};
	departmentId:Field = {
		name:'departmentId'
		,controlType: 'Dropdown'
		,label: 'Department'
		,isRequired: true
		,listName: 'departmentList'
		,valueType: 1
		,errorId: 'invalidId'
		,maxValue: 9999999999999
	};
	departmentName:Field = {
		name:'departmentName'
		,controlType: 'Input'
		,label: 'Department Name'
		,isRequired: true
		,valueType: 0
		,errorId: 'invalidText'
		,maxLength: 1000
	};
	name:Field = {
		name:'name'
		,controlType: 'Input'
		,label: 'Level'
		,isRequired: true
		,valueType: 0
		,errorId: 'invalidName'
		,maxLength: 150
	};
	usn:Field = {
		name:'usn'
		,controlType: 'Input'
		,label: 'USN'
		,valueType: 0
		,errorId: 'invalidText'
		,maxLength: 1000
	};
	phoneNumber:Field = {
		name:'phoneNumber'
		,controlType: 'Input'
		,label: 'Phone'
		,isRequired: true
		,valueType: 0
		,errorId: 'invalidPhone'
		,minLength: 10
		,maxLength: 12
	};
	semester:Field = {
		name:'semester'
		,controlType: 'Output'
		,label: 'semester'
		,isRequired: true
		,valueType: 0
		,errorId: 'invalidText'
		,maxLength: 1000
	};
	section:Field = {
		name:'section'
		,controlType: 'Output'
		,label: 'section'
		,isRequired: true
		,valueType: 0
		,errorId: 'invalidText'
		,maxLength: 1000
	};
	programName:Field = {
		name:'programName'
		,controlType: 'Output'
		,label: 'programName'
		,isRequired: true
		,valueType: 0
		,errorId: 'invalidText'
		,maxLength: 1000
	};
	admissionDate:Field = {
		name:'admissionDate'
		,controlType: 'Output'
		,label: 'admissionDate'
		,isRequired: true
		,valueType: 0
		,errorId: 'invalidText'
		,maxLength: 1000
	};
	admissionQuota:Field = {
		name:'admissionQuota'
		,controlType: 'Output'
		,label: 'admissionQuota'
		,isRequired: true
		,valueType: 0
		,errorId: 'invalidText'
		,maxLength: 1000
	};
	bloodGroup:Field = {
		name:'bloodGroup'
		,controlType: 'Output'
		,label: 'bloodGroup'
		,isRequired: true
		,valueType: 0
		,errorId: 'invalidText'
		,maxLength: 1000
	};
	caste:Field = {
		name:'caste'
		,controlType: 'Output'
		,label: 'caste'
		,isRequired: true
		,valueType: 0
		,errorId: 'invalidText'
		,maxLength: 1000
	};
	category:Field = {
		name:'category'
		,controlType: 'Output'
		,label: 'category'
		,isRequired: true
		,valueType: 0
		,errorId: 'invalidText'
		,maxLength: 1000
	};
	dateOfBirth:Field = {
		name:'dateOfBirth'
		,controlType: 'Output'
		,label: 'dateOfBirth'
		,isRequired: true
		,valueType: 0
		,errorId: 'invalidText'
		,maxLength: 1000
	};
	email:Field = {
		name:'email'
		,controlType: 'Output'
		,label: 'email'
		,isRequired: true
		,valueType: 0
		,errorId: 'invalidText'
		,maxLength: 1000
	};
	gender:Field = {
		name:'gender'
		,controlType: 'Output'
		,label: 'gender'
		,isRequired: true
		,valueType: 0
		,errorId: 'invalidText'
		,maxLength: 1000
	};
	nationality:Field = {
		name:'nationality'
		,controlType: 'Output'
		,label: 'nationality'
		,isRequired: true
		,valueType: 0
		,errorId: 'invalidText'
		,maxLength: 1000
	};
	personalId:Field = {
		name:'personalId'
		,controlType: 'Output'
		,label: 'personalId'
		,isRequired: true
		,valueType: 0
		,errorId: 'invalidText'
		,maxLength: 1000
	};
	previousBoard:Field = {
		name:'previousBoard'
		,controlType: 'Output'
		,label: 'previousBoard'
		,isRequired: true
		,valueType: 0
		,errorId: 'invalidText'
		,maxLength: 1000
	};
	previousInstitute:Field = {
		name:'previousInstitute'
		,controlType: 'Output'
		,label: 'previousInstitute'
		,isRequired: true
		,valueType: 0
		,errorId: 'invalidText'
		,maxLength: 1000
	};
	qualifyingExamRank:Field = {
		name:'qualifyingExamRank'
		,controlType: 'Output'
		,label: 'qualifyingExamRank'
		,isRequired: true
		,valueType: 0
		,errorId: 'invalidText'
		,maxLength: 1000
	};
	religion:Field = {
		name:'religion'
		,controlType: 'Output'
		,label: 'religion'
		,isRequired: true
		,valueType: 0
		,errorId: 'invalidText'
		,maxLength: 1000
	};
	addressLine1:Field = {
		name:'addressLine1'
		,controlType: 'Output'
		,label: 'addressLine1'
		,isRequired: true
		,valueType: 0
		,errorId: 'invalidText'
		,maxLength: 1000
	};
	addressLine2:Field = {
		name:'addressLine2'
		,controlType: 'Output'
		,label: 'addressLine2'
		,isRequired: true
		,valueType: 0
		,errorId: 'invalidText'
		,maxLength: 1000
	};
	presentAddressLine1:Field = {
		name:'presentAddressLine1'
		,controlType: 'Output'
		,label: 'presentAddressLine1'
		,isRequired: true
		,valueType: 0
		,errorId: 'invalidText'
		,maxLength: 1000
	};
	presentAddressLine2:Field = {
		name:'presentAddressLine2'
		,controlType: 'Output'
		,label: 'presentAddressLine2'
		,isRequired: true
		,valueType: 0
		,errorId: 'invalidText'
		,maxLength: 1000
	};

	public static getInstance(): StudentDetailDownloadForm {
		return StudentDetailDownloadForm._instance;
	}

	constructor() {
		super();
		this.fields = new Map();
		this.controls = new Map();
		this.controls.set('studentId', [Validators.required, Validators.min(-1), Validators.max(9999999999999)]);
		this.fields.set('studentId', this.studentId);
		this.controls.set('instituteId', [Validators.max(9999999999999)]);
		this.fields.set('instituteId', this.instituteId);
		this.controls.set('departmentId', [Validators.required, Validators.max(9999999999999)]);
		this.fields.set('departmentId', this.departmentId);
		this.controls.set('departmentName', [Validators.required, Validators.maxLength(1000)]);
		this.fields.set('departmentName', this.departmentName);
		this.controls.set('name', [Validators.required, Validators.maxLength(150)]);
		this.fields.set('name', this.name);
		this.controls.set('usn', [Validators.maxLength(1000)]);
		this.fields.set('usn', this.usn);
		this.controls.set('phoneNumber', [Validators.required, Validators.minLength(10), Validators.maxLength(12), Validators.pattern('[1-9][0-9]*')]);
		this.fields.set('phoneNumber', this.phoneNumber);
		this.controls.set('semester', [Validators.required, Validators.maxLength(1000)]);
		this.fields.set('semester', this.semester);
		this.controls.set('section', [Validators.required, Validators.maxLength(1000)]);
		this.fields.set('section', this.section);
		this.controls.set('programName', [Validators.required, Validators.maxLength(1000)]);
		this.fields.set('programName', this.programName);
		this.controls.set('admissionDate', [Validators.required, Validators.maxLength(1000)]);
		this.fields.set('admissionDate', this.admissionDate);
		this.controls.set('admissionQuota', [Validators.required, Validators.maxLength(1000)]);
		this.fields.set('admissionQuota', this.admissionQuota);
		this.controls.set('bloodGroup', [Validators.required, Validators.maxLength(1000)]);
		this.fields.set('bloodGroup', this.bloodGroup);
		this.controls.set('caste', [Validators.required, Validators.maxLength(1000)]);
		this.fields.set('caste', this.caste);
		this.controls.set('category', [Validators.required, Validators.maxLength(1000)]);
		this.fields.set('category', this.category);
		this.controls.set('dateOfBirth', [Validators.required, Validators.maxLength(1000)]);
		this.fields.set('dateOfBirth', this.dateOfBirth);
		this.controls.set('email', [Validators.required, Validators.maxLength(1000)]);
		this.fields.set('email', this.email);
		this.controls.set('gender', [Validators.required, Validators.maxLength(1000)]);
		this.fields.set('gender', this.gender);
		this.controls.set('nationality', [Validators.required, Validators.maxLength(1000)]);
		this.fields.set('nationality', this.nationality);
		this.controls.set('personalId', [Validators.required, Validators.maxLength(1000)]);
		this.fields.set('personalId', this.personalId);
		this.controls.set('previousBoard', [Validators.required, Validators.maxLength(1000)]);
		this.fields.set('previousBoard', this.previousBoard);
		this.controls.set('previousInstitute', [Validators.required, Validators.maxLength(1000)]);
		this.fields.set('previousInstitute', this.previousInstitute);
		this.controls.set('qualifyingExamRank', [Validators.required, Validators.maxLength(1000)]);
		this.fields.set('qualifyingExamRank', this.qualifyingExamRank);
		this.controls.set('religion', [Validators.required, Validators.maxLength(1000)]);
		this.fields.set('religion', this.religion);
		this.controls.set('addressLine1', [Validators.required, Validators.maxLength(1000)]);
		this.fields.set('addressLine1', this.addressLine1);
		this.controls.set('addressLine2', [Validators.required, Validators.maxLength(1000)]);
		this.fields.set('addressLine2', this.addressLine2);
		this.controls.set('presentAddressLine1', [Validators.required, Validators.maxLength(1000)]);
		this.fields.set('presentAddressLine1', this.presentAddressLine1);
		this.controls.set('presentAddressLine2', [Validators.required, Validators.maxLength(1000)]);
		this.fields.set('presentAddressLine2', this.presentAddressLine2);
		this.opsAllowed = {get: true, create: true, update: true, filter: true};
		this.listFields = ['departmentId'];
		this.keyFields = ["studentId"];
	}

	public getName(): string {
		 return 'studentDetailDownload';
	}
}


export class StudentDetailDownloadFd extends FormData {
	constructor(form: StudentDetailDownloadForm, sa: ServiceAgent) {
		super(form, sa);
	}

	setFieldValue(name: 'studentId' | 'instituteId' | 'departmentId' | 'departmentName' | 'name' | 'usn' | 'phoneNumber' | 'semester' | 'section' | 'programName' | 'admissionDate' | 'admissionQuota' | 'bloodGroup' | 'caste' | 'category' | 'dateOfBirth' | 'email' | 'gender' | 'nationality' | 'personalId' | 'previousBoard' | 'previousInstitute' | 'qualifyingExamRank' | 'religion' | 'addressLine1' | 'addressLine2' | 'presentAddressLine1' | 'presentAddressLine2', value: string | number | boolean | null ): void {
		super.setFieldValue(name, value);
	}

	getFieldValue(name: 'studentId' | 'instituteId' | 'departmentId' | 'departmentName' | 'name' | 'usn' | 'phoneNumber' | 'semester' | 'section' | 'programName' | 'admissionDate' | 'admissionQuota' | 'bloodGroup' | 'caste' | 'category' | 'dateOfBirth' | 'email' | 'gender' | 'nationality' | 'personalId' | 'previousBoard' | 'previousInstitute' | 'qualifyingExamRank' | 'religion' | 'addressLine1' | 'addressLine2' | 'presentAddressLine1' | 'presentAddressLine2' ): string | number | boolean | null {
		return super.getFieldValue(name);
	}
}


export interface StudentDetailDownloadVo extends Vo {
	presentAddressLine1?: string, 
	presentAddressLine2?: string, 
	personalId?: string, 
	gender?: string, 
	departmentId?: number, 
	section?: string, 
	studentId?: number, 
	usn?: string, 
	bloodGroup?: string, 
	programName?: string, 
	addressLine1?: string, 
	addressLine2?: string, 
	admissionQuota?: string, 
	email?: string, 
	departmentName?: string, 
	admissionDate?: string, 
	previousInstitute?: string, 
	caste?: string, 
	dateOfBirth?: string, 
	previousBoard?: string, 
	qualifyingExamRank?: string, 
	religion?: string, 
	phoneNumber?: string, 
	nationality?: string, 
	name?: string, 
	instituteId?: number, 
	semester?: string, 
	category?: string
}
