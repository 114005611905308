
import { Form , Field, ChildForm } from '../form/form';
import { FormData } from '../form/formData';
import { SelectOption, Vo } from '../form/types';
import { Validators } from '@angular/forms'
import { ServiceAgent} from '../form/serviceAgent';

export class PendingApprovalForm extends Form {
	private static _instance = new PendingApprovalForm();

	public static getInstance(): PendingApprovalForm {
		return PendingApprovalForm._instance;
	}

	constructor() {
		super();
		this.fields = new Map();
		this.controls = new Map();
		this.opsAllowed = {filter: true};
		this.keyFields = ["approvalId"];
	}

	public getName(): string {
		 return 'pendingApproval';
	}
}


export class PendingApprovalFd extends FormData {
	constructor(form: PendingApprovalForm, sa: ServiceAgent) {
		super(form, sa);
	}
	/**  this form has no editable fields. data nust be accessed as Vo and not through fd **/
}


export interface PendingApprovalVo extends Vo {
	usn?: string, 
	internalRefId?: number, 
	requestType?: string, 
	refText?: string, 
	name?: string, 
	instituteId?: number, 
	contactPerson?: string, 
	approvalId?: number, 
	requestedDate?: string
}
