
import { Form , Field, ChildForm } from '../form/form';
import { FormData } from '../form/formData';
import { SelectOption, Vo } from '../form/types';
import { Validators } from '@angular/forms'
import { ServiceAgent} from '../form/serviceAgent';

export class CodedPaperNameForm extends Form {
	private static _instance = new CodedPaperNameForm();

	public static getInstance(): CodedPaperNameForm {
		return CodedPaperNameForm._instance;
	}

	constructor() {
		super();
		this.fields = new Map();
		this.controls = new Map();
		this.opsAllowed = {get: true, filter: true};
		this.keyFields = ["paperCode"];
	}

	public getName(): string {
		 return 'codedPaperName';
	}
}


export class CodedPaperNameFd extends FormData {
	constructor(form: CodedPaperNameForm, sa: ServiceAgent) {
		super(form, sa);
	}
	/**  this form has no editable fields. data nust be accessed as Vo and not through fd **/
}


export interface CodedPaperNameVo extends Vo {
	marks2?: number, 
	effectiveMarks?: number, 
	marks3?: number, 
	valuationRound?: number, 
	paperCode?: string, 
	subjectRegisterId?: number, 
	isMakeup?: boolean, 
	marks1?: number, 
	packetCode?: string, 
	studentId?: number, 
	offeredSubjectId?: number, 
	usn?: string, 
	grade?: string, 
	name?: string, 
	instituteId?: number, 
	hasMalpracticed?: boolean
}
