
import { Form , Field, ChildForm } from '../form/form';
import { FormData } from '../form/formData';
import { SelectOption, Vo } from '../form/types';
import { Validators } from '@angular/forms'
import { ServiceAgent} from '../form/serviceAgent';
import { AssignablePacketForm, AssignablePacketVo } from './assignablePacketForm';

export class AssignPacketForm extends Form {
	private static _instance = new AssignPacketForm();

	packets: ChildForm = {
		name:'packets',
		form:AssignablePacketForm.getInstance(),
		isEditable:false,
		isTabular:true,
		label:'',
		minRows:0,
		maxRows:0,
		errorId:null
	};

	public static getInstance(): AssignPacketForm {
		return AssignPacketForm._instance;
	}

	constructor() {
		super();
		this.fields = new Map();
		this.controls = new Map();

		this.childForms = new Map();
		this.childForms.set('packets', this.packets);
		this.opsAllowed = {get: true, update: true};
	}

	public getName(): string {
		 return 'assignPacket';
	}
}


export class AssignPacketFd extends FormData {
	constructor(form: AssignPacketForm, sa: ServiceAgent) {
		super(form, sa);
	}
	/**  this form has no editable fields. data nust be accessed as Vo and not through fd **/
}


export interface AssignPacketVo extends Vo {
	offeredSubjectId?: number, 
	evaluatorName?: string, 
	valuationRound?: number, 
	isMakeUp?: boolean, 
	PhoneNumber?: string, 
	evaluatorId?: number, 
	valuationType?: number, 
	subjectName?: string, 
	packets?: AssignablePacketVo
}
