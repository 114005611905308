import { Component, OnInit, Input, ViewChild, ViewContainerRef, Type, ComponentFactoryResolver } from "@angular/core";
import { DynamicPanel, LandingPanel, GroupPanel } from '../interfaces'
import { SessionComponent, SecondaryMenu } from '../component';
import { MenuService } from 'src/app/services/menuService';
import { ResetPasswordPageComponent} from './resetPassword-page/component'
/**
 Const to map the screen-component to screen-name to rended the facotry
 */
const MENU:SecondaryMenu = {
  ResetPassword: { comp: ResetPasswordPageComponent, title: "Reset Password", sideNav: false, pageId: "D55", displayonSidenav:false ,name: "ResetPassword", mainNav: true },
};

@Component({
  selector: "authorisation",
  templateUrl: "./component.html",
  styleUrls: ["./component.scss"]
})
export class ResetPasswordComponent implements OnInit, GroupPanel {
  @Input() inputData: any;
  @Input() parentPanel: SessionComponent;
  menu: { [key: string]: Type<DynamicPanel> };
  sidenav: boolean = false;
  displayNames: {}
  cardHeader: any;
  public items:{};
  public defaultSelected: string;
  pScreens: string[];
  /**
    container for the child page loaded from the seconday navbar
   */

  @ViewChild("secondaryContainer", { static: true, read: ViewContainerRef }) container: ViewContainerRef;
  constructor(private componentFactoryResolver: ComponentFactoryResolver, private ms: MenuService) { 
    this.pScreens = this.ms.getPermittedScreens();
  }

  ngOnInit() {

    this.cardHeader = "Reset Password";
    /**
     Navigate to Pending Authorisation page on app load
     */
    this.navigate("ResetPassword", {});
    this.items = MENU;
  }

  /**
   *
   * @param compName is the name of the component to navigate to
   * @param data is for any related data needed
   */
  public navigate(compName: string, data: any): void {
    console.log('Navigating to ' + compName + ' with data ', data);
    const item = MENU[compName];
    if (!item) {
      console.error(compName + ' is not a valid manu item. Navigation failed.');
      return;
    }

    if (!item.name) {
      console.error('Navigation abandoned because name is missing for this item');
      return;
    }

    if (this.pScreens.indexOf(item.pageId) == -1) {
      console.error('Navigation abandoned because pageId ' + item.pageId + ' is not found for this item')
      return;
    }

    this.parentPanel.toggleMainNav(item.mainNav);
    this.sidenav = item.sideNav;
    this.defaultSelected = item.name;
    this.cardHeader = item.title;
    this.container.clear();
    const factory = this.componentFactoryResolver.resolveComponentFactory(item.comp);
    const c = this.container.createComponent(factory);
    c.instance.inputData = data;
    c.instance.parentPanel = this;
    c.changeDetectorRef.detectChanges();
    console.log('Input data set to ', data );
  }

  fullScreenMode(){
    this.sidenav = false;
    this.parentPanel.hideMainNav();
  }

  halfScreenMode(){
    this.sidenav = true;
    this.parentPanel.showMainNav();
  }
}
