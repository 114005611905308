
import { Form , Field, ChildForm } from '../form/form';
import { FormData } from '../form/formData';
import { SelectOption, Vo } from '../form/types';
import { Validators } from '@angular/forms'
import { ServiceAgent} from '../form/serviceAgent';

export class InitiateValuationForm extends Form {
	private static _instance = new InitiateValuationForm();
	valuationRound:Field = {
		name:'valuationRound'
		,controlType: 'Dropdown'
		,label: 'Valuation Round'
		,isRequired: true
		,listName: 'valuationRoundList'
		,valueList: [
			{value:'1',text:'Initial'},
			{value:'2',text:'Revaluation'},
			{value:'3',text:'Challenge'}
			]
		,valueType: 1
		,defaultValue: 1
		,errorId: 'invalidInteger'
		,maxValue: 9999999999999
	};
	packetPrefix:Field = {
		name:'packetPrefix'
		,controlType: 'Input'
		,label: 'Packet-Prefix'
		,isRequired: true
		,valueType: 0
		,errorId: 'invalidText'
		,maxLength: 1000
	};
	examTypes:Field = {
		name:'examTypes'
		,controlType: 'Dropdown'
		,label: 'Type of Exam'
		,isRequired: true
		,listName: 'examTypes'
		,valueList: [
			{value:'1',text:'Normal'},
			{value:'2',text:'Make-up'}
			]
		,valueType: 1
		,defaultValue: 1
		,errorId: 'invalidInteger'
		,maxValue: 9999999999999
	};
	isFrozen:Field = {
		name:'isFrozen'
		,controlType: 'Hidden'
		,label: 'isFrozen'
		,isRequired: true
		,valueType: 3
		,errorId: 'invalidBool'
	};
	makeupInitiated:Field = {
		name:'makeupInitiated'
		,controlType: 'Hidden'
		,label: 'makeupInitiated'
		,isRequired: true
		,valueType: 3
		,errorId: 'invalidBool'
	};

	public static getInstance(): InitiateValuationForm {
		return InitiateValuationForm._instance;
	}

	constructor() {
		super();
		this.fields = new Map();
		this.controls = new Map();
		this.controls.set('valuationRound', [Validators.required, Validators.max(9999999999999)]);
		this.fields.set('valuationRound', this.valuationRound);
		this.controls.set('packetPrefix', [Validators.required, Validators.maxLength(1000)]);
		this.fields.set('packetPrefix', this.packetPrefix);
		this.controls.set('examTypes', [Validators.required, Validators.max(9999999999999)]);
		this.fields.set('examTypes', this.examTypes);
		this.controls.set('isFrozen', [Validators.required]);
		this.fields.set('isFrozen', this.isFrozen);
		this.controls.set('makeupInitiated', [Validators.required]);
		this.fields.set('makeupInitiated', this.makeupInitiated);
		this.opsAllowed = {get: true, create: true, update: true, filter: true};
		this.listFields = ['valuationRound','examTypes'];
	}

	public getName(): string {
		 return 'initiateValuation';
	}
}


export class InitiateValuationFd extends FormData {
	constructor(form: InitiateValuationForm, sa: ServiceAgent) {
		super(form, sa);
	}

	setFieldValue(name: 'valuationRound' | 'packetPrefix' | 'examTypes' | 'isFrozen' | 'makeupInitiated', value: string | number | boolean | null ): void {
		super.setFieldValue(name, value);
	}

	getFieldValue(name: 'valuationRound' | 'packetPrefix' | 'examTypes' | 'isFrozen' | 'makeupInitiated' ): string | number | boolean | null {
		return super.getFieldValue(name);
	}
}


export interface InitiateValuationVo extends Vo {
	makeupInitiated?: boolean, 
	valuationRound?: number, 
	instituteId?: number, 
	examTypes?: number, 
	isFrozen?: boolean, 
	packetPrefix?: string
}
