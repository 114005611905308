import { Component, OnInit, ViewChild, Input } from "@angular/core";
import { DynamicPanel, GroupPanel } from '../../interfaces';
import { ServiceAgent } from 'src/app/framework-modules/formdata/form/serviceAgent';
import { CtTableComponent } from '../../../../framework-modules/elements/ct-table/component'
import { SessionComponent } from '../../component';
import { MessageService } from 'src/app/services/messageService';
import { OfferedSubjectDetailForm, OfferedSubjectDetailFd } from 'src/app/framework-modules/formdata/gen/offeredSubjectDetailForm';
import { SubjectSectionDetailVo } from 'src/app/framework-modules/formdata/gen/subjectSectionDetailForm';
import { FilterRequest } from 'src/app/framework-modules/formdata/form/types';
import { MatDialog } from '@angular/material';
import { CtDialogComponent } from 'src/app/framework-modules/elements/ct-dialog/component';

@Component({
  selector: "first-year-offer-edit",
  templateUrl: "./component.html",
  styleUrls: ["./component.scss"]
})


export class FirstYearOfferEditComponent implements DynamicPanel, OnInit {
  @ViewChild("gridTable", { static: false }) gtable: CtTableComponent;
  @Input() inputData: any;
  @Input() parentPanel: GroupPanel;
  @Input() session: SessionComponent;

  /**
   * this is a linked form that has section details as its child
   */
  public form: OfferedSubjectDetailForm;
  /**
   * fd is used when the subject is not yet offered
   */
  public fd: OfferedSubjectDetailFd;
  /**
   * is true if isOffered is true. Set to true after user selects a level for which sections are to be shown
   */
  public levelSelected:boolean = false;
  /**
   * all sections to which this subject can be offered. 
   * It is received as child data if this subject is already offered.
   * else it is fetched after user selects a level
   */
  public sectionList: SubjectSectionDetailVo[] = [];
  /*
   * chip requires name and select attribute names in the VO
   */
  public nameAttr = 'name';
  public selectAttr = 'isOffered';

  constructor(private sa: ServiceAgent, private ms: MessageService,public dialog:MatDialog) {
    this.form = OfferedSubjectDetailForm.getInstance();
    this.fd = this.form.newFormData(this.sa);
  }

  ngOnInit() {
    this.fetchSubjectDetails(this.inputData);
  }

  /**
   * get sections for the selected level 
   */
  getSections() {
    const id = this.fd.getFieldValue('levelId');
    const filter: FilterRequest = { conditions: { levelId: { comp: "=", value: id } } };

    this.fd.fetchChildren('sections', filter).subscribe(
      data => {
        console.log('sections received');
        /*
         * get section data and set it to the child table
         */
        this.sectionList = data as SubjectSectionDetailVo[];
        this.levelSelected = true;
      },
      msgs => {
        this.ms.showError('Server returned with Error : ' + msgs);
      }
    );

  }

  openWithdrawDialog(){
    let dialogRef = this.dialog.open(CtDialogComponent);
    dialogRef.componentInstance.title = 'Withdraw Course';
    dialogRef.componentInstance.content = 'Are you sure you want to withdraw '+this.fd.getFieldValue('name');
    dialogRef.componentInstance.primary = 'Withdraw';
    const subscribeDialog = dialogRef.componentInstance.save.subscribe((data) => {
      this.withdraw();
      this.dialog.closeAll();
    });
  }
  /**
   *Withdraw/unoffer this subject
   */

  withdraw() {
    if (!this.fd.getFieldValue('isOffered')) {
      /**
       * user has not saved changes, but has changed her mind about the level.
       * no need to go to server. just show the drop-downs again, and hide sections.
       */
      this.levelSelected = false;
      return;
    }
    this.fd.delete().subscribe(
      data => {
        this.parentPanel.navigate('FirstYearRegisterList');
      },
      msgs => {
        this.ms.showError('Server returned with Error : ' + msgs);
      }
    );
  }

  /**
   * 
   * @param subjectId passed by the caller. this page rendered for this subject id
   */
  private fetchSubjectDetails(subjectId: any): void {
    if (!subjectId) {
      console.error('Design error : Input for subjectId not received.');
      return;
    }
    this.fd.setFieldValue('subjectId', subjectId);
    this.fd.fetchData().subscribe(
      data => {
        /*
         * get section data and set it to the child table
         */
        const sections = data.sections as SubjectSectionDetailVo[];
        if (sections.length != 0) {
          this.levelSelected = true;
          this.sectionList = sections;
        } else {
          this.levelSelected = false;
        }
      },
      msgs => {
        this.ms.showError('Server returned with Error : ' + msgs);
      }
    );
  }

  /**
   * save the changes to sections
   */
  save() {

    console.log(this.fd);
    if (!this.atLeastOneSection()) {
      this.ms.showError('At least one section is to be selected. CLick on WITHDRAW button if you do not wish to offer this subject for this level');
      return;
    }
    let dialogRef = this.dialog.open(CtDialogComponent);
    dialogRef.componentInstance.title = 'Save Changes';
    dialogRef.componentInstance.content = 'Are you sure you want to save changes?';
    dialogRef.componentInstance.primary = 'Save';
    const subscribeDialog = dialogRef.componentInstance.save.subscribe((data) => {
      this.dialog.closeAll();
      this.fd.save().subscribe(
        data => {
          this.ms.showSuccess('Subject Offered to sections Successfully')
          this.parentPanel.navigate('FirstYearRegisterList');
        },
        msgs => {
          this.ms.showError('Server returned with Error : ' + msgs);
        }
      );
    });
    
  }

  /**
   * has the user selected at least one section?
   */
  private atLeastOneSection(): boolean {
    for (let vo of this.sectionList) {
      if (vo.isOffered) {
        return true;
      }
    }
    return false;
  }

  /**
   * user discards changes
   */
  cancel(){
    let dialogRef = this.dialog.open(CtDialogComponent);
    dialogRef.componentInstance.title = 'Discard Changes';
    dialogRef.componentInstance.content = 'Are you sure you want to discard changes?';
    dialogRef.componentInstance.primary = 'Discard';
    const subscribeDialog = dialogRef.componentInstance.save.subscribe((data) => {
      this.dialog.closeAll();    
      this.parentPanel.navigate('FirstYearRegisterList');
    });
  }

  toggleAutoEnrolment($event){
    this.fd.setFieldValue('autoEnroll',$event.checked);
  }
}