
import { Form , Field, ChildForm } from '../form/form';
import { FormData } from '../form/formData';
import { SelectOption, Vo } from '../form/types';
import { Validators } from '@angular/forms'
import { ServiceAgent} from '../form/serviceAgent';

export class AllSectionSelectionForm extends Form {
	private static _instance = new AllSectionSelectionForm();
	departmentId:Field = {
		name:'departmentId'
		,controlType: 'Dropdown'
		,label: 'Select a Department'
		,isRequired: true
		,listName: 'departmentList'
		,valueType: 1
		,errorId: 'invalidId'
		,maxValue: 9999999999999
	};
	offeredSubjectId:Field = {
		name:'offeredSubjectId'
		,controlType: 'Dropdown'
		,label: 'Select a Subject'
		,isRequired: true
		,listName: 'allSubjectList'
		,listKey: 'departmentId'
		,valueType: 1
		,errorId: 'invalidId'
		,maxValue: 9999999999999
	};
	subjectSectionId:Field = {
		name:'subjectSectionId'
		,controlType: 'Hidden'
		,label: 'Select a Section'
		,isRequired: true
		,listName: 'subjectSectionList'
		,listKey: 'offeredSubjectId'
		,valueType: 1
		,errorId: 'invalidId'
		,maxValue: 9999999999999
	};

	public static getInstance(): AllSectionSelectionForm {
		return AllSectionSelectionForm._instance;
	}

	constructor() {
		super();
		this.fields = new Map();
		this.controls = new Map();
		this.controls.set('departmentId', [Validators.required, Validators.max(9999999999999)]);
		this.fields.set('departmentId', this.departmentId);
		this.controls.set('offeredSubjectId', [Validators.required, Validators.max(9999999999999)]);
		this.fields.set('offeredSubjectId', this.offeredSubjectId);
		this.controls.set('subjectSectionId', [Validators.required, Validators.max(9999999999999)]);
		this.fields.set('subjectSectionId', this.subjectSectionId);
		this.listFields = ['departmentId','offeredSubjectId','subjectSectionId'];
	}

	public getName(): string {
		 return 'allSectionSelection';
	}
}


export class AllSectionSelectionFd extends FormData {
	constructor(form: AllSectionSelectionForm, sa: ServiceAgent) {
		super(form, sa);
	}

	setFieldValue(name: 'departmentId' | 'offeredSubjectId' | 'subjectSectionId', value: string | number | boolean | null ): void {
		super.setFieldValue(name, value);
	}

	getFieldValue(name: 'departmentId' | 'offeredSubjectId' | 'subjectSectionId' ): string | number | boolean | null {
		return super.getFieldValue(name);
	}
}


export interface AllSectionSelectionVo extends Vo {
	offeredSubjectId?: number, 
	subjectSectionId?: number, 
	departmentId?: number
}
