
import { Form , Field, ChildForm } from '../form/form';
import { FormData } from '../form/formData';
import { SelectOption, Vo } from '../form/types';
import { Validators } from '@angular/forms'
import { ServiceAgent} from '../form/serviceAgent';

export class InstituteGpaStatisticsForm extends Form {
	private static _instance = new InstituteGpaStatisticsForm();

	public static getInstance(): InstituteGpaStatisticsForm {
		return InstituteGpaStatisticsForm._instance;
	}

	constructor() {
		super();
		this.fields = new Map();
		this.controls = new Map();
		this.opsAllowed = {filter: true, get: true};
	}

	public getName(): string {
		 return 'instituteGpaStatistics';
	}
}


export class InstituteGpaStatisticsFd extends FormData {
	constructor(form: InstituteGpaStatisticsForm, sa: ServiceAgent) {
		super(form, sa);
	}
	/**  this form has no editable fields. data nust be accessed as Vo and not through fd **/
}


export interface InstituteGpaStatisticsVo extends Vo {
	count?: number, 
	instituteId?: number, 
	seasonId?: number, 
	gpaRange?: string
}
