
import { Form , Field, ChildForm } from '../form/form';
import { FormData } from '../form/formData';
import { SelectOption, Vo } from '../form/types';
import { Validators } from '@angular/forms'
import { ServiceAgent} from '../form/serviceAgent';

export class ProgramListForm extends Form {
	private static _instance = new ProgramListForm();
	programId:Field = {
		name:'programId'
		,controlType: 'Hidden'
		,label: 'programId'
		,isRequired: true
		,valueType: 1
		,errorId: 'invalidId'
		,maxValue: 9999999999999
	};
	instituteId:Field = {
		name:'instituteId'
		,controlType: 'Hidden'
		,label: 'instituteId'
		,valueType: 1
		,errorId: 'invalidTenentKey'
		,maxValue: 9999999999999
	};
	departmentId:Field = {
		name:'departmentId'
		,controlType: 'Hidden'
		,label: 'departmentId'
		,isRequired: true
		,valueType: 1
		,errorId: 'invalidId'
		,maxValue: 9999999999999
	};
	programName:Field = {
		name:'programName'
		,controlType: 'Input'
		,label: 'Name'
		,isRequired: true
		,valueType: 0
		,errorId: 'invalidName'
		,maxLength: 150
	};
	programCode:Field = {
		name:'programCode'
		,controlType: 'Input'
		,label: 'Code'
		,valueType: 0
		,errorId: 'invalidCode'
		,maxLength: 50
	};
	departmentName:Field = {
		name:'departmentName'
		,controlType: 'Hidden'
		,label: 'departmentName'
		,isRequired: true
		,valueType: 0
		,errorId: 'invalidText'
		,maxLength: 1000
	};
	departmentCode:Field = {
		name:'departmentCode'
		,controlType: 'Hidden'
		,label: 'departmentCode'
		,isRequired: true
		,valueType: 0
		,errorId: 'invalidText'
		,maxLength: 1000
	};

	public static getInstance(): ProgramListForm {
		return ProgramListForm._instance;
	}

	constructor() {
		super();
		this.fields = new Map();
		this.controls = new Map();
		this.controls.set('programId', [Validators.required, Validators.max(9999999999999)]);
		this.fields.set('programId', this.programId);
		this.controls.set('instituteId', [Validators.max(9999999999999)]);
		this.fields.set('instituteId', this.instituteId);
		this.controls.set('departmentId', [Validators.required, Validators.max(9999999999999)]);
		this.fields.set('departmentId', this.departmentId);
		this.controls.set('programName', [Validators.required, Validators.maxLength(150)]);
		this.fields.set('programName', this.programName);
		this.controls.set('programCode', [Validators.maxLength(50)]);
		this.fields.set('programCode', this.programCode);
		this.controls.set('departmentName', [Validators.required, Validators.maxLength(1000)]);
		this.fields.set('departmentName', this.departmentName);
		this.controls.set('departmentCode', [Validators.required, Validators.maxLength(1000)]);
		this.fields.set('departmentCode', this.departmentCode);
		this.opsAllowed = {get: true, create: true, update: true, filter: true};
	}

	public getName(): string {
		 return 'programList';
	}
}


export class ProgramListFd extends FormData {
	constructor(form: ProgramListForm, sa: ServiceAgent) {
		super(form, sa);
	}

	setFieldValue(name: 'programId' | 'instituteId' | 'departmentId' | 'programName' | 'programCode' | 'departmentName' | 'departmentCode', value: string | number | boolean | null ): void {
		super.setFieldValue(name, value);
	}

	getFieldValue(name: 'programId' | 'instituteId' | 'departmentId' | 'programName' | 'programCode' | 'departmentName' | 'departmentCode' ): string | number | boolean | null {
		return super.getFieldValue(name);
	}
}


export interface ProgramListVo extends Vo {
	departmentName?: string, 
	programCode?: string, 
	programName?: string, 
	departmentId?: number, 
	departmentCode?: string, 
	instituteId?: number, 
	programId?: number
}
