import { Component, ViewChild, Input, AfterViewInit } from "@angular/core";
import { GroupPanel } from "../../interfaces";
import { ServiceAgent } from "src/app/framework-modules/formdata/form/serviceAgent";
import { SessionComponent } from "../../component";
import { ClientContext } from "src/app/framework-modules/formdata/form/clientContext";
import { TableMetaData,CtTableComponent} from "src/app/framework-modules/elements/ct-table/component";
import { MessageService } from "src/app/services/messageService";
import { AssignedStaffNameForm, AssignedStaffNameFd } from 'src/app/framework-modules/formdata/gen/assignedStaffNameForm';
import { InstituteConfigurationForm, InstituteConfigurationFd, InstituteConfigurationVo } from 'src/app/framework-modules/formdata/gen/instituteConfigurationForm';
import { CtSpinner } from 'src/app/framework-modules/elements/ct-spinner/component';
import { FilterRequest, Vo } from 'src/app/framework-modules/formdata/form/types';
import { MatDialog } from '@angular/material';
import { CtDialogComponent } from 'src/app/framework-modules/elements/ct-dialog/component';

@Component({
  selector: "cie-submission-list",
  templateUrl: "./component.html",
  styleUrls: ["./component.scss"]
})
export class CieSubmissionComponent implements AfterViewInit {
  @ViewChild("gridTable", { static: false }) gtable: CtTableComponent;
  @ViewChild("spinner", {static:false}) spinner: CtSpinner;
  @Input() inputData: any;
  @Input() parentPanel: GroupPanel;
  @Input() session: SessionComponent;

  /**
   * for selecting subjectSection
   */
  form: AssignedStaffNameForm;
  fd: AssignedStaffNameFd;
  assignedStaffFd : AssignedStaffNameFd;
  form1: InstituteConfigurationForm;
  fd1: InstituteConfigurationFd;
  vo1: InstituteConfigurationVo = {};
  tableData: TableMetaData;

  constructor(private sa: ServiceAgent,private cc: ClientContext,private ms: MessageService, private dialog: MatDialog) {
    this.form = AssignedStaffNameForm.getInstance();
    this.fd = this.form.newFormData(this.sa);
    this.assignedStaffFd = this.form.newFormData(this.sa);
    this.form1 = InstituteConfigurationForm.getInstance();
    this.fd1 = this.form1.newFormData(this.sa);
  }

  ngAfterViewInit(){
    this.metaDataInit();
    this.fetchSubmissionData();
  }

  private metaDataInit(){
    this.tableData = {
      data: [],
      metaData: {
        column_names: [
          "name",
          "phoneNumber",
          "subjectName",
          "section",
          "cieSubmitted"
        ],
        display_names: {
          "name":"Staff Name",
          "phoneNumber": "Phone",
          "subjectName":"Subject",
          "section":"Section",
          "cieSubmitted":"Submitted"
        },
        edit: true,
        search: true,
        placeholder: "Search by Staff Name, Subject.....",
        buttonName: "ALERT",
        styleAttribute: {
          "cieSubmitted": [
            {comp: '=', value: 'YES', style:{'color':'green','font-weight':600}},
            {comp: '=', value: 'NO', style:{'color':'red','font-weight':600}},
           ]
        }
      }
    }
  }

  /**
   * Local function to fetch data
   */
  private fetchSubmissionData(){
    var instituteId = this.cc.getInstituteId();
    this.fetchData(instituteId,this.cc.getcurrentSeasonId());
  }

  /**
   * Fetch the submission list for a season
   * @param seasonId Current Season ID
   */
  private fetchData(instituteId,seasonId){
    const filter: FilterRequest = {
      conditions: {
        instituteId: {comp:'=',value:instituteId},
        seasonId: {comp:'=',value:seasonId}
      }
    }
    this.fd.filter(filter).subscribe({
      next: data=> {
        this.tableData.data = data;
        this.gtable.update();
      }
    });
  }

  alertStaff($event) {
    this.assignedStaffFd.setAll(this.tableData.data[$event]);
    if(this.tableData.data[$event].cieSubmitted=='NO'){
      this.askToalert($event);
    }
    else{
      this.ms.showError('CIE has already been submitted by the Staff - '+this.tableData.data[$event].name);
    }
  }

  private askToalert($event){
    let dialogRef = this.dialog.open(CtDialogComponent);
    dialogRef.componentInstance.title = 'Send Alert';
    dialogRef.componentInstance.content = 'Are you sure you want to send an alert to '+this.tableData.data[$event].name +'?';
    dialogRef.componentInstance.primary = 'Send';
    const subscribeDialog = dialogRef.componentInstance.save.subscribe((data) => {
      this.spinner.show("Sending Alert to " + this.tableData.data[$event].name +".....");
      this.assignedStaffFd.save().subscribe({
        next: vo => {
          this.ms.showSuccess('Alert Message has been sent to the Staff '+this.tableData.data[$event].name+ ' Sucessfully.');
          this.dialog.closeAll();
          this.spinner.hide();
        },
        error: msg => {
          this.ms.showError('Error in sending messages to - '+this.tableData.data[$event].name);
          this.dialog.closeAll();
          this.spinner.hide();
        }
      });
    });
    
  }
}
