import { Component, OnInit, ViewChild, Input } from "@angular/core";
import { DynamicPanel, GroupPanel } from '../../interfaces';
import { ServiceAgent } from 'src/app/framework-modules/formdata/form/serviceAgent';
import { CtTableComponent, TableMetaData } from '../../../../framework-modules/elements/ct-table/component'
import { SessionComponent } from '../../component';
import { MessageService } from 'src/app/services/messageService';
import { CtSpinner } from 'src/app/framework-modules/elements/ct-spinner/component';
import { ExcelDownloadService } from 'src/app/services/excelDownloadService';
import { OfferedSubjectSelectionFd, OfferedSubjectSelectionForm } from "src/app/framework-modules/formdata/gen/offeredSubjectSelectionForm";
import { FilterRequest } from "src/app/framework-modules/formdata/form/types";
import { MalpracticePaperListFd, MalpracticePaperListForm, MalpracticePaperListVo } from "src/app/framework-modules/formdata/gen/malpracticePaperListForm";



@Component({
  selector: "malpractice-list",
  templateUrl: "./component.html",
  styleUrls: ["./component.scss"],
})


export class MalpracticeListComponent implements DynamicPanel, OnInit {
  @ViewChild("gridTable", { static: false }) gtable: CtTableComponent;
  @ViewChild("spinner", { static: false }) spinner: CtSpinner;
  @Input() inputData: any;
  @Input() parentPanel: GroupPanel;
  @Input() session: SessionComponent;

  public tableData: TableMetaData;
  /** form and fd for selecting the subjects */
  public subjectForm: OfferedSubjectSelectionForm;
  public subjectFd: OfferedSubjectSelectionFd;
  public form: MalpracticePaperListForm;
  public fd: MalpracticePaperListFd;
  public vo: MalpracticePaperListVo = {};

  
  private selectedId: string = null;

  constructor(private sa: ServiceAgent, private ms: MessageService, private ds: ExcelDownloadService) {
    this.subjectForm = OfferedSubjectSelectionForm.getInstance();
    this.subjectFd = this.subjectForm.newFormData(this.sa);
    this.form = MalpracticePaperListForm.getInstance();
    this.fd = this.form.newFormData(this.sa);
  }


  /**
   * 
   */
  ngOnInit() {
    this.metaDataInit();
  }

  metaDataInit() {
    this.tableData = {
      data: [],
      metaData: {
        column_names: [
          "paperCode",
          "usn",
          "name",
        ],
        display_names: {
          paperCode: "Paper Code",
          usn: "USN",
          name: "Name",
        },
        search: true,
        placeholder: "Search By Name, USN, Department"
      },
    };
  }

  changeSubject($event) {
    this.spinner.show('Getting attendance for this subject...');
    const filter: FilterRequest = {
      conditions: {
        offeredSubjectId: {comp:'=',value:this.subjectFd.getFieldValue('offeredSubjectId')}
      }
    }
    this.fd.filter(filter).subscribe({
      next: data => {
        this.tableData.data = data as [];
        this.gtable.update();
        this.spinner.hide();
      },

      error: msg => {
        this.spinner.hide();
        this.ms.showError('Server returned with error.');
      }
    });
  }

  edit() {
    const d = { 
      offeredSubjectId: this.subjectFd.getFieldValue('offeredSubjectId'),
    };
    this.parentPanel.navigate('MalpracticeEdit', d);
  }

}