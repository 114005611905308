import { Component, Input, ViewChild } from '@angular/core';
import { ServiceAgent } from 'src/app/framework-modules/formdata/form/serviceAgent';
import { FormData } from 'src/app/framework-modules/formdata/form/formData';
import { RouterService } from 'src/app/services/routerService';
import { LoginForm, LoginFd } from 'src/app/framework-modules/formdata/gen/loginForm';
import { NonSessionComponent } from '../../component';
import { DynamicPanel, GroupPanel } from '../../interfaces';
import { CtSpinner } from 'src/app/framework-modules/elements/ct-spinner/component';
import { MessageService } from 'src/app/services/messageService';
import { ClientContext } from 'src/app/framework-modules/formdata/form/clientContext';

@Component({
  selector: 'site-down',
  templateUrl: './component.html',
  styleUrls: ['./component.scss']
})
export class SiteDownComponent implements DynamicPanel {
  @ViewChild("CtSpinner", { static: false }) public CtSpinner: CtSpinner;
  @Input() inputData: any;
  @Input() parentPanel: GroupPanel;
  @Input() session: NonSessionComponent;

  public upDate: Date;
  
  public constructor(public sa: ServiceAgent,public routerService: RouterService,private ms: MessageService,private clientContext: ClientContext) {
    this.upDate = new Date('20/01/2021');
  }
  
}
