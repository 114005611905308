import {
  Component,
  Input,
  OnInit,
  ViewChild,
  AfterViewInit
} from "@angular/core";
import {
  DynamicPanel,
  GroupPanel
} from '../../interfaces';
import {
  ServiceAgent
} from 'src/app/framework-modules/formdata/form/serviceAgent';
import {
  SessionComponent
} from '../../component';
import {
  FormData
} from 'src/app/framework-modules/formdata/form/formData';
import {
  ClientContext
} from 'src/app/framework-modules/formdata/form/clientContext';
import {
  CtCheckboxTableComponent
} from 'src/app/framework-modules/elements/ct-checkbox-table/component';
import {
  ExcelDownloadService
} from 'src/app/services/excelDownloadService';
import {
  TableMetaData,
  CtTableComponent
} from 'src/app/framework-modules/elements/ct-table/component';

import {
  MessageService
} from 'src/app/services/messageService';
import {
  FilterRequest,
  Vo
} from 'src/app/framework-modules/formdata/form/types';
import {
  HallTicketLevelForm,
  HallTicketLevelFd
} from 'src/app/framework-modules/formdata/gen/hallTicketLevelForm';
import {
  HallTicketStudentForm,
  HallTicketStudentFd
} from 'src/app/framework-modules/formdata/gen/hallTicketStudentForm';


@Component({
  selector: "hallticket-list",
  templateUrl: "./component.html",
  styleUrls: ["./component.scss"]
})
export class HallTicketComponent implements DynamicPanel, OnInit {
  @ViewChild("gridTable", {
    static: false
  }) gtable: CtTableComponent;
  @Input() inputData: any;
  @Input() parentPanel: GroupPanel;
  @Input() session: SessionComponent;

  public form: HallTicketLevelForm;
  public fd: HallTicketLevelFd;
  public studentForm: HallTicketStudentForm;
  public studentFd: HallTicketStudentFd;
  public tableData: TableMetaData;
  public selectedId = '';


  constructor(private sa: ServiceAgent,
    private cc: ClientContext,
    private ms: MessageService,
    private ds: ExcelDownloadService) {

    this.form = HallTicketLevelForm.getInstance();
    this.fd = new FormData(this.form, this.sa);
    this.studentForm = HallTicketStudentForm.getInstance();
    this.studentFd = new FormData(this.studentForm, this.sa);


  }


  ngOnInit() {
    this.metaDataInit();
  }


  metaDataInit() {
    this.tableData = {
      data: [],
      metaData: {
        column_names: [
          "studentName",
          "usn",
          "departmentName"
        ],
        display_names: {
          "studentName": "Student Name",
          "usn": "Student USN",
          "departmentName": "Department Name"
        },
        search: true,
        placeholder: "Search By Student Name, USN, Program"
      }
    };
    this.filter();
  }



  filter() {

    this.tableData.metaData.edit = true;
    this.tableData.metaData.buttonName = "DOWNLOAD";
    /**
     * initial filter conditions
     */
    const filter: FilterRequest = {
      conditions: {
        levelId: {
          comp: "=",
          value: this.fd.getFieldValue('programLevelId')
        },
        programId: {
          comp: "=",
          value: this.fd.getFieldValue('programId')
        }
      },
      sort: {
        name: "asc"
      },
      maxRows: 10
    };
    const obs = this.studentFd.filter(filter);

    obs.subscribe(
      data => {
        this.tableData.data = data;
        this.gtable.update();
      },
      msgs => {
        console.error(msgs);
      }
    );
  }

  edit($event) {
    this.parentPanel.navigate("HallTicketView", {
      studentId: this.tableData.data[$event].studentId,
      levelId: this.fd.getFieldValue('programLevelId')
    });
  }
}
