import { Component, Input, Output, EventEmitter, OnInit, ViewChild, AfterViewInit } from "@angular/core";
import { DynamicPanel, LandingPanel, GroupPanel } from '../../interfaces';
import { ServiceAgent } from 'src/app/framework-modules/formdata/form/serviceAgent';
import { SessionComponent } from '../../component';
import { FormData } from 'src/app/framework-modules/formdata/form/formData';
import { DepartmentForm, DepartmentVo } from 'src/app/framework-modules/formdata/gen/departmentForm';
import { CtEditableTableComponent } from 'src/app/framework-modules/elements/ct-editable-table/component';
import { FilterRequest } from 'src/app/framework-modules/formdata/form/types';
import { ClientContext } from 'src/app/framework-modules/formdata/form/clientContext';
import { TableMetaData } from 'src/app/framework-modules/elements/ct-table/component';
import { CtBarChartComponent, BarChartMetaData } from 'src/app/framework-modules/elements/ct-barchart/component';
import { PieChartMetaData } from 'src/app/framework-modules/elements/ct-piechart/component';
import { PieGridChartMetaData } from 'src/app/framework-modules/elements/ct-piegridchart/component';

@Component({
  selector: "department-list",
  templateUrl: "./component.html",
  styleUrls: ["./component.scss"]
})
export class DepartmentListComponent implements DynamicPanel, OnInit {
  @Input() inputData: any;
  @Input() parentPanel: GroupPanel;
  @Input() session: SessionComponent;
  @ViewChild("gridTable", { static: false }) gtable: CtEditableTableComponent;
  public tableData: TableMetaData;
  public form: DepartmentForm;
  public fd: FormData;
  public vo: DepartmentVo;

  constructor(private sa: ServiceAgent, private cc: ClientContext) {
    this.form = DepartmentForm.getInstance();
    this.fd = new FormData(this.form, this.sa);
  }

  ngOnInit() {
    this.metaDataInit();
    this.filter();
  }

  filter() {
    this.fd.filter().subscribe(
      data => {
        console.log(data);
        this.tableData.data = data;
        this.gtable.update();
      },
      msgs => {
        console.error(msgs);
      }
    );
  }

  metaDataInit() {
    this.tableData = {
      data: [],
      metaData: {
        column_names: [
          "name",
          "code",
          "email"
        ],
        display_names: {
          "name": "Department Name",
          "code": "Department Code",
          "email": "E-mail"
        },
        edit: true,
        search: true,
        placeholder: "Search by Department Name, Department Code, Email"
      }
    };
  }


  doAddDepartment() {
    this.parentPanel.navigate('DepartmentAdd', this.inputData)
  }

  edit($event) {
    console.log($event);
    this.parentPanel.navigate("DepartmentEdit", this.tableData.data[$event].departmentId)
  }

  goBack() {
    this.parentPanel.navigate("InstituteList");
  }
}
