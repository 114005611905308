import { Component, OnInit, ViewChild, Input } from "@angular/core";
import { DynamicPanel, GroupPanel } from '../../interfaces';
import { ServiceAgent } from 'src/app/framework-modules/formdata/form/serviceAgent';
import { CtTableComponent, TableMetaData, } from '../../../../framework-modules/elements/ct-table/component'
import { FilterRequest, Vo } from 'src/app/framework-modules/formdata/form/types';
import { SessionComponent } from '../../component';
import { CtSpinner } from 'src/app/framework-modules/elements/ct-spinner/component';
import { PacketListForm, PacketListVo } from 'src/app/framework-modules/formdata/gen/packetListForm';
import { PanelData } from 'src/app/framework-modules/formdata/form/formData';
import { MessageService } from 'src/app/services/messageService';
import { EvaluatorSelectionForm, EvaluatorSelectionFd } from 'src/app/framework-modules/formdata/gen/evaluatorSelectionForm';
import { CodedPaperNameFd, CodedPaperNameForm } from "src/app/framework-modules/formdata/gen/codedPaperNameForm";
import { CodedPaperForm } from "src/app/framework-modules/formdata/gen/codedPaperForm";
import { ExcelDownloadService } from "src/app/services/excelDownloadService";
import { PdfDownloadService } from "src/app/services/pdfDownloadService";
import { AssignChallengeReviewerFd, AssignChallengeReviewerForm } from "src/app/framework-modules/formdata/gen/assignChallengeReviewerForm";
import { ChallengePaperEvaluationDetailsFd, ChallengePaperEvaluationDetailsForm } from "src/app/framework-modules/formdata/gen/challengePaperEvaluationDetailsForm";


@Component({
  selector: "packet-list",
  templateUrl: "./component.html",
  styleUrls: ["./component.scss"]
})

export class AssignReviewerComponent implements DynamicPanel, OnInit {
  @ViewChild("gridTable", { static: false }) gtable: CtTableComponent;
  @ViewChild("spinner", { static: false }) public spinner: CtSpinner;
  @Input() inputData: any;
  @Input() parentPanel: GroupPanel;
  @Input() session: SessionComponent;
  public form: AssignChallengeReviewerForm;
  public fd: AssignChallengeReviewerFd;
  public reportForm: ChallengePaperEvaluationDetailsForm;
  public reportFd: ChallengePaperEvaluationDetailsFd;
  public tableData: TableMetaData;

  private pd: PanelData;
  private packets: PacketListVo[];


  constructor(private sa: ServiceAgent, private ms: MessageService, private ps:PdfDownloadService,) {
    this.form = AssignChallengeReviewerForm.getInstance();
    this.fd = this.form.newFormData(this.sa);

    this.reportForm = ChallengePaperEvaluationDetailsForm.getInstance();
    this.reportFd = this.reportForm.newFormData(this.sa);
  }


  ngOnInit() {
    this.metaDataInit();
  }

  metaDataInit() {
    this.tableData = {
      data: [],
      metaData: {
        column_names: [
          "name",
          "phoneNumber"
        ],
        display_names: {
          name: "Evaluator Name",
          phoneNumber: "Phone Number"
        },
        search: true,
        edit:true,
        view:false,
        buttonName:"Assign",
        placeholder: "Search By Evaluator Name, Phone....",
      }
    };
  }

  public listForSubject() {
    const offerId = this.fd.getFieldValue('offeredSubjectId');
    const packetCode = this.fd.getFieldValue('packetCode');
    console.log(packetCode);
    if (!offerId || !packetCode) {
      this.ms.showError('Please select a Subject');
      return;
    }
    this.getData();
  }

  /**
   * get list of packets for this offer-id. PacketListForm is used
   * @param offerId 
   */
  private getData() {
    this.spinner.show('Getting packets ..');
    this.sa.serve('Get_assignChallengeReviewer', {data: {offeredSubjectId: this.fd.getFieldValue('offeredSubjectId'),packetCode: this.fd.getFieldValue('packetCode') }}).subscribe({
      next: data=>{
        this.tableData.data = data.evaluators as any[];
        this.gtable.update();
        this.spinner.hide();
      },
      error: msgs => {
        this.spinner.hide();
      }
    })
  }

  public assign($event) {
    const offerId = this.fd.getFieldValue('offeredSubjectId');
    const packetCode = this.fd.getFieldValue('packetCode');
    console.log(packetCode);
    if (!offerId || !packetCode) {
      this.ms.showError('Please select a value from the dropdown');
      return;
    }
    this.sa.serve('Update_assignChallengeReviewer', 
      {data: {offeredSubjectId: this.fd.getFieldValue('offeredSubjectId'),packetCode: this.fd.getFieldValue('packetCode'), reviewerId:  this.tableData.data[$event].evaluatorId}}
    ).subscribe(data=>{
      this.ms.showSuccess("Reviewer Assigned Successfully!");
      this.downloadReport();
    })
  }



  downloadReport(){
    const packetcode = this.fd.getFieldValue('packetCode');
    if(!packetcode){
      this.ms.showError('Please select a value from the dropdown');
      return;
    }
    this.reportFd.setFieldValue('packetCode',packetcode);
    this.reportFd.fetchData().subscribe(
      data=>{
        this.ps.downloadValuationSheet(data,"",data.packetCode);
      }
    )
  }
}
