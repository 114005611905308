import { Component, OnInit, ViewChild, Input, AfterViewInit } from "@angular/core";
import { GroupPanel } from '../../interfaces';
import { ServiceAgent } from 'src/app/framework-modules/formdata/form/serviceAgent';
import { PanelData } from 'src/app/framework-modules/formdata/form/formData';
import { SessionComponent } from '../../component';
import { CtTableComponent, TableMetaData } from 'src/app/framework-modules/elements/ct-table/component';
import { ConsolidatedAttendanceForm, ConsolidatedAttendanceVo } from 'src/app/framework-modules/formdata/gen/consolidatedAttendanceForm';
import { SectionAttendanceVo } from 'src/app/framework-modules/formdata/gen/sectionAttendanceForm';
import { TaughtSectionSelectionForm, TaughtSectionSelectionFd } from 'src/app/framework-modules/formdata/gen/taughtSectionSelectionForm';
import { MessageService } from 'src/app/services/messageService';
import { Vo } from 'src/app/framework-modules/formdata/form/types';
import { MatDialog } from '@angular/material';

@Component({
  selector: "consolidated-attendance-view",
  templateUrl: "./component.html",
  styleUrls: ["./component.scss"]
})


export class ConsolidatedAttendanceViewComponent implements OnInit, AfterViewInit {
  @ViewChild("gridTable", { static: false }) gtable: CtTableComponent;
  @Input() inputData: any;
  @Input() parentPanel: GroupPanel;
  @Input() session: SessionComponent;

  /**
   * drop-downs to select a subject-section-id are managed with this form.
   * this is a local form, in the sense that the data is not sent/recd.
   */
  selectForm: TaughtSectionSelectionForm;
  selectFd: TaughtSectionSelectionFd;

  /**
   * selected sectionId set to this local field. 
   * html hide/show is conditioned based on this value
   */
  sectionId: string = '';

  /**
   * form used to communicate with the server.
   */
  headerForm: ConsolidatedAttendanceForm;
  headerData: PanelData;
  headerVo: ConsolidatedAttendanceVo = {};

  /**
   * child data of the above form is captured into a Vo[] for ease of manipulation
   */
  students: SectionAttendanceVo[];

  /**
   * getting contect to check the role of the user.
   * using this to allow the unfreeze option only for admins
   */
  tableData: TableMetaData;
  isFrozen: boolean;

  constructor(private sa: ServiceAgent,
    private ms: MessageService,
    private dialog: MatDialog) {

    this.selectForm = TaughtSectionSelectionForm.getInstance();
    this.selectFd = this.selectForm.newFormData(this.sa);

    this.headerForm = ConsolidatedAttendanceForm.getInstance();
    this.headerData = this.headerForm.newPanelData(this.sa);
  }

  ngOnInit() {
    this.metaDataInit();
  }

  ngAfterViewInit() {
    this.gtable.update();
  }

  metaDataInit() {
    this.tableData = {
      data: [],
      metaData: {
        column_names: [
          "name",
          "usn",
          "overallAttendance"
        ],
        display_names: {
          "name": "Student Name",
          "usn": "USN",
          "overallAttendance": "Attendance Percentage"
        },
        search: true,
        placeholder: "Search by Student Name, Student USN",
        edit: false
      }
    };
  }

  /**
   * triggered when sectionId is changed.
   * @param sectionId 
   */
  sectionChanged(sectionId: string) {
    if (sectionId == this.sectionId) {
      return;
    }

    if (sectionId) {
      this.sectionId = sectionId;
      this.getData(sectionId);
    }
  }

  getData(sectionId: any) {
    this.headerData.setFieldValue('subjectSectionId', sectionId);
    this.headerData.fetchData().subscribe(
      data => {
        this.isFrozen = data.attendanceFrozen as boolean;
        this.headerVo = this.headerData.getRawData();
        this.tableData.data = data.students as Vo[];
        this.gtable.update();
      },
      msgs => {
        this.ms.showError('Server returned with errors');
      }
    );
  }
}