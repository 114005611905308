
import { Form , Field, ChildForm } from '../form/form';
import { FormData } from '../form/formData';
import { SelectOption, Vo } from '../form/types';
import { Validators } from '@angular/forms'
import { ServiceAgent} from '../form/serviceAgent';
import { CodedPaperForm, CodedPaperVo } from './codedPaperForm';

export class PacketDetailForm extends Form {
	private static _instance = new PacketDetailForm();

	papers: ChildForm = {
		name:'papers',
		form:CodedPaperForm.getInstance(),
		isEditable:false,
		isTabular:true,
		label:'',
		minRows:0,
		maxRows:0,
		errorId:null
	};

	public static getInstance(): PacketDetailForm {
		return PacketDetailForm._instance;
	}

	constructor() {
		super();
		this.fields = new Map();
		this.controls = new Map();

		this.childForms = new Map();
		this.childForms.set('papers', this.papers);
		this.opsAllowed = {get: true};
		this.keyFields = ["packetCode"];
	}

	public getName(): string {
		 return 'packetDetail';
	}
}


export class PacketDetailFd extends FormData {
	constructor(form: PacketDetailForm, sa: ServiceAgent) {
		super(form, sa);
	}
	/**  this form has no editable fields. data nust be accessed as Vo and not through fd **/
}


export interface PacketDetailVo extends Vo {
	valuationRound?: number, 
	isMakeup?: boolean, 
	phoneNumber1?: string, 
	phoneNumber2?: string, 
	assigned2Id?: number, 
	assigned1Id?: number, 
	phoneNumber3?: string, 
	packetCode?: string, 
	offeredSubjectId?: number, 
	assigned3Id?: number, 
	name3?: string, 
	instituteId?: number, 
	name2?: string, 
	name1?: string, 
	subjectName?: string, 
	papers?: CodedPaperVo
}
