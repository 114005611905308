import { Component, Input, Output, EventEmitter, OnInit, ViewChild } from "@angular/core";
import { DynamicPanel, LandingPanel, GroupPanel } from '../../interfaces';
import { ServiceAgent } from 'src/app/framework-modules/formdata/form/serviceAgent';
import { SessionComponent } from '../../component';
import { CtTableComponent, TableMetaData } from 'src/app/framework-modules/elements/ct-table/component';
import { FilterRequest } from 'src/app/framework-modules/formdata/form/types';
import { ClientContext } from 'src/app/framework-modules/formdata/form/clientContext';
import { ProgramLevelListFd, ProgramLevelListForm } from 'src/app/framework-modules/formdata/gen/programLevelListForm';
import { EnrolledStudentDetailFd, EnrolledStudentDetailForm } from 'src/app/framework-modules/formdata/gen/enrolledStudentDetailForm';
import { DegreeLevelListFd, DegreeLevelListForm } from 'src/app/framework-modules/formdata/gen/degreeLevelListForm';


@Component({
  selector: "enrollments-list",
  templateUrl: "./component.html",
  styleUrls: ["./component.scss"]
})


export class EnrollmentsListComponent implements DynamicPanel, OnInit {
  @ViewChild("gridTable", { static: false }) gtable: CtTableComponent;
  @Input() inputData: any;
  @Input() parentPanel: GroupPanel;
  @Input() session: SessionComponent;
  public tableData: TableMetaData;
  public form: DegreeLevelListForm;
  public fd: DegreeLevelListFd;
  public enrollmentFd: EnrolledStudentDetailFd;
  selectedOptions: any;

  constructor(private sa: ServiceAgent, private cc: ClientContext) {
    this.form = DegreeLevelListForm.getInstance();
    this.fd = this.form.newFormData(this.sa);
    this.enrollmentFd = EnrolledStudentDetailForm.getInstance().newFormData(this.sa);
  }

  metaDataInit() {
    this.tableData = {
      data: [],
      metaData: {
        column_names: [
          "name",
          "usn",
          "sectionName"
        ],
        display_names: {
          "name": "Name",
          "usn": "USN",
          "sectionName": "Section"
        },
        edit: false,
        search: true,
        placeholder: "Search By Student Name...",
        view: false,
      },
    };
  }

  ngOnInit() {
    this.metaDataInit();
  }

  getEnrollments(){
    var seasonId = this.fd.getFieldValue('seasonId');
    var degreeId = this.fd.getFieldValue('degreeId');
    var levelId = this.fd.getFieldValue('levelId');
    var levelSectionId = this.fd.getFieldValue('levelSectionId');
    if(seasonId && degreeId && levelId && levelSectionId){
      var seasonId2 = this.fd.getFieldValue('seasonId');
      var degreeId2 = this.fd.getFieldValue('degreeId');
      var levelId2 = this.fd.getFieldValue('levelId');
      var levelSectionId2 = this.fd.getFieldValue('levelSectionId');
      this.selectedOptions = {
        seasonId: seasonId2,
        season: this.fd.getDisplayedValueOf('seasonId'),
        degreeId: degreeId2,
        degree: this.fd.getDisplayedValueOf('degreeId'),
        levelId: levelId2,
        level: this.fd.getDisplayedValueOf('levelId'),
        levelSectionId: levelSectionId2,
        levelSection: this.fd.getDisplayedValueOf('levelSectionId'),
      }
      const f: FilterRequest = {
        conditions: {
          seasonId: {comp:'=', value: seasonId2},
          degreeId: {comp: '=', value: degreeId2},
          levelId: {comp: '=', value: levelId2},
          levelSectionId: {comp: '=', value: levelSectionId2}
        }
      }
      this.enrollmentFd.filter(f).subscribe(
        data => {
          this.tableData.data = data;
          this.gtable.update();
        }
      )
    }
  }

  /**
   * Go to enroll students screen
   */
  enrollStudents(){
    this.parentPanel.navigate('EnrollmentsAdd',this.selectedOptions);
  }

}