import { Component, Input, OnInit, ViewChild } from "@angular/core";
import { ServiceAgent } from 'src/app/framework-modules/formdata/form/serviceAgent';
import { MessageService } from 'src/app/services/messageService';
import { SessionComponent } from '../../component';
import { DynamicPanel, GroupPanel } from '../../interfaces';
import { ApprovalForm, ApprovalFd, ApprovalVo } from 'src/app/framework-modules/formdata/gen/approvalForm';

@Component({
  selector: "authorisation-view",
  templateUrl: "./component.html",
  styleUrls: ["./component.scss"]
})

export class AuthorisationEditComponent implements DynamicPanel, OnInit {

  @ViewChild("details", { static: false })
  @Input() inputData: any;
  @Input() parentPanel: GroupPanel;
  @Input() session: SessionComponent;
  public form: ApprovalForm;
  public fd: ApprovalFd;
  public vo: ApprovalVo = {};
  public isClosed = true;
  
  constructor(private sa: ServiceAgent, private messageService: MessageService) {
    this.form = ApprovalForm.getInstance();
    this.fd = this.form.newFormData(this.sa);
  }

  ngOnInit() {
    if(this.inputData && this.inputData.approvalId && this.inputData.internalRefId){
      this.getData(this.inputData);
    }else{
      this.messageService.showError('Page invoked with invalid data');
    }
  }

  private getData(input){
    this.fd.setFieldValue('approvalId', input.approvalId);
    this.fd.setFieldValue('internalRefId', input.internalRefId);
    this.fd.fetchData().subscribe({
      next: data =>{
        this.vo = data;
        this.isClosed = this.vo.approvalStatus != 'Requested';
      },
      error: msg => {
        this.messageService.showError('Server returned with an error');
      }
    });
  }
  
  goBack() {
    this.parentPanel.navigate('PendingAuthorisation')
  }

  approve(toApprove:boolean) {
    status = toApprove ? 'Approved' : 'Rejected';
    this.fd.setFieldValue('approvalStatus', status);
    this.fd.save().subscribe({
      next: data=>{
        this.messageService.showSuccess('Request ' + status);
        this.goBack()
      },
      error: msg =>{
        this.messageService.showError('Sorry. Server returned with an error. Please try again');
      }
    });
  }
}

