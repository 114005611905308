
import { Form , Field, ChildForm } from '../form/form';
import { FormData } from '../form/formData';
import { SelectOption, Vo } from '../form/types';
import { Validators } from '@angular/forms'
import { ServiceAgent} from '../form/serviceAgent';

export class ValuationActivitiesForm extends Form {
	private static _instance = new ValuationActivitiesForm();

	public static getInstance(): ValuationActivitiesForm {
		return ValuationActivitiesForm._instance;
	}

	constructor() {
		super();
		this.fields = new Map();
		this.controls = new Map();
		this.opsAllowed = {get: true, filter: true};
		this.keyFields = ["instituteId"];
	}

	public getName(): string {
		 return 'valuationActivities';
	}
}


export class ValuationActivitiesFd extends FormData {
	constructor(form: ValuationActivitiesForm, sa: ServiceAgent) {
		super(form, sa);
	}
	/**  this form has no editable fields. data nust be accessed as Vo and not through fd **/
}


export interface ValuationActivitiesVo extends Vo {
	isValuationFrozen?: boolean, 
	currentValuationRound?: number, 
	instituteId?: number, 
	answerPacketPrefix?: string, 
	isGradesCalculated?: boolean, 
	makeupAnswerPacketPrefix?: string, 
	makeupExamInitiated?: boolean
}
