import { Component, OnInit, ViewChild, Input } from "@angular/core";
import { DynamicPanel, GroupPanel } from '../../interfaces';
import { ServiceAgent } from 'src/app/framework-modules/formdata/form/serviceAgent';
import { SessionComponent } from '../../component';
import { MessageService } from 'src/app/services/messageService';
import { CtSpinner } from 'src/app/framework-modules/elements/ct-spinner/component';
import { ExamAttendeeListForm, ExamAttendeeListFd, ExamAttendeeListVo } from 'src/app/framework-modules/formdata/gen/examAttendeeListForm';
import { TableMetaData, CtTableComponent } from 'src/app/framework-modules/elements/ct-table/component';
import { PanelData } from 'src/app/framework-modules/formdata/form/formData';
import { SubjectwiseResultForm, SubjectwiseResultFd, SubjectwiseResultVo } from 'src/app/framework-modules/formdata/gen/subjectwiseResultForm';
import { FilterRequest } from 'src/app/framework-modules/formdata/form/types';
import { OfferedSubjectSelectionForm, OfferedSubjectSelectionFd } from 'src/app/framework-modules/formdata/gen/offeredSubjectSelectionForm';
import { ExcelDownloadService } from 'src/app/services/excelDownloadService';



@Component({
  selector: "subjevtwise-marks",
  templateUrl: "./component.html",
  styleUrls: ["./component.scss"],
})


export class ResultsSubjectComponent implements DynamicPanel, OnInit {
  @ViewChild("gridTable", { static: false }) gtable: CtTableComponent;
  @ViewChild("spinner", { static: false }) spinner: CtSpinner;
  @Input() inputData: any;
  @Input() parentPanel: GroupPanel;
  @Input() session: SessionComponent;

  public form: SubjectwiseResultForm;
  public fd: SubjectwiseResultFd;
  public vo: SubjectwiseResultVo = {};
  public form1: OfferedSubjectSelectionForm;
  public fd1: OfferedSubjectSelectionFd;
  public tableData: TableMetaData;
  panelData: PanelData;

  constructor(private sa: ServiceAgent, private ms: MessageService, private ds: ExcelDownloadService) {
    this.form = SubjectwiseResultForm.getInstance();
    this.fd = this.form.newFormData(this.sa);
    this.form1 = OfferedSubjectSelectionForm.getInstance();
    this.fd1 = this.form1.newFormData(this.sa);
  }
  ngAfterViewInit(): void {
    this.gtable.update();
  }


  ngOnInit() {
    this.metaDataInit()
  }

  /**
   * Event change listener for the offered subhject ID dropdown.
   * @param $event Offfered subject ID
   */
  idChanged($event){
    const filter: FilterRequest ={
      conditions: {
        offeredSubjectId:{comp:"=",value:$event}
      },
      maxRows: 10,
      sort: {studentId:"asc"}
    }
    const obs = this.fd.filter(filter);
    const that = this;
    this.spinner.show("Loading Data.....");
    obs.subscribe(
      data => {
        this.tableData.data = data;
        this.gtable.update();
        this.spinner.hide();
    });
  }

  /**
   * Initializing table metadata.
   */
  metaDataInit() {
    this.tableData = {
      data: [],
      metaData: {
        column_names: [
          "name",
          "usn",
          "departmentName",
          "cieMarks",
          "examMarks",
          "grade",
          "gradePoints"
        ],
        display_names: {
          "name":"Student Name",
          "usn":"USN",
          "departmentName":"Department Name",
          "cieMarks":"CIE",
          "examMarks":"Exam Marks",
          "grade":"Grade",
          "gradePoints":"Grade Points",
        },
        styleAttribute: {
          "grade": [
            {comp: '=', value: 'F', style:{'color':'red', 'font-weight': 800}},
            {comp: '=', value: 'W', style:{'color':'black', 'font-weight': 800}},
            {comp: '=', value: 'X', style:{'color':'green', 'font-weight': 800}},
           ]
        },
        search: true,
        placeholder: "Search By Student Name, USN and Department"
      }
    };
  }

  /**
   * Downloads the excel of the students and their result enrolled in the selected subject.
   */
  doDownload(){
    var data = []
    this.tableData.data.forEach(row =>{
      data.push({
        "Name": row["name"],
        "USN": row["usn"],
        "Department Name": row["departmentName"],
        "CIE Marks": row["cieMarks"],
        "Exam Marks": row["examMarks"],
        "Grade": row["grade"],
        "Grade Points": row["gradePoints"]
      })
    });
    this.ds.downloadExcel({Results:data},this.fd1.getDisplayedValueOf('departmentId')+ " - "+this.fd1.getDisplayedValueOf('offeredSubjectId') + " Results")
  
  }
}