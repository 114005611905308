
import { Form , Field, ChildForm } from '../form/form';
import { FormData } from '../form/formData';
import { SelectOption, Vo } from '../form/types';
import { Validators } from '@angular/forms'
import { ServiceAgent} from '../form/serviceAgent';

export class SubjectRegisterForm extends Form {
	private static _instance = new SubjectRegisterForm();

	public static getInstance(): SubjectRegisterForm {
		return SubjectRegisterForm._instance;
	}

	constructor() {
		super();
		this.fields = new Map();
		this.controls = new Map();
		this.opsAllowed = {get: true, filter: true};
		this.keyFields = ["subjectRegisterId"];
	}

	public getName(): string {
		 return 'subjectRegister';
	}
}


export class SubjectRegisterFd extends FormData {
	constructor(form: SubjectRegisterForm, sa: ServiceAgent) {
		super(form, sa);
	}
	/**  this form has no editable fields. data nust be accessed as Vo and not through fd **/
}


export interface SubjectRegisterVo extends Vo {
	cieMarks?: number, 
	effectiveMarks?: number, 
	subjectRegisterId?: number, 
	hasAttendedExam?: boolean, 
	isEligibleToWithdraw?: boolean, 
	hasWithdrawn?: boolean, 
	isAuthorizedAbsence?: boolean, 
	subjectId?: number, 
	studentId?: number, 
	createdAt?: string, 
	isGraced?: boolean, 
	examMarks?: number, 
	hasAttendanceShortage?: boolean, 
	seasonId?: number, 
	subjectSectionId?: number, 
	gracedByUser?: number, 
	hasCieShortage?: boolean, 
	updatedAt?: string, 
	isCondoned?: boolean, 
	overallAttendance?: string, 
	hasAttendedMakeup?: boolean, 
	isEligibleForExam?: boolean, 
	gracedMarks?: number, 
	totalAttendance?: number, 
	isEligibleForMakeup?: boolean, 
	hasOptedForMakeup?: boolean, 
	sectionId?: number, 
	isFinalGrade?: boolean, 
	offeredSubjectId?: number, 
	totalClasses?: number, 
	condonedByUser?: number, 
	isCondonable?: boolean, 
	grade?: string, 
	instituteId?: number, 
	hasMalpracticed?: boolean
}
