import { Component,Input, Output, EventEmitter, OnInit } from "@angular/core";
import { DynamicPanel, LandingPanel, GroupPanel } from '../../interfaces';
import { ServiceAgent } from 'src/app/framework-modules/formdata/form/serviceAgent';
import { SessionComponent } from '../../component';
import { FormData } from 'src/app/framework-modules/formdata/form/formData';
import { InstituteForm, InstituteVo, InstituteFd } from 'src/app/framework-modules/formdata/gen/instituteForm';
import { MessageService } from 'src/app/services/messageService';
import { CtDialogComponent } from 'src/app/framework-modules/elements/ct-dialog/component';
import { MatDialog } from '@angular/material';


@Component({
  selector: "institute-add",
  templateUrl: "./component.html",
  styleUrls: ["./component.scss"]
})


export class InstituteAddComponent implements DynamicPanel,OnInit{
  @Input() inputData: any;
  @Input() parentPanel: GroupPanel;
  @Input() session:SessionComponent;
  
  public form:InstituteForm;
  public fd:InstituteFd;
  public vo:InstituteVo;
  
  constructor(private sa:ServiceAgent, private ms: MessageService, private dialog: MatDialog){
    this.form = InstituteForm.getInstance();
    this.fd = new FormData(this.form,this.sa);
    this.fd.setFieldValue('trustId',1);
    this.fd.setFieldValue('instituteId',5);
    this.fd.setFieldValue('isActive',false);

  }

  ngOnInit(){
  }

  save(){
    let dialogRef = this.dialog.open(CtDialogComponent);
    dialogRef.componentInstance.title = 'Save Changes';
    dialogRef.componentInstance.content = 'Are you sure you want to save changes?';
    dialogRef.componentInstance.primary = 'Save';
    const subscribeDialog = dialogRef.componentInstance.save.subscribe((data) => {
      this.dialog.closeAll();
      this.doAddInstitute();
    });
  }
  /**
   * Adds a new Institute to the client's trust
   */
  doAddInstitute() {
    this.fd.saveAsNew().subscribe({
      next: vo => {
      this.parentPanel.navigate("InstituteList");
      this.ms.showSuccess("New Institute has been added succesfully!")
      },
      error: err => {
        this.ms.showError(err);
      }
    });
  }

  cancel(){
    this.parentPanel.navigate("InstituteList");
  }

  doCancel() {
    let dialogRef = this.dialog.open(CtDialogComponent);
    dialogRef.componentInstance.title = 'Discard Changes';
    dialogRef.componentInstance.content = 'Are you sure you want to discard changes?';
    dialogRef.componentInstance.primary = 'Discard';
    const subscribeDialog = dialogRef.componentInstance.save.subscribe((data) => {
      this.dialog.closeAll();
      this.parentPanel.navigate("InstituteList");
    }); 
  }

}