
import { Form , Field, ChildForm } from '../form/form';
import { FormData } from '../form/formData';
import { SelectOption, Vo } from '../form/types';
import { Validators } from '@angular/forms'
import { ServiceAgent} from '../form/serviceAgent';

export class MakeUpPacketListForm extends Form {
	private static _instance = new MakeUpPacketListForm();

	public static getInstance(): MakeUpPacketListForm {
		return MakeUpPacketListForm._instance;
	}

	constructor() {
		super();
		this.fields = new Map();
		this.controls = new Map();
		this.opsAllowed = {filter: true};
	}

	public getName(): string {
		 return 'makeUpPacketList';
	}
}


export class MakeUpPacketListFd extends FormData {
	constructor(form: MakeUpPacketListForm, sa: ServiceAgent) {
		super(form, sa);
	}
	/**  this form has no editable fields. data nust be accessed as Vo and not through fd **/
}


export interface MakeUpPacketListVo extends Vo {
	offeredSubjectId?: number, 
	phoneNumber?: string, 
	valuationRound?: number, 
	name?: string, 
	instituteId?: number, 
	assignedTo?: number, 
	valuationType?: number, 
	packetCode?: string
}
