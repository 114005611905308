
import { Form , Field, ChildForm } from '../form/form';
import { FormData } from '../form/formData';
import { SelectOption, Vo } from '../form/types';
import { Validators } from '@angular/forms'
import { ServiceAgent} from '../form/serviceAgent';
import { ExamRegisterForm, ExamRegisterVo } from './examRegisterForm';

export class ExamAttendanceForm extends Form {
	private static _instance = new ExamAttendanceForm();

	students: ChildForm = {
		name:'students',
		form:ExamRegisterForm.getInstance(),
		isEditable:false,
		isTabular:true,
		label:'',
		minRows:0,
		maxRows:0,
		errorId:null
	};

	public static getInstance(): ExamAttendanceForm {
		return ExamAttendanceForm._instance;
	}

	constructor() {
		super();
		this.fields = new Map();
		this.controls = new Map();

		this.childForms = new Map();
		this.childForms.set('students', this.students);
		this.opsAllowed = {get: true, update: true};
		this.keyFields = ["offeredSubjectId"];
	}

	public getName(): string {
		 return 'examAttendance';
	}
}


export class ExamAttendanceFd extends FormData {
	constructor(form: ExamAttendanceForm, sa: ServiceAgent) {
		super(form, sa);
	}
	/**  this form has no editable fields. data nust be accessed as Vo and not through fd **/
}


export interface ExamAttendanceVo extends Vo {
	name?: string, 
	offeredSubjectId?: number, 
	isMakeup?: string, 
	subjectId?: number, 
	students?: ExamRegisterVo
}
