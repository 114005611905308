import { Component, Input, Output, EventEmitter, OnInit, ViewChild } from "@angular/core";
import { DynamicPanel, LandingPanel, GroupPanel } from '../../interfaces';
import { ServiceAgent } from 'src/app/framework-modules/formdata/form/serviceAgent';
import { SessionComponent } from '../../component';
import { CtTableComponent, TableMetaData } from 'src/app/framework-modules/elements/ct-table/component';
import { FilterRequest } from 'src/app/framework-modules/formdata/form/types';
import { ClientContext } from 'src/app/framework-modules/formdata/form/clientContext';
import { AssessmentSchemeListForm, AssessmentSchemeListFd } from 'src/app/framework-modules/formdata/gen/assessmentSchemeListForm';


@Component({
  selector: "assessment-scheme-list",
  templateUrl: "./component.html",
  styleUrls: ["./component.scss"]
})


export class AssessmentSchemeListComponent implements DynamicPanel, OnInit {
  @ViewChild("gridTable", { static: false }) gtable: CtTableComponent;
  @Input() inputData: any;
  @Input() parentPanel: GroupPanel;
  @Input() session: SessionComponent;
  public tableData: TableMetaData;

  // Form to fetch list of Assessment Schemes
  public assessmentSchemeForm : AssessmentSchemeListForm;
  public assessmentSchemeFd : AssessmentSchemeListFd;

  /**
   * Creates a instance of the all the above forms  
   */
  constructor(private sa: ServiceAgent, private cc: ClientContext) {
    this.assessmentSchemeForm = AssessmentSchemeListForm.getInstance();
    this.assessmentSchemeFd = this.assessmentSchemeForm.newFormData(this.sa);
  }

  /**
   * To initialize metaData of table
   */
  metaDataInit() {
    this.tableData = {
      data: [],
      metaData: {
        column_names: [
          "name",
          "code",
          "minCieMarks"
        ],
        display_names: {
          "name": "Assessment Scheme Name",
          "code": "Assessment Scheme Code",
          "minCieMarks": "Minimum CIE Marks"
        },
        edit: false,
        search: true,
        placeholder: "Search By Assessment Scheme Name,Code...",
        view: false,
      },
    };
  }

  /**
   * It is called when the directive is instantiated.
   */
  ngOnInit() {
    this.metaDataInit();
    this.filter();
  }

  /**
   * To Fetch List of Assessment Schemes based on instituteId
   */
  filter(){
    const f: FilterRequest = {
      conditions: {
        instituteId: {value:this.cc.getInstituteId(), comp:'='}
      }
    }
    this.assessmentSchemeFd.filter(f).subscribe(
      data => {
        this.tableData.data = data;
        this.gtable.update();
      },
      msgs => {
        console.error(msgs);

      }
    );
  }

  /**
   * Go to Add Assessment Screen screen
   */
  addAssessmentScheme(){
    this.parentPanel.navigate('AssessmentSchemeAdd');
  }

}