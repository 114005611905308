
import { Form , Field, ChildForm } from '../form/form';
import { FormData } from '../form/formData';
import { SelectOption, Vo } from '../form/types';
import { Validators } from '@angular/forms'
import { ServiceAgent} from '../form/serviceAgent';

export class PdcListTempUgForm extends Form {
	private static _instance = new PdcListTempUgForm();
	pdcTempUgId:Field = {
		name:'pdcTempUgId'
		,controlType: 'Hidden'
		,label: 'pdcTempUgId'
		,valueType: 1
		,errorId: 'invalidId'
		,maxValue: 9999999999999
	};
	instituteId:Field = {
		name:'instituteId'
		,controlType: 'Hidden'
		,label: 'instituteId'
		,valueType: 1
		,errorId: 'invalidTenentKey'
		,maxValue: 9999999999999
	};
	date:Field = {
		name:'date'
		,controlType: 'Input'
		,label: 'Date'
		,isRequired: true
		,valueType: 0
		,errorId: 'invalidText'
		,maxLength: 1000
	};
	studentName:Field = {
		name:'studentName'
		,controlType: 'Input'
		,label: 'Student Name'
		,isRequired: true
		,valueType: 0
		,errorId: 'invalidText'
		,maxLength: 1000
	};
	programName:Field = {
		name:'programName'
		,controlType: 'Input'
		,label: 'Program Name'
		,isRequired: true
		,valueType: 0
		,errorId: 'invalidText'
		,maxLength: 1000
	};
	pdcNo:Field = {
		name:'pdcNo'
		,controlType: 'Input'
		,label: 'Pdc No.'
		,isRequired: true
		,valueType: 0
		,errorId: 'invalidText'
		,maxLength: 1000
	};
	usn:Field = {
		name:'usn'
		,controlType: 'Input'
		,label: 'USN'
		,isRequired: true
		,valueType: 0
		,errorId: 'invalidText'
		,maxLength: 1000
	};
	departmentName:Field = {
		name:'departmentName'
		,controlType: 'Input'
		,label: 'Department Name'
		,isRequired: true
		,valueType: 0
		,errorId: 'invalidText'
		,maxLength: 1000
	};
	cgpa:Field = {
		name:'cgpa'
		,controlType: 'Input'
		,label: 'cgpa'
		,isRequired: true
		,valueType: 2
		,errorId: 'invalidDecimal'
		,nbrFractions: 4
		,maxValue: 1000
	};

	public static getInstance(): PdcListTempUgForm {
		return PdcListTempUgForm._instance;
	}

	constructor() {
		super();
		this.fields = new Map();
		this.controls = new Map();
		this.controls.set('pdcTempUgId', [Validators.max(9999999999999)]);
		this.fields.set('pdcTempUgId', this.pdcTempUgId);
		this.controls.set('instituteId', [Validators.max(9999999999999)]);
		this.fields.set('instituteId', this.instituteId);
		this.controls.set('date', [Validators.required, Validators.maxLength(1000)]);
		this.fields.set('date', this.date);
		this.controls.set('studentName', [Validators.required, Validators.maxLength(1000)]);
		this.fields.set('studentName', this.studentName);
		this.controls.set('programName', [Validators.required, Validators.maxLength(1000)]);
		this.fields.set('programName', this.programName);
		this.controls.set('pdcNo', [Validators.required, Validators.maxLength(1000)]);
		this.fields.set('pdcNo', this.pdcNo);
		this.controls.set('usn', [Validators.required, Validators.maxLength(1000)]);
		this.fields.set('usn', this.usn);
		this.controls.set('departmentName', [Validators.required, Validators.maxLength(1000)]);
		this.fields.set('departmentName', this.departmentName);
		this.controls.set('cgpa', [Validators.required, Validators.max(1000)]);
		this.fields.set('cgpa', this.cgpa);
		this.opsAllowed = {get: true, update: true, filter: true};
		this.keyFields = ["pdcTempUgId"];
	}

	public getName(): string {
		 return 'pdcListTempUg';
	}
}


export class PdcListTempUgFd extends FormData {
	constructor(form: PdcListTempUgForm, sa: ServiceAgent) {
		super(form, sa);
	}

	setFieldValue(name: 'pdcTempUgId' | 'instituteId' | 'date' | 'studentName' | 'programName' | 'pdcNo' | 'usn' | 'departmentName' | 'cgpa', value: string | number | boolean | null ): void {
		super.setFieldValue(name, value);
	}

	getFieldValue(name: 'pdcTempUgId' | 'instituteId' | 'date' | 'studentName' | 'programName' | 'pdcNo' | 'usn' | 'departmentName' | 'cgpa' ): string | number | boolean | null {
		return super.getFieldValue(name);
	}
}


export interface PdcListTempUgVo extends Vo {
	pdcTempUgId?: number, 
	date?: string, 
	departmentName?: string, 
	usn?: string, 
	createdAt?: string, 
	programName?: string, 
	studentName?: string, 
	instituteId?: number, 
	pdcNo?: string, 
	cgpa?: number, 
	updatedAt?: string
}
