
import { Form , Field, ChildForm } from '../form/form';
import { FormData } from '../form/formData';
import { SelectOption, Vo } from '../form/types';
import { Validators } from '@angular/forms'
import { ServiceAgent} from '../form/serviceAgent';
import { OfferedSubjectRegisterDetailForm, OfferedSubjectRegisterDetailVo } from './offeredSubjectRegisterDetailForm';

export class ExamMarksSubjectForm extends Form {
	private static _instance = new ExamMarksSubjectForm();
	offeredSubjectId:Field = {
		name:'offeredSubjectId'
		,controlType: 'Hidden'
		,label: 'offeredSubjectId'
		,valueType: 1
		,errorId: 'invalidId'
		,maxValue: 9999999999999
	};

	students: ChildForm = {
		name:'students',
		form:OfferedSubjectRegisterDetailForm.getInstance(),
		isEditable:false,
		isTabular:true,
		label:'',
		minRows:0,
		maxRows:0,
		errorId:null
	};

	public static getInstance(): ExamMarksSubjectForm {
		return ExamMarksSubjectForm._instance;
	}

	constructor() {
		super();
		this.fields = new Map();
		this.controls = new Map();
		this.controls.set('offeredSubjectId', [Validators.max(9999999999999)]);
		this.fields.set('offeredSubjectId', this.offeredSubjectId);

		this.childForms = new Map();
		this.childForms.set('students', this.students);
		this.opsAllowed = {get: true, filter: true, update: true};
		this.keyFields = ["offeredSubjectId"];
	}

	public getName(): string {
		 return 'examMarksSubject';
	}
}


export class ExamMarksSubjectFd extends FormData {
	constructor(form: ExamMarksSubjectForm, sa: ServiceAgent) {
		super(form, sa);
	}

	setFieldValue(name: 'offeredSubjectId', value: string | number | boolean | null ): void {
		super.setFieldValue(name, value);
	}

	getFieldValue(name: 'offeredSubjectId' ): string | number | boolean | null {
		return super.getFieldValue(name);
	}
}


export interface ExamMarksSubjectVo extends Vo {
	maxCieMarks?: number, 
	degreeId?: number, 
	examDate?: string, 
	departmentId?: number, 
	attendanceFrozen?: boolean, 
	cieFrozen?: boolean, 
	subjectId?: number, 
	seeWeightage?: number, 
	requires2?: boolean, 
	offeredSubjectId?: number, 
	createdAt?: string, 
	maxSeeMarks?: number, 
	seasonId?: number, 
	levelId?: number, 
	instituteId?: number, 
	isIntegrated?: boolean, 
	cieWeightage?: number, 
	assessmentSchemeId?: number, 
	isChild?: boolean, 
	updatedAt?: string, 
	students?: OfferedSubjectRegisterDetailVo
}
