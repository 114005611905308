
import { Form , Field, ChildForm } from '../form/form';
import { FormData } from '../form/formData';
import { SelectOption, Vo } from '../form/types';
import { Validators } from '@angular/forms'
import { ServiceAgent} from '../form/serviceAgent';

export class AssessmentSchemeListForm extends Form {
	private static _instance = new AssessmentSchemeListForm();
	instituteId:Field = {
		name:'instituteId'
		,controlType: 'Hidden'
		,label: 'instituteId'
		,valueType: 1
		,errorId: 'invalidTenentKey'
		,maxValue: 9999999999999
	};
	assessmentSchemeId:Field = {
		name:'assessmentSchemeId'
		,controlType: 'Hidden'
		,label: 'assessmentSchemeId'
		,valueType: 1
		,errorId: 'invalidId'
		,maxValue: 9999999999999
	};
	name:Field = {
		name:'name'
		,controlType: 'Output'
		,label: 'Scheme Name'
		,valueType: 0
		,errorId: 'invalidName'
		,maxLength: 150
	};
	code:Field = {
		name:'code'
		,controlType: 'Output'
		,label: 'Scheme Code'
		,valueType: 0
		,defaultValue: ''
		,errorId: 'invalidCode'
		,maxLength: 50
	};
	cieCalculation:Field = {
		name:'cieCalculation'
		,controlType: 'Output'
		,label: 'Cie Calculation Formulae'
		,valueType: 0
		,defaultValue: ''
		,errorId: 'invalidText'
		,maxLength: 1000
	};
	minCieMarks:Field = {
		name:'minCieMarks'
		,controlType: 'Output'
		,label: 'Mimimum CIE Marks'
		,valueType: 1
		,defaultValue: 0
		,errorId: 'invalidInteger'
		,maxValue: 9999999999999
	};
	isActive:Field = {
		name:'isActive'
		,controlType: 'Output'
		,label: 'isActive'
		,valueType: 3
		,defaultValue: true
		,errorId: 'invalidBool'
	};

	public static getInstance(): AssessmentSchemeListForm {
		return AssessmentSchemeListForm._instance;
	}

	constructor() {
		super();
		this.fields = new Map();
		this.controls = new Map();
		this.controls.set('instituteId', [Validators.max(9999999999999)]);
		this.fields.set('instituteId', this.instituteId);
		this.controls.set('assessmentSchemeId', [Validators.max(9999999999999)]);
		this.fields.set('assessmentSchemeId', this.assessmentSchemeId);
		this.controls.set('name', [Validators.maxLength(150)]);
		this.fields.set('name', this.name);
		this.controls.set('code', [Validators.maxLength(50)]);
		this.fields.set('code', this.code);
		this.controls.set('cieCalculation', [Validators.maxLength(1000)]);
		this.fields.set('cieCalculation', this.cieCalculation);
		this.controls.set('minCieMarks', [Validators.max(9999999999999)]);
		this.fields.set('minCieMarks', this.minCieMarks);
		this.controls.set('isActive', []);
		this.fields.set('isActive', this.isActive);
		this.opsAllowed = {get: true, filter: true, update: true, create: true, delete: true};
		this.keyFields = ["assessmentSchemeId"];
	}

	public getName(): string {
		 return 'assessmentSchemeList';
	}
}


export class AssessmentSchemeListFd extends FormData {
	constructor(form: AssessmentSchemeListForm, sa: ServiceAgent) {
		super(form, sa);
	}

	setFieldValue(name: 'instituteId' | 'assessmentSchemeId' | 'name' | 'code' | 'cieCalculation' | 'minCieMarks' | 'isActive', value: string | number | boolean | null ): void {
		super.setFieldValue(name, value);
	}

	getFieldValue(name: 'instituteId' | 'assessmentSchemeId' | 'name' | 'code' | 'cieCalculation' | 'minCieMarks' | 'isActive' ): string | number | boolean | null {
		return super.getFieldValue(name);
	}
}


export interface AssessmentSchemeListVo extends Vo {
	minCieMarks?: number, 
	createdAt?: string, 
	code?: string, 
	cieCalculation?: string, 
	name?: string, 
	instituteId?: number, 
	assessmentSchemeId?: number, 
	isActive?: boolean, 
	updatedAt?: string
}
