
import { Form , Field, ChildForm } from '../form/form';
import { FormData } from '../form/formData';
import { SelectOption, Vo } from '../form/types';
import { Validators } from '@angular/forms'
import { ServiceAgent} from '../form/serviceAgent';

export class ExamRegisterForm extends Form {
	private static _instance = new ExamRegisterForm();

	public static getInstance(): ExamRegisterForm {
		return ExamRegisterForm._instance;
	}

	constructor() {
		super();
		this.fields = new Map();
		this.controls = new Map();
		this.opsAllowed = {get: true, update: true, filter: true};
		this.keyFields = ["subjectSectionId"];
	}

	public getName(): string {
		 return 'examRegister';
	}
}


export class ExamRegisterFd extends FormData {
	constructor(form: ExamRegisterForm, sa: ServiceAgent) {
		super(form, sa);
	}
	/**  this form has no editable fields. data nust be accessed as Vo and not through fd **/
}


export interface ExamRegisterVo extends Vo {
	hasAttendedMakeup?: boolean, 
	overallAttendance?: string, 
	subjectRegisterId?: number, 
	level?: string, 
	hasAttendedExam?: boolean, 
	isEligibleToWithdraw?: boolean, 
	hasWithdrawn?: boolean, 
	isAuthorizedAbsence?: boolean, 
	hasOptedForMakeup?: boolean, 
	studentId?: number, 
	offeredSubjectId?: number, 
	usn?: string, 
	subjectSectionId?: number, 
	name?: string, 
	instituteId?: number, 
	department?: string, 
	hasAttended?: boolean
}
