import { Component, OnInit, ViewChild, Input } from "@angular/core";
import { DynamicPanel, GroupPanel } from '../../interfaces';
import { ServiceAgent } from 'src/app/framework-modules/formdata/form/serviceAgent';
import { CtTableComponent, TableMetaData, } from '../../../../framework-modules/elements/ct-table/component'
import { FilterRequest } from 'src/app/framework-modules/formdata/form/types';
import { SessionComponent } from '../../component';
import { CtSpinner } from 'src/app/framework-modules/elements/ct-spinner/component';
import { MessageService } from 'src/app/services/messageService';
import { EvaluatorSelectionForm, EvaluatorSelectionFd } from 'src/app/framework-modules/formdata/gen/evaluatorSelectionForm';
import { PacketStatusFd, PacketStatusForm } from "src/app/framework-modules/formdata/gen/packetStatusForm";


@Component({
  selector: "packet-list-search",
  templateUrl: "./component.html",
  styleUrls: ["./component.scss"]
})

export class PacketListStatusComponent implements DynamicPanel, OnInit {
  @ViewChild("gridTable", { static: false }) gtable: CtTableComponent;
  @ViewChild("spinner", { static: false }) public spinner: CtSpinner;
  @Input() inputData: any;
  @Input() parentPanel: GroupPanel;
  @Input() session: SessionComponent;  
  public form: EvaluatorSelectionForm;
  public fd: EvaluatorSelectionFd;
  public tableData: TableMetaData;
  public form1: PacketStatusForm;
  public fd1: PacketStatusFd;


  constructor(private sa: ServiceAgent, private ms: MessageService) {
    this.form = EvaluatorSelectionForm.getInstance();
    this.fd = this.form.newFormData(this.sa);
    this.form1 = PacketStatusForm.getInstance();
    this.fd1 = this.form1.newFormData(this.sa);
  }


  ngOnInit() {
    this.metaDataInit();
  }

  metaDataInit() {
    this.tableData = {
      data: [],
      metaData: {
        column_names: [
          "packetCode",
          "subjectName"
        ],
        display_names: {
          packetCode: "Packet",
          subjectName: "Subject"
        },
        search: true,
        placeholder: "Search By Packet Code, Subject Name.....",
      }
    };
  }

  getData(){
    this.spinner.show("Getting Papers....");
    var valuationType = this.fd.getFieldValue('valuationType');
    var filter: FilterRequest;
    if(valuationType == 1){
      filter = { conditions: { notCompleted1 : { comp:'=', value: true } } }
    }
    else if(valuationType == 2){
      filter = { conditions: { notCompleted2 : { comp:'=', value: true } } }
    }
    else {
      filter = { conditions: { notCompleted3 : { comp:'=', value: true } } }
    }
    this.fd1.filter(filter).subscribe(
      data =>{
        this.tableData.data = data;
        this.gtable.update();
        this.spinner.hide()
      },
      err =>{
        this.spinner.hide();
      }
    )
  }
  
}
