import { Component, OnInit, ViewChild, Input, AfterViewInit } from "@angular/core";
import { TableMetaData, CtTableComponent } from 'src/app/framework-modules/elements/ct-table/component';
import { GroupPanel } from '../../interfaces';
import { SessionComponent } from '../../component';
import { CtSpinner } from 'src/app/framework-modules/elements/ct-spinner/component';
import { PacketDetailForm, PacketDetailVo } from 'src/app/framework-modules/formdata/gen/packetDetailForm';
import { ServiceAgent } from 'src/app/framework-modules/formdata/form/serviceAgent';
import { MessageService } from 'src/app/services/messageService';
import { PanelData } from 'src/app/framework-modules/formdata/form/formData';
import { CodedPaperVo } from 'src/app/framework-modules/formdata/gen/codedPaperForm';
import { ClientContext } from 'src/app/framework-modules/formdata/form/clientContext';
import { PaperMarksEntryVo } from 'src/app/framework-modules/formdata/gen/paperMarksEntryForm';
import { ExcelDownloadService } from "src/app/services/excelDownloadService";

@Component({
  selector: 'makeup-valuation-entry',
  templateUrl: "./component.html",
  styleUrls: ["./component.scss"]
})
export class MakeUpValuationViewComponent implements OnInit, AfterViewInit {
  @ViewChild("gridTable", { static: false }) gtable: CtTableComponent;
  @ViewChild("spinner", { static: false }) spinner: CtSpinner;
  @Input() inputData: any;
  @Input() parentPanel: GroupPanel;
  @Input() session: SessionComponent;

  private panelData: PanelData;
  public vo: PacketDetailVo = {};
  public papers: CodedPaperVo[] = [];

  public tableData: TableMetaData;
  public papersExist = false;
  /**
   * set to true if the logged-in user is authorized to edit themarks
   */
  public okToEdit = false;
  public rowBeingEdited : CodedPaperVo  = null;
  /**
   * when beingEdited=true, editableData must be set to teh right values to be sent to the child-panel
   */
  public editableData: { subjectRegisterId: number, paperCode: string } = null;
  cameFromScreen: any;

  constructor(private sa: ServiceAgent, 
    private ms: MessageService, 
    private cc: ClientContext,
    private ds: ExcelDownloadService
    ) {
    this.panelData = PacketDetailForm.getInstance().newFormData(this.sa);
    //TODO: pageId or pageName?? what should we check for 
    //this.okToEdit = cc.isPageValid('D106');
    this.okToEdit = true;
  }

  ROUND1_NAMES = ["paperCode", "marks1", "requires2x", "marksSecondVal", "marksMod", "requires3x", "marks3", "effectiveMarks"];
  ROUND1_TITLES = ["Code", "Marks", "2nd Required?", "2nd Valuation", "Moderation", "3rd Required?", "3rd Valuation", "Effective Marks"];
  NAMES = ["paperCode", "marks1", "marks2", "requires3x", "marks3", "effectiveMarks"];
  TITLES = ["Code", "Current", "2nd Valuation", "3rd Required?", "3rd Valuation", "Effective Marks"];
  ngOnInit() {
    this.metaDataInit();
  }
  ngAfterViewInit() {
    this.cameFromScreen = this.inputData.screen;
    this.inputData = this.inputData.data;
    if (this.inputData && this.inputData.packetCode) {
      this.getData(this.inputData);
    } else {
      this.ms.showError('Page invoked with invalid data');
    }
  }
  metaDataInit() {
    this.tableData = {
      data: [],
      metaData: {
        column_names: [
          "paperCode",
        ],
        display_names: {
          paperCode: "Paper Code",
        },
        styleAttribute : {
          "requires2x": [
            {comp:'=',value: "YES",style:{color: "red", 'font-weight': 600}}
          ],
          "requires3x": [
            {comp:'=',value: "YES",style:{color: "red", 'font-weight': 600}}
          ],
          "effectiveMarks": [
            {comp:'=',value:true,dependentCol:"secondVal",style:{color: '#004FAA', 'font-weight': 600}},
            {comp:'=',value:true,dependentCol:"moderation",style:{color: 'green', 'font-weight': 600}}
          ]
        }
      }
    };
    if(this.okToEdit){
      this.tableData.metaData.edit = true;
      this.tableData.metaData.buttonName = 'Edit Marks';
    }
  }

  private getData(data) {
    this.spinner.show('Getting papers ..');
    this.panelData.fetchFor(data).subscribe({

      next: data => {
        this.vo = data;
        const list = data.papers as [];
        this.papers = list;
        if (list && list.length) {
          this.setData(list);
        } else {
          this.spinner.hide();
          this.ms.showError('No papers for this packet');
        }
      },

      error: msgs => {
        this.spinner.hide();
        this.ms.showError('Server returned with errors..');
      }
    });
  }

  private setData(list: any[]) {
    let names = this.NAMES;
    let titles = this.TITLES;
    if (this.vo.valuationRound == 1) {
      names = this.ROUND1_NAMES;
      titles = this.ROUND1_TITLES;
    }
    const obj = {};
    for (let i = 0; i < names.length; i++) {
      obj[names[i]] = titles[i];
    }
    list.forEach(element =>{
      element["requires2"] ? element["requires2x"] = "YES" : element["requires2x"] = "NO";
      element["requires3"] ? element["requires3x"] = "YES" : element["requires3x"] = "NO";
      element["secondVal"] = element["requires2"] && (element["marks2"] != -1);
      element["moderation"] = !element["requires2"] && (element["marks2"] != -1);
      if(element["moderation"]){
        element["marksMod"] = element["marks2"]
        element["marksSecondVal"] = "-"
      }
      else{
        element["marksMod"] = "-"
        element["marksSecondVal"] = element["marks2"] == -1 ? "-" : element["marks2"];
      }
      element["marks1"] = ( element["marks1"] == -1 ? "-" : element["marks1"])
      element["marks2"] = ( element["marks2"] == -1 ? "-" : element["marks2"])
      element["marks3"] = ( element["marks3"] == -1 ? "-" : element["marks3"])
    });
    this.tableData.metaData.column_names = names;
    this.tableData.metaData.display_names = obj;
    this.tableData.data = list;
    this.gtable.update(this.spinner);
  }

  private saveData() {
    this.spinner.show('Saving marks..');
    this.panelData.save().subscribe({
      next: data => {
        this.spinner.hide();
        this.ms.showSuccess('Marks saved');
        this.goBack();
      },

      error: msgs => {
        this.spinner.hide();
        this.ms.showError('Server returned with errors..');
      }
    });
  }

  public editPaper(rowId) {
    const vo = this.papers[rowId];
    this.editableData = {
      paperCode:vo.paperCode,
      subjectRegisterId:vo.subjectRegisterId
    }
    this.rowBeingEdited = vo;
    // do we need to do anything for  angular to the magic of hiding current panel and show editable panel??
  }

  public editPacket() {
    this.parentPanel.navigate("MakeUpValuationEdit", this.inputData);
  }
  private goBack() {
    this.parentPanel.navigate(this.cameFromScreen);
  }

  public doneEditing(newMarks:PaperMarksEntryVo){
    console.log('doneEditing() triggered', newMarks);
    if(newMarks){
      this.rowBeingEdited.marks1 = newMarks.marks1;
      this.rowBeingEdited.marks2 = newMarks.marks2;
      this.rowBeingEdited.marks3 = newMarks.marks3;
      this.rowBeingEdited.effectiveMarks = newMarks.effectiveMarks;
      this.rowBeingEdited.requires2 = newMarks.requires2;
      this.rowBeingEdited.requires3 = newMarks.requires3;
      this.rowBeingEdited["requires2"] ? this.rowBeingEdited["requires2x"] = "YES" : this.rowBeingEdited["requires2x"] = "NO";
      this.rowBeingEdited["requires3"] ? this.rowBeingEdited["requires3x"] = "YES" : this.rowBeingEdited["requires3x"] = "NO";
      this.rowBeingEdited["secondVal"] = this.rowBeingEdited["requires2"] && (this.rowBeingEdited["marks2"] != 0);
      this.rowBeingEdited["moderation"] = !this.rowBeingEdited["requires2"] && (this.rowBeingEdited["marks2"] != 0);
      if(this.rowBeingEdited["moderation"]){
        this.rowBeingEdited["marksMod"] = this.rowBeingEdited["marks2"]
        this.rowBeingEdited["marksSecondVal"] = "-"
      }
      else{
        this.rowBeingEdited["marksMod"] = "-"
        this.rowBeingEdited["marksSecondVal"] = this.rowBeingEdited["marks2"] == 0 ? "-" : this.rowBeingEdited["marks2"];
      }
      // update used to have some issue if done repeatedly!!
      // if that persists, we have to set everything all over again.
      this.gtable.update();
    }else{
      console.info('Paper marks edit cancelled by user');
    }
    this.rowBeingEdited = null;
  }

  download(){
    var excelData = []
    this.tableData.data.forEach(d => {
      var row = {}
      this.tableData.metaData.column_names.forEach(col =>{
        row[this.tableData.metaData.display_names[col]] = d[col]
      });
      excelData.push(row);
    });
    var metaData = {};
    metaData["Count"] = excelData;
    this.ds.downloadExcelBook(metaData,this.inputData.packetCode + "-Status");
  }
}