import { Component, OnInit, ViewChild, Input, AfterViewInit } from "@angular/core";
import { DynamicPanel, GroupPanel } from '../../interfaces';
import { CtTableComponent, TableMetaData } from '../../../../framework-modules/elements/ct-table/component'
import { SessionComponent } from '../../component';
import { ServiceAgent } from 'src/app/framework-modules/formdata/form/serviceAgent';
import { PanelData } from 'src/app/framework-modules/formdata/form/formData';
import { FilterRequest } from 'src/app/framework-modules/formdata/form/types';
import { MessageService } from 'src/app/services/messageService';
import { CtSpinner } from 'src/app/framework-modules/elements/ct-spinner/component';
import { EvaluatorSelectionForm, EvaluatorSelectionFd } from 'src/app/framework-modules/formdata/gen/evaluatorSelectionForm';
import { CurrentEvaluatorDetailFd, CurrentEvaluatorDetailForm } from 'src/app/framework-modules/formdata/gen/currentEvaluatorDetailForm';
import { ValuationActivitiesForm } from 'src/app/framework-modules/formdata/gen/valuationActivitiesForm';
import { PdfDownloadService } from "src/app/services/pdfDownloadService";
import { ClientContext } from "src/app/framework-modules/formdata/form/clientContext";
import { MakeUpPacketListForm } from 'src/app/framework-modules/formdata/gen/makeUpPacketListForm';
@Component({
  selector: "makeup-assign-evaluators-list",
  templateUrl: "./component.html",
  styleUrls: ["./component.scss"]
})
export class MakeUpAssignPacketsListComponent implements DynamicPanel, OnInit, AfterViewInit {
  @ViewChild("gridTable", { static: false }) gtable: CtTableComponent;
  @ViewChild("spinner", { static: false }) spinner: CtSpinner;
  @Input() inputData: any;
  @Input() parentPanel: GroupPanel;
  @Input() session: SessionComponent;

  public tableData: TableMetaData;
  /**
   * form for header drop-down fields that are used to get the
   */
  public form: EvaluatorSelectionForm;
  public fd: EvaluatorSelectionFd;

  private selectedId: boolean = false;

  public form1: CurrentEvaluatorDetailForm;
  public fd1:CurrentEvaluatorDetailFd;

  /**
   * for getting list of packtes assigned to this evaluator
   */
  private pd: PanelData;
  private valuationActivitiesPd: PanelData;

  constructor(private sa: ServiceAgent, private ms: MessageService, private ps :PdfDownloadService, private cc: ClientContext) {
    this.form = EvaluatorSelectionForm.getInstance();
    this.fd = this.form.newFormData(this.sa);
    this.pd = MakeUpPacketListForm.getInstance().newPanelData(this.sa);
    this.valuationActivitiesPd =ValuationActivitiesForm.getInstance().newFormData(this.sa);
    this.form1 = CurrentEvaluatorDetailForm.getInstance();
    this.fd1 = this.form1.newFormData(this.sa);
  }

  ngOnInit() {
    this.metaDataInit();
  }

  ngAfterViewInit(){
    this.getSeason();
  }
  /**
   * Table Metadata initialization
   */
  metaDataInit() {
    this.tableData = {
      data: [] = [],
      metaData: {
        column_names: [
          "packetCode"
        ],
        display_names: {
          packetCode: "Packet Code"
        },
        search: true,
        edit:true,
        placeholder: "Search on Packet Code",
        buttonName:"Download Valuation Sheet"
      }
    };
  }

  /**
   * we need to toget the valuation round.
   * This will be made available in the context at some time.
   * This method is temp till that time
   */
  private getSeason(){
    this.fd.setFieldValue('valuationRound', parseInt(this.cc.getValue('_user').currentValuationRound));
  }


  getData(id) {

    /**
     * offeredSubjectId and 
     */
    const offerId = this.fd.getFieldValue('offeredSubjectId');
    const evalId = this.fd.getFieldValue('evaluatorId');
    const vType = this.fd.getFieldValue('valuationType');
    if (!offerId || !evalId || !vType) {
      this.ms.showError('Please select values for all drop-downs');
      return;
    }

    const filter: FilterRequest = {
      conditions: {
        offeredSubjectId: { value: offerId, comp: '=' },
        assignedTo: { value: evalId, comp: '=' },
        valuationType: {value: vType, comp: '='}
      }
    };

    this.spinner.show('Getting assigned packets..');
    this.pd.filter(filter).subscribe({
      next: list => {
        this.selectedId = true;
        this.tableData.data = list;
        this.gtable.update(this.spinner);
      },
      error: msgs => {
        this.spinner.hide();
        this.ms.showError('server returned with errors');
      }
    });
  }

  /**
   * Navigate to Evaluator Edit Screen
   */
  edit() {
    const d = {
      evaluatorId: this.fd.getFieldValue('evaluatorId'),
      offeredSubjectId: this.fd.getFieldValue('offeredSubjectId'),
      valuationType: this.fd.getFieldValue('valuationType'),
      valuationRound: this.fd.getFieldValue('valuationRound'),
      isMakeUp: true
    };
    this.parentPanel.navigate("MakeUpAssignPacketsEdit", d);
  }


  public doDownload($event){
    var valuationType = this.fd.getFieldValue('valuationType');
    this.sa.serve("get_currentEvaluatorDetail",{ data: { packetCode: this.tableData.data[$event].packetCode as string, valuationRound: this.tableData.data[$event].valuationRound, offeredSubjectId: this.fd.getFieldValue('offeredSubjectId')}}).subscribe(
      (data) => {
        this.ps.downloadValuationSheet(data,valuationType,data.packetCode);
      },
      (err) => {
        console.log(err);
      }
    );
  }
}