import { Component, OnInit, ViewChild, Input } from "@angular/core";
import { GroupPanel } from '../../interfaces';
import { ServiceAgent } from 'src/app/framework-modules/formdata/form/serviceAgent';
import { SessionComponent } from '../../component';
import { TableMetaData, CtTableComponent } from 'src/app/framework-modules/elements/ct-table/component';
import { CtSpinner } from 'src/app/framework-modules/elements/ct-spinner/component';
import { CtDialogComponent } from 'src/app/framework-modules/elements/ct-dialog/component';
import { MatDialog } from '@angular/material';
import { MessageService } from 'src/app/services/messageService';
import { ExcelDownloadService } from 'src/app/services/excelDownloadService';
import { DegreeLevelListFd, DegreeLevelListForm } from 'src/app/framework-modules/formdata/gen/degreeLevelListForm';

@Component({
  selector: "degree-grade-calculation",
  templateUrl: "./component.html",
  styleUrls: ["./component.scss"]
})


export class DegreeGradeCalculationComponent implements OnInit {
  @ViewChild("gridTable", { static: false }) gtable: CtTableComponent;
  @ViewChild("spinner", { static: false }) public spinner: CtSpinner;
  @Input() inputData: any;
  @Input() parentPanel: GroupPanel;
  @Input() session: SessionComponent;

  form: DegreeLevelListForm;
  fd: DegreeLevelListFd;


  constructor(private sa: ServiceAgent, private ms: MessageService, private dialog: MatDialog, private ds: ExcelDownloadService) {
    this.form = DegreeLevelListForm.getInstance();
    this.fd = this.form.newFormData(this.sa);
  }

  ngOnInit() {
  }

 

  /**
   * opens dialog to confirm calculation
   */
  calculate() {
    let dialogRef = this.dialog.open(CtDialogComponent);
    dialogRef.componentInstance.title = 'Calculate Grades';
    dialogRef.componentInstance.content = 'Are you sure you want calculate grades?';
    dialogRef.componentInstance.primary = 'Calculate';
    const subscribeDialog = dialogRef.componentInstance.save.subscribe((data) => {
      this.dialog.closeAll();
      this.doCalculate();
    });
  }

  private doCalculate(){
    this.spinner.show("Calculating Grades......");
    const payload = {
      degreeId:this.fd.getFieldValue('degreeId'),
      levelId: this.fd.getFieldValue('levelId')
    };
    this.sa.serve("calculateGradesForDegree",{data:payload}).subscribe({
      next: data => {
        this.ms.showSuccess("Grades successfully calculated");
        this.spinner.hide();
      },
      error: err =>{
        this.spinner.hide();
      }
    })
  }

}