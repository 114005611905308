import { MenuService } from 'src/app/services/menuService';
import { Component, OnInit, Input, ViewChild, ViewContainerRef, Type, ComponentFactoryResolver } from "@angular/core";
import { DynamicPanel, GroupPanel } from '../interfaces'
import { SessionComponent, SecondaryMenu } from '../component';
import { StaffListComponent } from './staff-list/component';
import { StaffEditComponent } from './staff-edit/component';
import { StaffAddComponent } from './staff-add/component';
import { DesignationListComponent } from './designation-list/component';
import { ClientContext } from 'src/app/framework-modules/formdata/form/clientContext';
import { MessageService } from 'src/app/services/messageService';
import { DesignationAddComponent } from './designation-add/component';
import { DesignationEditComponent } from './designation-edit/component';



/**
 Const to map the screen-component to screen-name to rended the facotry
 */
const MENU: SecondaryMenu = {
  StaffList: {
    comp: StaffListComponent,
    title: "Staff List",
    name: "Staff List",
    sideNav: true,
    pageId: "D28",
    displayonSidenav: true,
    mainNav: true,
    order: 1
  },
  StaffAdd: {
    comp: StaffAddComponent,
    title: "Staff Add",
    name: "Staff Add",
    sideNav: false,
    pageId: "D29",
    displayonSidenav: false,
    mainNav: false
  },

  StaffEdit: {
    comp: StaffEditComponent,
    title: "Edit Staff",
    name: "Edit Staff",
    sideNav: false,
    pageId: "D30",
    displayonSidenav: false,
    mainNav: false,

  },
  DesignationList: {
    comp: DesignationListComponent,
    title: "Designation List",
    name: "Designation List",
    sideNav: true,
    pageId: "D80",
    displayonSidenav: true,
    mainNav: true,
    order: 2
  },
  DesignationAdd: {
    comp: DesignationAddComponent,
    title: "Designation Add",
    name: "Designation Add",
    sideNav: false,
    pageId: "D81",
    displayonSidenav: false,
    mainNav: false
  },
  DesignationEdit: {
    comp: DesignationEditComponent,
    title: "Designation Edit",
    name: "Designation Edit",
    sideNav: false,
    pageId: "D82",
    displayonSidenav: false,
    mainNav: false
  }
  
};

@Component({
  selector: "Staffs",
  templateUrl: "./component.html",
  styleUrls: ["./component.scss"]
})
export class StaffComponent implements OnInit, GroupPanel {
  @Input() inputData: any;
  @Input() parentPanel: SessionComponent;
  menu: { [key: string]: Type<DynamicPanel> };
  public sidenav: boolean = false;
  displayNames: {}
  public items: {};
  cardHeader: any;
  public defaultSelected: string;
  pScreens: Array<string>;
  screens: string[];
  defaultComponent: string;

  /**
    container for the child page loaded from the seconday navbar
   */

  @ViewChild("secondaryContainer", { static: true, read: ViewContainerRef }) container: ViewContainerRef;
  constructor(private componentFactoryResolver: ComponentFactoryResolver,
    private cc: ClientContext, 
    private ms: MessageService, private mns: MenuService) {
      this.screens = this.mns.getScreens();
      Object.keys(MENU).forEach( key => {
      if(this.screens.includes(MENU[key].pageId) && this.defaultSelected == undefined){
        this.defaultSelected = MENU[key].name;
        this.cardHeader = MENU[key].title;
        this.defaultComponent = key;
      }
    })
  }

  ngOnInit() {
    this.navigate(this.defaultComponent, {});
    /**
     * providing menu items to the items array from the MENU
     */
    this.items = MENU;
  }

  /**
   *
   * @param compName is the name of the component to navigate to
   * @param data is for any related data needed
   */

  public navigate(compName: string, data: any): void {
    const m = MENU[compName];
    if (!m) {
      this.ms.showInfo('Navigation Error: ' + compName + ' is not a valid page name.');
      return;
    }

    if(!this.cc.isPageValid(m.pageId)){
      this.ms.showInfo('Navigation Error: ' + compName + ' is not authorized for this user.');
      return;
    }

    this.parentPanel.toggleMainNav(m.mainNav)
    this.sidenav = m.sideNav;

    if (m.name != undefined) {
      this.defaultSelected = m.name;
    }

    this.cardHeader = m.title;
    this.container.clear();
    const factory = this.componentFactoryResolver.resolveComponentFactory(
      m.comp
    );

    const c = this.container.createComponent(factory);
    c.instance.inputData = data;
    c.instance.parentPanel = this;
    c.changeDetectorRef.detectChanges();
  }

  fullScreenMode() {
    this.sidenav = false;
    this.parentPanel.hideMainNav();
  }

  halfScreenMode() {
    this.sidenav = true;
    this.parentPanel.showMainNav();
  }
}
