
import { Form , Field, ChildForm } from '../form/form';
import { FormData } from '../form/formData';
import { SelectOption, Vo } from '../form/types';
import { Validators } from '@angular/forms'
import { ServiceAgent} from '../form/serviceAgent';

export class ForgotPasswordForm extends Form {
	private static _instance = new ForgotPasswordForm();
	userName:Field = {
		name:'userName'
		,controlType: 'Input'
		,label: 'Email'
		,isRequired: true
		,valueType: 0
		,errorId: 'invalidEmail'
		,maxLength: 1000
	};
	otp:Field = {
		name:'otp'
		,controlType: 'Password'
		,label: 'Enter OTP'
		,isRequired: true
		,valueType: 0
		,errorId: 'invalidText'
		,maxLength: 1000
	};
	newPassword:Field = {
		name:'newPassword'
		,controlType: 'Password'
		,label: 'New Password'
		,isRequired: true
		,valueType: 0
		,errorId: 'invalidText'
		,maxLength: 1000
	};
	confirmPassword:Field = {
		name:'confirmPassword'
		,controlType: 'Password'
		,label: 'Re-enter New Password'
		,isRequired: true
		,valueType: 0
		,errorId: 'invalidText'
		,maxLength: 1000
	};

	public static getInstance(): ForgotPasswordForm {
		return ForgotPasswordForm._instance;
	}

	constructor() {
		super();
		this.serveGuests = true;
		this.fields = new Map();
		this.controls = new Map();
		this.controls.set('userName', [Validators.required, Validators.email, Validators.maxLength(1000)]);
		this.fields.set('userName', this.userName);
		this.controls.set('otp', [Validators.required, Validators.maxLength(1000)]);
		this.fields.set('otp', this.otp);
		this.controls.set('newPassword', [Validators.required, Validators.maxLength(1000)]);
		this.fields.set('newPassword', this.newPassword);
		this.controls.set('confirmPassword', [Validators.required, Validators.maxLength(1000)]);
		this.fields.set('confirmPassword', this.confirmPassword);
		this.opsAllowed = {update: true};
	}

	public getName(): string {
		 return 'forgotPassword';
	}
}


export class ForgotPasswordFd extends FormData {
	constructor(form: ForgotPasswordForm, sa: ServiceAgent) {
		super(form, sa);
	}

	setFieldValue(name: 'userName' | 'otp' | 'newPassword' | 'confirmPassword', value: string | number | boolean | null ): void {
		super.setFieldValue(name, value);
	}

	getFieldValue(name: 'userName' | 'otp' | 'newPassword' | 'confirmPassword' ): string | number | boolean | null {
		return super.getFieldValue(name);
	}
}


export interface ForgotPasswordVo extends Vo {
	newPassword?: string, 
	confirmPassword?: string, 
	otp?: string, 
	userName?: string
}
