import { Component,Input, Output, EventEmitter, OnInit } from "@angular/core";
import { DynamicPanel, LandingPanel, GroupPanel } from '../../interfaces';
import { ServiceAgent } from 'src/app/framework-modules/formdata/form/serviceAgent';
import { SessionComponent } from '../../component';
import { FormData } from 'src/app/framework-modules/formdata/form/formData';
import { LevelForm, LevelVo } from 'src/app/framework-modules/formdata/gen/levelForm';


@Component({
  selector: "level-list",
  templateUrl: "./component.html",
  styleUrls: ["./component.scss"]
})


export class LevelListComponent implements DynamicPanel,OnInit{
  @Input() inputData: any;
  @Input() parentPanel: GroupPanel;
  @Input() session:SessionComponent;
  
  public form:LevelForm;
  public fd:FormData;
  public vo:LevelVo;
  public buttonText: string;
  public levelsExist: boolean = false;

  // levels: Array<string> = ["Level 1","Level 2","Level 3","Level 4","Level 5","Level 6","Level 1","Level 2","Level 3","Level 4","Level 5","Level 6", "Level 5"];
  levels: Array<string> = ["Level 1","Level 2","Level 3","Level 4","Level 5","Level 6","Level 1","Level 2","Level 3","Level 4","Level 5","Level 6", "Level 5","Level 6","Level 1","Level 2","Level 3","Level 4","Level 5","Level 6"];
  
  constructor(private sa:ServiceAgent){
    this.form = LevelForm.getInstance();
    this.fd = new FormData(this.form,this.sa);
  }

  ngOnInit(){
    this.buttonText = "ADD LEVELS";
    this.metaDataInit();
  }

  metaDataInit(){
      
  }

  goBack(){
    this.session.navigate("InstituteList");
  }

  addLevel(){
    this.levelsExist = !this.levelsExist;
    this.buttonText = "EDIT";
  }

}