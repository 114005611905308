
import { Form , Field, ChildForm } from '../form/form';
import { FormData } from '../form/formData';
import { SelectOption, Vo } from '../form/types';
import { Validators } from '@angular/forms'
import { ServiceAgent} from '../form/serviceAgent';
import { TranscriptSemesterDetailForm, TranscriptSemesterDetailVo } from './transcriptSemesterDetailForm';
import { TranscriptSubjectDetailForm, TranscriptSubjectDetailVo } from './transcriptSubjectDetailForm';
import { PreviousSemesterGPADetailForm, PreviousSemesterGPADetailVo } from './previousSemesterGPADetailForm';

export class TranscriptForm extends Form {
	private static _instance = new TranscriptForm();
	studentId:Field = {
		name:'studentId'
		,controlType: 'Hidden'
		,label: 'studentId'
		,isRequired: true
		,valueType: 1
		,defaultValue: -1
		,errorId: 'invalidFlexibleId'
		,minValue: -1
		,maxValue: 9999999999999
	};

	semesterDetails: ChildForm = {
		name:'semesterDetails',
		form:TranscriptSemesterDetailForm.getInstance(),
		isEditable:false,
		isTabular:true,
		label:'',
		minRows:0,
		maxRows:0,
		errorId:null
	};
	subjectDetails: ChildForm = {
		name:'subjectDetails',
		form:TranscriptSubjectDetailForm.getInstance(),
		isEditable:false,
		isTabular:true,
		label:'',
		minRows:0,
		maxRows:0,
		errorId:null
	};
	previousSemesterGPADetails: ChildForm = {
		name:'previousSemesterGPADetails',
		form:PreviousSemesterGPADetailForm.getInstance(),
		isEditable:false,
		isTabular:true,
		label:'',
		minRows:0,
		maxRows:0,
		errorId:null
	};

	public static getInstance(): TranscriptForm {
		return TranscriptForm._instance;
	}

	constructor() {
		super();
		this.fields = new Map();
		this.controls = new Map();
		this.controls.set('studentId', [Validators.required, Validators.min(-1), Validators.max(9999999999999)]);
		this.fields.set('studentId', this.studentId);

		this.childForms = new Map();
		this.childForms.set('semesterDetails', this.semesterDetails);
		this.childForms.set('subjectDetails', this.subjectDetails);
		this.childForms.set('previousSemesterGPADetails', this.previousSemesterGPADetails);
		this.opsAllowed = {get: true, filter: true};
		this.keyFields = ["studentId"];
	}

	public getName(): string {
		 return 'transcript';
	}
}


export class TranscriptFd extends FormData {
	constructor(form: TranscriptForm, sa: ServiceAgent) {
		super(form, sa);
	}

	setFieldValue(name: 'studentId', value: string | number | boolean | null ): void {
		super.setFieldValue(name, value);
	}

	getFieldValue(name: 'studentId' ): string | number | boolean | null {
		return super.getFieldValue(name);
	}
}


export interface TranscriptVo extends Vo {
	departmentName?: string, 
	levelSectionId?: number, 
	degreeId?: number, 
	departmentId?: number, 
	studentTypeId?: number, 
	section?: string, 
	degreeName?: string, 
	studentId?: number, 
	usn?: string, 
	phoneNumber?: string, 
	programName?: string, 
	courseDuration?: string, 
	levelId?: number, 
	name?: string, 
	instituteId?: number, 
	semester?: string, 
	programId?: number, 
	semesterDetails?: TranscriptSemesterDetailVo, 
	subjectDetails?: TranscriptSubjectDetailVo, 
	previousSemesterGPADetails?: PreviousSemesterGPADetailVo
}
