import { Component, OnInit, ViewChild, Input, AfterViewInit } from "@angular/core";
import { DynamicPanel, GroupPanel } from '../../interfaces';
import { ServiceAgent } from 'src/app/framework-modules/formdata/form/serviceAgent';
import { CtTableComponent, TableMetaData } from '../../../../framework-modules/elements/ct-table/component'
import { SessionComponent } from '../../component';
import { RegisterStudentForm, RegisterStudentFd, RegisterStudentVo } from 'src/app/framework-modules/formdata/gen/registerStudentForm';
import { MessageService } from 'src/app/services/messageService';
import { RegisterableStudentVo } from 'src/app/framework-modules/formdata/gen/registerableStudentForm';
import { MatDialog } from '@angular/material';
import { CtDialogComponent } from 'src/app/framework-modules/elements/ct-dialog/component';
import { FilterRequest, Vo } from 'src/app/framework-modules/formdata/form/types';
import { ExcelParserService } from "src/app/services/excelParserService";
import { CtSpinner } from "src/app/framework-modules/elements/ct-spinner/component";

@Component({
  selector: "student-register-edit",
  templateUrl: "./component.html",
  styleUrls: ["./component.scss"]
})


export class RegisterStudentEditComponent implements DynamicPanel, OnInit {
  @ViewChild("CtSpinner", { static: false }) public CtSpinner: CtSpinner;
  @ViewChild("gridTable", { static: false }) gtable: CtTableComponent;
  @Input() inputData: any;
  @Input() parentPanel: GroupPanel;
  @Input() session: SessionComponent;
  public tableData: TableMetaData;
  public form: RegisterStudentForm;
  public fd: RegisterStudentFd;
  public vo: RegisterStudentVo;
  public students1: RegisterableStudentVo[];

  constructor(private sa: ServiceAgent, private ms: MessageService, private dialog:MatDialog, private ep: ExcelParserService) {
    this.form = RegisterStudentForm.getInstance();
    this.fd = this.form.newFormData(this.sa);
    this.vo = this.fd.getRawData();
  }

  ngOnInit() { 

    this.metaDataInit();
    /**
     * get data for the subject-section 
     */
    this.fd.setFieldValue("subjectSectionId", this.inputData);
    this.fd.fetchData().subscribe({
      next: vo => {
        this.vo = this.fd.getRawData();
        this.tableData.data = vo.students as Vo[];
        this.gtable.update();
        /*
         * TODO: set selected rows to the collection...
         */
      },
      error: msg => console.error("Error from server ", msg)
    });
  }

  metaDataInit() {
    this.tableData = {
      data: [],
      metaData: {
        column_names: [
          "name",
          "usn",
          "department",
        ],
        display_names: {
          "name": "Student Name",
          "usn": "Student USN",
          "department": "Student Department"
        },
        edit: false,
        search: true,
        placeholder: "Search By Student Name, USN, Temporary USN, Department",
        selectAttr:'isRegistered',
        managerFunction: "REGISTER STUDENTS",
        itemName: "Students"
      }
    };
  }

  register() {
    let dialogRef = this.dialog.open(CtDialogComponent);
    dialogRef.componentInstance.title = 'Save Changes';
    dialogRef.componentInstance.content = 'Are you sure you want to save changes?';
    dialogRef.componentInstance.primary = 'Save';
    const subscribeDialog = dialogRef.componentInstance.save.subscribe((data) => {
      this.dialog.closeAll();
      this.fd.save().subscribe({
        next: vo => {
          this.ms.showSuccess('Success ! Students Registered to ' + this.vo.subjectName + ' Successfully.');
          this.parentPanel.navigate('RegisterStudentList');
        },
        error: msg => {
          this.ms.showError("Please select atleast one staff member!");
        }
      });
    });
    
  }
  cancel() {
    let dialogRef = this.dialog.open(CtDialogComponent);
    dialogRef.componentInstance.title = 'Discard Changes';
    dialogRef.componentInstance.content = 'Are you sure you want to discard changes?';
    dialogRef.componentInstance.primary = 'Discard';
    const subscribeDialog = dialogRef.componentInstance.save.subscribe((data) => {
      this.dialog.closeAll();
    this.parentPanel.navigate('RegisterStudentList')
    });
  }

  onFileChange(ev){
    this.CtSpinner.show("Uploading....");
    this.ep.parseUSN(ev,"Registration").subscribe(
      data =>{
        this.fillData(data);
      },
      err =>{
        this.CtSpinner.hide();
      }
    );
  }

  /**
   * usnMap = {usn:index};
   * @param data has {usn:true}
   */
   private fillData(data:{}){
    var usnMap: {[key:string]:any} = {};
    this.tableData.data.forEach((element,index) =>{
      usnMap[element["usn"] as string] = index;
    });
    let allUsn = Object.keys(usnMap), count = 0;
    Object.keys(data).forEach(key =>{
      if(usnMap[key] == undefined){
        return
      }
      count++;
      let index = usnMap[key];
      this.tableData.data[index]["isRegistered"] = true;
    });
    this.ms.showSuccess("Selected " + count + " students!");
    this.CtSpinner.hide();
    this.gtable.update();
  }
}