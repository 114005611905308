
import { Form , Field, ChildForm } from '../form/form';
import { FormData } from '../form/formData';
import { SelectOption, Vo } from '../form/types';
import { Validators } from '@angular/forms'
import { ServiceAgent} from '../form/serviceAgent';

export class PacketStatusForm extends Form {
	private static _instance = new PacketStatusForm();

	public static getInstance(): PacketStatusForm {
		return PacketStatusForm._instance;
	}

	constructor() {
		super();
		this.fields = new Map();
		this.controls = new Map();
		this.opsAllowed = {get: true, filter: true};
		this.keyFields = ["packetCode"];
	}

	public getName(): string {
		 return 'packetStatus';
	}
}


export class PacketStatusFd extends FormData {
	constructor(form: PacketStatusForm, sa: ServiceAgent) {
		super(form, sa);
	}
	/**  this form has no editable fields. data nust be accessed as Vo and not through fd **/
}


export interface PacketStatusVo extends Vo {
	offeredSubjectId?: number, 
	seasonId?: number, 
	instituteId?: number, 
	notCompleted1?: boolean, 
	notCompleted2?: boolean, 
	packetCode?: string, 
	subjectName?: string, 
	notCompleted3?: boolean
}
