import { MenuService } from 'src/app/services/menuService';
import { Component, OnInit, Input, ViewChild, ViewContainerRef, Type, ComponentFactoryResolver } from "@angular/core";
import { DynamicPanel, LandingPanel, GroupPanel } from '../interfaces'
import { SessionComponent, SecondaryMenu } from '../component';
import { SubjectListComponent } from './subject-list/component';
import { SubjectAddComponent } from './subject-add/component';
import { SubjectEditComponent } from './subject-edit/component';
import { OfferSubjectListComponent } from './offerSubject-list/component';
import { OfferSubjectEditComponent } from './offerSubject-edit/component';
import { RegisterStudentListComponent } from './registerStudent-list/component';
import { RegisterStudentEditComponent } from './registerStudent-edit/component';
import { AssignStaffComponent } from './assignStaff-list/component';
import { AssignStaffEditComponent } from './assignStaff-edit/component';
import { TestTypeSelectionComponent } from './testTypeSelection-list/component';
import { MessageService } from 'src/app/services/messageService';
import { ClientContext } from 'src/app/framework-modules/formdata/form/clientContext';
import { FirstYearOfferList } from './firstYearOffer-list/component';
import { AutoRegisterListComponent } from './auto-register/component';
import { FirstYearOfferEditComponent } from './firstYearOffer-edit/component';
import { ManualRegisterListComponent } from './manual-register/component';
import { ManualRegisterEditComponent } from './manualRegister-edit/component';
import { AutoRegisterEditComponent } from './autoRegister-edit/component';

/**
 Const to map the screen-component to screen-name to rended the facotry
 */
const MENU:SecondaryMenu = {
  SubjectList: { comp: SubjectListComponent, title: "List of Subjects", name: "Subjects List", sideNav: true, pageId: "D36", displayonSidenav: true, mainNav: true, order:1 },
  SubjectAdd: { comp: SubjectAddComponent, title: "Add a new subject", sideNav: false, pageId: "D37", displayonSidenav: false, mainNav: false },
  SubjectEdit: { comp: SubjectEditComponent, title: "Edit subject details", sideNav: false, pageId: "D38", displayonSidenav: false, mainNav: false },
  OfferSubjectList: { comp: OfferSubjectListComponent, title: "List of offered subjects", sideNav: true, name: "Offer Subjects", pageId: "D39", displayonSidenav: true, mainNav: true, order:2 },
  FirstYearRegisterList: { comp: FirstYearOfferList, title: "First Year Register", sideNav: true, name: "First Year Register", pageId: "D143", displayonSidenav: true, mainNav: true, order:3 },
  FirstYearRegisterEdit :{comp: FirstYearOfferEditComponent, title: "First Year Register", sideNav: false, name: "First Year Register", pageId: "D144", displayonSidenav: false, mainNav: false},
  AutoRegister: { comp: AutoRegisterListComponent, title: "Auto Register", sideNav: true, name: "Auto Register", pageId: "D145", displayonSidenav: true, mainNav: true, order:4 },
  AutoRegisterEdit : { comp: AutoRegisterEditComponent, title: "Auto Register", sideNav: false, name: "Auto Register", pageId: "D146", displayonSidenav: false, mainNav: false},
  ManualRegister: { comp: ManualRegisterListComponent, title: "Manual Register", sideNav: true, name: "Manual Register", pageId: "D147", displayonSidenav: true, mainNav: true, order:5 },
  ManualRegisterEdit: { comp: ManualRegisterEditComponent, title: "Manual Register", sideNav: false, name: "Manual Register", pageId: "D148", displayonSidenav: false, mainNav: false},
  OfferSubjectEdit: { comp: OfferSubjectEditComponent, title: "Select Section", sideNav: false, name: "Offer Subjects", pageId: "D40", displayonSidenav: false, mainNav: false },
  TestTypeAllocationList: { comp: TestTypeSelectionComponent,title: "Allocate Test Types for subject",sideNav: true,pageId: "D15",displayonSidenav: true,name: "Test Type Allocation",mainNav: true, order:6 },
  RegisterStudentList: { comp: RegisterStudentListComponent, title: "List of registered students", sideNav: true, name: "Register Students", pageId: "D41", displayonSidenav: true, mainNav: true, order:7 },
  RegisterStudentEdit: { comp: RegisterStudentEditComponent, title: "List of Unregistered students", sideNav: false, pageId: "D42", displayonSidenav: false, mainNav: false},
  AssignStaff: { comp: AssignStaffComponent, title: "List of subjects assigned", sideNav: true, name: "Assign Staff(s)", pageId: "D43", displayonSidenav: true, mainNav: true, order:8 },
  AssignStaffEdit: { comp: AssignStaffEditComponent, title: "Assign subjects to Staff", sideNav: false, pageId: "D44", displayonSidenav: false, mainNav: false },
};

@Component({
  selector: "Subjects",
  templateUrl: "./component.html",
  styleUrls: ["./component.scss"]
})
export class SubjectComponent implements OnInit, GroupPanel {
  @Input() inputData: any;
  @Input() parentPanel: SessionComponent;
  menu: { [key: string]: Type<DynamicPanel> };
  sidenav: boolean = false;
  displayNames: {}
  cardHeader: any;
  public items: {};
  public defaultSelected: string;
  pScreens: Array<string>;
  screens: string[];
  defaultComponent: any;
  /**
    container for the child page loaded from the seconday navbar
   */

  @ViewChild("secondaryContainer", { static: true, read: ViewContainerRef }) container: ViewContainerRef;
  constructor(private componentFactoryResolver: ComponentFactoryResolver, private mns: MenuService, private ms: MessageService, private cc: ClientContext) {
    this.pScreens = this.mns.getPermittedScreens();
    this.screens = this.mns.getScreens();
      Object.keys(MENU).forEach( key => {
      if(this.screens.includes(MENU[key].pageId) && this.defaultSelected == undefined){
        this.defaultSelected = MENU[key].name;
        this.cardHeader = MENU[key].title;
        this.defaultComponent = key;
      }
    })
  }

  ngOnInit() {
    this.navigate(this.defaultComponent, {});
    this.items = MENU;
  }

  /**
   *
   * @param compName is the name of the component to navigate to
   * @param data is for any related data needed
   */

  public navigate(compName: string, data: any): void {
    let m = MENU[compName];
    this.parentPanel.toggleMainNav(m.mainNav)
    if(!this.cc.isPageValid(m.pageId)){
      this.ms.showInfo('Navigation Error: ' + compName + ' is not authorized for this user.');
      return;
    }
    if (this.pScreens.indexOf(m.pageId) > -1) {
      this.sidenav = m.sideNav;
      if (m.name != undefined) {
        this.defaultSelected = m.name;
      }
      this.cardHeader = m.title;
      this.container.clear();
      const factory = this.componentFactoryResolver.resolveComponentFactory(
        m.comp
      );
      const c = this.container.createComponent(factory);
      c.instance.inputData = data;
      c.instance.parentPanel = this;
      c.changeDetectorRef.detectChanges();
    }
  }

  fullScreenMode(){
    this.sidenav = false;
    this.parentPanel.hideMainNav();
  }

  halfScreenMode(){
    this.sidenav = true;
    this.parentPanel.showMainNav();
  }
}
