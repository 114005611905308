
import { Form , Field, ChildForm } from '../form/form';
import { FormData } from '../form/formData';
import { SelectOption, Vo } from '../form/types';
import { Validators } from '@angular/forms'
import { ServiceAgent} from '../form/serviceAgent';

export class HallTicketDownloadForm extends Form {
	private static _instance = new HallTicketDownloadForm();
	isMakeup:Field = {
		name:'isMakeup'
		,controlType: 'Checkbox'
		,label: 'Is Makeup'
		,isRequired: true
		,valueType: 3
		,errorId: 'invalidBool'
	};
	title:Field = {
		name:'title'
		,controlType: 'Input'
		,label: 'Hallticket Title'
		,isRequired: true
		,valueType: 0
		,errorId: 'invalidText'
		,maxLength: 1000
	};

	public static getInstance(): HallTicketDownloadForm {
		return HallTicketDownloadForm._instance;
	}

	constructor() {
		super();
		this.fields = new Map();
		this.controls = new Map();
		this.controls.set('isMakeup', [Validators.required]);
		this.fields.set('isMakeup', this.isMakeup);
		this.controls.set('title', [Validators.required, Validators.maxLength(1000)]);
		this.fields.set('title', this.title);
		this.opsAllowed = {get: true, filter: true};
	}

	public getName(): string {
		 return 'hallTicketDownload';
	}
}


export class HallTicketDownloadFd extends FormData {
	constructor(form: HallTicketDownloadForm, sa: ServiceAgent) {
		super(form, sa);
	}

	setFieldValue(name: 'isMakeup' | 'title', value: string | number | boolean | null ): void {
		super.setFieldValue(name, value);
	}

	getFieldValue(name: 'isMakeup' | 'title' ): string | number | boolean | null {
		return super.getFieldValue(name);
	}
}


export interface HallTicketDownloadVo extends Vo {
	instituteId?: number, 
	title?: string, 
	isMakeup?: boolean
}
