
import { Form , Field, ChildForm } from '../form/form';
import { FormData } from '../form/formData';
import { SelectOption, Vo } from '../form/types';
import { Validators } from '@angular/forms'
import { ServiceAgent} from '../form/serviceAgent';

export class InstituteConfigurationForm extends Form {
	private static _instance = new InstituteConfigurationForm();

	public static getInstance(): InstituteConfigurationForm {
		return InstituteConfigurationForm._instance;
	}

	constructor() {
		super();
		this.fields = new Map();
		this.controls = new Map();
		this.opsAllowed = {get: true, create: true, update: true, filter: true, delete: true};
		this.keyFields = ["instituteId"];
	}

	public getName(): string {
		 return 'instituteConfiguration';
	}
}


export class InstituteConfigurationFd extends FormData {
	constructor(form: InstituteConfigurationForm, sa: ServiceAgent) {
		super(form, sa);
	}
	/**  this form has no editable fields. data nust be accessed as Vo and not through fd **/
}


export interface InstituteConfigurationVo extends Vo {
	autoRollOverEnrollment?: boolean, 
	minMarksForAutoreval?: number, 
	answerPacketSeqWidth?: number, 
	currentSeason?: number, 
	autoSubjectRegistration?: boolean, 
	nbrPapersPerPacket?: number, 
	maxMarksForAutoreval?: number, 
	minAttendanceForEligibility?: number, 
	maxPapersForAutoreval?: number, 
	minAttendanceForExemption?: number, 
	resultsAnnounced?: boolean, 
	acceptableMarksDeviation?: number, 
	autoRollOverSubjects?: boolean, 
	instituteId?: number, 
	instituteTypeId?: number
}
