import { Component, Input, OnInit, ViewChild, AfterViewInit } from "@angular/core";
import { DynamicPanel, GroupPanel } from '../../interfaces';
import { ServiceAgent } from 'src/app/framework-modules/formdata/form/serviceAgent';
import { ClientContext } from 'src/app/framework-modules/formdata/form/clientContext';
import { CtSpinner } from 'src/app/framework-modules/elements/ct-spinner/component';
import { CtTableComponent, TableMetaData } from 'src/app/framework-modules/elements/ct-table/component';
import { ProgramLevelListFd, ProgramLevelListForm } from 'src/app/framework-modules/formdata/gen/programLevelListForm';
import { OfferedSubjectDetailFd, OfferedSubjectDetailForm } from 'src/app/framework-modules/formdata/gen/offeredSubjectDetailForm';
import { SeasonSubjectListFd, SeasonSubjectListForm } from 'src/app/framework-modules/formdata/gen/seasonSubjectListForm';
import { ExcelDownloadService } from 'src/app/services/excelDownloadService';
import { AdmissionQuotaStudentForm, AdmissionQuotaStudentFd } from 'src/app/framework-modules/formdata/gen/admissionQuotaStudentForm';
import { QuotaWiseGradeStatisticsForm, QuotaWiseGradeStatisticsFd } from 'src/app/framework-modules/formdata/gen/quotaWiseGradeStatisticsForm';
import { QuotaWiseGpaStatisticsForm, QuotaWiseGpaStatisticsFd } from 'src/app/framework-modules/formdata/gen/quotaWiseGpaStatisticsForm';
import { AdmissionQuotaSubjectPerformanceForm, AdmissionQuotaSubjectPerformanceFd } from 'src/app/framework-modules/formdata/gen/admissionQuotaSubjectPerformanceForm';
import { FilterRequest } from 'src/app/framework-modules/formdata/form/types';
import { BarChartMetaData } from 'src/app/framework-modules/elements/ct-barchart/component';
import { EnrolledStudentDetailFd, EnrolledStudentDetailForm } from 'src/app/framework-modules/formdata/gen/enrolledStudentDetailForm';
import { InstituteConfigurationForm, InstituteConfigurationFd, InstituteConfigurationVo } from 'src/app/framework-modules/formdata/gen/instituteConfigurationForm';
import { SubjectwiseResultFd, SubjectwiseResultForm } from 'src/app/framework-modules/formdata/gen/subjectwiseResultForm';
import { QuotaWiseSubjectGradeStatisticsForm, QuotaWiseSubjectGradeStatisticsFd } from 'src/app/framework-modules/formdata/gen/quotaWiseSubjectGradeStatisticsForm';

@Component({
  selector: "icd-list",
  templateUrl: "./component.html",
  styleUrls: ["./component.scss"]
})
export class IcdListComponent implements DynamicPanel, AfterViewInit {
  @ViewChild("spinner", {static:false}) spinner: CtSpinner;
  @ViewChild("gridTable", { static: false }) gtable: CtTableComponent;
  @ViewChild("gpaTable", { static: false }) gpaTable: CtTableComponent;
  @ViewChild("degreeTopTenTable", { static: false }) degreeTopTenTable: CtTableComponent;
  @ViewChild("degreeBottomTenTable", { static: false }) degreeBottomTenTable: CtTableComponent;
  @ViewChild("programTopFiveTable", { static: false }) programTopFiveTable: CtTableComponent;
  @ViewChild("programBottomFiveTable", { static: false }) programBottomFiveTable: CtTableComponent;
  @ViewChild("topFiveTable", { static: false }) topFiveTable: CtTableComponent;
  @ViewChild("bottomFiveTable", { static: false }) bottomFiveTable: CtTableComponent;
  @Input() inputData: any;
  @Input() parentPanel: GroupPanel;

  public admissionQuotaICD: TableMetaData;
  public admissionQuotaICDSubjectPerformance: TableMetaData;
  public consolidatedBarMetaData: BarChartMetaData;
  public consolidatedBarData: {}[];
  public consolidatedGpaMetaData: BarChartMetaData;
  public consolidatedGpaData: {}[];
  public tableData: TableMetaData;
  public seasonSubjectForm: SeasonSubjectListForm;
  public seasonSubjectFd: SeasonSubjectListFd;
  public admissionQuotaForm : AdmissionQuotaStudentForm;
  public admissionQuotaFd : AdmissionQuotaStudentFd;
  public degreeForm1: OfferedSubjectDetailForm;
  public degreeFd1: OfferedSubjectDetailFd;
  public admissionQuotaSubjectPerformanceForm : AdmissionQuotaSubjectPerformanceForm;
  public admissionQuotaSubjectPerformanceFd : AdmissionQuotaSubjectPerformanceFd;
  public programForm: ProgramLevelListForm;
  public programFd: ProgramLevelListFd;
  public quotaWiseGradeStatisticsForm : QuotaWiseGradeStatisticsForm;
  public quotaWiseGradeStatisticsFd : QuotaWiseGradeStatisticsFd;
  public quotaWiseGpaStatisticsForm : QuotaWiseGpaStatisticsForm;
  public quotaWiseGpaStatisticsFd : QuotaWiseGpaStatisticsFd;
  public gpaTableData: TableMetaData;
  public degreeForm: OfferedSubjectDetailForm;
  public degreeFd: OfferedSubjectDetailFd;
  public programForm2: ProgramLevelListForm;
  public programFd2: ProgramLevelListFd;
  public degreeTopTen: TableMetaData;
  public degreeBottomTen: TableMetaData;
  public enrolledStudentsForm: EnrolledStudentDetailForm;
  public enrolledStudentsFd: EnrolledStudentDetailFd;
  public form1: InstituteConfigurationForm;
  public fd1: InstituteConfigurationFd;
  public vo1: InstituteConfigurationVo = {};
  public programForm3: ProgramLevelListForm;
  public programFd3: ProgramLevelListFd;
  public programTopFive: TableMetaData;
  public programBottomFive: TableMetaData;
  public enrollmentsForm : SubjectwiseResultForm;
  public enrollmentsFd : SubjectwiseResultFd;
  public topFiveData: TableMetaData;
  public bottomFiveData: TableMetaData;
  public subjectBarMetaData:BarChartMetaData;
  public subjectBarData:{}[];
  public quotaWiseSubjectGradeStatisticsForm : QuotaWiseSubjectGradeStatisticsForm;
  public quotaWiseSubjectGradeStatisticsFd: QuotaWiseSubjectGradeStatisticsFd;

  selectedSubject: any;
  switchName: 'Switch To Subject Wise View' | 'Switch to Institute Level View' = 'Switch To Subject Wise View'
  subjectWise: boolean = false;
  subjectTotal: number;
  gpaTotal: number;
  selectedSeason: number;
  selectedLevel: number;
  selectedDegree: number;

  constructor(private sa: ServiceAgent, private cc: ClientContext, private ds: ExcelDownloadService){
    this.programForm = ProgramLevelListForm.getInstance();
    this.programFd = this.programForm.newFormData(this.sa);
    this.seasonSubjectForm = SeasonSubjectListForm.getInstance();
    this.seasonSubjectFd = this.seasonSubjectForm.newFormData(this.sa);
    this.admissionQuotaForm = AdmissionQuotaStudentForm.getInstance();
    this.admissionQuotaFd = this.admissionQuotaForm.newFormData(this.sa);
    this.degreeForm1 = OfferedSubjectDetailForm.getInstance();
    this.degreeFd1 = this.degreeForm1.newFormData(this.sa);
    this.admissionQuotaSubjectPerformanceForm = AdmissionQuotaSubjectPerformanceForm.getInstance();
    this.admissionQuotaSubjectPerformanceFd = this.admissionQuotaSubjectPerformanceForm.newFormData(this.sa);
    this.quotaWiseGradeStatisticsForm = QuotaWiseGradeStatisticsForm.getInstance();
    this.quotaWiseGradeStatisticsFd = this.quotaWiseGradeStatisticsForm.newFormData(this.sa);
    this.quotaWiseGpaStatisticsForm = QuotaWiseGpaStatisticsForm.getInstance();
    this.quotaWiseGpaStatisticsFd = this.quotaWiseGpaStatisticsForm.newFormData(this.sa);
    this.degreeForm = OfferedSubjectDetailForm.getInstance();
    this.degreeFd = this.degreeForm.newFormData(this.sa);
    this.programForm2 = ProgramLevelListForm.getInstance();
    this.programFd2 = this.programForm2.newFormData(this.sa);
    this.enrolledStudentsForm = EnrolledStudentDetailForm.getInstance();
    this.enrolledStudentsFd = this.enrolledStudentsForm.newFormData(this.sa);
    this.form1 = InstituteConfigurationForm.getInstance();
    this.fd1 = this.form1.newFormData(this.sa);
    this.programForm3 = ProgramLevelListForm.getInstance();
    this.programFd3 = this.programForm3.newFormData(this.sa);
    this.enrollmentsForm = SubjectwiseResultForm.getInstance();
    this.enrollmentsFd = this.enrollmentsForm.newFormData(this.sa);
    this.quotaWiseSubjectGradeStatisticsForm = QuotaWiseSubjectGradeStatisticsForm.getInstance();
    this.quotaWiseSubjectGradeStatisticsFd = this.quotaWiseSubjectGradeStatisticsForm.newFormData(this.sa);
  }

  ngAfterViewInit() {
    this.metaDataInit();
    var instituteId = this.cc.getInstituteId();
    this.fd1.setFieldValue('instituteId',instituteId);
    this.spinner.show("Loading Data....");
    this.fd1.fetchData().subscribe({
      next: vo=>{
        this.vo1 = vo;
        this.selectedSeason = this.vo1.currentSeason;
        this.programFd.setFieldValue('seasonId',this.selectedSeason);
        this.quotaWiseGradeStatistics();
        this.quotaWiseGpaStatistics();
      }
    });
  }

  /**
   * Initialize the metadata for tables
   */
  metaDataInit() {

    this.subjectBarMetaData = {
      scheme: {
        domain: ['#cad4f4', '#c7e5ee', '#bce7da', '#dfedcc', '#f7eac3', '#faddcb', '#f6c2bc','#f3a7ab','#EE958F']
      },
      view: [650,300],
      xAxisLabel: "Grades",
      yAxisLabel: "Students",
      showLegend: false,
      customToolTip: true
    };


    this.consolidatedBarMetaData = {
      view:[650,300],
      xAxisLabel: "Grades",
      yAxisLabel: "Students",
      showLegend: false,
      customToolTip: true
    };

    this.tableData = {
      data: [],
      metaData: {
        column_names: [
          'name',
          'value'
        ],
        display_names: {
          'name': "Grade",
          'value': "Percentage"
        }
      }
    }

    this.consolidatedGpaMetaData = {
      view:[650,300],
      xAxisLabel: "CGPA",
      yAxisLabel: "Students",
      showLegend: false,
    };

    this.programTopFive = {
      data: [],
      metaData: {
        column_names: [
          "name",
          "usn",
          "sgpa",
          "cgpa",
        ],
        display_names:{
          "name": "Name",
          "usn": "USN",
          "sgpa":"SGPA",
          "cgpa":"CGPA",
        }
      }
    };

    this.programBottomFive = {
      data: [],
      metaData: {
        column_names: [
          "name",
          "usn",
          "sgpa",
          "cgpa",
        ],
        display_names:{
          "name": "Name",
          "usn": "USN",
          "sgpa":"SGPA",
          "cgpa":"CGPA",
        }
      }
    };

    this.gpaTableData ={data: [],metaData: {  column_names: [    'name',    'value'  ],  display_names: {    'name': "GPA Range",    'value': "Percentage of Students"  }}};

    this.degreeTopTen = {data: [],metaData: {  column_names: [    "name",    "usn",    "sgpa",    "cgpa",  ],  display_names:{    "name": "Name",    "usn": "USN",    "sgpa":"SGPA",    "cgpa":"CGPA",  }}};

    this.degreeBottomTen = {data: [],metaData: {  column_names: [    "name",    "usn",    "sgpa",    "cgpa",  ],  display_names:{    "name": "Name",    "usn": "USN",    "sgpa":"SGPA",    "cgpa":"CGPA",  }}};

    this.topFiveData = {data: [],metaData: {  column_names: [    "usn",    "name",    "cieMarks",    "examMarks",    "totalMarks",    "grade"  ],  display_names:{    "usn":"USN",    "name":"Name",    "cieMarks":"CIE",    "examMarks":"Exam",    "totalMarks":"Total Marks",    "grade": "Grade"  }}};

    this.bottomFiveData = {data: [],metaData: {  column_names: [    "usn",    "name",    "cieMarks",    "examMarks",    "totalMarks",    "grade"  ],  display_names:{    "usn":"USN",    "name":"Name",    "cieMarks":"CIE",    "examMarks":"Exam",    "totalMarks":"Total Marks",    "grade": "Grade"  }}};

  }

  /**
   * Toggle Between subjectwise and Institute wise grades
   */
  toggle(){
    var offeredSubjectId = this.seasonSubjectFd.getFieldValue('seasonOfferedSubjectId');
    this.subjectWise = !this.subjectWise;
    if(this.subjectWise){
      this.changeSubject(this.selectedSubject);
      this.switchName='Switch to Institute Level View';
    } 
    else{
      this.switchName='Switch To Subject Wise View';
      this.metaDataInit();
      this.quotaWiseGradeStatistics();
      this.quotaWiseGpaStatistics();
      this.changeLevel();
      this.changeProgramLevel();
    }
  }

  changeLevel($event?){
    if(this.programFd2.getFieldValue('seasonId') !=null && this.degreeFd.getFieldValue('levelId')!=null){
    const f: FilterRequest ={
      conditions: {
        instituteId: {value: this.cc.getInstituteId(), comp:'='},
        degreeId: {comp: '=', value:this.degreeFd.getFieldValue('degreeId')},
        levelId: {comp: '=', value: this.degreeFd.getFieldValue('levelId')},
        seasonId: {comp:'=',value:this.programFd2.getFieldValue('seasonId')},
        admissionQuota: {value: 'ICD', comp:'='}
      },
      sort: {
        cgpa: "desc"
      }
    }
    this.spinner.show('Loading');
    this.enrolledStudentsFd.filter(f).subscribe(
      data =>{
        this.degreeTopTen.data = data.splice(0,20);
        this.degreeBottomTen.data = data.splice(-20).reverse();
        this.degreeBottomTenTable.update();
        this.degreeTopTenTable.update();
        this.spinner.hide();
      }
    )
    }
  }

  changeProgramLevel($event?){
    if(this.programFd3.getFieldValue('seasonId') !=null && this.programFd3.getFieldValue('programLevelId')!=null){
    const f: FilterRequest ={
      conditions: {
        instituteId: {value: this.cc.getInstituteId(), comp:'='},
        programId: {comp: '=', value:this.programFd3.getFieldValue('programId')},
        levelId: {comp: '=', value: this.programFd3.getFieldValue('programLevelId')},
        seasonId: {comp:'=',value:this.programFd3.getFieldValue('seasonId')},
        admissionQuota: {value: 'ICD', comp:'='}
      },
      sort: {
        cgpa: "desc"
      }
    }
    this.spinner.show('Loading');
    this.enrolledStudentsFd.filter(f).subscribe(
      data =>{
        this.programTopFive.data = data.splice(0,5);
        this.programBottomFive.data = data.splice(-5).reverse();
        this.programBottomFiveTable.update();
        this.programTopFiveTable.update();
        this.spinner.hide();
      }
    )}
  }

  changeSeason($event){
    this.quotaWiseGradeStatistics();
    this.quotaWiseGpaStatistics();
  }

    /**
   * Subject change listner for the offered subject Drop down
   * Gets the data for selected subject and populates the subject Bar chart
   * @param $event Offered Subject ID from the offered subject Drop down
   */
  changeSubject($event){
    this.selectedSubject = this.seasonSubjectFd.getFieldValue('seasonOfferedSubjectId');
    const f: FilterRequest = {
      conditions: {
        instituteId: {value: this.cc.getInstituteId(), comp:'='},
        seasonId: {comp: "=", value:this.seasonSubjectFd.getFieldValue('seasonId')},
        offeredSubjectId : {comp: "=", value:this.seasonSubjectFd.getFieldValue('seasonOfferedSubjectId')},
        admissionQuota: {value: 'ICD', comp:'='}
      },
    }
    this.quotaWiseSubjectGradeStatisticsFd.filter(f).subscribe({
      next :data =>{
        var subjectGradeStats = data as unknown as {}[];
        this.subjectBarData = [];
        subjectGradeStats.forEach(grade =>{
          this.subjectBarData.push(
            {"name":grade['grade'],"value":grade['nbr'],tooltipText: 'Click to View More'}
          );
        });
        this.getPercentages(this.subjectBarData,this.tableData,this.gtable,this.subjectTotal);
      }
    });
    this.getFives(this.selectedSubject);
  }

  getFives($event){
    const f: FilterRequest = {
      conditions: {
        instituteId: {value: this.cc.getInstituteId(), comp:'='},
        seasonId: {comp: "=", value:this.seasonSubjectFd.getFieldValue('seasonId')},
        offeredSubjectId: {comp: "=", value: $event},
        admissionQuota: {value: 'ICD', comp:'='}
      },
      sort: {totalMarks: "desc"}
    }
    this.enrollmentsFd.filter(f).subscribe({
      next: data =>{
        this.topFiveData.data = data.splice(0,5);
        this.bottomFiveData.data = data.splice(-5).reverse();
        this.topFiveTable.update();
        this.bottomFiveTable.update();
      }
    })
  }

  quotaWiseGradeStatistics(){
    const f: FilterRequest = {
      conditions: {
        seasonId: {value:this.programFd.getFieldValue('seasonId'), comp:'='},
        instituteId: {value: this.cc.getInstituteId(), comp:'='},
        admissionQuota: {value: 'ICD', comp:'='}
      }
    }
    this.spinner.show('Loading');
    this.quotaWiseGradeStatisticsFd.filter(f).subscribe({
      next: data =>{
        var gradeStats = data as unknown as {}[];
        this.consolidatedBarData = [];
        gradeStats.forEach(grade =>{
          this.consolidatedBarData.push(
            {"name":grade['grade'],"value":grade['nbr'],tooltipText: 'Click to View More'}
          );
        });
        this.getPercentages(this.consolidatedBarData,this.tableData,this.gtable,this.subjectTotal);
        this.spinner.hide();
      }
    })
  }

  quotaWiseGpaStatistics(){
    const f: FilterRequest = {
      conditions: {
        seasonId: {value:this.programFd.getFieldValue('seasonId'), comp:'='},
        instituteId: {value: this.cc.getInstituteId(), comp:'='},
        admissionQuota: {value: 'ICD', comp:'='}
      }
    }
    this.spinner.show('Loading');
    this.quotaWiseGpaStatisticsFd.filter(f).subscribe({
      next: data =>{
        var gpaStats = data as unknown as {}[];
        this.consolidatedGpaData = [];
        gpaStats.forEach(gpa =>{
          this.consolidatedGpaData.push(
            {"name":gpa['gpaRange'],"value":gpa['count']}
          );
        });
        this.getPercentages(this.consolidatedGpaData,this.gpaTableData,this.gpaTable,this.gpaTotal);
        this.spinner.hide();
      }
    })
  }

  getPercentages(array: Array<{}>,tableData: TableMetaData, table: CtTableComponent,total: number){
    total = 0;
    array.forEach( data =>{
      total += data['value'];
    });
    tableData.data = [];
    array.forEach(element => {
      var percentage = Math.round(element['value']*10000/total)/100 + '%';
      tableData.data.push(
        {"name": element['name'],"value":percentage}
      )
    });
    table.update();
  }

  /**
   * Triggered when the user clicks any grade from the consolidated bar chart
   * Forms the filter request to get all students in all subjects and selected grade
   * Navigates to Dashboard View
   * @param $event grade chosen from the consolidated bar chart
   */
  selectedGrade($event: any){
    const f: FilterRequest = {
      conditions: {
        grade: {comp:'=',value: $event.name},
        instituteId: {comp: '=', value:this.cc.getInstituteId()},
        seasonId: {comp:'=',value:this.programFd.getFieldValue('seasonId')},
        admissionQuota: {value: 'ICD', comp:'='}
      }
    };
    this.parentPanel.navigate('DashboardView',[f,this.programFd.getDisplayedValueOf('seasonId'),true]);
  }

  /**
   * Triggered when the user clicks any grade from the subject bar chart
   * Forms the filter request to get all students in selected subject and selected grade
   * Navigates to Dashboard View
   * @param $event grade chosen from the subject bar chart
   */
  selectedSubjectGrade($event: any){
    const f: FilterRequest = {
      conditions: {
        offeredSubjectId: {comp:'=',value:this.selectedSubject},
        grade: {comp:'=',value: $event.name},
        instituteId: {comp: '=', value:this.cc.getInstituteId()},
        seasonId: {comp:'=',value:this.seasonSubjectFd.getFieldValue('seasonId')},
        admissionQuota: {value: 'ICD', comp:'='}
      }
    };
    this.parentPanel.navigate('DashboardView',[f,this.seasonSubjectFd.getDisplayedValueOf('seasonId')+'-'+this.seasonSubjectFd.getDisplayedValueOf('seasonOfferedSubjectId'),false]);
  }

  download(tableData: TableMetaData,name: string){
    var excelData = []
    var cols = tableData.metaData.column_names;
    var displayMap = tableData.metaData.display_names;

    tableData.data.forEach(d => {
      var row = {}
      cols.forEach(col =>{
        row[displayMap[col]] = d[col]
      });
      excelData.push(row);
    });

    this.ds.downloadExcelBook({"Sheet1": excelData},name);
  }
}