
import { Form , Field, ChildForm } from '../form/form';
import { FormData } from '../form/formData';
import { SelectOption, Vo } from '../form/types';
import { Validators } from '@angular/forms'
import { ServiceAgent} from '../form/serviceAgent';

export class AttendanceUpdateForm extends Form {
	private static _instance = new AttendanceUpdateForm();
	subjectRegisterId:Field = {
		name:'subjectRegisterId'
		,controlType: 'Hidden'
		,label: 'subjectRegisterId'
		,isRequired: true
		,valueType: 1
		,errorId: 'invalidId'
		,maxValue: 9999999999999
	};
	subjectId:Field = {
		name:'subjectId'
		,controlType: 'Hidden'
		,label: 'subjectId'
		,isRequired: true
		,valueType: 1
		,errorId: 'invalidId'
		,maxValue: 9999999999999
	};
	studentId:Field = {
		name:'studentId'
		,controlType: 'Hidden'
		,label: 'studentId'
		,isRequired: true
		,valueType: 1
		,errorId: 'invalidId'
		,maxValue: 9999999999999
	};
	totalClasses:Field = {
		name:'totalClasses'
		,controlType: 'Hidden'
		,label: 'totalClasses'
		,valueType: 1
		,errorId: 'invalidInteger'
		,maxValue: 9999999999999
	};
	totalAttendance:Field = {
		name:'totalAttendance'
		,controlType: 'Input'
		,label: 'Revised Attendance'
		,isRequired: true
		,valueType: 1
		,errorId: 'invalidInteger'
		,maxValue: 9999999999999
	};
	requesterName:Field = {
		name:'requesterName'
		,controlType: 'Input'
		,label: 'Requester Name'
		,isRequired: true
		,valueType: 0
		,errorId: 'invalidText'
		,maxLength: 1000
	};
	requesterPhone:Field = {
		name:'requesterPhone'
		,controlType: 'Input'
		,label: 'Requester Phone'
		,isRequired: true
		,valueType: 0
		,errorId: 'invalidText'
		,maxLength: 1000
	};
	remarks:Field = {
		name:'remarks'
		,controlType: 'Input'
		,label: 'Remarks'
		,isRequired: true
		,valueType: 0
		,errorId: 'invalidText'
		,maxLength: 1000
	};

	public static getInstance(): AttendanceUpdateForm {
		return AttendanceUpdateForm._instance;
	}

	constructor() {
		super();
		this.fields = new Map();
		this.controls = new Map();
		this.controls.set('subjectRegisterId', [Validators.required, Validators.max(9999999999999)]);
		this.fields.set('subjectRegisterId', this.subjectRegisterId);
		this.controls.set('subjectId', [Validators.required, Validators.max(9999999999999)]);
		this.fields.set('subjectId', this.subjectId);
		this.controls.set('studentId', [Validators.required, Validators.max(9999999999999)]);
		this.fields.set('studentId', this.studentId);
		this.controls.set('totalClasses', [Validators.max(9999999999999)]);
		this.fields.set('totalClasses', this.totalClasses);
		this.controls.set('totalAttendance', [Validators.required, Validators.max(9999999999999)]);
		this.fields.set('totalAttendance', this.totalAttendance);
		this.controls.set('requesterName', [Validators.required, Validators.maxLength(1000)]);
		this.fields.set('requesterName', this.requesterName);
		this.controls.set('requesterPhone', [Validators.required, Validators.maxLength(1000)]);
		this.fields.set('requesterPhone', this.requesterPhone);
		this.controls.set('remarks', [Validators.required, Validators.maxLength(1000)]);
		this.fields.set('remarks', this.remarks);
		this.opsAllowed = {get: true, update: true};
		this.validations = [{type: 'range', errorId: 'Can not be more than the total classes', f1: 'totalAttendance', f2: 'totalClasses', equalOk: true}];
		this.keyFields = ["subjectRegisterId"];
	}

	public getName(): string {
		 return 'attendanceUpdate';
	}
}


export class AttendanceUpdateFd extends FormData {
	constructor(form: AttendanceUpdateForm, sa: ServiceAgent) {
		super(form, sa);
	}

	setFieldValue(name: 'subjectRegisterId' | 'subjectId' | 'studentId' | 'totalClasses' | 'totalAttendance' | 'requesterName' | 'requesterPhone' | 'remarks', value: string | number | boolean | null ): void {
		super.setFieldValue(name, value);
	}

	getFieldValue(name: 'subjectRegisterId' | 'subjectId' | 'studentId' | 'totalClasses' | 'totalAttendance' | 'requesterName' | 'requesterPhone' | 'remarks' ): string | number | boolean | null {
		return super.getFieldValue(name);
	}
}


export interface AttendanceUpdateVo extends Vo {
	subjectRegisterId?: number, 
	currentTotalAttendance?: number, 
	totalAttendance?: number, 
	requesterPhone?: string, 
	subjectId?: number, 
	studentId?: number, 
	sectionName?: string, 
	usn?: string, 
	totalClasses?: number, 
	requesterName?: string, 
	studentName?: string, 
	instituteId?: number, 
	remarks?: string, 
	subjectName?: string
}
