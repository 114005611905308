import { Component, OnInit, ViewChild, Input, AfterViewInit } from "@angular/core";
import { ServiceAgent } from 'src/app/framework-modules/formdata/form/serviceAgent';
import { CtTableComponent, TableMetaData } from 'src/app/framework-modules/elements/ct-table/component';
import { GroupPanel } from '../../interfaces';
import { SessionComponent } from '../../component';
import { MarksForSectionForm, MarksForSectionFd } from 'src/app/framework-modules/formdata/gen/marksForSectionForm';
import { RowToColMeta, ColToRowMeta, Transposer, Vo } from 'src/app/framework-modules/formdata/form/types';
import { MessageService } from 'src/app/services/messageService';
import { AssignedSubjectSelectionForm, AssignedSubjectSelectionFd } from 'src/app/framework-modules/formdata/gen/assignedSubjectSelectionForm';
import { AssignedPpNpSubjectSelectionFd, AssignedPpNpSubjectSelectionForm } from "src/app/framework-modules/formdata/gen/assignedPpNpSubjectSelectionForm";
import { PpNpGradeFd, PpNpGradeForm } from "src/app/framework-modules/formdata/gen/ppNpGradeForm";
import { MatDialog } from "@angular/material";
import { CtDialogComponent } from "src/app/framework-modules/elements/ct-dialog/component";


@Component({
  selector: 'ppNpgrade-entry-edit',
  templateUrl: "./component.html",
  styleUrls: ["./component.scss"]
})
export class ppNpGradeEditComponent implements OnInit, AfterViewInit {
  @ViewChild("gridTable", { static: false }) gtable: CtTableComponent;
  @Input() inputData: any;
  @Input() parentPanel: GroupPanel;
  @Input() session: SessionComponent;

  /**
   * for selecting subjectSection and test (seqNo)
   */
  form: AssignedPpNpSubjectSelectionForm;
  fd: AssignedPpNpSubjectSelectionFd;


  /**
   * for getting marks-list
   */
  form1: PpNpGradeForm;
  fd1: PpNpGradeFd;
  tableData: TableMetaData;

  constructor(private sa: ServiceAgent,
    private dialog: MatDialog,
    private ms: MessageService) {

    this.form = AssignedPpNpSubjectSelectionForm.getInstance();
    this.fd = this.form.newFormData(this.sa);
    this.form1 = PpNpGradeForm.getInstance();
    this.fd1 = this.form1.newFormData(this.sa);
  }

  ngOnInit() {
    this.initTable();
  }

  ngAfterViewInit() {
  }

  setAllPp(){
    this.tableData.data.forEach(element => {
      console.log(this.tableData.data);
      element.grade="PP";
      this.gtable.update();
    });
  }


  getData() {
    this.fd1.setFieldValue('subjectSectionId', this.inputData);
    this.fd1.fetchData().subscribe({
      next: data => {
        console.log(data);
        this.tableData.data = data.students as Vo[];
        this.gtable.update();
      },
      error: msg => {
        console.error(msg);
      }
    });
  }

  /**
   * 
   * @param data set the data reced from server into our table component after transposing it
   */
  initTable() {
    this.tableData = {
      data: [],
      metaData: {
        column_names: ["name","usn","grade"],
        display_names: {
          "name":"Student Name",
          "usn":"USN",
          "grade":"Grade"
        },
        search: true,
        edit: false,
        placeholder: "Search by Student Name, Student USN",
        editable_columns:[
          "grade"
        ],
        error:"ERROR:Please enter PP or NP",
        validations:{
          grade:{
            pattern:"PP|NP|pp|np|pP|Pp|nP|Np|^$"
          }
        }
      }
    };
    this.getData();
  }

  doSave(){
    this.fd1.save().subscribe(
      data=>{
        this.ms.showSuccess('Grade Update Successfully')
        this.parentPanel.navigate('PpNpGradeList');
      }
    )
  }

  save() {
    let dialogRef = this.dialog.open(CtDialogComponent);
    dialogRef.componentInstance.title = "Save Changes";
    dialogRef.componentInstance.content = "Are you sure you want update grades?";
    dialogRef.componentInstance.primary = "Update";
    const subscribeDialog = dialogRef.componentInstance.save.subscribe(data => {
      this.dialog.closeAll();
      this.doSave();
    });
  }

  cancel(){
    let dialogRef = this.dialog.open(CtDialogComponent);
    dialogRef.componentInstance.title = "Discard Changes";
    dialogRef.componentInstance.content = "Are you sure you want discard grades?";
    dialogRef.componentInstance.primary = "Discard";
    const subscribeDialog = dialogRef.componentInstance.save.subscribe(data => {
      this.dialog.closeAll();
      this.parentPanel.navigate("PpNpGradeList");
    });
  }
}