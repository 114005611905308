import { Component,Input, Output, EventEmitter, OnInit } from "@angular/core";
import { DynamicPanel, LandingPanel, GroupPanel } from '../../interfaces';
import { ServiceAgent } from 'src/app/framework-modules/formdata/form/serviceAgent';
import { SessionComponent } from '../../component';
import { FormData } from 'src/app/framework-modules/formdata/form/formData';
import { InstituteForm, InstituteVo } from 'src/app/framework-modules/formdata/gen/instituteForm';


@Component({
  selector: "institute-add",
  templateUrl: "./component.html",
  styleUrls: ["./component.scss"]
})


export class InstituteViewComponent implements DynamicPanel,OnInit{
  @Input() inputData: any;
  @Input() parentPanel: GroupPanel;
  @Input() session:SessionComponent;
  
  public form:InstituteForm;
  public fd:FormData;
  public vo:InstituteVo;
  
  constructor(private sa:ServiceAgent){
    this.form = InstituteForm.getInstance();
    this.fd = new FormData(this.form,this.sa);
  }

  ngOnInit(){
    this.fd.setFieldValue(this.form.instituteId.name,this.inputData);
    this.fd.fetchData().subscribe({
    	next: vo=>{
       this.vo = vo;
      },
    	error: msg => console.error("Error from server ", msg)
    });
    this.fd.formGroup.disable();
  }

  goBack(){
    this.session.navigate("Institutes");
  }

}