
import { Form , Field, ChildForm } from '../form/form';
import { FormData } from '../form/formData';
import { SelectOption, Vo } from '../form/types';
import { Validators } from '@angular/forms'
import { ServiceAgent} from '../form/serviceAgent';

export class AssignedStaffNameForm extends Form {
	private static _instance = new AssignedStaffNameForm();
	instituteId:Field = {
		name:'instituteId'
		,controlType: 'Hidden'
		,label: 'instituteId'
		,valueType: 1
		,errorId: 'invalidTenentKey'
		,maxValue: 9999999999999
	};
	seasonId:Field = {
		name:'seasonId'
		,controlType: 'Hidden'
		,label: 'seasonId'
		,valueType: 1
		,errorId: 'invalidId'
		,maxValue: 9999999999999
	};
	sectionId:Field = {
		name:'sectionId'
		,controlType: 'Hidden'
		,label: 'sectionId'
		,valueType: 1
		,errorId: 'invalidId'
		,maxValue: 9999999999999
	};
	subjectSectionId:Field = {
		name:'subjectSectionId'
		,controlType: 'Hidden'
		,label: 'subjectSectionId'
		,valueType: 1
		,errorId: 'invalidId'
		,maxValue: 9999999999999
	};
	staffId:Field = {
		name:'staffId'
		,controlType: 'Hidden'
		,label: 'staffId'
		,valueType: 1
		,errorId: 'invalidId'
		,maxValue: 9999999999999
	};
	name:Field = {
		name:'name'
		,controlType: 'Output'
		,label: 'name'
		,valueType: 0
		,errorId: 'invalidName'
		,maxLength: 150
	};
	email:Field = {
		name:'email'
		,controlType: 'Output'
		,label: 'email'
		,valueType: 0
		,errorId: 'invalidText'
		,maxLength: 1000
	};
	phoneNumber:Field = {
		name:'phoneNumber'
		,controlType: 'Output'
		,label: 'phoneNumber'
		,valueType: 0
		,errorId: 'invalidPhone'
		,minLength: 10
		,maxLength: 12
	};
	section:Field = {
		name:'section'
		,controlType: 'Output'
		,label: 'section'
		,valueType: 0
		,errorId: 'invalidText'
		,maxLength: 1000
	};
	subjectName:Field = {
		name:'subjectName'
		,controlType: 'Output'
		,label: 'subjectName'
		,valueType: 0
		,errorId: 'invalidText'
		,maxLength: 1000
	};
	cieSubmitted:Field = {
		name:'cieSubmitted'
		,controlType: 'Output'
		,label: 'cieSubmitted'
		,valueType: 0
		,errorId: 'invalidText'
		,maxLength: 1000
	};

	public static getInstance(): AssignedStaffNameForm {
		return AssignedStaffNameForm._instance;
	}

	constructor() {
		super();
		this.fields = new Map();
		this.controls = new Map();
		this.controls.set('instituteId', [Validators.max(9999999999999)]);
		this.fields.set('instituteId', this.instituteId);
		this.controls.set('seasonId', [Validators.max(9999999999999)]);
		this.fields.set('seasonId', this.seasonId);
		this.controls.set('sectionId', [Validators.max(9999999999999)]);
		this.fields.set('sectionId', this.sectionId);
		this.controls.set('subjectSectionId', [Validators.max(9999999999999)]);
		this.fields.set('subjectSectionId', this.subjectSectionId);
		this.controls.set('staffId', [Validators.max(9999999999999)]);
		this.fields.set('staffId', this.staffId);
		this.controls.set('name', [Validators.maxLength(150)]);
		this.fields.set('name', this.name);
		this.controls.set('email', [Validators.maxLength(1000)]);
		this.fields.set('email', this.email);
		this.controls.set('phoneNumber', [Validators.minLength(10), Validators.maxLength(12), Validators.pattern('[1-9][0-9]*')]);
		this.fields.set('phoneNumber', this.phoneNumber);
		this.controls.set('section', [Validators.maxLength(1000)]);
		this.fields.set('section', this.section);
		this.controls.set('subjectName', [Validators.maxLength(1000)]);
		this.fields.set('subjectName', this.subjectName);
		this.controls.set('cieSubmitted', [Validators.maxLength(1000)]);
		this.fields.set('cieSubmitted', this.cieSubmitted);
		this.opsAllowed = {get: true, filter: true, update: true};
	}

	public getName(): string {
		 return 'assignedStaffName';
	}
}


export class AssignedStaffNameFd extends FormData {
	constructor(form: AssignedStaffNameForm, sa: ServiceAgent) {
		super(form, sa);
	}

	setFieldValue(name: 'instituteId' | 'seasonId' | 'sectionId' | 'subjectSectionId' | 'staffId' | 'name' | 'email' | 'phoneNumber' | 'section' | 'subjectName' | 'cieSubmitted', value: string | number | boolean | null ): void {
		super.setFieldValue(name, value);
	}

	getFieldValue(name: 'instituteId' | 'seasonId' | 'sectionId' | 'subjectSectionId' | 'staffId' | 'name' | 'email' | 'phoneNumber' | 'section' | 'subjectName' | 'cieSubmitted' ): string | number | boolean | null {
		return super.getFieldValue(name);
	}
}


export interface AssignedStaffNameVo extends Vo {
	phoneNumber?: string, 
	seasonId?: number, 
	subjectSectionId?: number, 
	name?: string, 
	cieSubmitted?: string, 
	instituteId?: number, 
	section?: string, 
	sectionId?: number, 
	staffId?: number, 
	email?: string, 
	subjectName?: string
}
