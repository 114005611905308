
import { Form , Field, ChildForm } from '../form/form';
import { FormData } from '../form/formData';
import { SelectOption, Vo } from '../form/types';
import { Validators } from '@angular/forms'
import { ServiceAgent} from '../form/serviceAgent';

export class ValuationStatisticsForm extends Form {
	private static _instance = new ValuationStatisticsForm();

	public static getInstance(): ValuationStatisticsForm {
		return ValuationStatisticsForm._instance;
	}

	constructor() {
		super();
		this.fields = new Map();
		this.controls = new Map();
		this.opsAllowed = {get: true, filter: true};
		this.keyFields = ["valuationRound"];
	}

	public getName(): string {
		 return 'valuationStatistics';
	}
}


export class ValuationStatisticsFd extends FormData {
	constructor(form: ValuationStatisticsForm, sa: ServiceAgent) {
		super(form, sa);
	}
	/**  this form has no editable fields. data nust be accessed as Vo and not through fd **/
}


export interface ValuationStatisticsVo extends Vo {
	valuationRound?: number, 
	totalPapers?: number, 
	iiiValuated?: number, 
	scriptsModerated?: number, 
	instituteId?: number, 
	iiValuationPending?: number, 
	evaluationCompleted?: number, 
	iiValuated?: number, 
	evalulationPending?: number
}
