
import { Form , Field, ChildForm } from '../form/form';
import { FormData } from '../form/formData';
import { SelectOption, Vo } from '../form/types';
import { Validators } from '@angular/forms'
import { ServiceAgent} from '../form/serviceAgent';

export class StudentInRegisterForm extends Form {
	private static _instance = new StudentInRegisterForm();
	studentId:Field = {
		name:'studentId'
		,controlType: 'Hidden'
		,label: 'studentId'
		,valueType: 1
		,errorId: 'invalidId'
		,maxValue: 9999999999999
	};
	hasWithdrawn:Field = {
		name:'hasWithdrawn'
		,controlType: 'Hidden'
		,label: 'hasWithdrawn'
		,valueType: 3
		,errorId: 'invalidBool'
	};
	isIntegrated:Field = {
		name:'isIntegrated'
		,controlType: 'Hidden'
		,label: 'isIntegrated'
		,valueType: 3
		,errorId: 'invalidBool'
	};

	public static getInstance(): StudentInRegisterForm {
		return StudentInRegisterForm._instance;
	}

	constructor() {
		super();
		this.fields = new Map();
		this.controls = new Map();
		this.controls.set('studentId', [Validators.max(9999999999999)]);
		this.fields.set('studentId', this.studentId);
		this.controls.set('hasWithdrawn', []);
		this.fields.set('hasWithdrawn', this.hasWithdrawn);
		this.controls.set('isIntegrated', []);
		this.fields.set('isIntegrated', this.isIntegrated);
		this.opsAllowed = {get: true, filter: true};
		this.keyFields = ["subjectRegisterId"];
	}

	public getName(): string {
		 return 'studentInRegister';
	}
}


export class StudentInRegisterFd extends FormData {
	constructor(form: StudentInRegisterForm, sa: ServiceAgent) {
		super(form, sa);
	}

	setFieldValue(name: 'studentId' | 'hasWithdrawn' | 'isIntegrated', value: string | number | boolean | null ): void {
		super.setFieldValue(name, value);
	}

	getFieldValue(name: 'studentId' | 'hasWithdrawn' | 'isIntegrated' ): string | number | boolean | null {
		return super.getFieldValue(name);
	}
}


export interface StudentInRegisterVo extends Vo {
	cieMarks?: number, 
	overallAttendance?: string, 
	subjectRegisterId?: number, 
	hasWithdrawn?: boolean, 
	studentId?: number, 
	offeredSubjectId?: number, 
	usn?: string, 
	seasonId?: number, 
	subjectSectionId?: number, 
	name?: string, 
	instituteId?: number, 
	isIntegrated?: boolean, 
	subjectName?: string
}
