import { Component, OnInit, ViewChild, Input } from "@angular/core";
import { DynamicPanel, GroupPanel } from '../../interfaces';
import { ServiceAgent } from 'src/app/framework-modules/formdata/form/serviceAgent';
import { CtTableComponent, TableMetaData,  } from '../../../../framework-modules/elements/ct-table/component'
import { FilterRequest } from 'src/app/framework-modules/formdata/form/types';
import { SessionComponent } from '../../component';
import { StudentDetailForm, StudentDetailFd } from 'src/app/framework-modules/formdata/gen/studentDetailForm';
import { CtSpinner } from 'src/app/framework-modules/elements/ct-spinner/component';

@Component({
  selector: "student-list",
  templateUrl: "./component.html",
  styleUrls: ["./component.scss"]
})

export class StudentListComponent implements DynamicPanel, OnInit {
  @ViewChild("gridTable", { static: false }) gtable: CtTableComponent;
  @ViewChild("spinner", {static:false}) spinner: CtSpinner;
  
  @Input() 
  inputData: any;
  
  @Input() parentPanel: GroupPanel;
  @Input() session: SessionComponent;
  public tableData: TableMetaData;
  public from: StudentDetailForm;
  public fd: StudentDetailFd;

  constructor(private sa: ServiceAgent) {
    this.from = StudentDetailForm.getInstance();
    this.fd = this.from.newFormData(this.sa);
  }

  metaDataInit() {
    this.tableData = {
      data: [] = [],
      metaData: {
        column_names: [
          "name",
          "usn",
          "programName",
          "phoneNumber"
        ],
        display_names: {
          "name": "Student Name",
          "usn": "USN",
          "programName": "Program",
          "phoneNumber": "Phone Number"
        },
        edit: true,
        search: true,
        placeholder: "Search By Student Name, USN,Department",
      }
    };
  }

  ngOnInit() {
    this.metaDataInit();
  }

  ngAfterViewInit(){
    this.filter();
  }

  filter() {
    /**
     * initial filter conditions
     */
    this.spinner.show('Loading Student Data......');
    const filter: FilterRequest = {
      conditions: {},
      sort: { name: "asc" }
    };
    this.fd.filter(filter).subscribe(
      data => {
        this.tableData.data = data;
        this.gtable.update(this.spinner);
      },
      msgs => {
        this.spinner.hide();
        console.error(msgs);
      }
    );
  }

  addStudent() {
    this.parentPanel.navigate('StudentAdd')
  }

  edit($event) {
    this.parentPanel.navigate('StudentEdit', {studentId: this.tableData.data[$event].studentId,navigatedFrom:"StudentList"})
  }

  view($event) {
    // this.parentPanel.navigate('StudentView', this.tableData.data[$event].studentId)
  }
}
