import { Component, OnInit, ViewChild, Input, Output, EventEmitter, ComponentFactoryResolver, ViewChildren } from "@angular/core";
import { DynamicPanel, LandingPanel, GroupPanel } from '../../interfaces';
import { ServiceAgent } from 'src/app/framework-modules/formdata/form/serviceAgent';
import { CtTableComponent, TableMetaData,  } from '../../../../framework-modules/elements/ct-table/component'
import { FilterRequest } from 'src/app/framework-modules/formdata/form/types';
import { SessionComponent } from '../../component';
import { StudentDetailForm, StudentDetailFd } from 'src/app/framework-modules/formdata/gen/studentDetailForm';
import { CtSpinner } from 'src/app/framework-modules/elements/ct-spinner/component';
import { ClientContext } from 'src/app/framework-modules/formdata/form/clientContext';
import { ProgramLevelListFd, ProgramLevelListForm } from "src/app/framework-modules/formdata/gen/programLevelListForm";
import { TranscriptFd, TranscriptForm } from "src/app/framework-modules/formdata/gen/transcriptForm";
import { ExcelDownloadService } from "src/app/services/excelDownloadService";
import { CtDialogComponent } from "src/app/framework-modules/elements/ct-dialog/component";
import { MatDialog } from "@angular/material";

@Component({
  selector: "transcripts",
  templateUrl: "./component.html",
  styleUrls: ["./component.scss"]
})

export class TranscriptListComponent implements DynamicPanel, OnInit {
  @ViewChild("gridTable", { static: false }) gtable: CtTableComponent;
  @ViewChild("spinner", {static:false}) spinner: CtSpinner;
  
  @Input() 
  inputData: any;
  
  @Input() parentPanel: GroupPanel;
  @Input() session: SessionComponent;
  public tableData: TableMetaData;
  public form: ProgramLevelListForm;
  public fd: ProgramLevelListFd;
  public studentFrom: StudentDetailForm;
  public studentFd: StudentDetailFd;
  public transcriptForm: TranscriptForm;
  public transcriptFd: TranscriptFd;

  constructor(private sa: ServiceAgent,private ctx:ClientContext, private ds: ExcelDownloadService, private dialog: MatDialog) {
    this.form = ProgramLevelListForm.getInstance();
    this.fd = this.form.newFormData(this.sa);
    this.studentFrom = StudentDetailForm.getInstance();
    this.studentFd = this.studentFrom.newFormData(this.sa);
    this.transcriptForm = TranscriptForm.getInstance();
    this.transcriptFd = this.transcriptForm.newFormData(this.sa);
  }

  metaDataInit() {
    this.tableData = {
      data: [] = [],
      metaData: {
        column_names: [
          "name",
          "usn",
          "programName",
          "phoneNumber"
        ],
        display_names: {
          "name": "Student Name",
          "usn": "USN",
          "programName": "Program",
          "phoneNumber": "Phone Number"
        },
        edit: true,
        buttonName: "Download",
        search: true,
        placeholder: "Search By Student Name, USN,Department",
      }
    };
  }

  ngOnInit() {
    this.metaDataInit();
  }

  ngAfterViewInit(){
    
  }

  filter() {
    /**
     * initial filter conditions
     */
    this.spinner.show('Loading Student Data......');
    const filter: FilterRequest = {
      conditions: {
        "programId": {comp:'=',value: this.fd.getFieldValue('programId')},
        "levelId": {comp:'=',value: this.fd.getFieldValue('programLevelId')},
      },
      sort: { usn: "asc" }
    };
    this.studentFd.filter(filter).subscribe(
      data => {
        this.tableData.data = data;
        this.gtable.update(this.spinner);
      },
      msgs => {
        this.spinner.hide();
        console.error(msgs);
      }
    );
  }

  addStudent() {
    this.parentPanel.navigate('StudentAdd')
  }

  edit($event) {
    if(this.fd.getFieldValue("dateOfIssue") == undefined){
      return
    }
    this.transcriptFd.fetchFor({studentId:this.tableData.data[$event].studentId as number}).subscribe({
      next: data =>{
        console.log(data);
        var subjects = (data.subjectDetails as any[]);
        for(var i=0; i<subjects.length; i++ ){
          if(!subjects[i]["isPassGrade"]){
            this.showFailAlert(data,subjects[i]);
            return
          }
        }
        this.ds.downloadTranscript(data,this.fd.getFieldValue("dateOfIssue"),this.fd.getFieldValue("yearOfCompletion"), this.fd.getFieldValue('isAciteRequired') as boolean)
      },
      error: err =>{
      }
    })
  }

  showFailAlert(transcriptData,subject){
    let dialogRef = this.dialog.open(CtDialogComponent);
    dialogRef.componentInstance.title = 'Alert!';
    dialogRef.componentInstance.content = transcriptData.usn + " has a Fail grade in " + subject.subjectName + ". Are you sure you want to download the transcript?";
    dialogRef.componentInstance.primary = 'Yes';
    const subscribeDialog = dialogRef.componentInstance.save.subscribe((data) => {
      this.ds.downloadTranscript(transcriptData,this.fd.getFieldValue("dateOfIssue"),this.fd.getFieldValue("yearOfCompletion"), this.fd.getFieldValue('isAciteRequired') as boolean)
      this.dialog.closeAll();
    });
  }

}
