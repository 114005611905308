import { Component, Input, OnInit, ViewChild } from "@angular/core";
import { DynamicPanel, GroupPanel} from '../../interfaces';
import { ServiceAgent} from 'src/app/framework-modules/formdata/form/serviceAgent';
import { SessionComponent} from '../../component';
import { FormData} from 'src/app/framework-modules/formdata/form/formData';
import { ClientContext} from 'src/app/framework-modules/formdata/form/clientContext';
import { ExcelDownloadService} from 'src/app/services/excelDownloadService';
import { TableMetaData, CtTableComponent} from 'src/app/framework-modules/elements/ct-table/component';
import { MessageService} from 'src/app/services/messageService';
import { FilterRequest, Vo } from 'src/app/framework-modules/formdata/form/types';
import { ProgramLevelListForm, ProgramLevelListFd } from 'src/app/framework-modules/formdata/gen/programLevelListForm';
import { GradeGracingStudentListForm, GradeGracingStudentListFd } from 'src/app/framework-modules/formdata/gen/gradeGracingStudentListForm';

@Component({
  selector: "gradegracing-list",
  templateUrl: "./component.html",
  styleUrls: ["./component.scss"]
})
export class GradeGracingListComponent implements DynamicPanel, OnInit {
  @ViewChild("gridTable", {  static: false}) gtable: CtTableComponent;
  @Input() inputData: any;
  @Input() parentPanel: GroupPanel;
  @Input() session: SessionComponent;

  public form: ProgramLevelListForm;
  public fd: ProgramLevelListFd;
  public studentForm: GradeGracingStudentListForm;
  public studentFd: GradeGracingStudentListFd;
  public tableData: TableMetaData;
  public selectedId = '';

  constructor(private sa: ServiceAgent,  private cc: ClientContext,  private ms: MessageService,  private ds: ExcelDownloadService) {
    this.form = ProgramLevelListForm.getInstance();
    this.fd = new FormData(this.form, this.sa);
    this.studentForm = GradeGracingStudentListForm.getInstance();
    this.studentFd = new FormData(this.studentForm, this.sa);
    this.fd.setFieldValue("seasonId", this.cc.getcurrentSeasonId());
  }

  ngOnInit() {
    this.metaDataInit();
  }

  metaDataInit() {
    this.tableData = {
      data: [],
      metaData: {
        column_names: [
          "studentName",
          "usn",
          "programName"
        ],
        display_names: {
          "studentName": "Student Name",
          "usn": "Student USN",
          "programName": "Program Name"
        },
        search: true,
        placeholder: "Search By Student Name, USN, Program"
      }
    };
    // this.filter();
  }

  filter() {
    this.tableData.metaData.edit = true;
    this.tableData.metaData.buttonName = "View";
    /**
     * initial filter conditions
     */
    const filter: FilterRequest = {
      conditions: {
        levelId: { comp: "=", value: this.fd.getFieldValue('programLevelId')},
        programId: { comp: "=", value: this.fd.getFieldValue('programId')},
        seasonId: { comp: "=", value: this.fd.getFieldValue("seasonId")}
      },
      sort: {
        name: "asc"
      },
      maxRows: 10
    };
    const obs = this.studentFd.filter(filter);

    obs.subscribe(
      data => {
        this.tableData.data = data;
        this.gtable.update();
      },
      msgs => {
        console.error(msgs);
      }
    );
  }

  view($event) {
    if(((this.tableData.data[$event].subjectDetails) as Vo[]).length == 0){
      this.ms.showError("No subjects avaliable for selected student.")
      return;
    }
    this.parentPanel.navigate("GradeGracingView", { studentId: this.tableData.data[$event].studentId, levelId: this.fd.getFieldValue('programLevelId'), seasonId: this.fd.getFieldValue('seasonId'), programId: this.fd.getFieldValue('programId')});
  }
}
