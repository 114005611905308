import { Component,Input, Output, EventEmitter, OnInit, AfterViewInit } from "@angular/core";
import { DynamicPanel, LandingPanel, GroupPanel } from '../../interfaces';
import { ServiceAgent } from 'src/app/framework-modules/formdata/form/serviceAgent';
import { SessionComponent } from '../../component';
import { FormData, TabularData } from 'src/app/framework-modules/formdata/form/formData';
import { GuardianEditForm } from 'src/app/framework-modules/formdata/gen/guardianEditForm';
import { FieldValues, FilterRequest } from 'src/app/framework-modules/formdata/form/types';
import { CtDialogComponent } from 'src/app/framework-modules/elements/ct-dialog/component';
import { MatDialog } from '@angular/material';
import { MessageService } from 'src/app/services/messageService';
import { ClientContext } from 'src/app/framework-modules/formdata/form/clientContext';

const ADDRESS_FIELDS = ['addressLine1', 'addressLine2', 'city', 'state', 'country', 'pincode'];
const PRESENT_FIELDS = ['presentAddressLine1', 'presentAddressLine2', 'presentCity', 'presentState', 'presentCountry', 'presentPincode'];

@Component({
  selector: "guardian-edit",
  templateUrl: "./component.html",
  styleUrls: ["./component.scss"]
})

export class GuardianEditComponent implements DynamicPanel,OnInit,AfterViewInit{
  @Input() inputData: any;
  @Input() parentPanel: GroupPanel;
  @Input() session:SessionComponent;
  
  public guardianForm:GuardianEditForm;
  public guardian: FormData;
  public guardians: FormData[];
  
  public addressFlags: boolean[] = [false, false];
  public presentIsPermanent: boolean = false;

  actions: Array<any> = [];

  constructor(private sa:ServiceAgent, private ms: MessageService, private dialog: MatDialog, private cc: ClientContext){
    this.guardianForm = GuardianEditForm.getInstance();
    this.guardian = new FormData(this.guardianForm,this.sa);
  }

  ngOnInit() {
    this.guardian.setFieldValue('studentId', this.inputData.studentId);
    const f: FilterRequest = {
      conditions: {
        studentId: {value:this.inputData.studentId, comp:'='},
      },
      sort: {guardianId: "asc"}
    }
    this.guardian.filter(f).subscribe({
        next: data => {
          this.guardians = this.guardian.toFdArray(data, this.guardianForm, this.sa);
          for (let i = 0; i < this.guardians.length; i++) {
            this.actions.push([false, false]);
          }
          if(this.actions.length > 0)
          {
            this.actions[this.actions.length - 1][1] = true;
          }
          if(this.guardians.length == 0)
          {
            var tempGuardian : FormData = new FormData(this.guardianForm,this.sa);
            tempGuardian.setFieldValue('relationType', 'Father');
            tempGuardian.setFieldValue('gender', 'Male');
            this.guardians.push(tempGuardian);
            this.actions.push([true, true]);
          }
        },
    });
  }


  ngAfterViewInit(){
  }

  save(){
    let dialogRef = this.dialog.open(CtDialogComponent);
    dialogRef.componentInstance.title = 'Save Changes';
    dialogRef.componentInstance.content = 'Are you sure you want to save changes?';
    dialogRef.componentInstance.primary = 'Save';
    const subscribeDialog = dialogRef.componentInstance.save.subscribe((data) => {
      this.dialog.closeAll();
      this.doSave();
    });
  }

  doSave() {
    if(this.validate(this.guardians)) {
      var allSaved = false;
      this.guardians.forEach(guard =>{
        guard.setFieldValue('studentId', this.inputData.studentId);
        guard.setFieldValue('instituteId', this.cc.getInstituteId());
        guard.save().subscribe({
          next : vo => {
            if(this.guardians.indexOf(guard) == this.guardians.length-1){
              this.ms.showSuccess("Guardian details changed successfully! ");
              this.parentPanel.navigate('StudentEdit',{studentId:this.inputData.studentId,navigatedFrom:"StudentList"});
            }
          },
          error : err =>{
            // this.ms.showError(err);
          }
        })
      });
    }
  }

  private validate(guardians: FormData[]): boolean {
    guardians.forEach(guardian =>{
      if(!guardian.validateForm()){
        return false
      }
    })
    return true;
  }

  doCancel() {
    let dialogRef = this.dialog.open(CtDialogComponent);
    dialogRef.componentInstance.title = 'Discard Changes';
    dialogRef.componentInstance.content = 'Are you sure you want to discard changes?';
    dialogRef.componentInstance.primary = 'Discard';
    const subscribeDialog = dialogRef.componentInstance.save.subscribe((data) => {
      this.dialog.closeAll();
      this.parentPanel.navigate('StudentEdit',{studentId:this.inputData.studentId,navigatedFrom:"StudentList"});
    }); 
  }

  guardianFlagChanged(idx: number, $event: any) {
    this.addressFlags[idx] = $event.checked;
    this.guardians[idx].setFieldValue('addressLine1',this.inputData.addressLine1);
    this.guardians[idx].setFieldValue('addressLine2',this.inputData.addressLine2);
    this.guardians[idx].setFieldValue('city',this.inputData.city);
    this.guardians[idx].setFieldValue('state',this.inputData.state);
    this.guardians[idx].setFieldValue('pincode',this.inputData.pincode);
  }

  addGuardian(){
    var tempGuardian : FormData = new FormData(this.guardianForm,this.sa);
    tempGuardian.setFieldValue('relationType', null);
    this.guardians.push(tempGuardian);
    this.actions[this.actions.length - 1][1] = false;
    this.actions.push([true, true]);
  }

  removeGuardian(guardian : FormData,i : number) {
    var guardianId = guardian.getFieldValue('guardianId');
    if(guardianId == null || guardianId == -1 || guardianId == 0)
    {
      this.guardians.splice(i, 1);
      this.actions.splice(i, 1);
      this.actions[this.actions.length - 1][1] = true;
    }
    else
    {
      this.ms.showError("Existing Guardian cannot be deleted !");
    }
  }
}