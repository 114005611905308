import { Component, Input, Output, EventEmitter, OnInit } from "@angular/core";
import { DynamicPanel, LandingPanel, GroupPanel } from '../../interfaces';
import { ServiceAgent } from 'src/app/framework-modules/formdata/form/serviceAgent';
import { SessionComponent } from '../../component';
import { StaffForm, StaffFd } from 'src/app/framework-modules/formdata/gen/staffForm';
import { MessageService } from 'src/app/services/messageService';
import { CtDialogComponent } from 'src/app/framework-modules/elements/ct-dialog/component';
import { MatDialog } from '@angular/material';

@Component({
  selector: "staff-add",
  templateUrl: "./component.html",
  styleUrls: ["./component.scss"]
})


export class StaffAddComponent implements DynamicPanel, OnInit {
  @Input() inputData: any;
  @Input() parentPanel: GroupPanel;
  @Input() session: SessionComponent;

  public form: StaffForm;
  public fd: StaffFd;

  constructor(private sa: ServiceAgent, private messageService: MessageService, private dialog: MatDialog) {
    this.form = StaffForm.getInstance();
    this.fd = new StaffFd(this.form, this.sa);
  }

  ngOnInit() {
    this.fd = this.form.newFormData(this.sa);
  }

  save(){
    let dialogRef = this.dialog.open(CtDialogComponent);
    dialogRef.componentInstance.title = 'Save Changes';
    dialogRef.componentInstance.content = 'Are you sure you want to save changes?';
    dialogRef.componentInstance.primary = 'Save';
    const subscribeDialog = dialogRef.componentInstance.save.subscribe((data) => {
      this.doSave();
      this.dialog.closeAll();
    });
  }

  doSave() {
    this.fd.saveAsNew().subscribe({
      next: vo => {
        this.messageService.showSuccess('Staff Added Successfully!')
        this.parentPanel.navigate("StaffList");
      },
      error: err => {
        console.error(err);
      }
    });
  }

  doCancel() {
    let dialogRef = this.dialog.open(CtDialogComponent);
    dialogRef.componentInstance.title = 'Discard Changes';
    dialogRef.componentInstance.content = 'Are you sure you want to discard changes?';
    dialogRef.componentInstance.primary = 'Discard';
    const subscribeDialog = dialogRef.componentInstance.save.subscribe((data) => {
      this.parentPanel.navigate('StaffList');
      this.dialog.closeAll();
    });
    
  }
}
