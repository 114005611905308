
import { Form , Field, ChildForm } from '../form/form';
import { FormData } from '../form/formData';
import { SelectOption, Vo } from '../form/types';
import { Validators } from '@angular/forms'
import { ServiceAgent} from '../form/serviceAgent';

export class PaperHistoryForm extends Form {
	private static _instance = new PaperHistoryForm();

	public static getInstance(): PaperHistoryForm {
		return PaperHistoryForm._instance;
	}

	constructor() {
		super();
		this.fields = new Map();
		this.controls = new Map();
		this.opsAllowed = {get: true, filter: true};
	}

	public getName(): string {
		 return 'paperHistory';
	}
}


export class PaperHistoryFd extends FormData {
	constructor(form: PaperHistoryForm, sa: ServiceAgent) {
		super(form, sa);
	}
	/**  this form has no editable fields. data nust be accessed as Vo and not through fd **/
}


export interface PaperHistoryVo extends Vo {
	chPaperCode?: string, 
	revalPaperCode?: string, 
	subjectRegisterId?: number, 
	isMakeup?: boolean, 
	paperCode?: string, 
	packetCode?: string, 
	revalPacketCode?: string, 
	offeredSubjectId?: number, 
	usn?: string, 
	chPacketCode?: string, 
	name?: string, 
	instituteId?: number
}
