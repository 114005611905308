import { MenuService } from 'src/app/services/menuService';
import { Component, OnInit, Input, ViewChild, ViewContainerRef, Type, ComponentFactoryResolver } from "@angular/core";
import { DynamicPanel, GroupPanel } from '../interfaces'
import { SessionComponent, SecondaryMenu } from '../component';
import { AssignPacketsListComponent } from './assignPackets-list/component';
import { AssignPacketsEditComponent } from './assignPackets-edit/component';
import { CodingComponent } from './coding/component';
import { ExamAttendanceListComponent } from './examAttendance-list/component';
import { ExamAttendanceEditComponent } from './examAttendance-edit/component';
import { ExternalStaffListComponent } from './externalStaff-list/component';
import { ExternalStaffAddComponent } from './externalStaff-add/component';
import { ExternalStaffEditComponent } from './externalStaff-edit/component';
import { PacketListComponent } from './packetList-subject/component';
import { ValuationViewComponent } from './valuationView/component';
import { ValuationEditComponent } from './valuationEdit/component';
import { ClientContext } from 'src/app/framework-modules/formdata/form/clientContext';
import { MessageService } from 'src/app/services/messageService';
import { AssignEvaluatorsListComponent } from './assignEvaluators-list/component';
import { AssignEvaluatorsEditComponent } from './assignEvaluators-edit/component';
import { PacketListByEvaluatorComponent } from './packetList-evaluator/component';
import { InitiateValuationListComponent } from './initiate-valuation/component';
import { ResultsSubjectComponent } from './resultsSubject-list/component';
import { RevaluationListComponent } from './revaluation-list/component';
import { RevaluationEditComponent } from './revaluation-edit/component';
import { ChallengeValuationListComponent } from './challengeValuation-list/component';
import { ChallengeValuationEditComponent } from './challengeValuation-edit/component';
import { ExamExemptionListComponent } from './examExemption-list/component';
import { ExamExemptionEditComponent } from './examExemption-edit/component';
import { ResultsLevelListComponent } from './resultsLevel-list/component';
import { ResultsValuationComponent } from './resultsValuation/component';
import { ResultsLevelViewComponent } from './resultsLevel-view/component';
import { IndividualGradeCardListComponent } from './individualGradeCard-list/component';
import { FinalMarksListComponent } from './final-exam-marks-list/component';
import { FinalMarksUpdateComponent } from './final-exam-marks-update/component';
import { SubjectGradeCalculationComponent } from './subject-grade-calculation/component';
import { DegreeGradeCalculationComponent } from './degree-grade-calculation/component';
import { PacketListSearchComponent } from './packetList-search/component';
import { PacketListStatusComponent } from './packetList-status/component';
import { ForceValuationComponent } from './force-valuation/component';
import { MalpracticeListComponent } from './malpractice-list/component';
import { MalpracticeEditComponent } from './malpractice-edit/component';
import { CurrentBacklogsComponent } from './current-backlogs/component';
import { TranscriptListComponent } from './transcripts/component';
import { AssignReviewerComponent } from './assignReviewer/component';
import { ProjectMarksEntryListComponent } from './projectMarksEntry-list/component';
import { ProjectMarksEntryEditComponent } from './projectMarksEntry-edit/component';
import { ProjectMarksEntryUpdateComponent } from './projectMarksEntry-update/component';
import { ProjectMarksEntryBulkUpdateComponent } from './projectMarksEntry-update-bulk/component';
import { pdcListComponent } from './pdc-list/component';
import { pdcUpdateComponent } from './pdc-update/component';
import { pdcEditComponent } from './pdc-edit/component';
import { GradeGracingListComponent } from './gradeGracing-list/component';
import { GradeGracingViewComponent } from './gradeGracing-view/component';
import { GradeGracingEditComponent } from './gradeGracing-edit/component';
import { RevalChallengeStudentListComponent } from './reval-challenge-student-list/component';




/**
 Const to map the screen-component to screen-name to rended the facotry
 */
const MENU: SecondaryMenu = {
  ExamExemptionList:{
    comp:ExamExemptionListComponent,
    title: "List of Exempted Students",
    name: "Exemptions",
    sideNav: true,
    pageId: "D85",
    displayonSidenav: true,
    mainNav: true,
    order:1
  },

  ExamExemptionEdit:{
    comp:ExamExemptionEditComponent,
    title: "List of Exempted Students",
    name: "Exemptions",
    sideNav: false,
    pageId: "D86",
    displayonSidenav: false,
    mainNav: false
  },

  MalpracticeList:{
    comp:MalpracticeListComponent,
    title: "List of Malpractice Students",
    name: "Malpractice",
    sideNav: true,
    pageId: "D155",
    displayonSidenav: true,
    mainNav: true,
    order:2
  },

  MalpracticeEdit:{
    comp:MalpracticeEditComponent,
    title: "List of Malpractice Students",
    name: "Malpractice",
    sideNav: false,
    pageId: "D156",
    displayonSidenav: false,
    mainNav: false
  },

  ExamAttendanceList: {
    comp: ExamAttendanceListComponent,
    title: "Exam Attendance",
    name: "Exam Attendance",
    sideNav: true,
    pageId: "D49",
    displayonSidenav: true,
    mainNav: true,
    order: 3
  },
  ExamAttendanceEdit: {
    comp: ExamAttendanceEditComponent,
    title: "Mark Exam Attendance",
    name: "Exam Attendance Edit",
    sideNav: false,
    pageId: "D45",
    displayonSidenav: false,
    mainNav: false
  },

  ExternalStaffList: {
    comp: ExternalStaffListComponent,
    title: "List of External Staff",
    name: "External Evaluators",
    sideNav: true,
    pageId: "D50",
    displayonSidenav: true,
    mainNav: true,
    order: 4
  },
  ExternalStaffAdd: {
    comp: ExternalStaffAddComponent,
    title: "Add a New Staff",
    name: "Add a New Staff",
    sideNav: false,
    pageId: "D51",
    displayonSidenav: false,
    mainNav: false
  },
  ExternalStaffEdit: {
    comp: ExternalStaffEditComponent,
    title: "Edit External Staff",
    name: "Edit External Staff",
    sideNav: false,
    pageId: "D52",
    displayonSidenav: false,
    mainNav: false
  },

  AssignEvaluatorsList: {
    comp: AssignEvaluatorsListComponent,
    title: "Evaluators for a Subject",
    name: "Evaluators for Subjects",
    sideNav: true,
    pageId: "D47",
    displayonSidenav: true,
    mainNav: true,
    order: 5
  },
  AssignEvaluatorsEdit: {
    comp: AssignEvaluatorsEditComponent,
    pageId: "D48",
    title: "Edit Evaluators",
    name: "Edit Evaluator Details",
    sideNav: false,
    mainNav: true,
    displayonSidenav: false
  },

  Coding: {
    comp: CodingComponent,
    title: "Coding",
    name: "Coding",
    sideNav: true,
    mainNav: true,
    pageId: "D46",
    displayonSidenav: true,
    subTitle: "Coding",
    order: 6
  },

  AssignPacketsList: {
    comp: AssignPacketsListComponent,
    title: "Assign Packets to Evaluators",
    name: "Packet Assignment",
    sideNav: true,
    pageId: "D101",
    displayonSidenav: true,
    mainNav: true,
    order: 7
  },

  AssignPacketsEdit: {
    comp: AssignPacketsEditComponent,
    title: "Assign Packets to Evaluators",
    name: "Packet Assignment",
    sideNav: false,
    pageId: "D102",
    displayonSidenav: false,
    mainNav: false
  },

  InitiateValuation:{
    comp:InitiateValuationListComponent,
    title: "Initiate and Freeze Valuation Rounds",
    name: "Initiate Valuation",
    sideNav: true,
    pageId: "D69",
    displayonSidenav: true,
    mainNav: true,
    order:8
  },

  PacketListSearch: {
    comp: PacketListSearchComponent,
    title: "Packets List Search",
    name: "Packet List - Search",
    sideNav: true,
    pageId: "D150",
    displayonSidenav: true,
    mainNav: true,
    order: 9
  },

  PacketListStatus: {
    comp: PacketListStatusComponent,
    title: "Packets Status",
    name: "Packet Status",
    sideNav: true,
    pageId: "D151",
    displayonSidenav: true,
    mainNav: true,
    order: 10
  },

  PacketList: {
    comp: PacketListComponent,
    title: "Packets List By Subjects",
    name: "Packet List - Subject",
    sideNav: true,
    pageId: "D103",
    displayonSidenav: true,
    mainNav: true,
    order: 11
  },

  PacketListByEvaluator: {
    comp: PacketListByEvaluatorComponent,
    title: "Packets List By Evaluator",
    name: "Packet List - Evaluator",
    sideNav: true,
    pageId: "D111",
    displayonSidenav: true,
    mainNav: true,
    order: 12
  },

  RevaluationList:{
    comp: RevaluationListComponent,
    title: "List of students for revaluation",
    name: "Revaluation List",
    sideNav: true,
    pageId: "D74",
    displayonSidenav: true,
    mainNav: true,
    order: 13
  },

  RevaluationEdit: {
    comp: RevaluationEditComponent,
    title: "Select students for revaluation",
    name: "Revaluation",
    sideNav: false,
    pageId: "D75",
    displayonSidenav: false,
    mainNav: false,
  },

  ChallengeValuationList:{
    comp: ChallengeValuationListComponent,
    title: "List of students for Challenge Revaluation",
    name: "Challenge Valuation",
    sideNav: true,
    pageId: "D76",
    displayonSidenav: true,
    mainNav: true,
    order: 14
  },

  ChallengeValuationEdit: {
    comp: ChallengeValuationEditComponent,
    title: "Select students for Challenge Revaluation",
    name: "Revaluation",
    sideNav: false,
    pageId: "D77",
    displayonSidenav: false,
    mainNav: false,
  },

  FinalMarksList:{
    comp:FinalMarksListComponent,
    title: "Final Exam Marks",
    mainNav: true,
    sideNav: true,
    pageId:"D133",
    displayonSidenav: true,
    name: "Exam Marks",
    order:15
  },

  FinalMarksUpdate:{
    comp:FinalMarksUpdateComponent,
    title: "Update Exam Marks",
    mainNav: false,
    sideNav: false,
    pageId:"D134",
  },

  ResultsSubject: {
    comp: ResultsSubjectComponent,
    title: "Subject wise result of students",
    name: "Results - Subject",
    sideNav: true,
    pageId: "D70",
    displayonSidenav: true,
    mainNav: true,
    order:16
  },

  ResultsLevel: {
    comp: ResultsLevelListComponent,
    title: "Results of students Levelwise",
    name: "Results - Level Wise",
    sideNav: true,
    pageId: "D71",
    displayonSidenav: true,
    mainNav: true,
    order:17
  },

  ResultsLevelView: {
    comp: ResultsLevelViewComponent,
    title: "View Marks of students",
    name: "Marks - Consolidated",
    sideNav: false,
    pageId: "D73",
    displayonSidenav: false,
    mainNav: false,
  },


  valuationMarksView: {
    comp: ResultsValuationComponent,
    title: "View Marks of students",
    name: "Results - Valuation",
    sideNav: true,
    pageId: "D72",
    displayonSidenav: true,
    mainNav: true,
    order:18
  },

  ValuationView: {
    comp: ValuationViewComponent,
    title: "View Marks",
    name: "View  Marks",
    sideNav: false,
    pageId: "D104",
    displayonSidenav: false,
    mainNav: false
  },

  ValuationEdit: {
    comp: ValuationEditComponent,
    title: "Enter Marks",
    name: "Marks Entry",
    sideNav: false,
    pageId: "D105",
    displayonSidenav: false,
    mainNav: false
  },

  PaperValuationEdit: {
    comp: null,
    title: "Marks Entry for a Paper",
    name: "Marks Entry",
    sideNav: false,
    pageId: "D106",
    displayonSidenav: false,
    mainNav: false
  },
  IndividualGradeCardList:{
    comp:IndividualGradeCardListComponent,
    mainNav:true,
    pageId:"D112",
    sideNav:true,
    title:"Individual Grade Card",
    displayonSidenav:true,
    name:"Student Grade Card",
    order:19
  },
  GradeCalculation:{
    comp: SubjectGradeCalculationComponent,
    mainNav: true,
    sideNav: true,
    displayonSidenav: true,
    pageId: "D141",
    title: "Calculate Grades for a Subject",
    order: 20,
    name: "Subject Grading"
  },
  DegreeGradeCalculation:{
    comp: DegreeGradeCalculationComponent,
    mainNav: true,
    sideNav: true,
    displayonSidenav: true,
    pageId: "D142",
    title: "Calculate Grades for a Degree",
    order: 21,
    name: "Degree Grading"
  },
  ForceValuation:{
    comp: ForceValuationComponent,
    mainNav: true,
    sideNav: true,
    displayonSidenav: true,
    pageId: "D152",
    title: "Force Evaluation",
    order: 22,
    name: "Force Evaluation"
  },

  CurrentBacklogs:{
    comp: CurrentBacklogsComponent,
    mainNav: true,
    sideNav: true,
    displayonSidenav: true,
    pageId: "D152",
    title: "Current Backlogs Report",
    order: 23,
    name: "Current Backlogs"
  },

  TranscriptList:{
    comp: TranscriptListComponent,
    mainNav: true,
    sideNav: true,
    displayonSidenav: true,
    pageId: "D152",
    title: "Transcripts",
    order: 24,
    name: "Transcripts"
  },
  AssignReviewer: {
    comp:AssignReviewerComponent,
    mainNav: true,
    pageId: "D157",
    sideNav: true,
    title: "Assign Reviewer",
    displayonSidenav: true,
    name: "Assign Reviewer",
    order: 25,
  },
  ProjectMarksEntryList: {
    comp:ProjectMarksEntryListComponent,
    mainNav: true,
    pageId: "D158",
    sideNav: true,
    title: "Project Marks Entry",
    displayonSidenav: true,
    name: "Project Marks Entry",
    order: 26,
  },
  ProjectMarksEntryEdit: {
    name: "Project Marks Enrty",
    comp: ProjectMarksEntryEditComponent,
    title: "Edit Project Marks",
    sideNav: false,
    pageId: "D159",
    displayonSidenav: false,
    mainNav: false
  },
  ProjectMarksEntryBulkUpdate:{
    name:"Marks Entry Bulk",
    comp:ProjectMarksEntryBulkUpdateComponent,
    sideNav:false,
    mainNav:false,
    pageId:"D160",
    title:"Bulk Marks Entry",
    displayonSidenav:false
  },
  ProjectMarksEntryUpdate:{
    name:"Marks Update",
    comp:ProjectMarksEntryUpdateComponent,
    mainNav:false,
    sideNav:false,
    pageId:"D161",
    title:"Edit Marks",
    displayonSidenav:false
  },
  pdcDownload:{ 
    comp: pdcListComponent, 
    title: "PDC DOWNLOAD", 
    sideNav: true, 
    pageId: "D162", 
    displayonSidenav: true, 
    name: "PDC Downlaod", 
    mainNav: true, 
    order:27 
  },
  pdcUpdate:{ 
    comp: pdcUpdateComponent, 
    title: "PDC DOWNLOAD UPDATE", 
    sideNav: false, 
    pageId: "D163", 
    displayonSidenav: false, 
    name: "PDC Downlaod UPDATE", 
    mainNav: false 
  },
  pdcEdit:{ 
    comp: pdcEditComponent, 
    title: "PDC UPDATE", 
    sideNav: false, 
    pageId: "D164", 
    displayonSidenav: false, 
    name: "PDC UPDATE", 
    mainNav: false 
  },
  GradeGracingList:{ 
    comp: GradeGracingListComponent, 
    title: "Grade Gracing Student List", 
    sideNav: true, 
    pageId: "D177", 
    displayonSidenav: true, 
    name: "Grade Gracing", 
    mainNav: true,
    order:28
  },
  GradeGracingView:{
    name: "Grade Gracing View",
    comp: GradeGracingViewComponent,
    sideNav:false,
    mainNav:false,
    pageId:"D178",
    title:"Grade Gracing View",
    displayonSidenav:false
  },
  GradeGracingEdit:{
    name: "Grade Gracing Edit",
    comp: GradeGracingEditComponent,
    sideNav:false,
    mainNav:false,
    pageId:"D179",
    title:"Grade Gracing Edit",
    displayonSidenav:false
  },
  RevalChallengeStudentList:{ 
    comp: RevalChallengeStudentListComponent, 
    title: "Re-valuation or Challenge Valuation Opted Student List", 
    sideNav: true, 
    pageId: "D181", 
    displayonSidenav: true, 
    name: "RV / CV Opted List", 
    mainNav: true,
    order:29
  }
};

@Component({
  selector: "Terminalss",
  templateUrl: "./component.html",
  styleUrls: ["./component.scss"]
})
export class TerminalComponent implements OnInit, GroupPanel {
  @Input() inputData: any;
  @Input() parentPanel: SessionComponent;
  menu: { [key: string]: Type<DynamicPanel> };
  public sidenav: boolean = false;
  displayNames: {}
  public items: {};
  cardHeader: any;
  public defaultSelected: string;
  pScreens: Array<string>;
  screens: string[];
  defaultComponent: string;
  /**
    container for the child page loaded from the seconday navbar
   */

  @ViewChild("secondaryContainer", { static: true, read: ViewContainerRef }) container: ViewContainerRef;
  constructor(private componentFactoryResolver: ComponentFactoryResolver,private cc: ClientContext, private ms: MessageService, private mns: MenuService) {
    this.screens = this.mns.getScreens();
      Object.keys(MENU).forEach( key => {
      if(this.screens.includes(MENU[key].pageId) && this.defaultSelected == undefined){
        this.defaultSelected = MENU[key].name;
        this.cardHeader = MENU[key].title;
        this.defaultComponent = key;
      }
    })
  }

  ngOnInit() {
    this.navigate(this.defaultComponent, {});
    /**
     * providing menu items to the items array from the MENU
     */
    this.items = MENU;
  }

  /**
   *
   * @param compName is the name of the component to navigate to
   * @param data is for any related data needed
   */

  public navigate(compName: string, data: any): void {
    const m = MENU[compName];
    if (!m) {
      this.ms.showInfo('Navigation Error: ' + compName + ' is not a valid page name.');
      return;
    }

    if(!this.cc.isPageValid(m.pageId)){
      this.ms.showInfo('Navigation Error: ' + compName + ' is not authorized for this user.');
      return;
    }

    this.parentPanel.toggleMainNav(m.mainNav)
    this.sidenav = m.sideNav;

    if (m.name != undefined) {
      this.defaultSelected = m.name;
    }

    this.cardHeader = m.title;
    this.container.clear();
    const factory = this.componentFactoryResolver.resolveComponentFactory(
      m.comp
    );

    const c = this.container.createComponent(factory);
    c.instance.inputData = data;
    c.instance.parentPanel = this;
    c.changeDetectorRef.detectChanges();
  }

  fullScreenMode() {
    this.sidenav = false;
    this.parentPanel.hideMainNav();
  }

  halfScreenMode() {
    this.sidenav = true;
    this.parentPanel.showMainNav();
  }
}
