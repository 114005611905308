import { Component, OnInit, ViewChild, Input } from "@angular/core";
import { DynamicPanel, GroupPanel } from '../../interfaces';
import { ServiceAgent } from 'src/app/framework-modules/formdata/form/serviceAgent';
import { CtTableComponent, TableMetaData } from '../../../../framework-modules/elements/ct-table/component'
import { FilterRequest, Vo } from 'src/app/framework-modules/formdata/form/types';
import { SessionComponent } from '../../component';
import { OfferableSubjectForm } from 'src/app/framework-modules/formdata/gen/offerableSubjectForm';
import { OfferedSubjectSelectionFd, OfferedSubjectSelectionForm } from 'src/app/framework-modules/formdata/gen/offeredSubjectSelectionForm';

@Component({
  selector: "manual-register-list",
  templateUrl: "./component.html",
  styleUrls: ["./component.scss"]
})

export class ManualRegisterListComponent implements DynamicPanel, OnInit {
  @ViewChild("gridTable", { static: false }) gtable: CtTableComponent;
  @Input() inputData: any;
  @Input() parentPanel: GroupPanel;
  @Input() session: SessionComponent;
  
  public tableData: TableMetaData;
  public form: OfferedSubjectSelectionForm;
  public fd: OfferedSubjectSelectionFd;

  public subjectListForm: OfferableSubjectForm;
  public vo: Vo[] = [];
  
  constructor(private sa: ServiceAgent) {
    this.form = OfferedSubjectSelectionForm.getInstance();
    this.fd = this.form.newFormData(this.sa);
    this.subjectListForm = OfferableSubjectForm.getInstance();
  }

  ngOnInit() {
    this.metaDataInit();
  }

  ngAfterViewInit(){
    this.filter();
  }

  private metaDataInit() {
    this.tableData = {
      data: [] = [],
      metaData: {
        column_names: [
          "name",
          "code",
          "offeredSections"
        ],
        display_names: {
          "name": "Subject",
          "code": "Code",
          "offeredSections": "Sections"
        },
        edit: true,
        search: true,
        placeholder: "Search By Subject, Code, Department or Sections",
        buttonName: "REGISTER"
      }
    };
  }

  private filter() {
    /**
     * initial filter conditions
     */
    const filter: FilterRequest = {
      conditions: { departmentId: { value: this.fd.getFieldValue('departmentId'), comp: "=" }    },
      sort: { name: "asc" }
    };
    const obs = this.sa.filter(this.subjectListForm, filter);

    obs.subscribe(
      data => {
        this.vo = data;
        this.filterSubjects();
      },
      msgs => {
        console.error(msgs);
      }
    );
  }

  edit($event) {
    this.parentPanel.navigate('ManualRegisterEdit', this.tableData.data[$event].subjectId);
  }

  private filterSubjects(){
    this.tableData.data = this.vo.filter(
      row => row.departmentId == this.fd.getFieldValue('departmentId')
    );
    this.gtable.update();
  }

  changeDepartment($event){
    this.filter();
  }
}