
import { Form , Field, ChildForm } from '../form/form';
import { FormData } from '../form/formData';
import { SelectOption, Vo } from '../form/types';
import { Validators } from '@angular/forms'
import { ServiceAgent} from '../form/serviceAgent';

export class ValuationMarksForm extends Form {
	private static _instance = new ValuationMarksForm();
	departmentId:Field = {
		name:'departmentId'
		,controlType: 'Dropdown'
		,label: 'Select a department'
		,isRequired: true
		,listName: 'departmentList'
		,valueType: 1
		,errorId: 'invalidId'
		,maxValue: 9999999999999
	};
	offeredSubjectId:Field = {
		name:'offeredSubjectId'
		,controlType: 'Dropdown'
		,label: 'Select a Subject'
		,isRequired: true
		,listName: 'offeredSubjectList'
		,listKey: 'departmentId'
		,valueType: 1
		,errorId: 'invalidId'
		,maxValue: 9999999999999
	};
	valuationRound:Field = {
		name:'valuationRound'
		,controlType: 'Dropdown'
		,label: 'Select a Round'
		,isRequired: true
		,listName: 'valuationRoundList'
		,valueList: [
			{value:'1',text:'Initial'},
			{value:'2',text:'Revaluation'},
			{value:'3',text:'Challenge'}
			]
		,valueType: 1
		,defaultValue: 1
		,errorId: 'invalidId'
		,maxValue: 9999999999999
	};
	examType:Field = {
		name:'examType'
		,controlType: 'Dropdown'
		,label: 'Select an Exam'
		,isRequired: true
		,listName: 'examTypes'
		,valueList: [
			{value:'1',text:'Normal'},
			{value:'2',text:'Make-up'}
			]
		,valueType: 1
		,defaultValue: 1
		,errorId: 'invalidId'
		,maxValue: 9999999999999
	};

	public static getInstance(): ValuationMarksForm {
		return ValuationMarksForm._instance;
	}

	constructor() {
		super();
		this.fields = new Map();
		this.controls = new Map();
		this.controls.set('departmentId', [Validators.required, Validators.max(9999999999999)]);
		this.fields.set('departmentId', this.departmentId);
		this.controls.set('offeredSubjectId', [Validators.required, Validators.max(9999999999999)]);
		this.fields.set('offeredSubjectId', this.offeredSubjectId);
		this.controls.set('valuationRound', [Validators.required, Validators.max(9999999999999)]);
		this.fields.set('valuationRound', this.valuationRound);
		this.controls.set('examType', [Validators.required, Validators.max(9999999999999)]);
		this.fields.set('examType', this.examType);
		this.opsAllowed = {get: true, create: true, update: true, filter: true};
		this.listFields = ['departmentId','offeredSubjectId','valuationRound','examType'];
	}

	public getName(): string {
		 return 'valuationMarks';
	}
}


export class ValuationMarksFd extends FormData {
	constructor(form: ValuationMarksForm, sa: ServiceAgent) {
		super(form, sa);
	}

	setFieldValue(name: 'departmentId' | 'offeredSubjectId' | 'valuationRound' | 'examType', value: string | number | boolean | null ): void {
		super.setFieldValue(name, value);
	}

	getFieldValue(name: 'departmentId' | 'offeredSubjectId' | 'valuationRound' | 'examType' ): string | number | boolean | null {
		return super.getFieldValue(name);
	}
}


export interface ValuationMarksVo extends Vo {
	offeredSubjectId?: number, 
	examType?: number, 
	valuationRound?: number, 
	departmentId?: number
}
