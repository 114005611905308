
import { Form , Field, ChildForm } from '../form/form';
import { FormData } from '../form/formData';
import { SelectOption, Vo } from '../form/types';
import { Validators } from '@angular/forms'
import { ServiceAgent} from '../form/serviceAgent';

export class SubjectFrozenStatusForm extends Form {
	private static _instance = new SubjectFrozenStatusForm();

	public static getInstance(): SubjectFrozenStatusForm {
		return SubjectFrozenStatusForm._instance;
	}

	constructor() {
		super();
		this.fields = new Map();
		this.controls = new Map();
		this.opsAllowed = {filter: true};
		this.keyFields = ["offeredSubjectId"];
	}

	public getName(): string {
		 return 'subjectFrozenStatus';
	}
}


export class SubjectFrozenStatusFd extends FormData {
	constructor(form: SubjectFrozenStatusForm, sa: ServiceAgent) {
		super(form, sa);
	}
	/**  this form has no editable fields. data nust be accessed as Vo and not through fd **/
}


export interface SubjectFrozenStatusVo extends Vo {
	degreeId?: number, 
	departmentId?: number, 
	attendanceFrozen?: boolean, 
	cieFrozen?: boolean, 
	subjectId?: number, 
	offeredSubjectId?: number, 
	createdAt?: string, 
	seasonId?: number, 
	levelId?: number, 
	instituteId?: number, 
	isIntegrated?: boolean, 
	assessmentSchemeId?: number, 
	isChild?: boolean, 
	updatedAt?: string
}
