import { Component, OnInit, ViewChild, Input } from "@angular/core";
import { DynamicPanel, GroupPanel } from '../../interfaces';
import { ServiceAgent } from 'src/app/framework-modules/formdata/form/serviceAgent';
import { CtTableComponent, TableMetaData } from '../../../../framework-modules/elements/ct-table/component'
import { SessionComponent } from '../../component';
import { MessageService } from 'src/app/services/messageService';
import { CtSpinner } from 'src/app/framework-modules/elements/ct-spinner/component';
import { ExamAttendeeListForm, ExamAttendeeListFd, ExamAttendeeListVo } from 'src/app/framework-modules/formdata/gen/examAttendeeListForm';
import { FormADownloadForm, FormADownloadFd } from 'src/app/framework-modules/formdata/gen/formADownloadForm';
import { MakeupFormADownloadForm, MakeupFormADownloadFd } from "src/app/framework-modules/formdata/gen/makeupFormADownloadForm";
import { ExcelDownloadService } from 'src/app/services/excelDownloadService';
import { FormAConfigFd, FormAConfigForm } from "src/app/framework-modules/formdata/gen/formAConfigForm";
import * as _ from 'lodash';
import { ClientContext } from 'src/app/framework-modules/formdata/form/clientContext';


@Component({
  selector: "makeup-exam-attendance-list",
  templateUrl: "./component.html",
  styleUrls: ["./component.scss"],
})


export class MakeUpExamAttendanceListComponent implements DynamicPanel, OnInit {
  @ViewChild("gridTable", { static: false }) gtable: CtTableComponent;
  @ViewChild("spinner", { static: false }) spinner: CtSpinner;
  @Input() inputData: any;
  @Input() parentPanel: GroupPanel;
  @Input() session: SessionComponent;

  public tableData: TableMetaData;
  /** form and fd for selecting the subjects */
  public form: ExamAttendeeListForm;
  public fd: ExamAttendeeListFd;

  public form1: ExamAttendeeListForm;
  public fd1: ExamAttendeeListFd;

  public vo: ExamAttendeeListVo = {};
  public downloadForm:FormADownloadForm;
  public downloadFd:FormADownloadFd;
  public downloadSettingsForm: FormAConfigForm;
  public downloadSettingsFd: FormAConfigFd;
  public downloadSettings: boolean = false;
  public MakeupFormADownloadForm: MakeupFormADownloadForm;
  public MakeupFormADownloadFd: MakeupFormADownloadFd;


  /**
   * Important:
   * 1. our drop-downs are designed to take the first value from the valid list and set it to the field.
   *    That is, dop-downs are alomost always set a value on-load automatically.
   * 2. When offeredSubject field has a value, we are to automatically trigger the service.
   *    There is no button to be clicked by the user to go to server and fetch data.
   * 3. As a result, fetchData gets triggered onload for the first offeredSubject for the first department.
   */
  /**
   * offered subject id selected by the user
   */
  private selectedId: string = null;

  constructor(private sa: ServiceAgent, private ms: MessageService, private ds: ExcelDownloadService, private ctx: ClientContext) {
    this.form = ExamAttendeeListForm.getInstance();
    this.fd = this.form.newFormData(this.sa);

    this.form1 = ExamAttendeeListForm.getInstance();
    this.fd1 = this.form.newFormData(this.sa);
    this.downloadForm = FormADownloadForm.getInstance();
    this.MakeupFormADownloadForm = MakeupFormADownloadForm.getInstance();
    this.MakeupFormADownloadFd = this.MakeupFormADownloadForm.newFormData(this.sa);
    this.downloadFd = this.downloadForm.newFormData(this.sa);
    this.downloadSettingsForm = FormAConfigForm.getInstance();
    this.downloadSettingsFd = this.downloadSettingsForm.newFormData(this.sa);
  }


  /**
   * 
   */
  ngOnInit() {
    this.metaDataInit();
    this.downloadSettingsFd.setFieldValue('dateOfIssue',new Date().getDate())
    this.fd.setFieldValue('isMakeup',true)
  }

  metaDataInit() {
    this.tableData = {
      data: [],
      metaData: {
        column_names: [
          "name",
          "usn",
          "department",
          "level",
          "section"
        ],
        display_names: {
          name: "Name",
          usn: "USN",
          department: "Department Name",
          level: "Level",
          section: "Section"
        },
        search: true,
        placeholder: "Search By Name, USN, Department"
      },
    };
  }

  public idChanged(id) {
    if (this.selectedId == id) {
      return;
    }
    this.selectedId = id;
    if (id) {
      this.getData(id);
    }
  }


  getData(id) {
    this.spinner.show('Getting attendance for this subject...');
    const payload = {
      offeredSubjectId: id,
      departmentId:this.fd.getFieldValue('departmentId'),
      isMakeup: true
    };
    this.fd1.fetchFor(payload).subscribe({
      next: data => {
        this.vo = data;
        this.tableData.data = data.students as [];
        this.gtable.update(this.spinner);
      },

      error: msg => {
        this.spinner.hide();
        this.ms.showError('Server returned with error.');
      }
    });
  }

  edit() {
    const d = { 
      offeredSubjectId: this.selectedId, 
      departmentId: this.vo.departmentId,
      isMakeup: this.fd.getFieldValue('isMakeup'),
      subjectName: this.fd.getDisplayedValueOf('offeredSubjectId')
    };
    this.parentPanel.navigate('MakeUpExamAttendanceEdit', d);
  }

  download() {
    this.downloadSettings = true;
  }

  goBack(){
    this.downloadSettings = false;
  }

  doDownload(){

    var download  ;
    var title = ''
    if (this.fd.getFieldValue('isMakeup') == true){
      download = this.MakeupFormADownloadFd
      if(this.ctx.getInstituteId() == 1){
        title = "B.E/B.Arch/M.B.A/M.C.A/M.Tech. _________ Make Up Examination __________ (MM/YYYY)"
      }
      if(this.ctx.getInstituteId() == 2){
        title = "B.A/B.Sc/B.B.A/B.C.A/B.Voc/B.Com _________ Make Up Examination __________ (MM/YYYY)"
      }
    }else if(this.fd.getFieldValue('isMakeup') == false)
    {
      download = this.downloadFd
      if(this.ctx.getInstituteId() == 1){
        title = "B.E/B.Arch/M.B.A/M.C.A/M.Tech. _________ Semester Examination __________ (MM/YYYY)"
      }
      if(this.ctx.getInstituteId() == 2){
        title = "B.A/B.Sc/B.B.A/B.C.A/B.Voc/B.Com _________ Semester Examination __________ (MM/YYYY)"
      }
    }
    if(!this.downloadSettingsFd.validateForm()){
      this.ms.showError("Please fill date and time to download!");
      return;
    }
    download.setFieldValue('offeredSubjectId',this.fd.getFieldValue('offeredSubjectId'));
    download.setFieldValue('isMakeup',this.fd.getFieldValue('isMakeup'));

    download.fetchData().subscribe(
      data=>{
        var programs = {};
        if(this.downloadSettingsFd.getFieldValue('departmentWise') == true){
          (data.students as unknown as any[]).forEach(element => {
            var pn = element["programName"];
            if(programs[pn] == undefined){
              programs[pn] = _.cloneDeep(data);
              programs[pn].students = [element];
            }
            else{
              programs[pn].students.push(element)
            }
          });
          Object.keys(programs).forEach(program=>{
            this.ds.downloadFormA(programs[program],program + " - " + this.fd.getDisplayedValueOf('offeredSubjectId'),this.downloadSettingsFd.getFieldValue('dateOfIssue'),this.downloadSettingsFd.getFieldValue('examTimings'),title);
          });
        }
        else{
          this.ds.downloadFormA(data,this.fd.getDisplayedValueOf('offeredSubjectId'),this.downloadSettingsFd.getFieldValue('dateOfIssue'),this.downloadSettingsFd.getFieldValue('examTimings'),title);
        }
        this.ms.showSuccess('Download Successful');
      }
    )
  }
}