
import { Form , Field, ChildForm } from '../form/form';
import { FormData } from '../form/formData';
import { SelectOption, Vo } from '../form/types';
import { Validators } from '@angular/forms'
import { ServiceAgent} from '../form/serviceAgent';

export class AssignablePacketForm extends Form {
	private static _instance = new AssignablePacketForm();

	public static getInstance(): AssignablePacketForm {
		return AssignablePacketForm._instance;
	}

	constructor() {
		super();
		this.fields = new Map();
		this.controls = new Map();
		this.opsAllowed = {filter: true};
	}

	public getName(): string {
		 return 'assignablePacket';
	}
}


export class AssignablePacketFd extends FormData {
	constructor(form: AssignablePacketForm, sa: ServiceAgent) {
		super(form, sa);
	}
	/**  this form has no editable fields. data nust be accessed as Vo and not through fd **/
}


export interface AssignablePacketVo extends Vo {
	isAssigned?: boolean, 
	packetCode?: string
}
