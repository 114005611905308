import { Component, OnInit, Input, ViewChild, ViewContainerRef, Type, ComponentFactoryResolver } from "@angular/core";
import { DynamicPanel, LandingPanel, GroupPanel } from '../interfaces'
import { SessionComponent, SecondaryMenu } from '../component';
import { MenuService } from 'src/app/services/menuService';
import { MessageService } from 'src/app/services/messageService';
import { ClientContext } from 'src/app/framework-modules/formdata/form/clientContext';
import { StudentArchiveComponent } from './student-archive/component';

/**
 Const to map the screen-component to screen-name to rended the facotry
 */
const MENU: SecondaryMenu = {
  StudentArchive: {comp: StudentArchiveComponent, title:"Student Archive", pageId:"D132",sideNav:false,mainNav:true},
};

@Component({
  selector: "dashboard",
  templateUrl: "./component.html",
  styleUrls: ["./component.scss"]
})
export class ArchiveComponent implements OnInit, GroupPanel {
  @Input() inputData: any;
  @Input() parentPanel: SessionComponent;
  sidenav: boolean = false;
  displayNames: {}
  cardHeader: any;
  defaultSelected: string;
  public items: {};
  screens: string[];
  defaultComponent: any;
  /**
    container for the child page loaded from the seconday navbar
   */

  @ViewChild("secondaryContainer", { static: true, read: ViewContainerRef }) container: ViewContainerRef;
  constructor(private componentFactoryResolver: ComponentFactoryResolver, private mns: MenuService, private ms: MessageService, private cc: ClientContext) {
    this.screens = this.mns.getScreens();
      Object.keys(MENU).forEach( key => {
      if(this.screens.includes(MENU[key].pageId) && this.defaultSelected == undefined){
        this.defaultSelected = MENU[key].name;
        this.cardHeader = MENU[key].title;
        this.defaultComponent = key;
      }
    })
   }

  ngOnInit() {
    this.navigate(this.defaultComponent, {});
    this.items = MENU;
  }

  /**
   *
   * @param compName is the name of the component to navigate to
   * @param data is for any related data needed
   */

  public navigate(compName: string, data: any): void {
    let m = MENU[compName]
    if(!this.cc.isPageValid(m.pageId)){
      this.ms.showInfo('Navigation Error: ' + compName + ' is not authorized for this user.');
      return;
    }
    this.parentPanel.toggleMainNav(m.mainNav);
    this.sidenav = m.sideNav;
    if (m.name != undefined) {
      this.defaultSelected = m.name;
    }
    this.cardHeader = m.title;
    this.container.clear();
    const factory = this.componentFactoryResolver.resolveComponentFactory(
      m.comp
    );
    const c = this.container.createComponent(factory);
    c.instance.inputData = data;
    c.instance.parentPanel = this;
    c.changeDetectorRef.detectChanges();
  }
  fullScreenMode(){
    this.sidenav = false;
    this.parentPanel.hideMainNav();
  }

  halfScreenMode(){
    this.sidenav = true;
    this.parentPanel.showMainNav();
  }
}
