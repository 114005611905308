import { Component, OnInit, ViewChild, Input, AfterViewInit } from "@angular/core";
import { GroupPanel } from '../../interfaces';
import { ServiceAgent } from 'src/app/framework-modules/formdata/form/serviceAgent';
import { SessionComponent } from '../../component';
import { FilterRequest } from 'src/app/framework-modules/formdata/form/types';
import { MessageService } from 'src/app/services/messageService';
import { ExcelDownloadService } from 'src/app/services/excelDownloadService';
import { PdcListTempUgForm,PdcListTempUgFd } from 'src/app/framework-modules/formdata/gen/pdcListTempUgForm';
import { CtSpinner } from 'src/app/framework-modules/elements/ct-spinner/component';
import { ClientContext } from 'src/app/framework-modules/formdata/form/clientContext';
import { MatDialog } from '@angular/material';
import { CtDialogComponent } from 'src/app/framework-modules/elements/ct-dialog/component';


@Component({
  selector: "pdc-edit",
  templateUrl: "./component.html",
  styleUrls: ["./component.scss"]
})


export class pdcEditComponent implements OnInit, AfterViewInit {
  @ViewChild("spinner", {static:false}) spinner: CtSpinner;
  @Input() inputData: any;
  @Input() parentPanel: GroupPanel;
  @Input() session: SessionComponent;
  form: PdcListTempUgForm;
  selectedSection = ''

  /**
 * for getting students-list
 */
  fd: PdcListTempUgFd;

  constructor(private sa: ServiceAgent, private ms: MessageService, private ds: ExcelDownloadService, private ctx: ClientContext,private dialog: MatDialog) {
    this.form = PdcListTempUgForm.getInstance();
    this.fd = PdcListTempUgForm.getInstance().newFormData(this.sa);
  }

  ngOnInit() {

  }

  ngAfterViewInit() {
    if (this.inputData && this.inputData.pdcTempUgId) {
      this.getData(this.inputData.pdcTempUgId);
    } else {
      this.ms.showError('Page invoked with invalid data');
    }
  }

  getData(id){
    this.fd.setFieldValue("pdcTempUgId",id)
    this.fd.setFieldValue("instituteId",this.ctx.getInstituteId())
    this.fd.fetchData().subscribe({
      next: data =>{
      },
      error: msg => {
        this.ms.showError('Server returned with an error');
      }
    });
  }

  download(data){
    this.ds.getPdc(data)
  }

  save() {
    let dialogRef = this.dialog.open(CtDialogComponent);
    dialogRef.componentInstance.title = 'Save Changes';
    dialogRef.componentInstance.content = 'Are you sure you want to save changes?';
    dialogRef.componentInstance.primary = 'Save';
    const subscribeDialog = dialogRef.componentInstance.save.subscribe((data) => {
      // Yet to implement bulk upload functionality
      this.dialog.closeAll();
      this.saveData();
    });
  }

  saveData(){
    this.fd.save().subscribe(
      data => {
        this.ms.showSuccess("Updated successfully!");
        this.download([this.fd.extractAll()])
        this.parentPanel.navigate('pdcDownload')
      },
      error => {
        this.ms.showError('Updated failed. Please try again');
      }
    );
  }

  cancel() {
    let dialogRef = this.dialog.open(CtDialogComponent);
    dialogRef.componentInstance.title = 'Discard Changes';
    dialogRef.componentInstance.content = 'Are you sure you want to discard changes?';
    dialogRef.componentInstance.primary = 'Discard';
    const subscribeDialog = dialogRef.componentInstance.save.subscribe((data) => {
      this.dialog.closeAll();
      this.parentPanel.navigate('pdcDownload')
    });
  }
}