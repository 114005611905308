import { Component, OnInit, ViewChild, Input} from "@angular/core";
import { DynamicPanel, GroupPanel } from '../../interfaces';
import { ServiceAgent } from 'src/app/framework-modules/formdata/form/serviceAgent';
import { TableMetaData } from 'src/app/framework-modules/elements/ct-table/component'
import { SessionComponent } from '../../component';
import { MatDialog } from '@angular/material';
import { CtCheckboxTableComponent } from 'src/app/framework-modules/elements/ct-checkbox-table/component';
import { ExamAttendanceForm, ExamAttendanceVo } from 'src/app/framework-modules/formdata/gen/examAttendanceForm';
import { CtDialogComponent } from 'src/app/framework-modules/elements/ct-dialog/component';
import { CtSpinner } from 'src/app/framework-modules/elements/ct-spinner/component';
import { PanelData } from 'src/app/framework-modules/formdata/form/formData';
import { MessageService } from 'src/app/services/messageService';
import { ExamRegisterVo } from 'src/app/framework-modules/formdata/gen/examRegisterForm';
import { FilterRequest } from 'src/app/framework-modules/formdata/form/types';
import { WithdrawlEligibilityFd, WithdrawlEligibilityForm } from 'src/app/framework-modules/formdata/gen/withdrawlEligibilityForm';

@Component({
  selector: "withdraw-subject-edit",
  templateUrl: "./component.html",
  styleUrls: ["./component.scss"],
})

export class WithdrawSubjectEditComponent implements DynamicPanel, OnInit {
  @ViewChild("gridTable", { static: false }) gtable: CtCheckboxTableComponent;
  @ViewChild("spinner", { static: false }) spinner: CtSpinner;
  @Input() inputData: any;
  @Input() parentPanel: GroupPanel;
  @Input() session: SessionComponent;

  public tableData: TableMetaData;
  private fd: WithdrawlEligibilityFd;
  public vo: ExamAttendanceVo;
  private students: ExamRegisterVo[];

  constructor(private sa: ServiceAgent,
    private dialog: MatDialog,
    private ms: MessageService) {
    this.fd = WithdrawlEligibilityForm.getInstance().newFormData(this.sa);
    this.vo = {};
    this.students = [];
  }

  ngOnInit() {
    this.metaDataInit();
  }

  ngAfterViewInit() {
    if (this.inputData) {
      this.fd.setFieldValue('subjectSectionId',this.inputData[0]);
      this.fd.setFieldValue('offeredSubjectId',this.inputData[1]);
      this.getData(this.inputData);
    } else {
      this.ms.showError('Page with no/invalid data');
    }
  }

  metaDataInit() {
    this.tableData = {
      data: [],
      metaData: {
        column_names: [
          "name",
          "usn",
          "overallAttendance"
        ],
        display_names: {
          name: "Name",
          usn: "USN",
          overallAttendance: "Attendance"
        },
        selectAttr:"hasWithdrawn",
        search: true,
        placeholder: "Search By Name,USN,Department Name...",
        managerFunction: "SAVE",
        itemName: "Students"
      },
    };
  }
  private getData(data) {
    this.spinner.show('Getting attendance register..');
    /**
     * offeredSubjectId is the primary key in the form
     */
    this.fd.fetchFor({subjectSectionId:this.inputData[0]}).subscribe({
      next: data =>{
        this.tableData.data = data.students as [];
        this.gtable.update();
        this.spinner.hide();
      },
      error: e=>{
        this.spinner.hide();
        this.ms.showError('server returned with errors');
      }
    });
  }


  private saveData() {
    this.fd.save().subscribe({
      next: data=>{
        this.ms.showSuccess("Student List Updated Successfully!");
        this.parentPanel.navigate("WithdrawSubjectList");
      },
      error: err=>{
        console.error(err);
        this.ms.showError('server returned with errors');
      }
    })
  }


  /**
   * Function is called when the user clicks save button.
   * Marks students selected as withrawn and goes back to WithdrawList.
   */
  save() {
    let dialogRef = this.dialog.open(CtDialogComponent);
    dialogRef.componentInstance.title = 'Save Changes';
    dialogRef.componentInstance.content = 'Students selected will be withdrawn from the subject. Are you sure you want to continue?';
    dialogRef.componentInstance.primary = 'Save';
    const subscribeDialog = dialogRef.componentInstance.save.subscribe((data) => {
      this.saveData();
      this.dialog.closeAll();
    });
  }

  /**
   * Function is called when the user clicks cancel button.
   * Goes back to assign Withdraw Subject list screen
   */
  cancel() {
    let dialogRef = this.dialog.open(CtDialogComponent);
    dialogRef.componentInstance.title = 'Discard Changes';
    dialogRef.componentInstance.content = 'Are you sure you want to discard changes?';
    dialogRef.componentInstance.primary = 'Discard';
    const subscribeDialog = dialogRef.componentInstance.save.subscribe((data) => {
      this.dialog.closeAll();
      this.parentPanel.navigate("WithdrawSubjectList");
    });
  }
}