import { Component,Input, Output, EventEmitter, OnInit, AfterViewInit } from "@angular/core";
import { DynamicPanel, LandingPanel, GroupPanel } from '../../interfaces';
import { ServiceAgent } from 'src/app/framework-modules/formdata/form/serviceAgent';
import { SessionComponent } from '../../component';
import { FormData, TabularData } from 'src/app/framework-modules/formdata/form/formData';
import { CtDialogComponent } from 'src/app/framework-modules/elements/ct-dialog/component';
import { MatDialog } from '@angular/material';
import { MessageService } from 'src/app/services/messageService';
import { StudentNameForm } from 'src/app/framework-modules/formdata/gen/studentNameForm';
import { GuardianNamesForm } from 'src/app/framework-modules/formdata/gen/guardianNamesForm';

@Component({
  selector: "student-name-edit",
  templateUrl: "./component.html",
  styleUrls: ["./component.scss"]
})


export class StudentNamesEditComponent implements DynamicPanel,OnInit{
  @Input() inputData: any;
  @Input() parentPanel: GroupPanel;
  @Input() session:SessionComponent;
  
  public form:StudentNameForm;
  public guardianForm:GuardianNamesForm;
  public fd:FormData;

  public child:TabularData;
  public guardians: FormData[];
  public items: {};
  selected: any;
  order: number=1;
  
  public addressFlags: boolean[] = [false, false];
  public presentIsPermanent: boolean = false;

  constructor(private sa:ServiceAgent, private ms: MessageService, private dialog: MatDialog){
    this.form = StudentNameForm.getInstance();
    this.fd = new FormData(this.form,this.sa);
    this.guardianForm = GuardianNamesForm.getInstance();
    this.selected = "studentDetails";
  }

  ngOnInit() {
    this.fd.setFieldValue('studentId', this.inputData.studentId)
    this.fd.fetchData().subscribe({
    	next: vo=>{
        this.fetchDefaultParentData();
      },
    	error: msg => console.error("Error from server ", msg)
    });
  }

  save(){
    let dialogRef = this.dialog.open(CtDialogComponent);
    dialogRef.componentInstance.title = 'Save Changes';
    dialogRef.componentInstance.content = 'Are you sure you want to save changes?';
    dialogRef.componentInstance.primary = 'Save';
    const subscribeDialog = dialogRef.componentInstance.save.subscribe((data) => {
      this.dialog.closeAll();
      this.doSave();
    });
  }

  doSave() {
    this.fd.save().subscribe({
      next: vo => {
        this.ms.showSuccess("Student details changed successfully! ");
        this.parentPanel.navigate(this.inputData.navigatedFrom);
      },
      error: err => {
        console.error(err);
        this.ms.showError(err);
      }
    });
  }

  doCancel() {
    let dialogRef = this.dialog.open(CtDialogComponent);
    dialogRef.componentInstance.title = 'Discard Changes';
    dialogRef.componentInstance.content = 'Are you sure you want to discard changes?';
    dialogRef.componentInstance.primary = 'Discard';
    const subscribeDialog = dialogRef.componentInstance.save.subscribe((data) => {
      this.dialog.closeAll();
      this.parentPanel.navigate(this.inputData.navigatedFrom);
    });
    
  }

  /**
   * father and mother are mandatory as of now. T
   */
  fetchDefaultParentData(){
    this.child = this.fd.getChildTable('guardians');
    this.guardians = this.child.childData as FormData[];
    }

  /**
   * order of the guardians has no consequence.
   * hence no facility to insert. we always append.
   */
  addGuardian() {
    const fd = this.child.appendRow();
    fd.setFieldValue('relationType', "Legal Guardian");
    console.log('Guardina added.');
    this.addressFlags.push(false);
  }

  /**
   * 
   * @param k 0-based index of the guardina
   */
  removeGuardian(k: number) {
    this.child.removeRow(k);
    console.log('Guardina removed at .',k);
  }

}