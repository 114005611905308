
import { Form , Field, ChildForm } from '../form/form';
import { FormData } from '../form/formData';
import { SelectOption, Vo } from '../form/types';
import { Validators } from '@angular/forms'
import { ServiceAgent} from '../form/serviceAgent';

export class OfferableSubjectForm extends Form {
	private static _instance = new OfferableSubjectForm();
	subjectId:Field = {
		name:'subjectId'
		,controlType: 'Hidden'
		,label: 'subjectId'
		,isRequired: true
		,valueType: 1
		,errorId: 'invalidId'
		,maxValue: 9999999999999
	};
	name:Field = {
		name:'name'
		,controlType: 'Output'
		,label: 'Subject Name'
		,valueType: 0
		,errorId: 'invalidName'
		,maxLength: 150
	};
	code:Field = {
		name:'code'
		,controlType: 'Input'
		,label: 'Subject Code'
		,valueType: 0
		,errorId: 'invalidCode'
		,maxLength: 50
	};
	departmentName:Field = {
		name:'departmentName'
		,controlType: 'Output'
		,label: 'Subject Name'
		,valueType: 0
		,errorId: 'invalidName'
		,maxLength: 150
	};
	offeredSections:Field = {
		name:'offeredSections'
		,controlType: 'Output'
		,label: 'Sections'
		,valueType: 0
		,defaultValue: ''
		,errorId: 'invalidText'
		,maxLength: 1000
	};

	public static getInstance(): OfferableSubjectForm {
		return OfferableSubjectForm._instance;
	}

	constructor() {
		super();
		this.fields = new Map();
		this.controls = new Map();
		this.controls.set('subjectId', [Validators.required, Validators.max(9999999999999)]);
		this.fields.set('subjectId', this.subjectId);
		this.controls.set('name', [Validators.maxLength(150)]);
		this.fields.set('name', this.name);
		this.controls.set('code', [Validators.maxLength(50)]);
		this.fields.set('code', this.code);
		this.controls.set('departmentName', [Validators.maxLength(150)]);
		this.fields.set('departmentName', this.departmentName);
		this.controls.set('offeredSections', [Validators.maxLength(1000)]);
		this.fields.set('offeredSections', this.offeredSections);
		this.opsAllowed = {filter: true, get: true};
		this.keyFields = ["subjectId"];
	}

	public getName(): string {
		 return 'offerableSubject';
	}
}


export class OfferableSubjectFd extends FormData {
	constructor(form: OfferableSubjectForm, sa: ServiceAgent) {
		super(form, sa);
	}

	setFieldValue(name: 'subjectId' | 'name' | 'code' | 'departmentName' | 'offeredSections', value: string | number | boolean | null ): void {
		super.setFieldValue(name, value);
	}

	getFieldValue(name: 'subjectId' | 'name' | 'code' | 'departmentName' | 'offeredSections' ): string | number | boolean | null {
		return super.getFieldValue(name);
	}
}


export interface OfferableSubjectVo extends Vo {
	departmentName?: string, 
	code?: string, 
	departmentId?: number, 
	name?: string, 
	instituteId?: number, 
	offeredSections?: string, 
	subjectId?: number
}
