import { Component, Input, OnInit, ViewChild, AfterViewInit } from "@angular/core";
import { DynamicPanel, GroupPanel } from '../../interfaces';
import { ServiceAgent } from 'src/app/framework-modules/formdata/form/serviceAgent';
import { InstituteGradeStatisticsForm, InstituteGradeStatisticsFd } from 'src/app/framework-modules/formdata/gen/instituteGradeStatisticsForm';
import { BarChartMetaData } from 'src/app/framework-modules/elements/ct-barchart/component';
import { OfferedSectionSelectionForm, OfferedSectionSelectionFd } from 'src/app/framework-modules/formdata/gen/offeredSectionSelectionForm';
import { SubjectGradeStatisticsForm, SubjectGradeStatisticsFd } from 'src/app/framework-modules/formdata/gen/subjectGradeStatisticsForm';
import { FilterRequest } from 'src/app/framework-modules/formdata/form/types';
import { AdvancedPieChartMetaData } from 'src/app/framework-modules/elements/ct-advanced-piechart/component';
import { ClientContext } from 'src/app/framework-modules/formdata/form/clientContext';
import { InstituteConfigurationForm, InstituteConfigurationFd, InstituteConfigurationVo } from 'src/app/framework-modules/formdata/gen/instituteConfigurationForm';
import { CtSpinner } from 'src/app/framework-modules/elements/ct-spinner/component';
import { CtTableComponent, TableMetaData } from 'src/app/framework-modules/elements/ct-table/component';
import { InstituteGpaStatisticsFd, InstituteGpaStatisticsForm } from 'src/app/framework-modules/formdata/gen/instituteGpaStatisticsForm';
import { ProgramLevelListFd, ProgramLevelListForm } from 'src/app/framework-modules/formdata/gen/programLevelListForm';
import { SubjectwiseResultFd, SubjectwiseResultForm, SubjectwiseResultVo } from 'src/app/framework-modules/formdata/gen/subjectwiseResultForm';
import { OfferedSubjectDetailFd, OfferedSubjectDetailForm } from 'src/app/framework-modules/formdata/gen/offeredSubjectDetailForm';
import { EnrolledStudentDetailFd, EnrolledStudentDetailForm } from 'src/app/framework-modules/formdata/gen/enrolledStudentDetailForm';
import { SeasonSubjectListFd, SeasonSubjectListForm } from 'src/app/framework-modules/formdata/gen/seasonSubjectListForm';
import { ExcelDownloadService } from 'src/app/services/excelDownloadService';
import { AdmissionQuotaStudentForm, AdmissionQuotaStudentFd } from 'src/app/framework-modules/formdata/gen/admissionQuotaStudentForm';
import { AdmissionQuotaSubjectPerformanceForm, AdmissionQuotaSubjectPerformanceFd } from 'src/app/framework-modules/formdata/gen/admissionQuotaSubjectPerformanceForm';

@Component({
  selector: "dashboard-list",
  templateUrl: "./component.html",
  styleUrls: ["./component.scss"]
})
export class DashBoardListComponent implements DynamicPanel, AfterViewInit {
  @ViewChild("spinner", {static:false}) spinner: CtSpinner;
  @ViewChild("gridTable", { static: false }) gtable: CtTableComponent;
  @ViewChild("gpaTable", { static: false }) gpaTable: CtTableComponent;
  @ViewChild("topFiveTable", { static: false }) topFiveTable: CtTableComponent;
  @ViewChild("bottomFiveTable", { static: false }) bottomFiveTable: CtTableComponent;
  @ViewChild("degreeTopTenTable", { static: false }) degreeTopTenTable: CtTableComponent;
  @ViewChild("degreeBottomTenTable", { static: false }) degreeBottomTenTable: CtTableComponent;
  @ViewChild("programTopFiveTable", { static: false }) programTopFiveTable: CtTableComponent;
  @ViewChild("programBottomFiveTable", { static: false }) programBottomFiveTable: CtTableComponent;
  @ViewChild("admissionQuotaICDTable", { static: false }) admissionQuotaICDTable: CtTableComponent;
  @ViewChild("admissionQuotaICDSubjectPerformanceTable", { static: false }) admissionQuotaICDSubjectPerformanceTable: CtTableComponent;
  @Input() inputData: any;
  @Input() parentPanel: GroupPanel;

  public consolidatedBarMetaData: BarChartMetaData;
  public consolidatedBarData: {}[];
  public tableData: TableMetaData;
  public degreeTopTen: TableMetaData;
  public degreeBottomTen: TableMetaData;
  public programTopFive: TableMetaData;
  public programBottomFive: TableMetaData;
  public admissionQuotaICD: TableMetaData;
  public admissionQuotaICDSubjectPerformance: TableMetaData;
  public subjectBarData:{}[];
  public subjectBarMetaData:BarChartMetaData;
  public subjectPieMetaData:AdvancedPieChartMetaData;
  public form: InstituteGradeStatisticsForm;
  public fd: InstituteGradeStatisticsFd;
  public offeredSubjectForm: OfferedSectionSelectionForm;
  public offeredSubjectFd: OfferedSectionSelectionFd;
  public subjectGradesForm: SubjectGradeStatisticsForm;
  public subjectGradesFd: SubjectGradeStatisticsFd;
  public gpaTableData: TableMetaData;
  public consolidatedGpaMetaData: BarChartMetaData;
  public consolidatedGpaData: {}[];
  public instituteGpaForm: InstituteGpaStatisticsForm;
  public instituteGpaFd: InstituteGpaStatisticsFd;
  public programForm: ProgramLevelListForm;
  public programFd: ProgramLevelListFd;
  public programForm2: ProgramLevelListForm;
  public programFd2: ProgramLevelListFd;
  public programForm3: ProgramLevelListForm;
  public programFd3: ProgramLevelListFd;
  public programForm4: ProgramLevelListForm;
  public programFd4: ProgramLevelListFd;
  public enrollmentsForm : SubjectwiseResultForm
  public enrollmentsFd : SubjectwiseResultFd
  public enrollmentsVo: SubjectwiseResultVo = {};
  public topFiveData: TableMetaData;
  public bottomFiveData: TableMetaData;
  public degreeForm: OfferedSubjectDetailForm;
  public degreeFd: OfferedSubjectDetailFd;
  public enrolledStudentsForm: EnrolledStudentDetailForm;
  public enrolledStudentsFd: EnrolledStudentDetailFd;
  public seasonSubjectForm: SeasonSubjectListForm;
  public seasonSubjectFd: SeasonSubjectListFd;
  public seasonSubjectForm1: SeasonSubjectListForm;
  public seasonSubjectFd1: SeasonSubjectListFd;
  public admissionQuotaForm : AdmissionQuotaStudentForm;
  public admissionQuotaFd : AdmissionQuotaStudentFd;
  public degreeForm1: OfferedSubjectDetailForm;
  public degreeFd1: OfferedSubjectDetailFd;
  public admissionQuotaSubjectPerformanceForm : AdmissionQuotaSubjectPerformanceForm;
  public admissionQuotaSubjectPerformanceFd : AdmissionQuotaSubjectPerformanceFd;

  selectedSubject: any;
  switchName: 'Swtich To Subject Wise View' | 'Switch to Institute Level View' = 'Swtich To Subject Wise View'
  subjectWise: boolean = false;
  subjectTotal: number;
  gpaTotal: number;
  selectedSeason: number;
  selectedLevel: number;
  selectedDegree: number;

  constructor(private sa: ServiceAgent, private cc: ClientContext, private ds: ExcelDownloadService){
    this.form = InstituteGradeStatisticsForm.getInstance();
    this.fd = this.form.newFormData(this.sa);
    this.offeredSubjectForm = OfferedSectionSelectionForm.getInstance();
    this.offeredSubjectFd = this.offeredSubjectForm.newFormData(this.sa);
    this.subjectGradesForm = SubjectGradeStatisticsForm.getInstance();
    this.subjectGradesFd = this.subjectGradesForm.newFormData(this.sa);
    this.instituteGpaForm = InstituteGpaStatisticsForm.getInstance();
    this.instituteGpaFd = this.instituteGpaForm.newFormData(this.sa);
    this.programForm = ProgramLevelListForm.getInstance();
    this.programFd = this.programForm.newFormData(this.sa);
    this.programForm2 = ProgramLevelListForm.getInstance();
    this.programFd2 = this.programForm2.newFormData(this.sa);
    this.programForm3 = ProgramLevelListForm.getInstance();
    this.programFd3 = this.programForm3.newFormData(this.sa);
    this.programForm4 = ProgramLevelListForm.getInstance();
    this.programFd4 = this.programForm4.newFormData(this.sa);
    this.enrollmentsForm = SubjectwiseResultForm.getInstance();
    this.enrollmentsFd = this.enrollmentsForm.newFormData(this.sa);
    this.degreeForm = OfferedSubjectDetailForm.getInstance();
    this.degreeFd = this.degreeForm.newFormData(this.sa);
    this.enrolledStudentsForm = EnrolledStudentDetailForm.getInstance();
    this.enrolledStudentsFd = this.enrolledStudentsForm.newFormData(this.sa);
    this.seasonSubjectForm = SeasonSubjectListForm.getInstance();
    this.seasonSubjectFd = this.seasonSubjectForm.newFormData(this.sa);
    this.seasonSubjectForm1 = SeasonSubjectListForm.getInstance();
    this.seasonSubjectFd1 = this.seasonSubjectForm1.newFormData(this.sa);
    this.admissionQuotaForm = AdmissionQuotaStudentForm.getInstance();
    this.admissionQuotaFd = this.admissionQuotaForm.newFormData(this.sa);
    this.degreeForm1 = OfferedSubjectDetailForm.getInstance();
    this.degreeFd1 = this.degreeForm1.newFormData(this.sa);
    this.admissionQuotaSubjectPerformanceForm = AdmissionQuotaSubjectPerformanceForm.getInstance();
    this.admissionQuotaSubjectPerformanceFd = this.admissionQuotaSubjectPerformanceForm.newFormData(this.sa);
  }

  ngAfterViewInit() {
    this.ChartDataInit();
    var instituteId = this.cc.getInstituteId();
    this.selectedSeason = this.cc.getcurrentSeasonId();
    this.programFd.setFieldValue('seasonId',this.cc.getcurrentSeasonId());
    this.getInstituteGrade();
    this.getInstituteGpa();
  }

  getChartData() {
    this.getInstituteGrade();
    this.getInstituteGpa();
  }

  getInstituteGrade(){
    const payload = {seasonId: this.selectedSeason};
    this.fd.fetchFor(payload).subscribe({
      next: data =>{
        var gradeStats = data as unknown as {}[];
        this.consolidatedBarData = [];
        gradeStats.forEach(grade =>{
          this.consolidatedBarData.push(
            {"name":grade['grade'],"value":grade['nbr'],tooltipText: 'Click to View More'}
          );
        });
        this.getPercentages(this.consolidatedBarData,this.tableData,this.gtable,this.subjectTotal);
        this.spinner.hide();
      }
    });
  }

  /**
   * Get the institute Grade statistics
   */
  getInstituteGpa(){
    const f: FilterRequest = {
      conditions: {
        seasonId: {value:this.selectedSeason, comp:'='},
        instituteId: {value: this.cc.getInstituteId(), comp:'='}
      }
    }
    this.instituteGpaFd.filter(f).subscribe({
      next: data =>{
        var gpaStats = data as unknown as {}[];
        this.consolidatedGpaData = [];
        gpaStats.forEach(gpa =>{
          this.consolidatedGpaData.push(
            {"name":gpa['gpaRange'],"value":gpa['count']}
          );
        });
        this.getPercentages(this.consolidatedGpaData,this.gpaTableData,this.gpaTable,this.gpaTotal)
      }
    })
  }

  getPercentages(array: Array<{}>,tableData: TableMetaData, table: CtTableComponent,total: number){
    total = 0;
    array.forEach( data =>{
      total += data['value'];
    });
    tableData.data = [];
    array.forEach(element => {
      var percentage = Math.round(element['value']*10000/total)/100 + '%';
      tableData.data.push(
        {"name": element['name'],"value":percentage}
      )
    });
    table.update()
  }
  
  /**
   * Initialize the metadata for charts
   */
  ChartDataInit() {
    this.consolidatedBarMetaData = {
      view:[650,300],
      xAxisLabel: "Grades",
      yAxisLabel: "Students",
      showLegend: false,
      customToolTip: true
    };

    this.consolidatedGpaMetaData = {
      view:[650,300],
      xAxisLabel: "CGPA",
      yAxisLabel: "Students",
      showLegend: false,
    };

    this.subjectBarMetaData = {
      scheme: {
        domain: ['#cad4f4', '#c7e5ee', '#bce7da', '#dfedcc', '#f7eac3', '#faddcb', '#f6c2bc','#f3a7ab','#EE958F']
      },
      view: [650,300],
      xAxisLabel: "Grades",
      yAxisLabel: "Students",
      showLegend: false
    };

    this.tableData = {
      data: [],
      metaData: {
        column_names: [
          'name',
          'value'
        ],
        display_names: {
          'name': "Grade",
          'value': "Percentage"
        }
      }
    }

    this.gpaTableData ={data: [],metaData: {  column_names: [    'name',    'value'  ],  display_names: {    'name': "GPA Range",    'value': "Percentage of Students"  }}};

    this.topFiveData = {data: [],metaData: {  column_names: [    "usn",    "name",    "cieMarks",    "examMarks",    "totalMarks",    "grade"  ],  display_names:{    "usn":"USN",    "name":"Name",    "cieMarks":"CIE",    "examMarks":"Exam",    "totalMarks":"Total Marks",    "grade": "Grade"  }}}

    this.bottomFiveData = {data: [],metaData: {  column_names: [    "usn",    "name",    "cieMarks",    "examMarks",    "totalMarks",    "grade"  ],  display_names:{    "usn":"USN",    "name":"Name",    "cieMarks":"CIE",    "examMarks":"Exam",    "totalMarks":"Total Marks",    "grade": "Grade"  }}}

    this.degreeTopTen = {data: [],metaData: {  column_names: [    "name",    "usn",    "sgpa",    "cgpa",  ],  display_names:{    "name": "Name",    "usn": "USN",    "sgpa":"SGPA",    "cgpa":"CGPA",  }}};

    this.degreeBottomTen = {data: [],metaData: {  column_names: [    "name",    "usn",    "sgpa",    "cgpa",  ],  display_names:{    "name": "Name",    "usn": "USN",    "sgpa":"SGPA",    "cgpa":"CGPA",  }}};

    this.programBottomFive = {
      data: [],
      metaData: {
        column_names: [
          "name",
          "usn",
          "sgpa",
          "cgpa",
        ],
        display_names:{
          "name": "Name",
          "usn": "USN",
          "sgpa":"SGPA",
          "cgpa":"CGPA",
        }
      }
    };

    this.programTopFive = {
      data: [],
      metaData: {
        column_names: [
          "name",
          "usn",
          "sgpa",
          "cgpa",
        ],
        display_names:{
          "name": "Name",
          "usn": "USN",
          "sgpa":"SGPA",
          "cgpa":"CGPA",
        }
      }
    };

    this.admissionQuotaICD = {
      data: [],
      metaData: {
        column_names: [
          "name",
          "usn",
          "sgpa",
          "cgpa",
        ],
        display_names:{
          "name": "Student Name",
          "usn": "USN",
          "sgpa":"SGPA",
          "cgpa":"CGPA",
        }
      }
    };

    this.admissionQuotaICDSubjectPerformance = {
      data: [],
      metaData: {
        column_names: [
          "usn",
          "name",
          "cieMarks",
          "totalMarks",
          "grade",
        ],
        display_names:{
          "usn": "USN",
          "name": "Student Name",
          "cieMarks": "CIE Marks",
          "totalMarks": "Total Marks",
          "grade": "Grade",
        }
      }
    };
  }

  /**
   * Triggered when the user clicks any grade from the consolidated bar chart
   * Forms the filter request to get all students in all subjects and selected grade
   * Navigates to Dashboard View
   * @param $event grade chosen from the consolidated bar chart
   */
  selectedGrade($event: any){
    const f: FilterRequest = {
      conditions: {
        grade: {comp:'=',value: $event.name},
        instituteId: {comp: '=', value:this.cc.getInstituteId()},
        seasonId: {comp:'=',value:this.selectedSeason}
      }
    };
    this.parentPanel.navigate('DashboardView',[f,this.programFd.getDisplayedValueOf('seasonId'),true]);
  }

  /**
   * Triggered when the user clicks any grade from the subject bar chart
   * Forms the filter request to get all students in selected subject and selected grade
   * Navigates to Dashboard View
   * @param $event grade chosen from the subject bar chart
   */
  selectedSubjectGrade($event: any){
    const f: FilterRequest = {
      conditions: {
        offeredSubjectId: {comp:'=',value:this.selectedSubject},
        grade: {comp:'=',value: $event.name},
        instituteId: {comp: '=', value:this.cc.getInstituteId()},
        seasonId: {comp:'=',value:this.seasonSubjectFd.getFieldValue('seasonId')}
      }
    };
    this.parentPanel.navigate('DashboardView',[f,this.seasonSubjectFd.getDisplayedValueOf('seasonId')+'-'+this.seasonSubjectFd.getDisplayedValueOf('seasonOfferedSubjectId'),false]);
  }

  /**
   * Subject change listner for the offered subject Drop down
   * Gets the data for selected subject and populates the subject Bar chart
   * @param $event Offered Subject ID from the offered subject Drop down
   */
  changeSubject($event){
    this.selectedSubject = $event;
    this.changeSubjectWisePerformance($event)
    const payload = {offeredSubjectId: $event,seasonId: this.seasonSubjectFd.getFieldValue('seasonId')}
    this.subjectGradesFd.fetchFor(payload).subscribe({
      next: data =>{
        var subjectGradeStats = data as unknown as {}[];
        this.subjectBarData = [];
        subjectGradeStats.forEach(grade =>{
          this.subjectBarData.push(
            {"name":grade['grade'],"value":grade['nbr'],tooltipText: 'Click to View More'}
          );
        });
        this.getPercentages(this.subjectBarData,this.tableData,this.gtable,this.subjectTotal);
      }
    });
    this.getFives($event)
  }

  getFives($event){
    const f: FilterRequest = {
      conditions: {
        seasonId: {comp: "=", value:this.seasonSubjectFd.getFieldValue('seasonId')},
        offeredSubjectId: {comp: "=", value: $event}
      },
      sort: {totalMarks: "desc"}
    }
    this.enrollmentsFd.filter(f).subscribe({
      next: data =>{
        this.topFiveData.data = data.splice(0,5);
        this.bottomFiveData.data = data.splice(-5).reverse();
        this.topFiveTable.update();
        this.bottomFiveTable.update();
      }
    })
  }

  /**
   * Toggle Between subjectwise and Institute wise grades
   */
  toggle(){
    var offeredSubjectId = this.seasonSubjectFd.getFieldValue('seasonOfferedSubjectId');
    this.subjectWise = !this.subjectWise;
    if(this.subjectWise){
      this.changeSubject(offeredSubjectId);
      this.changeSubjectWisePerformance(offeredSubjectId);
      this.switchName='Switch to Institute Level View';
    } 
    else{
      this.switchName='Swtich To Subject Wise View';
      this.getChartData();
      this.changeLevel();
      this.changeProgramLevel();
    }
  }

  changeSeason($event){
    this.selectedSeason = $event;
    this.getChartData();
  }

  changeLevel($event?){
    if(this.programFd2.getFieldValue('seasonId') !=null && this.degreeFd.getFieldValue('levelId')!=null){
    const f: FilterRequest ={
      conditions: {
        degreeId: {comp: '=', value:this.degreeFd.getFieldValue('degreeId')},
        levelId: {comp: '=', value: this.degreeFd.getFieldValue('levelId')},
        seasonId: {comp:'=',value:this.programFd2.getFieldValue('seasonId')}
      },
      sort: {
        cgpa: "desc"
      }
    }
    this.spinner.show('Loading');
    this.enrolledStudentsFd.filter(f).subscribe(
      data =>{
        this.degreeTopTen.data = data.splice(0,20);
        this.degreeBottomTen.data = data.splice(-20).reverse();
        this.degreeBottomTenTable.update();
        this.degreeTopTenTable.update();
        this.spinner.hide();
      }
    )
    }
  }

  changeICDStudentTable($event){
    var seasonId= this.programFd4.getFieldValue('seasonId')
    var levelId = this.degreeFd1.getFieldValue('levelId')
    var degreeId= this.degreeFd1.getFieldValue('degreeId')
    if(degreeId && levelId && seasonId){
      const payload = {seasonId: this.programFd4.getFieldValue('seasonId'),
                      levelId : this.degreeFd1.getFieldValue('levelId'),
                      degreeId : this.degreeFd1.getFieldValue('degreeId'),
                      admissionQuota : 'ICD'};
      this.admissionQuotaFd.fetchFor(payload).subscribe({
        next:data => {
          this.admissionQuotaICD.data = data as unknown as [];
          this.admissionQuotaICDTable.update();
        }
      });
    }
  }
  
  changeSubjectWisePerformance($event){
    var seasonId= this.seasonSubjectFd.getFieldValue('seasonId')
    var subjectId = this.seasonSubjectFd.getFieldValue('seasonOfferedSubjectId')
    if(subjectId && seasonId){
      const payload = {seasonId: this.seasonSubjectFd.getFieldValue('seasonId'),
                       subjectId : this.seasonSubjectFd.getFieldValue('seasonOfferedSubjectId'),
                       admissionQuota : 'ICD'};
      this.admissionQuotaSubjectPerformanceFd.fetchFor(payload).subscribe({
        next:data => {
          this.admissionQuotaICDSubjectPerformance.data = data as unknown as [];
          this.admissionQuotaICDSubjectPerformanceTable.update();
        }
      });
    }
  }

  changeProgramLevel($event?){
    if(this.programFd3.getFieldValue('seasonId') !=null && this.programFd3.getFieldValue('programLevelId')!=null){
    const f: FilterRequest ={
      conditions: {
        programId: {comp: '=', value:this.programFd3.getFieldValue('programId')},
        levelId: {comp: '=', value: this.programFd3.getFieldValue('programLevelId')},
        seasonId: {comp:'=',value:this.programFd3.getFieldValue('seasonId')}
      },
      sort: {
        cgpa: "desc"
      }
    }
    this.spinner.show('Loading');
    this.enrolledStudentsFd.filter(f).subscribe(
      data =>{
        this.programTopFive.data = data.splice(0,5);
        this.programBottomFive.data = data.splice(-5).reverse();
        this.programBottomFiveTable.update();
        this.programTopFiveTable.update();
        this.spinner.hide();
      }
    )}
  }

  download(tableData: TableMetaData,name: string){
    var excelData = []
    var cols = tableData.metaData.column_names;
    var displayMap = tableData.metaData.display_names;

    tableData.data.forEach(d => {
      var row = {}
      cols.forEach(col =>{
        row[displayMap[col]] = d[col]
      });
      excelData.push(row);
    });

    this.ds.downloadExcelBook({"Sheet1": excelData},name);
  }

  gotoValuationActivities(){
    this.parentPanel.navigate("ValuationActivities");
  }
}