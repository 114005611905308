
import { Form , Field, ChildForm } from '../form/form';
import { FormData } from '../form/formData';
import { SelectOption, Vo } from '../form/types';
import { Validators } from '@angular/forms'
import { ServiceAgent} from '../form/serviceAgent';
import { PaperValuationForm, PaperValuationVo } from './paperValuationForm';

export class PapersInPacketForm extends Form {
	private static _instance = new PapersInPacketForm();

	papers: ChildForm = {
		name:'papers',
		form:PaperValuationForm.getInstance(),
		isEditable:false,
		isTabular:true,
		label:'',
		minRows:0,
		maxRows:0,
		errorId:null
	};

	public static getInstance(): PapersInPacketForm {
		return PapersInPacketForm._instance;
	}

	constructor() {
		super();
		this.fields = new Map();
		this.controls = new Map();

		this.childForms = new Map();
		this.childForms.set('papers', this.papers);
		this.opsAllowed = {get: true, update: true};
	}

	public getName(): string {
		 return 'papersInPacket';
	}
}


export class PapersInPacketFd extends FormData {
	constructor(form: PapersInPacketForm, sa: ServiceAgent) {
		super(form, sa);
	}
	/**  this form has no editable fields. data nust be accessed as Vo and not through fd **/
}


export interface PapersInPacketVo extends Vo {
	offeredSubjectId?: number, 
	phoneNumber?: string, 
	valuationRound?: number, 
	isMakeUp?: boolean, 
	name?: string, 
	instituteId?: number, 
	assignedTo?: number, 
	valuationType?: number, 
	packetCode?: string, 
	papers?: PaperValuationVo
}
