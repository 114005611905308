
import { Form , Field, ChildForm } from '../form/form';
import { FormData } from '../form/formData';
import { SelectOption, Vo } from '../form/types';
import { Validators } from '@angular/forms'
import { ServiceAgent} from '../form/serviceAgent';
import { GuardianForm, GuardianVo } from './guardianForm';

export class StudentForm extends Form {
	private static _instance = new StudentForm();
	studentId:Field = {
		name:'studentId'
		,controlType: 'Hidden'
		,label: 'studentId'
		,isRequired: true
		,valueType: 1
		,defaultValue: -1
		,errorId: 'invalidFlexibleId'
		,minValue: -1
		,maxValue: 9999999999999
	};
	instituteId:Field = {
		name:'instituteId'
		,controlType: 'Hidden'
		,label: 'instituteId'
		,valueType: 1
		,errorId: 'invalidTenentKey'
		,maxValue: 9999999999999
	};
	departmentId:Field = {
		name:'departmentId'
		,controlType: 'Dropdown'
		,label: 'Department'
		,isRequired: true
		,listName: 'departmentList'
		,valueType: 1
		,errorId: 'invalidId'
		,maxValue: 9999999999999
	};
	programId:Field = {
		name:'programId'
		,controlType: 'Dropdown'
		,label: 'Program'
		,isRequired: true
		,listName: 'programList'
		,listKey: 'departmentId'
		,valueType: 1
		,errorId: 'invalidId'
		,maxValue: 9999999999999
	};
	levelId:Field = {
		name:'levelId'
		,controlType: 'Dropdown'
		,label: 'Level'
		,isRequired: true
		,listName: 'programLevelList'
		,listKey: 'programId'
		,valueType: 1
		,errorId: 'invalidId'
		,maxValue: 9999999999999
	};
	levelSectionId:Field = {
		name:'levelSectionId'
		,controlType: 'Dropdown'
		,label: 'Section'
		,isRequired: true
		,listName: 'levelSectionList'
		,listKey: 'levelId'
		,valueType: 1
		,errorId: 'invalidId'
		,maxValue: 9999999999999
	};
	usn:Field = {
		name:'usn'
		,controlType: 'Input'
		,label: 'USN'
		,isRequired: true
		,valueType: 0
		,errorId: 'invalidText'
		,maxLength: 1000
	};
	tempUsn:Field = {
		name:'tempUsn'
		,controlType: 'Input'
		,label: 'Temporary USN'
		,valueType: 0
		,errorId: 'invalidText'
		,maxLength: 1000
	};
	name:Field = {
		name:'name'
		,controlType: 'Input'
		,label: 'Name'
		,isRequired: true
		,valueType: 0
		,errorId: 'invalidName'
		,maxLength: 150
	};
	gender:Field = {
		name:'gender'
		,controlType: 'Dropdown'
		,label: 'Gender'
		,listName: 'gender'
		,valueList: [
			{value:'Male',text:'Male'},
			{value:'Female',text:'Female'},
			{value:'Others',text:'Others'},
			{value:'Not Applicable',text:'Not Applicable'}
			]
		,valueType: 0
		,errorId: 'invalidGender'
		,maxLength: 10
	};
	presentAddressLine1:Field = {
		name:'presentAddressLine1'
		,controlType: 'Input'
		,label: 'Address - Line 1'
		,valueType: 0
		,errorId: 'invalidDesc'
		,maxLength: 1000
	};
	presentAddressLine2:Field = {
		name:'presentAddressLine2'
		,controlType: 'Input'
		,label: 'Address - Line 2'
		,valueType: 0
		,errorId: 'invalidDesc'
		,maxLength: 1000
	};
	presentCity:Field = {
		name:'presentCity'
		,controlType: 'Input'
		,label: 'City'
		,valueType: 0
		,errorId: 'invalidName'
		,maxLength: 150
	};
	presentState:Field = {
		name:'presentState'
		,controlType: 'Dropdown'
		,label: 'State'
		,listName: 'state'
		,listKey: 'presentCountry'
		,keyedList: {
			91 : [
				{value:'Karnataka',text:'Karnataka'}, 
				{value:'Tamil Nadu',text:'Tamil Nadu'}, 
				{value:'Kerala',text:'Kerala'}, 
				{value:'Uttar Pradesh',text:'Uttar Pradesh'}
			], 
			130 : [
				{value:'Karnataka',text:'Karnataka'}, 
				{value:'Tamil Nadu',text:'Tamil Nadu'}, 
				{value:'Kerala',text:'Kerala'}, 
				{value:'Uttar Pradesh',text:'Uttar Pradesh'}
			]
			}
		,valueType: 0
		,errorId: 'invalidState'
		,maxLength: 50
	};
	presentPincode:Field = {
		name:'presentPincode'
		,controlType: 'Input'
		,label: 'Pin Code'
		,valueType: 0
		,errorId: 'invalidPin'
		,minLength: 6
		,maxLength: 6
	};
	presentCountry:Field = {
		name:'presentCountry'
		,controlType: 'Hidden'
		,label: 'Country'
		,valueType: 1
		,defaultValue: 130
		,errorId: 'invalidCountry'
		,maxValue: 999
	};
	addressLine1:Field = {
		name:'addressLine1'
		,controlType: 'Input'
		,label: 'Address - Line 1'
		,valueType: 0
		,errorId: 'invalidDesc'
		,maxLength: 1000
	};
	addressLine2:Field = {
		name:'addressLine2'
		,controlType: 'Input'
		,label: 'Address - Line 2'
		,valueType: 0
		,errorId: 'invalidDesc'
		,maxLength: 1000
	};
	city:Field = {
		name:'city'
		,controlType: 'Input'
		,label: 'City'
		,valueType: 0
		,errorId: 'invalidName'
		,maxLength: 150
	};
	state:Field = {
		name:'state'
		,controlType: 'Dropdown'
		,label: 'State'
		,listName: 'state'
		,listKey: 'country'
		,keyedList: {
			91 : [
				{value:'Karnataka',text:'Karnataka'}, 
				{value:'Tamil Nadu',text:'Tamil Nadu'}, 
				{value:'Kerala',text:'Kerala'}, 
				{value:'Uttar Pradesh',text:'Uttar Pradesh'}
			], 
			130 : [
				{value:'Karnataka',text:'Karnataka'}, 
				{value:'Tamil Nadu',text:'Tamil Nadu'}, 
				{value:'Kerala',text:'Kerala'}, 
				{value:'Uttar Pradesh',text:'Uttar Pradesh'}
			]
			}
		,valueType: 0
		,errorId: 'invalidState'
		,maxLength: 50
	};
	pincode:Field = {
		name:'pincode'
		,controlType: 'Input'
		,label: 'Pin Code'
		,valueType: 0
		,errorId: 'invalidPin'
		,minLength: 6
		,maxLength: 6
	};
	country:Field = {
		name:'country'
		,controlType: 'Hidden'
		,label: 'Country'
		,valueType: 1
		,defaultValue: 130
		,errorId: 'invalidCountry'
		,maxValue: 999
	};
	phoneNumber:Field = {
		name:'phoneNumber'
		,controlType: 'Input'
		,label: 'Phone'
		,isRequired: true
		,valueType: 0
		,errorId: 'invalidPhone'
		,minLength: 10
		,maxLength: 12
	};
	email:Field = {
		name:'email'
		,controlType: 'Input'
		,label: 'Email'
		,isRequired: true
		,valueType: 0
		,errorId: 'invalidEmail'
		,maxLength: 1000
	};
	admissionQuota:Field = {
		name:'admissionQuota'
		,controlType: 'Input'
		,label: 'Admission Quota'
		,listName: 'admissionQuota'
		,valueList: [
			{value:'CET',text:'CET'},
			{value:'COMEDK',text:'COMEDK'},
			{value:'CETSNQ',text:'CET-SNQ'},
			{value:'MANG',text:'MANAGEMENT'},
			{value:'ICD',text:'ICD'},
			{value:'NRI',text:'NRI'},
			{value:'GOI',text:'GOI'},
			{value:'Other',text:'Other'}
			]
		,valueType: 0
		,errorId: 'invalidText'
		,maxLength: 1000
	};
	admissionDate:Field = {
		name:'admissionDate'
		,controlType: 'Input'
		,label: 'Admission Date'
		,valueType: 4
		,errorId: 'invalidDate'
		,minValue: 73000
		,maxValue: 73000
	};
	bloodGroup:Field = {
		name:'bloodGroup'
		,controlType: 'Input'
		,label: 'Blood Group'
		,listName: 'bloodGroup'
		,valueList: [
			{value:'O -ve',text:'O -ve'},
			{value:'O +ve',text:'O +ve'},
			{value:'A -ve',text:'A -ve'},
			{value:'A +ve',text:'A +ve'},
			{value:'B -ve',text:'B -ve'},
			{value:'B +ve',text:'B +ve'},
			{value:'AB -ve',text:'AB -ve'},
			{value:'AB +ve',text:'AB +ve'}
			]
		,valueType: 0
		,errorId: 'invalidText'
		,maxLength: 1000
	};
	religion:Field = {
		name:'religion'
		,controlType: 'Input'
		,label: 'Religion'
		,listName: 'religion'
		,valueList: [
			{value:'Hindu',text:'Hindu'},
			{value:'Muslim',text:'Muslim'},
			{value:'Christian',text:'Christian'},
			{value:'Sikh',text:'Sikh'},
			{value:'Jain',text:'Jain'},
			{value:'Others',text:'Other'}
			]
		,valueType: 0
		,errorId: 'invalidText'
		,maxLength: 1000
	};
	caste:Field = {
		name:'caste'
		,controlType: 'Dropdown'
		,label: 'Caste'
		,valueType: 0
		,errorId: 'invalidText'
		,maxLength: 1000
	};
	studentTypeId:Field = {
		name:'studentTypeId'
		,controlType: 'Dropdown'
		,label: 'Student Type'
		,isRequired: true
		,listName: 'studentTypeList'
		,listKey: 'programId'
		,valueType: 1
		,errorId: 'invalidId'
		,maxValue: 9999999999999
	};
	nationality:Field = {
		name:'nationality'
		,controlType: 'Input'
		,label: 'Nationality'
		,valueType: 0
		,errorId: 'invalidText'
		,maxLength: 1000
	};
	category:Field = {
		name:'category'
		,controlType: 'Input'
		,label: 'Category'
		,valueType: 0
		,errorId: 'invalidText'
		,maxLength: 1000
	};
	personalId:Field = {
		name:'personalId'
		,controlType: 'Input'
		,label: 'Personal Id'
		,valueType: 0
		,errorId: 'invalidUniqueId'
		,minLength: 16
		,maxLength: 16
	};
	dateOfBirth:Field = {
		name:'dateOfBirth'
		,controlType: 'Input'
		,label: 'Date ofBirth'
		,valueType: 4
		,errorId: 'invalidDate'
		,minValue: 73000
		,maxValue: 73000
	};
	placeOfBirth:Field = {
		name:'placeOfBirth'
		,controlType: 'Input'
		,label: 'Place of Birth'
		,valueType: 0
		,errorId: 'invalidText'
		,maxLength: 1000
	};
	domicileState:Field = {
		name:'domicileState'
		,controlType: 'Dropdown'
		,label: 'Domicile State'
		,listName: 'domicileState'
		,valueList: [
			{value:'Karnataka',text:'Karnataka'},
			{value:'Non-Karnataka',text:'Non-Karnataka'},
			{value:'Foreign',text:'Foreign'}
			]
		,valueType: 0
		,errorId: 'invalidState'
		,maxLength: 50
	};
	previousBoard:Field = {
		name:'previousBoard'
		,controlType: 'Input'
		,label: 'Previous Board'
		,valueType: 0
		,errorId: 'invalidText'
		,maxLength: 1000
	};
	previousClass:Field = {
		name:'previousClass'
		,controlType: 'Input'
		,label: 'Previous Class'
		,valueType: 0
		,errorId: 'invalidText'
		,maxLength: 1000
	};
	previousInstitute:Field = {
		name:'previousInstitute'
		,controlType: 'Input'
		,label: 'Previous Institute'
		,valueType: 0
		,errorId: 'invalidText'
		,maxLength: 1000
	};
	qualifyingExamRank:Field = {
		name:'qualifyingExamRank'
		,controlType: 'Input'
		,label: 'Qualifying Exam'
		,valueType: 0
		,errorId: 'invalidText'
		,maxLength: 1000
	};
	academicRegulationId:Field = {
		name:'academicRegulationId'
		,controlType: 'Dropdown'
		,label: 'Academic Regulation'
		,isRequired: true
		,listName: 'academicRegulationList'
		,valueType: 1
		,errorId: 'invalidId'
		,maxValue: 9999999999999
	};
	isActive:Field = {
		name:'isActive'
		,controlType: 'Hidden'
		,label: 'isActive'
		,valueType: 3
		,defaultValue: true
		,errorId: 'invalidBool'
	};
	createdAt:Field = {
		name:'createdAt'
		,controlType: 'Hidden'
		,label: 'createdAt'
		,valueType: 5
		,errorId: 'invalidTimestamp'
	};
	updatedAt:Field = {
		name:'updatedAt'
		,controlType: 'Hidden'
		,label: 'updatedAt'
		,valueType: 5
		,errorId: 'invalidTimestamp'
	};
	regNo:Field = {
		name:'regNo'
		,controlType: 'Input'
		,label: 'regNo'
		,valueType: 0
		,errorId: 'invalidText'
		,maxLength: 1000
	};

	guardians: ChildForm = {
		name:'guardians',
		form:GuardianForm.getInstance(),
		isEditable:true,
		isTabular:true,
		label:'',
		minRows:1,
		maxRows:0,
		errorId:null
	};

	public static getInstance(): StudentForm {
		return StudentForm._instance;
	}

	constructor() {
		super();
		this.fields = new Map();
		this.controls = new Map();
		this.controls.set('studentId', [Validators.required, Validators.min(-1), Validators.max(9999999999999)]);
		this.fields.set('studentId', this.studentId);
		this.controls.set('instituteId', [Validators.max(9999999999999)]);
		this.fields.set('instituteId', this.instituteId);
		this.controls.set('departmentId', [Validators.required, Validators.max(9999999999999)]);
		this.fields.set('departmentId', this.departmentId);
		this.controls.set('programId', [Validators.required, Validators.max(9999999999999)]);
		this.fields.set('programId', this.programId);
		this.controls.set('levelId', [Validators.required, Validators.max(9999999999999)]);
		this.fields.set('levelId', this.levelId);
		this.controls.set('levelSectionId', [Validators.required, Validators.max(9999999999999)]);
		this.fields.set('levelSectionId', this.levelSectionId);
		this.controls.set('usn', [Validators.required, Validators.maxLength(1000)]);
		this.fields.set('usn', this.usn);
		this.controls.set('tempUsn', [Validators.maxLength(1000)]);
		this.fields.set('tempUsn', this.tempUsn);
		this.controls.set('name', [Validators.required, Validators.maxLength(150)]);
		this.fields.set('name', this.name);
		this.controls.set('gender', [Validators.maxLength(10)]);
		this.fields.set('gender', this.gender);
		this.controls.set('presentAddressLine1', [Validators.maxLength(1000)]);
		this.fields.set('presentAddressLine1', this.presentAddressLine1);
		this.controls.set('presentAddressLine2', [Validators.maxLength(1000)]);
		this.fields.set('presentAddressLine2', this.presentAddressLine2);
		this.controls.set('presentCity', [Validators.maxLength(150)]);
		this.fields.set('presentCity', this.presentCity);
		this.controls.set('presentState', [Validators.maxLength(50)]);
		this.fields.set('presentState', this.presentState);
		this.controls.set('presentPincode', [Validators.minLength(6), Validators.maxLength(6), Validators.pattern('[1-9][0-9]{5}')]);
		this.fields.set('presentPincode', this.presentPincode);
		this.controls.set('presentCountry', [Validators.max(999)]);
		this.fields.set('presentCountry', this.presentCountry);
		this.controls.set('addressLine1', [Validators.maxLength(1000)]);
		this.fields.set('addressLine1', this.addressLine1);
		this.controls.set('addressLine2', [Validators.maxLength(1000)]);
		this.fields.set('addressLine2', this.addressLine2);
		this.controls.set('city', [Validators.maxLength(150)]);
		this.fields.set('city', this.city);
		this.controls.set('state', [Validators.maxLength(50)]);
		this.fields.set('state', this.state);
		this.controls.set('pincode', [Validators.minLength(6), Validators.maxLength(6), Validators.pattern('[1-9][0-9]{5}')]);
		this.fields.set('pincode', this.pincode);
		this.controls.set('country', [Validators.max(999)]);
		this.fields.set('country', this.country);
		this.controls.set('phoneNumber', [Validators.required, Validators.minLength(10), Validators.maxLength(12), Validators.pattern('[1-9][0-9]*')]);
		this.fields.set('phoneNumber', this.phoneNumber);
		this.controls.set('email', [Validators.required, Validators.email, Validators.maxLength(1000)]);
		this.fields.set('email', this.email);
		this.controls.set('admissionQuota', [Validators.maxLength(1000)]);
		this.fields.set('admissionQuota', this.admissionQuota);
		this.controls.set('admissionDate', []);
		this.fields.set('admissionDate', this.admissionDate);
		this.controls.set('bloodGroup', [Validators.maxLength(1000)]);
		this.fields.set('bloodGroup', this.bloodGroup);
		this.controls.set('religion', [Validators.maxLength(1000)]);
		this.fields.set('religion', this.religion);
		this.controls.set('caste', [Validators.maxLength(1000)]);
		this.fields.set('caste', this.caste);
		this.controls.set('studentTypeId', [Validators.required, Validators.max(9999999999999)]);
		this.fields.set('studentTypeId', this.studentTypeId);
		this.controls.set('nationality', [Validators.maxLength(1000)]);
		this.fields.set('nationality', this.nationality);
		this.controls.set('category', [Validators.maxLength(1000)]);
		this.fields.set('category', this.category);
		this.controls.set('personalId', [Validators.minLength(16), Validators.maxLength(16), Validators.pattern('[1-9][0-9]{15}')]);
		this.fields.set('personalId', this.personalId);
		this.controls.set('dateOfBirth', []);
		this.fields.set('dateOfBirth', this.dateOfBirth);
		this.controls.set('placeOfBirth', [Validators.maxLength(1000)]);
		this.fields.set('placeOfBirth', this.placeOfBirth);
		this.controls.set('domicileState', [Validators.maxLength(50)]);
		this.fields.set('domicileState', this.domicileState);
		this.controls.set('previousBoard', [Validators.maxLength(1000)]);
		this.fields.set('previousBoard', this.previousBoard);
		this.controls.set('previousClass', [Validators.maxLength(1000)]);
		this.fields.set('previousClass', this.previousClass);
		this.controls.set('previousInstitute', [Validators.maxLength(1000)]);
		this.fields.set('previousInstitute', this.previousInstitute);
		this.controls.set('qualifyingExamRank', [Validators.maxLength(1000)]);
		this.fields.set('qualifyingExamRank', this.qualifyingExamRank);
		this.controls.set('academicRegulationId', [Validators.required, Validators.max(9999999999999)]);
		this.fields.set('academicRegulationId', this.academicRegulationId);
		this.controls.set('isActive', []);
		this.fields.set('isActive', this.isActive);
		this.controls.set('createdAt', []);
		this.fields.set('createdAt', this.createdAt);
		this.controls.set('updatedAt', []);
		this.fields.set('updatedAt', this.updatedAt);
		this.controls.set('regNo', [Validators.maxLength(1000)]);
		this.fields.set('regNo', this.regNo);

		this.childForms = new Map();
		this.childForms.set('guardians', this.guardians);
		this.opsAllowed = {get: true, create: true, update: true, filter: true};
		this.listFields = ['departmentId','programId','levelId','levelSectionId','gender','presentState','state','admissionQuota','bloodGroup','religion','studentTypeId','domicileState','academicRegulationId'];
		this.keyFields = ["studentId"];
	}

	public getName(): string {
		 return 'student';
	}
}


export class StudentFd extends FormData {
	constructor(form: StudentForm, sa: ServiceAgent) {
		super(form, sa);
	}

	setFieldValue(name: 'studentId' | 'instituteId' | 'departmentId' | 'programId' | 'levelId' | 'levelSectionId' | 'usn' | 'tempUsn' | 'name' | 'gender' | 'presentAddressLine1' | 'presentAddressLine2' | 'presentCity' | 'presentState' | 'presentPincode' | 'presentCountry' | 'addressLine1' | 'addressLine2' | 'city' | 'state' | 'pincode' | 'country' | 'phoneNumber' | 'email' | 'admissionQuota' | 'admissionDate' | 'bloodGroup' | 'religion' | 'caste' | 'studentTypeId' | 'nationality' | 'category' | 'personalId' | 'dateOfBirth' | 'placeOfBirth' | 'domicileState' | 'previousBoard' | 'previousClass' | 'previousInstitute' | 'qualifyingExamRank' | 'academicRegulationId' | 'isActive' | 'createdAt' | 'updatedAt' | 'regNo', value: string | number | boolean | null ): void {
		super.setFieldValue(name, value);
	}

	getFieldValue(name: 'studentId' | 'instituteId' | 'departmentId' | 'programId' | 'levelId' | 'levelSectionId' | 'usn' | 'tempUsn' | 'name' | 'gender' | 'presentAddressLine1' | 'presentAddressLine2' | 'presentCity' | 'presentState' | 'presentPincode' | 'presentCountry' | 'addressLine1' | 'addressLine2' | 'city' | 'state' | 'pincode' | 'country' | 'phoneNumber' | 'email' | 'admissionQuota' | 'admissionDate' | 'bloodGroup' | 'religion' | 'caste' | 'studentTypeId' | 'nationality' | 'category' | 'personalId' | 'dateOfBirth' | 'placeOfBirth' | 'domicileState' | 'previousBoard' | 'previousClass' | 'previousInstitute' | 'qualifyingExamRank' | 'academicRegulationId' | 'isActive' | 'createdAt' | 'updatedAt' | 'regNo' ): string | number | boolean | null {
		return super.getFieldValue(name);
	}
}


export interface StudentVo extends Vo {
	presentAddressLine1?: string, 
	regNo?: string, 
	presentAddressLine2?: string, 
	country?: number, 
	personalId?: string, 
	gender?: string, 
	city?: string, 
	departmentId?: number, 
	studentTypeId?: number, 
	isActive?: boolean, 
	studentId?: number, 
	usn?: string, 
	bloodGroup?: string, 
	previousClass?: string, 
	createdAt?: string, 
	presentState?: string, 
	levelId?: number, 
	addressLine1?: string, 
	addressLine2?: string, 
	state?: string, 
	admissionQuota?: string, 
	academicRegulationId?: number, 
	email?: string, 
	updatedAt?: string, 
	pincode?: string, 
	admissionDate?: string, 
	placeOfBirth?: string, 
	domicileState?: string, 
	levelSectionId?: number, 
	previousInstitute?: string, 
	caste?: string, 
	dateOfBirth?: string, 
	previousBoard?: string, 
	qualifyingExamRank?: string, 
	religion?: string, 
	presentCity?: string, 
	phoneNumber?: string, 
	nationality?: string, 
	presentCountry?: number, 
	name?: string, 
	instituteId?: number, 
	addressesAreSame?: boolean, 
	tempUsn?: string, 
	category?: string, 
	programId?: number, 
	presentPincode?: string, 
	guardians?: GuardianVo
}
