
import { Form , Field, ChildForm } from '../form/form';
import { FormData } from '../form/formData';
import { SelectOption, Vo } from '../form/types';
import { Validators } from '@angular/forms'
import { ServiceAgent} from '../form/serviceAgent';

export class PacketSearchForm extends Form {
	private static _instance = new PacketSearchForm();
	packetCode:Field = {
		name:'packetCode'
		,controlType: 'Input'
		,label: 'Enter Packet Code'
		,isRequired: true
		,valueType: 0
		,errorId: 'invalidText'
		,maxLength: 1000
	};

	public static getInstance(): PacketSearchForm {
		return PacketSearchForm._instance;
	}

	constructor() {
		super();
		this.fields = new Map();
		this.controls = new Map();
		this.controls.set('packetCode', [Validators.required, Validators.maxLength(1000)]);
		this.fields.set('packetCode', this.packetCode);
		this.opsAllowed = {filter: true};
	}

	public getName(): string {
		 return 'packetSearch';
	}
}


export class PacketSearchFd extends FormData {
	constructor(form: PacketSearchForm, sa: ServiceAgent) {
		super(form, sa);
	}

	setFieldValue(name: 'packetCode', value: string | number | boolean | null ): void {
		super.setFieldValue(name, value);
	}

	getFieldValue(name: 'packetCode' ): string | number | boolean | null {
		return super.getFieldValue(name);
	}
}


export interface PacketSearchVo extends Vo {
	packetCode?: string
}
