
import { Form , Field, ChildForm } from '../form/form';
import { FormData } from '../form/formData';
import { SelectOption, Vo } from '../form/types';
import { Validators } from '@angular/forms'
import { ServiceAgent} from '../form/serviceAgent';
import { CieSectionDetailForm, CieSectionDetailVo } from './cieSectionDetailForm';

export class CieSubjectDetailForm extends Form {
	private static _instance = new CieSubjectDetailForm();

	sections: ChildForm = {
		name:'sections',
		form:CieSectionDetailForm.getInstance(),
		isEditable:false,
		isTabular:true,
		label:'',
		minRows:0,
		maxRows:0,
		errorId:null
	};

	public static getInstance(): CieSubjectDetailForm {
		return CieSubjectDetailForm._instance;
	}

	constructor() {
		super();
		this.fields = new Map();
		this.controls = new Map();

		this.childForms = new Map();
		this.childForms.set('sections', this.sections);
		this.opsAllowed = {get: true, filter: true};
		this.keyFields = ["seasonId", "subjectId"];
	}

	public getName(): string {
		 return 'cieSubjectDetail';
	}
}


export class CieSubjectDetailFd extends FormData {
	constructor(form: CieSubjectDetailForm, sa: ServiceAgent) {
		super(form, sa);
	}
	/**  this form has no editable fields. data nust be accessed as Vo and not through fd **/
}


export interface CieSubjectDetailVo extends Vo {
	departmentName?: string, 
	code?: string, 
	degreeId?: number, 
	attendanceFrozen?: boolean, 
	levelName?: string, 
	cieFrozen?: boolean, 
	degreeName?: string, 
	autoEnroll?: boolean, 
	subjectId?: number, 
	parentId?: number, 
	offeredSubjectId?: number, 
	isOffered?: boolean, 
	nbrEnrolled?: number, 
	seasonId?: number, 
	levelId?: number, 
	name?: string, 
	isIntegrated?: boolean, 
	canBeWithdrawn?: boolean, 
	isChild?: boolean, 
	sections?: CieSectionDetailVo
}
