import { Component,Input, OnInit, ViewChild, AfterViewInit } from "@angular/core";
import { DynamicPanel, GroupPanel } from '../../interfaces';
import { TableMetaData, CtTableComponent } from 'src/app/framework-modules/elements/ct-table/component';
import { ServiceAgent } from 'src/app/framework-modules/formdata/form/serviceAgent';
import { MessageService } from 'src/app/services/messageService';
import { CtSpinner } from 'src/app/framework-modules/elements/ct-spinner/component';
import { AssignedSubjectSelectionFd, AssignedSubjectSelectionForm } from 'src/app/framework-modules/formdata/gen/assignedSubjectSelectionForm';
import { MarksForSectionFd, MarksForSectionForm } from 'src/app/framework-modules/formdata/gen/marksForSectionForm';
import { BarChartMetaData } from 'src/app/framework-modules/elements/ct-barchart/component';
import { SubjectSectionTestStatisticsFd, SubjectSectionTestStatisticsForm } from 'src/app/framework-modules/formdata/gen/subjectSectionTestStatisticsForm';
import { ColToRowMeta, FilterRequest, RowToColMeta, Transposer, Vo } from 'src/app/framework-modules/formdata/form/types';
import { StudentSubjectMarksFd, StudentSubjectMarksForm } from 'src/app/framework-modules/formdata/gen/studentSubjectMarksForm';
import { SubjectGradeStatisticsFd, SubjectGradeStatisticsForm } from 'src/app/framework-modules/formdata/gen/subjectGradeStatisticsForm';
import { AssignedParentSubjectSelectionFd, AssignedParentSubjectSelectionForm } from 'src/app/framework-modules/formdata/gen/assignedParentSubjectSelectionForm';
import { SubjectwiseResultFd, SubjectwiseResultForm } from 'src/app/framework-modules/formdata/gen/subjectwiseResultForm';
import { InstituteConfigurationFd, InstituteConfigurationForm, InstituteConfigurationVo } from 'src/app/framework-modules/formdata/gen/instituteConfigurationForm';
import { ClientContext } from 'src/app/framework-modules/formdata/form/clientContext';
import { LineChartMetaData } from 'src/app/framework-modules/elements/ct-linechart/component';
import { CtCheckboxTableComponent } from 'src/app/framework-modules/elements/ct-checkbox-table/component';
import { ExcelDownloadService } from 'src/app/services/excelDownloadService';
import { cloneDeep } from 'lodash';


@Component({
  selector: "staff-dashboard-list",
  templateUrl: "./component.html",
  styleUrls: ["./component.scss"]
})


export class StaffDashboardListComponent  implements DynamicPanel, AfterViewInit{
  @ViewChild("spinner", {static:false}) spinner: CtSpinner;
  @ViewChild("gridTable", { static: false }) gtable: CtTableComponent;
  @ViewChild("bottomTenTable", { static: false }) bottomTenTable: CtTableComponent;
  @ViewChild("topTenTable", { static: false }) topTenTable: CtTableComponent;
  @ViewChild("topFiveTable", { static: false }) topFiveTable: CtTableComponent;
  @ViewChild("bottomFiveTable", { static: false }) bottomFiveTable: CtTableComponent;
  @ViewChild("studentsTable", { static: false }) studentsTable: CtTableComponent;
  @ViewChild("checkboxTable", { static: false }) checkboxTable: CtCheckboxTableComponent;
  @Input() inputData: any;
  @Input() parentPanel: GroupPanel;

  form: AssignedSubjectSelectionForm;
  fd: AssignedSubjectSelectionFd;
  selectedSection = ''
  selectedSeqNo = '';
  
  avgBarMetaData: BarChartMetaData;
  avgBarData: {}[];

  public tableData: TableMetaData;
  public studentsTableData: TableMetaData;
  public checkboxTableData: TableMetaData;
  public topTen: TableMetaData;
  public bottomTen: TableMetaData;
  public topFiveData: TableMetaData;
  public bottomFiveData: TableMetaData;
  public studentMetaData: LineChartMetaData;
  public studentLineData: {}[];

  public form1: MarksForSectionForm;
  public fd1: MarksForSectionFd;
  public form2: SubjectSectionTestStatisticsForm;
  public fd2: SubjectSectionTestStatisticsFd;
  public form4: StudentSubjectMarksForm;
  public fd4: StudentSubjectMarksFd;
  public subjectGradesForm: SubjectGradeStatisticsForm;
  public subjectGradesFd: SubjectGradeStatisticsFd;
  public assignedParentSubjectForm: AssignedParentSubjectSelectionForm;
  public assignedParentSubjectFd: AssignedParentSubjectSelectionFd;
  public enrollmentsForm : SubjectwiseResultForm
  public enrollmentsFd : SubjectwiseResultFd
  public instituteFd: InstituteConfigurationFd;

  switchName: 'Swtich To Result Analysis' | 'Switch to Pre Exam View' = 'Swtich To Result Analysis';
  sectionSwitchName: 'Switch To Section Wise' | 'Switch to Subject Wise' = 'Switch To Section Wise';
  sectionWise: boolean = false;
  postExam: boolean = false;
  private colToRowMeta: ColToRowMeta;
  testData: Vo;
  subjectBarMetaData: BarChartMetaData;
  subjectBarData: any[];
  subjectTotal: number;
  lineChart: boolean = false;
  showChart: boolean;

  constructor(private sa: ServiceAgent, private ms: MessageService, private cc: ClientContext, private ds: ExcelDownloadService){
    this.form = AssignedSubjectSelectionForm.getInstance();
    this.fd = this.form.newFormData(this.sa);
    this.form1 = MarksForSectionForm.getInstance();
    this.fd1 = this.form1.newFormData(this.sa);
    this.form2 = SubjectSectionTestStatisticsForm.getInstance();
    this.fd2 = this.form2.newFormData(this.sa);
    this.form4 = StudentSubjectMarksForm.getInstance();
    this.fd4 = this.form4.newFormData(this.sa);
    this.subjectGradesForm = SubjectGradeStatisticsForm.getInstance();
    this.subjectGradesFd = this.subjectGradesForm.newFormData(this.sa);
    this.assignedParentSubjectForm = AssignedParentSubjectSelectionForm.getInstance();
    this.assignedParentSubjectFd = this.assignedParentSubjectForm.newFormData(this.sa);
    this.enrollmentsForm = SubjectwiseResultForm.getInstance();
    this.enrollmentsFd = this.enrollmentsForm.newFormData(this.sa);
  }

  ngAfterViewInit(){
    this.metaDataInit();
  }


  metaDataInit(){
    this.avgBarMetaData = {
      view:[500,300],
      xAxisLabel: "Test",
      yAxisLabel: "Marks",
      showLegend: false,
      customToolTip: false
    };

    this.topTen = {
      data: [],
      metaData: {
        column_names: [],
        display_names: {},
      }
    };

    this.studentsTableData = {
      data: [],
      metaData: {
        column_names: [],
        display_names: {},
        search: true,
        placeholder: "Search a student"
      }
    };

    this.checkboxTableData = {
      data: [],
      metaData: {
        column_names: [],
        display_names: {},
        search: true,
        placeholder: "Search a student",
        selectAttr: 'select'
      }
    };

    this.studentMetaData = {
      view: [650,300],
      xAxisLabel: "Test",
      yAxisLabel: "Marks",
      showLegend: false,
      yScaleMax: 25
    }

    this.bottomTen = {
      data: [],
      metaData: {
        column_names: [],
        display_names: {},
      }
    };

    this.subjectBarMetaData = {
      view: [650,300],
      xAxisLabel: "Grades",
      yAxisLabel: "Students",
      showLegend: false,
      customToolTip: true
    };



    this.tableData = {
      data: [],
      metaData: {
        column_names: [
          'name',
          'value'
        ],
        display_names: {
          'name': "Grade",
          'value': "Percentage"
        }
      }
    };

    this.topFiveData = {data: [],metaData: {  column_names: [    "usn",    "name",    "cieMarks",    "examMarks",    "totalMarks",    "grade"  ],  display_names:{    "usn":"USN",    "name":"Name",    "cieMarks":"CIE",    "examMarks":"Exam",    "totalMarks":"Total Marks",    "grade": "Grade"  }}}
    this.bottomFiveData = {data: [],metaData: {  column_names: [    "usn",    "name",    "cieMarks",    "examMarks",    "totalMarks",    "grade"  ],  display_names:{    "usn":"USN",    "name":"Name",    "cieMarks":"CIE",    "examMarks":"Exam",    "totalMarks":"Total Marks",    "grade": "Grade"  }}}

  }
 

  filter(){
    var ssid = this.fd.getFieldValue('subjectSectionId');
    this.fd1.setFieldValue('subjectSectionId', ssid);
    this.getTestList()
    this.spinner.show("Loading.....");
    const f: FilterRequest = {
      conditions: {
        subjectSectionId: {comp: '=', value: ssid}
      },
      sort: {seqNo:"asc"}
    }
    this.fd2.filter(f).subscribe(
      data => {
        this.avgBarData = []
        data.forEach(e=>{
          this.avgBarData.push(
            {"name":e.name,"value":e.average}
          )
        });
        this.spinner.hide();
      }
    );
  }

  getTestList(){
    this.fd1.fetchData().subscribe({
    next: data => {
      this.testData = data;
      this.seqNoChanged();
    },
    error: msg => {
      console.error(msg);
      this.ms.showError('Server returned with an error message: ' + msg[0].text);
    }
  });}

  /**
   * 
   * @param data set the data reced from server into our table component after transposing it
   */
  setData(data,seqId) {
    /**
     * input meta data for the trasnposer
     */
    const meta: RowToColMeta = {
      datarowsAttribute: 'marks',
      headingAttribute: 'name',
      keyAttribute: 'seqNo',
      rowKeyAttribute: 'assessmentSeqNo',
      rowValueAttribute: 'marksScored',
      columns: data.names as [],
    };
    const students = data.students as [];
    const colNames = ["name", "usn"];
    const headings = { name: "Name", usn: "USN" };
    const tableMeta = { names: colNames, headings: headings };
    this.colToRowMeta = Transposer.rowToCol(meta, tableMeta, students);
    this.resetTable(colNames, headings, students,seqId);
  }

  resetTable(colNames: string[], headings: object, data: object[],seqId) {
    this.studentsTableData.data = data as Vo[];
    this.studentsTableData.metaData.column_names = colNames;
    this.studentsTableData.metaData.display_names = headings as {};
    this.checkboxTableData.data = data as Vo[];
    this.checkboxTableData.metaData.column_names = colNames;
    this.checkboxTableData.metaData.display_names = headings as {};
    if(this.studentsTable != undefined){
      this.studentsTable.update();
      return;
    }
    if(this.checkboxTable != undefined){
      this.checkboxTable.update();
      return;
    }
    data.sort(this.predicateBy(colNames[seqId+1]));
    var newCols = ["name", "usn"];
    newCols.push(colNames[seqId+1])
    this.topTen.metaData.column_names = newCols;
    this.topTen.metaData.display_names = headings as {};
    this.bottomTen.metaData.column_names = newCols;
    this.bottomTen.metaData.display_names = headings as {};
    this.topTen.data = data.splice(-5).reverse() as Vo[];
    this.bottomTen.data = data.splice(0,5) as Vo[];
    this.topTenTable.update();
    this.bottomTenTable.update();
  }

  private predicateBy(prop){
    return function(a,b){
       if (a[prop] > b[prop]){
           return 1;
       } else if(a[prop] < b[prop]){
           return -1;
       }
       return 0;
    }
  }
  /**
   * Toggle Between subjectwise and Institute wise grades
   */
  toggle(){
    this.changeSubject();
    this.postExam = !this.postExam;
    if(this.postExam){
      this.switchName='Switch to Pre Exam View';
    } 
    else{
      this.switchName='Swtich To Result Analysis';
      this.filter();
    }
  }

  seqNoChanged(){
    if(this.testData){
      const temp = cloneDeep(this.testData);
      var seqId = this.fd1.getFieldValue('seqNo');
      this.setData(temp,seqId);
    }
  }

  /**
   * Subject change listner for the offered subject Drop down
   * Gets the data for selected subject and populates the subject Bar char
   */
  changeSubject(){
    this.spinner.show("Loading....")
    var ofid = this.assignedParentSubjectFd.getFieldValue('offeredSubjectId')
    const payload = {offeredSubjectId: ofid, seasonId: this.cc.getcurrentSeasonId(), forSsid: false}
    this.subjectGradesFd.fetchFor(payload).subscribe({
      next: data =>{
        var subjectGradeStats = data as unknown as {}[];
        this.subjectBarData = [];
        subjectGradeStats.forEach(grade =>{
          this.subjectBarData.push(
            {"name":grade['grade'],"value":grade['nbr'],tooltipText: 'Click to View More'}
          );
        });
        this.subjectTotal = this.getPercentages(this.subjectBarData,this.tableData,this.gtable);
        this.spinner.hide();
      }
    });
    this.getFives(ofid);
  }

  /**
   * Triggered when the user clicks any grade from the subject bar chart
   * Forms the filter request to get all students in selected subject and selected grade
   * Navigates to Dashboard View
   * @param $event grade chosen from the subject bar chart
   */
  selectedSubjectGrade($event: any){
    const f: FilterRequest = {
      conditions: {
        offeredSubjectId: {comp:'=',value:this.assignedParentSubjectFd.getFieldValue('offeredSubjectId')},
        grade: {comp:'=',value: $event.name},
        instituteId: {comp: '=', value:this.cc.getInstituteId()},
        seasonId: {comp:'=',value:this.cc.getcurrentSeasonId()}
      }
    };
    this.parentPanel.navigate('DashboardView',f);
  }

  getFives($event){
    const f: FilterRequest = {
      conditions: {
        seasonId: {comp: "=", value: this.cc.getcurrentSeasonId()},
        offeredSubjectId: {comp: "=", value: $event}
      },
      sort: {totalMarks: "desc"}
    };
    this.enrollmentsFd.filter(f).subscribe({
      next: data =>{
        this.topFiveData.data = data.splice(0,5);
        this.bottomFiveData.data = data.splice(-5).reverse();
        this.topFiveTable.update();
        this.bottomFiveTable.update();
      }
    })
  }

  getPercentages(array: Array<{}>,tableData: TableMetaData, table: CtTableComponent){
    var total = 0;
    array.forEach( data =>{
      total += data['value'];
    });
    tableData.data = [];
    array.forEach(element => {
      var percentage = Math.round(element['value']*10000/total)/100 + '%';
      tableData.data.push(
        {"name": element['name'],"value":percentage}
      )
    });
    table.update();
    return total;
  }

  toggleLineChart(showChart: boolean){
    this.lineChart = true;
    this.showChart = showChart;
    this.getTestList();
    if(this.showChart){
      this.studentLineData = [{name:"Average",series:this.avgBarData}];
      this.checkboxTableData.data.forEach(e=>{
        e['select'] = false
      })
      this.checkboxTableData.metaData.column_names = ["name","usn"];
      
    }
  }

  toggleBack(){
    this.lineChart = false;
    this.filter();
  }

  addStudent(){
    var lineData = [this.studentLineData[0]];
    var cols = this.studentsTableData.metaData.column_names.slice(3);
    this.checkboxTableData.data.forEach(e =>{
      if(e['select']){
        var student = {name:e['name'],series:[]}
        cols.forEach(c => {
          student['series'].push(
            {"name":this.studentsTableData.metaData.display_names[c],"value":e[c]}
          )
        });
        lineData.push(student);
      }
    });
    this.studentLineData = lineData;
    console.log(lineData);
  }

  download(tableData: TableMetaData,name: string){
    var excelData = []
    var cols = tableData.metaData.column_names;
    var displayMap = tableData.metaData.display_names;

    tableData.data.forEach(d => {
      var row = {}
      cols.forEach(col =>{
        row[displayMap[col]] = d[col]
      });
      excelData.push(row);
    });

    this.ds.downloadExcel({"Sheet1": excelData},name);
  }

  /**
   * Change Subject Section For Result Analysis
   */
  changeSubjectSection(){
    this.spinner.show("Loading......")
    var ssid = this.fd.getFieldValue('subjectSectionId');
    const payload = {subjectSectionId: ssid, seasonId: this.cc.getcurrentSeasonId(),forSsid: true}
    this.subjectGradesFd.fetchFor(payload).subscribe({
      next: data =>{
        var subjectGradeStats = data.gradeStats as unknown as {}[];
        this.subjectBarData = [];
        subjectGradeStats.forEach(grade =>{
          this.subjectBarData.push(
            {"name":grade['grade'],"value":grade['nbr'],tooltipText: 'Click to View More'}
          );
        });
        this.subjectTotal = this.getPercentages(this.subjectBarData,this.tableData,this.gtable);
        this.getSectionFives(data.parentSubjectSectionId);
        this.spinner.hide();
      }
    });
  }

  getSectionFives($event){
    const f: FilterRequest = {
      conditions: {
        seasonId: {comp: "=", value: this.cc.getcurrentSeasonId()},
        subjectSectionId: {comp: "=", value: $event}
      },
      sort: {totalMarks: "desc"}
    };
    this.enrollmentsFd.filter(f).subscribe({
      next: data =>{
        this.topFiveData.data = data.splice(0,5);
        this.bottomFiveData.data = data.splice(-5).reverse();
        this.topFiveTable.update();
        this.bottomFiveTable.update();
      }
    })
  }

  toggleSection(){
    this.sectionWise = !this.sectionWise;
    if(this.sectionWise){
      this.changeSubjectSection();
      this.sectionSwitchName = 'Switch to Subject Wise';
    }
    else{
      this.changeSubject();
      this.sectionSwitchName = 'Switch To Section Wise';
    }
  }

}