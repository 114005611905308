import { Component, OnInit, ViewChild, Input, AfterViewInit } from "@angular/core";
import { ServiceAgent } from 'src/app/framework-modules/formdata/form/serviceAgent';
import { CtTableComponent, TableMetaData } from 'src/app/framework-modules/elements/ct-table/component';
import { GroupPanel } from '../../interfaces';
import { SessionComponent } from '../../component';
import { MarksForSectionForm, MarksForSectionFd } from 'src/app/framework-modules/formdata/gen/marksForSectionForm';
import { RowToColMeta, ColToRowMeta, Transposer, Vo } from 'src/app/framework-modules/formdata/form/types';
import { MessageService } from 'src/app/services/messageService';
import { AssignedSubjectSelectionForm, AssignedSubjectSelectionFd } from 'src/app/framework-modules/formdata/gen/assignedSubjectSelectionForm';
import { AssignedPpNpSubjectSelectionFd, AssignedPpNpSubjectSelectionForm } from "src/app/framework-modules/formdata/gen/assignedPpNpSubjectSelectionForm";
import { PpNpGradeFd, PpNpGradeForm } from "src/app/framework-modules/formdata/gen/ppNpGradeForm";
import { ExcelDownloadService } from "src/app/services/excelDownloadService";


@Component({
  selector: 'manrks-entry-list',
  templateUrl: "./component.html",
  styleUrls: ["./component.scss"]
})
export class ppNpGradeListComponent implements OnInit, AfterViewInit {
  @ViewChild("gridTable", { static: false }) gtable: CtTableComponent;
  @Input() inputData: any;
  @Input() parentPanel: GroupPanel;
  @Input() session: SessionComponent;

  /**
   * for selecting subjectSection and test (seqNo)
   */
  form: AssignedPpNpSubjectSelectionForm;
  fd: AssignedPpNpSubjectSelectionFd;


  /**
   * for getting marks-list
   */
  form1: PpNpGradeForm;
  fd1: PpNpGradeFd;
  tableData: TableMetaData;
  showEnter:boolean = false;

  constructor(private sa: ServiceAgent,
    private ds: ExcelDownloadService,
    private ms: MessageService) {

    this.form = AssignedPpNpSubjectSelectionForm.getInstance();
    this.fd = this.form.newFormData(this.sa);
    this.form1 = PpNpGradeForm.getInstance();
    this.fd1 = this.form1.newFormData(this.sa);
  }

  ngOnInit() {
    this.initTable();
  }

  ngAfterViewInit() {
  }



  getData() {
    if(this.fd.getFieldValue('subjectSectionId')===null){
      this.showEnter=false;
    }
    else{
      this.showEnter = true;
    }
    this.fd1.setFieldValue('subjectSectionId', this.fd.getFieldValue('subjectSectionId'));
    this.fd1.fetchData().subscribe({
      next: data => {
        console.log(data);
        this.tableData.data = data.students as Vo[];
        this.gtable.update();
      },
      error: msg => {
        console.error(msg);
      }
    });
  }

  /**
   * 
   * @param data set the data reced from server into our table component after transposing it
   */
  initTable() {
    this.tableData = {
      data: [],
      metaData: {
        column_names: ["name","usn","grade"],
        display_names: {
          "name":"Student Name",
          "usn":"USN",
          "grade":"Grade"
        },
        search: true,
        edit: false,
        placeholder: "Search by Student Name, Student USN"
      }
    };
  }

  gotoEdit(){
    this.parentPanel.navigate('PpNpGradeEdit',this.fd.getFieldValue('subjectSectionId'));
  }

  doDownload(){
    var map ={};
    this.tableData.data.forEach(row=>{
      map[row['usn'] as string] = row['grade']
    })
    this.sa.serve("get_cieSubjectSectionDetail", {
      data:{subjectSectionId:this.fd.getFieldValue('subjectSectionId')}
    }
    ).subscribe(
      data=>{
        this.ds.getPpNpSheet(data,map);
      }
    )
  }

}