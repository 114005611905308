
import { Form , Field, ChildForm } from '../form/form';
import { FormData } from '../form/formData';
import { SelectOption, Vo } from '../form/types';
import { Validators } from '@angular/forms'
import { ServiceAgent} from '../form/serviceAgent';

export class CurrentEnrolledStudentForm extends Form {
	private static _instance = new CurrentEnrolledStudentForm();

	public static getInstance(): CurrentEnrolledStudentForm {
		return CurrentEnrolledStudentForm._instance;
	}

	constructor() {
		super();
		this.fields = new Map();
		this.controls = new Map();
		this.opsAllowed = {get: true, filter: true};
		this.keyFields = ["studentId"];
	}

	public getName(): string {
		 return 'currentEnrolledStudent';
	}
}


export class CurrentEnrolledStudentFd extends FormData {
	constructor(form: CurrentEnrolledStudentForm, sa: ServiceAgent) {
		super(form, sa);
	}
	/**  this form has no editable fields. data nust be accessed as Vo and not through fd **/
}


export interface CurrentEnrolledStudentVo extends Vo {
	departmentName?: string, 
	registeredCredits?: number, 
	obtainedCredits?: number, 
	levelName?: string, 
	studentId?: number, 
	sgpa?: number, 
	usn?: string, 
	seasonId?: number, 
	programName?: string, 
	levelId?: number, 
	studentName?: string, 
	instituteId?: number, 
	cgpa?: number, 
	programId?: number
}
