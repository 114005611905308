
import { Form , Field, ChildForm } from '../form/form';
import { FormData } from '../form/formData';
import { SelectOption, Vo } from '../form/types';
import { Validators } from '@angular/forms'
import { ServiceAgent} from '../form/serviceAgent';

export class IndividualGradeCardForm extends Form {
	private static _instance = new IndividualGradeCardForm();
	degreeId:Field = {
		name:'degreeId'
		,controlType: 'Dropdown'
		,label: 'Select a Degree'
		,isRequired: true
		,listName: 'degreeList'
		,valueType: 1
		,errorId: 'invalidId'
		,maxValue: 9999999999999
	};
	levelId:Field = {
		name:'levelId'
		,controlType: 'Dropdown'
		,label: 'Select a Level'
		,isRequired: true
		,listName: 'levelList'
		,listKey: 'degreeId'
		,valueType: 1
		,errorId: 'invalidId'
		,maxValue: 9999999999999
	};
	seasonId:Field = {
		name:'seasonId'
		,controlType: 'Dropdown'
		,label: 'Select a Season'
		,isRequired: true
		,listName: 'seasonList'
		,valueType: 1
		,errorId: 'invalidId'
		,maxValue: 9999999999999
	};
	usn:Field = {
		name:'usn'
		,controlType: 'Input'
		,label: 'Enter Student USN'
		,isRequired: true
		,valueType: 0
		,errorId: 'invalidText'
		,maxLength: 1000
	};
	date:Field = {
		name:'date'
		,controlType: 'Input'
		,label: 'Date of Issue'
		,isRequired: true
		,valueType: 0
		,errorId: 'invalidText'
		,maxLength: 1000
	};
	finalMtechGradeCard:Field = {
		name:'finalMtechGradeCard'
		,controlType: 'Checkbox'
		,label: 'For Final Mtech'
		,isRequired: true
		,valueType: 3
		,defaultValue: false
		,errorId: 'invalidBool'
	};
	isAciteRequired:Field = {
		name:'isAciteRequired'
		,controlType: 'Input'
		,label: 'Is ACITE Required'
		,valueType: 3
		,defaultValue: false
		,errorId: 'invalidBool'
	};

	public static getInstance(): IndividualGradeCardForm {
		return IndividualGradeCardForm._instance;
	}

	constructor() {
		super();
		this.fields = new Map();
		this.controls = new Map();
		this.controls.set('degreeId', [Validators.required, Validators.max(9999999999999)]);
		this.fields.set('degreeId', this.degreeId);
		this.controls.set('levelId', [Validators.required, Validators.max(9999999999999)]);
		this.fields.set('levelId', this.levelId);
		this.controls.set('seasonId', [Validators.required, Validators.max(9999999999999)]);
		this.fields.set('seasonId', this.seasonId);
		this.controls.set('usn', [Validators.required, Validators.maxLength(1000)]);
		this.fields.set('usn', this.usn);
		this.controls.set('date', [Validators.required, Validators.maxLength(1000)]);
		this.fields.set('date', this.date);
		this.controls.set('finalMtechGradeCard', [Validators.required]);
		this.fields.set('finalMtechGradeCard', this.finalMtechGradeCard);
		this.controls.set('isAciteRequired', []);
		this.fields.set('isAciteRequired', this.isAciteRequired);
		this.listFields = ['degreeId','levelId','seasonId'];
	}

	public getName(): string {
		 return 'individualGradeCard';
	}
}


export class IndividualGradeCardFd extends FormData {
	constructor(form: IndividualGradeCardForm, sa: ServiceAgent) {
		super(form, sa);
	}

	setFieldValue(name: 'degreeId' | 'levelId' | 'seasonId' | 'usn' | 'date' | 'finalMtechGradeCard' | 'isAciteRequired', value: string | number | boolean | null ): void {
		super.setFieldValue(name, value);
	}

	getFieldValue(name: 'degreeId' | 'levelId' | 'seasonId' | 'usn' | 'date' | 'finalMtechGradeCard' | 'isAciteRequired' ): string | number | boolean | null {
		return super.getFieldValue(name);
	}
}


export interface IndividualGradeCardVo extends Vo {
	date?: string, 
	usn?: string, 
	degreeId?: number, 
	finalMtechGradeCard?: boolean, 
	seasonId?: number, 
	levelId?: number, 
	isAciteRequired?: boolean
}
