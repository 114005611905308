import { Component, OnInit, ViewChild, Input } from "@angular/core";
import { GroupPanel } from "../../interfaces";
import { ServiceAgent } from "src/app/framework-modules/formdata/form/serviceAgent";
import { SessionComponent } from "../../component";
import { ClientContext } from "src/app/framework-modules/formdata/form/clientContext";
import { TableMetaData,CtTableComponent} from "src/app/framework-modules/elements/ct-table/component";
import { AllSectionSelectionFd, AllSectionSelectionForm} from "src/app/framework-modules/formdata/gen/allSectionSelectionForm";
import { MarksForSectionForm, MarksForSectionFd } from "src/app/framework-modules/formdata/gen/marksForSectionForm";
import { MessageService } from "src/app/services/messageService";
import { ColToRowMeta, RowToColMeta, Transposer, Vo } from "src/app/framework-modules/formdata/form/types";
import { ExcelDownloadService } from "src/app/services/excelDownloadService";
import { CieSubjectDetailForm, CieSubjectDetailFd } from 'src/app/framework-modules/formdata/gen/cieSubjectDetailForm';

@Component({
  selector: "cie-calculation-list",
  templateUrl: "./component.html",
  styleUrls: ["./component.scss"]
})
export class CieCalculationListComponent implements OnInit {
  @ViewChild("gridTable", { static: false }) gtable: CtTableComponent;
  @Input() inputData: any;
  @Input() parentPanel: GroupPanel;
  @Input() session: SessionComponent;

  /**
   * for selecting subjectSection
   */
  form: AllSectionSelectionForm;
  fd: AllSectionSelectionFd;
  selectedSection = "";
  /**
   * for getting marks-list
   */
  public form1: MarksForSectionForm;
  public fd1: MarksForSectionFd;
  public cieForm:CieSubjectDetailForm;
  public cieFd:CieSubjectDetailFd;

  private colToRowMeta: ColToRowMeta;
  tableData: TableMetaData;

  constructor(
    private sa: ServiceAgent,
    private cc: ClientContext,
    private ms: MessageService,
    private ds: ExcelDownloadService
  ) {
    this.form = AllSectionSelectionForm.getInstance();
    this.fd = this.form.newFormData(this.sa);
    this.form1 = MarksForSectionForm.getInstance();
    this.fd1 = this.form1.newFormData(this.sa);
    this.cieForm = CieSubjectDetailForm.getInstance();
    this.cieFd = this.cieForm.newFormData(this.sa);
  }
  ngOnInit() {
    this.initTable();
  }

  ngAfterViewInit() {}

  sectionChanged(id: string) {
    if (this.selectedSection == id) {
      console.log("section remains as " + id);
      return;
    }
    this.selectedSection = id;

    if (id) {
      this.getData(id);
    }
  }

  getData(id: string) {
    this.fd1.setFieldValue("subjectSectionId", id);
    this.fd1.fetchData().subscribe({
      next: data => {
        this.selectedSection = id;
        this.setData(data);
      },

      error: msg => {
        console.error(msg);
        this.ms.showError("Server returned with an error message: " + msg[0].text);
      }
    });
  }
  /**
   *
   * @param data set the data reced from server into our table component after transposing it
   */
  setData(data) {
    /**
     * table meta data
     */
    const students = data.students as [];
    const colNames = ["name", "usn", "cieMarks"];
    const headings = { name: "Name", usn: "USN", cieMarks: "CIE" };

    if (data.isIntegrated) {
      /**
       * integrated subject will have thery and lab cie as additonal columns
       */
      colNames.push("theoryCieMarks");
      colNames.push("labCieMarks");
      headings["theoryCieMarks"] = "Theory";
      headings["labCieMarks"] = "Lab";
    } else {
      /**
       * non-integrated subject will have cie for tests as child rows.
       * We have to transpose these rows into columns
       */
      const meta: RowToColMeta = {
        datarowsAttribute: "marks",
        headingAttribute: "name",
        keyAttribute: "seqNo",
        rowKeyAttribute: "assessmentSeqNo",
        rowValueAttribute: "marks",
        columns: data.names as []
      };

      const tableMeta = { names: colNames, headings: headings };
      this.colToRowMeta = Transposer.rowToCol(meta, tableMeta, students);
    }
    this.resetTable(colNames, headings, students);
  }

  showIntegratedCie(data) {}

  initTable() {
    this.tableData = {
      data: [],
      metaData: {
        column_names: [],
        display_names: {},
        search: true,
        placeholder: "Search by Student Name, Student USN"
      }
    };
  }

  resetTable(colNames: string[], headings: object, data: object[]) {
    this.tableData.metaData.column_names = colNames;
    this.tableData.metaData.display_names = headings as {};
    this.tableData.data = data as Vo[];
    this.gtable.update();
  }

  calculateCie() {
    const data = { subjectSectionId: this.selectedSection };
    this.sa.serve("calculateCieForSection", { data: data }).subscribe({
      next: data => {
        this.ms.showSuccess("Cie claculated for this subject-section");
        this.getData(this.selectedSection);
      },

      error: msg => {
        console.error(msg);
        this.ms.showError("Server returned with an error message: " + msg[0].text);
      }
    });
  }

  doDownload(){
    this.sa.serve("get_cieSubjectSectionDetail", {
      data:{subjectSectionId:this.fd.getFieldValue('subjectSectionId')}
    }
    ).subscribe(
      data=>{
        this.ds.downloadCIE(data);
      }
    )
  }
}
