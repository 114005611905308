import { Component,Input, OnInit, ViewChild, AfterViewInit } from "@angular/core";
import { DynamicPanel, GroupPanel } from '../../interfaces';
import { TableMetaData, CtTableComponent } from 'src/app/framework-modules/elements/ct-table/component';
import { ServiceAgent } from 'src/app/framework-modules/formdata/form/serviceAgent';
import { MessageService } from 'src/app/services/messageService';
import { CtSpinner } from 'src/app/framework-modules/elements/ct-spinner/component';
import { ClientContext } from 'src/app/framework-modules/formdata/form/clientContext';
import { ExcelDownloadService } from 'src/app/services/excelDownloadService';
import { cloneDeep } from 'lodash';
import { SubjectRegisterDetailForm, SubjectRegisterDetailFd } from 'src/app/framework-modules/formdata/gen/subjectRegisterDetailForm';
import { BarChartMetaData } from 'src/app/framework-modules/elements/ct-barchart/component';
import { LineChartMetaData } from 'src/app/framework-modules/elements/ct-linechart/component';
import { ProgramLevelListForm, ProgramLevelListFd } from 'src/app/framework-modules/formdata/gen/programLevelListForm';
import { FilterRequest } from 'src/app/framework-modules/formdata/form/types';
import { StudentSubjectMarksForm, StudentSubjectMarksFd } from 'src/app/framework-modules/formdata/gen/studentSubjectMarksForm';
import { EnrolledStudentDetailFd, EnrolledStudentDetailForm } from 'src/app/framework-modules/formdata/gen/enrolledStudentDetailForm';
import { SubjectwiseResultForm, SubjectwiseResultFd, SubjectwiseResultVo } from 'src/app/framework-modules/formdata/gen/subjectwiseResultForm';
import { InstituteConfigurationFd, InstituteConfigurationForm, InstituteConfigurationVo } from 'src/app/framework-modules/formdata/gen/instituteConfigurationForm';
import { NumberCardMetaData } from 'src/app/framework-modules/elements/ct-number-card/component';
import { SubjectSectionTestStatisticsFd, SubjectSectionTestStatisticsForm } from 'src/app/framework-modules/formdata/gen/subjectSectionTestStatisticsForm';
import { ProgramAverageGpaDetailFd, ProgramAverageGpaDetailForm } from 'src/app/framework-modules/formdata/gen/programAverageGpaDetailForm';
import { StudentIdForUsnFd, StudentIdForUsnForm, StudentIdForUsnVo } from 'src/app/framework-modules/formdata/gen/studentIdForUsnForm';
import { CurrentBacklogSubjectDetailFd, CurrentBacklogSubjectDetailForm } from "src/app/framework-modules/formdata/gen/currentBacklogSubjectDetailForm";


@Component({
  selector: "student-dashboard-list",
  templateUrl: "./component.html",
  styleUrls: ["./component.scss"]
})


export class StudentArchiveComponent  implements DynamicPanel{
  @ViewChild("spinner", {static:false}) spinner: CtSpinner;
  @ViewChild("gridTable", { static: false }) gtable: CtTableComponent;
  @ViewChild("backlogTable", { static: false }) backlogTable: CtTableComponent;
  @ViewChild("gradeTable", { static: false }) gradeTable: CtTableComponent;
  @ViewChild("subjectsTable", { static: false }) subjectsTable: CtTableComponent;
  @Input() inputData: any;
  @Input() parentPanel: GroupPanel;


  public programForm: ProgramLevelListForm;
  public programFd: ProgramLevelListFd;
  public subjectWiseCIEMetaData: BarChartMetaData;
  public subjectWiseCIEData: {}[] = [];
  public subjectRegisterDetailForm : SubjectRegisterDetailForm;
  public subjectRegisterDetailFd : SubjectRegisterDetailFd;
  public attendanceMetaData: BarChartMetaData;
  public attendanceData: {}[] = [];
  public studentSubjectMarksForm : StudentSubjectMarksForm;
  public studentSubjectMarksFd : StudentSubjectMarksFd;
  public subjectTestMetaData : LineChartMetaData;
  public subjectTestData : {}[] = [];
  public lineChartData : {}[] = [];
  overallStats: boolean = true;
  public sgpaMetaData : LineChartMetaData;
  public sgpaData : {}[] = [];
  public spgaLineChartData : {}[] = [];
  public cgpaMetaData : LineChartMetaData;
  public cgpaData : {}[] = [];
  public cpgaLineChartData : {}[] = [];
  public enrolledStudentForm : EnrolledStudentDetailForm;
  public enrolledStudentFd : EnrolledStudentDetailFd;
  public enrolledSubjectsStudentData: TableMetaData;
  public gradeData: TableMetaData;
  public subjectWiseResultForm : SubjectwiseResultForm;
  public subjectWiseResultFd : SubjectwiseResultFd;
  public subjectWiseResultVo : SubjectwiseResultVo[];
  public instituteFd: InstituteConfigurationFd;
  public instituteVo: InstituteConfigurationVo;
  public numData: {}[];
  public numMetaData: NumberCardMetaData;
  public subjectTableData: TableMetaData;
  public form2: SubjectSectionTestStatisticsForm;
  public fd2: SubjectSectionTestStatisticsFd;
  public programAverageGpaFd: ProgramAverageGpaDetailFd;
  public studentIdForm: StudentIdForUsnForm;
  public studentIdFd: StudentIdForUsnFd;
  public studentIdVo: StudentIdForUsnVo = {};
  public enrolledBarChartMetaData: BarChartMetaData;
  public enrolledBarChartData: {}[] = [];
  public backlogSubjectsData: TableMetaData;
  public backlogForm: CurrentBacklogSubjectDetailForm;
  public backlogFd: CurrentBacklogSubjectDetailFd;


  switchName: 'Switch to Overall Stats' | 'Switch to Semester Stats' = 'Switch to Semester Stats';
  programName: any;
  studentName: any;
  usn: any;
  totalCredits: any;
  searched: boolean = false;
  phone: string;

  

  constructor(private sa: ServiceAgent, private ms: MessageService, private cc: ClientContext, private ds: ExcelDownloadService){
    this.programForm = ProgramLevelListForm.getInstance();
    this.programFd = this.programForm.newFormData(this.sa);
    this.subjectRegisterDetailForm = SubjectRegisterDetailForm.getInstance();
    this.subjectRegisterDetailFd = this.subjectRegisterDetailForm.newFormData(this.sa);
    this.studentSubjectMarksForm = StudentSubjectMarksForm.getInstance();
    this.studentSubjectMarksFd = this.studentSubjectMarksForm.newFormData(this.sa);
    this.enrolledStudentForm = EnrolledStudentDetailForm.getInstance();
    this.enrolledStudentFd = this.enrolledStudentForm.newFormData(this.sa);
    this.subjectWiseResultForm = SubjectwiseResultForm.getInstance();
    this.subjectWiseResultFd = this.subjectWiseResultForm.newFormData(this.sa);
    this.instituteFd = InstituteConfigurationForm.getInstance().newFormData(this.sa);
    this.form2 = SubjectSectionTestStatisticsForm.getInstance();
    this.fd2 = this.form2.newFormData(this.sa);
    this.programAverageGpaFd = ProgramAverageGpaDetailForm.getInstance().newFormData(this.sa);
    this.studentIdForm = StudentIdForUsnForm.getInstance();
    this.studentIdFd = this.studentIdForm.newFormData(this.sa);
    this.backlogForm = CurrentBacklogSubjectDetailForm.getInstance();
    this.backlogFd = this.backlogForm.newFormData(this.sa);
  }

  /**
   * Activated when the search button is pressed
   * This will output the studentId for a selected USN
   * Shows an error message to the user if student is not found
   */
  searchForStudent(){
    this.studentIdFd.setFieldValue('usn',this.studentIdFd.getFieldValue('usn').toString().trim().toUpperCase());
    this.studentIdFd.fetchData().subscribe(
      data => {
        this.studentIdVo = data;
        this.searched = true;
        this.overallStats = true;
        this.switchName = 'Switch to Semester Stats';
        this.phone = this.studentIdVo.phoneNumber;
        this.setStudentData();
      },
      err =>{
        this.ms.showInfo("No student Found!");
      }
    );
  }

  /**
   * Render all relevant student Data
   */
  setStudentData(){
    this.usn = this.studentIdVo.usn;
    this.studentName = this.studentIdVo.name;
    this.chartDataInit();
    this.setTableData();
    this.sgpaChartData();
    this.getBacklogDetails();
  }

  chartDataInit(){
    this.subjectWiseCIEMetaData = {
      view:[500,350],
      xAxisLabel: "Subjects",
      yAxisLabel: "Marks",
      showLegend: false,
    };

    this.attendanceMetaData = {
      view: [500,350],
      xAxisLabel: "Subjects",
      yAxisLabel: "Percentage",
      showLegend: false
    };

    this.subjectTestMetaData = {
      view: [600,300],
      xAxisLabel:'Tests',
      yAxisLabel:'Marks',
      showLegend: true,
      yScaleMax: 25
    };

    this.sgpaMetaData = {
      view: [500,200],
      xAxisLabel:'Semester',
      yAxisLabel:'SGPA',
      showLegend: true,
      yScaleMax: 10
    };

    this.cgpaMetaData = {
      view: [500,200],
      xAxisLabel:'Semester',
      yAxisLabel:'CGPA',
      showLegend: true,
      yScaleMax: 10
    };

    this.enrolledBarChartMetaData = {
      view: [500,350],
      xAxisLabel: "Grades",
      yAxisLabel: "Number",
      customToolTip: true,
      showLegend: false
    }
    

    this.enrolledSubjectsStudentData = {data: [],
      metaData: {  
        column_names: [    "subjectName",    "seasonName", "cieMarks", "examMarks",    "grade",    "credits"  ],  
        display_names:{    
          "subjectName":"Subject Name",    
          "seasonName":"Season Name",   
          "cieMarks": "CIE",
          "examMarks": "Exam", 
          "grade":"Grade",    
          "credits":"Credits"  
        },
        search:true,
        placeholder: "Search a subject...."
      }
    };

    this.gradeData = {data: [],
      metaData: {  
        column_names: [    "subjectName",    "seasonName",   "credits"  ],  
        display_names:{    
          "subjectName":"Subject",    
          "seasonName":"Season",   
          "credits":"Credits"  
        }
      }
    }

    this.backlogSubjectsData = {
      data: [],
      metaData: {
        column_names: ["subjectName", "subjectCode", "numberOfAttempts", "pendingCredits"],
        display_names: {
          "subjectName":"Subject",
          "subjectCode": "Code",
          "numberOfAttempts": "Number Of Attempts",
          "pendingCredits": "Pending Credits"
        }
      }
    }

    this.subjectTableData = {
      data: [],
      metaData: {
        column_names: [
          "subjectName",
          "cieMarks"
        ],
        display_names:{
          "subjectName":"Subject Name",
          "cieMarks": "CIE"
        },
        edit:true,
        buttonName:"VIEW"
      }
    }

    this.numMetaData = {
      view: [650,150]
    }
  }

  setTableData(){
    const f: FilterRequest = {
      conditions: {
        studentId: {value:this.studentIdVo.studentId, comp:'='},
      }
    }
    this.subjectWiseResultFd.filter(f).subscribe({
      next:data =>{
        this.formGradeChartData(data);
        this.enrolledSubjectsStudentData.data = data;
        this.gtable.update();
      }
    });
  }

  formGradeChartData(data: SubjectwiseResultVo[]){
     this.enrolledBarChartData = Object.values(data.reduce(
      (a, {grade}) => {
        a[grade] = a[grade] || {grade, name:grade, value: 0, tooltipText: 'Click to View More'};
        a[grade].value++;
        return a;
      }, 
      Object.create(null))
    );
    this.gradeData.data = this.enrolledSubjectsStudentData.data.filter((row)=>{
      return (row.grade == this.enrolledBarChartData[0]['name'])
   });
   this.gradeTable.update();
  }

  changeSeason($event){
    var seasonId = this.programFd.getFieldValue('seasonId');
    if(seasonId){
      const f: FilterRequest = {
        conditions: {
          seasonId: {value: seasonId, comp:'='},
          studentId: {value:this.studentIdVo.studentId, comp:'='},
        }
      }
    
      this.subjectRegisterDetailFd.filter(f).subscribe({
        next:data =>{
          var cieData = data as unknown as {}[];
          this.formChartdata(cieData);
        }
      });
    }
  }

  formChartdata(cieData){
    this.subjectWiseCIEData = [];
    this.attendanceData = [];
    var subjects = [];
    cieData.forEach(cie =>{
      if(cie['parentId']==0){
        this.subjectWiseCIEData.push(
          {"name":cie['subjectName'],"value":cie['cieMarks']}
        );
        this.attendanceData.push(
          {"name":cie['subjectName'],"value":parseFloat(cie['overallAttendance']) | 0}
        );
      }
      subjects.push(cie)
    });
    this.subjectTableData.data = subjects;
    this.subjectsTable.update();
  }

  sgpaChartData(){
    const f: FilterRequest = {
      conditions: {
        studentId: {value:this.studentIdVo.studentId, comp:'='}
      },
      sort: {levelId: "asc"}
    }
    this.enrolledStudentFd.filter(f).subscribe({
      next:data =>{
        var chartData = data as unknown as {}[];
        this.programName = chartData[0]['programName'];
        this.studentName = chartData[0]['name']
        this.usn = chartData[0]['usn']
        var sgpaSeries = [];
        var cgpaSeries = [];
        chartData.forEach(vo =>{
          sgpaSeries.push(
            {"name":vo['levelName'],"value":vo['sgpa']}
          )
          cgpaSeries.push(
            {"name":vo['levelName'],"value":vo['cgpa']}
          );
        });
        this.getAverageGpa(chartData[0]['programId'],cgpaSeries,sgpaSeries)
        var temp1 =cloneDeep(sgpaSeries);
        var temp2 = cloneDeep(cgpaSeries);
        var temp3 = cloneDeep(chartData);
        this.totalCredits = temp3.splice(-1)[0]['totalCredits'];
        this.numData = [
          {"name":"Current SGPA", value:temp1.splice(-1)[0].value},
          {"name":"Current CGPA", value:temp2.splice(-1)[0].value},
          {"name":"Total Credits", value: this.totalCredits}
        ];
      }
    });
  }

  /**
   * Gets the average GPA and plots it with student GPA
   * @param programId Program ID of the student
   * @param cgpaSeries CGPA details of the student
   * @param sgpaSeries SGPA details of the student
   */
  getAverageGpa(programId,cgpaSeries,sgpaSeries){
    const f: FilterRequest = {
      conditions: {
        programId: {comp: '=',value:programId}
      },
      sort: {levelId: "asc"}
    }
    this.programAverageGpaFd.filter(f).subscribe(
      data =>{
        var avgCgpa = [];
        var avgSgpa = [];
        data.forEach(level =>{
          avgSgpa.push(
            {"name":level['levelName'],"value":level['averageSgpa']}
          );
          avgCgpa.push(
            {"name":level['levelName'],"value":level['averageCgpa']}
          );
        });
        this.sgpaData = [
          {"name":this.programName + " Average","series":avgSgpa},
          {"name":this.studentName,"series":sgpaSeries}
        ]
        this.cgpaData = [
          {"name":this.programName + " Average","series":avgCgpa},
          {"name":this.studentName,"series":cgpaSeries}
        ]
      }
    )
  }

  toggle(){
    this.overallStats = !this.overallStats;
    if(this.overallStats){
      this.switchName='Switch to Semester Stats';
      this.setTableData();
    } 
    else{
      this.switchName='Switch to Overall Stats';
      this.programFd.setFieldValue('seasonId',this.cc.getcurrentSeasonId());
    }
  }

  changeSubject($event){
    var vo1 = this.subjectTableData.data[$event] as {};
    const studentFilter: FilterRequest = {
      conditions: {
        subjectSectionId: {value: vo1['subjectSectionId'] , comp:'='},
        studentId: {value:this.studentIdVo.studentId, comp:'='}
      },
      sort: {"seqNo":"asc"}
    }
    const overallFilter: FilterRequest = {
      conditions: {
        subjectSectionId: {value: vo1['subjectSectionId'] , comp:'='}
      },
      sort: {"seqNo":"asc"}
    }

    this.fd2.filter(overallFilter).subscribe(
      overallData =>{
        var avgBarData = []
        overallData.forEach(e=>{
          avgBarData.push(
            {"name":e.name,"value":e.average}
          )
        });
        this.formTestStats(avgBarData,studentFilter);
      }
    )
  }

  formTestStats(avgBarData,studentFilter){
    this.studentSubjectMarksFd.filter(studentFilter).subscribe({
      next:data =>{
        var subjectMarks = data as unknown as {}[];
        this.subjectTestData = [];
        this.lineChartData = [];
        subjectMarks.forEach(subMarks=>{
          var marks = parseFloat(subMarks['marks']) | 0;
          this.lineChartData.push(
            {"name":subMarks['name'],"value":marks}
          );
        });
        this.subjectTestData.push(
          {"name":"Class Average", series: avgBarData},
          {"name":this.studentName,"series":this.lineChartData}
        );
      }
    });
  }

  setGrade($event){
    this.gradeData.data = this.enrolledSubjectsStudentData.data.filter((row)=>{
      return (row.grade == $event.name)
   });
   this.gradeTable.update();
  }

  getBacklogDetails(){
    const f: FilterRequest = {
      conditions: {
        studentId: {comp:'=',value:this.studentIdVo.studentId}
      },
      sort: {pendingCredits:"desc"}
    }
    this.backlogFd.filter(f).subscribe({
      next: data =>{
        this.backlogSubjectsData.data = data;
        this.backlogTable.update();
      }
    })
  }

  download(tableData: TableMetaData,name: string){
    var excelData = []
    var cols = tableData.metaData.column_names;
    var displayMap = tableData.metaData.display_names;

    tableData.data.forEach(d => {
      var row = {}
      cols.forEach(col =>{
        row[displayMap[col]] = d[col]
      });
      excelData.push(row);
    });

    this.ds.downloadExcelBook({"Sheet1": excelData},name);
  }

}