
import { Form , Field, ChildForm } from '../form/form';
import { FormData } from '../form/formData';
import { SelectOption, Vo } from '../form/types';
import { Validators } from '@angular/forms'
import { ServiceAgent} from '../form/serviceAgent';
import { SectionPpNpGradeForm, SectionPpNpGradeVo } from './sectionPpNpGradeForm';

export class PpNpGradeForm extends Form {
	private static _instance = new PpNpGradeForm();
	subjectName:Field = {
		name:'subjectName'
		,controlType: 'Output'
		,label: 'Subject'
		,valueType: 0
		,defaultValue: ''
		,errorId: 'invalidName'
		,maxLength: 150
	};
	sectionName:Field = {
		name:'sectionName'
		,controlType: 'Output'
		,label: 'Section'
		,valueType: 0
		,defaultValue: ''
		,errorId: 'invalidName'
		,maxLength: 150
	};
	subjectSectionId:Field = {
		name:'subjectSectionId'
		,controlType: 'Hidden'
		,label: 'subjectSectionId'
		,isRequired: true
		,valueType: 1
		,errorId: 'invalidId'
		,maxValue: 9999999999999
	};

	students: ChildForm = {
		name:'students',
		form:SectionPpNpGradeForm.getInstance(),
		isEditable:false,
		isTabular:true,
		label:'',
		minRows:1,
		maxRows:0,
		errorId:null
	};

	public static getInstance(): PpNpGradeForm {
		return PpNpGradeForm._instance;
	}

	constructor() {
		super();
		this.fields = new Map();
		this.controls = new Map();
		this.controls.set('subjectName', [Validators.maxLength(150)]);
		this.fields.set('subjectName', this.subjectName);
		this.controls.set('sectionName', [Validators.maxLength(150)]);
		this.fields.set('sectionName', this.sectionName);
		this.controls.set('subjectSectionId', [Validators.required, Validators.max(9999999999999)]);
		this.fields.set('subjectSectionId', this.subjectSectionId);

		this.childForms = new Map();
		this.childForms.set('students', this.students);
		this.opsAllowed = {get: true, update: true};
		this.keyFields = ["subjectSectionId"];
	}

	public getName(): string {
		 return 'ppNpGrade';
	}
}


export class PpNpGradeFd extends FormData {
	constructor(form: PpNpGradeForm, sa: ServiceAgent) {
		super(form, sa);
	}

	setFieldValue(name: 'subjectName' | 'sectionName' | 'subjectSectionId', value: string | number | boolean | null ): void {
		super.setFieldValue(name, value);
	}

	getFieldValue(name: 'subjectName' | 'sectionName' | 'subjectSectionId' ): string | number | boolean | null {
		return super.getFieldValue(name);
	}
}


export interface PpNpGradeVo extends Vo {
	sectionName?: string, 
	subjectSectionId?: number, 
	instituteId?: number, 
	subjectCode?: string, 
	subjectId?: number, 
	subjectName?: string, 
	students?: SectionPpNpGradeVo
}
