
import { Form , Field, ChildForm } from '../form/form';
import { FormData } from '../form/formData';
import { SelectOption, Vo } from '../form/types';
import { Validators } from '@angular/forms'
import { ServiceAgent} from '../form/serviceAgent';

export class GuardianForm extends Form {
	private static _instance = new GuardianForm();
	guardianId:Field = {
		name:'guardianId'
		,controlType: 'Hidden'
		,label: 'guardianId'
		,isRequired: true
		,valueType: 1
		,defaultValue: -1
		,errorId: 'invalidFlexibleId'
		,minValue: -1
		,maxValue: 9999999999999
	};
	studentId:Field = {
		name:'studentId'
		,controlType: 'Hidden'
		,label: 'studentId'
		,valueType: 1
		,errorId: 'invalidFlexibleId'
		,minValue: -1
		,maxValue: 9999999999999
	};
	instituteId:Field = {
		name:'instituteId'
		,controlType: 'Hidden'
		,label: 'instituteId'
		,valueType: 1
		,errorId: 'invalidTenentKey'
		,maxValue: 9999999999999
	};
	relationType:Field = {
		name:'relationType'
		,controlType: 'Input'
		,label: 'Relationship'
		,isRequired: true
		,listName: 'relationType'
		,valueList: [
			{value:'Mother',text:'Mother'},
			{value:'Father',text:'Father'},
			{value:'Legal Guardian',text:'Legal Guardian'}
			]
		,valueType: 0
		,errorId: 'invalidText'
		,maxLength: 1000
	};
	name:Field = {
		name:'name'
		,controlType: 'Input'
		,label: 'Name'
		,isRequired: true
		,valueType: 0
		,errorId: 'invalidName'
		,maxLength: 150
	};
	gender:Field = {
		name:'gender'
		,controlType: 'Dropdown'
		,label: 'Gender'
		,listName: 'gender'
		,valueList: [
			{value:'Male',text:'Male'},
			{value:'Female',text:'Female'},
			{value:'Others',text:'Others'},
			{value:'Not Applicable',text:'Not Applicable'}
			]
		,valueType: 0
		,errorId: 'invalidGender'
		,maxLength: 10
	};
	addressLine1:Field = {
		name:'addressLine1'
		,controlType: 'Input'
		,label: 'Premanent Address - Line 1'
		,valueType: 0
		,errorId: 'invalidDesc'
		,maxLength: 1000
	};
	addressLine2:Field = {
		name:'addressLine2'
		,controlType: 'Input'
		,label: 'Premanent Address - Line 2'
		,valueType: 0
		,errorId: 'invalidDesc'
		,maxLength: 1000
	};
	city:Field = {
		name:'city'
		,controlType: 'Input'
		,label: 'Premanent City'
		,valueType: 0
		,errorId: 'invalidName'
		,maxLength: 150
	};
	state:Field = {
		name:'state'
		,controlType: 'Dropdown'
		,label: 'Premanent State'
		,listName: 'state'
		,listKey: 'country'
		,keyedList: {
			91 : [
				{value:'Karnataka',text:'Karnataka'}, 
				{value:'Tamil Nadu',text:'Tamil Nadu'}, 
				{value:'Kerala',text:'Kerala'}, 
				{value:'Uttar Pradesh',text:'Uttar Pradesh'}
			], 
			130 : [
				{value:'Karnataka',text:'Karnataka'}, 
				{value:'Tamil Nadu',text:'Tamil Nadu'}, 
				{value:'Kerala',text:'Kerala'}, 
				{value:'Uttar Pradesh',text:'Uttar Pradesh'}
			]
			}
		,valueType: 0
		,errorId: 'invalidState'
		,maxLength: 50
	};
	pincode:Field = {
		name:'pincode'
		,controlType: 'Input'
		,label: 'Premanent Pin Code'
		,valueType: 0
		,errorId: 'invalidPin'
		,minLength: 6
		,maxLength: 6
	};
	country:Field = {
		name:'country'
		,controlType: 'Input'
		,label: 'Premanent Country'
		,valueType: 1
		,defaultValue: 130
		,errorId: 'invalidCountry'
		,maxValue: 999
	};
	phoneNumber:Field = {
		name:'phoneNumber'
		,controlType: 'Input'
		,label: 'Phone'
		,valueType: 0
		,errorId: 'invalidPhone'
		,minLength: 10
		,maxLength: 12
	};
	email:Field = {
		name:'email'
		,controlType: 'Input'
		,label: 'Email'
		,isRequired: true
		,valueType: 0
		,errorId: 'invalidEmail'
		,maxLength: 1000
	};
	occupation:Field = {
		name:'occupation'
		,controlType: 'Input'
		,label: 'Occupation'
		,valueType: 0
		,errorId: 'invalidText'
		,maxLength: 1000
	};
	annualSalary:Field = {
		name:'annualSalary'
		,controlType: 'Input'
		,label: 'Annual Salary'
		,valueType: 1
		,errorId: 'invalidIncome'
		,maxValue: 9999999999999
	};
	qualification:Field = {
		name:'qualification'
		,controlType: 'Input'
		,label: 'Qualification'
		,valueType: 0
		,errorId: 'invalidText'
		,maxLength: 1000
	};
	createdAt:Field = {
		name:'createdAt'
		,controlType: 'Hidden'
		,label: 'createdAt'
		,valueType: 5
		,errorId: 'invalidTimestamp'
	};
	updatedAt:Field = {
		name:'updatedAt'
		,controlType: 'Hidden'
		,label: 'updatedAt'
		,valueType: 5
		,errorId: 'invalidTimestamp'
	};

	public static getInstance(): GuardianForm {
		return GuardianForm._instance;
	}

	constructor() {
		super();
		this.fields = new Map();
		this.controls = new Map();
		this.controls.set('guardianId', [Validators.required, Validators.min(-1), Validators.max(9999999999999)]);
		this.fields.set('guardianId', this.guardianId);
		this.controls.set('studentId', [Validators.min(-1), Validators.max(9999999999999)]);
		this.fields.set('studentId', this.studentId);
		this.controls.set('instituteId', [Validators.max(9999999999999)]);
		this.fields.set('instituteId', this.instituteId);
		this.controls.set('relationType', [Validators.required, Validators.maxLength(1000)]);
		this.fields.set('relationType', this.relationType);
		this.controls.set('name', [Validators.required, Validators.maxLength(150)]);
		this.fields.set('name', this.name);
		this.controls.set('gender', [Validators.maxLength(10)]);
		this.fields.set('gender', this.gender);
		this.controls.set('addressLine1', [Validators.maxLength(1000)]);
		this.fields.set('addressLine1', this.addressLine1);
		this.controls.set('addressLine2', [Validators.maxLength(1000)]);
		this.fields.set('addressLine2', this.addressLine2);
		this.controls.set('city', [Validators.maxLength(150)]);
		this.fields.set('city', this.city);
		this.controls.set('state', [Validators.maxLength(50)]);
		this.fields.set('state', this.state);
		this.controls.set('pincode', [Validators.minLength(6), Validators.maxLength(6), Validators.pattern('[1-9][0-9]{5}')]);
		this.fields.set('pincode', this.pincode);
		this.controls.set('country', [Validators.max(999)]);
		this.fields.set('country', this.country);
		this.controls.set('phoneNumber', [Validators.minLength(10), Validators.maxLength(12), Validators.pattern('[1-9][0-9]*')]);
		this.fields.set('phoneNumber', this.phoneNumber);
		this.controls.set('email', [Validators.required, Validators.email, Validators.maxLength(1000)]);
		this.fields.set('email', this.email);
		this.controls.set('occupation', [Validators.maxLength(1000)]);
		this.fields.set('occupation', this.occupation);
		this.controls.set('annualSalary', [Validators.max(9999999999999)]);
		this.fields.set('annualSalary', this.annualSalary);
		this.controls.set('qualification', [Validators.maxLength(1000)]);
		this.fields.set('qualification', this.qualification);
		this.controls.set('createdAt', []);
		this.fields.set('createdAt', this.createdAt);
		this.controls.set('updatedAt', []);
		this.fields.set('updatedAt', this.updatedAt);
		this.opsAllowed = {get: true, create: true, update: true, filter: true};
		this.listFields = ['relationType','gender','state'];
		this.keyFields = ["guardianId"];
	}

	public getName(): string {
		 return 'guardian';
	}
}


export class GuardianFd extends FormData {
	constructor(form: GuardianForm, sa: ServiceAgent) {
		super(form, sa);
	}

	setFieldValue(name: 'guardianId' | 'studentId' | 'instituteId' | 'relationType' | 'name' | 'gender' | 'addressLine1' | 'addressLine2' | 'city' | 'state' | 'pincode' | 'country' | 'phoneNumber' | 'email' | 'occupation' | 'annualSalary' | 'qualification' | 'createdAt' | 'updatedAt', value: string | number | boolean | null ): void {
		super.setFieldValue(name, value);
	}

	getFieldValue(name: 'guardianId' | 'studentId' | 'instituteId' | 'relationType' | 'name' | 'gender' | 'addressLine1' | 'addressLine2' | 'city' | 'state' | 'pincode' | 'country' | 'phoneNumber' | 'email' | 'occupation' | 'annualSalary' | 'qualification' | 'createdAt' | 'updatedAt' ): string | number | boolean | null {
		return super.getFieldValue(name);
	}
}


export interface GuardianVo extends Vo {
	relationType?: string, 
	annualSalary?: number, 
	pincode?: string, 
	country?: number, 
	occupation?: string, 
	gender?: string, 
	city?: string, 
	guardianId?: number, 
	studentId?: number, 
	qualification?: string, 
	createdAt?: string, 
	phoneNumber?: string, 
	name?: string, 
	instituteId?: number, 
	addressLine1?: string, 
	addressLine2?: string, 
	state?: string, 
	email?: string, 
	updatedAt?: string
}
