import { Component, OnInit, ViewChild, Input} from "@angular/core";
import { DynamicPanel, GroupPanel } from '../../interfaces';
import { ServiceAgent } from 'src/app/framework-modules/formdata/form/serviceAgent';
import { SessionComponent } from '../../component';
import { CtSpinner } from 'src/app/framework-modules/elements/ct-spinner/component';
import { CtTableComponent, TableMetaData } from "src/app/framework-modules/elements/ct-table/component";
import { ExcelParserService } from 'src/app/services/excelParserService';
import { MessageService } from 'src/app/services/messageService';
import { CtDialogComponent } from 'src/app/framework-modules/elements/ct-dialog/component';
import { MatDialog } from '@angular/material';
import { ExcelTemplate, ExcelDownloadService } from 'src/app/services/excelDownloadService';
import * as _ from 'lodash';

@Component({
  selector: "student-upload",
  templateUrl: "./component.html",
  styleUrls: ["./component.scss"]
})

export class StudentUploadComponent implements DynamicPanel, OnInit {
  @ViewChild("spinner", {static:false}) spinner: CtSpinner;
  @ViewChild("gridTable", { static: false }) gtable: CtTableComponent;
  @Input() inputData: any;
  @Input() parentPanel: GroupPanel;
  @Input() session: SessionComponent;
  public tableData:TableMetaData;
  public Data: [];
  public errorFlag : boolean = false;

  constructor(private sa: ServiceAgent, private ep: ExcelParserService,private ms: MessageService, private dialog: MatDialog, private ds: ExcelDownloadService) {

  }
  metaDataInit(){
    this.tableData = {
      data: [
      ],
      metaData: {
        column_names: [
          "usn",
          "name",
          "fatherName",
          "motherName",
          "gender",
          "category",
          "phoneNumber",
          "email",
          "status"
        ],
        display_names: {
          "usn": "USN",
          "name": "Student Name",
          "fatherName": "Father Name",
          "motherName": "Mother Name",
          "gender": "Gender",
          "category": "Category",
          "phoneNumber": "Phone Number",
          "email": "Email",
          "status": "Data Status"
        },
        edit: false,
        search: true,
        placeholder: "Search By Student Name,USN, etc..",
        styleAttribute: {
          "status": [
            {comp: '=', value: 'MISSING', style:{'color':'red', 'font-weight': 600}},
            {comp: '=', value: 'EXTRACTED', style:{'color':'green', 'font-weight': 600}},
           ]
        }
      }
    };
  }

  ngOnInit() {
    this.metaDataInit();
  }

  ngAfterViewInit() {
    this.gtable.update();
  }

  clearList() {
    this.errorFlag = false;
    this.tableData.data = [];
    this.gtable.update();
  }

  onFileChange(ev){
    // student data parser
    this.clearList();
    this.ep.parseStudentUploadedExcel(ev,"Student Data").subscribe(
      data =>{
        this.tableData.data = data["tableData"];
        this.gtable.update();
        this.Data = data["student"];
        let msg:string = ``
        this.errorFlag = (data["errorRows"].length == 0) ? false : true
        msg = (data["errorRows"].length == 0)? "Data Extraction Finished, Please Upload" : `Data missing in excel rows - ${String(data["errorRows"])}`
        this.ms.showError(msg);
      }
    );
  }

  downloadTemplate(){
    let templateData = [];
    let template: ExcelTemplate = {
      fixedHeader: [
      ],
      dynamicHeader: [],
      merges: [],
      tableArray: "Students",
      tableStart: 1,
      tableStyle: { rowHeight: 15, },
      tableHeader: ["USN", "Student Name", "Father Name", "Mother Name", "Gender", "Category", "Mobile Phone Number", "E-mail ID"],
      tableValues: [
      ],
      colWidths: [35, 65,65, 65, 10, 20, 35, 65],
      fixedFooter: [],
      dynamicFootfer: [],
      footerMerges: [],
      footerRowHeights: [],
      tableHeaderStyle: { font: { size: 14, name: 'Cambria', bold: true }, alignment: { horizontal: 'center', vertical: 'middle' }, border: {bottom: {style: 'thin'},top: {style: 'thin'}, left: {style: 'thin'},right:{style: 'thin'} }, fill: {type: 'pattern',pattern: 'solid',fgColor: {  argb: 'FF00FF00'}}, numFmt: '', protection: {locked:true} }
    }
    this.ds.excelFromTemplate(template,{"Students": []},"Student Update Template","Student Data")
  }

  popUpMessage(){
    if(this.errorFlag){
      let dialogRef = this.dialog.open(CtDialogComponent);
      dialogRef.componentInstance.title = 'INFORM';
      dialogRef.componentInstance.content = 'System will not update student data with status as MISSING, Are you sure you want to continue?';
      dialogRef.componentInstance.primary = 'Continue';
      const subscribeDialog = dialogRef.componentInstance.save.subscribe((data) => {
        // Yet to implement bulk upload functionality
        this.dialog.closeAll();
        this.uploadData(this.Data)
      });
    }else{
      this.uploadData(this.Data);
    }
  }

  uploadData(data){
    // student upload service
    if(this.Data.length>0){
      const options = { students: data };
      this.sa.serve("update_bulkStudentData", { data: options }).subscribe({
        next: data => {
          this.ms.showSuccess("uploaded Successfully.Please check data once");
        },
        error: msg => {
          console.error(msg);
          this.ms.showError(msg[msg.length-1].text);
        }
      });
    }else{
      this.ms.showError("No student data found");
    }
  }
}
