import { Component, OnInit, ViewChild, Input } from "@angular/core";
import { DynamicPanel, GroupPanel } from '../../interfaces';
import { ServiceAgent } from 'src/app/framework-modules/formdata/form/serviceAgent';
import { CtTableComponent} from '../../../../framework-modules/elements/ct-table/component'
import { SessionComponent } from '../../component';
import { ClientContext } from 'src/app/framework-modules/formdata/form/clientContext';
import { MessageService } from 'src/app/services/messageService';
import { FilterRequest } from 'src/app/framework-modules/formdata/form/types';
import { FormData } from 'src/app/framework-modules/formdata/form/formData';
import { TaughtSubjectSelectionForm, TaughtSubjectSelectionFd } from 'src/app/framework-modules/formdata/gen/taughtSubjectSelectionForm';
import { TaughtSectionSelectionFd, TaughtSectionSelectionForm } from 'src/app/framework-modules/formdata/gen/taughtSectionSelectionForm';
import { OfferedSubjectDetailForm } from 'src/app/framework-modules/formdata/gen/offeredSubjectDetailForm';
import { CieActivitiesForm, CieActivitiesFd } from 'src/app/framework-modules/formdata/gen/cieActivitiesForm';
import { SubjectFrozenStatusForm, SubjectFrozenStatusFd } from 'src/app/framework-modules/formdata/gen/subjectFrozenStatusForm';
import { SubjectSectionFrozenStatusForm, SubjectSectionFrozenStatusFd } from 'src/app/framework-modules/formdata/gen/subjectSectionFrozenStatusForm';


@Component({
  selector: "assign-staff-list",
  templateUrl: "./component.html",
  styleUrls: ["./component.scss"]
})

export class FreezeListComponent implements DynamicPanel,OnInit {
  @ViewChild("gridTable", { static: false }) gtable: CtTableComponent;
  @Input() inputData: any;
  @Input() parentPanel: GroupPanel;
  @Input() session: SessionComponent;

  public form:CieActivitiesForm;
  public fd:CieActivitiesFd;
  public form1:TaughtSubjectSelectionForm;
  public fd1:TaughtSubjectSelectionFd;
  public form2:TaughtSectionSelectionForm;
  public fd2:TaughtSectionSelectionFd;
  public instituteCieFrozen:boolean;
  public instituteAttendanceFrozen:boolean;
  public subjectCieFrozen:boolean;
  public subjectAttendanceFrozen:boolean;
  public subjectSectionCieFrozen:boolean;
  public subjectSectionAttendanceFrozen:boolean;
  public selectedSubject;
  public selectedSubjectSection;
  public subjectFrozenStatusForm : SubjectFrozenStatusForm;
  public subjectFrozenStatusFd : SubjectFrozenStatusFd;
  public subjectSectionFrozenStatusForm : SubjectSectionFrozenStatusForm;
  public subjectSectionFrozenStatusFd : SubjectSectionFrozenStatusFd;


 

  constructor(private sa: ServiceAgent, private ctx: ClientContext,private ms:MessageService) {
    this.form = CieActivitiesForm.getInstance();
    this.fd = new FormData(this.form,this.sa);
    this.form1 = TaughtSubjectSelectionForm.getInstance();
    this.fd1 = new FormData(this.form1,this.sa);
    this.form2 = TaughtSectionSelectionForm.getInstance();
    this.fd2 = new FormData(this.form2,this.sa);
    this.subjectFrozenStatusForm = SubjectFrozenStatusForm.getInstance();
    this.subjectFrozenStatusFd = new FormData(this.subjectFrozenStatusForm,this.sa);
    this.subjectSectionFrozenStatusForm = SubjectSectionFrozenStatusForm.getInstance();
    this.subjectSectionFrozenStatusFd = new FormData(this.subjectSectionFrozenStatusForm,this.sa);
  }

  ngOnInit(){
    this.fd.fetchData().subscribe({
      next:vo=>{
        this.instituteAttendanceFrozen = vo.attendanceEntryFrozen as boolean;
        this.instituteCieFrozen = vo.cieEntryFrozen as boolean;
        this.subjectChanged();
      }
    });
  }

  /**
   * Institute level freezing !
   */
  freezeInstituteCie(){
    this.sa.serve("freezeCie").subscribe({
      next: data => {
        this.ms.showSuccess('CIE frozen for the Whole Institute');
        this.subjectChanged();
        this.parentPanel.navigate('FreezeList');
      },

      error: msg => {
        this.ms.showError('Server returned with an error message. ');
      }
    });
  }

  freezeInstituteAttendance(){
    this.sa.serve("freezeAttendance").subscribe({
      next: data => {
        this.ms.showSuccess('Attendance frozen for the Whole Institute');
        this.subjectChanged();
        this.parentPanel.navigate('FreezeList');
      },

      error: msg => {
        this.ms.showError('Server returned with an error message. ');
      }
    });
  }

   unfreezeInstituteAll(){
     this.unfreezeInstituteCie();
     this.unfreezeInstituteAttendance();
     this.subjectChanged();
   }
   unfreezeInstituteCie(){
    this.sa.serve("unfreezeCie").subscribe({
      next: data => {
        this.ms.showSuccess('CIE unfrozen for the Whole Institute');
        this.subjectChanged();
        this.parentPanel.navigate('FreezeList');
      },

      error: msg => {
        this.ms.showError('Server returned with an error message. ');
      }
    });
  }

  unfreezeInstituteAttendance(){
    this.sa.serve("unfreezeAttendance").subscribe({
      next: data => {
        this.ms.showSuccess('Attendance unfrozen for the Whole Institute');
        this.subjectChanged();
        this.parentPanel.navigate('FreezeList');
      },

      error: msg => {
        this.ms.showError('Server returned with an error message. ');
      }
    });
  }

   freezeInstituteAll(){
     this.freezeInstituteCie();
     this.freezeInstituteAttendance();
     this.subjectChanged();
   }

   /**
    * Subject Level Freezing
    */

   freezeSubjectCie(){
     const data = {offeredSubjectId:this.selectedSubject};
     this.sa.serve("freezeCieForSubject",{data:data}).subscribe({
      next: data => {
        this.ms.showSuccess('CIE frozen for the selected subject');
        this.subjectChanged();
        this.parentPanel.navigate('FreezeList');
      },

      error: msg => {
        this.ms.showError('Server returned with an error message. ');
      }
    });
  }

  freezeSubjectAttendance(){
    const data = {offeredSubjectId:this.selectedSubject};
    this.sa.serve("freezeAttendanceForSubject",{data:data}).subscribe({
      next: data => {
        this.ms.showSuccess('Attendance frozen for the selected subject');
        this.subjectChanged();
        this.parentPanel.navigate('FreezeList');
      },

      error: msg => {
        this.ms.showError('Server returned with an error message. ');
      }
    });
  }

   unfreezeSubjectAll(){
     this.unfreezeSubjectCie();
     this.unfreezeSubjectAttendance();
     this.subjectChanged();
   }
   unfreezeSubjectCie(){
    const data = {offeredSubjectId:this.selectedSubject};
    this.sa.serve("unfreezeCieForSubject",{data:data}).subscribe({
      next: data => {
        this.ms.showSuccess('CIE unfrozen for the Selected Subject');
        this.subjectChanged();
        this.parentPanel.navigate('FreezeList');
      },

      error: msg => {
        this.ms.showError('Server returned with an error message. ');
      }
    });
  }

  unfreezeSubjectAttendance(){
    const data = {offeredSubjectId:this.selectedSubject};
    this.sa.serve("unfreezeAttendanceForSubject",{data:data}).subscribe({
      next: data => {
        this.ms.showSuccess('Attendance unfrozen for the selected subject');
        this.subjectChanged();
        this.parentPanel.navigate('FreezeList');
      },

      error: msg => {
        this.ms.showError('Server returned with an error message. ');
      }
    });
  }

  freezeSubjectAll(){
    this.freezeSubjectCie();
    this.freezeSubjectAttendance();
    this.subjectChanged();
  }

  subjectChanged(){
    this.selectedSubject=this.fd1.getFieldValue('offeredSubjectId');
    if(this.selectedSubject != null)
    {
      const f: FilterRequest = {
        conditions: {
          offeredSubjectId: {value:this.selectedSubject, comp:'='},
        }
      }
      this.subjectFrozenStatusFd.filter(f).subscribe({
          next:data =>{
            this.subjectCieFrozen = data[0]['cieFrozen'] as boolean;
            this.subjectAttendanceFrozen = data[0]['attendanceFrozen'] as boolean;
          }
        });
    }
  }
  
  /**
   * subject section level freeze
   */
   freezeSubjectSectionCie(){
    const data = {subjectSectionId:this.selectedSubjectSection};
    this.sa.serve("freezeCieForSection",{data:data}).subscribe({
     next: data => {
       this.ms.showSuccess('CIE frozen for the selected section');
       this.subjectSectionChanged();
       this.parentPanel.navigate('FreezeList');
     },

     error: msg => {
       this.ms.showError('Server returned with an error message. ');
     }
   });
 }

  freezeSubjectSectionAttendance(){
    const data = {subjectSectionId:this.selectedSubjectSection};
    this.sa.serve("freezeAttendanceForSection",{data:data}).subscribe({
      next: data => {
        this.ms.showSuccess('Attendance frozen for the selected subject');
        this.subjectSectionChanged();
        this.parentPanel.navigate('FreezeList');
      },

      error: msg => {
        this.ms.showError('Server returned with an error message. ');
      }
    });
  }

  freezeSubjectSectionAll(){
    this.freezeSubjectSectionCie();
    this.freezeSubjectSectionAttendance();
    this.subjectSectionChanged();
  }

  unfreezeSubjectSectionCie(){
    const data = {subjectSectionId:this.selectedSubjectSection};
    this.sa.serve("unfreezeCieForSection",{data:data}).subscribe({
      next: data => {
        this.ms.showSuccess('CIE unfrozen for the Selected Section');
        this.subjectSectionChanged();
        this.parentPanel.navigate('FreezeList');
      },

      error: msg => {
        this.ms.showError('Server returned with an error message. ');
      }
    });
  }

  unfreezeSubjectSectionAttendance(){
    const data = {subjectSectionId:this.selectedSubjectSection};
    this.sa.serve("unfreezeAttendanceForSection",{data:data}).subscribe({
      next: data => {
        this.ms.showSuccess('Attendance unfrozen for the selected Section');
        this.subjectSectionChanged();
        this.parentPanel.navigate('FreezeList');
      },

      error: msg => {
        this.ms.showError('Server returned with an error message. ');
      }
    });
  }

  unfreezeSubjectSectionAll(){
    this.unfreezeSubjectSectionCie();
    this.unfreezeSubjectSectionAttendance();
    this.subjectSectionChanged();
  }

  subjectSectionChanged(){
    this.selectedSubjectSection = this.fd2.getFieldValue('subjectSectionId');
    this.selectedSubject = this.fd2.getFieldValue('offeredSubjectId');
    if(this.selectedSubjectSection != null && this.selectedSubject != null){
      const f: FilterRequest = {
        conditions: {
          subjectSectionId: {value:this.selectedSubjectSection, comp:'='},
        }
      }
      this.subjectSectionFrozenStatusFd.filter(f).subscribe({
          next:data =>{
            this.subjectSectionCieFrozen = data[0]['cieFrozen'] as boolean;
            this.subjectSectionAttendanceFrozen = data[0]['attendanceFrozen'] as boolean;
          }
        });
    }
  }
}