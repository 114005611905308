import { Component,Input, OnInit, ViewChild, AfterViewInit } from "@angular/core";
import { DynamicPanel, GroupPanel } from '../../interfaces';
import { TableMetaData, CtTableComponent } from 'src/app/framework-modules/elements/ct-table/component';
import { PieGridChartMetaData } from 'src/app/framework-modules/elements/ct-piegridchart/component';
import { SubjectwiseResultForm, SubjectwiseResultFd } from 'src/app/framework-modules/formdata/gen/subjectwiseResultForm';
import { ServiceAgent } from 'src/app/framework-modules/formdata/form/serviceAgent';
import { MessageService } from 'src/app/services/messageService';
import { CtSpinner } from 'src/app/framework-modules/elements/ct-spinner/component';
import { ExcelDownloadService } from 'src/app/services/excelDownloadService';
import { cloneDeep } from 'lodash';


@Component({
  selector: "dashboard-view",
  templateUrl: "./component.html",
  styleUrls: ["./component.scss"]
})


export class DashBoardViewComponent  implements DynamicPanel, AfterViewInit{
  @ViewChild("spinner", {static:false}) spinner: CtSpinner;
  @ViewChild("gridTable", { static: false }) gtable: CtTableComponent;
  @Input() inputData: any;
  @Input() parentPanel: GroupPanel;
  public tableData: TableMetaData;
  public resultStatisticsForm: SubjectwiseResultForm;
  public resultStatisticsFd: SubjectwiseResultFd;

  constructor(private sa: ServiceAgent, private ms: MessageService, private ds: ExcelDownloadService){
    this.resultStatisticsForm = SubjectwiseResultForm.getInstance();
    this.resultStatisticsFd = this.resultStatisticsForm.newFormData(this.sa);
  }

  ngAfterViewInit(){
    this.initMetadata();
    this.filter();
  }

  initMetadata() {
    this.tableData = {
      data: [],
      metaData: {
        column_names: [
          "name",
          "usn",
          "subjectName",
          "cieMarks",
          "examMarks",
          "grade"
        ],
        display_names:{
          "usn": "USN",
          "name": "Name",
          "subjectName": "Subject Name",
          "cieMarks": "CIE",
          "examMarks": "Exam",
          "grade": "Grade"
        },
        search: true,
        placeholder: "Search a student..."
      }
    };
    
  }

  filter(){
    this.spinner.show("Loading.....");
    this.resultStatisticsFd.filter(this.inputData[0]).subscribe({
      next: data =>{
        this.tableData.data = data;
        this.gtable.update();
        this.spinner.hide();
      },
      error: err =>{
        this.spinner.hide();
        this.ms.showError("No students found")
        this.parentPanel.navigate("DashboardList")
      }
    });
  }
  
  goBack() {
    this.parentPanel.navigate("DashboardList");
  }

  download(tableData: TableMetaData,name: string){
    var excelData = []
    var cols = [
    "studentId",
    "name",
    "usn",
    "departmentName",
    "subjectCode",
    "subjectName",
    "cieMarks",
    "examMarks",
    "grade"]
    var displayMap = {
      "studentId": "studentId",
      "usn": "USN",
      "name": "Name",
      "departmentName": "Department",
      "subjectCode": "Code",
      "subjectName": "Subject",
      "cieMarks": "CIE",
      "examMarks": "Exam",
      "grade": "Grade"
    };
    tableData.data.forEach(d => {
      var row = {}
      cols.forEach(col =>{
        row[displayMap[col]] = d[col]
      });
      excelData.push(row);
    });
    var metaData = {};
    if(this.inputData[2]){
      var countData = Object.values(excelData.reduce(
        (a, {studentId,Name,USN, Department, Code,Subject}) => {
          a[studentId] = a[studentId] || {studentId,Name, USN, Department, Codes:'', Subjects:'', Count: 0};
          a[studentId].Count++;
          a[studentId].Codes += Code + ',\n';
          a[studentId].Subjects += Subject + ',\n';
          return a;
        }, 
        Object.create(null))
      );
      let countResult = countData.map(({studentId, ...rest}) => rest);
      let studentResults = excelData.map(({studentId, ...rest}) => rest);
      countResult.sort(this.predicateDescendingBy('Count'));
      metaData["Count"] = countResult;
      metaData["Student Statistics"]= studentResults;
    }
    else{
      let studentResults = excelData.map(({studentId, ...rest}) => rest);
      metaData["Student Statistics"]= studentResults
    }
    this.ds.downloadExcelBook(metaData,name);
  }

  private predicateDescendingBy(prop){
    return function(a,b){
      if (a[prop] > b[prop]){return -1;} 
      else if(a[prop] < b[prop]){return 1;}
      return 0;
    }
  }

}