
import { Form , Field, ChildForm } from '../form/form';
import { FormData } from '../form/formData';
import { SelectOption, Vo } from '../form/types';
import { Validators } from '@angular/forms'
import { ServiceAgent} from '../form/serviceAgent';

export class HallTicketSubjectForm extends Form {
	private static _instance = new HallTicketSubjectForm();

	public static getInstance(): HallTicketSubjectForm {
		return HallTicketSubjectForm._instance;
	}

	constructor() {
		super();
		this.fields = new Map();
		this.controls = new Map();
		this.opsAllowed = {get: true, filter: true};
		this.keyFields = ["studentId"];
	}

	public getName(): string {
		 return 'hallTicketSubject';
	}
}


export class HallTicketSubjectFd extends FormData {
	constructor(form: HallTicketSubjectForm, sa: ServiceAgent) {
		super(form, sa);
	}
	/**  this form has no editable fields. data nust be accessed as Vo and not through fd **/
}


export interface HallTicketSubjectVo extends Vo {
	studentId?: number, 
	qpCode?: string, 
	seasonId?: number, 
	examDate?: string, 
	isEligibleForMakeup?: boolean, 
	instituteId?: number, 
	isEligible?: string, 
	subjectCode?: string, 
	subjectName?: string
}
