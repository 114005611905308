import { Component, ViewChild, Input, OnInit } from "@angular/core";
import { DynamicPanel, GroupPanel } from '../../interfaces';
import { ServiceAgent } from 'src/app/framework-modules/formdata/form/serviceAgent';
import { SessionComponent } from '../../component';
import { CtCheckboxTableComponent } from 'src/app/framework-modules/elements/ct-checkbox-table/component';
import { AssignStaffToSectionForm, AssignStaffToSectionFd, AssignStaffToSectionVo } from 'src/app/framework-modules/formdata/gen/assignStaffToSectionForm';
import { AssignableStaffVo } from 'src/app/framework-modules/formdata/gen/assignableStaffForm';
import { MessageService } from 'src/app/services/messageService';
import { MatDialog } from '@angular/material';
import { CtDialogComponent } from 'src/app/framework-modules/elements/ct-dialog/component';
import { TableMetaData } from 'src/app/framework-modules/elements/ct-table/component';

@Component({
  selector: "subject-list",
  templateUrl: "./component.html",
  styleUrls: ["./component.scss"]
})


export class AssignStaffEditComponent implements DynamicPanel, OnInit {
  @ViewChild("gridTable", { static: false }) gtable: CtCheckboxTableComponent;
  @Input() inputData: any;
  @Input() parentPanel: GroupPanel;
  @Input() session: SessionComponent;
  public tableData: TableMetaData;
  public form: AssignStaffToSectionForm;
  public fd: AssignStaffToSectionFd;
  public vo: AssignStaffToSectionVo;
  public nameAttr = 'name';
  public selectAttr = 'isAssigned';
  public staffs: AssignableStaffVo[] = [];

  constructor(private sa: ServiceAgent, private ms: MessageService, private dialog: MatDialog) {
    this.form = AssignStaffToSectionForm.getInstance();
    this.fd = this.form.newFormData(this.sa);
    this.vo = this.fd.getRawData();
  }

  ngOnInit() {
    /*
     * get data for this subjectsection
     */
    this.metaDataInit();
    this.fd.setFieldValue("subjectSectionId", this.inputData);
    this.fd.fetchData().subscribe({
      next: vo => {
        this.vo = this.fd.getRawData();
        this.staffs = vo.staffs as AssignableStaffVo[];
        this.tableData.data = this.staffs;
        this.gtable.update();
      },
      // error: msg => this.ms.showError("Error from server " + msg)
    });
  }

  save() {
    let dialogRef = this.dialog.open(CtDialogComponent);
    dialogRef.componentInstance.title = 'Save Changes';
    dialogRef.componentInstance.content = 'Are you sure you want to save changes?';
    dialogRef.componentInstance.primary = 'Save';
    dialogRef.componentInstance.save.subscribe((data) => {
      this.dialog.closeAll();
      this.fd.save().subscribe({
        next: vo => {
          this.ms.showSuccess('Success ! Staff Assigned to ' + this.vo.subjectName + ' Successfully. Staff has been Notified!');
          this.parentPanel.navigate('AssignStaff');
        },
        error: msg => {
          this.ms.showError("Please select atleast one staff member!");
        }
      });
    });
    
  }
  
  cancel() {
    let dialogRef = this.dialog.open(CtDialogComponent);
    dialogRef.componentInstance.title = 'Discard Changes';
    dialogRef.componentInstance.content = 'Are you sure you want to discard changes?';
    dialogRef.componentInstance.primary = 'Discard';
    const subscribeDialog = dialogRef.componentInstance.save.subscribe((data) => {
      this.dialog.closeAll();
      this.parentPanel.navigate('AssignStaff');
    });
  }

  metaDataInit(){
    this.tableData = {
      data:[],
      metaData:{
        column_names:[
          "name",
          "email"
        ],
        display_names:{
          "name":"Staff Name",
          "email":"Email"
        },
        selectAttr:"isAssigned",
        search:true,
        placeholder:"Search by Staff name and/or Staff Id",
        itemName: "Staffs",
        managerFunction: "ASSIGN"
      }
    }
  }
}