
import { Form , Field, ChildForm } from '../form/form';
import { FormData } from '../form/formData';
import { SelectOption, Vo } from '../form/types';
import { Validators } from '@angular/forms'
import { ServiceAgent} from '../form/serviceAgent';

export class StudentSubjectAndAssignedStaffNameForm extends Form {
	private static _instance = new StudentSubjectAndAssignedStaffNameForm();
	seasonId:Field = {
		name:'seasonId'
		,controlType: 'Input'
		,label: 'Season Id'
		,isRequired: true
		,listName: 'seasonList'
		,valueType: 1
		,errorId: 'invalidId'
		,maxValue: 9999999999999
	};

	public static getInstance(): StudentSubjectAndAssignedStaffNameForm {
		return StudentSubjectAndAssignedStaffNameForm._instance;
	}

	constructor() {
		super();
		this.fields = new Map();
		this.controls = new Map();
		this.controls.set('seasonId', [Validators.required, Validators.max(9999999999999)]);
		this.fields.set('seasonId', this.seasonId);
		this.opsAllowed = {get: true, filter: true};
		this.listFields = ['seasonId'];
	}

	public getName(): string {
		 return 'studentSubjectAndAssignedStaffName';
	}
}


export class StudentSubjectAndAssignedStaffNameFd extends FormData {
	constructor(form: StudentSubjectAndAssignedStaffNameForm, sa: ServiceAgent) {
		super(form, sa);
	}

	setFieldValue(name: 'seasonId', value: string | number | boolean | null ): void {
		super.setFieldValue(name, value);
	}

	getFieldValue(name: 'seasonId' ): string | number | boolean | null {
		return super.getFieldValue(name);
	}
}


export interface StudentSubjectAndAssignedStaffNameVo extends Vo {
	departmentName?: string, 
	departmentId?: number, 
	levelName?: string, 
	studentID?: number, 
	sectionName?: string, 
	usn?: string, 
	seasonId?: number, 
	levelId?: number, 
	name?: string, 
	staffName?: string, 
	instituteId?: number, 
	subjectCode?: string, 
	programId?: number, 
	subjectName?: string
}
