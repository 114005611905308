import { Component, Input, Output, EventEmitter, OnInit, ViewChild } from "@angular/core";
import { DynamicPanel, LandingPanel, GroupPanel } from '../../interfaces';
import { ServiceAgent } from 'src/app/framework-modules/formdata/form/serviceAgent';
import { SessionComponent } from '../../component';
import { FormData } from 'src/app/framework-modules/formdata/form/formData';
import { ProgramForm, ProgramVo } from 'src/app/framework-modules/formdata/gen/programForm';
import { CtTableComponent, TableMetaData } from 'src/app/framework-modules/elements/ct-table/component';
import { FilterRequest } from 'src/app/framework-modules/formdata/form/types';
import { ClientContext } from 'src/app/framework-modules/formdata/form/clientContext';
import { ProgramListForm, ProgramListVo } from 'src/app/framework-modules/formdata/gen/programListForm';


@Component({
  selector: "program-list",
  templateUrl: "./component.html",
  styleUrls: ["./component.scss"]
})


export class ProgramListComponent implements DynamicPanel, OnInit {
  @ViewChild("gridTable", { static: false }) gtable: CtTableComponent;
  @Input() inputData: any;
  @Input() parentPanel: GroupPanel;
  @Input() session: SessionComponent;
  public tableData: TableMetaData;
  public from: ProgramListForm;
  public fd: FormData;
  public vo: ProgramListVo;

  constructor(private sa: ServiceAgent, private cc: ClientContext) {
    this.from = ProgramListForm.getInstance();
    this.fd = this.from.newFormData(this.sa);
  }

  metaDataInit() {
    this.tableData = {
      data: [],
      metaData: {
        column_names: [
          "programName",
          "programCode",
          "departmentCode"
        ],
        display_names: {
          "programName": "Program Name",
          "programCode": "Program Code",
          "departmentCode": "Departments"
        },
        badgesColumn: "departmentCode",
        disableBadges: true,
        allSelected: false,
        edit: false,
        search: true,
        placeholder: "Search By Program Name...",
        view: false,
      },
    };
  }

  ngOnInit() {
    this.metaDataInit();
    this.filter();
  }

  filter() {
    this.fd.filter().subscribe(
      data => {
        this.tableData.data = data;
        this.gtable.update();
      },
      msgs => {
        console.error(msgs);
      }
    );
  }

  doAddProgram($event) {
    this.parentPanel.navigate('ProgramAdd');
  }

  goBack() {
    this.parentPanel.navigate('InstituteList')
  }

  edit($event) {
    this.parentPanel.navigate('ProgramEdit', this.tableData.data[$event].departmentId);
  }


}