
import { Form , Field, ChildForm } from '../form/form';
import { FormData } from '../form/formData';
import { SelectOption, Vo } from '../form/types';
import { Validators } from '@angular/forms'
import { ServiceAgent} from '../form/serviceAgent';

export class MarksForAssessmentForm extends Form {
	private static _instance = new MarksForAssessmentForm();
	studentAssessmentId:Field = {
		name:'studentAssessmentId'
		,controlType: 'Hidden'
		,label: 'studentAssessmentId'
		,valueType: 1
		,errorId: 'invalidId'
		,maxValue: 9999999999999
	};
	studentId:Field = {
		name:'studentId'
		,controlType: 'Hidden'
		,label: 'studentId'
		,isRequired: true
		,valueType: 1
		,errorId: 'invalidId'
		,maxValue: 9999999999999
	};
	name:Field = {
		name:'name'
		,controlType: 'Output'
		,label: 'name'
		,valueType: 0
		,errorId: 'invalidName'
		,maxLength: 150
	};
	usn:Field = {
		name:'usn'
		,controlType: 'Output'
		,label: 'usn'
		,valueType: 0
		,errorId: 'invalidText'
		,maxLength: 1000
	};
	marks:Field = {
		name:'marks'
		,controlType: 'Output'
		,label: 'marks'
		,valueType: 0
		,defaultValue: ''
		,errorId: 'invalidMarksOrAb'
		,maxLength: 5
	};

	public static getInstance(): MarksForAssessmentForm {
		return MarksForAssessmentForm._instance;
	}

	constructor() {
		super();
		this.fields = new Map();
		this.controls = new Map();
		this.controls.set('studentAssessmentId', [Validators.max(9999999999999)]);
		this.fields.set('studentAssessmentId', this.studentAssessmentId);
		this.controls.set('studentId', [Validators.required, Validators.max(9999999999999)]);
		this.fields.set('studentId', this.studentId);
		this.controls.set('name', [Validators.maxLength(150)]);
		this.fields.set('name', this.name);
		this.controls.set('usn', [Validators.maxLength(1000)]);
		this.fields.set('usn', this.usn);
		this.controls.set('marks', [Validators.maxLength(5), Validators.pattern('(ab|AB|Ab|\\d{0,2}\\.?\\d{0,2})')]);
		this.fields.set('marks', this.marks);
		this.opsAllowed = {get: true, update: true};
		this.keyFields = ["studentAssessmentId"];
	}

	public getName(): string {
		 return 'marksForAssessment';
	}
}


export class MarksForAssessmentFd extends FormData {
	constructor(form: MarksForAssessmentForm, sa: ServiceAgent) {
		super(form, sa);
	}

	setFieldValue(name: 'studentAssessmentId' | 'studentId' | 'name' | 'usn' | 'marks', value: string | number | boolean | null ): void {
		super.setFieldValue(name, value);
	}

	getFieldValue(name: 'studentAssessmentId' | 'studentId' | 'name' | 'usn' | 'marks' ): string | number | boolean | null {
		return super.getFieldValue(name);
	}
}


export interface MarksForAssessmentVo extends Vo {
	subjectRegisterId?: number, 
	hasWithdrawn?: boolean, 
	marks?: string, 
	studentId?: number, 
	studentAssessmentId?: number, 
	usn?: string, 
	assessmentSeqNo?: number, 
	subjectSectionId?: number, 
	assessmentName?: string, 
	name?: string, 
	instituteId?: number, 
	marksScored?: number, 
	assessmentSchemeId?: number, 
	hasAttended?: boolean
}
