
import { Form , Field, ChildForm } from '../form/form';
import { FormData } from '../form/formData';
import { SelectOption, Vo } from '../form/types';
import { Validators } from '@angular/forms'
import { ServiceAgent} from '../form/serviceAgent';
import { MarksForAssessmentForm, MarksForAssessmentVo } from './marksForAssessmentForm';

export class MarksEntryForm extends Form {
	private static _instance = new MarksEntryForm();
	subjectSectionId:Field = {
		name:'subjectSectionId'
		,controlType: 'Hidden'
		,label: 'subjectSectionId'
		,isRequired: true
		,valueType: 1
		,errorId: 'invalidId'
		,maxValue: 9999999999999
	};
	assessmentSchemeId:Field = {
		name:'assessmentSchemeId'
		,controlType: 'Hidden'
		,label: 'assessmentSchemeId'
		,isRequired: true
		,valueType: 1
		,errorId: 'invalidId'
		,maxValue: 9999999999999
	};
	seqNo:Field = {
		name:'seqNo'
		,controlType: 'Hidden'
		,label: 'seqNo'
		,isRequired: true
		,valueType: 1
		,errorId: 'invalidInteger'
		,maxValue: 9999999999999
	};
	subjectName:Field = {
		name:'subjectName'
		,controlType: 'Output'
		,label: 'subjectName'
		,valueType: 0
		,defaultValue: ''
		,errorId: 'invalidName'
		,maxLength: 150
	};
	sectionName:Field = {
		name:'sectionName'
		,controlType: 'Output'
		,label: 'sectionName'
		,valueType: 0
		,defaultValue: ''
		,errorId: 'invalidName'
		,maxLength: 150
	};
	assessmentName:Field = {
		name:'assessmentName'
		,controlType: 'Output'
		,label: 'assessmentName'
		,valueType: 0
		,errorId: 'invalidText'
		,maxLength: 1000
	};
	maxMarks:Field = {
		name:'maxMarks'
		,controlType: 'Output'
		,label: 'maxMarks'
		,valueType: 1
		,errorId: 'invalidInteger'
		,maxValue: 9999999999999
	};

	students: ChildForm = {
		name:'students',
		form:MarksForAssessmentForm.getInstance(),
		isEditable:false,
		isTabular:true,
		label:'',
		minRows:1,
		maxRows:0,
		errorId:null
	};

	public static getInstance(): MarksEntryForm {
		return MarksEntryForm._instance;
	}

	constructor() {
		super();
		this.fields = new Map();
		this.controls = new Map();
		this.controls.set('subjectSectionId', [Validators.required, Validators.max(9999999999999)]);
		this.fields.set('subjectSectionId', this.subjectSectionId);
		this.controls.set('assessmentSchemeId', [Validators.required, Validators.max(9999999999999)]);
		this.fields.set('assessmentSchemeId', this.assessmentSchemeId);
		this.controls.set('seqNo', [Validators.required, Validators.max(9999999999999)]);
		this.fields.set('seqNo', this.seqNo);
		this.controls.set('subjectName', [Validators.maxLength(150)]);
		this.fields.set('subjectName', this.subjectName);
		this.controls.set('sectionName', [Validators.maxLength(150)]);
		this.fields.set('sectionName', this.sectionName);
		this.controls.set('assessmentName', [Validators.maxLength(1000)]);
		this.fields.set('assessmentName', this.assessmentName);
		this.controls.set('maxMarks', [Validators.max(9999999999999)]);
		this.fields.set('maxMarks', this.maxMarks);

		this.childForms = new Map();
		this.childForms.set('students', this.students);
		this.opsAllowed = {get: true, update: true};
		this.keyFields = ["subjectSectionId"];
	}

	public getName(): string {
		 return 'marksEntry';
	}
}


export class MarksEntryFd extends FormData {
	constructor(form: MarksEntryForm, sa: ServiceAgent) {
		super(form, sa);
	}

	setFieldValue(name: 'subjectSectionId' | 'assessmentSchemeId' | 'seqNo' | 'subjectName' | 'sectionName' | 'assessmentName' | 'maxMarks', value: string | number | boolean | null ): void {
		super.setFieldValue(name, value);
	}

	getFieldValue(name: 'subjectSectionId' | 'assessmentSchemeId' | 'seqNo' | 'subjectName' | 'sectionName' | 'assessmentName' | 'maxMarks' ): string | number | boolean | null {
		return super.getFieldValue(name);
	}
}


export interface MarksEntryVo extends Vo {
	levelSectionId?: number, 
	seqNo?: number, 
	departmentId?: number, 
	attendanceFrozen?: boolean, 
	cieFrozen?: boolean, 
	sectionId?: number, 
	subjectId?: number, 
	maxMarks?: number, 
	sectionName?: string, 
	offeredSubjectId?: number, 
	isOffered?: boolean, 
	totalClasses?: number, 
	subjectSectionId?: number, 
	assessmentName?: string, 
	instituteId?: number, 
	assessmentSchemeId?: number, 
	subjectCode?: string, 
	subjectName?: string, 
	students?: MarksForAssessmentVo
}
