import { Component, OnInit, ViewChild, Input} from "@angular/core";
import { DynamicPanel, GroupPanel } from '../../interfaces';
import { ServiceAgent } from 'src/app/framework-modules/formdata/form/serviceAgent';
import { TableMetaData } from 'src/app/framework-modules/elements/ct-table/component'
import { SessionComponent } from '../../component';
import { MatDialog } from '@angular/material';
import { CtCheckboxTableComponent } from 'src/app/framework-modules/elements/ct-checkbox-table/component';
import { CtDialogComponent } from 'src/app/framework-modules/elements/ct-dialog/component';
import { CtSpinner } from 'src/app/framework-modules/elements/ct-spinner/component';
import { MessageService } from 'src/app/services/messageService';
import { ChallengeValuationStudentForm, ChallengeValuationStudentFd, ChallengeValuationStudentVo } from 'src/app/framework-modules/formdata/gen/challengeValuationStudentForm';
import { Vo } from 'src/app/framework-modules/formdata/form/types';

@Component({
  selector: "challenge-edit",
  templateUrl: "./component.html",
  styleUrls: ["./component.scss"],
})

export class ChallengeValuationEditComponent implements DynamicPanel, OnInit {
  @ViewChild("gridTable", { static: false }) gtable: CtCheckboxTableComponent;
  @ViewChild("spinner", { static: false }) spinner: CtSpinner;
  @Input() inputData: any;
  @Input() parentPanel: GroupPanel;
  @Input() session: SessionComponent;

  public tableData: TableMetaData;
  private fd: ChallengeValuationStudentFd;
  public vo: ChallengeValuationStudentVo;
  private form:ChallengeValuationStudentForm;
  private students: [];

  constructor(private sa: ServiceAgent,
    private dialog: MatDialog,
    private ms: MessageService) {
    
    this.form = ChallengeValuationStudentForm.getInstance()
    this.fd = this.form.newFormData(this.sa);
    this.vo = {};
    this.students = [];
  }

  ngOnInit() {
    this.fd.setFieldValue('offeredSubjectId',this.inputData);
    this.metaDataInit();
  }

  ngAfterViewInit() {
    if (this.inputData) {
      this.getData(this.inputData);
    } 
  }

  metaDataInit() {
    this.tableData = {
      data: [],
      metaData: {
        column_names: [
          "name",
          "usn",
          "department",
          "level"
        ],
        display_names: {
          name: "Name",
          usn: "USN",
          department: "Department Name",
          level: "Level"
        },
        selectAttr:"optedForChallenge",
        search: true,
        placeholder: "Search By Name,USN,Department Name...",
        managerFunction: "SAVE",
        itemName: "Students"
      },
    };
  }
  private getData(data) {
    this.spinner.show('Getting attendance register..');
    /**
     * offeredSubjectId is the primary key in the form
     */
    this.fd.fetchData().subscribe({
      next: data => {
        this.vo = data;
        var students = data.students as Vo[];
        var notOptedStudents = []
        students.forEach(element => {
          if(!element.optedForChallenge){
            notOptedStudents.push(element);
          }
        });
        this.tableData.data = notOptedStudents;
        this.gtable.update(this.spinner);
      },

      error: msg => {
        this.spinner.hide();
        this.ms.showError('server returned with errors');
      }
    });
  }


  private saveData() {
    this.fd.save().subscribe({
      next: data => {
        this.ms.showSuccess('Attendance Register updted..');
        this.parentPanel.navigate('ChallengeValuationList');
      },

      error: msg => {
        this.ms.showError('server returned with errors');
      }
    });
  }


  /**
   * Function is called when the user clicks save button.
   * Saves the assigned evaluators to a asubject.
   */
  save() {
    let dialogRef = this.dialog.open(CtDialogComponent);
    dialogRef.componentInstance.title = 'Save Changes';
    dialogRef.componentInstance.content = 'Are you sure you want to save changes?';
    dialogRef.componentInstance.primary = 'Save';
    const subscribeDialog = dialogRef.componentInstance.save.subscribe((data) => {
      this.saveData();
      this.dialog.closeAll();
    });
  }

  /**
   * Function is called when the user clicks cancel button.
   * Goes back to assign Evaluators list screen
   */
  cancel() {
    let dialogRef = this.dialog.open(CtDialogComponent);
    dialogRef.componentInstance.title = 'Discard Changes';
    dialogRef.componentInstance.content = 'Are you sure you want to discard changes?';
    dialogRef.componentInstance.primary = 'Discard';
    const subscribeDialog = dialogRef.componentInstance.save.subscribe((data) => {
      this.dialog.closeAll();
      this.parentPanel.navigate("ChallengeValuationList");
    });
  }
}