
import { Form , Field, ChildForm } from '../form/form';
import { FormData } from '../form/formData';
import { SelectOption, Vo } from '../form/types';
import { Validators } from '@angular/forms'
import { ServiceAgent} from '../form/serviceAgent';

export class SubjectListForm extends Form {
	private static _instance = new SubjectListForm();
	subjectId:Field = {
		name:'subjectId'
		,controlType: 'Hidden'
		,label: 'subjectId'
		,isRequired: true
		,valueType: 1
		,errorId: 'invalidId'
		,maxValue: 9999999999999
	};
	instituteId:Field = {
		name:'instituteId'
		,controlType: 'Hidden'
		,label: 'instituteId'
		,valueType: 1
		,errorId: 'invalidTenentKey'
		,maxValue: 9999999999999
	};
	name:Field = {
		name:'name'
		,controlType: 'Input'
		,label: 'Subject Name'
		,isRequired: true
		,valueType: 0
		,errorId: 'invalidName'
		,maxLength: 150
	};
	code:Field = {
		name:'code'
		,controlType: 'Input'
		,label: 'Subject Code'
		,isRequired: true
		,valueType: 0
		,errorId: 'invalidCode'
		,maxLength: 50
	};
	department:Field = {
		name:'department'
		,controlType: 'Input'
		,label: 'Offering Department'
		,isRequired: true
		,valueType: 0
		,errorId: 'invalidText'
		,maxLength: 1000
	};
	lectureCredits:Field = {
		name:'lectureCredits'
		,controlType: 'Input'
		,label: 'Lecture Credits'
		,isRequired: true
		,valueType: 1
		,errorId: 'invalidInteger'
		,maxValue: 9999999999999
	};
	practicalCredits:Field = {
		name:'practicalCredits'
		,controlType: 'Input'
		,label: 'Practical Credits'
		,isRequired: true
		,valueType: 1
		,errorId: 'invalidInteger'
		,maxValue: 9999999999999
	};
	tutorialCredits:Field = {
		name:'tutorialCredits'
		,controlType: 'Input'
		,label: 'Tutorial Credits'
		,isRequired: true
		,valueType: 1
		,errorId: 'invalidInteger'
		,maxValue: 9999999999999
	};
	totalCredits:Field = {
		name:'totalCredits'
		,controlType: 'Input'
		,label: 'Total Credits'
		,isRequired: true
		,valueType: 1
		,errorId: 'invalidInteger'
		,maxValue: 9999999999999
	};
	isActive:Field = {
		name:'isActive'
		,controlType: 'Input'
		,label: 'Is Active'
		,isRequired: true
		,valueType: 3
		,errorId: 'invalidBool'
	};
	parentId:Field = {
		name:'parentId'
		,controlType: 'Input'
		,label: 'Is Active'
		,isRequired: true
		,valueType: 1
		,errorId: 'invalidId'
		,maxValue: 9999999999999
	};

	public static getInstance(): SubjectListForm {
		return SubjectListForm._instance;
	}

	constructor() {
		super();
		this.fields = new Map();
		this.controls = new Map();
		this.controls.set('subjectId', [Validators.required, Validators.max(9999999999999)]);
		this.fields.set('subjectId', this.subjectId);
		this.controls.set('instituteId', [Validators.max(9999999999999)]);
		this.fields.set('instituteId', this.instituteId);
		this.controls.set('name', [Validators.required, Validators.maxLength(150)]);
		this.fields.set('name', this.name);
		this.controls.set('code', [Validators.required, Validators.maxLength(50)]);
		this.fields.set('code', this.code);
		this.controls.set('department', [Validators.required, Validators.maxLength(1000)]);
		this.fields.set('department', this.department);
		this.controls.set('lectureCredits', [Validators.required, Validators.max(9999999999999)]);
		this.fields.set('lectureCredits', this.lectureCredits);
		this.controls.set('practicalCredits', [Validators.required, Validators.max(9999999999999)]);
		this.fields.set('practicalCredits', this.practicalCredits);
		this.controls.set('tutorialCredits', [Validators.required, Validators.max(9999999999999)]);
		this.fields.set('tutorialCredits', this.tutorialCredits);
		this.controls.set('totalCredits', [Validators.required, Validators.max(9999999999999)]);
		this.fields.set('totalCredits', this.totalCredits);
		this.controls.set('isActive', [Validators.required]);
		this.fields.set('isActive', this.isActive);
		this.controls.set('parentId', [Validators.required, Validators.max(9999999999999)]);
		this.fields.set('parentId', this.parentId);
		this.opsAllowed = {get: true, create: true, update: true, filter: true};
		this.keyFields = ["subjectId"];
	}

	public getName(): string {
		 return 'subjectList';
	}
}


export class SubjectListFd extends FormData {
	constructor(form: SubjectListForm, sa: ServiceAgent) {
		super(form, sa);
	}

	setFieldValue(name: 'subjectId' | 'instituteId' | 'name' | 'code' | 'department' | 'lectureCredits' | 'practicalCredits' | 'tutorialCredits' | 'totalCredits' | 'isActive' | 'parentId', value: string | number | boolean | null ): void {
		super.setFieldValue(name, value);
	}

	getFieldValue(name: 'subjectId' | 'instituteId' | 'name' | 'code' | 'department' | 'lectureCredits' | 'practicalCredits' | 'tutorialCredits' | 'totalCredits' | 'isActive' | 'parentId' ): string | number | boolean | null {
		return super.getFieldValue(name);
	}
}


export interface SubjectListVo extends Vo {
	totalCredits?: number, 
	code?: string, 
	lectureCredits?: number, 
	practicalCredits?: number, 
	tutorialCredits?: number, 
	name?: string, 
	instituteId?: number, 
	department?: string, 
	isActive?: boolean, 
	subjectId?: number, 
	parentId?: number
}
