
import { Form , Field, ChildForm } from '../form/form';
import { FormData } from '../form/formData';
import { SelectOption, Vo } from '../form/types';
import { Validators } from '@angular/forms'
import { ServiceAgent} from '../form/serviceAgent';
import { AssessmentItemForm, AssessmentItemVo } from './assessmentItemForm';

export class LogicBlockForAssessmentSchemeForm extends Form {
	private static _instance = new LogicBlockForAssessmentSchemeForm();
	selectMethod:Field = {
		name:'selectMethod'
		,controlType: 'Dropdown'
		,label: 'Select A Method'
		,isRequired: true
		,listName: 'selectMethod'
		,valueList: [
			{value:'1',text:'Best of'},
			{value:'2',text:'Average of'},
			{value:'3',text:'Compulsory'}
			]
		,valueType: 1
		,defaultValue: 1
		,errorId: 'invalidInteger'
		,maxValue: 9999999999999
	};
	numberOfTests:Field = {
		name:'numberOfTests'
		,controlType: 'Input'
		,label: 'Number of Tests'
		,isRequired: true
		,valueType: 1
		,errorId: 'invalidInteger'
		,maxValue: 9999999999999
	};
	totalTests:Field = {
		name:'totalTests'
		,controlType: 'Input'
		,label: 'Total Number of Tests'
		,isRequired: true
		,valueType: 1
		,errorId: 'invalidInteger'
		,maxValue: 9999999999999
	};

	assessmentItem: ChildForm = {
		name:'assessmentItem',
		form:AssessmentItemForm.getInstance(),
		isEditable:false,
		isTabular:true,
		label:'',
		minRows:1,
		maxRows:0,
		errorId:null
	};

	public static getInstance(): LogicBlockForAssessmentSchemeForm {
		return LogicBlockForAssessmentSchemeForm._instance;
	}

	constructor() {
		super();
		this.fields = new Map();
		this.controls = new Map();
		this.controls.set('selectMethod', [Validators.required, Validators.max(9999999999999)]);
		this.fields.set('selectMethod', this.selectMethod);
		this.controls.set('numberOfTests', [Validators.required, Validators.max(9999999999999)]);
		this.fields.set('numberOfTests', this.numberOfTests);
		this.controls.set('totalTests', [Validators.required, Validators.max(9999999999999)]);
		this.fields.set('totalTests', this.totalTests);

		this.childForms = new Map();
		this.childForms.set('assessmentItem', this.assessmentItem);
		this.listFields = ['selectMethod'];
	}

	public getName(): string {
		 return 'logicBlockForAssessmentScheme';
	}
}


export class LogicBlockForAssessmentSchemeFd extends FormData {
	constructor(form: LogicBlockForAssessmentSchemeForm, sa: ServiceAgent) {
		super(form, sa);
	}

	setFieldValue(name: 'selectMethod' | 'numberOfTests' | 'totalTests', value: string | number | boolean | null ): void {
		super.setFieldValue(name, value);
	}

	getFieldValue(name: 'selectMethod' | 'numberOfTests' | 'totalTests' ): string | number | boolean | null {
		return super.getFieldValue(name);
	}
}


export interface LogicBlockForAssessmentSchemeVo extends Vo {
	selectMethod?: number, 
	numberOfTests?: number, 
	totalTests?: number, 
	assessmentItem?: AssessmentItemVo
}
