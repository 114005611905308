
import { Form , Field, ChildForm } from '../form/form';
import { FormData } from '../form/formData';
import { SelectOption, Vo } from '../form/types';
import { Validators } from '@angular/forms'
import { ServiceAgent} from '../form/serviceAgent';

export class AssessmentSchemeSelectionForm extends Form {
	private static _instance = new AssessmentSchemeSelectionForm();
	departmentId:Field = {
		name:'departmentId'
		,controlType: 'Dropdown'
		,label: 'Department'
		,isRequired: true
		,listName: 'departmentList'
		,valueType: 1
		,errorId: 'invalidId'
		,maxValue: 9999999999999
	};
	offeredSubjectId:Field = {
		name:'offeredSubjectId'
		,controlType: 'Dropdown'
		,label: 'Subject'
		,isRequired: true
		,listName: 'taughtSubjectList'
		,listKey: 'departmentId'
		,valueType: 1
		,errorId: 'invalidId'
		,maxValue: 9999999999999
	};
	assessmentSchemeId:Field = {
		name:'assessmentSchemeId'
		,controlType: 'Dropdown'
		,label: 'Assessment Scheme'
		,isRequired: true
		,listName: 'assessmentSchemeList'
		,valueType: 1
		,errorId: 'invalidId'
		,maxValue: 9999999999999
	};

	public static getInstance(): AssessmentSchemeSelectionForm {
		return AssessmentSchemeSelectionForm._instance;
	}

	constructor() {
		super();
		this.fields = new Map();
		this.controls = new Map();
		this.controls.set('departmentId', [Validators.required, Validators.max(9999999999999)]);
		this.fields.set('departmentId', this.departmentId);
		this.controls.set('offeredSubjectId', [Validators.required, Validators.max(9999999999999)]);
		this.fields.set('offeredSubjectId', this.offeredSubjectId);
		this.controls.set('assessmentSchemeId', [Validators.required, Validators.max(9999999999999)]);
		this.fields.set('assessmentSchemeId', this.assessmentSchemeId);
		this.opsAllowed = {get: true, update: true};
		this.listFields = ['departmentId','offeredSubjectId','assessmentSchemeId'];
		this.keyFields = ["offeredSubjectId"];
	}

	public getName(): string {
		 return 'assessmentSchemeSelection';
	}
}


export class AssessmentSchemeSelectionFd extends FormData {
	constructor(form: AssessmentSchemeSelectionForm, sa: ServiceAgent) {
		super(form, sa);
	}

	setFieldValue(name: 'departmentId' | 'offeredSubjectId' | 'assessmentSchemeId', value: string | number | boolean | null ): void {
		super.setFieldValue(name, value);
	}

	getFieldValue(name: 'departmentId' | 'offeredSubjectId' | 'assessmentSchemeId' ): string | number | boolean | null {
		return super.getFieldValue(name);
	}
}


export interface AssessmentSchemeSelectionVo extends Vo {
	offeredSubjectId?: number, 
	createdAt?: string, 
	departmentId?: number, 
	instituteId?: number, 
	assessmentSchemeId?: number, 
	updatedAt?: string
}
