import { Component, Input, OnInit, ViewChild, AfterViewInit } from "@angular/core";
import { DynamicPanel, GroupPanel } from '../../interfaces';
import { ServiceAgent } from 'src/app/framework-modules/formdata/form/serviceAgent';
import { SessionComponent } from '../../component';
import { FormData } from 'src/app/framework-modules/formdata/form/formData';
import { ClientContext } from 'src/app/framework-modules/formdata/form/clientContext';
import { ExcelDownloadService } from 'src/app/services/excelDownloadService';
import { TableMetaData, CtTableComponent } from 'src/app/framework-modules/elements/ct-table/component';

import { MessageService } from 'src/app/services/messageService';
import { HallTicketStudentForm, HallTicketStudentFd, HallTicketStudentVo } from 'src/app/framework-modules/formdata/gen/hallTicketStudentForm';
import { MatDialog } from '@angular/material';
import { CtDialogComponent } from 'src/app/framework-modules/elements/ct-dialog/component';
import { Vo } from 'src/app/framework-modules/formdata/form/types';
import * as moment from "moment";
import { CtCheckboxComponent } from 'src/app/framework-modules/elements/ct-checkbox/component';


@Component({
  selector: "hallticket-edit",
  templateUrl: "./component.html",
  styleUrls: ["./component.scss"]
})
export class HallTicketViewComponent implements DynamicPanel,OnInit,AfterViewInit{
  @ViewChild("gridTable", { static: false }) gtable: CtTableComponent;
  @Input() inputData: any;
  @Input() parentPanel: GroupPanel;
  @Input() session: SessionComponent;

  public studentVo:HallTicketStudentVo = {};

  tableData: TableMetaData;
  students: Vo;


  constructor(private sa: ServiceAgent,
    private cc: ClientContext,
    private ms: MessageService,
    private ds: ExcelDownloadService,
    private dialog: MatDialog) {
      //
  }


  ngOnInit(){
    this.metadataInit();
  }

  ngAfterViewInit(): void {
    const pd = HallTicketStudentForm.getInstance().newPanelData(this.sa);
    pd.fetchFor({studentId:this.inputData.studentId,levelId:this.inputData.levelId, seasonId: this.cc.getcurrentSeasonId()}).subscribe(
      data=>{
        var subjects = data.subjectDetails as Vo[];
        var table = []
        subjects.forEach(subject =>{
          if(subject.seasonId == data.seasonId){
            table.push(subject);
          }
        });
        this.studentVo = data;
        this.students = data;
        this.students.subjectDetails = table;
        this.tableData.data = table;
        this.gtable.update();
      });
  
  }
  /**
   * Initialize table Data
   */
  metadataInit(){
    this.tableData = {
      data: [],
      metaData: {
        column_names: [
          "subjectCode",
          "subjectName",
          "isEligible" 
        ],
        display_names:{
          "subjectName": "Course Code",
          "subjectCode": "Course Title",
          "isEligible" : "Eligibility"
        }
      }
    }
  }
  
  /**
   * Downloads the hallticket
   * of the student selected
   */
  download(){
    var date = new Date();
    if(this.cc.getInstituteId() == 1){
      this.ds.downloadHallTicket([this.students], moment(date).format("DD-MM-YYYY"), "HallTicket - " + this.studentVo.usn, "Semester End Examination SEPT/OCT - 2022", false);
    }else if(this.cc.getInstituteId() == 2){
      this.ds.downloadBmscwHallTicket([this.students], moment(date).format("DD MMMM YYYY"), "HallTicket - " + this.studentVo.usn, "SEMESTER END EXAMINATION SEPT / OCT 2022", false);
    }
    this.parentPanel.navigate('HallTicketList');
  }

  /**
   * Goback to the list screen
   */
  cancel(){
    let dialogRef = this.dialog.open(CtDialogComponent);
    dialogRef.componentInstance.title = 'Go Back';
    dialogRef.componentInstance.content = 'Are you sure you want to go back?';
    dialogRef.componentInstance.primary = 'Go Back';
    const subscribeDialog = dialogRef.componentInstance.save.subscribe((data) => {
      this.dialog.closeAll();
      this.parentPanel.navigate('HallTicketList');
    });
  }
}