
import { Form , Field, ChildForm } from '../form/form';
import { FormData } from '../form/formData';
import { SelectOption, Vo } from '../form/types';
import { Validators } from '@angular/forms'
import { ServiceAgent} from '../form/serviceAgent';

export class SeasonSubjectListForm extends Form {
	private static _instance = new SeasonSubjectListForm();
	seasonId:Field = {
		name:'seasonId'
		,controlType: 'Dropdown'
		,label: 'Select a Season'
		,isRequired: true
		,listName: 'seasonList'
		,valueType: 1
		,errorId: 'invalidId'
		,maxValue: 9999999999999
	};
	seasonOfferedSubjectId:Field = {
		name:'seasonOfferedSubjectId'
		,controlType: 'Dropdown'
		,label: 'Select a subject'
		,isRequired: true
		,listName: 'parentSubjectList'
		,listKey: 'seasonId'
		,valueType: 1
		,errorId: 'invalidId'
		,maxValue: 9999999999999
	};

	public static getInstance(): SeasonSubjectListForm {
		return SeasonSubjectListForm._instance;
	}

	constructor() {
		super();
		this.fields = new Map();
		this.controls = new Map();
		this.controls.set('seasonId', [Validators.required, Validators.max(9999999999999)]);
		this.fields.set('seasonId', this.seasonId);
		this.controls.set('seasonOfferedSubjectId', [Validators.required, Validators.max(9999999999999)]);
		this.fields.set('seasonOfferedSubjectId', this.seasonOfferedSubjectId);
		this.listFields = ['seasonId','seasonOfferedSubjectId'];
	}

	public getName(): string {
		 return 'seasonSubjectList';
	}
}


export class SeasonSubjectListFd extends FormData {
	constructor(form: SeasonSubjectListForm, sa: ServiceAgent) {
		super(form, sa);
	}

	setFieldValue(name: 'seasonId' | 'seasonOfferedSubjectId', value: string | number | boolean | null ): void {
		super.setFieldValue(name, value);
	}

	getFieldValue(name: 'seasonId' | 'seasonOfferedSubjectId' ): string | number | boolean | null {
		return super.getFieldValue(name);
	}
}


export interface SeasonSubjectListVo extends Vo {
	seasonId?: number, 
	seasonOfferedSubjectId?: number
}
