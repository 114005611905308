
import { Form , Field, ChildForm } from '../form/form';
import { FormData } from '../form/formData';
import { SelectOption, Vo } from '../form/types';
import { Validators } from '@angular/forms'
import { ServiceAgent} from '../form/serviceAgent';
import { HallTicketSubjectForm, HallTicketSubjectVo } from './hallTicketSubjectForm';

export class HallTicketStudentForm extends Form {
	private static _instance = new HallTicketStudentForm();

	subjectDetails: ChildForm = {
		name:'subjectDetails',
		form:HallTicketSubjectForm.getInstance(),
		isEditable:false,
		isTabular:true,
		label:'',
		minRows:0,
		maxRows:0,
		errorId:null
	};

	public static getInstance(): HallTicketStudentForm {
		return HallTicketStudentForm._instance;
	}

	constructor() {
		super();
		this.fields = new Map();
		this.controls = new Map();

		this.childForms = new Map();
		this.childForms.set('subjectDetails', this.subjectDetails);
		this.opsAllowed = {get: true, filter: true};
		this.keyFields = ["studentId"];
	}

	public getName(): string {
		 return 'hallTicketStudent';
	}
}


export class HallTicketStudentFd extends FormData {
	constructor(form: HallTicketStudentForm, sa: ServiceAgent) {
		super(form, sa);
	}
	/**  this form has no editable fields. data nust be accessed as Vo and not through fd **/
}


export interface HallTicketStudentVo extends Vo {
	departmentName?: string, 
	regNo?: string, 
	levelName?: string, 
	degreeName?: string, 
	studentId?: number, 
	usn?: string, 
	seasonId?: number, 
	programName?: string, 
	levelId?: number, 
	studentName?: string, 
	instituteId?: number, 
	programId?: number, 
	subjectDetails?: HallTicketSubjectVo
}
