import { Component, OnInit, ViewChild, Input, AfterViewInit } from "@angular/core";
import { DynamicPanel, GroupPanel } from '../../interfaces';
import { ServiceAgent } from 'src/app/framework-modules/formdata/form/serviceAgent';
import { TableMetaData } from '../../../../framework-modules/elements/ct-table/component'
import { SessionComponent } from '../../component';
import { CtCheckboxTableComponent } from 'src/app/framework-modules/elements/ct-checkbox-table/component';
import { CtDialogComponent } from 'src/app/framework-modules/elements/ct-dialog/component';
import { MatDialog } from '@angular/material';
import { MessageService } from 'src/app/services/messageService';
import { PanelData } from 'src/app/framework-modules/formdata/form/formData';
import { AssignPacketForm, AssignPacketVo } from 'src/app/framework-modules/formdata/gen/assignPacketForm';
import { CtSpinner } from 'src/app/framework-modules/elements/ct-spinner/component';
import { CurrentEvaluatorDetailFd, CurrentEvaluatorDetailForm } from "src/app/framework-modules/formdata/gen/currentEvaluatorDetailForm";
import { ValuationActivitiesForm } from "src/app/framework-modules/formdata/gen/valuationActivitiesForm";
import { PdfDownloadService } from "src/app/services/pdfDownloadService";

@Component({
  selector: "makeup-assign-evaluators-edit",
  templateUrl: "./component.html",
  styleUrls: ["./component.scss"]
})
export class MakeUpAssignPacketsEditComponent implements DynamicPanel, OnInit, AfterViewInit {
  @ViewChild("gridTable", { static: false }) gtable: CtCheckboxTableComponent;
  @ViewChild("spinner", { static: false }) spinner: CtSpinner;
  @Input() inputData: { 
    offeredSubjectId: any, 
    evaluatorId: any,
    valuationType: any,
    valuationRound: any
  };
  @Input() parentPanel: GroupPanel;
  @Input() session: SessionComponent;

  public tableData: TableMetaData;
  private pd: PanelData;
  public vo: AssignPacketVo;
  private valuationActivitiesPd: PanelData;
  index: number = 0;

  public form1: CurrentEvaluatorDetailForm;
  public fd1:CurrentEvaluatorDetailFd;
  constructor(private sa: ServiceAgent, private dialog: MatDialog, private ms: MessageService,private ps:PdfDownloadService) {
    this.pd = AssignPacketForm.getInstance().newPanelData(this.sa);
    this.vo = {};
    this.form1 = CurrentEvaluatorDetailForm.getInstance();
    this.fd1 = this.form1.newFormData(this.sa);
    this.valuationActivitiesPd =ValuationActivitiesForm.getInstance().newFormData(this.sa);
    
  } 

  ngOnInit() {
    this.metaDataInit();
  }

  ngAfterViewInit() {
    if (this.inputData 
        && this.inputData.offeredSubjectId 
        && this.inputData.evaluatorId
        && this.inputData.valuationType
        && this.inputData.valuationRound) {
      this.getData(this.inputData);
    } else {
      this.ms.showError('Page invoked with invalid data');
    }
  }

  /**
   * Table Metadata initialization
   */
  metaDataInit() {
    this.tableData = {
      data: [] = [],
      metaData: {
        column_names: [
          "packetCode"
        ],
        display_names: {
          packetCode: "Packet"
        },
        selectAttr: "isAssigned",
        itemName: "Packet(s)",
        managerFunction: "SAVE",
        search: true,
        placeholder: "Search by Packet...."
      }
    };
  }

  private getData(data) {
    this.spinner.show('Getting Evaluators for this subject..');
    /**
     * offeredSubjectId is the primary key in the form
     */
    this.pd.fetchFor(data).subscribe({
      next: data => {
        this.vo = data;
        const list = data.packets as [];
        this.tableData.data = list;
        this.gtable.update(this.spinner);
      },

      error: msg => {
        this.spinner.hide();
        this.ms.showError('server returned with errors');
      }
    });
  }


  private saveData() {
    this.spinner.show('Assigning packets..');
    this.pd.setFieldValue("isMakeUp", true);
    this.pd.save().subscribe({
      next: data => {
        let packets:any = this.vo.packets;
        this.spinner.hide();
        var download = []
        packets.forEach(element => {
          if(element.isAssigned){
            // this.doDownload(this.vo.valuationType,element.packetCode)
            download.push(element.packetCode);
          }  
        });
        this.doDownload(this.vo.valuationType,download);
      },

      error: msg => {
        this.spinner.hide();
        this.ms.showError('server returned with errors');
      }
    });
  }


  /**
   * Function is called when the user clicks save button.
   * Saves the assigned evaluators to a asubject.
   */
  save() {
    let dialogRef = this.dialog.open(CtDialogComponent);
    dialogRef.componentInstance.title = 'Save Changes';
    dialogRef.componentInstance.content = 'Are you sure you want to save changes?';
    dialogRef.componentInstance.primary = 'Save';
    const subscribeDialog = dialogRef.componentInstance.save.subscribe((data) => {
      this.saveData();
      this.dialog.closeAll();
    });
  }

  /**
   * Function is called when the user clicks cancel button.
   * Goes back to assign Evaluators list screen
   */
  cancel() {
    let dialogRef = this.dialog.open(CtDialogComponent);
    dialogRef.componentInstance.title = 'Discard Changes';
    dialogRef.componentInstance.content = 'Are you sure you want to discard changes?';
    dialogRef.componentInstance.primary = 'Discard';
    const subscribeDialog = dialogRef.componentInstance.save.subscribe((data) => {
      this.dialog.closeAll();
      this.parentPanel.navigate("MakeUpAssignPacketsList");
    });
  }


  public doDownload(valuationType1,packetCodeArray: any[]){
    this.spinner.show("Downloading Valuation Sheets");
    this.valuationActivitiesPd.fetchFor({}).subscribe(
      data=>{
        this.fd1.setFieldValue('valuationRound',this.vo.valuationRound as number);
        this.downloadRecursive(valuationType1,packetCodeArray,this.vo.valuationRound);
    })
  }

  private downloadRecursive(valuationType1,packetCodeArray: any[],valuationRound){
    if(packetCodeArray.length == this.index){
      this.spinner.hide();
      this.ms.showSuccess("Packets Assigned Successfully!");
      this.parentPanel.navigate('MakeUpAssignPacketsList');
      return 1;
    }
    else{
      this.fd1.setFieldValue('packetCode',packetCodeArray[this.index]);
      this.sa.serve("get_currentEvaluatorDetail",{ data: { packetCode: packetCodeArray[this.index] as string, valuationRound: valuationRound, offeredSubjectId: this.inputData.offeredSubjectId}}).subscribe(
        (data) => {
          this.index++;
          this.ps.downloadValuationSheet(data,valuationType1,data.packetCode);
          this.downloadRecursive(valuationType1,packetCodeArray,valuationRound);
        },
        (err) => {
          console.log(err);
        }
      );
    }
  } 
}