import { Component, OnInit, ViewChild, Input, AfterViewInit } from "@angular/core";
import { GroupPanel } from '../../interfaces';
import { ServiceAgent } from 'src/app/framework-modules/formdata/form/serviceAgent';
import { CtTableComponent, TableMetaData } from '../../../../framework-modules/elements/ct-table/component'
import { SessionComponent } from '../../component';
import { RowToColMeta, FilterRequest } from 'src/app/framework-modules/formdata/form/types';
import { EligibilityListForm, EligibilityListFd, EligibilityListVo } from 'src/app/framework-modules/formdata/gen/eligibilityListForm';
import { TaughtSectionSelectionForm, TaughtSectionSelectionFd } from 'src/app/framework-modules/formdata/gen/taughtSectionSelectionForm';
import { MessageService } from 'src/app/services/messageService';
import { OfferedSectionSelectionForm, OfferedSectionSelectionFd } from 'src/app/framework-modules/formdata/gen/offeredSectionSelectionForm';
import { EligibilityListDownloadFd, EligibilityListDownloadForm } from 'src/app/framework-modules/formdata/gen/eligibilityListDownloadForm';
import { ExcelDownloadService } from 'src/app/services/excelDownloadService';

@Component({
  selector: "eligibility-list",
  templateUrl: "./component.html",
  styleUrls: ["./component.scss"]
})


export class EligibilityListComponent implements OnInit, AfterViewInit {
  @ViewChild("gridTable", { static: false }) gtable: CtTableComponent;
  @Input() inputData: any;
  @Input() parentPanel: GroupPanel;
  @Input() session: SessionComponent;
  /**
 * for selecting subjectSection
 */
  form: OfferedSectionSelectionForm;
  fd: OfferedSectionSelectionFd;
  selectedSection = ''

  /**
 * for getting students-list
 */
  fd1: EligibilityListDownloadFd;

  tableData: TableMetaData;

  constructor(private sa: ServiceAgent, private ms: MessageService, private ds: ExcelDownloadService) {
    this.form = OfferedSectionSelectionForm.getInstance();
    this.fd = this.form.newFormData(this.sa);
    this.fd1 = EligibilityListDownloadForm.getInstance().newFormData(this.sa);
  }

  ngOnInit() {
    this.initTable();
  }

  ngAfterViewInit() {
    this.gtable.update();
  }

  private initTable() {
    this.tableData = {
      data: [],
      metaData: {
        column_names: [
          "name",
          "usn",
          "overallAttendance",
          "cieMarks",
          "isEligibleForExam"
        ],
        display_names: {
          name: "Name",
          usn: "USN",
          overallAttendance: "Attendance",
          cieMarks: "CIE Marks",
          isEligibleForExam: "Eligible"
        },
        search: true,
        placeholder: "Search by Student Name, Student USN",
        styleAttribute: {
          "isEligibleForExam": [
            {comp: '=', value: 'NOT ELIGIBLE', style:{'color':'red', 'font-weight': 600}},
            {comp: '=', value: 'ELIGIBLE', style:{'color':'green', 'font-weight': 600}},
           ],
           "overallAttendance" : [
            {comp : '=', value : true, dependentCol : "hasAttendanceShortage", style:{'color':'red', 'font-weight': 600}},
           ],
           "cieMarks" : [
            {comp : '=', value : true, dependentCol : "hasCieShortage", style:{'color':'red', 'font-weight': 600}},
           ]
        }
    }
  }
}

  sectionChanged(id: string) {
    if (this.selectedSection == id ) {
      return;
    }
    if (id) {
      this.getData(id);
    }else{
      this.selectedSection = id;
    }
  }

  private getData(id: string) {
    const f: FilterRequest = {
      conditions: { subjectSectionId: { comp: "=", value: id } },
      sort: { usn: "asc" }
    };

    this.fd1.filter(f).subscribe({
      next: data => {
        console.log(data);
        this.selectedSection = id;
        this.tableData.data = data;
        if(!data.length){
          this.ms.showInfo('No students found for this section.');
        }
        this.gtable.update();
      },
      error: msg => {
        console.error(msg);
        this.ms.showError('Server returned with an error message. ');
      }
    });
  }

  download(){
    this.sa.serve("get_cieSubjectSectionDetail", {
      data:{subjectSectionId:this.fd.getFieldValue('subjectSectionId')}
    }
    ).subscribe(
      data=>{
        this.ds.downloadCIE(data);
      }
    )
  }

  
}