import { Component, OnInit, ViewChild, Input, AfterViewInit } from "@angular/core";
import { ServiceAgent } from 'src/app/framework-modules/formdata/form/serviceAgent';
import { TableMetaData } from 'src/app/framework-modules/elements/ct-table/component';
import { GroupPanel } from '../../interfaces';
import { SessionComponent } from '../../component';
import { MessageService } from 'src/app/services/messageService';
import { CtEditableTableComponent } from 'src/app/framework-modules/elements/ct-editable-table/component';
import { CtDialogComponent } from 'src/app/framework-modules/elements/ct-dialog/component';
import { MatDialog } from '@angular/material';
import { CtSpinner } from 'src/app/framework-modules/elements/ct-spinner/component';
import { ExamMarksSubjectFd, ExamMarksSubjectForm } from 'src/app/framework-modules/formdata/gen/examMarksSubjectForm';
import { OfferedSubjectRegisterDetailFd, OfferedSubjectRegisterDetailForm, OfferedSubjectRegisterDetailVo } from 'src/app/framework-modules/formdata/gen/offeredSubjectRegisterDetailForm';
import { FilterRequest } from 'src/app/framework-modules/formdata/form/types';
import { ExcelDownloadService, ExcelTemplate } from "src/app/services/excelDownloadService";
import { ExcelParserService } from "src/app/services/excelParserService";
import { ClientContext } from 'src/app/framework-modules/formdata/form/clientContext';


@Component({
  selector: 'marks-entry-view',
  templateUrl: "./component.html",
  styleUrls: ["./component.scss"]
})
export class FinalMarksUpdateComponent implements AfterViewInit {
  @ViewChild("gridTable", { static: false }) gtable: CtEditableTableComponent;
  @ViewChild("spinner", { static: false }) public spinner: CtSpinner;
  @Input() inputData: any;
  @Input() parentPanel: GroupPanel;
  @Input() session: SessionComponent;

  parentForm: ExamMarksSubjectForm;
  parentFd: ExamMarksSubjectFd;
  childForm: OfferedSubjectRegisterDetailForm;
  childFd: OfferedSubjectRegisterDetailFd;
  childVo: OfferedSubjectRegisterDetailVo;

  tableData: TableMetaData;

  constructor(private sa: ServiceAgent, private ms: MessageService, private dialog: MatDialog,private ds: ExcelDownloadService, private ep: ExcelParserService,private cc: ClientContext) {
    this.parentForm = ExamMarksSubjectForm.getInstance();
    this.parentFd = this.parentForm.newFormData(this.sa);
    this.childForm = OfferedSubjectRegisterDetailForm.getInstance();
    this.childFd = this.childForm.newFormData(this.sa);
  }

  ngAfterViewInit() {
    this.initTable();
  }

  /**
   * Metadata Init for the table
   */
  initTable() {
    this.tableData = {
      data: [],
      metaData: {
        column_names: [
          "name",
          "usn",
          "cieMarks",
          "examMarks"
        ],
        display_names: {
          "name": "Name",
          "usn": "USN",
          "cieMarks":"CIE Marks",
          "examMarks": "Exam Marks"
        },
        search: true,
        edit: false,
        placeholder: "Search by Student Name, Student USN",
        editable_columns: [
          "examMarks"
        ],
        validations: {
          "examMarks": {
            minValue: 0,
            maxValue: 100,
          }
        },
        error: "Invalid Marks",
      }
    };
    this.getData();
  }

  /**
   * Get student data for the input
   */
  getData(){
    this.spinner.show("Loading....");
    this.parentFd.setFieldValue('offeredSubjectId',this.inputData['offeredSubjectId']);
    const f: FilterRequest = {
      conditions: {
        offeredSubjectId: {comp:'=',value:this.inputData['offeredSubjectId']},
        hasAttendedExam: {comp:'=',value:true}
      },
      sort: {usn:"asc"}
    }
    this.childFd.filter(f).subscribe(
      data => {
        this.parentFd.childTabularData.get("students").setAll(data);
        this.tableData.data = data as [];
        this.gtable.update();
        this.spinner.hide();
      },
      err => {
        this.spinner.hide();
      }
    )
  }

  /**
   * Cancel the changes and go back
   */
  cancel(){
    let dialogRef = this.dialog.open(CtDialogComponent);
    dialogRef.componentInstance.title = 'Discard Changes';
    dialogRef.componentInstance.content = 'Are you sure you want to discard changes?';
    dialogRef.componentInstance.primary = 'Discard';
    const subscribeDialog = dialogRef.componentInstance.save.subscribe((data) => {
      this.dialog.closeAll();
      this.parentPanel.navigate("FinalMarksList");
    });
  }

  /**
   * Update exam marks for the students in the subject
   */
  update(){
    let dialogRef = this.dialog.open(CtDialogComponent);
    dialogRef.componentInstance.title = 'Save Changes';
    dialogRef.componentInstance.content = 'Are you sure you want to save the exam marks entered?';
    dialogRef.componentInstance.primary = 'Save';
    const subscribeDialog = dialogRef.componentInstance.save.subscribe((data) => {
      this.save();
    });
  }

  /**
   * private method to save data
   */
  private save(){
    this.spinner.show("Saving.....")
    this.parentFd.save().subscribe(
      data =>{
        this.spinner.hide();
        this.ms.showSuccess("Exam Marks Updated!");
        this.dialog.closeAll();
        this.parentPanel.navigate("FinalMarksList");
      },
      err => {
        this.spinner.hide();
        // this.ms.showError("Invalid Marks Entered!")
        this.dialog.closeAll();
      }
    );
  }

  doDownload(){
    let templateData = [];
    let template: ExcelTemplate = {
      fixedHeader: [
        { loc: "A1", value: `${this.cc.getinstituteName()}, ${this.cc.getinstituteCity()} - ${this.cc.getinstitutePinCode().split(' ')[this.cc.getinstitutePinCode().split(' ').length-1]}`.toUpperCase(), style: { font: { size: 18, name: 'Cambria', bold: true }, alignment: { horizontal: 'center', vertical: 'middle' }, border: {}, fill: null, numFmt: '', protection: {locked:true} } },
        { loc: "A2", value: `(Autonomous Institute, Affiliated to ${this.cc.getuniversityName()})`, style: { font: { size: 12, name: 'Cambria', bold: true }, alignment: { horizontal: 'center', vertical: 'middle' }, border: {}, fill: null, numFmt: '', protection: {locked:true} } },
        { loc: "A3", value: "Exam Marks Upload --- "+this.inputData.subject, style: { font: { size: 16, name: 'Cambria', bold: true }, alignment: { horizontal: 'center', vertical: 'middle' }, border: {}, fill: null, numFmt: '', protection: {locked:true} } },
      ],
      dynamicHeader: [],
      merges: ['A1:C1', 'A2:C2', 'A3:C3'],
      tableArray: "students",
      tableStart: 5,
      tableStyle: { rowHeight: 15, },
      tableHeader: ["USN", "Name", "Marks"],
      tableValues: [
        { key: "usn", width: 35, style: { font: { size: 12, name: 'Cambria' }, alignment: { horizontal: 'center', vertical: 'middle' }, border: {}, fill: null, numFmt: '', protection: {locked:true} } },
        { key: "name", width: 65, style: { font: { size: 12, name: 'Cambria' }, alignment: { horizontal: 'left', vertical: 'middle' }, border: {}, fill: null, numFmt: '', protection: {locked:true} } },
        { key: "examMarks", width: 10, style: { font: { size: 12, name: 'Cambria' }, alignment: { horizontal: 'center', vertical: 'middle' }, border: {}, fill: null, numFmt: '', protection: null } },
      ],
      colWidths: [30, 60,20],
      fixedFooter: [],
      dynamicFootfer: [],
      footerMerges: [],
      footerRowHeights: [],
      tableHeaderStyle: { font: { size: 14, name: 'Cambria', bold: true }, alignment: { horizontal: 'center', vertical: 'middle' }, border: {bottom: {style: 'thin'},top: {style: 'thin'}, left: {style: 'thin'},right:{style: 'thin'} }, fill: {type: 'pattern',pattern: 'solid',fgColor: {  argb: 'FF00FF00'}}, numFmt: '', protection: {locked:true} }
    }
    this.ds.excelFromTemplate(template,{"students":this.tableData.data},"Exam Marks Template --- "+this.inputData.subject,(this.inputData.subject as string).split(' - ').reverse()[0])
  }

  onFileChange(ev){
    this.ep.parseMarks(ev,(this.inputData.subject as string).split(' - ').reverse()[0]).subscribe(
      data =>{
        this.fillData(data);
      }
    );
  }

  /**
   * usnMap = {usn:index};
   * @param data has {usn:marks}
   */
  private fillData(data:{}){
    var usnMap = {};
    this.tableData.data.forEach((element,index) =>{
      usnMap[element["usn"] as string] = index;
    });
    if(Object.keys(data).length != Object.keys(usnMap).length){
      this.ms.showError("Number of students is not same in excel and in database. Excel data manipulated!");
      return;
    }
    let flag: boolean = true;
    Object.keys(data).forEach(key =>{
      let index = usnMap[key];
      if(index == undefined){
        this.ms.showError(key+" not found in this section. Excel Data manipulated");
        flag=false;
        return;
      }
    });
    if(flag){
      Object.keys(data).forEach(key =>{
        let index = usnMap[key];
        this.tableData.data[index]["examMarks"] = data[key];
      });
      this.ms.showSuccess("Excel Uploaded Successfully!");
    }
  }

}