
import { Form , Field, ChildForm } from '../form/form';
import { FormData } from '../form/formData';
import { SelectOption, Vo } from '../form/types';
import { Validators } from '@angular/forms'
import { ServiceAgent} from '../form/serviceAgent';

export class AssessmentItemNameForm extends Form {
	private static _instance = new AssessmentItemNameForm();

	public static getInstance(): AssessmentItemNameForm {
		return AssessmentItemNameForm._instance;
	}

	constructor() {
		super();
		this.fields = new Map();
		this.controls = new Map();
		this.keyFields = ["seqNo", "assessmentSchemeId"];
	}

	public getName(): string {
		 return 'assessmentItemName';
	}
}


export class AssessmentItemNameFd extends FormData {
	constructor(form: AssessmentItemNameForm, sa: ServiceAgent) {
		super(form, sa);
	}
	/**  this form has no editable fields. data nust be accessed as Vo and not through fd **/
}


export interface AssessmentItemNameVo extends Vo {
	name?: string, 
	instituteId?: number, 
	seqNo?: number, 
	assessmentSchemeId?: number, 
	maxMarks?: number
}
