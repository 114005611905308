
import { Form , Field, ChildForm } from '../form/form';
import { FormData } from '../form/formData';
import { SelectOption, Vo } from '../form/types';
import { Validators } from '@angular/forms'
import { ServiceAgent} from '../form/serviceAgent';
import { HallTicketStudentForm, HallTicketStudentVo } from './hallTicketStudentForm';

export class HallTicketLevelForm extends Form {
	private static _instance = new HallTicketLevelForm();
	departmentId:Field = {
		name:'departmentId'
		,controlType: 'Output'
		,label: 'Select a Department'
		,isRequired: true
		,listName: 'departmentList'
		,valueType: 1
		,errorId: 'invalidId'
		,maxValue: 9999999999999
	};
	programId:Field = {
		name:'programId'
		,controlType: 'Output'
		,label: 'Select a Program'
		,isRequired: true
		,listName: 'programList'
		,listKey: 'departmentId'
		,valueType: 1
		,errorId: 'invalidId'
		,maxValue: 9999999999999
	};
	programLevelId:Field = {
		name:'programLevelId'
		,controlType: 'Output'
		,label: 'Select a Semester'
		,isRequired: true
		,listName: 'programLevelList'
		,listKey: 'programId'
		,valueType: 1
		,errorId: 'invalidId'
		,maxValue: 9999999999999
	};

	hallTicketStudents: ChildForm = {
		name:'hallTicketStudents',
		form:HallTicketStudentForm.getInstance(),
		isEditable:false,
		isTabular:true,
		label:'',
		minRows:0,
		maxRows:0,
		errorId:null
	};

	public static getInstance(): HallTicketLevelForm {
		return HallTicketLevelForm._instance;
	}

	constructor() {
		super();
		this.fields = new Map();
		this.controls = new Map();
		this.controls.set('departmentId', [Validators.required, Validators.max(9999999999999)]);
		this.fields.set('departmentId', this.departmentId);
		this.controls.set('programId', [Validators.required, Validators.max(9999999999999)]);
		this.fields.set('programId', this.programId);
		this.controls.set('programLevelId', [Validators.required, Validators.max(9999999999999)]);
		this.fields.set('programLevelId', this.programLevelId);

		this.childForms = new Map();
		this.childForms.set('hallTicketStudents', this.hallTicketStudents);
		this.opsAllowed = {get: true, filter: true};
		this.listFields = ['departmentId','programId','programLevelId'];
		this.keyFields = ["programLevelId", "seasonId", "programId"];
	}

	public getName(): string {
		 return 'hallTicketLevel';
	}
}


export class HallTicketLevelFd extends FormData {
	constructor(form: HallTicketLevelForm, sa: ServiceAgent) {
		super(form, sa);
	}

	setFieldValue(name: 'departmentId' | 'programId' | 'programLevelId', value: string | number | boolean | null ): void {
		super.setFieldValue(name, value);
	}

	getFieldValue(name: 'departmentId' | 'programId' | 'programLevelId' ): string | number | boolean | null {
		return super.getFieldValue(name);
	}
}


export interface HallTicketLevelVo extends Vo {
	programLevelId?: number, 
	levelSectionId?: number, 
	seasonId?: number, 
	isMakeup?: boolean, 
	yearOfCompletion?: string, 
	departmentId?: number, 
	isAciteRequired?: boolean, 
	dateOfIssue?: string, 
	programId?: number, 
	hallTicketStudents?: HallTicketStudentVo
}
