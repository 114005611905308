
import { Form , Field, ChildForm } from '../form/form';
import { FormData } from '../form/formData';
import { SelectOption, Vo } from '../form/types';
import { Validators } from '@angular/forms'
import { ServiceAgent} from '../form/serviceAgent';
import { AssessmentItemNameForm, AssessmentItemNameVo } from './assessmentItemNameForm';
import { MarksForStudentForm, MarksForStudentVo } from './marksForStudentForm';

export class MarksForSectionForm extends Form {
	private static _instance = new MarksForSectionForm();
	subjectSectionId:Field = {
		name:'subjectSectionId'
		,controlType: 'Hidden'
		,label: 'subjectSectionId'
		,isRequired: true
		,valueType: 1
		,errorId: 'invalidId'
		,maxValue: 9999999999999
	};
	assessmentSchemeId:Field = {
		name:'assessmentSchemeId'
		,controlType: 'Hidden'
		,label: 'assessmentSchemeId'
		,isRequired: true
		,valueType: 1
		,errorId: 'invalidId'
		,maxValue: 9999999999999
	};
	seqNo:Field = {
		name:'seqNo'
		,controlType: 'Input'
		,label: 'Test Name'
		,isRequired: true
		,listName: 'assessmentItemList'
		,listKey: 'assessmentSchemeId'
		,valueType: 1
		,errorId: 'invalidInteger'
		,maxValue: 9999999999999
	};
	isIntegrated:Field = {
		name:'isIntegrated'
		,controlType: 'Hidden'
		,label: 'isIntegrated'
		,valueType: 3
		,defaultValue: true
		,errorId: 'invalidBool'
	};
	freezeAt:Field = {
		name:'freezeAt'
		,controlType: 'Hidden'
		,label: 'freezeAt'
		,valueType: 1
		,defaultValue: 0
		,errorId: 'invalidInteger'
		,maxValue: 9999999999999
	};

	names: ChildForm = {
		name:'names',
		form:AssessmentItemNameForm.getInstance(),
		isEditable:false,
		isTabular:true,
		label:'',
		minRows:0,
		maxRows:0,
		errorId:null
	};
	students: ChildForm = {
		name:'students',
		form:MarksForStudentForm.getInstance(),
		isEditable:false,
		isTabular:true,
		label:'',
		minRows:0,
		maxRows:0,
		errorId:null
	};

	public static getInstance(): MarksForSectionForm {
		return MarksForSectionForm._instance;
	}

	constructor() {
		super();
		this.fields = new Map();
		this.controls = new Map();
		this.controls.set('subjectSectionId', [Validators.required, Validators.max(9999999999999)]);
		this.fields.set('subjectSectionId', this.subjectSectionId);
		this.controls.set('assessmentSchemeId', [Validators.required, Validators.max(9999999999999)]);
		this.fields.set('assessmentSchemeId', this.assessmentSchemeId);
		this.controls.set('seqNo', [Validators.required, Validators.max(9999999999999)]);
		this.fields.set('seqNo', this.seqNo);
		this.controls.set('isIntegrated', []);
		this.fields.set('isIntegrated', this.isIntegrated);
		this.controls.set('freezeAt', [Validators.max(9999999999999)]);
		this.fields.set('freezeAt', this.freezeAt);

		this.childForms = new Map();
		this.childForms.set('names', this.names);
		this.childForms.set('students', this.students);
		this.opsAllowed = {get: true, update: true};
		this.listFields = ['seqNo'];
		this.keyFields = ["subjectSectionId"];
	}

	public getName(): string {
		 return 'marksForSection';
	}
}


export class MarksForSectionFd extends FormData {
	constructor(form: MarksForSectionForm, sa: ServiceAgent) {
		super(form, sa);
	}

	setFieldValue(name: 'subjectSectionId' | 'assessmentSchemeId' | 'seqNo' | 'isIntegrated' | 'freezeAt', value: string | number | boolean | null ): void {
		super.setFieldValue(name, value);
	}

	getFieldValue(name: 'subjectSectionId' | 'assessmentSchemeId' | 'seqNo' | 'isIntegrated' | 'freezeAt' ): string | number | boolean | null {
		return super.getFieldValue(name);
	}
}


export interface MarksForSectionVo extends Vo {
	levelSectionId?: number, 
	seqNo?: number, 
	departmentId?: number, 
	attendanceFrozen?: boolean, 
	cieFrozen?: boolean, 
	sectionId?: number, 
	subjectId?: number, 
	sectionName?: string, 
	offeredSubjectId?: number, 
	isOffered?: boolean, 
	totalClasses?: number, 
	freezeAt?: number, 
	subjectSectionId?: number, 
	instituteId?: number, 
	isIntegrated?: boolean, 
	assessmentSchemeId?: number, 
	subjectCode?: string, 
	subjectName?: string, 
	names?: AssessmentItemNameVo, 
	students?: MarksForStudentVo
}
