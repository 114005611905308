
import { Form , Field, ChildForm } from '../form/form';
import { FormData } from '../form/formData';
import { SelectOption, Vo } from '../form/types';
import { Validators } from '@angular/forms'
import { ServiceAgent} from '../form/serviceAgent';
import { SectionAttendanceForm, SectionAttendanceVo } from './sectionAttendanceForm';

export class ConsolidatedAttendanceForm extends Form {
	private static _instance = new ConsolidatedAttendanceForm();
	subjectName:Field = {
		name:'subjectName'
		,controlType: 'Output'
		,label: 'Subject'
		,valueType: 0
		,defaultValue: ''
		,errorId: 'invalidName'
		,maxLength: 150
	};
	sectionName:Field = {
		name:'sectionName'
		,controlType: 'Output'
		,label: 'Section'
		,valueType: 0
		,defaultValue: ''
		,errorId: 'invalidName'
		,maxLength: 150
	};
	subjectSectionId:Field = {
		name:'subjectSectionId'
		,controlType: 'Hidden'
		,label: 'subjectSectionId'
		,isRequired: true
		,valueType: 1
		,errorId: 'invalidId'
		,maxValue: 9999999999999
	};
	attendanceFrozen:Field = {
		name:'attendanceFrozen'
		,controlType: 'Hidden'
		,label: 'attendanceFrozen'
		,valueType: 3
		,defaultValue: false
		,errorId: 'invalidBool'
	};
	totalClasses:Field = {
		name:'totalClasses'
		,controlType: 'Output'
		,label: 'totalClasses'
		,isRequired: true
		,valueType: 1
		,errorId: 'invalidInteger'
		,maxValue: 9999999999999
	};
	freezeAt:Field = {
		name:'freezeAt'
		,controlType: 'Hidden'
		,label: 'freezeAt'
		,isRequired: true
		,valueType: 1
		,defaultValue: 0
		,errorId: 'invalidInteger'
		,maxValue: 9999999999999
	};

	students: ChildForm = {
		name:'students',
		form:SectionAttendanceForm.getInstance(),
		isEditable:false,
		isTabular:true,
		label:'',
		minRows:1,
		maxRows:0,
		errorId:null
	};

	public static getInstance(): ConsolidatedAttendanceForm {
		return ConsolidatedAttendanceForm._instance;
	}

	constructor() {
		super();
		this.fields = new Map();
		this.controls = new Map();
		this.controls.set('subjectName', [Validators.maxLength(150)]);
		this.fields.set('subjectName', this.subjectName);
		this.controls.set('sectionName', [Validators.maxLength(150)]);
		this.fields.set('sectionName', this.sectionName);
		this.controls.set('subjectSectionId', [Validators.required, Validators.max(9999999999999)]);
		this.fields.set('subjectSectionId', this.subjectSectionId);
		this.controls.set('attendanceFrozen', []);
		this.fields.set('attendanceFrozen', this.attendanceFrozen);
		this.controls.set('totalClasses', [Validators.required, Validators.max(9999999999999)]);
		this.fields.set('totalClasses', this.totalClasses);
		this.controls.set('freezeAt', [Validators.required, Validators.max(9999999999999)]);
		this.fields.set('freezeAt', this.freezeAt);

		this.childForms = new Map();
		this.childForms.set('students', this.students);
		this.opsAllowed = {get: true, update: true};
		this.keyFields = ["subjectSectionId"];
	}

	public getName(): string {
		 return 'consolidatedAttendance';
	}
}


export class ConsolidatedAttendanceFd extends FormData {
	constructor(form: ConsolidatedAttendanceForm, sa: ServiceAgent) {
		super(form, sa);
	}

	setFieldValue(name: 'subjectName' | 'sectionName' | 'subjectSectionId' | 'attendanceFrozen' | 'totalClasses' | 'freezeAt', value: string | number | boolean | null ): void {
		super.setFieldValue(name, value);
	}

	getFieldValue(name: 'subjectName' | 'sectionName' | 'subjectSectionId' | 'attendanceFrozen' | 'totalClasses' | 'freezeAt' ): string | number | boolean | null {
		return super.getFieldValue(name);
	}
}


export interface ConsolidatedAttendanceVo extends Vo {
	levelSectionId?: number, 
	departmentId?: number, 
	attendanceFrozen?: boolean, 
	cieFrozen?: boolean, 
	sectionId?: number, 
	subjectId?: number, 
	sectionName?: string, 
	offeredSubjectId?: number, 
	isOffered?: boolean, 
	totalClasses?: number, 
	freezeAt?: number, 
	subjectSectionId?: number, 
	instituteId?: number, 
	subjectCode?: string, 
	subjectName?: string, 
	students?: SectionAttendanceVo
}
