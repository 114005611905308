
import { Form , Field, ChildForm } from '../form/form';
import { FormData } from '../form/formData';
import { SelectOption, Vo } from '../form/types';
import { Validators } from '@angular/forms'
import { ServiceAgent} from '../form/serviceAgent';

export class PdcDownloadTempUgForm extends Form {
	private static _instance = new PdcDownloadTempUgForm();
	usn:Field = {
		name:'usn'
		,controlType: 'Output'
		,label: 'usn'
		,isRequired: true
		,valueType: 0
		,errorId: 'invalidText'
		,maxLength: 1000
	};
	pdcNo:Field = {
		name:'pdcNo'
		,controlType: 'Output'
		,label: 'Pdc No'
		,isRequired: true
		,valueType: 0
		,errorId: 'invalidText'
		,maxLength: 1000
	};
	studentName:Field = {
		name:'studentName'
		,controlType: 'Output'
		,label: 'Student Name'
		,isRequired: true
		,valueType: 0
		,errorId: 'invalidText'
		,maxLength: 1000
	};
	programName:Field = {
		name:'programName'
		,controlType: 'Output'
		,label: 'Program Name'
		,isRequired: true
		,valueType: 0
		,errorId: 'invalidText'
		,maxLength: 1000
	};
	date:Field = {
		name:'date'
		,controlType: 'Output'
		,label: 'Date'
		,isRequired: true
		,valueType: 0
		,errorId: 'invalidText'
		,maxLength: 1000
	};
	departmentName:Field = {
		name:'departmentName'
		,controlType: 'Output'
		,label: 'Department Name'
		,isRequired: true
		,valueType: 0
		,errorId: 'invalidText'
		,maxLength: 1000
	};
	cgpa:Field = {
		name:'cgpa'
		,controlType: 'Output'
		,label: 'CGPA'
		,isRequired: true
		,valueType: 2
		,errorId: 'invalidDecimal'
		,nbrFractions: 4
		,maxValue: 1000
	};

	public static getInstance(): PdcDownloadTempUgForm {
		return PdcDownloadTempUgForm._instance;
	}

	constructor() {
		super();
		this.fields = new Map();
		this.controls = new Map();
		this.controls.set('usn', [Validators.required, Validators.maxLength(1000)]);
		this.fields.set('usn', this.usn);
		this.controls.set('pdcNo', [Validators.required, Validators.maxLength(1000)]);
		this.fields.set('pdcNo', this.pdcNo);
		this.controls.set('studentName', [Validators.required, Validators.maxLength(1000)]);
		this.fields.set('studentName', this.studentName);
		this.controls.set('programName', [Validators.required, Validators.maxLength(1000)]);
		this.fields.set('programName', this.programName);
		this.controls.set('date', [Validators.required, Validators.maxLength(1000)]);
		this.fields.set('date', this.date);
		this.controls.set('departmentName', [Validators.required, Validators.maxLength(1000)]);
		this.fields.set('departmentName', this.departmentName);
		this.controls.set('cgpa', [Validators.required, Validators.max(1000)]);
		this.fields.set('cgpa', this.cgpa);
		this.opsAllowed = {get: true, create: true, update: true, filter: true, delete: true};
		this.keyFields = ["pdcTempUgId"];
	}

	public getName(): string {
		 return 'pdcDownloadTempUg';
	}
}


export class PdcDownloadTempUgFd extends FormData {
	constructor(form: PdcDownloadTempUgForm, sa: ServiceAgent) {
		super(form, sa);
	}

	setFieldValue(name: 'usn' | 'pdcNo' | 'studentName' | 'programName' | 'date' | 'departmentName' | 'cgpa', value: string | number | boolean | null ): void {
		super.setFieldValue(name, value);
	}

	getFieldValue(name: 'usn' | 'pdcNo' | 'studentName' | 'programName' | 'date' | 'departmentName' | 'cgpa' ): string | number | boolean | null {
		return super.getFieldValue(name);
	}
}


export interface PdcDownloadTempUgVo extends Vo {
	pdcTempUgId?: number, 
	date?: string, 
	departmentName?: string, 
	usn?: string, 
	createdAt?: string, 
	programName?: string, 
	studentName?: string, 
	instituteId?: number, 
	pdcNo?: string, 
	cgpa?: number, 
	updatedAt?: string
}
