import { Component, OnInit, ViewChild, Input, AfterViewInit } from "@angular/core";
import { DynamicPanel, GroupPanel } from '../../interfaces';
import { ServiceAgent } from 'src/app/framework-modules/formdata/form/serviceAgent';
import { SessionComponent } from '../../component';
import { MessageService } from 'src/app/services/messageService';
import { CtSpinner } from 'src/app/framework-modules/elements/ct-spinner/component';
import { TableMetaData, CtTableComponent } from 'src/app/framework-modules/elements/ct-table/component';
import { ValuationMarksForm, ValuationMarksFd, ValuationMarksVo } from 'src/app/framework-modules/formdata/gen/valuationMarksForm';
import { FilterRequest } from 'src/app/framework-modules/formdata/form/types';
import { CodedPaperNameForm, CodedPaperNameFd, CodedPaperNameVo } from 'src/app/framework-modules/formdata/gen/codedPaperNameForm';

@Component({
  selector: "results-valuation",
  templateUrl: "./component.html",
  styleUrls: ["./component.scss"],
})


export class ResultsValuationComponent implements DynamicPanel, OnInit, AfterViewInit {
  @ViewChild("gridTable", { static: false }) public gtable: CtTableComponent;
  @ViewChild("spinner", { static: false }) spinner: CtSpinner;
  @Input() inputData: any;
  @Input() parentPanel: GroupPanel;
  @Input() session: SessionComponent;

  public form: ValuationMarksForm;
  public fd: ValuationMarksFd;
  public vo: ValuationMarksVo = {};
  public marksForm: CodedPaperNameForm;
  public marksFd: CodedPaperNameFd;
  public marksVo: CodedPaperNameVo = {};
  public tableData: TableMetaData;
  offeredSubject: any;
  valuationRound: any;
  isMakeup: boolean;

  constructor(private sa: ServiceAgent, private ms: MessageService) {
    this.form = ValuationMarksForm.getInstance();
    this.fd = this.form.newFormData(this.sa);
    this.marksForm = CodedPaperNameForm.getInstance();
    this.marksFd = this.marksForm.newFormData(this.sa);
  }


  ngOnInit() {
    this.metaDataInit()
  }

  ngAfterViewInit(){
    this.gtable.update()
  }

  /**
   * Initializing the tablemetadata
   */
  metaDataInit() {
    this.tableData = {
      data: [] = [],
      metaData: {
        column_names: [
          "name",
          "usn",
          "marks1",
          "marks2",
          "marks3",
          "effectiveMarks",
          "grade"
        ],
        display_names: {
          "name": "Student Name",
          "usn": "USN",
          "marks1": "Marks 1",
          "marks2": "Marks 2",
          "marks3": "Marks 3",
          "effectiveMarks": "Effective Marks",
          "grade": "Grade"
        },
        styleAttribute: {
          "grade": [
            {comp: '=', value: 'F', style:{'color':'red', 'font-weight': 800}},
            {comp: '=', value: 'W', style:{'color':'black', 'font-weight': 800}},
            {comp: '=', value: 'X', style:{'color':'green', 'font-weight': 800}},
           ]
        },
        search: true,
        placeholder: "Search a student..."
      }
    };
  }

  /**
   * Event Change Listener for the offeredSubjectId dropdown
   * @param $event subjectId
   */
  subjectChanged($event){
    this.offeredSubject = $event;
    this.filter();
  }

  /**
   * Event Change Listener for the valuationRound dropdown
   * @param $event roundId
   */
  roundChanged($event){
    this.valuationRound = $event;
    this.filter();
  }

  /**
   * Event Change Listener for the examType dropdown
   * @param $event examTypeId
   */
  examChanged($event){
    $event==1 ? this.isMakeup = false : this.isMakeup = true;
    this.filter();
  }

  /**
   * Filter conditions to filter the students as per the valuation round.
   */
  filter(){
    const filter: FilterRequest ={
      conditions:{
        valuationRound: {comp:"=",value:this.valuationRound},
        isMakeup: {comp:"=",value:this.isMakeup},
        offeredSubjectId: {comp:"=",value:this.offeredSubject}
      },
      maxRows:10,
      sort: {name:"asc"}
    }

    const obs = this.marksFd.filter(filter);
    const that = this;
    
    obs.subscribe(data=>{
      data.forEach(element =>{
        element["marks1"] = ( element["marks1"] == -1 ? "-" : element["marks1"])
        element["marks2"] = ( element["marks2"] == -1 ? "-" : element["marks2"])
        element["marks3"] = ( element["marks3"] == -1 ? "-" : element["marks3"])
      });
      this.tableData.data = data;
      this.gtable.update();
    });
  }

  /**
   * Downloads the excel containin the result of the current valuation round.
   */
  doDownload(){
    // TODO
  }
}