
import { Form , Field, ChildForm } from '../form/form';
import { FormData } from '../form/formData';
import { SelectOption, Vo } from '../form/types';
import { Validators } from '@angular/forms'
import { ServiceAgent} from '../form/serviceAgent';

export class QuotaWiseGradeStatisticsForm extends Form {
	private static _instance = new QuotaWiseGradeStatisticsForm();

	public static getInstance(): QuotaWiseGradeStatisticsForm {
		return QuotaWiseGradeStatisticsForm._instance;
	}

	constructor() {
		super();
		this.fields = new Map();
		this.controls = new Map();
		this.opsAllowed = {get: true, filter: true};
	}

	public getName(): string {
		 return 'quotaWiseGradeStatistics';
	}
}


export class QuotaWiseGradeStatisticsFd extends FormData {
	constructor(form: QuotaWiseGradeStatisticsForm, sa: ServiceAgent) {
		super(form, sa);
	}
	/**  this form has no editable fields. data nust be accessed as Vo and not through fd **/
}


export interface QuotaWiseGradeStatisticsVo extends Vo {
	instituteId?: number, 
	admissionQuota?: string, 
	seasonId?: number, 
	grade?: string, 
	nbr?: number
}
