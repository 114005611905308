
import { Form , Field, ChildForm } from '../form/form';
import { FormData } from '../form/formData';
import { SelectOption, Vo } from '../form/types';
import { Validators } from '@angular/forms'
import { ServiceAgent} from '../form/serviceAgent';

export class FormAStudentForm extends Form {
	private static _instance = new FormAStudentForm();
	offeredSubjectId:Field = {
		name:'offeredSubjectId'
		,controlType: 'Output'
		,label: 'offeredSubjectId'
		,valueType: 1
		,errorId: 'invalidId'
		,maxValue: 9999999999999
	};

	public static getInstance(): FormAStudentForm {
		return FormAStudentForm._instance;
	}

	constructor() {
		super();
		this.fields = new Map();
		this.controls = new Map();
		this.controls.set('offeredSubjectId', [Validators.max(9999999999999)]);
		this.fields.set('offeredSubjectId', this.offeredSubjectId);
		this.opsAllowed = {get: true, create: true, update: true, filter: true};
	}

	public getName(): string {
		 return 'formAStudent';
	}
}


export class FormAStudentFd extends FormData {
	constructor(form: FormAStudentForm, sa: ServiceAgent) {
		super(form, sa);
	}

	setFieldValue(name: 'offeredSubjectId', value: string | number | boolean | null ): void {
		super.setFieldValue(name, value);
	}

	getFieldValue(name: 'offeredSubjectId' ): string | number | boolean | null {
		return super.getFieldValue(name);
	}
}


export interface FormAStudentVo extends Vo {
	studentId?: number, 
	offeredSubjectId?: number, 
	usn?: string, 
	programName?: string, 
	instituteId?: number, 
	hasAttended?: boolean
}
