
import { Form , Field, ChildForm } from '../form/form';
import { FormData } from '../form/formData';
import { SelectOption, Vo } from '../form/types';
import { Validators } from '@angular/forms'
import { ServiceAgent} from '../form/serviceAgent';
import { LogicBlockForAssessmentSchemeForm, LogicBlockForAssessmentSchemeVo } from './logicBlockForAssessmentSchemeForm';

export class AssessmentSchemeAddForm extends Form {
	private static _instance = new AssessmentSchemeAddForm();
	instituteId:Field = {
		name:'instituteId'
		,controlType: 'Hidden'
		,label: 'instituteId'
		,valueType: 1
		,errorId: 'invalidTenentKey'
		,maxValue: 9999999999999
	};
	name:Field = {
		name:'name'
		,controlType: 'Input'
		,label: 'Scheme Name'
		,isRequired: true
		,valueType: 0
		,errorId: 'invalidName'
		,maxLength: 150
	};
	code:Field = {
		name:'code'
		,controlType: 'Input'
		,label: 'Scheme Code'
		,isRequired: true
		,valueType: 0
		,errorId: 'invalidCode'
		,maxLength: 50
	};
	minCieMarks:Field = {
		name:'minCieMarks'
		,controlType: 'Input'
		,label: 'Minimum CIE Marks'
		,isRequired: true
		,valueType: 1
		,errorId: 'invalidInteger'
		,maxValue: 9999999999999
	};

	logicBlockForAssessmentScheme: ChildForm = {
		name:'logicBlockForAssessmentScheme',
		form:LogicBlockForAssessmentSchemeForm.getInstance(),
		isEditable:false,
		isTabular:true,
		label:'',
		minRows:1,
		maxRows:0,
		errorId:null
	};

	public static getInstance(): AssessmentSchemeAddForm {
		return AssessmentSchemeAddForm._instance;
	}

	constructor() {
		super();
		this.fields = new Map();
		this.controls = new Map();
		this.controls.set('instituteId', [Validators.max(9999999999999)]);
		this.fields.set('instituteId', this.instituteId);
		this.controls.set('name', [Validators.required, Validators.maxLength(150)]);
		this.fields.set('name', this.name);
		this.controls.set('code', [Validators.required, Validators.maxLength(50)]);
		this.fields.set('code', this.code);
		this.controls.set('minCieMarks', [Validators.required, Validators.max(9999999999999)]);
		this.fields.set('minCieMarks', this.minCieMarks);

		this.childForms = new Map();
		this.childForms.set('logicBlockForAssessmentScheme', this.logicBlockForAssessmentScheme);
		this.opsAllowed = {get: true, filter: true, update: true, create: true, delete: true};
		this.keyFields = ["assessmentSchemeId"];
	}

	public getName(): string {
		 return 'assessmentSchemeAdd';
	}
}


export class AssessmentSchemeAddFd extends FormData {
	constructor(form: AssessmentSchemeAddForm, sa: ServiceAgent) {
		super(form, sa);
	}

	setFieldValue(name: 'instituteId' | 'name' | 'code' | 'minCieMarks', value: string | number | boolean | null ): void {
		super.setFieldValue(name, value);
	}

	getFieldValue(name: 'instituteId' | 'name' | 'code' | 'minCieMarks' ): string | number | boolean | null {
		return super.getFieldValue(name);
	}
}


export interface AssessmentSchemeAddVo extends Vo {
	minCieMarks?: number, 
	createdAt?: string, 
	code?: string, 
	cieCalculation?: string, 
	name?: string, 
	instituteId?: number, 
	assessmentSchemeId?: number, 
	isActive?: boolean, 
	updatedAt?: string, 
	logicBlockForAssessmentScheme?: LogicBlockForAssessmentSchemeVo
}
