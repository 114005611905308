
import { Form , Field, ChildForm } from '../form/form';
import { FormData } from '../form/formData';
import { SelectOption, Vo } from '../form/types';
import { Validators } from '@angular/forms'
import { ServiceAgent} from '../form/serviceAgent';

export class ProgramLevelListForm extends Form {
	private static _instance = new ProgramLevelListForm();
	departmentId:Field = {
		name:'departmentId'
		,controlType: 'Output'
		,label: 'Select a Department'
		,isRequired: true
		,listName: 'departmentList'
		,valueType: 1
		,errorId: 'invalidId'
		,maxValue: 9999999999999
	};
	programId:Field = {
		name:'programId'
		,controlType: 'Output'
		,label: 'Select a Program'
		,isRequired: true
		,listName: 'programList'
		,listKey: 'departmentId'
		,valueType: 1
		,errorId: 'invalidId'
		,maxValue: 9999999999999
	};
	programLevelId:Field = {
		name:'programLevelId'
		,controlType: 'Output'
		,label: 'Select a Semester'
		,isRequired: true
		,listName: 'programLevelList'
		,listKey: 'programId'
		,valueType: 1
		,errorId: 'invalidId'
		,maxValue: 9999999999999
	};
	seasonId:Field = {
		name:'seasonId'
		,controlType: 'Output'
		,label: 'Select a Season'
		,isRequired: true
		,listName: 'seasonList'
		,valueType: 1
		,errorId: 'invalidId'
		,maxValue: 9999999999999
	};
	levelSectionId:Field = {
		name:'levelSectionId'
		,controlType: 'Output'
		,label: 'Select a Section'
		,isRequired: true
		,listName: 'levelSectionList'
		,listKey: 'programLevelId'
		,valueType: 1
		,errorId: 'invalidId'
		,maxValue: 9999999999999
	};
	dateOfIssue:Field = {
		name:'dateOfIssue'
		,controlType: 'Input'
		,label: 'Issue Date'
		,isRequired: true
		,valueType: 4
		,errorId: 'invalidDate'
		,minValue: 73000
		,maxValue: 73000
	};
	yearOfCompletion:Field = {
		name:'yearOfCompletion'
		,controlType: 'Input'
		,label: 'Year of Completion'
		,valueType: 0
		,defaultValue: ''
		,errorId: 'invalidText'
		,maxLength: 1000
	};
	isMakeup:Field = {
		name:'isMakeup'
		,controlType: 'Input'
		,label: 'Is Makeup'
		,valueType: 3
		,defaultValue: false
		,errorId: 'invalidBool'
	};
	isAciteRequired:Field = {
		name:'isAciteRequired'
		,controlType: 'Input'
		,label: 'Is ACITE Required'
		,valueType: 3
		,defaultValue: false
		,errorId: 'invalidBool'
	};

	public static getInstance(): ProgramLevelListForm {
		return ProgramLevelListForm._instance;
	}

	constructor() {
		super();
		this.fields = new Map();
		this.controls = new Map();
		this.controls.set('departmentId', [Validators.required, Validators.max(9999999999999)]);
		this.fields.set('departmentId', this.departmentId);
		this.controls.set('programId', [Validators.required, Validators.max(9999999999999)]);
		this.fields.set('programId', this.programId);
		this.controls.set('programLevelId', [Validators.required, Validators.max(9999999999999)]);
		this.fields.set('programLevelId', this.programLevelId);
		this.controls.set('seasonId', [Validators.required, Validators.max(9999999999999)]);
		this.fields.set('seasonId', this.seasonId);
		this.controls.set('levelSectionId', [Validators.required, Validators.max(9999999999999)]);
		this.fields.set('levelSectionId', this.levelSectionId);
		this.controls.set('dateOfIssue', [Validators.required]);
		this.fields.set('dateOfIssue', this.dateOfIssue);
		this.controls.set('yearOfCompletion', [Validators.maxLength(1000)]);
		this.fields.set('yearOfCompletion', this.yearOfCompletion);
		this.controls.set('isMakeup', []);
		this.fields.set('isMakeup', this.isMakeup);
		this.controls.set('isAciteRequired', []);
		this.fields.set('isAciteRequired', this.isAciteRequired);
		this.listFields = ['departmentId','programId','programLevelId','seasonId','levelSectionId'];
		this.keyFields = ["programLevelId", "seasonId", "programId"];
	}

	public getName(): string {
		 return 'programLevelList';
	}
}


export class ProgramLevelListFd extends FormData {
	constructor(form: ProgramLevelListForm, sa: ServiceAgent) {
		super(form, sa);
	}

	setFieldValue(name: 'departmentId' | 'programId' | 'programLevelId' | 'seasonId' | 'levelSectionId' | 'dateOfIssue' | 'yearOfCompletion' | 'isMakeup' | 'isAciteRequired', value: string | number | boolean | null ): void {
		super.setFieldValue(name, value);
	}

	getFieldValue(name: 'departmentId' | 'programId' | 'programLevelId' | 'seasonId' | 'levelSectionId' | 'dateOfIssue' | 'yearOfCompletion' | 'isMakeup' | 'isAciteRequired' ): string | number | boolean | null {
		return super.getFieldValue(name);
	}
}


export interface ProgramLevelListVo extends Vo {
	programLevelId?: number, 
	levelSectionId?: number, 
	seasonId?: number, 
	isMakeup?: boolean, 
	yearOfCompletion?: string, 
	departmentId?: number, 
	isAciteRequired?: boolean, 
	dateOfIssue?: string, 
	programId?: number
}
