import { Component, AfterViewInit, Input } from '@angular/core';
import { ServiceAgent } from 'src/app/framework-modules/formdata/form/serviceAgent';
import { FormData } from 'src/app/framework-modules/formdata/form/formData';
import { RouterService } from 'src/app/services/routerService';
import { NonSessionComponent } from '../../component';
import { DynamicPanel, GroupPanel } from '../../interfaces';
import { MessageService } from 'src/app/services/messageService';
import { ForgotPasswordForm, ForgotPasswordFd } from 'src/app/framework-modules/formdata/gen/forgotPasswordForm';
import { ClientContext } from 'src/app/framework-modules/formdata/form/clientContext';

@Component({
  selector: 'forgot-password-otp',
  templateUrl: './component.html',
  styleUrls: ['./component.scss']
})
export class ForgotPassowrdOtpComponent implements DynamicPanel {
  @Input() inputData: any;
  @Input() parentPanel: GroupPanel;
  @Input() session: NonSessionComponent;
  token: string;

  showUsername: boolean = true;         // Shows the otp or the username field depending on the user input
  showPassword: boolean = false;
  showOtp: boolean = false;
  public form: ForgotPasswordForm;
  public fd: ForgotPasswordFd;
  public constructor(public sa: ServiceAgent, public routerService: RouterService, private ms: MessageService, private ctx: ClientContext) {
    this.form = ForgotPasswordForm.getInstance();
    this.fd = new FormData(this.form, this.sa);
  }

  /**
   * Validates the username/email enterd by the user.
   * Requests the server to send an OTP to the user's mailbox.
   * Activates the OTP Field
   */
  requestUser() {
    var usr = this.fd.getFieldValue('userName');
    if (!usr) {
      console.log("no user.");
      return;
    }
    this.sa.serve('sendOtp', { data: { loginId: usr} }).subscribe(
      data => {
        this.showUsername = false;
        this.ms.showInfo("OTP sent to registered mobile");
      },

      error => {
        this.ms.showError("email/userName is not valid, or the email is already registered.");
      }
    );
  }

  goBack() {
    this.parentPanel.navigate("Login");
  }
  /**
   * Validates the OTP enterd by the user
   */
  validateOTP() {
    var otp = this.fd.getFieldValue('otp') as string;
    if (!otp) {
      this.ms.showError("Please enter the OTP!");
      return false;
    }
  }

  changePassword() {
    var newPass = this.fd.getFieldValue('newPassword') as string;
    var confirmPass = this.fd.getFieldValue('confirmPassword') as string;
    if (newPass !== confirmPass) {
      this.ms.showError("Passwords do not match!");
      return;
    }
    this.fd.save().subscribe(
      data => {
        this.ms.showSuccess("Password reset successful!");
        this.parentPanel.navigate('Login');
      },
      error => {
        this.ms.showError('Invalid OTP. please try  again');
      }
    );
  }
}
