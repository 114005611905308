import { Component, OnInit, ViewChild, Input } from "@angular/core";
import { GroupPanel } from '../../interfaces';
import { ServiceAgent } from 'src/app/framework-modules/formdata/form/serviceAgent';
import { SessionComponent } from '../../component';
import { TableMetaData, CtTableComponent } from 'src/app/framework-modules/elements/ct-table/component';
import { CtSpinner } from 'src/app/framework-modules/elements/ct-spinner/component';
import { CtDialogComponent } from 'src/app/framework-modules/elements/ct-dialog/component';
import { MatDialog } from '@angular/material';
import { CodedPaperListForm, CodedPaperListFd } from 'src/app/framework-modules/formdata/gen/codedPaperListForm';
import { MessageService } from 'src/app/services/messageService';
import { ExcelDownloadService } from 'src/app/services/excelDownloadService';
import { ClientContext } from "src/app/framework-modules/formdata/form/clientContext";
import { FilterRequest } from "src/app/framework-modules/formdata/form/types";
import { PaperHistoryFd, PaperHistoryForm } from "src/app/framework-modules/formdata/gen/paperHistoryForm";
import { PdfDownloadService } from "src/app/services/pdfDownloadService";

@Component({
  selector: "cie-calculation-list",
  templateUrl: "./component.html",
  styleUrls: ["./component.scss"]
})


export class CodingComponent implements OnInit {
  @ViewChild("gridTable", { static: false }) gtable: CtTableComponent;
  @ViewChild("spinner", { static: false }) public spinner: CtSpinner;
  @Input() inputData: any;
  @Input() parentPanel: GroupPanel;
  @Input() session: SessionComponent;

  public tableData: TableMetaData;
  form: CodedPaperListForm;
  fd: CodedPaperListFd;

  selectedId = null;
  isCodeGenerated: boolean = false;
  selectedSubject: string = "Engineering Mechanics";
  codeMaps:{}[];
  selectedValRnd: number;
  historyForm: PaperHistoryForm;
  historyFd: PaperHistoryFd;

  constructor(private sa: ServiceAgent, private ms: MessageService, private dialog: MatDialog, private ds: ExcelDownloadService, private cc: ClientContext) {
    this.form = CodedPaperListForm.getInstance();
    this.fd = this.form.newFormData(this.sa);
    this.historyForm = PaperHistoryForm.getInstance();
    this.historyFd = this.historyForm.newFormData(this.sa);
  }

  ngOnInit() {
    this.metaDataInit();
    this.fd.setFieldValue('valuationRound',this.cc.getValue("_user").currentValuationRound);
  }

  metaDataInit() {
    this.tableData = {
      data: [],
      metaData: {
        column_names: [
          "name",
          "usn",
          "paperCode",
          "packetCode"
        ],
        display_names: {
          name: "Student Name",
          usn: "Student USN",
          paperCode: "Code",
          packetCode: "Packet Number",
        },
        search: true,
        placeholder: "Search Student name, USN, Code or Packet Number"
      }
    };
  }

  idChanged(id) {
    if (id == this.selectedId) {
      return;
    }
    this.selectedId = id;
    if (id) {
      this.getData(false);
    }
  }

  /**
   * we normally use fd.fetchData().
   * values on fd are not available when onChange is triggered, and hence we are forced to use fetchFor()
   */
  private getData(afterSave: boolean) {
    this.isCodeGenerated = false;
    var valuationRound = this.fd.getFieldValue('valuationRound');
    if(valuationRound == 0 || valuationRound == undefined){
      this.fd.setFieldValue('valuationRound',this.cc.getValue("_user").currentValuationRound)
      valuationRound = this.fd.getFieldValue('valuationRound');
    }
    this.selectedValRnd = parseInt(valuationRound as string);
    const d = {
      offeredSubjectId: this.selectedId,
      departmentId: this.fd.getFieldValue('departmentId'),
      valuationRound: this.selectedValRnd,
      isMakeup: false
    };
    this.spinner.show('Getting students for this subject...');
    this.fd.fetchFor(d).subscribe({
      next: data => {
        this.fd.setFieldValue('valuationRound',this.selectedValRnd);
        const list = data.students as [];
        if (list && list.length) {
          console.info(list.length + ' students received');
          this.isCodeGenerated = true;
          this.tableData.data = list;
          this.gtable.update(this.spinner);
        } else {
          this.spinner.hide();
          if (afterSave) {
            this.ms.showError('No students found in the attendance register. No papers were coded.');
          } else {
            this.ms.showInfo('Code not yet generated for this subject.');
          }
        }
      },
      error: msg => {
        this.spinner.hide();
        console.log(msg);
      }
    });
  }
  /**
   * to be replaced by server code when generate functionality is complete
   * data hardcoded for now
   */
  doGenerate() {
    this.spinner.show('Generating codes for answer papers...');
    this.fd.setFieldValue('isMakeup',false);
    this.fd.save().subscribe({
      next: data => {
        this.spinner.hide();
        this.ms.showSuccess('Code generated for this subject.');
        this.getData(true);
      },
      error: msg => {
        this.spinner.hide();
      }
    });
  }

  /**
   * opens dialog to confirm codes generation
   */
  generate() {
    let dialogRef = this.dialog.open(CtDialogComponent);
    dialogRef.componentInstance.title = 'Generate Codes';
    dialogRef.componentInstance.content = 'Are you sure you want Generate Codes? Once Codes are generated, codes can not be re-generated';
    dialogRef.componentInstance.primary = 'Generate';
    const subscribeDialog = dialogRef.componentInstance.save.subscribe((data) => {
      this.dialog.closeAll();
      this.doGenerate();
    });
  }

  /**
   * to call the download service
   * format to be requested from client
   */
  download() {
    var codes = [];
    this.codeMaps = [];
    var usns = [];
    var packetNumbers = []
    this.tableData.data.forEach((data,index) =>{
      codes.push(data.paperCode);
      usns.push(data.usn);
      packetNumbers.push(data.packetCode);
      this.generateCodeMap(index,data);
    });
    var packets = packetNumbers.filter((v, i, a) => a.indexOf(v) === i).sort((one, two) => (one < two ? -1 : 1));
    console.log(this.codeMaps);
    this.ds.downloadCodes(codes,this.fd.getDisplayedValueOf('offeredSubjectId') + " codes", this.fd.getDisplayedValueOf('offeredSubjectId'));
    this.ds.downloadCodes(usns,this.fd.getDisplayedValueOf('offeredSubjectId') + " USN Map",this.fd.getDisplayedValueOf('offeredSubjectId'));
    this.ds.downloadCodes(packets,this.fd.getDisplayedValueOf('offeredSubjectId') + " Packets",this.fd.getDisplayedValueOf('offeredSubjectId'),true);
    this.ds.downloadExcel({'codes':this.codeMaps},"Mapped Codes-"+this.fd.getDisplayedValueOf('offeredSubjectId'));
  }

  /**
   * This is a helper function for dowloading codes pdf
   * It sets the name of selected subject as the file download name
   * @param $event Subject name changed event
   */
  setSubject($event){
    this.selectedSubject = $event
  }

  /**
   * Method to create an array of json containg code details
   * @param index index of each code
   * @param data data associated with the paper
   */
  generateCodeMap(index,data){
    var index1 = index+1;
    var pageNo = Math.ceil(index1/24);
    var rowNo = Math.ceil((index1%24 || 24)/3);
    var colNo = ((index1%24)%3 || 3 );
    this.codeMaps.push({usn:data.usn,packetCode:data.packetCode,paperCode:data.paperCode,pageNo:pageNo,rowNo:rowNo,colNo:colNo})
  }

  /**
   * Download the historical paper data of students
   * i.e the previous valuation paper code and this round valuation round paper code
   * Download this in an pdf
   */
  downloadHistory(){
    var valuationRound = this.fd.getFieldValue('valuationRound');
    const revalF: FilterRequest = {
      conditions: {
        offeredSubjectId: {comp:'=',value:this.fd.getFieldValue('offeredSubjectId')},
        revalPaperCode: {comp:'!=',value:'-'},
        isMakeup: {comp: '=', value: false}
      }
    }
    const challengeF: FilterRequest = {
      conditions: {
        offeredSubjectId: {comp:'=',value:this.fd.getFieldValue('offeredSubjectId')},
        chPaperCode: {comp:'!=',value:'-'},
        isMakeup: {comp: '=', value: false}
      }
    }
    this.historyFd.filter(valuationRound == 2 ? revalF: challengeF).subscribe({
      next: data =>{
        console.log(data);
        this.handover(data);
      },
      error: err => {
        console.error(err);
      }
    })
  }

  /**
   * Handover data to download
   * @param data Data to download
   */
  handover(data){
    var cols = ["name","usn","paperCode","packetCode","revalPaperCode","revalPacketCode","chPaperCode","chPacketCode"];
    var displayMap ={name: "Student Name",usn: "Student USN",paperCode: "Inital Code",packetCode: "Initial Packet",revalPaperCode: "Reval code",revalPacketCode: "Reval Packet",chPaperCode: "Challege code",chPacketCode: "Challenge Packet"};
    var excelData = [];
    data.forEach(d => {
      var row = {}
      cols.forEach(col =>{
        row[displayMap[col]] = d[col]
      });
      excelData.push(row);
    });
    this.ds.downloadExcelBook({"Paper history": excelData},this.fd.getDisplayedValueOf('offeredSubjectId') + " - Paper History");
  }
}