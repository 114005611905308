import { Component, Input, OnInit } from "@angular/core";
import { DynamicPanel, GroupPanel } from '../../interfaces';
import { ServiceAgent } from 'src/app/framework-modules/formdata/form/serviceAgent';
import { SessionComponent } from '../../component';
import { MessageService } from 'src/app/services/messageService';
import { ExternalForm, ExternalFd } from 'src/app/framework-modules/formdata/gen/externalForm';
import { CtDialogComponent } from 'src/app/framework-modules/elements/ct-dialog/component';
import { MatDialog } from '@angular/material';

@Component({
  selector: "external-evaluator-edit",
  templateUrl: "./component.html",
  styleUrls: ["./component.scss"]
})

export class ExternalStaffEditComponent implements DynamicPanel, OnInit {
  @Input() inputData: any;
  @Input() parentPanel: GroupPanel;
  @Input() session: SessionComponent;

  public form: ExternalForm;
  public fd: ExternalFd;

  constructor(private sa: ServiceAgent,private ms:MessageService, private dialog: MatDialog) {
    this.form = ExternalForm.getInstance();
    this.fd = this.form.newFormData(this.sa);
  }

  ngOnInit() {

    if(!this.inputData){
      this.ms.showError('Page invoked with invalid data');
      return;
    }
    this.getData(this.inputData);
  }

  private getData(id){
    this.fd.setFieldValue('externalId', id);
    this.fd.fetchData().subscribe({
      next: vo => {
      },
      error: msg => {
        this.ms.showError("server returned with error: " + msg[0].text);
      }
    });
  }

  save(){
    let dialogRef = this.dialog.open(CtDialogComponent);
    dialogRef.componentInstance.title = 'Save Changes';
    dialogRef.componentInstance.content = 'Are you sure you want to save changes?';
    dialogRef.componentInstance.primary = 'Save';
    const subscribeDialog = dialogRef.componentInstance.save.subscribe((data) => {
      this.dialog.closeAll();
      this.doSave();
    });
  }
  
  doCancel() {
    let dialogRef = this.dialog.open(CtDialogComponent);
    dialogRef.componentInstance.title = 'Discard Changes';
    dialogRef.componentInstance.content = 'Are you sure you want to discard changes?';
    dialogRef.componentInstance.primary = 'Discard';
    const subscribeDialog = dialogRef.componentInstance.save.subscribe((data) => {
      this.dialog.closeAll();
      this.parentPanel.navigate('ExternalStaffList');
    }); 
  }

  doSave() {
    this.fd.save().subscribe({
      next: vo => {
        this.ms.showSuccess("External Staff Details Updated Successfully!")
        this.parentPanel.navigate("ExternalStaffList");
      },
      error: err => {
        this.ms.showError(err);
        console.error(err);
      }
    });
  }

}
