
import { Form , Field, ChildForm } from '../form/form';
import { FormData } from '../form/formData';
import { SelectOption, Vo } from '../form/types';
import { Validators } from '@angular/forms'
import { ServiceAgent} from '../form/serviceAgent';

export class WithdrawnListForm extends Form {
	private static _instance = new WithdrawnListForm();
	studentId:Field = {
		name:'studentId'
		,controlType: 'Hidden'
		,label: 'studentId'
		,isRequired: true
		,valueType: 1
		,errorId: 'invalidId'
		,maxValue: 9999999999999
	};
	name:Field = {
		name:'name'
		,controlType: 'Output'
		,label: 'Name'
		,valueType: 0
		,errorId: 'invalidText'
		,maxLength: 1000
	};
	usn:Field = {
		name:'usn'
		,controlType: 'Output'
		,label: 'USN'
		,valueType: 0
		,errorId: 'invalidText'
		,maxLength: 1000
	};
	hasWithdrawn:Field = {
		name:'hasWithdrawn'
		,controlType: 'Output'
		,label: 'hasWithdrawn'
		,valueType: 3
		,errorId: 'invalidBool'
	};

	public static getInstance(): WithdrawnListForm {
		return WithdrawnListForm._instance;
	}

	constructor() {
		super();
		this.fields = new Map();
		this.controls = new Map();
		this.controls.set('studentId', [Validators.required, Validators.max(9999999999999)]);
		this.fields.set('studentId', this.studentId);
		this.controls.set('name', [Validators.maxLength(1000)]);
		this.fields.set('name', this.name);
		this.controls.set('usn', [Validators.maxLength(1000)]);
		this.fields.set('usn', this.usn);
		this.controls.set('hasWithdrawn', []);
		this.fields.set('hasWithdrawn', this.hasWithdrawn);
		this.opsAllowed = {filter: true};
		this.keyFields = ["subjectSectionId"];
	}

	public getName(): string {
		 return 'withdrawnList';
	}
}


export class WithdrawnListFd extends FormData {
	constructor(form: WithdrawnListForm, sa: ServiceAgent) {
		super(form, sa);
	}

	setFieldValue(name: 'studentId' | 'name' | 'usn' | 'hasWithdrawn', value: string | number | boolean | null ): void {
		super.setFieldValue(name, value);
	}

	getFieldValue(name: 'studentId' | 'name' | 'usn' | 'hasWithdrawn' ): string | number | boolean | null {
		return super.getFieldValue(name);
	}
}


export interface WithdrawnListVo extends Vo {
	hasAttendedMakeup?: boolean, 
	overallAttendance?: string, 
	subjectRegisterId?: number, 
	level?: string, 
	hasAttendedExam?: boolean, 
	isEligibleToWithdraw?: boolean, 
	hasWithdrawn?: boolean, 
	isAuthorizedAbsence?: boolean, 
	hasOptedForMakeup?: boolean, 
	studentId?: number, 
	offeredSubjectId?: number, 
	usn?: string, 
	subjectSectionId?: number, 
	name?: string, 
	instituteId?: number, 
	department?: string, 
	hasAttended?: boolean
}
