
import { Form , Field, ChildForm } from '../form/form';
import { FormData } from '../form/formData';
import { SelectOption, Vo } from '../form/types';
import { Validators } from '@angular/forms'
import { ServiceAgent} from '../form/serviceAgent';

export class AdmissionQuotaSubjectPerformanceForm extends Form {
	private static _instance = new AdmissionQuotaSubjectPerformanceForm();

	public static getInstance(): AdmissionQuotaSubjectPerformanceForm {
		return AdmissionQuotaSubjectPerformanceForm._instance;
	}

	constructor() {
		super();
		this.fields = new Map();
		this.controls = new Map();
		this.opsAllowed = {get: true, filter: true};
	}

	public getName(): string {
		 return 'admissionQuotaSubjectPerformance';
	}
}


export class AdmissionQuotaSubjectPerformanceFd extends FormData {
	constructor(form: AdmissionQuotaSubjectPerformanceForm, sa: ServiceAgent) {
		super(form, sa);
	}
	/**  this form has no editable fields. data nust be accessed as Vo and not through fd **/
}


export interface AdmissionQuotaSubjectPerformanceVo extends Vo {
	studentId?: number, 
	cieMarks?: number, 
	usn?: string, 
	seasonId?: number, 
	grade?: string, 
	name?: string, 
	totalMarks?: number, 
	subjectId?: number
}
