
import { Form , Field, ChildForm } from '../form/form';
import { FormData } from '../form/formData';
import { SelectOption, Vo } from '../form/types';
import { Validators } from '@angular/forms'
import { ServiceAgent} from '../form/serviceAgent';
import { StudentCieDetailForm, StudentCieDetailVo } from './studentCieDetailForm';

export class CieDownloadForm extends Form {
	private static _instance = new CieDownloadForm();
	subjectSectionId:Field = {
		name:'subjectSectionId'
		,controlType: 'Hidden'
		,label: 'subjectSectionId'
		,isRequired: true
		,valueType: 1
		,errorId: 'invalidId'
		,maxValue: 9999999999999
	};
	sectionName:Field = {
		name:'sectionName'
		,controlType: 'Output'
		,label: 'Section'
		,valueType: 0
		,errorId: 'invalidName'
		,maxLength: 150
	};
	subjectName:Field = {
		name:'subjectName'
		,controlType: 'Output'
		,label: 'Subject Name'
		,valueType: 0
		,errorId: 'invalidName'
		,maxLength: 150
	};
	subjectCode:Field = {
		name:'subjectCode'
		,controlType: 'Output'
		,label: 'Subject Code'
		,valueType: 0
		,errorId: 'invalidName'
		,maxLength: 150
	};
	offeredSubjectId:Field = {
		name:'offeredSubjectId'
		,controlType: 'Hidden'
		,label: 'offeredSubjectId'
		,valueType: 1
		,defaultValue: 0
		,errorId: 'invalidId'
		,maxValue: 9999999999999
	};
	subjectId:Field = {
		name:'subjectId'
		,controlType: 'Hidden'
		,label: 'subjectId'
		,valueType: 1
		,defaultValue: 0
		,errorId: 'invalidId'
		,maxValue: 9999999999999
	};
	levelName:Field = {
		name:'levelName'
		,controlType: 'Output'
		,label: 'Semester'
		,valueType: 0
		,errorId: 'invalidText'
		,maxLength: 1000
	};
	isIntegrated:Field = {
		name:'isIntegrated'
		,controlType: 'Output'
		,label: 'Is Integrated'
		,isRequired: true
		,valueType: 3
		,defaultValue: false
		,errorId: 'invalidBool'
	};
	date:Field = {
		name:'date'
		,controlType: 'Output'
		,label: 'Date'
		,valueType: 4
		,errorId: 'invalidDate'
		,minValue: 73000
		,maxValue: 73000
	};
	finalExam:Field = {
		name:'finalExam'
		,controlType: 'Output'
		,label: 'Final Exam Date'
		,valueType: 4
		,errorId: 'invalidDate'
		,minValue: 73000
		,maxValue: 73000
	};

	studentCieDetails: ChildForm = {
		name:'studentCieDetails',
		form:StudentCieDetailForm.getInstance(),
		isEditable:false,
		isTabular:true,
		label:'',
		minRows:0,
		maxRows:0,
		errorId:null
	};

	public static getInstance(): CieDownloadForm {
		return CieDownloadForm._instance;
	}

	constructor() {
		super();
		this.fields = new Map();
		this.controls = new Map();
		this.controls.set('subjectSectionId', [Validators.required, Validators.max(9999999999999)]);
		this.fields.set('subjectSectionId', this.subjectSectionId);
		this.controls.set('sectionName', [Validators.maxLength(150)]);
		this.fields.set('sectionName', this.sectionName);
		this.controls.set('subjectName', [Validators.maxLength(150)]);
		this.fields.set('subjectName', this.subjectName);
		this.controls.set('subjectCode', [Validators.maxLength(150)]);
		this.fields.set('subjectCode', this.subjectCode);
		this.controls.set('offeredSubjectId', [Validators.max(9999999999999)]);
		this.fields.set('offeredSubjectId', this.offeredSubjectId);
		this.controls.set('subjectId', [Validators.max(9999999999999)]);
		this.fields.set('subjectId', this.subjectId);
		this.controls.set('levelName', [Validators.maxLength(1000)]);
		this.fields.set('levelName', this.levelName);
		this.controls.set('isIntegrated', [Validators.required]);
		this.fields.set('isIntegrated', this.isIntegrated);
		this.controls.set('date', []);
		this.fields.set('date', this.date);
		this.controls.set('finalExam', []);
		this.fields.set('finalExam', this.finalExam);

		this.childForms = new Map();
		this.childForms.set('studentCieDetails', this.studentCieDetails);
		this.opsAllowed = {get: true, update: true, filter: true};
		this.keyFields = ["subjectSectionId"];
	}

	public getName(): string {
		 return 'cieDownload';
	}
}


export class CieDownloadFd extends FormData {
	constructor(form: CieDownloadForm, sa: ServiceAgent) {
		super(form, sa);
	}

	setFieldValue(name: 'subjectSectionId' | 'sectionName' | 'subjectName' | 'subjectCode' | 'offeredSubjectId' | 'subjectId' | 'levelName' | 'isIntegrated' | 'date' | 'finalExam', value: string | number | boolean | null ): void {
		super.setFieldValue(name, value);
	}

	getFieldValue(name: 'subjectSectionId' | 'sectionName' | 'subjectName' | 'subjectCode' | 'offeredSubjectId' | 'subjectId' | 'levelName' | 'isIntegrated' | 'date' | 'finalExam' ): string | number | boolean | null {
		return super.getFieldValue(name);
	}
}


export interface CieDownloadVo extends Vo {
	sectionName?: string, 
	date?: string, 
	offeredSubjectId?: number, 
	finalExam?: string, 
	subjectSectionId?: number, 
	assignedStaff?: string, 
	levelName?: string, 
	isIntegrated?: boolean, 
	subjectCode?: string, 
	subjectId?: number, 
	subjectName?: string, 
	studentCieDetails?: StudentCieDetailVo
}
