import { Component, OnInit, ViewChild, Input} from "@angular/core";
import { DynamicPanel, GroupPanel } from '../../interfaces';
import { ServiceAgent } from 'src/app/framework-modules/formdata/form/serviceAgent';
import { TableMetaData } from 'src/app/framework-modules/elements/ct-table/component'
import { SessionComponent } from '../../component';
import { MatDialog } from '@angular/material';
import { CtCheckboxTableComponent } from 'src/app/framework-modules/elements/ct-checkbox-table/component';
import { ExamAttendanceForm, ExamAttendanceVo } from 'src/app/framework-modules/formdata/gen/examAttendanceForm';
import { CtDialogComponent } from 'src/app/framework-modules/elements/ct-dialog/component';
import { CtSpinner } from 'src/app/framework-modules/elements/ct-spinner/component';
import { PanelData } from 'src/app/framework-modules/formdata/form/formData';
import { MessageService } from 'src/app/services/messageService';
import { ExamRegisterVo } from 'src/app/framework-modules/formdata/gen/examRegisterForm';
import { ExemptStudentsVo, ExemptStudentsForm, ExemptStudentsFd } from 'src/app/framework-modules/formdata/gen/exemptStudentsForm';

@Component({
  selector: "exam-exemption-edit",
  templateUrl: "./component.html",
  styleUrls: ["./component.scss"],
})

export class ExamExemptionEditComponent implements DynamicPanel, OnInit {
  @ViewChild("gridTable", { static: false }) gtable: CtCheckboxTableComponent;
  @ViewChild("spinner", { static: false }) spinner: CtSpinner;
  @Input() inputData: any;
  @Input() parentPanel: GroupPanel;
  @Input() session: SessionComponent;

  public tableData: TableMetaData;
  private fd: PanelData;
  public vo: ExemptStudentsVo;

  constructor(private sa: ServiceAgent,
    private dialog: MatDialog,
    private ms: MessageService) {
    this.fd = ExemptStudentsForm.getInstance().newPanelData(this.sa);
    this.vo = {};
  }

  ngOnInit() {
    this.metaDataInit();
  }

  ngAfterViewInit() {
    if (this.inputData && this.inputData.offeredSubjectId && this.inputData.departmentId) {
      this.getData(this.inputData);
    } else {
      this.ms.showError('Page with no/invalid data');
    }
  }

  metaDataInit() {
    this.tableData = {
      data: [],
      metaData: {
        column_names: [
          "name",
          "usn",
          "department",
          "level"
        ],
        display_names: {
          name: "Name",
          usn: "USN",
          department: "Department Name",
          level: "Level"
        },
        selectAttr:"isAuthorizedAbsence",
        search: true,
        placeholder: "Search By Name,USN,Department Name...",
        managerFunction: "SAVE",
        itemName: "Students"
      },
    };
  }
  private getData(data) {
    this.spinner.show('Getting attendance register..');
    /**
     * offeredSubjectId is the primary key in the form
     */
    this.fd.fetchFor(data).subscribe({
      next: data => {
        this.vo = data as ExemptStudentsVo;
        this.tableData.data = data.students as [];
        this.gtable.update(this.spinner);
      },

      error: msg => {
        this.spinner.hide();
        this.ms.showError('server returned with errors');
      }
    });
  }


  private saveData() {
    this.fd.save().subscribe({
      next: data => {
        this.ms.showSuccess('Attendance Register updted..');
        this.parentPanel.navigate('ExamExemptionList');
      },

      error: msg => {
        this.ms.showError('server returned with errors');
      }
    });
  }


  /**
   * Function is called when the user clicks save button.
   * Saves the Exempted list of students of a subject.
   */
  save() {
    let dialogRef = this.dialog.open(CtDialogComponent);
    dialogRef.componentInstance.title = 'Save Changes';
    dialogRef.componentInstance.content = 'Are you sure you want to save changes?';
    dialogRef.componentInstance.primary = 'Save';
    const subscribeDialog = dialogRef.componentInstance.save.subscribe((data) => {
      this.saveData();
      this.dialog.closeAll();
    });
  }

  /**
   * Function is called when the user clicks cancel button.
   * Goes back to Exam exemption list screen
   */
  cancel() {
    let dialogRef = this.dialog.open(CtDialogComponent);
    dialogRef.componentInstance.title = 'Discard Changes';
    dialogRef.componentInstance.content = 'Are you sure you want to discard changes?';
    dialogRef.componentInstance.primary = 'Discard';
    const subscribeDialog = dialogRef.componentInstance.save.subscribe((data) => {
      this.dialog.closeAll();
      this.parentPanel.navigate("ExamExemptionList");
    });
  }
}