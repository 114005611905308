
import { Form , Field, ChildForm } from '../form/form';
import { FormData } from '../form/formData';
import { SelectOption, Vo } from '../form/types';
import { Validators } from '@angular/forms'
import { ServiceAgent} from '../form/serviceAgent';
import { ExamRegisterForm, ExamRegisterVo } from './examRegisterForm';

export class WithdrawlEligibilityForm extends Form {
	private static _instance = new WithdrawlEligibilityForm();
	subjectSectionId:Field = {
		name:'subjectSectionId'
		,controlType: 'Hidden'
		,label: 'subjectSectionId'
		,isRequired: true
		,valueType: 1
		,errorId: 'invalidId'
		,maxValue: 9999999999999
	};
	offeredSubjectId:Field = {
		name:'offeredSubjectId'
		,controlType: 'Hidden'
		,label: 'offeredSubjectId'
		,isRequired: true
		,valueType: 1
		,errorId: 'invalidId'
		,maxValue: 9999999999999
	};

	students: ChildForm = {
		name:'students',
		form:ExamRegisterForm.getInstance(),
		isEditable:false,
		isTabular:true,
		label:'',
		minRows:0,
		maxRows:0,
		errorId:null
	};

	public static getInstance(): WithdrawlEligibilityForm {
		return WithdrawlEligibilityForm._instance;
	}

	constructor() {
		super();
		this.fields = new Map();
		this.controls = new Map();
		this.controls.set('subjectSectionId', [Validators.required, Validators.max(9999999999999)]);
		this.fields.set('subjectSectionId', this.subjectSectionId);
		this.controls.set('offeredSubjectId', [Validators.required, Validators.max(9999999999999)]);
		this.fields.set('offeredSubjectId', this.offeredSubjectId);

		this.childForms = new Map();
		this.childForms.set('students', this.students);
		this.opsAllowed = {filter: true, get: true, update: true};
		this.keyFields = ["subjectSectionId"];
	}

	public getName(): string {
		 return 'withdrawlEligibility';
	}
}


export class WithdrawlEligibilityFd extends FormData {
	constructor(form: WithdrawlEligibilityForm, sa: ServiceAgent) {
		super(form, sa);
	}

	setFieldValue(name: 'subjectSectionId' | 'offeredSubjectId', value: string | number | boolean | null ): void {
		super.setFieldValue(name, value);
	}

	getFieldValue(name: 'subjectSectionId' | 'offeredSubjectId' ): string | number | boolean | null {
		return super.getFieldValue(name);
	}
}


export interface WithdrawlEligibilityVo extends Vo {
	hasAttendedMakeup?: boolean, 
	overallAttendance?: string, 
	subjectRegisterId?: number, 
	level?: string, 
	hasAttendedExam?: boolean, 
	isEligibleToWithdraw?: boolean, 
	hasWithdrawn?: boolean, 
	isAuthorizedAbsence?: boolean, 
	hasOptedForMakeup?: boolean, 
	studentId?: number, 
	offeredSubjectId?: number, 
	usn?: string, 
	subjectSectionId?: number, 
	name?: string, 
	instituteId?: number, 
	department?: string, 
	hasAttended?: boolean, 
	students?: ExamRegisterVo
}
