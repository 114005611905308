
import { Form , Field, ChildForm } from '../form/form';
import { FormData } from '../form/formData';
import { SelectOption, Vo } from '../form/types';
import { Validators } from '@angular/forms'
import { ServiceAgent} from '../form/serviceAgent';
import { CodedPaperNameForm, CodedPaperNameVo } from './codedPaperNameForm';

export class CodedPaperListForm extends Form {
	private static _instance = new CodedPaperListForm();
	departmentId:Field = {
		name:'departmentId'
		,controlType: 'Dropdown'
		,label: 'Department'
		,isRequired: true
		,listName: 'departmentList'
		,valueType: 1
		,errorId: 'invalidId'
		,maxValue: 9999999999999
	};
	offeredSubjectId:Field = {
		name:'offeredSubjectId'
		,controlType: 'Dropdown'
		,label: 'Subject'
		,isRequired: true
		,listName: 'offeredSubjectList'
		,listKey: 'departmentId'
		,valueType: 1
		,errorId: 'invalidId'
		,maxValue: 9999999999999
	};
	valuationRound:Field = {
		name:'valuationRound'
		,controlType: 'Dropdown'
		,label: 'Valuation Round'
		,listName: 'valuationRoundList'
		,valueList: [
			{value:'1',text:'Initial'},
			{value:'2',text:'Revaluation'},
			{value:'3',text:'Challenge'}
			]
		,valueType: 1
		,errorId: 'invalidInteger'
		,maxValue: 9999999999999
	};
	isMakeup:Field = {
		name:'isMakeup'
		,controlType: 'Hidden'
		,label: 'isMakeup'
		,valueType: 3
		,errorId: 'invalidBool'
	};

	students: ChildForm = {
		name:'students',
		form:CodedPaperNameForm.getInstance(),
		isEditable:false,
		isTabular:true,
		label:'',
		minRows:0,
		maxRows:0,
		errorId:null
	};

	public static getInstance(): CodedPaperListForm {
		return CodedPaperListForm._instance;
	}

	constructor() {
		super();
		this.fields = new Map();
		this.controls = new Map();
		this.controls.set('departmentId', [Validators.required, Validators.max(9999999999999)]);
		this.fields.set('departmentId', this.departmentId);
		this.controls.set('offeredSubjectId', [Validators.required, Validators.max(9999999999999)]);
		this.fields.set('offeredSubjectId', this.offeredSubjectId);
		this.controls.set('valuationRound', [Validators.max(9999999999999)]);
		this.fields.set('valuationRound', this.valuationRound);
		this.controls.set('isMakeup', []);
		this.fields.set('isMakeup', this.isMakeup);

		this.childForms = new Map();
		this.childForms.set('students', this.students);
		this.opsAllowed = {get: true, update: true};
		this.listFields = ['departmentId','offeredSubjectId','valuationRound'];
	}

	public getName(): string {
		 return 'codedPaperList';
	}
}


export class CodedPaperListFd extends FormData {
	constructor(form: CodedPaperListForm, sa: ServiceAgent) {
		super(form, sa);
	}

	setFieldValue(name: 'departmentId' | 'offeredSubjectId' | 'valuationRound' | 'isMakeup', value: string | number | boolean | null ): void {
		super.setFieldValue(name, value);
	}

	getFieldValue(name: 'departmentId' | 'offeredSubjectId' | 'valuationRound' | 'isMakeup' ): string | number | boolean | null {
		return super.getFieldValue(name);
	}
}


export interface CodedPaperListVo extends Vo {
	offeredSubjectId?: number, 
	valuationRound?: number, 
	isMakeup?: boolean, 
	departmentId?: number, 
	students?: CodedPaperNameVo
}
