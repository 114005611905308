import { Component, Input, Output, EventEmitter, OnInit, ViewChild } from "@angular/core";
import { DynamicPanel, LandingPanel, GroupPanel } from '../../interfaces';
import { ServiceAgent } from 'src/app/framework-modules/formdata/form/serviceAgent';
import { SessionComponent } from '../../component';
import { FormData } from 'src/app/framework-modules/formdata/form/formData';
import { ProgramForm, ProgramVo } from 'src/app/framework-modules/formdata/gen/programForm';
import { CtTableComponent } from 'src/app/framework-modules/elements/ct-table/component';
import { MessageService } from 'src/app/services/messageService';
import { ClientContext } from 'src/app/framework-modules/formdata/form/clientContext';
import { Vo, FilterRequest } from 'src/app/framework-modules/formdata/form/types';
import { CtSearchBarFdComponent } from 'src/app/framework-modules/elements/ct-searchbar-fd/component';
import { MatDialog } from '@angular/material';
import { CtDialogComponent } from 'src/app/framework-modules/elements/ct-dialog/component';


@Component({
  selector: "program-add",
  templateUrl: "./component.html",
  styleUrls: ["./component.scss"]
})


export class ProgramAddComponent implements DynamicPanel, OnInit {
  @ViewChild("gridTable", { static: false }) gtable: CtTableComponent;
  @Input() inputData: any;
  @Input() parentPanel: GroupPanel;
  @Input() session: SessionComponent;
  public tableData: any;
  public form: ProgramForm;
  public programFd: FormData[];
  public vo: ProgramVo;
  public isCloseable: Array<boolean>;
  fd: FormData;
  actions: Array<any> = [];

  constructor(private sa: ServiceAgent, private cc: ClientContext, private ms: MessageService, private dialog: MatDialog) {
    this.form = ProgramForm.getInstance();
    this.fd = this.form.newFormData(this.sa);
  }

  ngOnInit() {
    this.filter();
  }

  filter() {
    /**
     * initial filter conditions
     */
    this.fd.filter().subscribe(
      data => {
        this.programFd = this.fd.toFdArray(data, this.form, this.sa);
        for (let i = 0; i < this.programFd.length; i++) {
          this.actions.push([false, false]);
        }
        this.actions[this.actions.length - 1][1] = true;
      },
      msgs => {

      }
    );

  }

  cancel() {
    let dialogRef = this.dialog.open(CtDialogComponent);
    dialogRef.componentInstance.title = 'Discard Changes';
    dialogRef.componentInstance.content = 'Are you sure you want to discard changes?';
    dialogRef.componentInstance.primary = 'Discard';
    const subscribeDialog = dialogRef.componentInstance.save.subscribe((data) => {
      this.dialog.closeAll();
      this.parentPanel.navigate('ProgramList');
    });

  }

  save() {
    this.ms.showInfo("Sorry this functionlity is disbled. Plese contct dmin");
    return;
    let dialogRef = this.dialog.open(CtDialogComponent);
    dialogRef.componentInstance.title = 'Save Changes';
    dialogRef.componentInstance.content = 'Are you sure you want to save changes?';
    dialogRef.componentInstance.primary = 'Save';
    const subscribeDialog = dialogRef.componentInstance.save.subscribe((data) => {
      this.doSavePrograms();
      this.dialog.closeAll();
    });
  }

  doSavePrograms() {
    let flag = true;
    this.programFd.forEach(prog => {
      if (prog.validateForm()) {

      }
      else {
        this.ms.showError("Fields in error");
        flag = false;
      }
    });
    if (flag) {
      let vo = this.fd.toVoArray(this.programFd);
      this.fd.bulkUpdate(vo).subscribe({
        next: vo => {
          this.ms.showSuccess("Program(s) saved successfully!")
          this.parentPanel.navigate("ProgramList");
        },
        error: err => {
          this.ms.showError("Fields in error");
        }
      });
    }
  }

  addProgram() {
    let vo = this.fd.toVoArray(this.programFd);
    const fd = this.form.newFormData(this.sa);
    fd.setFieldValue(this.form.departmentId.name, 1);
    fd.setFieldValue(this.form.degreeId.name, 2)
    this.programFd.push(fd);
    console.log(this.programFd);
    this.actions[this.actions.length - 1][1] = false;
    this.actions.push([true, true]);
  }

  removeProgram(i: number) {
    this.programFd.splice(i, 1);
    this.actions.splice(i, 1);
    this.actions[this.actions.length - 1][1] = true;
  }

}