import { Component,Input, OnInit } from "@angular/core";
import { DynamicPanel, GroupPanel } from '../../interfaces';
import { ServiceAgent } from 'src/app/framework-modules/formdata/form/serviceAgent';
import { SessionComponent } from '../../component';
import { FormData, TabularData } from 'src/app/framework-modules/formdata/form/formData';
import { SubjectForm, SubjectFd } from 'src/app/framework-modules/formdata/gen/subjectForm';
import { MessageService } from 'src/app/services/messageService';
import { MatDialog } from '@angular/material';
import { CtDialogComponent } from 'src/app/framework-modules/elements/ct-dialog/component';


@Component({
  selector: "subject-add",
  templateUrl: "./component.html",
  styleUrls: ["./component.scss"]
})


export class SubjectAddComponent implements DynamicPanel,OnInit{
  @Input() inputData: any;
  @Input() parentPanel: GroupPanel;
  @Input() session:SessionComponent;
  
  public form:SubjectForm;
  public fd:SubjectFd;
  public child: TabularData;
  public childSubjects:FormData[];
  public subjectTypes:string[] = ["Theory","Practical"];
  public showChildren = false;
  
  constructor(private sa:ServiceAgent,private ms:MessageService, private dialog: MatDialog){
    this.form = SubjectForm.getInstance();
    this.fd = new FormData(this.form,this.sa);
  }

  ngOnInit(){
    this.child = this.fd.getChildTable('childSubjects');
    this.childSubjects = this.child.childData as FormData[];
  }

  /**
   * To check if subject type selected is Integrated
   */
  checkIntegrated(){
    this.child.removeRow(0);
    this.child.removeRow(1);
    this.showChildren = false;
    this.fd.setFieldValue('isIntegrated', false);
    if(this.fd.getFieldValue('subjectTypeId')==4){
      this.child.appendRow();
      this.child.appendRow();
      this.fd.setFieldValue('isIntegrated', false);
      this.showChildren = true;
    }
  }

  /**
   * Set Required Fields to child with data from parent
   */
  setValuesToChild(){

    this.childSubjects.forEach((element:SubjectFd,index)=>{
      element.setFieldValue('departmentId',this.fd.getFieldValue('departmentId'));
      element.setFieldValue('code',this.fd.getFieldValue('code'));
      element.setFieldValue('isActive',this.fd.getFieldValue('isActive'));
      element.setFieldValue('isElective',this.fd.getFieldValue('isElective'));
      element.setFieldValue('isExamsRequired',this.fd.getFieldValue('isExamsRequired'));
      element.setFieldValue('isPrintedInHallticket',this.fd.getFieldValue('isPrintedInHallticket'));
      /**
       * Check if the subject needs exams
       */
      if(this.fd.getFieldValue('isExamsRequired')){
        element.setFieldValue('gradingSchemeId',1);
      }
      else{
        element.setFieldValue('gradingSchemeId',2);
      }
      /**
       * TO set values for theory integrated
       */
      if(index == 0){
        element.setFieldValue('name',this.fd.getFieldValue('name')+' - Lecture');
        element.setFieldValue('lectureCredits',this.fd.getFieldValue('lectureCredits'));
        element.setFieldValue('totalCredits',this.fd.getFieldValue('lectureCredits'));
        element.setFieldValue('subjectTypeId',1);
      }
      /**
       * TO set values for practical Integrated
       */
      else{
        element.setFieldValue('name',this.fd.getFieldValue('name')+' - Practical');
        element.setFieldValue('practicalCredits',this.fd.getFieldValue('practicalCredits'));
        element.setFieldValue('totalCredits',this.fd.getFieldValue('practicalCredits'));
        element.setFieldValue('subjectTypeId',2);
      }

    })
  }

  creditsChanged(){
    this.fd.setFieldValue('totalCredits',+this.fd.getFieldValue('practicalCredits') + +this.fd.getFieldValue('lectureCredits') + +this.fd.getFieldValue('tutorialCredits'))
  }

    /**
   * Adds a new subject to the institute
   */
  doAddSubject() {
    if(this.fd.getFieldValue('subjectTypeId')==4){
      this.setValuesToChild()
      this.fd.setFieldValue('isIntegrated',true);
    }
    if(this.fd.getFieldValue('isExamsRequired')){
      this.fd.setFieldValue('gradingSchemeId',1);
    }
    else{
      this.fd.setFieldValue('gradingSchemeId',2);
    }
    let dialogRef = this.dialog.open(CtDialogComponent);
    dialogRef.componentInstance.title = 'Save Changes';
    dialogRef.componentInstance.content = 'Are you sure you want to save changes?';
    dialogRef.componentInstance.primary = 'Save';
    const subscribeDialog = dialogRef.componentInstance.save.subscribe((data) => {
      this.dialog.closeAll();
      this.fd.saveAsNew().subscribe({
        next: vo => {
          this.ms.showSuccess("Subject " + this.fd.getFieldValue('name') + " added Successfully!")
        this.parentPanel.navigate("SubjectList");
        },
        error: err => {
          console.error(err);
        }
      });
    });
    
  }

  doCancel(){
    let dialogRef = this.dialog.open(CtDialogComponent);
    dialogRef.componentInstance.title = 'Discard Changes';
    dialogRef.componentInstance.content = 'Are you sure you want to discard changes?';
    dialogRef.componentInstance.primary = 'Discard';
    const subscribeDialog = dialogRef.componentInstance.save.subscribe((data) => {
      this.dialog.closeAll();    
      this.parentPanel.navigate("SubjectList");
    });
  }

}