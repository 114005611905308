import { Component, OnInit, ViewChild, Input } from "@angular/core";
import { DynamicPanel, GroupPanel } from '../../interfaces';
import { ServiceAgent } from 'src/app/framework-modules/formdata/form/serviceAgent';
import { CtTableComponent } from '../../../../framework-modules/elements/ct-table/component'
import { SessionComponent } from '../../component';
import { MessageService } from 'src/app/services/messageService';
import { SubjectSectionDetailVo } from 'src/app/framework-modules/formdata/gen/subjectSectionDetailForm';
import { MatDialog } from '@angular/material';
import { CtDialogComponent } from 'src/app/framework-modules/elements/ct-dialog/component';
import { AutoRegisterFd, AutoRegisterForm } from 'src/app/framework-modules/formdata/gen/autoRegisterForm';
import { DegreeProgramSelectionFd, DegreeProgramSelectionForm } from 'src/app/framework-modules/formdata/gen/degreeProgramSelectionForm';
import { Vo } from 'src/app/framework-modules/formdata/form/types';


@Component({
  selector: "subject-list",
  templateUrl: "./component.html",
  styleUrls: ["./component.scss"]
})


export class AutoRegisterEditComponent implements DynamicPanel, OnInit {
  @ViewChild("gridTable", { static: false }) gtable: CtTableComponent;
  @Input() inputData: any;
  @Input() parentPanel: GroupPanel;
  @Input() session: SessionComponent;

  public form: AutoRegisterForm;
  public fd: AutoRegisterFd;
  public programForm: DegreeProgramSelectionForm;
  public programFd: DegreeProgramSelectionFd;
  public levelSelected:boolean = false;
  public sectionList: SubjectSectionDetailVo[] = [];
  public isRegistered: boolean = false;
  programName: string;
  selectedProgram: any = 0;
  programs: DegreeProgramSelectionFd[] = [];
  actions: Array<any> = [];

  constructor(private sa: ServiceAgent, private ms: MessageService,public dialog:MatDialog) {
    this.form = AutoRegisterForm.getInstance();
    this.fd = this.form.newFormData(this.sa);
    this.programForm = DegreeProgramSelectionForm.getInstance();
    this.programFd = this.programForm.newFormData(this.sa);
  }

  ngOnInit() {
    this.fetchSubjectDetails();
  }

  fetchSubjectDetails(): void {
    if (!this.inputData) {
      console.error('Design error : Input for subjectId not received.');
      return;
    }
    if(this.selectedProgram == this.fd.getFieldValue('degreeId')){
      return;
    }
    this.fd.setFieldValue('subjectId', this.inputData);
    this.fd.fetchData().subscribe(
      data => {
        if(data.isOffered || data.degreeId!=0){
          this.levelSelected = true;
          this.selectedProgram = data.degreeId;
          this.isRegistered = data.isRegistered as boolean;
          this.programs = this.programFd.toFdArray(data.programs as Vo[], this.programForm, this.sa);
          this.programs.forEach(row=>{
            row.formGroup.disable();
            this.actions.push([false,false,false]);
          });
          if(this.programs.length == 0){
            var newFd = this.programForm.newFormData(this.sa);
            newFd.setFieldValue('degreeId',data.degreeId as number);
            newFd.setFieldValue('autoEnroll', false);
            this.programs.push(newFd);
            this.actions.push([false,true,true]);
          }
        }
      },
      msgs => {
        this.ms.showError('Server returned with Error : ' + msgs[0].text);
      }
    );
  }

  /**
   * save the changes
   */
  save() {
    let dialogRef = this.dialog.open(CtDialogComponent);
    dialogRef.componentInstance.title = 'Save Changes';
    dialogRef.componentInstance.content = 'Are you sure you want to save changes?';
    dialogRef.componentInstance.primary = 'Save';
    const subscribeDialog = dialogRef.componentInstance.save.subscribe((data) => {
      this.dialog.closeAll();
      this.doSave();
    });
    
  }

  private doSave(){
    var validations = this.validate();
    if(validations.valid){
      this.fd.childTabularData.get("programs").setAll(this.programFd.toVoArray(validations.validatedFd));
      this.fd.save().subscribe({
        next: data => {
          this.ms.showSuccess('Subject Offered to sections Successfully')
          this.parentPanel.navigate('AutoRegister');
        },
        error: err => {
          if(err){
            if(err[0].type == 'error'){
              this.ms.showError('Server returned with Error : ' + err[0].text);
            }
          }
        }
      });
    }
    else{
      this.ms.showError(validations.text);
    }
  }

  private validate(){
    var validatedFd: DegreeProgramSelectionFd[] = []
    var flag = true;
    var text = 'Fields in error!';
    this.programs.forEach(program=>{
      if(!program.formGroup.disabled){
        flag = flag && program.validateForm();
        validatedFd.push(program);
      }
    });
    if(flag){
      const unique = new Set();
      if(this.programs.some(element => unique.size === unique.add(element.getFieldValue('programId')).size)){
        flag = false;
        text = "Duplicate Programs Present! Please remove duplicates.";
      }
    }
    return {valid:flag,validatedFd:validatedFd,text:text};
  }

  /**
   * user discards changes
   */
  cancel(){
    let dialogRef = this.dialog.open(CtDialogComponent);
    dialogRef.componentInstance.title = 'Discard Changes';
    dialogRef.componentInstance.content = 'Are you sure you want to discard changes?';
    dialogRef.componentInstance.primary = 'Discard';
    const subscribeDialog = dialogRef.componentInstance.save.subscribe((data) => {
      this.dialog.closeAll();    
      this.parentPanel.navigate('AutoRegister');
    });
  }

  toggleAutoEnrolment($event,index){
    this.programs[index].setFieldValue('autoEnroll',$event.checked);
  }

  removeSection(i){
    this.programs.splice(i,1);
    this.actions.splice(i, 1);
    this.actions[this.actions.length - 1][1] = true;
  }

  addSection(){
    var newFd = this.programForm.newFormData(this.sa);
    newFd.setFieldValue('degreeId',this.fd.getFieldValue('degreeId'));
    newFd.setFieldValue('autoEnroll', false);
    this.programs.push(newFd);
    this.actions[this.actions.length - 1][1] = false;
    this.actions.push([true, true, true]);
  }
}