
import { Form , Field, ChildForm } from '../form/form';
import { FormData } from '../form/formData';
import { SelectOption, Vo } from '../form/types';
import { Validators } from '@angular/forms'
import { ServiceAgent} from '../form/serviceAgent';
import { SubjectwiseResultForm, SubjectwiseResultVo } from './subjectwiseResultForm';
import { GuardianForm, GuardianVo } from './guardianForm';

export class GradeCardStudentDetailForm extends Form {
	private static _instance = new GradeCardStudentDetailForm();
	instituteId:Field = {
		name:'instituteId'
		,controlType: 'Hidden'
		,label: 'instituteId'
		,valueType: 1
		,errorId: 'invalidTenentKey'
		,maxValue: 9999999999999
	};
	studentId:Field = {
		name:'studentId'
		,controlType: 'Hidden'
		,label: 'studentId'
		,isRequired: true
		,valueType: 1
		,errorId: 'invalidId'
		,maxValue: 9999999999999
	};
	levelId:Field = {
		name:'levelId'
		,controlType: 'Hidden'
		,label: 'levelId'
		,isRequired: true
		,valueType: 1
		,errorId: 'invalidId'
		,maxValue: 9999999999999
	};
	programId:Field = {
		name:'programId'
		,controlType: 'Hidden'
		,label: 'programId'
		,isRequired: true
		,valueType: 1
		,errorId: 'invalidId'
		,maxValue: 9999999999999
	};
	seasonId:Field = {
		name:'seasonId'
		,controlType: 'Hidden'
		,label: 'seasonId'
		,isRequired: true
		,valueType: 1
		,errorId: 'invalidId'
		,maxValue: 9999999999999
	};
	degreeId:Field = {
		name:'degreeId'
		,controlType: 'Hidden'
		,label: 'degreeId'
		,isRequired: true
		,valueType: 1
		,errorId: 'invalidId'
		,maxValue: 9999999999999
	};
	studentName:Field = {
		name:'studentName'
		,controlType: 'Hidden'
		,label: 'studentName'
		,isRequired: true
		,valueType: 0
		,errorId: 'invalidText'
		,maxLength: 1000
	};
	usn:Field = {
		name:'usn'
		,controlType: 'Hidden'
		,label: 'usn'
		,isRequired: true
		,valueType: 0
		,errorId: 'invalidText'
		,maxLength: 1000
	};
	levelName:Field = {
		name:'levelName'
		,controlType: 'Hidden'
		,label: 'levelName'
		,isRequired: true
		,valueType: 0
		,errorId: 'invalidText'
		,maxLength: 1000
	};
	seasonName:Field = {
		name:'seasonName'
		,controlType: 'Hidden'
		,label: 'seasonName'
		,isRequired: true
		,valueType: 0
		,errorId: 'invalidText'
		,maxLength: 1000
	};
	departmentName:Field = {
		name:'departmentName'
		,controlType: 'Hidden'
		,label: 'departmentName'
		,isRequired: true
		,valueType: 0
		,errorId: 'invalidText'
		,maxLength: 1000
	};
	cgpa:Field = {
		name:'cgpa'
		,controlType: 'Hidden'
		,label: 'cgpa'
		,isRequired: true
		,valueType: 0
		,errorId: 'invalidText'
		,maxLength: 1000
	};
	sgpa:Field = {
		name:'sgpa'
		,controlType: 'Hidden'
		,label: 'sgpa'
		,isRequired: true
		,valueType: 0
		,errorId: 'invalidText'
		,maxLength: 1000
	};
	totalCredits:Field = {
		name:'totalCredits'
		,controlType: 'Hidden'
		,label: 'totalCredits'
		,isRequired: true
		,valueType: 0
		,errorId: 'invalidText'
		,maxLength: 1000
	};
	registeredCredits:Field = {
		name:'registeredCredits'
		,controlType: 'Hidden'
		,label: 'registeredCredits'
		,isRequired: true
		,valueType: 0
		,errorId: 'invalidText'
		,maxLength: 1000
	};
	obtainedCredits:Field = {
		name:'obtainedCredits'
		,controlType: 'Hidden'
		,label: 'obtainedCredits'
		,isRequired: true
		,valueType: 0
		,errorId: 'invalidText'
		,maxLength: 1000
	};
	barcode:Field = {
		name:'barcode'
		,controlType: 'Hidden'
		,label: 'barcode'
		,isRequired: true
		,valueType: 0
		,errorId: 'invalidText'
		,maxLength: 1000
	};
	sessionName:Field = {
		name:'sessionName'
		,controlType: 'Hidden'
		,label: 'sessionName'
		,valueType: 0
		,errorId: 'invalidText'
		,maxLength: 1000
	};
	academicYear:Field = {
		name:'academicYear'
		,controlType: 'Hidden'
		,label: 'academicYear'
		,valueType: 0
		,errorId: 'invalidText'
		,maxLength: 1000
	};
	studentTypeId:Field = {
		name:'studentTypeId'
		,controlType: 'Hidden'
		,label: 'studentTypeId'
		,valueType: 1
		,errorId: 'invalidId'
		,maxValue: 9999999999999
	};

	academicDetails: ChildForm = {
		name:'academicDetails',
		form:SubjectwiseResultForm.getInstance(),
		isEditable:false,
		isTabular:true,
		label:'',
		minRows:0,
		maxRows:0,
		errorId:null
	};
	guardianDetails: ChildForm = {
		name:'guardianDetails',
		form:GuardianForm.getInstance(),
		isEditable:false,
		isTabular:true,
		label:'',
		minRows:0,
		maxRows:0,
		errorId:null
	};

	public static getInstance(): GradeCardStudentDetailForm {
		return GradeCardStudentDetailForm._instance;
	}

	constructor() {
		super();
		this.fields = new Map();
		this.controls = new Map();
		this.controls.set('instituteId', [Validators.max(9999999999999)]);
		this.fields.set('instituteId', this.instituteId);
		this.controls.set('studentId', [Validators.required, Validators.max(9999999999999)]);
		this.fields.set('studentId', this.studentId);
		this.controls.set('levelId', [Validators.required, Validators.max(9999999999999)]);
		this.fields.set('levelId', this.levelId);
		this.controls.set('programId', [Validators.required, Validators.max(9999999999999)]);
		this.fields.set('programId', this.programId);
		this.controls.set('seasonId', [Validators.required, Validators.max(9999999999999)]);
		this.fields.set('seasonId', this.seasonId);
		this.controls.set('degreeId', [Validators.required, Validators.max(9999999999999)]);
		this.fields.set('degreeId', this.degreeId);
		this.controls.set('studentName', [Validators.required, Validators.maxLength(1000)]);
		this.fields.set('studentName', this.studentName);
		this.controls.set('usn', [Validators.required, Validators.maxLength(1000)]);
		this.fields.set('usn', this.usn);
		this.controls.set('levelName', [Validators.required, Validators.maxLength(1000)]);
		this.fields.set('levelName', this.levelName);
		this.controls.set('seasonName', [Validators.required, Validators.maxLength(1000)]);
		this.fields.set('seasonName', this.seasonName);
		this.controls.set('departmentName', [Validators.required, Validators.maxLength(1000)]);
		this.fields.set('departmentName', this.departmentName);
		this.controls.set('cgpa', [Validators.required, Validators.maxLength(1000)]);
		this.fields.set('cgpa', this.cgpa);
		this.controls.set('sgpa', [Validators.required, Validators.maxLength(1000)]);
		this.fields.set('sgpa', this.sgpa);
		this.controls.set('totalCredits', [Validators.required, Validators.maxLength(1000)]);
		this.fields.set('totalCredits', this.totalCredits);
		this.controls.set('registeredCredits', [Validators.required, Validators.maxLength(1000)]);
		this.fields.set('registeredCredits', this.registeredCredits);
		this.controls.set('obtainedCredits', [Validators.required, Validators.maxLength(1000)]);
		this.fields.set('obtainedCredits', this.obtainedCredits);
		this.controls.set('barcode', [Validators.required, Validators.maxLength(1000)]);
		this.fields.set('barcode', this.barcode);
		this.controls.set('sessionName', [Validators.maxLength(1000)]);
		this.fields.set('sessionName', this.sessionName);
		this.controls.set('academicYear', [Validators.maxLength(1000)]);
		this.fields.set('academicYear', this.academicYear);
		this.controls.set('studentTypeId', [Validators.max(9999999999999)]);
		this.fields.set('studentTypeId', this.studentTypeId);

		this.childForms = new Map();
		this.childForms.set('academicDetails', this.academicDetails);
		this.childForms.set('guardianDetails', this.guardianDetails);
		this.opsAllowed = {get: true, filter: true};
		this.keyFields = ["studentId"];
	}

	public getName(): string {
		 return 'gradeCardStudentDetail';
	}
}


export class GradeCardStudentDetailFd extends FormData {
	constructor(form: GradeCardStudentDetailForm, sa: ServiceAgent) {
		super(form, sa);
	}

	setFieldValue(name: 'instituteId' | 'studentId' | 'levelId' | 'programId' | 'seasonId' | 'degreeId' | 'studentName' | 'usn' | 'levelName' | 'seasonName' | 'departmentName' | 'cgpa' | 'sgpa' | 'totalCredits' | 'registeredCredits' | 'obtainedCredits' | 'barcode' | 'sessionName' | 'academicYear' | 'studentTypeId', value: string | number | boolean | null ): void {
		super.setFieldValue(name, value);
	}

	getFieldValue(name: 'instituteId' | 'studentId' | 'levelId' | 'programId' | 'seasonId' | 'degreeId' | 'studentName' | 'usn' | 'levelName' | 'seasonName' | 'departmentName' | 'cgpa' | 'sgpa' | 'totalCredits' | 'registeredCredits' | 'obtainedCredits' | 'barcode' | 'sessionName' | 'academicYear' | 'studentTypeId' ): string | number | boolean | null {
		return super.getFieldValue(name);
	}
}


export interface GradeCardStudentDetailVo extends Vo {
	departmentName?: string, 
	registeredCredits?: string, 
	degreeId?: number, 
	obtainedCredits?: string, 
	sessionName?: string, 
	studentTypeId?: number, 
	levelName?: string, 
	sessionSemester?: string, 
	studentId?: number, 
	sgpa?: string, 
	usn?: string, 
	totalCredits?: string, 
	academicYear?: string, 
	seasonId?: number, 
	levelId?: number, 
	seasonName?: string, 
	studentName?: string, 
	instituteId?: number, 
	cgpa?: string, 
	barcode?: string, 
	programId?: number, 
	academicDetails?: SubjectwiseResultVo, 
	guardianDetails?: GuardianVo
}
