
import { Form , Field, ChildForm } from '../form/form';
import { FormData } from '../form/formData';
import { SelectOption, Vo } from '../form/types';
import { Validators } from '@angular/forms'
import { ServiceAgent} from '../form/serviceAgent';

export class SubjectDetailForm extends Form {
	private static _instance = new SubjectDetailForm();

	public static getInstance(): SubjectDetailForm {
		return SubjectDetailForm._instance;
	}

	constructor() {
		super();
		this.fields = new Map();
		this.controls = new Map();
		this.opsAllowed = {get: true, update: true, filter: true};
		this.keyFields = ["seasonId", "subjectId"];
	}

	public getName(): string {
		 return 'subjectDetail';
	}
}


export class SubjectDetailFd extends FormData {
	constructor(form: SubjectDetailForm, sa: ServiceAgent) {
		super(form, sa);
	}
	/**  this form has no editable fields. data nust be accessed as Vo and not through fd **/
}


export interface SubjectDetailVo extends Vo {
	departmentName?: string, 
	isOffered?: boolean, 
	code?: string, 
	seasonId?: number, 
	departmentId?: number, 
	levelId?: number, 
	name?: string, 
	instituteId?: number, 
	levelName?: string, 
	subjectId?: number
}
