import { Component, OnInit, Input, ViewChild, ViewContainerRef, Type, ComponentFactoryResolver } from "@angular/core";
import { DynamicPanel, LandingPanel, GroupPanel } from '../interfaces'
import { InsituteListComponent } from "./institute-list/component";
import { SessionComponent, SecondaryMenu } from '../component';
import { InstituteEditComponent } from './institute-edit/component';
import { InstituteAddComponent } from './institute-add/component';
import { InstituteViewComponent } from './institute-view/component';
import { DepartmentAddComponent } from './department-add/component';
import { DepartmentEditComponent } from './department-edit/component';
import { DepartmentListComponent } from './department-list/component';
import { ProgramListComponent } from './program-list/component';
import { ProgramAddComponent } from './program-add/component';
import { LevelListComponent } from './level-list/component';
import { MenuService } from 'src/app/services/menuService';
import { MessageService } from 'src/app/services/messageService';
import { ClientContext } from 'src/app/framework-modules/formdata/form/clientContext';
import { EnrollmentsListComponent } from './enrollments-list/component';
import { EnrollmentsAddComponent } from './enrollments-add/component';
import { ChangeProgramComponent } from './program-change/component';
import { ChangeProgramEditComponent } from './program-change-edit/component';
import { AssessmentSchemeListComponent } from './assessment-scheme-list/component';
import { AssessmentSchemeAddComponent } from './assessment-scheme-add/component';

/**
 Const to map the screen-component to screen-name to rended the facotry
 */
const MENU: SecondaryMenu = {
  InstituteList: { comp: InsituteListComponent, title: "List of Institutes", sideNav: false, pageId: "D05", displayonSidenav: false, mainNav: true },
  InstituteAdd: { comp: InstituteAddComponent, title: "Add A New Institute", sideNav: false, pageId: "D06", displayonSidenav: false, mainNav: false },
  InstituteEdit: { comp: InstituteEditComponent, title: "Edit Institute Details", sideNav: false, pageId: "D07", displayonSidenav: false, mainNav: false },
  InstituteView: { comp: InstituteViewComponent, title: "View Institue Details", sideNav: false, pageId: "D08", displayonSidenav: false, mainNav: true },
  DepartmentList: { comp: DepartmentListComponent, title: "List Of Departments", sideNav: true, pageId: "D09", displayonSidenav: true, name: "Department", mainNav: true, order:1 },
  DepartmentAdd: { comp: DepartmentAddComponent, title: "Add a new Department", sideNav: false, pageId: "D10", displayonSidenav: false, mainNav: true },
  DepartmentEdit: { comp: DepartmentEditComponent, title: "Edit  Department Details", sideNav: false, pageId: "D11", displayonSidenav: false, mainNav: false },
  ProgramList: { comp: ProgramListComponent, title: "List of Programs", sideNav: true, pageId: "D12", displayonSidenav: true, name: "Programs", mainNav: true, order:2},
  ProgramAdd: { comp: ProgramAddComponent, title: "Add a new Program", sideNav: false, pageId: "D13", displayonSidenav: false,  mainNav: false },
  LevelList: { comp: LevelListComponent, title: "List of Levels", sideNav: true, pageId: "D14", displayonSidenav: true, name: "Levels", mainNav: true, order:3 },
  EnrollmentsList: { comp:EnrollmentsListComponent, title: "Enroll students", sideNav: true, pageId: "D130", displayonSidenav: true, name:"Enrollments", mainNav: true, order: 4},
  EnrollmentsAdd: { comp:EnrollmentsAddComponent, title: "Enroll students", sideNav: false, pageId: "D131", displayonSidenav: false, mainNav: false},
  ChangeProgram: { comp: ChangeProgramComponent, title: "Change Student's Program", sideNav: true, pageId: "D137", displayonSidenav: true, mainNav: true, name: "Change Program", order:5},
  ChangeProgramEdit: { comp: ChangeProgramEditComponent, title: "Change Student's Program", sideNav: false, pageId: "D137", displayonSidenav: false, mainNav: false},
  AssessmentSchemeList: { comp: AssessmentSchemeListComponent, title: "List of Assessment Scheme's", sideNav: true, pageId: "D139", displayonSidenav: true, mainNav: true, name: "Assessment Schemes", order:6},
  AssessmentSchemeAdd: { comp: AssessmentSchemeAddComponent, title: "Add a New Assessment Scheme", sideNav: false, pageId: "D140", displayonSidenav: false, mainNav: false},
};

@Component({
  selector: "institutes",
  templateUrl: "./component.html",
  styleUrls: ["./component.scss"]
})
export class InstituteComponent implements OnInit, GroupPanel {
  @Input() inputData: any;
  @Input() parentPanel: SessionComponent;
  sidenav: boolean = false;
  displayNames: {}
  cardHeader: any;
  defaultSelected: string;
  public items: {};
  screens: string[];
  defaultComponent: any;
  /**
    container for the child page loaded from the seconday navbar
   */

  @ViewChild("secondaryContainer", { static: true, read: ViewContainerRef }) container: ViewContainerRef;
  constructor(private componentFactoryResolver: ComponentFactoryResolver, private mns: MenuService, private ms: MessageService, private cc: ClientContext) {
    this.screens = this.mns.getScreens();
      Object.keys(MENU).forEach( key => {
      if(this.screens.includes(MENU[key].pageId) && this.defaultSelected == undefined){
        this.defaultSelected = MENU[key].name;
        this.cardHeader = MENU[key].title;
        this.defaultComponent = key;
      }
    })
   }

  ngOnInit() {
    this.navigate(this.defaultComponent, {});
    this.items = MENU;
  }

  /**
   *
   * @param compName is the name of the component to navigate to
   * @param data is for any related data needed
   */

  public navigate(compName: string, data: any): void {
    let m = MENU[compName]
    if(!this.cc.isPageValid(m.pageId)){
      this.ms.showInfo('Navigation Error: ' + compName + ' is not authorized for this user.');
      return;
    }
    this.parentPanel.toggleMainNav(m.mainNav);
    this.sidenav = m.sideNav;
    if (m.name != undefined) {
      this.defaultSelected = m.name;
    }
    this.cardHeader = m.title;
    this.container.clear();
    const factory = this.componentFactoryResolver.resolveComponentFactory(
      m.comp
    );
    const c = this.container.createComponent(factory);
    c.instance.inputData = data;
    c.instance.parentPanel = this;
    c.changeDetectorRef.detectChanges();
  }
  fullScreenMode(){
    this.sidenav = false;
    this.parentPanel.hideMainNav();
  }

  halfScreenMode(){
    this.sidenav = true;
    this.parentPanel.showMainNav();
  }
}
