import { Component, OnInit, ViewChild, Input } from "@angular/core";
import { GroupPanel } from '../../interfaces';
import { ServiceAgent } from 'src/app/framework-modules/formdata/form/serviceAgent';
import { PanelData } from 'src/app/framework-modules/formdata/form/formData';
import { SessionComponent } from '../../component';
import { CtCheckboxTableComponent } from 'src/app/framework-modules/elements/ct-checkbox-table/component';
import { CondoneAttendanceForm, CondoneAttendanceVo } from 'src/app/framework-modules/formdata/gen/condoneAttendanceForm';
import { ClientContext } from 'src/app/framework-modules/formdata/form/clientContext';
import { MatDialog } from '@angular/material';
import { CtDialogComponent } from 'src/app/framework-modules/elements/ct-dialog/component';
import { MessageService } from 'src/app/services/messageService';
import { TableMetaData } from 'src/app/framework-modules/elements/ct-table/component';

@Component({
  selector: "eligibility-list",
  templateUrl: "./component.html",
  styleUrls: ["./component.scss"]
})


export class CondoneAttendanceComponent implements OnInit {
  @ViewChild("gridTable", { static: false }) gtable: CtCheckboxTableComponent;
  @Input() inputData: any;
  @Input() parentPanel: GroupPanel;
  @Input() session: SessionComponent;

  public from: CondoneAttendanceForm;
  public fd: PanelData;
  public vo: CondoneAttendanceVo;
  public tableData: TableMetaData;


  constructor(private sa: ServiceAgent, private cc: ClientContext, private ms: MessageService, private dialog: MatDialog) {
    this.from = CondoneAttendanceForm.getInstance();
    this.fd = this.from.newPanelData(this.sa);
    this.vo = this.fd.getRawData();
  }
  ngOnInit() {
    this.metaDataInit();
    if (!this.inputData) {
      this.ms.showError('Page invocation error. No data recieved for this page.');
    } else {
      this.fetchData(this.inputData);
    }
  }

  metaDataInit() {
    this.tableData = {
      data: [],
      metaData: {
        column_names: [
          "name",
          "usn",
          "overallAttendance"
        ],
        display_names: {
          "name": "Student Name",
          "usn": "USN",
          "overallAttendance": "Overall Attendance"
        },
        "selectAttr": "isCondoned",
        managerFunction: "CONDONE ATTENDANCE",
        itemName: "Students"
      },
      
    };
  }

  fetchData(id) {
    this.vo.subjectSectionId = id;
    this.fd.fetchData().subscribe(
      data => {
        this.vo = this.fd.getRawData();
        const students = data.students as [];

        if (!students || !students.length) {
          this.ms.showInfo('No students for condonation');
        } else {
          console.log(students.length + ' students listed');
          this.tableData.data = students;
          this.gtable.update();
        }
      }
    );
  }

  ngAfterViewInit() {
    //
  }

  condoneAttendance() {
    let dialogRef = this.dialog.open(CtDialogComponent);
    dialogRef.componentInstance.title = 'Save Changes';
    dialogRef.componentInstance.content = 'Are you sure you want to save changes?';
    dialogRef.componentInstance.primary = 'Save';
    const subscribeDialog = dialogRef.componentInstance.save.subscribe((data) => {
      this.dialog.closeAll();
      this.saveData();
    });

  }

  private saveData() {
    this.fd.save().subscribe({
      next: data => {
        this.ms.showSuccess("Updated Successfully!");
        this.parentPanel.navigate('CondoneAttendanceList');
      }
    });
  }

  cancel() {
    let dialogRef = this.dialog.open(CtDialogComponent);
    dialogRef.componentInstance.title = 'Discard Changes';
    dialogRef.componentInstance.content = 'Are you sure you want to discard changes?';
    dialogRef.componentInstance.primary = 'Discard';
    const subscribeDialog = dialogRef.componentInstance.save.subscribe((data) => {
      this.dialog.closeAll();
      this.parentPanel.navigate('CondoneAttendanceList')
    });

  }
}