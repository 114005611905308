
import { Form , Field, ChildForm } from '../form/form';
import { FormData } from '../form/formData';
import { SelectOption, Vo } from '../form/types';
import { Validators } from '@angular/forms'
import { ServiceAgent} from '../form/serviceAgent';

export class FormAConfigForm extends Form {
	private static _instance = new FormAConfigForm();
	examTimings:Field = {
		name:'examTimings'
		,controlType: 'Dropdown'
		,label: 'Exam Timings'
		,isRequired: true
		,listName: 'examTimeList'
		,valueList: [
			{value:'9:30 am to 12:00 pm',text:'9:30 am to 12:00 pm'},
			{value:'9:30am to 12:30pm',text:'9:30am to 12:30pm'},
			{value:'1:30pm to 5:30pm',text:'1:30pm to 5:30pm'},
			{value:'10:00 am to 1:00 pm',text:'10:00 am to 1:00 pm'},
			{value:'2:00 pm to 4:00 pm',text:'2:00 pm to 4:00 pm'},
			{value:'2:00 pm to 4:30 pm',text:'2:00 pm to 4:30 pm'},
			{value:'2:00pm to 5:00pm',text:'2:00pm to 5:00pm'}
			]
		,valueType: 1
		,errorId: 'invalidId'
		,maxValue: 9999999999999
	};
	dateOfIssue:Field = {
		name:'dateOfIssue'
		,controlType: 'Input'
		,label: 'Issue Date'
		,isRequired: true
		,valueType: 4
		,errorId: 'invalidDate'
		,minValue: 73000
		,maxValue: 73000
	};
	departmentWise:Field = {
		name:'departmentWise'
		,controlType: 'Input'
		,label: 'Download Program-Wise'
		,valueType: 3
		,defaultValue: false
		,errorId: 'invalidBool'
	};

	public static getInstance(): FormAConfigForm {
		return FormAConfigForm._instance;
	}

	constructor() {
		super();
		this.fields = new Map();
		this.controls = new Map();
		this.controls.set('examTimings', [Validators.required, Validators.max(9999999999999)]);
		this.fields.set('examTimings', this.examTimings);
		this.controls.set('dateOfIssue', [Validators.required]);
		this.fields.set('dateOfIssue', this.dateOfIssue);
		this.controls.set('departmentWise', []);
		this.fields.set('departmentWise', this.departmentWise);
		this.opsAllowed = {get: true, filter: true};
		this.listFields = ['examTimings'];
	}

	public getName(): string {
		 return 'formAConfig';
	}
}


export class FormAConfigFd extends FormData {
	constructor(form: FormAConfigForm, sa: ServiceAgent) {
		super(form, sa);
	}

	setFieldValue(name: 'examTimings' | 'dateOfIssue' | 'departmentWise', value: string | number | boolean | null ): void {
		super.setFieldValue(name, value);
	}

	getFieldValue(name: 'examTimings' | 'dateOfIssue' | 'departmentWise' ): string | number | boolean | null {
		return super.getFieldValue(name);
	}
}


export interface FormAConfigVo extends Vo {
	dateOfIssue?: string, 
	examTimings?: number, 
	departmentWise?: boolean
}
