
import { Form , Field, ChildForm } from '../form/form';
import { FormData } from '../form/formData';
import { SelectOption, Vo } from '../form/types';
import { Validators } from '@angular/forms'
import { ServiceAgent} from '../form/serviceAgent';
import { CieStudentDetailForm, CieStudentDetailVo } from './cieStudentDetailForm';

export class CieSectionDetailForm extends Form {
	private static _instance = new CieSectionDetailForm();

	students: ChildForm = {
		name:'students',
		form:CieStudentDetailForm.getInstance(),
		isEditable:false,
		isTabular:true,
		label:'',
		minRows:0,
		maxRows:0,
		errorId:null
	};

	public static getInstance(): CieSectionDetailForm {
		return CieSectionDetailForm._instance;
	}

	constructor() {
		super();
		this.fields = new Map();
		this.controls = new Map();

		this.childForms = new Map();
		this.childForms.set('students', this.students);
		this.opsAllowed = {get: true, filter: true};
		this.keyFields = ["subjectSectionId"];
	}

	public getName(): string {
		 return 'cieSectionDetail';
	}
}


export class CieSectionDetailFd extends FormData {
	constructor(form: CieSectionDetailForm, sa: ServiceAgent) {
		super(form, sa);
	}
	/**  this form has no editable fields. data nust be accessed as Vo and not through fd **/
}


export interface CieSectionDetailVo extends Vo {
	levelSectionId?: number, 
	departmentId?: number, 
	attendanceFrozen?: boolean, 
	cieFrozen?: boolean, 
	sectionId?: number, 
	subjectId?: number, 
	parentId?: number, 
	sectionName?: string, 
	offeredSubjectId?: number, 
	isOffered?: boolean, 
	totalClasses?: number, 
	seasonId?: number, 
	subjectSectionId?: number, 
	instituteId?: number, 
	isIntegrated?: boolean, 
	subjectCode?: string, 
	subjectName?: string, 
	students?: CieStudentDetailVo
}
