
import { Form , Field, ChildForm } from '../form/form';
import { FormData } from '../form/formData';
import { SelectOption, Vo } from '../form/types';
import { Validators } from '@angular/forms'
import { ServiceAgent} from '../form/serviceAgent';

export class ChildSubjectForm extends Form {
	private static _instance = new ChildSubjectForm();
	subjectId:Field = {
		name:'subjectId'
		,controlType: 'Hidden'
		,label: 'subjectId'
		,valueType: 1
		,errorId: 'invalidId'
		,maxValue: 9999999999999
	};
	instituteId:Field = {
		name:'instituteId'
		,controlType: 'Hidden'
		,label: 'instituteId'
		,valueType: 1
		,errorId: 'invalidTenentKey'
		,maxValue: 9999999999999
	};
	name:Field = {
		name:'name'
		,controlType: 'Input'
		,label: 'Subject Name'
		,isRequired: true
		,valueType: 0
		,errorId: 'invalidName'
		,maxLength: 150
	};
	code:Field = {
		name:'code'
		,controlType: 'Input'
		,label: 'Subject Code'
		,isRequired: true
		,valueType: 0
		,errorId: 'invalidCode'
		,maxLength: 50
	};
	departmentId:Field = {
		name:'departmentId'
		,controlType: 'Dropdown'
		,label: 'Offering Department'
		,isRequired: true
		,listName: 'departmentList'
		,valueType: 1
		,errorId: 'invalidId'
		,maxValue: 9999999999999
	};
	subjectTypeId:Field = {
		name:'subjectTypeId'
		,controlType: 'Dropdown'
		,label: 'Type of Subject'
		,isRequired: true
		,listName: 'subjectTypeList'
		,valueType: 1
		,errorId: 'invalidId'
		,maxValue: 9999999999999
	};
	lectureCredits:Field = {
		name:'lectureCredits'
		,controlType: 'Input'
		,label: 'Lecture Credits'
		,isRequired: true
		,valueType: 1
		,defaultValue: 0
		,errorId: 'invalidInteger'
		,maxValue: 9999999999999
	};
	practicalCredits:Field = {
		name:'practicalCredits'
		,controlType: 'Input'
		,label: 'Practical Credits'
		,isRequired: true
		,valueType: 1
		,defaultValue: 0
		,errorId: 'invalidInteger'
		,maxValue: 9999999999999
	};
	tutorialCredits:Field = {
		name:'tutorialCredits'
		,controlType: 'Input'
		,label: 'Tutorial Credits'
		,isRequired: true
		,valueType: 1
		,defaultValue: 0
		,errorId: 'invalidInteger'
		,maxValue: 9999999999999
	};
	totalCredits:Field = {
		name:'totalCredits'
		,controlType: 'Input'
		,label: 'Total Credits'
		,isRequired: true
		,valueType: 1
		,errorId: 'invalidInteger'
		,maxValue: 9999999999999
	};
	isActive:Field = {
		name:'isActive'
		,controlType: 'Input'
		,label: 'Is Active'
		,valueType: 3
		,defaultValue: true
		,errorId: 'invalidBool'
	};
	isElective:Field = {
		name:'isElective'
		,controlType: 'Input'
		,label: 'Is Elective'
		,valueType: 3
		,defaultValue: false
		,errorId: 'invalidBool'
	};
	parentId:Field = {
		name:'parentId'
		,controlType: 'Hidden'
		,label: 'parentId'
		,valueType: 1
		,defaultValue: 0
		,errorId: 'invalidId'
		,maxValue: 9999999999999
	};
	createdAt:Field = {
		name:'createdAt'
		,controlType: 'Hidden'
		,label: 'createdAt'
		,valueType: 5
		,errorId: 'invalidTimestamp'
	};
	updatedAt:Field = {
		name:'updatedAt'
		,controlType: 'Hidden'
		,label: 'updatedAt'
		,valueType: 5
		,errorId: 'invalidTimestamp'
	};

	public static getInstance(): ChildSubjectForm {
		return ChildSubjectForm._instance;
	}

	constructor() {
		super();
		this.fields = new Map();
		this.controls = new Map();
		this.controls.set('subjectId', [Validators.max(9999999999999)]);
		this.fields.set('subjectId', this.subjectId);
		this.controls.set('instituteId', [Validators.max(9999999999999)]);
		this.fields.set('instituteId', this.instituteId);
		this.controls.set('name', [Validators.required, Validators.maxLength(150)]);
		this.fields.set('name', this.name);
		this.controls.set('code', [Validators.required, Validators.maxLength(50)]);
		this.fields.set('code', this.code);
		this.controls.set('departmentId', [Validators.required, Validators.max(9999999999999)]);
		this.fields.set('departmentId', this.departmentId);
		this.controls.set('subjectTypeId', [Validators.required, Validators.max(9999999999999)]);
		this.fields.set('subjectTypeId', this.subjectTypeId);
		this.controls.set('lectureCredits', [Validators.required, Validators.max(9999999999999)]);
		this.fields.set('lectureCredits', this.lectureCredits);
		this.controls.set('practicalCredits', [Validators.required, Validators.max(9999999999999)]);
		this.fields.set('practicalCredits', this.practicalCredits);
		this.controls.set('tutorialCredits', [Validators.required, Validators.max(9999999999999)]);
		this.fields.set('tutorialCredits', this.tutorialCredits);
		this.controls.set('totalCredits', [Validators.required, Validators.max(9999999999999)]);
		this.fields.set('totalCredits', this.totalCredits);
		this.controls.set('isActive', []);
		this.fields.set('isActive', this.isActive);
		this.controls.set('isElective', []);
		this.fields.set('isElective', this.isElective);
		this.controls.set('parentId', [Validators.max(9999999999999)]);
		this.fields.set('parentId', this.parentId);
		this.controls.set('createdAt', []);
		this.fields.set('createdAt', this.createdAt);
		this.controls.set('updatedAt', []);
		this.fields.set('updatedAt', this.updatedAt);
		this.opsAllowed = {get: true, create: true, update: true, filter: true};
		this.listFields = ['departmentId','subjectTypeId'];
		this.keyFields = ["subjectId"];
	}

	public getName(): string {
		 return 'childSubject';
	}
}


export class ChildSubjectFd extends FormData {
	constructor(form: ChildSubjectForm, sa: ServiceAgent) {
		super(form, sa);
	}

	setFieldValue(name: 'subjectId' | 'instituteId' | 'name' | 'code' | 'departmentId' | 'subjectTypeId' | 'lectureCredits' | 'practicalCredits' | 'tutorialCredits' | 'totalCredits' | 'isActive' | 'isElective' | 'parentId' | 'createdAt' | 'updatedAt', value: string | number | boolean | null ): void {
		super.setFieldValue(name, value);
	}

	getFieldValue(name: 'subjectId' | 'instituteId' | 'name' | 'code' | 'departmentId' | 'subjectTypeId' | 'lectureCredits' | 'practicalCredits' | 'tutorialCredits' | 'totalCredits' | 'isActive' | 'isElective' | 'parentId' | 'createdAt' | 'updatedAt' ): string | number | boolean | null {
		return super.getFieldValue(name);
	}
}


export interface ChildSubjectVo extends Vo {
	isElective?: boolean, 
	code?: string, 
	lectureCredits?: number, 
	practicalCredits?: number, 
	departmentId?: number, 
	tutorialCredits?: number, 
	isActive?: boolean, 
	subjectId?: number, 
	parentId?: number, 
	createdAt?: string, 
	totalCredits?: number, 
	name?: string, 
	instituteId?: number, 
	subjectTypeId?: number, 
	updatedAt?: string
}
