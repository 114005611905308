
import { Form , Field, ChildForm } from '../form/form';
import { FormData } from '../form/formData';
import { SelectOption, Vo } from '../form/types';
import { Validators } from '@angular/forms'
import { ServiceAgent} from '../form/serviceAgent';

export class InstituteGradeStatisticsForm extends Form {
	private static _instance = new InstituteGradeStatisticsForm();

	public static getInstance(): InstituteGradeStatisticsForm {
		return InstituteGradeStatisticsForm._instance;
	}

	constructor() {
		super();
		this.fields = new Map();
		this.controls = new Map();
		this.opsAllowed = {filter: true, get: true};
	}

	public getName(): string {
		 return 'instituteGradeStatistics';
	}
}


export class InstituteGradeStatisticsFd extends FormData {
	constructor(form: InstituteGradeStatisticsForm, sa: ServiceAgent) {
		super(form, sa);
	}
	/**  this form has no editable fields. data nust be accessed as Vo and not through fd **/
}


export interface InstituteGradeStatisticsVo extends Vo {
	grade?: string, 
	nbr?: number
}
