import { Component, OnInit, ViewChild, Input } from "@angular/core";
import { DynamicPanel, GroupPanel } from '../../interfaces';
import { ServiceAgent } from 'src/app/framework-modules/formdata/form/serviceAgent';
import { SessionComponent } from '../../component';
import { FormData } from 'src/app/framework-modules/formdata/form/formData';
import { MessageService } from 'src/app/services/messageService';
import { ResetPasswordForm, ResetPasswordFd } from 'src/app/framework-modules/formdata/gen/resetPasswordForm';



@Component({
  selector: "assign-staff-list",
  templateUrl: "./component.html",
  styleUrls: ["./component.scss"]
})

export class ResetPasswordPageComponent implements DynamicPanel,OnInit {
  @Input() inputData: any;
  @Input() parentPanel: GroupPanel;
  @Input() session: SessionComponent;
 
  public form: ResetPasswordForm;
  public fd: ResetPasswordFd;
  constructor(private sa: ServiceAgent,private ms:MessageService) {
    this.form = ResetPasswordForm.getInstance();  
    this.fd = new FormData(this.form,this.sa);
  }

  ngOnInit(){
   
  }
  doSave(){

  }
  reset(){

  }

}