import { Component, Input, OnInit } from "@angular/core";
import { DynamicPanel, GroupPanel} from '../../interfaces';
import { ServiceAgent} from 'src/app/framework-modules/formdata/form/serviceAgent';
import { SessionComponent} from '../../component';
import { MessageService} from 'src/app/services/messageService';
import { GracingSubjectRegisterDetailForm, GracingSubjectRegisterDetailFd, GracingSubjectRegisterDetailVo } from 'src/app/framework-modules/formdata/gen/gracingSubjectRegisterDetailForm';
import { CtDialogComponent } from 'src/app/framework-modules/elements/ct-dialog/component';
import { MatDialog } from '@angular/material';
import { colorSets } from '@swimlane/ngx-charts/release/utils';

@Component({
  selector: "gradegracing-edit",
  templateUrl: "./component.html",
  styleUrls: ["./component.scss"]
})
export class GradeGracingEditComponent implements DynamicPanel, OnInit {
  @Input() inputData: any;
  @Input() parentPanel: GroupPanel;
  @Input() session: SessionComponent;

  public form: GracingSubjectRegisterDetailForm;
  public fd: GracingSubjectRegisterDetailFd;
  
  constructor(private sa: ServiceAgent, private ms: MessageService,  private dialog: MatDialog) {
    this.form = GracingSubjectRegisterDetailForm.getInstance();
    this.fd = this.form.newFormData(this.sa);
  }

  ngOnInit() {
    this.getSubjectGradeDetails();
  }

  getSubjectGradeDetails() {
    this.fd.fetchFor(this.inputData).subscribe({
      next: data => {
      },
      error: msg => {
        this.ms.showError('Server returned with error.');
      }
    });
  }

  goBack(){
    this.parentPanel.navigate("GradeGracingView",this.inputData);
  }

  private saveData() {
    this.sa.serve("update_studentGradeGracing",{data: this.fd.extractAll()}).subscribe({next: data=>{
      this.ms.showSuccess('Gracing Grade updated..');
      this.parentPanel.navigate('GradeGracingView',this.inputData);
    },
    error: msgs => {
      console.error(msgs);
    }
  })
    // this.fd.save().subscribe({
    //   next: data => {
    //     this.ms.showSuccess('Gracing Grade updated..');
    //     this.parentPanel.navigate('GradeGracingView');
    //   },

    //   error: msg => {
    //     this.ms.showError('server returned with errors');
    //   }
    // });
  }


  /**
   * Function is called when the user clicks save button.
   * Saves the Malpractice list of students of a subject.
   */
  save() {
    if(this.fd.getFieldValue("gracedMarks") != 0 && this.fd.getFieldValue("gracedMarks") > 4){
      this.ms.showError("Invalid Graced Marks");
      return;
    }
    let dialogRef = this.dialog.open(CtDialogComponent);
    dialogRef.componentInstance.title = 'Save Changes';
    dialogRef.componentInstance.content = 'Are you sure you want to save changes?';
    dialogRef.componentInstance.primary = 'Save';
    const subscribeDialog = dialogRef.componentInstance.save.subscribe((data) => {
      this.saveData();
      this.dialog.closeAll();
    });
  }

  /**
   * Function is called when the user clicks cancel button.
   * Goes back to Malpractice list screen
   */
  cancel() {
    let dialogRef = this.dialog.open(CtDialogComponent);
    dialogRef.componentInstance.title = 'Discard Changes';
    dialogRef.componentInstance.content = 'Are you sure you want to discard changes?';
    dialogRef.componentInstance.primary = 'Discard';
    const subscribeDialog = dialogRef.componentInstance.save.subscribe((data) => {
      this.dialog.closeAll();
      this.parentPanel.navigate("GradeGracingView",this.inputData);
    });
  }

}
