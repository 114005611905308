
import { Form , Field, ChildForm } from '../form/form';
import { FormData } from '../form/formData';
import { SelectOption, Vo } from '../form/types';
import { Validators } from '@angular/forms'
import { ServiceAgent} from '../form/serviceAgent';

export class SectionForm extends Form {
	private static _instance = new SectionForm();
	sectionId:Field = {
		name:'sectionId'
		,controlType: 'Hidden'
		,label: 'sectionId'
		,valueType: 1
		,errorId: 'invalidId'
		,maxValue: 9999999999999
	};
	instituteId:Field = {
		name:'instituteId'
		,controlType: 'Hidden'
		,label: 'instituteId'
		,valueType: 1
		,errorId: 'invalidTenentKey'
		,maxValue: 9999999999999
	};
	name:Field = {
		name:'name'
		,controlType: 'Input'
		,label: 'Name'
		,isRequired: true
		,valueType: 0
		,errorId: 'invalidName'
		,maxLength: 150
	};
	code:Field = {
		name:'code'
		,controlType: 'Input'
		,label: 'Code'
		,valueType: 0
		,errorId: 'invalidCode'
		,maxLength: 50
	};
	createdAt:Field = {
		name:'createdAt'
		,controlType: 'Hidden'
		,label: 'createdAt'
		,valueType: 5
		,errorId: 'invalidTimestamp'
	};
	updatedAt:Field = {
		name:'updatedAt'
		,controlType: 'Hidden'
		,label: 'updatedAt'
		,valueType: 5
		,errorId: 'invalidTimestamp'
	};

	public static getInstance(): SectionForm {
		return SectionForm._instance;
	}

	constructor() {
		super();
		this.fields = new Map();
		this.controls = new Map();
		this.controls.set('sectionId', [Validators.max(9999999999999)]);
		this.fields.set('sectionId', this.sectionId);
		this.controls.set('instituteId', [Validators.max(9999999999999)]);
		this.fields.set('instituteId', this.instituteId);
		this.controls.set('name', [Validators.required, Validators.maxLength(150)]);
		this.fields.set('name', this.name);
		this.controls.set('code', [Validators.maxLength(50)]);
		this.fields.set('code', this.code);
		this.controls.set('createdAt', []);
		this.fields.set('createdAt', this.createdAt);
		this.controls.set('updatedAt', []);
		this.fields.set('updatedAt', this.updatedAt);
		this.opsAllowed = {get: true, create: true, update: true, filter: true};
		this.keyFields = ["sectionId"];
	}

	public getName(): string {
		 return 'section';
	}
}


export class SectionFd extends FormData {
	constructor(form: SectionForm, sa: ServiceAgent) {
		super(form, sa);
	}

	setFieldValue(name: 'sectionId' | 'instituteId' | 'name' | 'code' | 'createdAt' | 'updatedAt', value: string | number | boolean | null ): void {
		super.setFieldValue(name, value);
	}

	getFieldValue(name: 'sectionId' | 'instituteId' | 'name' | 'code' | 'createdAt' | 'updatedAt' ): string | number | boolean | null {
		return super.getFieldValue(name);
	}
}


export interface SectionVo extends Vo {
	createdAt?: string, 
	code?: string, 
	name?: string, 
	instituteId?: number, 
	sectionId?: number, 
	updatedAt?: string
}
