import { Component,Input, OnInit, ViewChild, AfterViewInit } from "@angular/core";
import { DynamicPanel, GroupPanel } from '../../interfaces';
import { TableMetaData, CtTableComponent } from 'src/app/framework-modules/elements/ct-table/component';
import { CtSpinner } from 'src/app/framework-modules/elements/ct-spinner/component';
import { ExcelDownloadService } from 'src/app/services/excelDownloadService';
import { cloneDeep } from 'lodash';
import * as $ from 'jquery';
import { DegreeProgramSelectionFd, DegreeProgramSelectionForm } from "src/app/framework-modules/formdata/gen/degreeProgramSelectionForm";
import { ServiceAgent } from "src/app/framework-modules/formdata/form/serviceAgent";
import { OfferedSubjectSelectionFd, OfferedSubjectSelectionForm } from "src/app/framework-modules/formdata/gen/offeredSubjectSelectionForm";
import { OfferedSubjectDetailFd, OfferedSubjectDetailForm } from "src/app/framework-modules/formdata/gen/offeredSubjectDetailForm";
import { ValuationStatisticsFd, ValuationStatisticsForm, ValuationStatisticsVo } from "src/app/framework-modules/formdata/gen/valuationStatisticsForm";
import { HorizontalBarChartMetaData } from "src/app/framework-modules/elements/ct-horizontal-barchart/component";
import { DegreeSubjectStatisticsFd, DegreeSubjectStatisticsForm } from "src/app/framework-modules/formdata/gen/degreeSubjectStatisticsForm";
import { FilterRequest } from "src/app/framework-modules/formdata/form/types";


@Component({
  selector: "dashboard-view",
  templateUrl: "./component.html",
  styleUrls: ["./component.scss"]
})


export class ValuationActivitiesComponent  implements DynamicPanel, AfterViewInit{
  @ViewChild("spinner", {static:false}) spinner: CtSpinner;
  @ViewChild("gridTable", { static: false }) gtable: CtTableComponent;
  @Input() inputData: any;
  @Input() parentPanel: GroupPanel;
  public tableData: TableMetaData;
  public degreeSelectionFd: OfferedSubjectDetailFd;
  public degreeSelectionForm: OfferedSubjectDetailForm;
  public offeredSubjectSelectionFd: OfferedSubjectSelectionFd;
  public offeredSubjectSelectionForm: OfferedSubjectSelectionForm;
  public switchName: 'Switch to Subject-wise' | 'Switch to Degree-wise' = 'Switch to Subject-wise';
  public title: 'Degree-wise Statistics' | 'Subject-wise Statistics' = 'Degree-wise Statistics';
  public subTitle: 'View the evaluation statistics' = 'View the evaluation statistics';
  public subjectWise: boolean = false;
  public valuationStatsForm: ValuationStatisticsForm;
  public valuationStatsFd: ValuationStatisticsFd;
  public valuationStatsVo: any = {evaluationCompleted:0,evalulationPending:0,iiValuated:0,iiValuationPending:0,iiiValuated:0,scriptsModerated:0,totalPapers:0};
  public degreeSubjectForm: DegreeSubjectStatisticsForm;
  public degreeSubjectFd: DegreeSubjectStatisticsFd;
  public semVals: any[] = [];
  public completionStatus: string;
  public barMetaData: HorizontalBarChartMetaData;
  public degreeStats: any = {};

  constructor(private sa: ServiceAgent){
    this.degreeSelectionForm = OfferedSubjectDetailForm.getInstance();
    this.degreeSelectionFd = this.degreeSelectionForm.newFormData(this.sa);
    this.offeredSubjectSelectionForm = OfferedSubjectSelectionForm.getInstance();
    this.offeredSubjectSelectionFd = this.offeredSubjectSelectionForm.newFormData(this.sa);
    this.valuationStatsForm = ValuationStatisticsForm.getInstance();
    this.valuationStatsFd = this.valuationStatsForm.newFormData(this.sa);
    this.degreeSubjectForm = DegreeSubjectStatisticsForm.getInstance();
    this.degreeSubjectFd = this.degreeSubjectForm.newFormData(this.sa);
  }
  
  ngAfterViewInit(){
    this.metaDataInit();
    this.getHeader();
  }

  metaDataInit(){
    this.tableData = {
      data: [],
      metaData: {
        column_names: [
          "name",
          "code",
          "totalPapers",
          "pendingEvaluation",
          "completedEvaluation",
          "moderated",
          "iiEvaluated"
        ],
        display_names:{
          "name":"Subject Name",
          "code":"Code",
          "totalPapers": "Total Papers",
          "pendingEvaluation": "Pending",
          "completedEvaluation": "Evaluated",
          "moderated":"Moderated",
          "iiEvaluated":"II Evaluated"
        },
        styleAttribute:{
          "pendingEvaluation":[
            {comp:'>',value:-1,dependentCol:"totalPapers",style:{"text-align":"center","background":"#EF7564","font-family":'Roboto-Bold',color:"black"}}
          ],
          "completedEvaluation":[
            {comp:'>',value:-1,dependentCol:"totalPapers",style:{"text-align":"center","background":"#94b447ad","font-family":'Roboto-Bold',color:"black"}}
          ],
          "moderated":[
            {comp:'>',value:-1,dependentCol:"totalPapers",style:{"text-align":"center","background":'#c7e5ee',"font-family":'Roboto-Bold',color:"black"}}
          ],
          "iiEvaluated":[
            {comp:'>',value:-1,dependentCol:"totalPapers",style:{"text-align":"center",}}
          ]
        }
      }
    }

    this.barMetaData = {
      view: [$("#bar").parent().width()-50,200],
      xAxisLabel: "Scripts",
      yAxisLabel: "",
      showYAxisLabel: false,
      showLegend: false,
      scheme: {
        domain: ['#EF7564','#94b447ad','#94b447ad','#94b447ad',]
      }
    }
  }

  getHeader(){
    this.valuationStatsFd.fetchData().subscribe(
      data=>{
        this.valuationStatsVo = data.header;
        var list = {}
        Object.keys(data.degreeStats).forEach(key=>{
          var array = [];
          data.degreeStats[key].forEach(element => {
            array.push({
              name: element.name,
              totalScripts: element.totalPapers,
              details: [
                {name:"Pending", value: element.pendingEvaluation},
                {name:"Evaluated", value: element.completedEvaluation},
                {name:"II Evaluated", value: element.iiEvaluated},
                {name:"Moderated", value: element.moderated}
              ]
            });
          });
          list[key] = array;
        });
        this.degreeStats = list;
        this.completionStatus = Math.round((this.valuationStatsVo.evaluationCompleted/this.valuationStatsVo.totalPapers)*100) + '%';
        this.changeDegree();
      }
    )
  }

  changeWise(){
    this.getHeader();
    this.subjectWise = !this.subjectWise;
    if(this.subjectWise){
      this.switchName = 'Switch to Degree-wise';
      this.title = 'Subject-wise Statistics';
    }
    else{
      this.switchName = 'Switch to Subject-wise';
      this.title = 'Degree-wise Statistics';
      this.degreeSelectionFd.getFieldValue('levelId')
    }
  }

  changeDegree(){
    this.semVals = this.degreeStats[this.degreeSelectionFd.getFieldValue('degreeId') as number];
    this.barMetaData.xScaleMax =  Math.max.apply(Math, this.semVals.map(function(o) { return o.totalScripts; }));
  }

  changeSubject() {
    const payload:FilterRequest = {
      conditions: {
        degreeId: {comp:'=', value:this.degreeSelectionFd.getFieldValue('degreeId')},
        levelId: {comp:'=', value:this.degreeSelectionFd.getFieldValue('levelId')},
        departmentId: {comp:'=', value:this.offeredSubjectSelectionFd.getFieldValue('departmentId')}
      }
    }
    this.degreeSubjectFd.filter(payload).subscribe(
      data=>{
        this.tableData.data = data;
        this.gtable.update();
        this.gtable.gotoFullScreen()
      }
    )
  }

  refresh(){
    this.getHeader();
    this.changeSubject();
  }

}