import { Component, Input, Output, EventEmitter, OnInit, ViewChild, AfterViewInit } from "@angular/core";
import { DynamicPanel, LandingPanel, GroupPanel } from '../../interfaces';
import { ServiceAgent } from 'src/app/framework-modules/formdata/form/serviceAgent';
import { SessionComponent } from '../../component';
import { ClientContext } from 'src/app/framework-modules/formdata/form/clientContext';
import { StudentDetailForm, StudentDetailFd, StudentDetailVo } from 'src/app/framework-modules/formdata/gen/studentDetailForm';
import { FilterRequest } from 'src/app/framework-modules/formdata/form/types';
import { StudentInRegisterForm, StudentInRegisterFd, StudentInRegisterVo } from 'src/app/framework-modules/formdata/gen/studentInRegisterForm';
import { CtTableComponent, TableMetaData } from 'src/app/framework-modules/elements/ct-table/component';
import { CtSpinner } from 'src/app/framework-modules/elements/ct-spinner/component';
import { InstituteConfigurationForm, InstituteConfigurationFd, InstituteConfigurationVo } from 'src/app/framework-modules/formdata/gen/instituteConfigurationForm';

@Component({
  selector: "student-view",
  templateUrl: "./component.html",
  styleUrls: ["./component.scss"]
})


export class StudentViewComponent implements DynamicPanel, AfterViewInit {
  @ViewChild("gridTable", { static: false }) gtable: CtTableComponent;
  @ViewChild("spinner", {static:false}) spinner: CtSpinner;
  @Input() inputData: any;
  @Input() parentPanel: GroupPanel;
  @Input() session: SessionComponent;

  public form: StudentDetailForm;
  public fd: StudentDetailFd;
  public studentVo: StudentDetailVo = {};
  public form1: StudentInRegisterForm;
  public fd1: StudentInRegisterFd;
  public form2: InstituteConfigurationForm;
  public fd2: InstituteConfigurationFd;
  public instituteVo: InstituteConfigurationVo = {};
  public subjectVo: StudentInRegisterVo = {};
  public tableData: TableMetaData;
  public studentId: number;

  constructor(private sa: ServiceAgent, private ctx: ClientContext) {
    this.form = StudentDetailForm.getInstance();
    this.fd = this.form.newFormData(this.sa);
    this.form1 = StudentInRegisterForm.getInstance();
    this.fd1 = this.form1.newFormData(this.sa);
    this.form2 = InstituteConfigurationForm.getInstance();
    this.fd2 = this.form2.newFormData(this.sa);
  }

  ngAfterViewInit() {
    this.metaDataInit()
    this.studentId = this.ctx.getUser().userId;
    var instituteId = this.ctx.getInstituteId();
    this.spinner.show("Loading Data.....");
    this.fd.setFieldValue('studentId', this.studentId);
    this.fd.fetchData().subscribe({
      next: vo => {
        this.studentVo = vo;
        this.getSubjectDetails(instituteId,this.studentId);
        this.spinner.hide();
      },
      error: msg => console.error("Error from server ", msg)
    });
  }

  /**
   * Initialize subject registered table metaData
   */
  private metaDataInit(){
    this.tableData = {
      data: [],
      metaData: {
        column_names: [
          "subjectName",
          "cieMarks",
          "overallAttendance"
        ],
        display_names:{
          "subjectName":"Subject Name",
          "cieMarks":"CIE Marks",
          "overallAttendance": "Attendance"
        },
        search: true,
        placeholder: "Search.....",
        edit:true,
        buttonName:"VIEW"
      }
    }
  }

  /**
   * Get the list of subjects the student has registered to in the current season.
   * @param instituteId Institue Id to fetch the current season.
   * @param studentId Student Id to fetch details of the user.
   */
  private getSubjectDetails(instituteId,studentId){
    this.spinner.show("Loading Data.....");
    this.fd2.setFieldValue('instituteId',instituteId);
    this.fd2.fetchData().subscribe({
      next: vo=> {
        this.spinner.hide();
        this.instituteVo = vo;
        /**
         * Filter conditions
         */
        const filter: FilterRequest = {
          conditions: {
            studentId: {comp:"=", value:studentId}, 
            seasonId: {comp:"=",value: this.instituteVo.currentSeason},
            isIntegrated: {comp: "=", value: false}, 
            hasWithdrawn: {comp: "=", value: false}
          },
        }
        this.spinner.show("Loading Data.....");
        this.fd1.filter(filter).subscribe({
          next: data =>{
            this.tableData.data = data;
            this.gtable.update();
            this.spinner.hide();
          },
          error: err =>{
            console.error(err);
          }
        });
      }
    });
  }

  /**
   * View details of a registerd subject
   * @param $event Index of table
   */
  edit($event){
    this.parentPanel.navigate("StudentSubjects", this.tableData.data[$event]);
  }
  
  /**
   * Go to student Details edit
   */
  studentEdit(){
    this.parentPanel.navigate("StudentEdit", {studentId:this.studentId,navigatedFrom:"StudentView"} );
  }

  /**
   * Go to current season results view page
   */
  checkResults(){
    this.parentPanel.navigate("StudentResult", {studentVo:this.studentVo, instituteVo: this.instituteVo});
  }
}
