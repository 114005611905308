
import { Form , Field, ChildForm } from '../form/form';
import { FormData } from '../form/formData';
import { SelectOption, Vo } from '../form/types';
import { Validators } from '@angular/forms'
import { ServiceAgent} from '../form/serviceAgent';

export class AssignedPpNpSubjectSelectionForm extends Form {
	private static _instance = new AssignedPpNpSubjectSelectionForm();
	subjectSectionId:Field = {
		name:'subjectSectionId'
		,controlType: 'Hidden'
		,label: 'Select a Section'
		,isRequired: true
		,listName: 'subjectTypeFiveList'
		,valueType: 1
		,errorId: 'invalidId'
		,maxValue: 9999999999999
	};

	public static getInstance(): AssignedPpNpSubjectSelectionForm {
		return AssignedPpNpSubjectSelectionForm._instance;
	}

	constructor() {
		super();
		this.fields = new Map();
		this.controls = new Map();
		this.controls.set('subjectSectionId', [Validators.required, Validators.max(9999999999999)]);
		this.fields.set('subjectSectionId', this.subjectSectionId);
		this.listFields = ['subjectSectionId'];
	}

	public getName(): string {
		 return 'assignedPpNpSubjectSelection';
	}
}


export class AssignedPpNpSubjectSelectionFd extends FormData {
	constructor(form: AssignedPpNpSubjectSelectionForm, sa: ServiceAgent) {
		super(form, sa);
	}

	setFieldValue(name: 'subjectSectionId', value: string | number | boolean | null ): void {
		super.setFieldValue(name, value);
	}

	getFieldValue(name: 'subjectSectionId' ): string | number | boolean | null {
		return super.getFieldValue(name);
	}
}


export interface AssignedPpNpSubjectSelectionVo extends Vo {
	subjectSectionId?: number
}
