import { Component, OnInit, ViewChild, Input, AfterViewInit } from "@angular/core";
import { DynamicPanel, GroupPanel } from '../../interfaces';
import { ServiceAgent } from 'src/app/framework-modules/formdata/form/serviceAgent';
import { SessionComponent } from '../../component';
import { MessageService } from 'src/app/services/messageService';
import { CtSpinner } from 'src/app/framework-modules/elements/ct-spinner/component';
import { CtTableComponent, TableMetaData } from 'src/app/framework-modules/elements/ct-table/component';
import { FilterRequest } from 'src/app/framework-modules/formdata/form/types';
import { SubjectwiseResultForm, SubjectwiseResultFd, SubjectwiseResultVo } from 'src/app/framework-modules/formdata/gen/subjectwiseResultForm';
import { ExcelDownloadService } from 'src/app/services/excelDownloadService';
import * as $ from 'jquery';
import { InstituteConfigurationForm, InstituteConfigurationFd, InstituteConfigurationVo } from 'src/app/framework-modules/formdata/gen/instituteConfigurationForm';
import { ClientContext } from 'src/app/framework-modules/formdata/form/clientContext';
import { CurrentEnrolledStudentForm, CurrentEnrolledStudentFd, CurrentEnrolledStudentVo } from 'src/app/framework-modules/formdata/gen/currentEnrolledStudentForm';
import { StudentDetailVo } from 'src/app/framework-modules/formdata/gen/studentDetailForm';


@Component({
  selector: "student-result",
  templateUrl: "./component.html",
  styleUrls: ["./component.scss"],
})


export class StudentResultComponent implements DynamicPanel, OnInit,AfterViewInit {
  @ViewChild("gridTable", { static: false }) public gtable: CtTableComponent;
  @ViewChild("spinner", { static: false }) spinner: CtSpinner;
  @Input() inputData: any;
  @Input() parentPanel: GroupPanel;
  @Input() session: SessionComponent;

  public form: SubjectwiseResultForm;
  public fd: SubjectwiseResultFd;
  public studentVo: SubjectwiseResultVo = {};
  public tableData: TableMetaData;
  public vo1: InstituteConfigurationVo = {};
  public studentDetailVo: StudentDetailVo = {};
  public form2: CurrentEnrolledStudentForm;
  public fd2: CurrentEnrolledStudentFd;
  public vo2: CurrentEnrolledStudentVo = {};
  resultsAnnounced: boolean = false;

  constructor(private sa: ServiceAgent, private ms: MessageService, private ds: ExcelDownloadService, private cc: ClientContext) {
    this.form = SubjectwiseResultForm.getInstance();
    this.fd = this.form.newFormData(this.sa);
    this.form2 = CurrentEnrolledStudentForm.getInstance();
    this.fd2 = this.form2.newFormData(this.sa);
  }

  /**
   * Input data consists of:
   * [0] - Details of student
   * [1] - The page from which this component was called to navigate back.
   */
  ngOnInit() {
    this.studentDetailVo = this.inputData.studentVo;
    this.vo1 = this.inputData.instituteVo;
    this.metaDataInit();
    this.checkResultStatus();
  }

  checkResultStatus(){
    if(this.vo1.resultsAnnounced){
      this.resultsAnnounced = true;
      this.filter();
      this.getStudentData(this.studentDetailVo.studentId);
    }
  }

  ngAfterViewInit(){
    this.gtable.update();
  }

  /**
   * Initializing the table data
   * If grade is F, it'll be displayed in RED.
   * If grade is W, it'll be displayed in BLACK.
   */
  metaDataInit() {
    this.tableData = {
      data: [] = [],
      metaData: {
        column_names: [
          "subjectName",
          "subjectCode",
          "credits",
          "cieMarks",
          "examMarks",
          "grade",
          "gradePoints"
        ],
        display_names: {
          "subjectName": "Subject Name",
          "subjectCode": "Subject Code",
          "credits": "Credits",
          "cieMarks": "CIE Marks",
          "examMarks": "Exam Marks",
          "grade": "Grade",
          "gradePoints": "Grade Points"
        },
        styleAttribute: {
          "grade": [
            {comp: '=', value: 'F', style:{'color':'red', 'font-weight': 800}},
            {comp: '=', value: 'W', style:{'color':'black', 'font-weight': 800}},
            {comp: '=', value: 'X', style:{'color':'green', 'font-weight': 800}},
           ]
        },
        search:true,
        placeholder: "Search for a subject..."
      }
      
    };
  }

  /**
   * Get student GPA
   */
  getStudentData(studentId){
    this.fd2.setFieldValue('studentId',studentId);
    this.fd2.setFieldValue('seasonId',this.vo1.currentSeason);
    this.fd2.fetchData().subscribe({
      next: data=>{
        this.vo2 = data;
      }
    })
  }

  /**
   * Filter Conditions to get student details
   */
  filter(){
    const filter: FilterRequest ={
      conditions: {
        studentId: {comp:"=",value:this.studentDetailVo.studentId},
        seasonId: {comp:"=",value:this.vo1.currentSeason}
      },
      maxRows:10,
      sort:{gradePoints:"desc"}
    }

    const obs = this.fd.filter(filter);
    const that = this;
    
    obs.subscribe(data=>{
      this.tableData.data = data;
      this.gtable.update();
    })
  }

  /**
   * Downloads the report card of a student.
   */
  doDownload(){
    var data = $.extend({},this.inputData[0],{subjectDetails:this.tableData.data});
    this.ds.downloadGradeCard(data);
    this.ms.showInfo("Grade Card Downloaded Succesfully!")
  }

  /**
   * Navigates back to the screen from which it was called.
   */
  goBack(){
    this.parentPanel.navigate('StudentView');
  }
}