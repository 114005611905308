
import { Form , Field, ChildForm } from '../form/form';
import { FormData } from '../form/formData';
import { SelectOption, Vo } from '../form/types';
import { Validators } from '@angular/forms'
import { ServiceAgent} from '../form/serviceAgent';

export class LevelSectionForm extends Form {
	private static _instance = new LevelSectionForm();
	levelSectionId:Field = {
		name:'levelSectionId'
		,controlType: 'Hidden'
		,label: 'levelSectionId'
		,valueType: 1
		,errorId: 'invalidId'
		,maxValue: 9999999999999
	};
	levelId:Field = {
		name:'levelId'
		,controlType: 'Hidden'
		,label: 'levelId'
		,isRequired: true
		,valueType: 1
		,errorId: 'invalidId'
		,maxValue: 9999999999999
	};
	programId:Field = {
		name:'programId'
		,controlType: 'Hidden'
		,label: 'programId'
		,valueType: 1
		,defaultValue: 0
		,errorId: 'invalidId'
		,maxValue: 9999999999999
	};

	public static getInstance(): LevelSectionForm {
		return LevelSectionForm._instance;
	}

	constructor() {
		super();
		this.fields = new Map();
		this.controls = new Map();
		this.controls.set('levelSectionId', [Validators.max(9999999999999)]);
		this.fields.set('levelSectionId', this.levelSectionId);
		this.controls.set('levelId', [Validators.required, Validators.max(9999999999999)]);
		this.fields.set('levelId', this.levelId);
		this.controls.set('programId', [Validators.max(9999999999999)]);
		this.fields.set('programId', this.programId);
		this.opsAllowed = {get: true, create: true, update: true, filter: true};
		this.keyFields = ["levelSectionId"];
	}

	public getName(): string {
		 return 'levelSection';
	}
}


export class LevelSectionFd extends FormData {
	constructor(form: LevelSectionForm, sa: ServiceAgent) {
		super(form, sa);
	}

	setFieldValue(name: 'levelSectionId' | 'levelId' | 'programId', value: string | number | boolean | null ): void {
		super.setFieldValue(name, value);
	}

	getFieldValue(name: 'levelSectionId' | 'levelId' | 'programId' ): string | number | boolean | null {
		return super.getFieldValue(name);
	}
}


export interface LevelSectionVo extends Vo {
	sectionId?: number, 
	levelSectionId?: number, 
	isActive?: boolean, 
	programId?: number, 
	levelId?: number
}
