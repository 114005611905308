import { Component, OnInit, ViewChild, Input } from "@angular/core";
import { GroupPanel } from '../../interfaces';
import { ServiceAgent } from 'src/app/framework-modules/formdata/form/serviceAgent';
import { SessionComponent } from '../../component';
import { CtTableComponent } from 'src/app/framework-modules/elements/ct-table/component';
import { CtSpinner } from 'src/app/framework-modules/elements/ct-spinner/component';
import { ExcelDownloadService } from 'src/app/services/excelDownloadService';
import { FilterRequest } from "src/app/framework-modules/formdata/form/types";
import { RevalOrChallengeValuationListForm, RevalOrChallengeValuationListFd } from 'src/app/framework-modules/formdata/gen/revalOrChallengeValuationListForm';
import { ClientContext } from 'src/app/framework-modules/formdata/form/clientContext';

@Component({
  selector: "reval-challenge-student-list",
  templateUrl: "./component.html",
  styleUrls: ["./component.scss"]
})


export class RevalChallengeStudentListComponent implements OnInit {
  @ViewChild("gridTable", { static: false }) gtable: CtTableComponent;
  @ViewChild("spinner", { static: false }) public spinner: CtSpinner;
  @Input() inputData: any;
  @Input() parentPanel: GroupPanel;
  @Input() session: SessionComponent;
  tableData: any;
  hideDownload: boolean = true;
  studentData:any;
  form: RevalOrChallengeValuationListForm;
  fd: RevalOrChallengeValuationListFd;


  constructor(private sa: ServiceAgent, private ds: ExcelDownloadService, private cc: ClientContext) {
    this.form = RevalOrChallengeValuationListForm.getInstance();
    this.fd = this.form.newFormData(this.sa);
  }

  ngOnInit() {
    this.metaDataInit();
  }

   /**
   * initialising the table data
   */
    metaDataInit() {
      this.tableData = {
        data: [] = [],
        metaData: {
          column_names: [
            "usn",
            "studentName",
            "subjectName",
            "optedForReval",
            "optedForChallenge"
          ],
          display_names: {
            "usn": "USN",
            "studentName": "Student Name",
            "subjectName":"Subject",
            "optedForReval": "Opted RV",
            "optedForChallenge": "Opted CV"
          },
          edit: false,
          search: true,
          placeholder: "Search a student..."
        }
      };
    }

  getStudentList(){
    this.hideDownload = true;
    const filter: FilterRequest = {
      conditions: {
        degreeId: {comp:"=",value:this.fd.getFieldValue('degreeId')},
        programId: {comp:"=",value:this.fd.getFieldValue('programId')},
        levelId: {comp:"=",value:this.fd.getFieldValue('levelId')},
        seasonId: {comp: "=",value: this.cc.getcurrentSeasonId()}
      },
      sort: { usn: "asc" }
    };
    this.fd.filter(filter).subscribe(
      data => {
        this.studentData = data;
        this.hideDownload = (data.length > 0)? false: true;
        this.tableData.data = data;
        this.gtable.update(this.spinner);
      },
      msgs => {
        this.tableData.data = [];
        this.gtable.update(this.spinner);
      }
    )
  }

  download(){
    let data = {};
    let sheetName = `${this.fd.getDisplayedValueOf('seasonId')}`;
    let fileName: string = `Revaluation/Challenge Opted Student Report for ${this.fd.getDisplayedValueOf('programId')}`
    let excelData = [];
    for(let i =0; i<this.studentData.length; i++){
      let row = {};
      row["Usn"] = this.studentData[i].usn
      row["Student Name"] = this.studentData[i].studentName
      row["Course Code"] = this.studentData[i].subjectCode
      row["Course Name"] = this.studentData[i].subjectName
      row["Semester"] = this.studentData[i].sem
      row["Revaluation"] = this.studentData[i].optedForReval == false ? "No" : "Yes"
      row["Challenge Valuation"] = this.studentData[i].optedForChallenge == false ? "No" : "Yes"
      excelData.push(row);
    }
    data[sheetName] = excelData;
    this.ds.downloadExcel(data,fileName);

  }
}