
import { Form , Field, ChildForm } from '../form/form';
import { FormData } from '../form/formData';
import { SelectOption, Vo } from '../form/types';
import { Validators } from '@angular/forms'
import { ServiceAgent} from '../form/serviceAgent';

export class RevaluationEligibleStudentForm extends Form {
	private static _instance = new RevaluationEligibleStudentForm();
	offeredSubjectId:Field = {
		name:'offeredSubjectId'
		,controlType: 'Hidden'
		,label: 'offeredSubjectId'
		,valueType: 1
		,errorId: 'invalidId'
		,maxValue: 9999999999999
	};

	public static getInstance(): RevaluationEligibleStudentForm {
		return RevaluationEligibleStudentForm._instance;
	}

	constructor() {
		super();
		this.fields = new Map();
		this.controls = new Map();
		this.controls.set('offeredSubjectId', [Validators.max(9999999999999)]);
		this.fields.set('offeredSubjectId', this.offeredSubjectId);
		this.opsAllowed = {filter: true};
	}

	public getName(): string {
		 return 'revaluationEligibleStudent';
	}
}


export class RevaluationEligibleStudentFd extends FormData {
	constructor(form: RevaluationEligibleStudentForm, sa: ServiceAgent) {
		super(form, sa);
	}

	setFieldValue(name: 'offeredSubjectId', value: string | number | boolean | null ): void {
		super.setFieldValue(name, value);
	}

	getFieldValue(name: 'offeredSubjectId' ): string | number | boolean | null {
		return super.getFieldValue(name);
	}
}


export interface RevaluationEligibleStudentVo extends Vo {
	studentId?: number, 
	offeredSubjectId?: number, 
	usn?: string, 
	level?: string, 
	name?: string, 
	optedForReval?: boolean, 
	instituteId?: number, 
	department?: string
}
