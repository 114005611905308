import { Component, OnInit, ViewChild, Input } from "@angular/core";
import { DynamicPanel, GroupPanel } from '../../interfaces';
import { ServiceAgent } from 'src/app/framework-modules/formdata/form/serviceAgent';
import { CtTableComponent, TableMetaData } from '../../../../framework-modules/elements/ct-table/component'
import { SessionComponent } from '../../component';
import { MessageService } from 'src/app/services/messageService';
import { CtSpinner } from 'src/app/framework-modules/elements/ct-spinner/component';
import { ExcelDownloadService } from 'src/app/services/excelDownloadService';
import { ExemptionListForm, ExemptionListFd, ExemptionListVo } from 'src/app/framework-modules/formdata/gen/exemptionListForm';



@Component({
  selector: "exam-exemption-list",
  templateUrl: "./component.html",
  styleUrls: ["./component.scss"],
})


export class ExamExemptionListComponent implements DynamicPanel, OnInit {
  @ViewChild("gridTable", { static: false }) gtable: CtTableComponent;
  @ViewChild("spinner", { static: false }) spinner: CtSpinner;
  @Input() inputData: any;
  @Input() parentPanel: GroupPanel;
  @Input() session: SessionComponent;

  public tableData: TableMetaData;
  /** form and fd for selecting the subjects */
  public form: ExemptionListForm;
  public fd: ExemptionListFd;
  public vo: ExemptionListVo = {};

  
  private selectedId: string = null;

  constructor(private sa: ServiceAgent, private ms: MessageService, private ds: ExcelDownloadService) {
    this.form = ExemptionListForm.getInstance();
    this.fd = this.form.newFormData(this.sa);
  }


  /**
   * 
   */
  ngOnInit() {
    this.metaDataInit();
  }

  metaDataInit() {
    this.tableData = {
      data: [],
      metaData: {
        column_names: [
          "name",
          "usn",
          "department",
          "level",
          "section"
        ],
        display_names: {
          name: "Name",
          usn: "USN",
          department: "Department Name",
          level: "Level",
          section: "Section"
        },
        search: true,
        placeholder: "Search By Name, USN, Department"
      },
    };
  }

  public idChanged(id) {
    if (this.selectedId == id) {
      return;
    }
    this.selectedId = id;
    if (id) {
      this.getData(id);
    }
  }

  private getData(id) {
    this.spinner.show('Getting attendance for this subject...');
    const payload = {
      offeredSubjectId:id,
      departmentId:this.fd.getFieldValue('departmentId')
    };
    this.fd.fetchFor(payload).subscribe({
      next: data => {
        this.vo = data;
        this.tableData.data = data.students as [];
        this.gtable.update(this.spinner);
      },

      error: msg => {
        this.spinner.hide();
        this.ms.showError('Server returned with error.');
      }
    });
  }

  edit() {
    const d = { 
      offeredSubjectId: this.selectedId, 
      departmentId: this.vo.departmentId 
    };
    this.parentPanel.navigate('ExamExemptionEdit', d);
  }

}