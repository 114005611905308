
import { Form , Field, ChildForm } from '../form/form';
import { FormData } from '../form/formData';
import { SelectOption, Vo } from '../form/types';
import { Validators } from '@angular/forms'
import { ServiceAgent} from '../form/serviceAgent';

export class ProgramAverageGpaDetailForm extends Form {
	private static _instance = new ProgramAverageGpaDetailForm();

	public static getInstance(): ProgramAverageGpaDetailForm {
		return ProgramAverageGpaDetailForm._instance;
	}

	constructor() {
		super();
		this.fields = new Map();
		this.controls = new Map();
		this.opsAllowed = {get: true, filter: true};
	}

	public getName(): string {
		 return 'programAverageGpaDetail';
	}
}


export class ProgramAverageGpaDetailFd extends FormData {
	constructor(form: ProgramAverageGpaDetailForm, sa: ServiceAgent) {
		super(form, sa);
	}
	/**  this form has no editable fields. data nust be accessed as Vo and not through fd **/
}


export interface ProgramAverageGpaDetailVo extends Vo {
	degreeId?: number, 
	averageCgpa?: number, 
	seasonId?: number, 
	programName?: string, 
	levelId?: number, 
	instituteId?: number, 
	levelName?: string, 
	programId?: number, 
	averageSgpa?: number
}
