
import { Form , Field, ChildForm } from '../form/form';
import { FormData } from '../form/formData';
import { SelectOption, Vo } from '../form/types';
import { Validators } from '@angular/forms'
import { ServiceAgent} from '../form/serviceAgent';
import { GracingSubjectRegisterDetailForm, GracingSubjectRegisterDetailVo } from './gracingSubjectRegisterDetailForm';

export class GradeGracingStudentListForm extends Form {
	private static _instance = new GradeGracingStudentListForm();
	studentId:Field = {
		name:'studentId'
		,controlType: 'Hidden'
		,label: 'studentId'
		,isRequired: true
		,valueType: 1
		,errorId: 'invalidId'
		,maxValue: 9999999999999
	};
	instituteId:Field = {
		name:'instituteId'
		,controlType: 'Hidden'
		,label: 'instituteId'
		,valueType: 1
		,errorId: 'invalidTenentKey'
		,maxValue: 9999999999999
	};
	seasonId:Field = {
		name:'seasonId'
		,controlType: 'Hidden'
		,label: 'seasonId'
		,isRequired: true
		,valueType: 1
		,errorId: 'invalidId'
		,maxValue: 9999999999999
	};
	degreeId:Field = {
		name:'degreeId'
		,controlType: 'Hidden'
		,label: 'degreeId'
		,isRequired: true
		,valueType: 1
		,errorId: 'invalidId'
		,maxValue: 9999999999999
	};
	programId:Field = {
		name:'programId'
		,controlType: 'Hidden'
		,label: 'programId'
		,isRequired: true
		,valueType: 1
		,errorId: 'invalidId'
		,maxValue: 9999999999999
	};
	levelId:Field = {
		name:'levelId'
		,controlType: 'Hidden'
		,label: 'levelId'
		,isRequired: true
		,valueType: 1
		,errorId: 'invalidId'
		,maxValue: 9999999999999
	};
	levelName:Field = {
		name:'levelName'
		,controlType: 'Input'
		,label: 'levelName'
		,isRequired: true
		,valueType: 0
		,errorId: 'invalidText'
		,maxLength: 1000
	};
	studentName:Field = {
		name:'studentName'
		,controlType: 'Input'
		,label: 'studentName'
		,isRequired: true
		,valueType: 0
		,errorId: 'invalidText'
		,maxLength: 1000
	};
	usn:Field = {
		name:'usn'
		,controlType: 'Input'
		,label: 'usn'
		,isRequired: true
		,valueType: 0
		,errorId: 'invalidText'
		,maxLength: 1000
	};
	programName:Field = {
		name:'programName'
		,controlType: 'Input'
		,label: 'programName'
		,isRequired: true
		,valueType: 0
		,errorId: 'invalidText'
		,maxLength: 1000
	};
	parentId:Field = {
		name:'parentId'
		,controlType: 'Hidden'
		,label: 'parentId'
		,valueType: 1
		,defaultValue: 0
		,errorId: 'invalidId'
		,maxValue: 9999999999999
	};
	gracedValuationRound:Field = {
		name:'gracedValuationRound'
		,controlType: 'Input'
		,label: 'gracedValuationRound'
		,isRequired: true
		,listName: 'valuationRoundList'
		,valueList: [
			{value:'1',text:'Initial'},
			{value:'2',text:'Revaluation'},
			{value:'3',text:'Challenge'}
			]
		,valueType: 1
		,errorId: 'invalidId'
		,maxValue: 9999999999999
	};

	subjectDetails: ChildForm = {
		name:'subjectDetails',
		form:GracingSubjectRegisterDetailForm.getInstance(),
		isEditable:false,
		isTabular:true,
		label:'',
		minRows:0,
		maxRows:0,
		errorId:null
	};

	public static getInstance(): GradeGracingStudentListForm {
		return GradeGracingStudentListForm._instance;
	}

	constructor() {
		super();
		this.fields = new Map();
		this.controls = new Map();
		this.controls.set('studentId', [Validators.required, Validators.max(9999999999999)]);
		this.fields.set('studentId', this.studentId);
		this.controls.set('instituteId', [Validators.max(9999999999999)]);
		this.fields.set('instituteId', this.instituteId);
		this.controls.set('seasonId', [Validators.required, Validators.max(9999999999999)]);
		this.fields.set('seasonId', this.seasonId);
		this.controls.set('degreeId', [Validators.required, Validators.max(9999999999999)]);
		this.fields.set('degreeId', this.degreeId);
		this.controls.set('programId', [Validators.required, Validators.max(9999999999999)]);
		this.fields.set('programId', this.programId);
		this.controls.set('levelId', [Validators.required, Validators.max(9999999999999)]);
		this.fields.set('levelId', this.levelId);
		this.controls.set('levelName', [Validators.required, Validators.maxLength(1000)]);
		this.fields.set('levelName', this.levelName);
		this.controls.set('studentName', [Validators.required, Validators.maxLength(1000)]);
		this.fields.set('studentName', this.studentName);
		this.controls.set('usn', [Validators.required, Validators.maxLength(1000)]);
		this.fields.set('usn', this.usn);
		this.controls.set('programName', [Validators.required, Validators.maxLength(1000)]);
		this.fields.set('programName', this.programName);
		this.controls.set('parentId', [Validators.max(9999999999999)]);
		this.fields.set('parentId', this.parentId);
		this.controls.set('gracedValuationRound', [Validators.required, Validators.max(9999999999999)]);
		this.fields.set('gracedValuationRound', this.gracedValuationRound);

		this.childForms = new Map();
		this.childForms.set('subjectDetails', this.subjectDetails);
		this.opsAllowed = {get: true, filter: true};
		this.listFields = ['gracedValuationRound'];
		this.keyFields = ["studentId"];
	}

	public getName(): string {
		 return 'gradeGracingStudentList';
	}
}


export class GradeGracingStudentListFd extends FormData {
	constructor(form: GradeGracingStudentListForm, sa: ServiceAgent) {
		super(form, sa);
	}

	setFieldValue(name: 'studentId' | 'instituteId' | 'seasonId' | 'degreeId' | 'programId' | 'levelId' | 'levelName' | 'studentName' | 'usn' | 'programName' | 'parentId' | 'gracedValuationRound', value: string | number | boolean | null ): void {
		super.setFieldValue(name, value);
	}

	getFieldValue(name: 'studentId' | 'instituteId' | 'seasonId' | 'degreeId' | 'programId' | 'levelId' | 'levelName' | 'studentName' | 'usn' | 'programName' | 'parentId' | 'gracedValuationRound' ): string | number | boolean | null {
		return super.getFieldValue(name);
	}
}


export interface GradeGracingStudentListVo extends Vo {
	degreeId?: number, 
	levelName?: string, 
	parentId?: number, 
	studentId?: number, 
	usn?: string, 
	seasonId?: number, 
	programName?: string, 
	levelId?: number, 
	studentName?: string, 
	instituteId?: number, 
	programId?: number, 
	gracedValuationRound?: number, 
	subjectDetails?: GracingSubjectRegisterDetailVo
}
