
import { Form , Field, ChildForm } from '../form/form';
import { FormData } from '../form/formData';
import { SelectOption, Vo } from '../form/types';
import { Validators } from '@angular/forms'
import { ServiceAgent} from '../form/serviceAgent';

export class DegreeSubjectStatisticsForm extends Form {
	private static _instance = new DegreeSubjectStatisticsForm();

	public static getInstance(): DegreeSubjectStatisticsForm {
		return DegreeSubjectStatisticsForm._instance;
	}

	constructor() {
		super();
		this.fields = new Map();
		this.controls = new Map();
		this.opsAllowed = {filter: true, get: true};
		this.keyFields = ["degreeId", "departmentId", "levelId"];
	}

	public getName(): string {
		 return 'degreeSubjectStatistics';
	}
}


export class DegreeSubjectStatisticsFd extends FormData {
	constructor(form: DegreeSubjectStatisticsForm, sa: ServiceAgent) {
		super(form, sa);
	}
	/**  this form has no editable fields. data nust be accessed as Vo and not through fd **/
}


export interface DegreeSubjectStatisticsVo extends Vo {
	code?: string, 
	moderated?: number, 
	degreeId?: number, 
	valuationRound?: number, 
	completedEvaluation?: number, 
	iiEvaluated?: number, 
	departmentId?: number, 
	pendingEvaluation?: number, 
	offeredSubjectId?: number, 
	totalPapers?: number, 
	levelId?: number, 
	name?: string, 
	instituteId?: number
}
