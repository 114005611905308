import { Component, OnInit, ViewChild, Input, AfterViewInit } from "@angular/core";
import { ServiceAgent } from "src/app/framework-modules/formdata/form/serviceAgent";
import { GroupPanel } from "../../interfaces";
import { SessionComponent } from "../../component";
import { CtEditableTableComponent } from "src/app/framework-modules/elements/ct-editable-table/component";
import { MessageService } from "src/app/services/messageService";
import { MatDialog } from "@angular/material";
import { CtDialogComponent } from "src/app/framework-modules/elements/ct-dialog/component";
import { MarksUpdateForm, MarksUpdateFd, MarksUpdateVo } from "src/app/framework-modules/formdata/gen/marksUpdateForm";

@Component({
  selector: "project-marks-entry-update",
  templateUrl: "./component.html",
  styleUrls: ["./component.scss"]
})
export class ProjectMarksEntryUpdateComponent implements OnInit {
  @ViewChild("gridTable", { static: false }) gtable: CtEditableTableComponent;
  @Input() inputData: any;
  @Input() parentPanel: GroupPanel;
  @Input() session: SessionComponent;

  form: MarksUpdateForm;
  fd: MarksUpdateFd;
  /**
   * important to intialize it to empty obbject as we 
   * have html that use vo.xxxx
   */
  vo: MarksUpdateVo = {};

  constructor(
    private sa: ServiceAgent,
    private ms: MessageService,
    private dialog: MatDialog
  ) {
    this.form = MarksUpdateForm.getInstance();
    this.fd = this.form.newFormData(this.sa);
  }

  ngOnInit() {
    if (this.inputData &&
      this.inputData.subjectRegisterId &&
      this.inputData.studentId &&
      this.inputData.assessmentSeqNo) {
      this.getData(this.inputData);
    } else {
      this.ms.showError('Page invoked with invalid data');
    }
  }

  private getData(keys) {
    this.fd.setFieldValue('studentId', keys.studentId);
    this.fd.setFieldValue('subjectRegisterId', keys.subjectRegisterId);
    this.fd.setFieldValue('assessmentSeqNo', keys.assessmentSeqNo);
    this.fd.fetchData().subscribe({
      next: data => {
        this.vo = data;
      },
      error: msg => {
        this.ms.showError('Server returned with an error');
      }
    });
  }

  /**
   * Call authorization service
   */
  updateMarks() {
    if (!this.fd.validateForm()) {
      this.ms.showError('Please fix the errors nefore submitting');
      return;
    }
    this.fd.save().subscribe({
      next: data => {
        this.ms.showSuccess('Request sent for Authorization');
        this.parentPanel.navigate("ProjectMarksEntryList");
      },
      error: msg => {
        this.ms.showError('Errors encountered. Please fix them and try again.');
      }
    });
  }

  cancel() {
    let dialogRef = this.dialog.open(CtDialogComponent);
    dialogRef.componentInstance.title = "Discard Changes";
    dialogRef.componentInstance.content =
      "Are you sure you want to discard changes?";
    dialogRef.componentInstance.primary = "Discard";
    const subscribeDialog = dialogRef.componentInstance.save.subscribe(data => {
      this.dialog.closeAll();
      this.parentPanel.navigate("ProjectMarksEntryList");
    });
  }

  save() {
    let dialogRef = this.dialog.open(CtDialogComponent);
    dialogRef.componentInstance.title = "Discard Changes";
    dialogRef.componentInstance.content = "Are you sure you want update Marks?";
    dialogRef.componentInstance.primary = "Update";
    const subscribeDialog = dialogRef.componentInstance.save.subscribe(data => {
      this.dialog.closeAll();
      this.updateMarks();
      this.parentPanel.navigate("ProjectMarksEntryList");
    });
  }
}
