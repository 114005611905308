import { Component, OnInit, ViewChild, Input, AfterViewInit } from "@angular/core";
import { ServiceAgent } from 'src/app/framework-modules/formdata/form/serviceAgent';
import { CtTableComponent, TableMetaData } from 'src/app/framework-modules/elements/ct-table/component';
import { GroupPanel } from '../../interfaces';
import { SessionComponent } from '../../component';
import { MarksForSectionForm, MarksForSectionFd } from 'src/app/framework-modules/formdata/gen/marksForSectionForm';
import { RowToColMeta, ColToRowMeta, Transposer, Vo } from 'src/app/framework-modules/formdata/form/types';
import { TaughtSectionSelectionFd, TaughtSectionSelectionForm } from 'src/app/framework-modules/formdata/gen/taughtSectionSelectionForm';
import { MessageService } from 'src/app/services/messageService';


@Component({
  selector: 'marks-entry-view',
  templateUrl: "./component.html",
  styleUrls: ["./component.scss"]
})
export class MarksEntryViewComponent implements OnInit, AfterViewInit {
  @ViewChild("gridTable", { static: false }) gtable: CtTableComponent;
  @Input() inputData: any;
  @Input() parentPanel: GroupPanel;
  @Input() session: SessionComponent;

  /**
   * for selecting subjectSection and test (seqNo)
   */
  form: TaughtSectionSelectionForm;
  fd: TaughtSectionSelectionFd;
  selectedSection = ''
  selectedSeqNo = '';
  /**
   * cie is effectively frozen?
   */
  cieFrozen = false;

  /**
   * for getting marks-list
   */
  form1: MarksForSectionForm;
  fd1: MarksForSectionFd;

  private colToRowMeta: ColToRowMeta;
  tableData: TableMetaData;

  constructor(private sa: ServiceAgent,
    private ms: MessageService) {

    this.form = TaughtSectionSelectionForm.getInstance();
    this.fd = this.form.newFormData(this.sa);
    this.form1 = MarksForSectionForm.getInstance();
    this.fd1 = this.form1.newFormData(this.sa);
  }

  ngOnInit() {
    this.initTable();
  }

  ngAfterViewInit() {
    /**
     * update() is not a repeatable method() we have to be careful before calling it!!
     */
    //this.gtable.update();
  }

  sectionChanged(id: string) {
    if (this.selectedSection == id) {
      console.log('section remains as ' + id);
      return;
    }
    if (id) {
      this.getData(id);
    }
  }

  seqNoChanged(seqNo) {
    this.selectedSeqNo = seqNo;
  }

  getData(id: string) {
    this.fd1.setFieldValue('subjectSectionId', id);
    this.fd1.fetchData().subscribe({
      next: data => {
        this.selectedSection = id;
        this.setData(data);
      },

      error: msg => {
        console.error(msg);
      }
    });
  }

  /**
   * 
   * @param data set the data reced from server into our table component after transposing it
   */
  setData(data) {
    this.cieFrozen = data.cieFrozen;
    /**
     * input meta data for the trasnposer
     */
    const meta: RowToColMeta = {
      datarowsAttribute: 'marks',
      headingAttribute: 'name',
      keyAttribute: 'seqNo',
      rowKeyAttribute: 'assessmentSeqNo',
      rowValueAttribute: 'marks',
      columns: data.names as [],
    };

    /**
     * table meta data
     */
    const students = data.students as [];
    const colNames = ["name", "usn"];
    const headings = { name: "Name", usn: "USN" };
    const tableMeta = { names: colNames, headings: headings };
    this.colToRowMeta = Transposer.rowToCol(meta, tableMeta, students);
    this.resetTable(colNames, headings, students);
  }

  initTable() {
    this.tableData = {
      data: [],
      metaData: {
        column_names: [],
        display_names: {},
        search: true,
        edit: false,
        placeholder: "Search by Student Name, Student USN"
      }
    };
  }

  resetTable(colNames: string[], headings: object, data: object[]) {
    this.tableData.metaData.column_names = colNames;
    this.tableData.metaData.display_names = headings as {};
    this.tableData.data = data as Vo[];
    this.gtable.update();
  }
}