import { Component, Input, Output, EventEmitter } from '@angular/core';
import { FormControl } from '@angular/forms';
import { } from 'events';
import { Field } from '../../formdata/form/form';
import { FormData } from '../../formdata/form/formData';

@Component({
  selector: 'ct-data-panel-1',
  templateUrl: './component.html',
  styleUrls: ['./component.scss']
})
export class CtDataPanel1 {

  ngOnInit() {
  }
}
