
import { Form , Field, ChildForm } from '../form/form';
import { FormData } from '../form/formData';
import { SelectOption, Vo } from '../form/types';
import { Validators } from '@angular/forms'
import { ServiceAgent} from '../form/serviceAgent';

export class AssessmentItemForm extends Form {
	private static _instance = new AssessmentItemForm();
	instituteId:Field = {
		name:'instituteId'
		,controlType: 'Hidden'
		,label: 'instituteId'
		,valueType: 1
		,errorId: 'invalidTenentKey'
		,maxValue: 9999999999999
	};
	name:Field = {
		name:'name'
		,controlType: 'Input'
		,label: 'Assessment Item Name'
		,isRequired: true
		,valueType: 0
		,errorId: 'invalidName'
		,maxLength: 150
	};
	maxMarks:Field = {
		name:'maxMarks'
		,controlType: 'Input'
		,label: 'Maximum Test Marks'
		,isRequired: true
		,valueType: 1
		,errorId: 'invalidInteger'
		,maxValue: 9999999999999
	};

	public static getInstance(): AssessmentItemForm {
		return AssessmentItemForm._instance;
	}

	constructor() {
		super();
		this.fields = new Map();
		this.controls = new Map();
		this.controls.set('instituteId', [Validators.max(9999999999999)]);
		this.fields.set('instituteId', this.instituteId);
		this.controls.set('name', [Validators.required, Validators.maxLength(150)]);
		this.fields.set('name', this.name);
		this.controls.set('maxMarks', [Validators.required, Validators.max(9999999999999)]);
		this.fields.set('maxMarks', this.maxMarks);
		this.opsAllowed = {get: true, filter: true, update: true, create: true, delete: true};
		this.keyFields = ["seqNo", "assessmentSchemeId"];
	}

	public getName(): string {
		 return 'assessmentItem';
	}
}


export class AssessmentItemFd extends FormData {
	constructor(form: AssessmentItemForm, sa: ServiceAgent) {
		super(form, sa);
	}

	setFieldValue(name: 'instituteId' | 'name' | 'maxMarks', value: string | number | boolean | null ): void {
		super.setFieldValue(name, value);
	}

	getFieldValue(name: 'instituteId' | 'name' | 'maxMarks' ): string | number | boolean | null {
		return super.getFieldValue(name);
	}
}


export interface AssessmentItemVo extends Vo {
	name?: string, 
	instituteId?: number, 
	seqNo?: number, 
	assessmentSchemeId?: number, 
	maxMarks?: number
}
