import { Component, OnInit, Input } from "@angular/core";
import { GroupPanel } from '../../interfaces';
import { ServiceAgent } from 'src/app/framework-modules/formdata/form/serviceAgent';
import { SessionComponent } from '../../component';
import { ClientContext } from 'src/app/framework-modules/formdata/form/clientContext';
import { AssessmentSchemeSelectionForm, AssessmentSchemeSelectionFd } from 'src/app/framework-modules/formdata/gen/assessmentSchemeSelectionForm';

@Component({
  selector: "test-type-selection",
  templateUrl: "./component.html",
  styleUrls: ["./component.scss"]
})

export class TestTypeSelectionComponent implements OnInit {
  @Input() inputData: any;
  @Input() parentPanel: GroupPanel;
  @Input() session: SessionComponent;
  /**
   * select a subject using this form
   */
  public form: AssessmentSchemeSelectionForm;
  public fd: AssessmentSchemeSelectionFd;

  public selectedSubject: any = '';
  public selectedScheme: string = '';

  constructor(private sa: ServiceAgent, private cc: ClientContext) {
    this.form = AssessmentSchemeSelectionForm.getInstance();
    this.fd = this.form.newFormData(this.sa);
  }

  ngOnInit() {
  }

  private subjectChanged(id: any) {
    if (id == this.selectedSubject) {
      return;
    }
    this.selectedSubject = id;
    if (id) {
      this.fetchData(id);
    }
  }

  private fetchData(subjectId: any) {
    this.fd.fetchData().subscribe(
      data => {
        if (this.fd.getFieldValue('assessmentSchemeId')) {
          this.selectedScheme = this.fd.getDisplayedValueOf('assessmentSchemeId');
        } else {
          this.selectedScheme = '';
        }
      },
      msgs => {
        console.error(msgs);
      }
    );
  }

  update() {
    this.fd.save().subscribe(
      data => {
        console.log('All done');
        this.fd.setFieldValue('offeredSubjectId', '');
        this.fd.setFieldValue('assessmentSchemeId', '');
        this.selectedScheme = ''
        this.selectedSubject = '';
      },
      msgs => {
        console.error(msgs);
      }
    );
  }
}
