
import { Form , Field, ChildForm } from '../form/form';
import { FormData } from '../form/formData';
import { SelectOption, Vo } from '../form/types';
import { Validators } from '@angular/forms'
import { ServiceAgent} from '../form/serviceAgent';

export class OfferedSubjectForm extends Form {
	private static _instance = new OfferedSubjectForm();
	subjectId:Field = {
		name:'subjectId'
		,controlType: 'Hidden'
		,label: 'subjectId'
		,isRequired: true
		,valueType: 1
		,errorId: 'invalidId'
		,maxValue: 9999999999999
	};
	offeredSubjectId:Field = {
		name:'offeredSubjectId'
		,controlType: 'Hidden'
		,label: 'offeredSubjectId'
		,valueType: 1
		,errorId: 'invalidId'
		,maxValue: 9999999999999
	};
	departmentId:Field = {
		name:'departmentId'
		,controlType: 'Hidden'
		,label: 'departmentId'
		,isRequired: true
		,valueType: 1
		,errorId: 'invalidId'
		,maxValue: 9999999999999
	};
	degreeId:Field = {
		name:'degreeId'
		,controlType: 'Hidden'
		,label: 'degreeId'
		,isRequired: true
		,listName: 'degreeList'
		,valueType: 1
		,errorId: 'invalidId'
		,maxValue: 9999999999999
	};
	levelId:Field = {
		name:'levelId'
		,controlType: 'Hidden'
		,label: 'levelId'
		,isRequired: true
		,listName: 'levelList'
		,listKey: 'degreeId'
		,valueType: 1
		,errorId: 'invalidId'
		,maxValue: 9999999999999
	};
	maxCieMarks:Field = {
		name:'maxCieMarks'
		,controlType: 'Input'
		,label: 'Maximum CIE marks'
		,isRequired: true
		,valueType: 1
		,defaultValue: 50
		,errorId: 'invalidInteger'
		,maxValue: 9999999999999
	};
	cieWeightage:Field = {
		name:'cieWeightage'
		,controlType: 'Input'
		,label: 'CIE Weightage(%)'
		,isRequired: true
		,valueType: 1
		,defaultValue: 100
		,errorId: 'invalidInteger'
		,maxValue: 9999999999999
	};
	maxSeeMarks:Field = {
		name:'maxSeeMarks'
		,controlType: 'Input'
		,label: 'Maximum Exam marks'
		,isRequired: true
		,valueType: 1
		,defaultValue: 100
		,errorId: 'invalidInteger'
		,maxValue: 9999999999999
	};
	seeWeightage:Field = {
		name:'seeWeightage'
		,controlType: 'Input'
		,label: 'Exam Weightage(%)'
		,isRequired: true
		,valueType: 1
		,defaultValue: 50
		,errorId: 'invalidInteger'
		,maxValue: 9999999999999
	};
	requires2:Field = {
		name:'requires2'
		,controlType: 'Input'
		,label: 'Requires II Evaluation'
		,valueType: 3
		,defaultValue: false
		,errorId: 'invalidBool'
	};
	examDate:Field = {
		name:'examDate'
		,controlType: 'Input'
		,label: 'Exam Date'
		,valueType: 4
		,errorId: 'invalidDate'
		,minValue: 73000
		,maxValue: 73000
	};

	public static getInstance(): OfferedSubjectForm {
		return OfferedSubjectForm._instance;
	}

	constructor() {
		super();
		this.fields = new Map();
		this.controls = new Map();
		this.controls.set('subjectId', [Validators.required, Validators.max(9999999999999)]);
		this.fields.set('subjectId', this.subjectId);
		this.controls.set('offeredSubjectId', [Validators.max(9999999999999)]);
		this.fields.set('offeredSubjectId', this.offeredSubjectId);
		this.controls.set('departmentId', [Validators.required, Validators.max(9999999999999)]);
		this.fields.set('departmentId', this.departmentId);
		this.controls.set('degreeId', [Validators.required, Validators.max(9999999999999)]);
		this.fields.set('degreeId', this.degreeId);
		this.controls.set('levelId', [Validators.required, Validators.max(9999999999999)]);
		this.fields.set('levelId', this.levelId);
		this.controls.set('maxCieMarks', [Validators.required, Validators.max(9999999999999)]);
		this.fields.set('maxCieMarks', this.maxCieMarks);
		this.controls.set('cieWeightage', [Validators.required, Validators.max(9999999999999)]);
		this.fields.set('cieWeightage', this.cieWeightage);
		this.controls.set('maxSeeMarks', [Validators.required, Validators.max(9999999999999)]);
		this.fields.set('maxSeeMarks', this.maxSeeMarks);
		this.controls.set('seeWeightage', [Validators.required, Validators.max(9999999999999)]);
		this.fields.set('seeWeightage', this.seeWeightage);
		this.controls.set('requires2', []);
		this.fields.set('requires2', this.requires2);
		this.controls.set('examDate', []);
		this.fields.set('examDate', this.examDate);
		this.opsAllowed = {delete: true, get: true, create: true, update: true, filter: true};
		this.listFields = ['degreeId','levelId'];
		this.keyFields = ["offeredSubjectId"];
	}

	public getName(): string {
		 return 'offeredSubject';
	}
}


export class OfferedSubjectFd extends FormData {
	constructor(form: OfferedSubjectForm, sa: ServiceAgent) {
		super(form, sa);
	}

	setFieldValue(name: 'subjectId' | 'offeredSubjectId' | 'departmentId' | 'degreeId' | 'levelId' | 'maxCieMarks' | 'cieWeightage' | 'maxSeeMarks' | 'seeWeightage' | 'requires2' | 'examDate', value: string | number | boolean | null ): void {
		super.setFieldValue(name, value);
	}

	getFieldValue(name: 'subjectId' | 'offeredSubjectId' | 'departmentId' | 'degreeId' | 'levelId' | 'maxCieMarks' | 'cieWeightage' | 'maxSeeMarks' | 'seeWeightage' | 'requires2' | 'examDate' ): string | number | boolean | null {
		return super.getFieldValue(name);
	}
}


export interface OfferedSubjectVo extends Vo {
	maxCieMarks?: number, 
	degreeId?: number, 
	examDate?: string, 
	departmentId?: number, 
	attendanceFrozen?: boolean, 
	cieFrozen?: boolean, 
	subjectId?: number, 
	seeWeightage?: number, 
	requires2?: boolean, 
	offeredSubjectId?: number, 
	createdAt?: string, 
	maxSeeMarks?: number, 
	seasonId?: number, 
	levelId?: number, 
	instituteId?: number, 
	isIntegrated?: boolean, 
	cieWeightage?: number, 
	assessmentSchemeId?: number, 
	isChild?: boolean, 
	updatedAt?: string
}
