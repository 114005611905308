
import { Form , Field, ChildForm } from '../form/form';
import { FormData } from '../form/formData';
import { SelectOption, Vo } from '../form/types';
import { Validators } from '@angular/forms'
import { ServiceAgent} from '../form/serviceAgent';
import { RegisterableStudentForm, RegisterableStudentVo } from './registerableStudentForm';

export class RegisterStudentForm extends Form {
	private static _instance = new RegisterStudentForm();
	sectionName:Field = {
		name:'sectionName'
		,controlType: 'Output'
		,label: 'Section'
		,valueType: 0
		,defaultValue: ''
		,errorId: 'invalidName'
		,maxLength: 150
	};
	subjectName:Field = {
		name:'subjectName'
		,controlType: 'Output'
		,label: 'Subject'
		,valueType: 0
		,defaultValue: ''
		,errorId: 'invalidName'
		,maxLength: 150
	};
	subjectSectionId:Field = {
		name:'subjectSectionId'
		,controlType: 'Hidden'
		,label: 'subjectSectionId'
		,isRequired: true
		,valueType: 1
		,errorId: 'invalidId'
		,maxValue: 9999999999999
	};

	students: ChildForm = {
		name:'students',
		form:RegisterableStudentForm.getInstance(),
		isEditable:false,
		isTabular:true,
		label:'',
		minRows:1,
		maxRows:10000,
		errorId:null
	};

	public static getInstance(): RegisterStudentForm {
		return RegisterStudentForm._instance;
	}

	constructor() {
		super();
		this.fields = new Map();
		this.controls = new Map();
		this.controls.set('sectionName', [Validators.maxLength(150)]);
		this.fields.set('sectionName', this.sectionName);
		this.controls.set('subjectName', [Validators.maxLength(150)]);
		this.fields.set('subjectName', this.subjectName);
		this.controls.set('subjectSectionId', [Validators.required, Validators.max(9999999999999)]);
		this.fields.set('subjectSectionId', this.subjectSectionId);

		this.childForms = new Map();
		this.childForms.set('students', this.students);
		this.opsAllowed = {get: true, update: true, filter: true};
		this.keyFields = ["subjectSectionId"];
	}

	public getName(): string {
		 return 'registerStudent';
	}
}


export class RegisterStudentFd extends FormData {
	constructor(form: RegisterStudentForm, sa: ServiceAgent) {
		super(form, sa);
	}

	setFieldValue(name: 'sectionName' | 'subjectName' | 'subjectSectionId', value: string | number | boolean | null ): void {
		super.setFieldValue(name, value);
	}

	getFieldValue(name: 'sectionName' | 'subjectName' | 'subjectSectionId' ): string | number | boolean | null {
		return super.getFieldValue(name);
	}
}


export interface RegisterStudentVo extends Vo {
	levelSectionId?: number, 
	departmentId?: number, 
	attendanceFrozen?: boolean, 
	cieFrozen?: boolean, 
	sectionId?: number, 
	subjectId?: number, 
	parentId?: number, 
	sectionName?: string, 
	offeredSubjectId?: number, 
	isOffered?: boolean, 
	totalClasses?: number, 
	seasonId?: number, 
	subjectSectionId?: number, 
	instituteId?: number, 
	isIntegrated?: boolean, 
	subjectCode?: string, 
	subjectName?: string, 
	students?: RegisterableStudentVo
}
