
import { Form , Field, ChildForm } from '../form/form';
import { FormData } from '../form/formData';
import { SelectOption, Vo } from '../form/types';
import { Validators } from '@angular/forms'
import { ServiceAgent} from '../form/serviceAgent';

export class ChallengeValuationOptedStudentForm extends Form {
	private static _instance = new ChallengeValuationOptedStudentForm();

	public static getInstance(): ChallengeValuationOptedStudentForm {
		return ChallengeValuationOptedStudentForm._instance;
	}

	constructor() {
		super();
		this.fields = new Map();
		this.controls = new Map();
		this.opsAllowed = {filter: true};
	}

	public getName(): string {
		 return 'challengeValuationOptedStudent';
	}
}


export class ChallengeValuationOptedStudentFd extends FormData {
	constructor(form: ChallengeValuationOptedStudentForm, sa: ServiceAgent) {
		super(form, sa);
	}
	/**  this form has no editable fields. data nust be accessed as Vo and not through fd **/
}


export interface ChallengeValuationOptedStudentVo extends Vo {
	studentId?: number, 
	offeredSubjectId?: number, 
	usn?: string, 
	level?: string, 
	name?: string, 
	instituteId?: number, 
	department?: string
}
