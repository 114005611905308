import { Component, Input, Output, EventEmitter, OnInit } from "@angular/core";
import { DynamicPanel, LandingPanel, GroupPanel } from '../../interfaces';
import { ServiceAgent } from 'src/app/framework-modules/formdata/form/serviceAgent';
import { SessionComponent } from '../../component';
import { MessageService } from 'src/app/services/messageService';
import { ExternalForm, ExternalFd } from 'src/app/framework-modules/formdata/gen/externalForm';
import { CtDialogComponent } from 'src/app/framework-modules/elements/ct-dialog/component';
import { MatDialog } from '@angular/material';

@Component({
  selector: "external-evaluator-add",
  templateUrl: "./component.html",
  styleUrls: ["./component.scss"]
})


export class ExternalStaffAddComponent implements DynamicPanel, OnInit {
  @Input() inputData: any;
  @Input() parentPanel: GroupPanel;
  @Input() session: SessionComponent;

  public form: ExternalForm;
  public fd: ExternalFd;

  constructor(private sa: ServiceAgent, private ms: MessageService, private dialog: MatDialog) {
    this.form = ExternalForm.getInstance();
    this.fd = this.form.newFormData(this.sa);
  }

  ngOnInit() {
  }

  save(){
    let dialogRef = this.dialog.open(CtDialogComponent);
    dialogRef.componentInstance.title = 'Save Changes';
    dialogRef.componentInstance.content = 'Are you sure you want to save changes?';
    dialogRef.componentInstance.primary = 'Save';
    const subscribeDialog = dialogRef.componentInstance.save.subscribe((data) => {
      this.dialog.closeAll();
      this.doSave();
    });
  }

  /**
   * to go back to the external evaluators list by discarding the add page
   */
  
  doCancel() {
    let dialogRef = this.dialog.open(CtDialogComponent);
    dialogRef.componentInstance.title = 'Discard Changes';
    dialogRef.componentInstance.content = 'Are you sure you want to discard changes?';
    dialogRef.componentInstance.primary = 'Discard';
    const subscribeDialog = dialogRef.componentInstance.save.subscribe((data) => {
      this.dialog.closeAll();
      this.parentPanel.navigate('ExternalStaffList');
    }); 
  }

  doSave() {
    /**
     * saves the data into the table 
     */
    this.fd.saveAsNew().subscribe({
      next: vo => {
        /**
         * navigates to listing page after saving.
         */
        this.ms.showSuccess("External Staff Added Successfully!!");
        this.parentPanel.navigate("ExternalStaffList");
      },
      /**
       * returns an error in console if there is any error.
       */
      error: err => {
        this.ms.showError(err);
        console.error(err);
      }
    });
  }

}
