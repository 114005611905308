
import { Form , Field, ChildForm } from '../form/form';
import { FormData } from '../form/formData';
import { SelectOption, Vo } from '../form/types';
import { Validators } from '@angular/forms'
import { ServiceAgent} from '../form/serviceAgent';

export class RegisteredStudentNameForm extends Form {
	private static _instance = new RegisteredStudentNameForm();

	public static getInstance(): RegisteredStudentNameForm {
		return RegisteredStudentNameForm._instance;
	}

	constructor() {
		super();
		this.fields = new Map();
		this.controls = new Map();
		this.opsAllowed = {filter: true};
	}

	public getName(): string {
		 return 'registeredStudentName';
	}
}


export class RegisteredStudentNameFd extends FormData {
	constructor(form: RegisteredStudentNameForm, sa: ServiceAgent) {
		super(form, sa);
	}
	/**  this form has no editable fields. data nust be accessed as Vo and not through fd **/
}


export interface RegisteredStudentNameVo extends Vo {
	studentId?: number, 
	offeredSubjectId?: number, 
	usn?: string, 
	subjectRegisterId?: number, 
	level?: string, 
	subjectSectionId?: number, 
	isAuthorizedAbsence?: boolean, 
	name?: string, 
	instituteId?: number, 
	section?: string, 
	department?: string
}
