
import { Form , Field, ChildForm } from '../form/form';
import { FormData } from '../form/formData';
import { SelectOption, Vo } from '../form/types';
import { Validators } from '@angular/forms'
import { ServiceAgent} from '../form/serviceAgent';
import { AssignableEvaluatorForm, AssignableEvaluatorVo } from './assignableEvaluatorForm';

export class AssignEvaluatorForm extends Form {
	private static _instance = new AssignEvaluatorForm();

	evaluators: ChildForm = {
		name:'evaluators',
		form:AssignableEvaluatorForm.getInstance(),
		isEditable:false,
		isTabular:true,
		label:'',
		minRows:0,
		maxRows:0,
		errorId:null
	};

	public static getInstance(): AssignEvaluatorForm {
		return AssignEvaluatorForm._instance;
	}

	constructor() {
		super();
		this.fields = new Map();
		this.controls = new Map();

		this.childForms = new Map();
		this.childForms.set('evaluators', this.evaluators);
		this.opsAllowed = {get: true, update: true};
	}

	public getName(): string {
		 return 'assignEvaluator';
	}
}


export class AssignEvaluatorFd extends FormData {
	constructor(form: AssignEvaluatorForm, sa: ServiceAgent) {
		super(form, sa);
	}
	/**  this form has no editable fields. data nust be accessed as Vo and not through fd **/
}


export interface AssignEvaluatorVo extends Vo {
	offeredSubjectId?: number, 
	departmentId?: number, 
	subjectName?: string, 
	evaluators?: AssignableEvaluatorVo
}
