import {
  Component,
  OnInit,
  ViewChild,
  Input,
  AfterViewInit
} from "@angular/core";
import { ServiceAgent } from "src/app/framework-modules/formdata/form/serviceAgent";
import { GroupPanel } from "../../interfaces";
import { SessionComponent } from "../../component";
import { CtEditableTableComponent } from "src/app/framework-modules/elements/ct-editable-table/component";
import { MessageService } from "src/app/services/messageService";
import { MatDialog } from "@angular/material";
import { CtDialogComponent } from "src/app/framework-modules/elements/ct-dialog/component";
import { AttendanceUpdateForm, AttendanceUpdateFd, AttendanceUpdateVo } from 'src/app/framework-modules/formdata/gen/attendanceUpdateForm';


@Component({
  selector: "consolidated-attendance-update",
  templateUrl: "./component.html",
  styleUrls: ["./component.scss"]
})

export class ConsolidatedAttendanceUpdateComponent implements OnInit {
  @ViewChild("gridTable", { static: false }) gtable: CtEditableTableComponent;
  @Input() inputData: any;
  @Input() parentPanel: GroupPanel;
  @Input() session: SessionComponent;

  form: AttendanceUpdateForm;
  fd: AttendanceUpdateFd;
  vo: AttendanceUpdateVo = {};

  constructor(
    private sa: ServiceAgent,
    private ms: MessageService,
    private dialog: MatDialog
  ) {
    this.form = AttendanceUpdateForm.getInstance();
    this.fd = this.form.newFormData(this.sa);
  }

  ngOnInit() {
    if(this.inputData && this.inputData.subjectRegisterId && this.inputData.studentId ){
      this.getData(this.inputData);
    }else{
      this.ms.showError('Page invoked with invalid data');
    }
  }

  private getData(keys){
    this.fd.setFieldValue('studentId', keys.studentId);
    this.fd.setFieldValue('subjectRegisterId',keys.subjectRegisterId);
    this.fd.fetchData().subscribe({
      next: data=> {
        this.vo = data;
      },
      error: msg => {
        this.ms.showError('Server returned with an error');
      }
    });
  }

  /**
   * open cancel dialog box
   */
  cancel() {
    let dialogRef = this.dialog.open(CtDialogComponent);
    dialogRef.componentInstance.title = "Discard Changes";
    dialogRef.componentInstance.content =
      "Are you sure you want to discard changes?";
    dialogRef.componentInstance.primary = "Discard";
    const subscribeDialog = dialogRef.componentInstance.save.subscribe(data => {
      this.dialog.closeAll();
      this.parentPanel.navigate("ConsolidatedAttendanceList");
    });
  }

  /**
   * open save dialog box
   */
  save() {
    let dialogRef = this.dialog.open(CtDialogComponent);
    dialogRef.componentInstance.title = "Discard Changes";
    dialogRef.componentInstance.content =
      "Are you sure you want to update attandance?";
    dialogRef.componentInstance.primary = "Update";
    const subscribeDialog = dialogRef.componentInstance.save.subscribe(data => {
      this.dialog.closeAll();
      this.updateAttendance();
    });
  }

  /**
   * Call authorization service
   */
  updateAttendance() {
    if(!this.fd.validateForm()){
      this.ms.showError('Please fix the errors nefore submitting');
      return;
    }
    this.fd.save().subscribe({
      next: data=> {
        this.ms.showSuccess('Request sent for Authorization');
        this.parentPanel.navigate("ConsolidatedAttendanceList");
      },
      error: msg => {
        this.ms.showError('Errors encountered. Please fix them and try again.');
      }
    });

  }
}
