import { Component, OnInit, ViewChild, Input } from "@angular/core";
import { DynamicPanel, GroupPanel } from '../../interfaces';
import { ServiceAgent } from 'src/app/framework-modules/formdata/form/serviceAgent';
import { CtTableComponent } from '../../../../framework-modules/elements/ct-table/component'
import { SessionComponent } from '../../component';
import { MessageService } from 'src/app/services/messageService';
import { OfferedSubjectDetailForm, OfferedSubjectDetailFd } from 'src/app/framework-modules/formdata/gen/offeredSubjectDetailForm';
import { SubjectSectionDetailVo } from 'src/app/framework-modules/formdata/gen/subjectSectionDetailForm';
import { FilterRequest } from 'src/app/framework-modules/formdata/form/types';
import { MatDialog } from '@angular/material';
import { CtDialogComponent } from 'src/app/framework-modules/elements/ct-dialog/component';
import { OfferedSubjectFd, OfferedSubjectForm } from 'src/app/framework-modules/formdata/gen/offeredSubjectForm';

@Component({
  selector: "offer-subject-edit",
  templateUrl: "./component.html",
  styleUrls: ["./component.scss"]
})


export class OfferSubjectEditComponent implements DynamicPanel, OnInit {
  @Input() inputData: any;
  @Input() parentPanel: GroupPanel;
  @Input() session: SessionComponent;

  public form: OfferedSubjectDetailForm;
  public fd: OfferedSubjectDetailFd;
  public saveForm: OfferedSubjectForm;
  public saveFd: OfferedSubjectFd;
  public levelSelected:boolean = false;
  public levelList: SubjectSectionDetailVo[] = [];
  /*
   * chip requires name and select attribute names in the VO
   */
  public nameAttr = 'name';
  public selectAttr = 'isOffered';

  constructor(private sa: ServiceAgent, private ms: MessageService,public dialog:MatDialog) {
    this.form = OfferedSubjectDetailForm.getInstance();
    this.fd = this.form.newFormData(this.sa);
    this.saveForm = OfferedSubjectForm.getInstance();
    this.saveFd = this.saveForm.newFormData(this.sa);
  }

  ngOnInit() {
    this.fetchSubjectDetails(this.inputData);
  }

  /**
   * 
   * @param subjectId passed by the caller. this page rendered for this subject id
   */
  private fetchSubjectDetails(subjectId: any): void {
    if (!this.inputData) {
      console.error('Design error : Input for subjectId not received.');
      return;
    }
    this.fd.setFieldValue('subjectId', this.inputData.subjectId);
    this.fd.fetchData().subscribe(
      data => {
        if(this.inputData.levelId != -1) {
          this.ms.showInfo("Subject has already been offered");
          this.levelSelected = true;
        }
        console.log(data);
      },
      msgs => {
        this.ms.showError('Server returned with Error : ' + msgs);
      }
    );
  }

  /**
   * save the changes to sections
   */
  save() {
    var cieWeightage = (this.saveFd.getFieldValue('cieWeightage')as number)/100;
    var maxCie = this.saveFd.getFieldValue('maxCieMarks') as number;
    var maxSee = this.saveFd.getFieldValue('maxSeeMarks') as number;
    var seeWeightage = (this.saveFd.getFieldValue('seeWeightage')as number)/100;
    var marks = (maxCie*cieWeightage + maxSee*seeWeightage);
    if(marks != 100){
      this.ms.showError("Invalid combination of CIE and Exam marks Weightage");
      return;
    }
    let dialogRef = this.dialog.open(CtDialogComponent);
    dialogRef.componentInstance.title = 'Save Changes';
    dialogRef.componentInstance.content = 'Are you sure you want to offer subject to ' + this.fd.getDisplayedValueOf('degreeId') + ' - ' + this.fd.getDisplayedValueOf('levelId') + '?';
    dialogRef.componentInstance.primary = 'Save';
    const subscribeDialog = dialogRef.componentInstance.save.subscribe((data) => {
      this.dialog.closeAll();
      this.saveFd.setFieldValue('subjectId',this.inputData.subjectId);
      this.saveFd.setFieldValue('departmentId',this.inputData.departmentId);
      this.saveFd.setFieldValue('degreeId',this.fd.getFieldValue('degreeId'));
      this.saveFd.setFieldValue('levelId',this.fd.getFieldValue('levelId'));
      this.saveFd.save().subscribe(
        {
          next: data =>{
            this.ms.showSuccess('Subject Offered to sections Successfully')
            this.parentPanel.navigate('OfferSubjectList');
          },
          error: err => {
            if(err[0].type == 'error'){
              this.ms.showError('Server returned with Error : ' + err[0].text);
            }
          }
        }
      );
    });
    
  }

  /**
   * user discards changes
   */
  cancel(){
    let dialogRef = this.dialog.open(CtDialogComponent);
    dialogRef.componentInstance.title = 'Discard Changes';
    dialogRef.componentInstance.content = 'Are you sure you want to discard changes?';
    dialogRef.componentInstance.primary = 'Discard';
    const subscribeDialog = dialogRef.componentInstance.save.subscribe((data) => {
      this.dialog.closeAll();    
      this.parentPanel.navigate('OfferSubjectList');
    });
  }
}