
import { Form , Field, ChildForm } from '../form/form';
import { FormData } from '../form/formData';
import { SelectOption, Vo } from '../form/types';
import { Validators } from '@angular/forms'
import { ServiceAgent} from '../form/serviceAgent';
import { CurrentBacklogSubjectDetailForm, CurrentBacklogSubjectDetailVo } from './currentBacklogSubjectDetailForm';

export class CurrentBacklogDetailForm extends Form {
	private static _instance = new CurrentBacklogDetailForm();
	studentId:Field = {
		name:'studentId'
		,controlType: 'Hidden'
		,label: 'studentId'
		,isRequired: true
		,valueType: 1
		,errorId: 'invalidId'
		,maxValue: 9999999999999
	};
	programId:Field = {
		name:'programId'
		,controlType: 'Hidden'
		,label: 'programId'
		,valueType: 1
		,errorId: 'invalidId'
		,maxValue: 9999999999999
	};
	levelId:Field = {
		name:'levelId'
		,controlType: 'Hidden'
		,label: 'levelId'
		,valueType: 1
		,errorId: 'invalidId'
		,maxValue: 9999999999999
	};

	subjects: ChildForm = {
		name:'subjects',
		form:CurrentBacklogSubjectDetailForm.getInstance(),
		isEditable:false,
		isTabular:true,
		label:'',
		minRows:0,
		maxRows:0,
		errorId:null
	};

	public static getInstance(): CurrentBacklogDetailForm {
		return CurrentBacklogDetailForm._instance;
	}

	constructor() {
		super();
		this.fields = new Map();
		this.controls = new Map();
		this.controls.set('studentId', [Validators.required, Validators.max(9999999999999)]);
		this.fields.set('studentId', this.studentId);
		this.controls.set('programId', [Validators.max(9999999999999)]);
		this.fields.set('programId', this.programId);
		this.controls.set('levelId', [Validators.max(9999999999999)]);
		this.fields.set('levelId', this.levelId);

		this.childForms = new Map();
		this.childForms.set('subjects', this.subjects);
		this.opsAllowed = {get: true, filter: true};
		this.keyFields = ["studentId"];
	}

	public getName(): string {
		 return 'currentBacklogDetail';
	}
}


export class CurrentBacklogDetailFd extends FormData {
	constructor(form: CurrentBacklogDetailForm, sa: ServiceAgent) {
		super(form, sa);
	}

	setFieldValue(name: 'studentId' | 'programId' | 'levelId', value: string | number | boolean | null ): void {
		super.setFieldValue(name, value);
	}

	getFieldValue(name: 'studentId' | 'programId' | 'levelId' ): string | number | boolean | null {
		return super.getFieldValue(name);
	}
}


export interface CurrentBacklogDetailVo extends Vo {
	studentId?: number, 
	totalPendingCredits?: number, 
	usn?: string, 
	levelSectionId?: number, 
	numberOfSubjects?: number, 
	levelId?: number, 
	name?: string, 
	instituteId?: number, 
	programId?: number, 
	subjects?: CurrentBacklogSubjectDetailVo
}
