import { Component, OnInit, ViewChild, Input } from "@angular/core";
import { DynamicPanel, GroupPanel } from '../../interfaces';
import { ServiceAgent } from 'src/app/framework-modules/formdata/form/serviceAgent';
import { CtTableComponent, TableMetaData, } from '../../../../framework-modules/elements/ct-table/component'
import { FilterRequest, Vo } from 'src/app/framework-modules/formdata/form/types';
import { SessionComponent } from '../../component';
import { CtSpinner } from 'src/app/framework-modules/elements/ct-spinner/component';
import { PacketListForm, PacketListVo } from 'src/app/framework-modules/formdata/gen/packetListForm';
import { PanelData } from 'src/app/framework-modules/formdata/form/formData';
import { MessageService } from 'src/app/services/messageService';
import { EvaluatorSelectionForm, EvaluatorSelectionFd } from 'src/app/framework-modules/formdata/gen/evaluatorSelectionForm';
import { CodedPaperNameFd, CodedPaperNameForm } from "src/app/framework-modules/formdata/gen/codedPaperNameForm";
import { CodedPaperForm } from "src/app/framework-modules/formdata/gen/codedPaperForm";
import { ExcelDownloadService } from "src/app/services/excelDownloadService";
import { PdfDownloadService } from "src/app/services/pdfDownloadService";


@Component({
  selector: "packet-list",
  templateUrl: "./component.html",
  styleUrls: ["./component.scss"]
})

export class PacketListComponent implements DynamicPanel, OnInit {
  @ViewChild("gridTable", { static: false }) gtable: CtTableComponent;
  @ViewChild("spinner", { static: false }) public spinner: CtSpinner;
  @Input() inputData: any;
  @Input() parentPanel: GroupPanel;
  @Input() session: SessionComponent;
  public form: EvaluatorSelectionForm;
  public fd: EvaluatorSelectionFd;
  public reportForm: CodedPaperNameForm;
  public reportFd: CodedPaperNameFd;
  public tableData: TableMetaData;

  private pd: PanelData;
  private packets: PacketListVo[];


  constructor(private sa: ServiceAgent, private ms: MessageService, private ps:PdfDownloadService,) {
    this.form = EvaluatorSelectionForm.getInstance();
    this.fd = this.form.newFormData(this.sa);

    this.reportForm = CodedPaperNameForm.getInstance();
    this.reportFd = this.reportForm.newFormData(this.sa);
    this.pd = PacketListForm.getInstance().newPanelData(this.sa);
  }


  ngOnInit() {
    this.metaDataInit();
  }

  metaDataInit() {
    this.tableData = {
      data: [],
      metaData: {
        column_names: [
          "packetCode",
          "name",
          "phoneNumber"
        ],
        display_names: {
          packetCode: "Packet",
          name: "Evaluator Name",
          phoneNumber: "Phone Number"
        },
        search: true,
        edit:true,
        view:true,
        buttonName:"Enter Marks",
        placeholder: "Search By Evaluator Name, Packet ID....",
      }
    };
  }

  public listForSubject() {
    const offerId = this.fd.getFieldValue('offeredSubjectId');
    if (!offerId) {
      this.ms.showError('Please select a Subject');
      return;
    }
    const filter: FilterRequest = {
      conditions: { offeredSubjectId: { comp: "=", value: offerId } }
    };
    this.getData(filter);
  }

  /**
   * get list of packets for this offer-id. PacketListForm is used
   * @param offerId 
   */
  private getData(filter) {
    this.spinner.show('Getting packets ..')
    this.pd.filter(filter).subscribe({
      next: list => {
        console.log(list.length + ' packets');
        this.tableData.data = list;
        this.packets = list;
        this.gtable.update(this.spinner);
      },
      error: msgs => {
        this.spinner.hide();
        this.ms.showError('Server returned with errors..');
      }
    });
  }

  private getParams(idx) {
    const vo = this.packets[idx];
    return { 
      offeredSubjectId: vo.offeredSubjectId, 
      packetCode: vo.packetCode,
      isMakeUp: false
    };
  }
  public editPacket(idx) {
    this.parentPanel.navigate('ValuationEdit', {data: this.getParams(idx), screen: "PacketList"});
  }
  public viewPacket(idx) {
    this.parentPanel.navigate('ValuationView', {data: this.getParams(idx), screen: "PacketList"});
  }



  downloadReport(){
    const filter1:FilterRequest = {
      conditions:
      {offeredSubjectId:{comp:"=",value:this.fd.getFieldValue('offeredSubjectId')}},
      sort:{"usn":"asc"}
  
    }
    this.reportFd.filter(filter1).subscribe(
      data=>{
        data.forEach(element => {
          element["marks1"] = (element["marks1"] == -1 ? "-" : element["marks1"])
          element["marks2"] = (element["marks2"] == -1 ? "-" : element["marks2"])
          element["marks3"] = (element["marks3"] == -1 ? "-" : element["marks3"])
        })
        var tempTable: TableMetaData = {
          data: data,
          metaData: {
            column_names: [
              "paperCode",
              "packetCode",
              "name",
              "usn",
              "marks1",
              "marks2",
              "marks3",
              "effectiveMarks"
            ],
            display_names: {
              "packetCode":"Packet Code",
              "paperCode":"Paper Code",
              "name": "Name",
              "usn": "USN",
              "marks1": "I Valuation",
              "marks2": "II Valuation / Moderation",
              "marks3":"III Valuation",
              "effectiveMarks":"Effective Marks"
            }
          }
        };
        this.ps.downloadTableLandscape(this.fd.getDisplayedValueOf('offeredSubjectId')+" Report",tempTable,"");
      }
    )
    }
  }
